import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Router } from 'react-router-dom';
import { InfoPagesLayoutTemplate } from 'themes/layouts';
import { Footer } from '../common/components/footer';
import { SinglePageCoreContainer } from '../common/containers';

class InfoPagesLayout extends React.Component {
    render() {
        return (
            <Suspense fallback="Loading ...">
                <InfoPagesLayoutTemplate {...this.props} />
            </Suspense>
        )
    }
}

export default InfoPagesLayout;