const httpClient = App.utils.httpClient;
const requestHeaders = {
	"Content-Type": "application/json",
};
export class AccountLockService {
	async createAccountLock(model, userLockSettings) {
		const url = `${BaseAddress}platform/agency-user-locks/lock-with-password`;
		await httpClient.put(
			url,
			JSON.stringify({ ...userLockSettings }),
			requestHeaders,
			{ responseType: "json" }
		);

		model.isDataUpdated = true;
		model.isUserLockedOut = true;
		return model;
	}

	async createOasisSelfTemporaryBlock(requestModel) {
		const url = `${BaseAddress}platform/tw-agency-user-locks/oasis-self-temporary-lock-with-password`;

		return await httpClient.put(
			url,
			JSON.stringify(requestModel),
			requestHeaders,
			{ responseType: "json" }
		);
	}
}
