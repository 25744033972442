import React from 'react';
import ReactDOM from 'react-dom';

const modalContainer = document.getElementById('app-modal');

class ModalTemplate extends React.Component {
    constructor(props) {
        super(props);

        this.elContainer = document.createElement('div');
    }

    componentDidMount() {
        modalContainer.appendChild(this.elContainer);
    }

    componentWillUnmount() {
        modalContainer.removeChild(this.elContainer);
    }

    render() {
        const { children, ...rest } = this.props;

        return ReactDOM.createPortal(
            React.cloneElement(children, rest),
            this.elContainer
        );
    }
}

export default ModalTemplate;