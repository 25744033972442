import React from "react";

export const AppContext = React.createContext({
    theme: 'light',
    culture: 'en',
});

export const AppActionsContext = React.createContext({
    setTheme: () => { },
    setCulture: () => { },
});