import React from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';

import { EventTemplate } from 'themes/pages/events';

@inject(stores => ({
    eventViewStore: stores.eventViewStore,
    betSlipScrollStore: stores.offerStore.betSlipStore.betSlipScrollStore,
    betSlipStore: stores.offerStore.betSlipStore
}))
@observer
@withRouter
class Event extends React.Component {
    async componentDidMount() {
        const { eventIds } = this.props.match.params;
        await this.props.eventViewStore.initEventIds(eventIds);

        window.scrollTo(0, 0);
    }

    async componentDidUpdate(prevProps) {
        if (this.props.match.params.eventIds !== prevProps.match.params.eventIds) {
            await this.props.eventViewStore.updateEventIds(this.props.match.params.eventIds);

            if (this.props.betSlipScrollStore && !this.props.betSlipScrollStore.isScrollEnabled) {
                window.scrollTo(0, 0);
            }
        }
    }

    componentWillUnmount() {
        this.props.eventViewStore.onDispose();
    }

    render() {
        return (
            <EventTemplate {...this.props} />
        )
    }
}

export default Event;