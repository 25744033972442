import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { constants } from '@gp/offer'
import { SportOfferContext, EventContext, OfferStoreContext, OfferOptionsContext } from '@gp/components';

import { Event, EventOutright } from '../../../../../../common/components/offer/new-offer';
import { defaultTemplate } from '../../../../../../common/hoc';
import { useTranslation } from 'react-i18next';

import { StatisticsButton } from '../../../../../../common/components/offer/buttons';

export const TournamentEventsTemplate = observer(function TournamentEventsTemplate(props) {

	const {
		visibleColumns,

		category,
		tournament,

		setHidden,
		hidden,
	} = props;

	const viewStore = useContext(OfferStoreContext);
	const sport = useContext(SportOfferContext);
	const tableClasses = classNames(
		'offer',
		{
			'offer--sports': !sport.isLive,
			'offer--sports--live': sport.isLive,
			'offer--full': !viewStore.additionalOfferStore.isAdditionalOfferVisible,
			'offer--sml': viewStore.additionalOfferStore.isAdditionalOfferVisible,
			'is-collapsed': props.hidden
		}
	);

	return (
		<table className={tableClasses}>
			<thead className="offer__head offer--sports__head">
				<Header
					visibleColumns={visibleColumns}
					category={category}
					tournament={tournament}

					setHidden={setHidden}
					hidden={hidden}
				/>
			</thead>
			<EventsList
				tournament={tournament}
				hidden={hidden}
			/>
		</table>
	)
});

const EventsList = defaultTemplate(function EventsList(props) {

	const {
		tournament,

		hidden,
	} = props;

	const {
		isOutright,
	} = useContext(SportOfferContext);

	if (hidden) {
		return null;
	}

	return (
		<tbody className="offer__body">
			{tournament.events.map((event, index) => (
				<EventContext.Provider key={event.id} value={event}>
					{isOutright ? (
						<EventOutright
							isAlternate={index % 2 === 0}
						/>
					) : (
						<Event
							noSecondaryOffer
							isAlternate={index % 2 === 0}
						/>
					)}
				</EventContext.Provider>
			))}
		</tbody>
	)
})



//#region Header

const Header = defaultTemplate(function Header(props) {

	const {
		visibleColumns,

		category,
		tournament,

		setHidden,
		hidden,
	} = props;

	const sport = useContext(SportOfferContext);

	if (sport.isOutright) {
		return (
			<OutrightHeader
				category={category}
				tournament={tournament}

				setHidden={setHidden}
				hidden={hidden}
			/>
		)
	}

	return (
		<PrematchHeader
			visibleColumns={visibleColumns}

			category={category}
			tournament={tournament}

			setHidden={setHidden}
			hidden={hidden}
		/>
	)
})

const OutrightHeader = defaultTemplate(function OutrightHeader(props) {

	const {
		category,
		tournament,

		setHidden,
		hidden,
	} = props;

	return (
		<tr className="offer__head__row">
			<th
				title={`${category.name} - ${tournament.name}`}
				className="offer__head__data offer__header--outright"
				colSpan={19}
			>
				<div className="offer__header--outright__wrapper">
					<span className="offer__header--outright__ct">
						<i className={"offer__header__icon u-icon u-icon--big u-icon--flag u-icon--rounded u-icon--flag--" + category.abrv + " u-mar--right--tny"} />
						{category.name} - {tournament.name}
					</span>
					<div className="offer__header--outright__close offer__toggle">
						<ToggleButton
							setHidden={setHidden}
							hidden={hidden}
						/>
					</div>
				</div>
			</th>
		</tr>
	)
})

const PrematchHeader = defaultTemplate(function PrematchHeader(props) {

	const {
		t,
		visibleColumns,

		category,
		tournament,

		setHidden,
		hidden,
	} = props;

	return (
		<tr className="offer__head__row">
			<th className="offer__head__data offer__header--tournament" colSpan="2">
				<div className="offer__header__wrapper">
					<span className="offer__header--tournament__inner" title={category.name + " - " + tournament.name + (tournament.isLive ? " * Live *" : "")}>
						<i className={"offer__header__icon u-icon u-icon--big u-icon--flag u-icon--rounded u-icon--flag--" + category.abrv + " u-mar--right--tny"} />
						<span className="offer__header--tournament__clip">{category.name} - {tournament.name} {tournament.isLive ? `${t('COMMON.LIVE')}` : ""}</span>
					</span>
					{tournament.events.length > 0 && <StatisticsButton tournamentEvents={tournament.events} />}
				</div>
			</th>
			<HeaderQuotes visibleColumns={visibleColumns} />

			{/* Spacer for action buttons */}
			<th className='offer__body__data offer__actions' />

			<th className="offer__head__data offer__toggle">
				<ToggleButton
					setHidden={setHidden}
					hidden={hidden}
				/>
			</th >
		</tr>

	);
})

const HeaderQuotes = observer(function HeaderQuotes(props) {

	const sport = useContext(SportOfferContext);

	const {
		numberOfColumns,
	} = useContext(OfferOptionsContext)

	const {
		configuration: {
			bettingTypeSelectorsStore
		},
	} = useContext(OfferStoreContext);

	const selectors = bettingTypeSelectorsStore.getSportSelector(sport);
	const selectorsArr = Object.values(selectors).slice(0, numberOfColumns);

	return selectorsArr.map((column, index) => (
		<BettingTypeColumn key={column != null ? column.name : index} column={column} />
	))
})

function BettingTypeColumn(props) {

	const {
		column,
	} = props;

	if (column == null) {
		return (
			<th className="offer__head__data offer__header--quote"></th>
		)
	}
	return (
		<th className="offer__head__data offer__header--quote">
			<div className="offer__header--quote__row">
				<Tips tips={column.displayTips} />
			</div>
		</th>
	)
}

function Tips(props) {

	const {
		tips,
	} = props;

	return tips.map(tip => (
		<Tip key={tip} tip={tip} />
	))
}

function Tip(props) {
	const { t } = useTranslation();

	const {
		tip,
	} = props;

	if ([constants.setnrPlaceholder, constants.gamenrPlaceholder, constants.marginPlaceholder].includes(tip)) {
		return <div className="offer__header--quote__col">&nbsp;</div>
	}

	return (
		<div className="offer__header--quote__col">{t([`BETTING_TYPE.TIPS.${tip}`, tip])}</div>
	)
}

function ToggleButton(props) {

	const {
		setHidden,
		hidden,
	} = props;

	return (
		<button
			className="offer__toggle__btn btn btn--sml-square btn--link btn--icon-center"
			type="button"
			onClick={() => setHidden(!hidden)}
		>
			<i className={classNames(
				"u-icon u-icon--xsml",
				{
					'u-icon--arrow-down u-icon--arrow-down--white': !hidden,
					'u-icon--arrow-right u-icon--arrow-right--white': hidden,
				}
			)}
			/>
		</button>
	)
}