import React from 'react';

import { ColumnType } from '@gp/components';

import { OfferColumn, OfferColumnResult } from '../../../../../../common/components/offer/new-offer/common';

export function EventOfferTemplate(props) {

	const {
		columns: {
			columns,
		},
	} = props;

	return columns.map((col, idx) =>
		<Column
			key={col.id}
			columnIndex={idx}
			column={col}
		/>
	)
}

function Column(props) {

	const {
		column,
		columnIndex
	} = props;

	if (column.type === ColumnType.RESULT) {
		return <OfferColumnResult />
	}

	return <OfferColumn columnIndex={columnIndex} {...column.componentProps} />
}