import React from 'react';
import { observer } from 'mobx-react';


import { ColumnTypeSelectorsTemplate, ColumnTypeSelectorsLiveTemplate, ColumnTypeSelectorsTournamentTemplate } from 'themes/common/components/offer/new-offer/common';

export const ColumnTypeSelectors = observer(function ColumnTypeSelectors(props) {
	if (props.isLive) {
		// this is true ONLY for selectors on live page
		return (
			<ColumnTypeSelectorsLiveTemplate
				{...props}
			/>
		)
	}
	else if (props.isTournamentOffer) {
		return (
			<ColumnTypeSelectorsTournamentTemplate
				{...props}
			/>
		)
	}

	return (
		<ColumnTypeSelectorsTemplate
			{...props}
		/>
	);
});