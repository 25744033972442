import React from 'react';
import classnames from 'classnames';

export const LiveMatchResultTemplate = ({ isScore, home, away }) => {
	return (
		<td className={classnames(
			"offer__body__data offer__result",
			{
				"is-score": isScore
			}
		)}>
			<div>{home}</div>
			<div>{away}</div>
		</td>
	);
};