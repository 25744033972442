import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";

export default observer(function SessionExpirePopupTemplate(props) {
    const { t } = useTranslation();
    const sessionExpireMinutes = DefaultSessionDuration / 60;


    const {
        stopSession
    } = props;
    
    return (
        <div className="popup popup--session">
            <div className="popup__card">
                <div className="popup__card__header">
                    {t("SESSION.SESSION_EXPIRE.SESSION_EXPIRED_LABEL")}
                </div>
                <div className="popup__card__body">
                    <div className="message--base message--note">
                        {t("SESSION.SESSION_EXPIRE.SESSION_EXPIRED_MESSAGE_DURATION", { 0: sessionExpireMinutes })}
                    </div>
                </div>
                <div className="popup__card__footer">
                    <button className="btn btn--sml btn--primary btn--icon-left" type="button" onClick={(e) => stopSession(e)}>
                        <i className="u-icon u-icon--sml u-icon--reload--white"></i>
                        <span className="btn__text">{t("COMMON.OK_BTN")}</span>
                    </button>
                </div>
            </div>
        </div>
    )
})