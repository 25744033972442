import React from "react";
import { ActivationErrorTemplate } from "themes/pages/membership/email-activation/components";

export default function ActivationError(props) {
	const { activationState, errorMessage } = props;

	if (
		activationState == null ||
		activationState == true ||
		errorMessage == null
	) {
		return null;
	}

	return <ActivationErrorTemplate errorMessage={errorMessage} />;
}
