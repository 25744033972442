import React from 'react';
import moment from 'moment';

import { defaultTemplate } from '../../../../../common/hoc'

export default defaultTemplate(function (props) {
    const {
        dateTime,
        components = {
            wrapper: React.Fragment,
        },
        shortDayLabel = false,
        t
    } = props;

    const now = moment().local();
    const tomorrow = moment().local().add(1, 'day');

    let dt = moment(dateTime);

    // check for today/tomorrow

    const isToday = dt.isSame(now, 'day');
    const isTomorrow = dt.isSame(tomorrow, 'day');

    let day;
    if (isToday) {
        day = t('OFFER.TIME.TODAY');
    }
    else if (isTomorrow) {
        day = t(`OFFER.TIME.TOMORROW${shortDayLabel ? '_SHORT' : ''}`)
    }
    else {
        day = dt.locale(App.utils.getCurrentCulture()).format('ddd');
    }

    const time = dt.format('HH:mm');

    return (
        <components.wrapper>
            {props.children != null ? props.children({ day, time }) : (
                <React.Fragment>
                    {day}
                    <strong>{time}</strong>
                </React.Fragment>
            )}
        </components.wrapper>
    )
})