import React from 'react';
import { observer, inject } from 'mobx-react';
import { useLocation, withRouter } from 'react-router-dom';
import { SportsTemplate } from 'themes/pages/sports';
import { SportsPromoOfferViewStore } from '../../../offer/stores/pages/sports';

@inject(stores => ({
    stores: stores,
    sportsViewStore: stores.sportsViewStore,
    timezone: stores.stateStore.timezonesStore.selectedTimezone,
    betSlipStore: stores.offerStore.betSlipStore,
    rootStore: stores.offerStore,
}))
@withRouter
@observer
class SportsPromoOffer extends React.Component {

    constructor(props) {
        super(props);

        this.store = new SportsPromoOfferViewStore(this.props.rootStore);
    }

    async componentDidMount() {
        const { match: { period, params: { topTournamentId } } } = this.props;

        await this.store.fetchTopTournamentData(topTournamentId, period);
    }

    async componentDidUpdate(prevProps) {
        const { match: { period, params: { topTournamentId } } } = this.props;
        const { match: { period: prevPeriod, params: { topTournamentId: prevTopTournamentId } } } = prevProps;

        let shouldUpdate = false;

        if (prevPeriod !== period) {
            shouldUpdate = true;
        }

        if (topTournamentId !== prevTopTournamentId) {
            shouldUpdate = true;
        }

        if (shouldUpdate) {
            await this.store.fetchTopTournamentData(topTournamentId, period);
            //this.store.resetNotCollapsed();
        }
    }

    render() {
        return (
            <SportsTemplate {...this.props} sportsViewStore={this.store} />
        );
    }
}

export default SportsPromoOffer;