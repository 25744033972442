// NOTE: THIS IS USED IN PREMATCH LAYOUT ONLY

import React from 'react';
import { Provider, inject } from 'mobx-react';

import Event from './Event';

@inject((s) => ({ offerStore: s.offerStore }))
class EventWrapper extends React.Component {
    constructor(props) {
        super(props);

        this.eventViewStore = new App.offer.pages.events.EventViewStore(props.offerStore, {
            enableBettingTypeGroups: !!props.enableBettingTypeGroups
        });
    }

    render() {
        return (
            <Provider eventViewStore={this.eventViewStore}>
                <Event isLive={this.props.isLive} />
            </Provider>
        );
    }
}

export default EventWrapper;