import React, { useContext } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import {
  MyBetsStoreContext,
  MyBetsBetContext,
} from "../../../../../common/context";
import { useCurrency } from "../../../../../common/hooks";

import { CashoutTypes } from "../../../../../../administration/pages/my-bets";

export default observer(function CashoutActions(props) {
  const bet = useContext(MyBetsBetContext);

  if (bet.cType !== CashoutTypes.JACKTIME || bet.betStatus.abrv !== "open") {
    return null;
  }

  return (
    <>
      <CashoutHistoryButton />
      <CashoutButton />
    </>
  );
});

function CashoutHistoryButton(props) {
  const { t } = useTranslation();
  const bet = useContext(MyBetsBetContext);
  const {
    cashoutStore: { fetchCashoutHistoryForBetSlip },
  } = useContext(MyBetsStoreContext);

  return (
    <button
      className="bet-card__actions__btn bet-card__actions__btn--history btn btn--neutral btn--sml no-print"
      type="button"
      onClick={(e) => fetchCashoutHistoryForBetSlip(bet.id, bet.betSlipNumber)}
    >
      {t("MY_BETS.ITEM.CASHOUT_HISTORY")}
    </button>
  );
}

const CashoutButton = observer(function CashoutButton(props) {
  const bet = useContext(MyBetsBetContext);
  const {
    cashoutStore: { openCashoutModal },
  } = useContext(MyBetsStoreContext);

  if (bet.displayedAmount < 1) {
    return <EmptyCashoutButtonCore />;
  }

  const buttonClasses = classNames(
    "bet-card__actions__btn",
    "bet-card__actions__btn--cashout",
    "btn",
    "btn--sml",
    "btn--light",
    "btn--icon-left",
    "no-print",
    {
      inc: bet.indicator === 1,
      dec: bet.indicator === -1,
    }
  );

  return (
    <button
      className={buttonClasses}
      type="button"
      onClick={(e) =>
        openCashoutModal({
          id: bet.id,
          calculatedCashoutAmount: bet.calculatedCashoutAmount,
          displayedAmount: bet.displayedAmount,
          cType: bet.cType,
          isChangeAccepted: bet.isChangeAccepted,
          isLive: bet.isLive,
        })
      }
    >
      <CashOutIndicator />
      <CashoutButtonCore />
    </button>
  );
});

function EmptyCashoutButtonCore(props) {
  const { t } = useTranslation();

  return (
    <button
      className="card--bet__btn card--bet__btn--cashout btn btn--sml btn--cashout btn--icon-left"
      disabled
    >
      <span>
        <span>{t("MY_BETS.ITEM.CASHOUT")}</span>
      </span>
    </button>
  );
}

const CashoutButtonCore = observer(function CashoutButtonCore(props) {
  const { displayedAmount } = useContext(MyBetsBetContext);
  const { currency } = useCurrency();
  const { t } = useTranslation();

  return (
    <span>
      <span>{t("MY_BETS.ITEM.CASHOUT")}</span>
      &nbsp;
      <span>
        {displayedAmount} {currency}
      </span>
    </span>
  );
});

const CashOutIndicator = observer(function CashOutIndicator(props) {
  const { indicator } = useContext(MyBetsBetContext);

  if (indicator === 0) {
    return null;
  }

  const indicatorClasses = classNames("u-icon", "u-icon--xsml", {
    "u-icon--arrow-up--success": indicator === 1,
    "u-icon--arrow-down--danger": indicator === -1,
  });
  return <i className={indicatorClasses} />;
});
