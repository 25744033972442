import React from 'react';
import { observer, inject, Provider } from 'mobx-react';

import { LiveEventRouteLayoutTemplate } from 'themes/layouts';

@inject(stores => ({
    offerStore: stores.offerStore,
    isOneClickBetActive: stores.offerStore.betSlipStore.betSlipState.isOneClickBetActive,
    onDeactivateOneClickBet: stores.offerStore.betSlipStore.betSlipState.onDeactivateOneClickBet,
    currentScroll: stores.offerStore.betSlipStore.betSlipScrollStore.currentScroll,
}))
@observer
class LiveEventRouteLayout extends React.Component {
    constructor(props) {
        super(props);

        this.eventViewStore = new App.offer.pages.events.EventViewStore(props.offerStore, {
            enableBettingTypeGroups: !!props.enableBettingTypeGroups
        });
    }

    render() {
        return (
            <Provider eventViewStore={this.eventViewStore}>
                <LiveEventRouteLayoutTemplate {...this.props} />
            </Provider>
        )
    }
}

export default LiveEventRouteLayout;