import React, { useEffect, useState } from "react";
import { useLoading } from "../../../common/hooks";
import { emailActivationService } from "./services";
import { EmailActivationTemplate } from "themes/pages/membership/email-activation";
import { ErrorPage } from "../../../common/components/error";

export default function EmailActivation() {

    const [activationState, setActivationState] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const { setIsLoading } = useLoading();

    const urlParams = new URLSearchParams(App.state.history.location.search);

    useEffect(() => {
        App.state.rootStore.titleStore.setTitle('Email activation');
        //on mount scroll to top
        document.querySelector('html, body').scrollTo(0, 0);

        activateUserEmail();
    }, []);

    const activateUserEmail = async () => {
        const mailkey = urlParams.get('mailkey');
        const username = urlParams.get('username');

        try {
            setIsLoading(true);
            const response = await emailActivationService.emailActivation(mailkey, username);
            if (response.success) {
                setActivationState(true);
            }
        }
        catch (error) {
            setActivationState(false);
            setErrorMessage(error?.error?.message);
        }
        finally {
            setIsLoading(false);
        }
    }

    return (
        <ErrorPage>
            <EmailActivationTemplate
                activationState={activationState}
                errorMessage={errorMessage}
            />
        </ErrorPage>
    )
}