import React from 'react';
import { defaultTemplate } from '../../../../../common/hoc';

import { DropDownList } from '@progress/kendo-react-dropdowns';

export default defaultTemplate(function (props) {
    const {
        languageStore: {
            languages,
            currentCulture,
            onChange,
            isLoading
        },
        t
    } = props;

    function itemRenderer(li, itemProps) {
        const itemRender = (
            <React.Fragment>
                <i className={"u-icon u-icon--lang u-icon--lang--" + itemProps.dataItem.abrv} />
                {li.props.children}
            </React.Fragment>
        );

        return React.cloneElement(li, li.props, itemRender);
    }

    function valueRenderer(element, value) {
        if (!value) {
            return element;
        }

        const valueRender = (
            <React.Fragment>
                <i className={"u-icon u-icon--lang u-icon--lang--" + value.abrv} />
                {element.props.children}
            </React.Fragment>
        );

        return React.cloneElement(element, { ...element.props }, valueRender);
    }

    return (
        <DropDownList
            name="culture"
            className="k-dropdown--primary"
            data={languages.peek()}
            loading={isLoading}
            value={currentCulture}
            dataItemKey="abrv"
            textField="name"
            onChange={event => onChange(event.target.value)}
            itemRender={itemRenderer}
            valueRender={valueRenderer}
            popupSettings={{
                className: "k-animation-container--primary"
            }}
        />
    );
});