import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { isNullOrWhitespace } from "../../../../../../../utils/common/helpers";
import { PasswordInput } from "../../../../../../common/components/inputs";

export default function PasswordStrenghtBarTemplate(props) {
	const { t } = useTranslation();
	const {
		onChangeInputPassword,
		passwordPattern,
		progressCounter,
		isPasswordRequired,
	} = props;

	const passwordPopupMsg = (
		<div>
			<p className="u-type--tny">
				{t(
					"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_REQUIREMENTS_VALID"
				)}
			</p>
			<ul>
				<li className="u-type--tny">
					{t(
						"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_REQUIREMENT_1",
						{ 0: "5" }
					)}
				</li>
				<li className="u-type--tny">
					{t(
						"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_REQUIREMENT_4"
					)}
				</li>
				<li className="u-type--tny">
					{t(
						"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_REQUIREMENT_2"
					)}
				</li>
				<li className="u-type--tny">
					{t(
						"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_REQUIREMENT_3"
					)}
				</li>
			</ul>
			<p className="u-type--tny">
				{t("specific:USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_TIPS")}
			</p>
			<ul>
				<li className="u-type--tny">
					{t("USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_TIPS_3")}
				</li>
				<li className="u-type--tny">
					{t("USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_TIPS_4")}
				</li>
				<li className="u-type--tny">
					{t("USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_TIPS_5")}
				</li>
			</ul>
		</div>
	);
	const progressClass = classNames("progress", [
		`progress--${progressCounter}`,
	]);

	return (
		<>
			<PasswordInput
				name="password"
				className="form__field row"
				label="MEMBERSHIP.ACCOUNT_ACTIVATION.NEW_PASSWORD_LABEL"
				placeholder="MEMBERSHIP.ACCOUNT_ACTIVATION.NEW_PASSWORD_PLACEHOLDER"
				onChange={onChangeInputPassword}
				pattern={{
					value: passwordPattern,
				}}
				popoverMsg={passwordPopupMsg}
				required={isPasswordRequired}
			/>
			<div className="form__field row">
				<div className="col-sml-4">
					<label htmlFor="pwdStrength" className="form__field__label">
						{t(
							"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_STRENGTH"
						)}
					</label>
				</div>
				<div className="col-sml-7">
					<progress
						className={progressClass}
						max="100"
						value={progressCounter}
					></progress>
				</div>
			</div>
		</>
	);
}
