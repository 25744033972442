import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export function useFixBody(useFixedPosition, isOpened, elementId) {
    const { pathname } = useLocation();

    useEffect(() => {
        if (pathname.includes('live')) {
            return;
        }

        if (isOpened) {
            App.utils.fixBody(useFixedPosition, elementId);
        }
        else {
            App.utils.resetBody(useFixedPosition, elementId);
        }

        return () => App.utils.resetBody(useFixedPosition, elementId);
    }, [isOpened]);
}