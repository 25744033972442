import React from 'react';
import PropTypes from 'prop-types';
import { defaultTemplate } from '../../../../../common/hoc';

const EmptyStateTemplate = function (props) {
    return (
        <div className={props.styles.wrapper}>
            <p>
                {props.children}
            </p>
        </div>
    )
};

EmptyStateTemplate.propTypes = {
    styles: PropTypes.shape({ wrapper: PropTypes.string, img: PropTypes.string }),
};

EmptyStateTemplate.defaultProps = {
    style: {
        wrapper: "",
    }
};

export default defaultTemplate(EmptyStateTemplate);