import { action } from 'mobx';
import { ReuseBetSlipStore as BaseStore } from './ReuseBetSlipStore.js';
export class ReuseBetSlipStore extends BaseStore {
    
    constructor(rootStore){
        super(rootStore);
    };
    
    @action.bound
    async reuseBetSlip({ id, isLive }) {
        await this.rootStore.betSlipStore.getSlipAndMap(id);

        if (isLive) {
            App.state.redirect(`/${App.utils.getCurrentCulture()}/live/events`);
        }
        else {
            App.state.redirect(`/${App.utils.getCurrentCulture()}/sports/full`);
        }

        this.closeReuseBetSlipModal();
    }
}