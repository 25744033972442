import React from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { LiveRouteLayoutTemplate } from 'themes/layouts';

@inject(stores => {
    return {
        rootStore: stores.offerStore,
    }
})
@observer
@withRouter
class LiveRouteLayout extends React.Component {
    componentDidMount() {
        const urlParams = new URLSearchParams(this.props.location.search);

        if (urlParams.has('isFromRegister')) {
            if (urlParams.get('isFromRegister') === 'true') {
                this.props.setDisplayRegisterModal(true);
            }
        }
    }

    render() {
        return <LiveRouteLayoutTemplate {...this.props} />
    }
}

export default LiveRouteLayout;