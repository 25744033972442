import React from "react";
import { useTranslation } from "react-i18next";

export default function LoginErrorMessageTemplate(props) {
    const { t } = useTranslation();
    const { loginError } = props;
    if (loginError == null) {
        return null;
    }

    if (loginError?.error?.errorCode == 400168) {
        return (
            <div className="message--base message--session">
                {t(`MEMBERSHIP.LOGIN.ERR_HANDLING.${loginError?.error?.message}`, {
                    0: loginError?.error?.errorDuration,
                    1: loginError?.error?.errorFailedAttempts,
                })}
            </div>
        )
    } else if (loginError.error.errorCode == 400166) {
        return (
            <div className="message--base message--session">
                {t(`MEMBERSHIP.LOGIN.ERR_HANDLING.${loginError?.error?.message}`, {
                    0: loginError?.error?.errorFailedAttempts,
                    1: loginError?.error?.errorDuration,
                })}
            </div>
        )
    }

    return (
        <div className="message--base message--session">
            {t(`MEMBERSHIP.LOGIN.ERR_HANDLING.${loginError?.error?.message}`)}
        </div>
    )
}
