import { DateTime } from 'luxon';

// TODO AccountStatmentSercive duplicate
export const betPeriods = [
    {
        name: "TODAY",
        value: "today",
        getTimeInterval: () => {
            return {
                from: DateTime.now().startOf('day').toUTC().toString(),
                to: DateTime.now().toUTC().toString(),
            };
        },
    }, {
        name: "LAST_3_DAYS",
        value: "last3days",
        getTimeInterval: () => {
            return {
                from: DateTime.now().minus({ days: 3 }).startOf('day').toUTC().toString(),
                to: DateTime.now().toUTC().toString(),
            };
        },
    }, {
        name: "LAST_7_DAYS",
        value: "last7days", getTimeInterval: () => {
            return {
                from: DateTime.now().minus({ days: 7 }).startOf('day').toUTC().toString(),
                to: DateTime.now().toUTC().toString(),
            };
        },
    }, {
        name: "LAST_14_DAYS",
        value: "last14days", getTimeInterval: () => {
            return {
                from: DateTime.now().minus({ days: 14 }).startOf('day').toUTC().toString(),
                to: DateTime.now().toUTC().toString(),
            };
        },
    }, {
        name: "LAST_30_DAYS",
        value: "last30days", getTimeInterval: () => {
            return {
                from: DateTime.now().minus({ days: 30 }).startOf('day').toUTC().toString(),
                to: DateTime.now().toUTC().toString(),
            };
        },
    }, {
        name: "LAST_60_DAYS",
        value: "last60days", getTimeInterval: () => {
            return {
                from: DateTime.now().minus({ days: 60 }).startOf('day').toUTC().toString(),
                to: DateTime.now().toUTC().toString(),
            };
        },
    }, {
        name: "CUSTOM",
        value: "custom", getTimeInterval: () => {
            return null;
        },
    }
]