import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { EventContext } from '@gp/components';

import { Time, Timezone } from '../../../../../../common/components/time';

export default observer(function EventTimeTemplate(props) {

    const { } = props;

    const event = useContext(EventContext);

    if (event.isLive) {
        return <Time time={event.matchTime?.displayTime} />
    }

    return (
        <Timezone dateTime={event.startTime}>
            {({ day, time }) => (
                <React.Fragment>
                    {day} {time}
                </React.Fragment>
            )}
        </Timezone>
    )
})