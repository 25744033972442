import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { ResultDataTabs, ResultTables, ResultTablesSelected } from '../../../../../pages/results/components';

export default observer(function ResultDataWrapperTemplate(props) {
    const {
        selectedTournaments
    } = props;

    const { t } = useTranslation();

    return (
        <div className="l--main__content col col-sml-10 u-padd--left--reset">
            <h1 className="title--primary">{t('RESULTS.TITLE')}</h1>

            <div id="results-table">
                {selectedTournaments.length === 0 ? (
                    <>
                        <ResultDataTabs />
                        <ResultTables />
                    </>
                ) :
                (
                    <ResultTablesSelected />
                )}
            </div>
        </div>

    )
})