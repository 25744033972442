import React, { useState } from "react";
import { HeaderLockButtonTemplate } from "themes/common/components/header/components";
import { UserDataService } from "../../../../../administration/services/common";
import { useNotification } from "../../../hooks";

const userDataService = new UserDataService();
export default function HeaderLockButton(props) {
    const { showError } = useNotification();
    const [lockResponse, setLockResponse] = useState(null);

    const create24hLock = async () => {
        try {
            const response = await userDataService.creat24hLock();
            setLockResponse(response);
        } catch (error) {
            console.error(error)
            showError("USER.ACCOUNT_SETTINGS.ERR_HANDLING.GENERIC_ERROR")
        }
    }

    return <HeaderLockButtonTemplate
        create24hLock={create24hLock}
        lockResponse={lockResponse}
        setLockResponse={setLockResponse}
        {...props}
    />
}