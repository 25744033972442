import {
	ErrorResponse,
	Response,
} from "../../../../../administration/services/common";
import { AccountLockService as AccountLockBaseService } from "./AccountLockService.js";

export class AccountLockService extends AccountLockBaseService {
	constructor() {
		super();
	}

	async getAccountClosureViewData(lookupOptions) {
		try {
			const user = await this.userDataService.getUserInfo();

			const durationTypes = await this.getAccountLockDurationLookups();
			const reasonTypes = await this.getAccountLockReasonTypesLookups();
			const accountClosureViewModel = {
				data: {
					explanation: "",
					lockDurationId: "",
					lockReasonId: "",
					IsPromoApproved: false,
					password: "",
				},
				durationTypes: [],
				reasonTypes: [],
				isLocked: false,
				isPermanentLocked: false,
				isAdminLock: false,
				accountLockStartDate: "",
				accountLockEndDate: "",
				accountLockDurationTypeId: "",
			};
			accountClosureViewModel.durationTypes = durationTypes.item
				.map((x) => {
					return {
						id: x.id,
						name: x.name,
						abrv: x.abrv,
						units: x.units,
					};
				})
				.filter((y) => {
					return lookupOptions.durationAbrvs.includes(y.abrv);
				});

			accountClosureViewModel.reasonTypes = reasonTypes.item
				.map((x) => {
					return {
						id: x.id,
						abrv: x.abrv,
						name: x.name,
					};
				})
				.filter((y) => {
					return lookupOptions.reasonAbrvs.includes(y.abrv);
				});

			const reasonTypeNone = reasonTypes.item.filter(
				(x) => x.abrv == "none"
			);

			accountClosureViewModel.isAdminLock = user.isAdminLock;
			const reasonTypeIdNone = reasonTypeNone[0];
			if (user.userAccountLockReasonTypeId != reasonTypeIdNone.id) {
				accountClosureViewModel.isLocked = true;
				const userAccountLockDurationType = durationTypes.item.filter(
					(x) => x.id == user.userAccountLockDurationTypeId
				);
				if (userAccountLockDurationType[0].abrv == "permanent") {
					accountClosureViewModel.isPermanentLocked = true;
				}
				accountClosureViewModel.accountLockStartDate =
					user.userAccountLockStartDate;
				accountClosureViewModel.accountLockEndDate =
					user.userAccountLockEndDate;
				accountClosureViewModel.accountLockDurationTypeId =
					user.userAccountLockDurationTypeId;
			}
			return new Response(accountClosureViewModel);
		} catch (error) {
			if (error.message == "Unauthorized") {
				return new ErrorResponse();
			}
			throw new ErrorResponse({ message: "GENERIC_ERROR" });
		}
	}

	async createAccountLock(model) {
		const accountClosureModel = {
			userAccountLockExplanation: model.explanation,
			userAccountLockDurationTypeId: model.lockDuration,
			userAccountLockReasonTypeId: model.lockReason,
			agencyUserIds: [
				{
					agencyId: App.utils.getAgencyId(),
					userId: App.state.rootStore.userAuthStore.user.id,
				},
			],
			password: model.password,
			isPromoApproved: false,
		};

		const dataUpdateModel = {
			isDataUpdated: false,
			isPasswordValid: false,
			isUserLockedOut: false,
		};

		try {
			await this.accountLockDataService.createAccountLock(
				dataUpdateModel,
				accountClosureModel
			);
			return new Response();
		} catch (error) {
			let errorMsg;

			if (error.message == "Unauthorized") {
				return new ErrorResponse();
			}

			if (error?.data.errorCode == 400176) {
				errorMsg = "INVALID_PASSWORD";
			} else if (error?.data?.errorCode == 400175) {
				await this.userDataService.logoutUser();
				window.location.assign(
					`/${App.utils.getCurrentCulture()}/auth/login?isSessionTerminated=true`
				);
				return new ErrorResponse();
			} else {
				errorMsg = "ACCOUNT_LOCK_ERR";
			}

			throw new ErrorResponse({ message: errorMsg });
		}
	}
}
