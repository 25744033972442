import React, { useContext, useEffect } from "react";
import { observer } from 'mobx-react';

import { MyBetsStoreContext } from "../../../../../common/context";
import { UserAccountType } from "../../../../../common/constants";

export default observer(function OpenBetsFilterForm(props) {

    const {
        onlineAccountTypeId,
        userAccountTypes,

        disposeCurrentMyBets,
        myBetsFilterStore: {
            setRawFilterData,
            handleHistoryBack
        }
    } = useContext(MyBetsStoreContext);

    /**
     * On mount submit default request
     */
    useEffect(() => {
        if (App.state.history.action === 'POP') {
            handleHistoryBack();
        }

        if (
            App.state.history.location.search != null &&
            App.state.history.location.search !== ''
        ) {
            return;
        }

        disposeCurrentMyBets();
        setRawFilterData({
            betStatusIds: 'open'
        });
    }, [])

    // TODO this currently displays nothing and is only used to apply default filter on bets type change
    return null;
})