import React from 'react';
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import { FormInputField } from '../../../../../../common/components/inputs';
import { EmailAvailabilitySectionTemplate } from '.';

export default function EmailChangeNewEmailTemplate(props) {

    const { t } = useTranslation();
    const { formState: { errors } } = useFormContext();

    const error = get(errors, "newEmail");
    const { emailPattern, newEmail } = props;

    return (
        <>
            <FormInputField
                className="form__field row"
                label="USER.ACCOUNT_SETTINGS.EMAIL_CHANGE.NEW_EMAIL_LABEL"
                name="newEmail"
                pattern={{
                    value: emailPattern
                }}
                maxLength={150}
                required
            />

            <div className="form__field row">
                <div className="col-sml-4">
                    <div className="form__field__label">{t('USER.ACCOUNT_SETTINGS.EMAIL_CHANGE.EMAIL_ADDRESS_AVAILABILITY')}</div>
                </div>

                <EmailAvailabilitySectionTemplate
                    newEmail={newEmail}
                    error={error}
                />

            </div>

        </>
    )
}

