import { processDirtyFields } from "../../../../../pages/administration/account-lock/utils";

export const changeIdToName = (data, reasonTypes, sortDurationTypes) => {
    const changedData = data.map(x => {
        const getName = reasonTypes.find(item => item.id === x.value) || sortDurationTypes.find(duration => duration.id === x.value);
        if (getName && getName.name) {
            x.value = getName.name;
        }
        return x;
    })

    return changedData;
}

export const mapDataForPasswordConfirmation = (data, dirtyFields, reasonTypes, sortDurationTypes) => {
    const processedData = processDirtyFields(data, dirtyFields);
    const dataEntries = Object.entries(processedData);

    let modifiedEntry;
    const mappedData = dataEntries.map(entry => {
        const modifiedEntryStart = entry[0].slice(0, 4);
        const modifiedEntryEnd = entry[0].slice(4);
        if (modifiedEntryStart.toLowerCase() == "lock") {
            modifiedEntry = modifiedEntryStart + "_" + modifiedEntryEnd + "_LABEL";
        } else {
            modifiedEntry = entry[0].toUpperCase() + "_LABEl"
        }
        return {
            "name": 'USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.' + modifiedEntry.toUpperCase(),
            "value": entry[1]
        }
    })

    const getIDValues = changeIdToName(mappedData, reasonTypes, sortDurationTypes);
    return getIDValues;
}