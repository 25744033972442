import React, { useContext, useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react';
import { WindowScroller, AutoSizer, List } from 'react-virtualized';
import classNames from 'classnames';

import { OfferStoreContext, SportOfferContext, EventContext } from '@gp/components';
import { getOfferColumns } from '@gp/components';

import { SportHeader, Event } from '../../../../../../common/components/offer/new-offer';

const ROW_HEIGHT = 30;

export const SportTemplate = observer((props) => {
	const [visible, setVisible] = useState(true);
	const viewStore = useContext(OfferStoreContext);
	const wsRef = useRef();

	useEffect(() => {
		wsRef.current.updatePosition();
	}, [visible]);

	const events = props.sport.events || [];

	const _renderEventRow = (rowProps) => {
		const event = events[rowProps.index];

		return (
			<div key={event.id} style={rowProps.style}>
				<EventContext.Provider key={event.id} value={event}>
					<Event headers={props.sport.headers} isAlternate={rowProps.index % 2 === 0} />
				</EventContext.Provider>
			</div>
		)
	}

	const _getRowHeight = ({ index }) => {
		const event = events[index];
		const eventKeys = viewStore.eventKeysMap.get(event.id);

		// base height for event row
		let height = ROW_HEIGHT;

		if (eventKeys == null || eventKeys.size === 0) {
			return height;
		}
		else {
			const secColumns = getOfferColumns(Array.from(eventKeys.values()), props.sport.headers.secondary);

			if (!secColumns.isEmpty) {
				height += ROW_HEIGHT;
			}
		}

		return height;
	}

	return (
		<SportOfferContext.Provider value={{
			abrv: props.sport.abrv,
			id: props.sport.id,
			name: props.sport.name,
			sortOrder: props.sport.sortOrder,
			headers: props.sport.headers,
			isLive: true
		}}>
			<SportHeader
				eventCount={props.sport.eventCount}
				sport={props.sport}
				headers={props.sport.headers}
				visible={visible}
				onShowHide={() => setVisible(!visible)}
			/>
			<div className={classNames('offer__body', { 'u-display--none': !visible })}>
				<WindowScroller ref={wsRef}>
					{(virtualProps) => (
						<>
							{visible ? (
								<List
									autoHeight
									height={virtualProps.height}
									width={1092}
									isScrolling={virtualProps.isScrolling}
									onScroll={virtualProps.onChildScroll}
									overscanRowCount={6}
									rowHeight={_getRowHeight}
									rowCount={events.length}
									rowRenderer={_renderEventRow}
									scrollTop={virtualProps.scrollTop}
								/>
							) : null}
						</>
					)}
				</WindowScroller>
			</div>
		</SportOfferContext.Provider>
	)
});