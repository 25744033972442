import React, { useContext } from "react";
import { observer } from "mobx-react";
import { maxBy } from "lodash";

import { EventContext, SportOfferContext } from "@gp/components";

import { OfferColumnResultTemplate } from "themes/common/components/offer/new-offer/common";

const pauseStatuses = [
	"not-started",
	"first-break",
	"second-break",
	"third-break",
	"fourth-break",
	"fifth-break",
	"sixth-break",
	"ended",
];

const pauseSkipSports = ["volleyball", "beach-volley"];

const notRoundedSports = ["tennis"];

/**
 * Formats game score
 * @param {string} input
 * @returns {string} formatted game score
 */
const formatGameScore = (input) =>
	input.startsWith("g") ? input.substr(1) : input.toUpperCase();

export const OfferColumnResult = observer((props) => {
	const event = useContext(EventContext);
	const sport = useContext(SportOfferContext);

	const isRounded = !notRoundedSports.includes(sport.abrv);

	let gameScore;
	if (sport.abrv === "tennis") {
		gameScore = { home: "0", away: "0" };
		if (event.result?.currentGameScore) {
			if (event.result?.currentGameScore.home) {
				gameScore.home = formatGameScore(
					event.result?.currentGameScore.home
				);
			}
			if (event.result?.currentGameScore.away) {
				gameScore.away = formatGameScore(
					event.result?.currentGameScore.away
				);
			}
		}

		if (event.result?.tiebreak) {
			if (event.result?.tiebreakScore) {
				gameScore.home = event.result?.tiebreakScore.home || "0";
				gameScore.away = event.result?.tiebreakScore.away || "0";
			} else {
				gameScore.home = "0";
				gameScore.away = "0";
			}
		}
	}

	let lastPeriodScore;
	if (
		pauseSkipSports.includes(sport.abrv) &&
		pauseStatuses.includes(event.matchStatus)
	) {
		lastPeriodScore = {
			home: "-",
			away: "-",
		};
	} else {
		lastPeriodScore = maxBy(event.result?.scorePerPeriod, "periodNumber");
	}

	if (lastPeriodScore == null) {
		return <OfferColumnResultTemplate />;
	}

	return (
		<OfferColumnResultTemplate
			serve={event.result?.serve}
			currentScore={event.currentScore}
			gameScore={gameScore}
			lastPeriodScore={lastPeriodScore}
			sport={sport}
			isRounded={isRounded}
		/>
	);
});
