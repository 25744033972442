import React from "react";
import { useFormContext } from "react-hook-form";
import { isNullOrWhitespace } from "../../../../../utils/common/helpers";
import { useNotification, useLoading } from "../../../../common/hooks";
import { PersonalDataPayPinSectionTemplate } from "themes/pages/administration/personal-data/components";

export default function PersonalDataPayPinSectin(props){

    const { setValue, getValues, resetField, reset } = useFormContext();
    const { showSuccess, showError } = useNotification();
    const { setIsLoading } = useLoading();

    const { 
        payPin,
        updateUserPin, 
        getNewPayPin,
        isEditing,
        setIsEditing
    } = props;

    if (isNullOrWhitespace(payPin)) {
        return null;
    }

    const handleSavePin = async () => {
        const currPayPin = getValues('payPin');
        try {
            setIsLoading(true);
            const response = await updateUserPin(currPayPin);
            if(response.success == true){
                setIsEditing(0);
                resetField('payPin', {defaultValue : currPayPin});
                showSuccess('USER.ACCOUNT_SETTINGS.PERSONAL_DATA.PERSONAL_DATA_UPDATE_SUCCESS')
            }
        }
        catch (error) {
            showError('USER.ACCOUNT_SETTINGS.ERR_HANDLING.' + error?.error?.message);
        }
        finally {
            setIsLoading(false);
        }
    }

    const handleCancelPin = () => {
        setIsEditing(0);
        reset();
    }

    const getNewPin = () => {
        setIsEditing(3);
        reset();
        const newPin = getNewPayPin();
        setValue('payPin', newPin.toString());
    }

    return <PersonalDataPayPinSectionTemplate 
                handleSavePin = {handleSavePin}
                handleCancelPin = {handleCancelPin}
                getNewPin = {getNewPin}
                isEditing = {isEditing}
            />
}