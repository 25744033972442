import React from "react";
import { Switch, Route } from "react-router-dom";
import { ErrorPage } from "../../../common/components/error";
import {
	NoMatchRoute,
	RouteElement,
} from "../../../common/components/navigation";
import {
	Login,
	AccountActivation,
	ForgotPassword,
	ForgotUsername,
	ForcePasswordChange,
	RecoverPassword,
} from "../../../pages/membership";

function MembershipLayoutTemplate() {
	return (
		<>
			<main className="l--full">
				<div className="container container--main">
					<div className="row">
						<div className="l--full__content">
							<Switch>
								{/* TODO: add restriction for logged user to access this route */}

								<RouteElement exact path="/:culture/auth/login">
									<Login />
								</RouteElement>

								<RouteElement
									exact
									path="/:culture/auth/forgot-password"
								>
									<ForgotPassword />
								</RouteElement>

								<RouteElement
									exact
									path="/:culture/auth/password-recovery"
								>
									<RecoverPassword />
								</RouteElement>

								<RouteElement
									exact
									path="/:culture/auth/forgot-username"
								>
									<ForgotUsername />
								</RouteElement>

								<RouteElement
									exact
									path="/:culture/auth/account-activation"
									privateRoute
								>
									<AccountActivation />
								</RouteElement>

								<RouteElement
									exact
									path="/:culture/auth/force-password-change"
									privateRoute
								>
									<ForcePasswordChange />
								</RouteElement>
								<NoMatchRoute />
							</Switch>
						</div>
					</div>
				</div>
			</main>
		</>
	);
}

export default MembershipLayoutTemplate;
