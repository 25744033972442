import React from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormButton } from "../../../../../../common/components/buttons";
import { FormInputFieldCurrency } from "../../../../../../common/components/inputs";
import { Loader } from "../../../../../../common/components/loader";
import {
    useLoading,
    useNotification
} from "../../../../../../common/hooks";

import {
    checkForDuration,
    checkForType,
    formatDateTime
} from "../../../../../../pages/administration/my-limits/utils";

export default function DecreaseLimitSection(props) {

    const {
        methods,
        fetchedData,
        decreaseBettingLimit,
        setFetchedData
    } = props;

    const { t } = useTranslation();
    const {
        showSuccess,
        showWarning
    } = useNotification();

    const {
        setIsLoading,
        isLoading
    } = useLoading();

    const { handleSubmit } = methods;

    const onSuccess = async (data) => {
        try {
            setIsLoading(true);
            const response = await decreaseBettingLimit(data);
            if (response.success == true) {
                setFetchedData(response.data.data);
                showSuccess("USER.ACCOUNT_SETTINGS.MY_LIMITS.DECREASE_SUCCESS");
            }
        } catch (error) {
            showWarning("USER.ACCOUNT_SETTINGS.MY_LIMITS.DECREASE_FAIL");
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            <Loader isLoading={isLoading} />
            <FormProvider {...methods}>
                {fetchedData.length != 0 &&
                    <form className="form" onSubmit={handleSubmit(onSuccess)}>
                        <div className="form__group">

                            <div className="message--base message--note">
                                {t(`USER.ACCOUNT_SETTINGS.MY_LIMITS.${checkForDuration(fetchedData.viewData.limitDurationAbrv)}_${checkForType(fetchedData.viewData.limitTypeAbrv)}_LIMIT_INFO_MESSAGE`, {
                                    0: formatDateTime(fetchedData.viewData.limitDateUpdated),
                                    1: fetchedData.viewData.displayLimitAmount,
                                    2: fetchedData.viewData.currencyDisplayCode,
                                    3: fetchedData.viewData.lostAmount,
                                    interpolation: { escapeValue: false }
                                })}
                            </div>

                            <FormInputFieldCurrency
                                label='USER.ACCOUNT_SETTINGS.MY_LIMITS.LIMIT_AMOUNT_LABEL'
                                name="limitAmount"
                                type="number"
                                className="form__field row"
                                pattern={{
                                    value: /^0*[1-9][0-9]*(\.[0-9]+)?/
                                }}
                                validate={(value) => value < fetchedData.viewData.limitAmount}
                                maxLength={10}
                                defaultValue={fetchedData.viewData.displayLimitAmount}
                                required
                            />

                            <div className="form__field row">
                                <div className="col-sml-11 u-type--right">
                                    <FormButton
                                        className="form__submit btn btn--med btn--secondary"
                                        btnText="USER.ACCOUNT_SETTINGS.MY_LIMITS.LIMIT_DECREASE"
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                }
            </FormProvider>
        </>

    )
}