import { useLocalization } from "@progress/kendo-react-intl";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { LoginTemplate } from "themes/pages/membership";
import { ErrorPage } from "../../../common/components/error";
import { useLoading, useUser } from "../../../common/hooks";
import { useAppContext } from "../../../common/hooks/useAppContext";
import { LoginService } from "./services";

const loginService = new LoginService();

const Login = function Login() {
    const { t } = useTranslation();
    const { culture } = useAppContext();
    const { search } = useLocation();
    const methods = useForm({
        mode: "onChange", defaultValues: {
            password: ""
        }
    });
    const user = useUser();

    const [loginError, setLoginError] = useState(null);
    const { resetField } = methods;

    const { setIsLoading } = useLoading();

    useEffect(() => {
        App.state.rootStore.titleStore.setTitle(t('MEMBERSHIP.LOGIN.TITLE'));
        //on mount scroll to top
        document.querySelector('html, body').scrollTo(0, 0);
        // TODO: this should be handled by user auth store

        App.state.rootStore.userAuthStore.removeUserFromLocalStorage();

        App.state.rootStore.automaticLogoutStore.clearTimer();
        App.state.rootStore.realityCheckStore.clearTimer();
        App.state.rootStore.closePopups();

        //remove authorization cookies

        document.cookie = `authorization=;expires=${new Date().toUTCString()};path=/`;
        document.cookie = `authorization_token=;expires=${new Date().toUTCString()};path=/`;

        return () => setIsLoading(false);
    }, []);


    let returnUrl;
    if (search.includes("returnUrl")) {
        returnUrl = search.substring(search.lastIndexOf("=") + 1)
    }

    const onSuccess = async (data) => {
        try {
            setIsLoading(true);
            const response = await loginService.loginUser(data);
            if (response.success) {
                localStorage.setItem('sln', data.stayLoggedIn);
                user.login(response.data);
                if (response.data.user != null) {
                    await App.state.rootStore.userAuthStore.bettingActivityStore.fetchBettingActivityData();
                }
                if (response.data.accountActivation != null) {
                    user.shouldDisplayAfterLogin = true;
                    if (response.data.accountActivation.activationType === 0) {
                        user.shouldDisplayAccountActivationAfterLogin = true;
                        user.shouldDisplayAcceptNewTermsAfterLogin = true;
                        App.state.history.push(`/${culture}/auth/account-activation`);
                    }
                    else {
                        user.shouldDisplayForcePasswordChangeAfterLogin = true;
                        App.state.history.push(`/${culture}/auth/force-password-change`);
                    }
                }
                else if (response.data.user?.bettingActivityNeeded) {
                    if (App.state.rootStore.userAuthStore.bettingActivityStore.bettingActivityFetchedData.bettingActivity === null && App.state.rootStore.userAuthStore.bettingActivityStore.bettingActivityFetchedData.userActivity === null) {
                        user.shouldDisplayAfterLogin = false;
                        user.shouldDisplayBettingActivityAfterLogin = false;
                        user.resolveBettingActivity();
                        await App.state.rootStore.userAuthStore.bettingActivityStore.updateBettingActivityReview();
                        if (user.user.shouldAcceptTermsAndConditions) {
                            App.state.history.push(`/${culture}/auth/accept-new-terms`);
                        }
                        else {
                            App.state.history.push(`/${culture}/home/full`)
                        }
                    }
                    else {
                        user.shouldDisplayAfterLogin = true;
                        user.shouldDisplayBettingActivityAfterLogin = true;
                        //App.state.rootStore.userAuthStore.bettingActivityStore.isLoading = false;
                        App.state.history.push(`/${culture}/auth/betting-activity`);
                    }
                }
                else if (response.data.user?.shouldAcceptTermsAndConditions) {
                    user.shouldDisplayAfterLogin = true;
                    user.shouldDisplayAcceptNewTermsAfterLogin = true;
                    App.state.history.push(`/${culture}/auth/accept-new-terms`);

                }
                else if (returnUrl != null) {
                    App.state.history.push(`/${culture}${returnUrl}`)
                }
                else {
                    App.state.history.push(`/${culture}/home/full`);
                }
            }
        } catch (error) {
            console.error(error)
            if (error?.error?.userData) {
                if (error.error.userData?.activationToken) {
                    //show popup with user data
                    user.confirmationHandlingStore.confirmationHandlingUserData = error.error.userData;
                    user.confirmationHandlingStore.showConfirmationPopup();
                    setLoginError(null);
                }
                else {
                    //show message about shop
                    resetField("password");
                    setLoginError({
                        error: {
                            errorCode: error.error.errorCode,
                            message: error.error.userData.shopInfo
                        }
                    });
                }
            }
            else {
                resetField("password");
                setLoginError(error);
            }
        } finally {
            if (!user.shouldDisplayAfterLogin) {
                setIsLoading(false);
            }
        }
    }

    return <ErrorPage>
        <LoginTemplate
            onSuccess={onSuccess}
            methods={methods}
            loginError={loginError}
        />
    </ErrorPage>
}
export default observer(Login);