import { observable, action, computed } from 'mobx';

class Queue {
    @observable elements = [];

    @observable lastRemovedElement;

    @computed get isEmpty() {
        return this.size === 0;
    }

    @computed get peek() {
        return this.isEmpty ? undefined : this.elements[0];
    }

    @computed get size() {
        return this.elements.length;
    }

    /**
     * 
     * @param {number} maxItems 0 - unlimited
     * @param {(queueElement, element) => boolean} filterPredicate predicate used to find elements in queue (used in isInQueue method)
     */
    constructor(maxItems = 0, filterPredicate = (a, b) => a === b) {
        this.maxItems = maxItems;
        this.filterPredicate = filterPredicate;

        this.isInQueue = this.isInQueue.bind(this);
        this.isInQueuePredicate = this.isInQueuePredicate.bind(this);
    }

    /**
     * 
     * @param {any} element to add into queue
     * @returns element that was removed if queue is at maxItems length
     */
    @action.bound
    enqueue(element) {
        if (this.maxItems === 0) {
            this.elements.push(element);
        }
        else {
            if (this.size < this.maxItems) {
                this.elements.push(element);
            }
            else {
                this.dequeue();
                this.enqueue(element);
            }
        }
    }

    /**
     * Removes element from queue
     * @returns element that was removed from queue
     */
    @action.bound
    dequeue() {
        const element = this.elements.shift();

        this.lastRemovedElement = element;
    }

    @action.bound
    remove(element) {
        if (this.isEmpty) {
            return;
        }

        const elemIdx = this.elements.findIndex(el => this.filterPredicate(el, element));

        this.lastRemovedElement = this.elements[elemIdx];

        if (elemIdx > -1) {
            this.elements.splice(elemIdx, 1);
        }
    }

    @action.bound
    clear() {
        this.elements = [];
        this.lastRemovedElement = null;
    }

    /**
     * Checks is element is in queue based on filterPredicate
     * @param {*} element 
     */
    isInQueue(element) {
        if (this.filterPredicate) {
            return this.isInQueuePredicate(element, this.filterPredicate);
        }

        return false;
    }

    /**
     * Checks is element is in queue based on passed predicate
     * @param {*} element 
     * @param {(queueElement, element) => boolean} predicate
     */
    isInQueuePredicate(element, predicate) {
        if (element == null || predicate == null) {
            console.error('Element or predicate are null.');
            return;
        }

        return this.elements.findIndex(el => predicate(el, element)) > -1;
    }

    @action.bound
    clearLastRemoved(){
        this.lastRemovedElement = undefined;
    }

}

export default Queue;