import React from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { HighlightOfferTemplate } from 'themes/pages/sports/components';

@inject(stores => ({ offerStore: stores.offerStore }))
@observer
@withRouter
class HighlightsOffer extends React.Component {
    constructor(props) {
        super(props);

        this.viewStore = new App.offer.pages.home.components.HighlightsViewStore(props.offerStore);
    }

    async componentDidMount() {
        await this.viewStore.onInitialize();
    }

    componentWillUnmount() {
        this.viewStore.onDispose();
    }

    render() {
        return (
            <HighlightOfferTemplate
                {...this.props}
                viewStore={this.viewStore}
            />
        );
    }
}

export default HighlightsOffer;