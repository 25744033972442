import React from "react";
import { useFormContext } from "react-hook-form";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import {
	FormInputField,
	DatePicker,
} from "../../../../../../common/components/inputs";
import { FormButton } from "../../../../../../common/components/buttons";

const emailPattern =
	/^[a-zA-Z][\w\.-]*[a-zA-Z0-9]@[a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]\.[a-zA-Z][a-zA-Z\.]*[a-zA-Z]$/;

export default function ForgotUsernameFormSectionTemplate(props) {
	const { handleSubmit } = useFormContext();

	const { t } = useTranslation();

	const { onFormSubmit } = props;

	const maxDate = DateTime.now().minus({ years: 18 }).toJSDate();
	const minDate = DateTime.now().minus({ years: 100 }).toJSDate();

	return (
		<div className="view--med">
			<div className="title--primary">
				{t("MEMBERSHIP.FORGOT_USERNAME.FORGOT_USERNAME")}
			</div>

			<div className="message--sml message--note">
				{t("MEMBERSHIP.FORGOT_USERNAME.FILL_IN_FORM")}
			</div>

			<form className="form" onSubmit={handleSubmit(onFormSubmit)}>
				<FormInputField
					className="form__field row"
					label="MEMBERSHIP.FORGOT_USERNAME.EMAIL"
					required
					placeholder="MEMBERSHIP.FORGOT_USERNAME.EMAIL_PLACEHOLDER"
					name="email"
					maxLength={100}
					pattern={{
						value: emailPattern,
						message: "MEMBERSHIP.FORGOT_USERNAME.INVALID_EMAIL",
					}}
				/>

				<DatePicker
					label="MEMBERSHIP.FORGOT_USERNAME.DOB"
					name="dateOfBirth"
					className="form__field row"
					maxDate={maxDate}
					minDate={minDate}
					required
				/>

				<div className="form__field row">
					<div className="col-sml-4">
						<div className="u-type--xsml u-type--color--negative">
							{t("MEMBERSHIP.FORGOT_USERNAME.CONTACT_ASTERISK")}
						</div>
					</div>
				</div>

				<div className="form__field row">
					<div className="col-sml-11 u-type--right">
						<FormButton
							className="form__submit btn btn--med btn--secondary"
							btnText="MEMBERSHIP.FORGOT_USERNAME.SEND"
						/>
					</div>
				</div>
			</form>
		</div>
	);
}
