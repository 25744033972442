import React, { useEffect, useCallback } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { AcceptNewTermsTemplate } from "themes/pages/membership";
import { ErrorPage } from "../../../common/components/error";
import { useAppContext, useLoading, useNotification, useUser } from "../../../common/hooks";
import { AcceptNewTermsService } from "./services";

const acceptNewTerms = new AcceptNewTermsService();

export default function AcceptNewTerms() {
    const { culture } = useAppContext();
    const methods = useForm({ mode: "onChange" });
    const [acceptNewTermsError, setAcceptNewTermsError] = useState(null);
    const { showError } = useNotification();
    const { setIsLoading } = useLoading();
    const { t } = useTranslation();
    const user = useUser();
    useEffect(() => {
        App.state.rootStore.titleStore.setTitle(t('MEMBERSHIP.ACCEPT_NEW_TERMS.TOC_HEAD'));
        //on mount scroll to top
        document.querySelector('html, body').scrollTo(0, 0);
        setIsLoading(false);

        return () => setAcceptNewTermsError(false);
    }, [])

    const onSuccess = async () => {
        try {
            setIsLoading(true);
            const response = await acceptNewTerms.acceptRegulation();
            if (response.success) {
                user.resolveAcceptNewTerms();
                App.state.history.push(`/${culture}/home/full`);
            }
        } catch (error) {
            setAcceptNewTermsError(error);
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    const logoutUser = async () => {
        try {
            setIsLoading(true);
            await user.logoutUser();
            App.state.history.push(`/${culture}/home/full`);
        } catch (error) {
            console.error(error);
            showError("USER.ACCOUNT_SETTINGS.ERR_HANDLING.GENERIC_ERROR")
        } finally {
            setIsLoading(false);
        }
    }

    const getTermsEffectiveDate = useCallback(() => {
        //Terms effective date is currently hardcoded
        const effectiveDate = DateTime.fromISO("2021-01-05").toLocaleString(DateTime.DATE_SHORT);
        return effectiveDate;
    }, [])

    return (
        <ErrorPage>
            <AcceptNewTermsTemplate
                methods={methods}
                onSuccess={onSuccess}
                logoutUser={logoutUser}
                setAcceptNewTermsError={setAcceptNewTermsError}
                acceptNewTermsError={acceptNewTermsError}
                getTermsEffectiveDate={getTermsEffectiveDate}
            />
        </ErrorPage>
    )
}