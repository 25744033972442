import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { EventType } from "@gp/models";

import {
  MyBetsStoreContext,
  MyBetsBetContext,
} from "../../../../../common/context";
import { DateTime } from "luxon";

export default function BetOfferItem(props) {
  const { offer } = props;

  const { isSystem: isBetSystem, totalNumberOfCombinations } =
    useContext(MyBetsBetContext);

  const isSystem = isBetSystem == null || isBetSystem == "";

  const systemIdStr =
    isSystem == true && totalNumberOfCombinations > 1
      ? offer.systemIdentifier
      : "";
  return (
    <tr
      className={`table--bets__body__row u-type--color--status--${offer.formattedBettingOfferStatus}`}
    >
     <BetOfferItem_Time offer={offer}/>
      <td
        className={`table--bets__body__data table--bets__body__data--char u-type--color--status--${offer.formattedBettingOfferStatus}`}
      >
        {systemIdStr}
      </td>
      <EventDataWrapper offer={offer} />
      <td className="table--bets__body__data table--bets__body__data--fix">
        {offer.isBank === true && (
          <span className="u-type--wgt--bold u-type--color--secondary u-mar--right--sml">
            B
          </span>
        )}
      </td>
      <BetOfferItem_Tip offer={offer} />
      <td className="table--bets__body__data table--bets__body__data--odds">
        {offer.value.toFixed(2)}
      </td>
      <td className="table--bets__body__data table--bets__body__data--results">
        <BetOfferItem_OfferResults offer={offer} />
      </td>
      <td className="table--bets__body__data table--bets__body__data--fix">
        <i
          className={`u-icon u-icon--xsml u-icon--status u-icon--status--${offer.formattedBettingOfferStatus}`}
        />
      </td>
    </tr>
  );
}

function BetOfferItem_Time(props) {

  const {
      offer
  } = props;
  
  const timeStr = !offer.isMulti ? DateTime.fromISO(offer.sportData.eventStartTime)
  .toLocaleString(DateTime.DATETIME_SHORT)
  .split(",")
  .join("") : '';

  return <td className="table--bets__body__data table--bets__body__data--time">
    {timeStr}
</td>
}

function BetOfferItem_Tip(props) {
  const { offer } = props;
  let str = "";
  if (offer.sportData.eventType === EventType.NORMAL) {
    str = `${offer.formattedBettingType} (${offer.formattedBettingTypeTip})`;
  } else {
    str = `${offer.formattedBettingTypeTip}`;
  }
  return (
    <td className="table--bets__body__data table--bets__body__data--bet-type">
      {str}
    </td>
  );
}

function EventDataWrapper(props) {
  const { offer } = props;

  if (offer.sportData.eventType != 0) {
    return <NormalEventData offer={offer} />;
  }

  return <EventData offer={offer} />;
}

function NormalEventData(props) {
  const { offer } = props;

  const eventNameStr =
    offer.hasOwnProperty("isMulti") && offer.isMulti == true
      ? offer.multiEventName
      : offer.sportData.eventName;

  return (
    <td className="table--bets__body__data table--bets__body__data--event">
      <LinkActiveEventsHOC
        eventId={offer.eventId}
        className="table--bets__body__data__link"
        isLive={offer.isLive}
      >
        ({eventNameStr})
      </LinkActiveEventsHOC>
    </td>
  );
}

function EventData(props) {
  const { offer } = props;

  const { t } = useTranslation();
  const {
    isSystem: isSystemBet,
    isLive: isBetLive,
    betStatus,
  } = useContext(MyBetsBetContext);

  let bodyDataClassName =
    "table--bets__body__data table--bets__body__data--event";
  if (!isBetLive || offer.isMulti) {
    bodyDataClassName += " u-type--color--status--" + betStatus;
  }

  return (
    <td className={bodyDataClassName}>
      <LinkActiveEventsHOC
        eventId={offer.eventId}
        className="table--bets__body__data__link"
        isLive={offer.isLive}
      >
        {offer.isMulti ? (
          <div className="table--bets__body__data--event__teams u-padd--left--base">
            {offer.multiEventName}
          </div>
        ) : (
          <div className="table--bets__body__data--event__teams">
            {`${offer.teamOneString} - ${offer.teamTwoString}`}
          </div>
        )}

        {((isSystemBet && isBetLive) || (!isSystemBet && offer.isLive)) &&
          offer.formattedResultAtBetTime != "" &&
          !offer.isMulti && (
            <div className="table--bets__body__data--event__live-score">
              *{t("MY_BETS.ITEM.LIVE_SCORE")}* {offer.formattedResultAtBetTime}{" "}
              {offer.scorePerPeriodAtBetTimeString}
            </div>
          )}
      </LinkActiveEventsHOC>
    </td>
  );
}

function BetOfferItem_OfferResults(props) {
  const { offer } = props;

  if (offer.sportData.result == null || offer.isMulti) {
    return null;
  }

  return (
    <div className="table--bets__body__data--results__wrapper">
      <div className="table--bets__body__data--results__ft">
        {offer.formattedCurrentResult}
      </div>

      {offer.scorePerPeriodString != "" && (
        <div className="table--bets__body__data--results__ht">
          {offer.scorePerPeriodString}
        </div>
      )}
    </div>
  );
}

const LinkActiveEventsHOC = observer(function LinkActiveEventsHOC(props) {
  const { eventId, className, isLive, children } = props;

  const { activeEvents } = useContext(MyBetsStoreContext);

  if (activeEvents.includes(eventId)) {
    const page = isLive ? "live/events/event" : "sports/full/event";
    const url = `/${App.utils.getCurrentCulture()}/${page}/${eventId}`;

    return (
      <Link className={className} to={url}>
        {children}
      </Link>
    );
  }

  return children;
});
