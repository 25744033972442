import {
	accountLockDurationLookup,
	accountLockReasonTypesLookup,
	oasisLockReasonTypesLookup,
	countriesLookup,
} from "../../../../../administration/services/account-settings/account-lock/lookups";
import {
	Response,
	ErrorResponse,
	UserDataService,
} from "../../../../../administration/services/common";
import { AccountLockService as AccountLockDataService } from "../../../../../administration/services/account-settings/account-lock";

export class AccountLockService {
	constructor() {
		this.accountLockDataService = new AccountLockDataService();
		this.userDataService = new UserDataService();
	}

	async getAccountLockDurationLookups() {
		const response = await accountLockDurationLookup.findLookup();
		return response;
	}

	async getAccountLockReasonTypesLookups() {
		const response = await accountLockReasonTypesLookup.findLookup();
		return response;
	}

	async getOasisLockReasonTypesLookups() {
		const response = await oasisLockReasonTypesLookup.findLookup();
		return response;
	}

	async getCountriesLookup() {
		const response = await countriesLookup.findLookup();
		return response;
	}

	async getAccountClosureViewData(lookupOptions) {
		try {
			const user = await this.userDataService.getUserInfo();
			const durationTypes = await this.getAccountLockDurationLookups();
			const reasonTypes = await this.getAccountLockReasonTypesLookups();
			const accountClosureViewModel = {
				data: {
					explanation: "",
					lockDurationId: "",
					lockReasonId: "",
					IsPromoApproved: false,
					password: "",
				},
				durationTypes: [],
				reasonTypes: [],
				isLocked: false,
				isPermanentLocked: false,
				isAdminLock: false,
				accountLockStartDate: "",
				accountLockEndDate: "",
				accountLockDurationTypeId: "",
			};

			accountClosureViewModel.durationTypes = durationTypes.item
				.map((x) => {
					return {
						id: x.id,
						name: x.name,
						abrv: x.abrv,
						units: x.units,
					};
				})
				.filter((y) => {
					return lookupOptions.durationAbrvs.includes(y.abrv);
				});

			accountClosureViewModel.reasonTypes = reasonTypes.item
				.map((x) => {
					return {
						id: x.id,
						abrv: x.abrv,
						name: x.name,
					};
				})
				.filter((y) => {
					return lookupOptions.reasonAbrvs.includes(y.abrv);
				});
			const reasonTypeNone = reasonTypes.item.filter(
				(x) => x.abrv == "none"
			);

			accountClosureViewModel.isAdminLock = user.isAdminLock;
			const reasonTypeIdNone = reasonTypeNone[0];
			if (user.userAccountLockReasonTypeId != reasonTypeIdNone.id) {
				accountClosureViewModel.isLocked = true;
				const userAccountLockDurationType = durationTypes.item.filter(
					(x) => x.id == user.userAccountLockDurationTypeId
				);
				if (userAccountLockDurationType[0].abrv == "permanent") {
					accountClosureViewModel.isPermanentLocked = true;
				}
				accountClosureViewModel.accountLockStartDate =
					user.userAccountLockStartDate;
				accountClosureViewModel.accountLockEndDate =
					user.userAccountLockEndDate;
			}
			return new Response(accountClosureViewModel);
		} catch (error) {
			if (error.message == "Unauthorized") {
				return new ErrorResponse();
			}
			throw new ErrorResponse({ message: "GENERIC_ERROR" });
		}
	}

	async createAccountLock(model) {
		const accountClosureModel = {
			userAccountLockExplanation: model.explanation,
			userAccountLockDurationTypeId: model.lockDuration,
			userAccountLockReasonTypeId: model.lockReason,
			isPromoApproved: false,
			password: model.password,
		};

		const dataUpdateModel = {
			isDataUpdated: false,
			isPasswordValid: false,
			isUserLockedOut: false,
		};

		try {
			const response =
				await this.accountLockDataService.createAccountLock(
					dataUpdateModel,
					accountClosureModel
				);
			if (response == null) {
				return;
			}
			if (response.isUserLockedOut) {
				await App.state.rootStore.userAuthStore.logoutUser();
				App.state.history.push(
					`/${App.utils.getCurrentCulture()}/auth/login?isUserLockedOut=true`
				);
				return new Response();
			}
			if (!response.isPasswordValid) {
				throw new ErrorResponse({ message: "INVALID_PASSWORD" });
			}
			if (accountClosureModel.isPromoApproved) {
				await this.userDataService.promoMaterialsApproval(
					accountClosureModel.isPromoApproved
				);
			}

			return new Response();
		} catch (error) {
			let errorMsg;

			if (error.message == "Unauthorized") {
				return new ErrorResponse();
			}

			if (error.data.errorCode == 400176) {
				errorMsg = "INVALID_PASSWORD";
			} else if (error.data.errorCode == 400175) {
				await App.state.rootStore.userAuthStore.logoutUser();
				App.state.history.push(
					`/${App.utils.getCurrentCulture()}/auth/login?isSessionTerminated=true`
				);
				return null;
			} else {
				errorMsg = "ACCOUNT_LOCK_ERR";
			}
			throw new ErrorResponse({ message: errorMsg });
		}
	}

	// Oassis lock methods

	async getOasisBlockData(lookupOptions) {
		try {
			const viewModel = {
				data: {
					personalDetails: [],
					isPersonalDetailsMissing: false,
					lockDurationId: "",
					reasonCodes: [],
					password: "",
					isLocked: false,
				},
				blockReasons: [],
				blockReasonsDisableOption: null,
				durationTypes: [],
				countries: [],
			};

			const durationTypes = await this.getAccountLockDurationLookups();
			const reasonTypes = await this.getOasisLockReasonTypesLookups();
			const countries = await this.getCountriesLookup();

			viewModel.durationTypes = durationTypes.item
				.map((x) => {
					return {
						id: x.id,
						name: x.name,
						abrv: x.abrv,
						units: x.units,
					};
				})
				.filter((y) => {
					return lookupOptions.oasisDurationAbrvs.includes(y.abrv);
				});

			viewModel.countries = countries.item.map((country) => {
				return {
					id: country.id,
					name: country.name,
				};
			});

			viewModel.blockReasons = reasonTypes.item
				.map((x) => {
					return {
						id: x.id,
						abrv: x.abrv,
						name: x.name,
						code: x.code,
					};
				})
				.filter((y) => {
					return lookupOptions.oasisReasonCodes.includes(y.code);
				});

			if (lookupOptions.oasisReasonCodesDisableCode == "99") {
				let disableOption = [];
				reasonTypes.item.map((x) => {
					if (x.code == "99") {
						disableOption.push({
							id: x.id,
							abrv: x.abrv,
							name: x.name,
							code: x.code,
						});
					}
				});
				viewModel.blockReasonsDisableOption = disableOption[0];
			}
			let personalDetailsArray = [];

			const validateResponse =
				await this.userDataService.validatePlayer();
			if (validateResponse.validationErrors.length != 0) {
				validateResponse.validationErrors.map((missingField) => {
					if (missingField.fieldName) {
						personalDetailsArray.push({
							fieldName:
								missingField.fieldName.charAt(0).toLowerCase() +
								missingField.fieldName.slice(1),
							value: "",
						});
					}
				});

				viewModel.personalDetails = personalDetailsArray;
				viewModel.isPersonalDetailsMissing = true;
			} else {
				viewModel.isPersonalDetailsMissing = false;
			}
			return new Response(viewModel);
		} catch (error) {
			if (error.message == "Unauthorized") {
				return new ErrorResponse();
			}
			throw new ErrorResponse({ message: "GENERIC_ERROR" });
		}
	}

	async createOasisBlock(model) {
		const dataUpdateResponse = {
			isDataUpdated: false,
			isPasswordValid: false,
			isUserLockedOut: false,
		};

		try {
			let oasisTemporaryBlockReq = {
				userAccountLockDurationTypeId: model.lockDurationId,
				exclusionReasonCodes: model.blockReasons,
				password: model.password,
			};

			if (model.isPersonalDetailsMissing) {
				oasisTemporaryBlockReq.missingUserInfo = model.personalDetails;
			}
			const response =
				await this.accountLockDataService.createOasisSelfTemporaryBlock(
					oasisTemporaryBlockReq
				);

			if (response == null || !response.isSuccess) {
				dataUpdateResponse.isDataUpdated = false;
				dataUpdateResponse.isUserLockedOut = false;
				throw new ErrorResponse({ message: "GENERIC_ERROR" });
			} else {
				dataUpdateResponse.isDataUpdated = true;
				dataUpdateResponse.isUserLockedOut = true;
			}

			if (dataUpdateResponse.isDataUpdated) {
				await App.state.rootStore.userAuthStore.logoutUser();
				App.state.history.push(
					`/${App.utils.getCurrentCulture()}/auth/login?isOasisLockedOut=true`
				);
			}
		} catch (error) {
			let errorMsg;

			if (error.message == "Unauthorized") {
				return new ErrorResponse();
			}

			if (error?.data?.errorCode == 400176) {
				errorMsg = "INVALID_PASSWORD";
			} else if (error?.data?.errorCode == 400175) {
				await App.state.rootStore.userAuthStore.logoutUser();
				App.state.history.push(
					`/${App.utils.getCurrentCulture()}/auth/login?isSessionTerminated=true`
				);
			} else {
				errorMsg = "GENERIC_ERROR";
			}

			throw new ErrorResponse({ message: errorMsg });
		}
	}
}
