import React from 'react';
import { inject } from 'mobx-react';

import { SportsLandingTemplate } from 'themes/pages/sports';

@inject(stores => ({
    rootStore: stores.offerStore
}))
class SportsLanding extends React.Component {
    constructor(props) {
        super(props);

        this.viewStore = new App.offer.pages.sports.SportsLandingViewStore(props.rootStore);
    }

    async componentDidMount() {
        await this.viewStore.onInitialize();
    }

    componentWillUnmount() {
        this.viewStore.onDispose();
    }

    render() {
        return (
            <SportsLandingTemplate viewStore={this.viewStore} />
        )
    }
}

export default SportsLanding;