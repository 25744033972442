import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLoading, useNotification } from '../../../../common/hooks';
import { OfferPrintService } from '../../../../pages/offer-print/services';
import { OfferPrintTable } from '../../../../pages/offer-print/components';

export default function OfferPrintPageTemplate(props) {
    const { t } = useTranslation();
    const [offerPrintData, setOfferPrintData] = useState(null);

    const {
        setIsLoading,
    } = useLoading();

    const {
        showError
    } = useNotification();

    useEffect(() => {

        async function fetchData() {
            try {
                setIsLoading(true);
                const response = await OfferPrintService.getOfferPrintData();
                setOfferPrintData(response);
            }
            catch (error) {
                showError(error);
            }
            finally {
                setIsLoading(false);
            }
        }

        fetchData();
    }, []);

    if (offerPrintData === null) {
        return null;
    }
    return (
        <main role="main" class="l--full">
            <div class="container container--main">
            <div class="row">
                    <div class="l--full__content">
                        <div class="view--med">
                            <div class="title--primary">
                                {t('OFFER_PRINT.TITLE')}
                            </div>
                            <div class="row">
                                <div class="col col-sml-6">
                                    <OfferPrintTable data={offerPrintData.decimalOfferPrint} />
                                </div>
                                <div class="col col-sml-6">
                                    <OfferPrintTable data={offerPrintData.hexadecimalOfferPrint} />
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            </div>
        </main>
    )
}