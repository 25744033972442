import { observable, action, runInAction } from 'mobx';

export class ActiveEventsStore {

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @observable activeEventIds = [];

    @action.bound
    async fetchActiveEvents(eventIds) {

        let activeEventIds = [];

        try {
            const activeEventsOffer = await this.rootStore.hub.getOffer({
                paging: {
                    pageNumber: 1,
                    pageSize: 100000,
                },
                filter: {
                    id: {
                        eq: eventIds,
                    },
                    offers: null,
                }
            })

            activeEventIds = activeEventsOffer?.offer?.map(offer => offer.eventId) || [];
        } catch (error) {
            console.error(error);
        } finally {
            runInAction(() => {
                this.activeEventIds = activeEventIds;
            })
        }
    }

    @action.bound
    onDispose() {
        this.activeEventIds = [];
    }
}