import React from 'react';
import { MembershipLayoutTemplate } from 'themes/layouts';

class MembershipLayout extends React.Component {
    render() {
        return (
            <MembershipLayoutTemplate />
        )
    }
}

export default MembershipLayout;