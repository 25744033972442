import React from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { LiveEventSubheaderTemplate } from 'themes/common/components/subheader';

@inject(stores => ({ liveEventSubheaderViewStore: stores.offerStore.liveEventSubheaderViewStore }))
@observer
@withRouter
class LiveSubheader extends React.Component {
    render() {
        return <LiveEventSubheaderTemplate {...this.props} />
    }
}

export default LiveSubheader;