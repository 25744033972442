import React from 'react';
import PropTypes from 'prop-types';
import { BetSlipSubmitValidationErrorsModalTemplate } from 'themes/common/components/bet-slip';

class BetSlipSubmitValidationErrorsModal extends React.Component {
    render() {
        return <BetSlipSubmitValidationErrorsModalTemplate {...this.props} />;
    }
}

BetSlipSubmitValidationErrorsModal.propTypes = {
    data: PropTypes.object,
    onClose: PropTypes.func,
};

export default BetSlipSubmitValidationErrorsModal;