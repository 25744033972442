import { DateTime } from "luxon";

export class FromToDates {
    constructor(period) {
        this.from = "";
        this.to = "";
        this.initialize(period);
    }

    initialize(period) {
        const now = DateTime.now();
        this.to = now.toJSDate().toISOString();
        switch (period) {
            case "last3months":
                this.from = now.minus({ months: 3 }).toJSDate().toISOString();
                break;
            case "last6months":
                this.from = now.minus({ months: 6 }).toJSDate().toISOString();
                break;
            case "last9months":
                this.from = now.minus({ months: 9 }).toJSDate().toISOString();
                break;
            case "last1year":
                this.from = now.minus({ year: 1 }).toJSDate().toISOString();
                break;
        }
    }
}