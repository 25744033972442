import React from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { BetSlipTemplate } from 'themes/common/components/bet-slip';

@inject(stores => ({
    betSlipStore: stores.offerStore.betSlipStore,
    user: stores.stateStore.userAuthStore.user
}))
@observer
@withRouter
class BetSlip extends React.Component {
    async componentDidMount() {
        const urlParams = new URLSearchParams(this.props.location.search)

        if (urlParams.has('bid')) {
            const bid = urlParams.get('bid');

            //this.props.betSlipStore.betSlipState.reset();
            await this.props.betSlipStore.getSlipAndMap(bid);
        }

        await this.props.betSlipStore.onInitialize();
    }

    // async componentDidUpdate(prevProps) {
    //     console.log('UPDATE');

    //     const urlParams = new URLSearchParams(this.props.location.search);
    //     const prevUrlParams = new URLSearchParams(prevProps.location.search);

    //     const currBid = urlParams.get('bid');
    //     const prevBid = prevUrlParams.get('bid');

    //     if (currBid != null && currBid !== prevBid) {
    //         const bid = urlParams.get('bid');

    //         this.props.betSlipStore.betSlipState.reset();
    //         await this.props.betSlipStore.getSlipAndMap(bid);
    //     }
    // }


    render() {
        return <BetSlipTemplate {...this.props} />
    }
}

export default BetSlip;