import { ErrorMessage } from "../../../../../common/components/error";
import { RequiredMark } from "../../../../../common/components/inputs";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

const FormInputFieldTemplate = (props) => {
    const { t } = useTranslation();

    const {
        label,
        name,
        className,
        errorMessage = undefined,
        type = 'text',
        required = false,
        placeholder = undefined,
        field,
        removeSuccessMark,
        inputClassName = null,
        noValidate = false,
        popoverMsg = null,
        ...rest
    } = props;

    const [mouseOverState, setMouseOverState] = useState(false);

    if (noValidate) {
        return (

            <input
                className={inputClassName}
                type={type}
                placeholder={t(placeholder)}
                autoComplete="off"
                id={name}
                maxLength="20"
                name={name}
                {...field}
                {...rest}
            />
        )
    }

    const inputWrappClassName = classNames(
        "col-sml-7",
        {
            "popover popover--bottom": popoverMsg
        }
    )

    return (
        <div className={className}>
            <div className="col-sml-4">
                <label className="form__field__label">
                    {t(label)}
                    <RequiredMark required={required} />
                </label>
            </div>
            <div className={inputWrappClassName}>
                {popoverMsg ?
                    <input
                        className={inputClassName ? inputClassName : "input input--base"}
                        type={type}
                        {...field}
                        {...rest}
                        placeholder={t(placeholder)}
                        onMouseEnter={() => setMouseOverState(true)}
                        onMouseLeave={() => setMouseOverState(false)}
                    />
                    :
                    <input
                        className={inputClassName ? inputClassName : "input input--base"}
                        type={type}
                        {...field}
                        {...rest}
                        placeholder={t(placeholder)}
                    />
                }

                {popoverMsg != null && mouseOverState &&
                    <div className="popover__wrapper popover--bottom__wrapper popover__wrapper--big">
                        <div className="popover__content">
                            {popoverMsg}
                        </div>
                        <span className="popover__arrow popover--bottom__arrow"></span>
                    </div>
                }

            </div>
            <div className="col-sml-1">
                <ErrorMessage removeSuccessMark={removeSuccessMark} name={name} />
            </div>
        </div >
    )
}
export default FormInputFieldTemplate;