import React from "react";
import { observer } from "mobx-react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
	FormInputField,
	PasswordInput,
} from "../../../../../common/components/inputs";
import { PasswordStrengthBar } from "../../../../../pages/membership/account-acitvation/components";
import { Button, FormButton } from "../../../../../common/components/buttons";

export default observer(function ForcePasswordChangeTemplate(props) {
	const { t } = useTranslation();
	const {
		methods,
		onSuccess,
		forcePasswordChangeData,
		logoutUser,
		forcePasswordChangeError,
		setForcePasswordChangeError,
	} = props;

	const passwordPattern = /^[a-zA-Z0-9!#$()@?{}|*+,^.\-+&=_%:;~]{5,40}$/gm;

	const { handleSubmit, getValues } = methods;

	const PasswordPopoverMsg = () => {
		return (
			<div>
				<p className="u-type--tny">
					{t(
						"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_REQUIREMENTS_VALID"
					)}
				</p>
				<ul className="list u-mar--bottom--tny u-padd--left--med">
					<li className="u-type--tny">
						{t(
							"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_REQUIREMENT_1",
							{ 0: "5" }
						)}
					</li>
					<li className="u-type--tny">
						{t(
							"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_REQUIREMENT_4"
						)}
					</li>
					<li className="u-type--tny">
						{t(
							"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_REQUIREMENT_2"
						)}
					</li>
					<li className="u-type--tny">
						{t(
							"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_REQUIREMENT_3"
						)}
					</li>
				</ul>

				<p className="u-type--tny">
					{t("USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_TIPS")}
				</p>
				<ul className="">
					<li className="u-type--tny">
						{t("USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_TIPS_3")}
					</li>
					<li className="u-type--tny">
						{t("USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_TIPS_4")}
					</li>
					<li className="u-type--tny">
						{t("USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_TIPS_5")}
					</li>
				</ul>
			</div>
		);
	};

	return (
		<div className="at-forcePwdChange view--med">
			<div className="title--primary">
				{t("MEMBERSHIP.FORCE_PASSWORD_CHANGE.TITLE_FPC")}
			</div>
			<FormProvider {...methods}>
				<form
					id="forcePasswordForm"
					className="form"
					onSubmit={handleSubmit(onSuccess)}
				>
					<section>
						<div className="form__field row">
							<div className="col-sml-12">
								<div className="message--sml message--note">
									{t(
										"MEMBERSHIP.FORCE_PASSWORD_CHANGE.FPC_DESCRIPTION"
									)}
								</div>
							</div>
						</div>
						<FormInputField
							className="form__field row"
							name="userCardNumber"
							label="MEMBERSHIP.ACCOUNT_ACTIVATION.USER_CARD_NUMBER"
							readOnly
						/>
						<PasswordStrengthBar
							userInfo={[
								forcePasswordChangeData?.userInfo?.userName,
							]}
							passwordPattern={passwordPattern}
							isPasswordRequired={true}
							popoverMsg={<PasswordPopoverMsg />}
						/>
						<div className="form__field row">
							<PasswordInput
								name="newPasswordConfirm"
								className="form__field row"
								label="MEMBERSHIP.ACCOUNT_ACTIVATION.CONFIRM_NEW_PASSWORD_LABEL"
								placeholder="MEMBERSHIP.ACCOUNT_ACTIVATION.CONFIRM_NEW_PASSWORD_PLACEHOLDER"
								pattern={{
									value: passwordPattern,
								}}
								validate={{
									isEqual: (value) =>
										value === getValues("password") ||
										"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.DOES_NOT_MATCH_PWD",
								}}
								required={true}
								popoverMsg={t(
									"MEMBERSHIP.FORCE_PASSWORD_CHANGE.REPEAT_PWD_INFO"
								)}
							/>
						</div>
					</section>

					<div className="form__field row">
						<div className="col-sml-11 u-type--right">
							<Button
								className="at-forcePwdChangeBtnCancel btn btn--med btn--secondary u-mar--right--sml"
								btnText="MEMBERSHIP.BETTING_ACTIVITY.BUTTON_CANCEL"
								onClick={logoutUser}
								type="button"
							/>

							<FormButton
								btnText="MEMBERSHIP.ACCOUNT_ACTIVATION.SAVE_BUTTON"
								className="at-forcePwdChangeBtnSubmit form__submit btn btn--med btn--primary"
							/>
						</div>
					</div>
				</form>
			</FormProvider>
		</div>
	);
});
