import React from "react";
import { inject, observer } from "mobx-react";

import { UserBalanceDisplayTemplate } from "themes/common/components/header/components";

export default inject(stores => ({
    activeAccountTypeId: stores.stateStore.userAuthStore.activeAccountTypeId,
    setActiveUserAccountTypeId: stores.stateStore.userAuthStore.setActiveUserAccountTypeId
}))(observer(function UserBalanceDisplay(props) {
    return <UserBalanceDisplayTemplate {...props} />
}))