import React, { useEffect, useMemo, useCallback } from "react";
import { EmailChangeTemplate } from "themes/pages/administration";
import {
	useNotification,
	useLoading,
	usePasswordConfirmation,
} from "../../../common/hooks";
import { useForm } from "react-hook-form";
import { EmailChangeService } from "./services";
import { useTranslation } from "react-i18next";
import { ErrorPage } from "../../../common/components/error";

const emailChangeServiceInstance = new EmailChangeService();

function EmailChange() {
	const methods = useForm({ mode: "onChange" });
	const { setIsLoading } = useLoading();
	const { setShowPasswordConfirmation } = usePasswordConfirmation();
	const { showError, showSuccess } = useNotification();
	const { t } = useTranslation();

	const { getValues, reset } = methods;

	const urlParams = new URLSearchParams(App.state.history.location.search);

	const emailChangeService = useMemo(() => emailChangeServiceInstance, []);

	useEffect(() => {
		App.state.rootStore.titleStore.setTitle(
			t("USER.ACCOUNT_SETTINGS.NAVIGATION.TITLE_EC")
		);

		//on mount scroll to top
		document.querySelector("html, body").scrollTo(0, 0);
		fetchOrActivateUserEmail(urlParams.get("mailkey"));
		return () => setShowPasswordConfirmation(false);
	}, []);

	const fetchOrActivateUserEmail = async (mailkey = null) => {
		try {
			setIsLoading(true);
			const response = await emailChangeService.getUser(mailkey);
			if (response.success == true) {
				reset(response.data);
				if (mailkey) {
					showSuccess(
						"USER.ACCOUNT_SETTINGS.EMAIL_CHANGE.EMAIL_ACTIVATE_SUCCESS"
					);
				}
			}
		} catch (error) {
			reset(error.error.data);
			showError(
				"USER.ACCOUNT_SETTINGS.EMAIL_CHANGE." + error?.error?.message
			);
		} finally {
			setIsLoading(false);
		}
	};

	const onSubmitData = useCallback(() => {
		setShowPasswordConfirmation(true);
	}, []);

	const onCancel = () => {
		reset();
		setShowPasswordConfirmation(false);
	};

	const onSuccess = useCallback(() => async (password) => {
		try {
			setIsLoading(true);
			const emailChangeModel = {
				NewEmail: getValues("newEmail"),
				Password: password,
			};
			const response = await emailChangeService.changeUserEmail(
				emailChangeModel
			);
			if (response.success == true) {
				reset();
				setShowPasswordConfirmation(false);
				showSuccess([
					{
						message:
							"USER.ACCOUNT_SETTINGS.EMAIL_CHANGE.SUCCESS_EMAIL_CHANGE_ONE",
					},
					{ message: emailChangeModel.NewEmail },
					{
						message:
							"USER.ACCOUNT_SETTINGS.EMAIL_CHANGE.SUCCESS_EMAIL_CHANGE_TWO",
					},
				]);
			}
		} catch (error) {
			if (error?.error?.message) {
				showError(
					"USER.ACCOUNT_SETTINGS.ERR_HANDLING." +
						error?.error?.message
				);
			}
		} finally {
			setIsLoading(false);
		}
	});

	const checkIsEmailAvailable = useCallback(() => async (newEmail) => {
		return emailChangeService.checkIsEmailAvailable(newEmail);
	});

	return (
		<ErrorPage>
			<EmailChangeTemplate
				methods={methods}
				onSubmitData={onSubmitData}
				onCancel={onCancel}
				onSuccess={onSuccess()}
				checkIsEmailAvailable={checkIsEmailAvailable()}
			/>
		</ErrorPage>
	);
}

export default EmailChange;
