import React from "react";
import { observer } from "mobx-react";

import { LiveUpcomingTemplate } from "themes/pages/live";

@observer
class LiveUpcoming extends React.Component {
	constructor(props) {
		super(props);
	}

	async componentDidMount() {
		App.state.rootStore.titleStore.setTitle(
			App.state.localizationService.t("LIVE.UPCOMING_LIVE_EVENTS")
		);

		await this.props.liveUpcomingViewStore.initializeStore();

		this.props.liveViewStore?.onInitialize();
	}

	componentWillUnmount() {
		this.props.liveUpcomingViewStore.onDispose();
		this.props.liveViewStore?.onDispose();
	}

	render() {
		return <LiveUpcomingTemplate {...this.props} />;
	}
}
export default LiveUpcoming;
