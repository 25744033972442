import React from "react";
import { useTranslation } from "react-i18next";
import { FormButton } from "../../../../../../common/components/buttons";
import { FormSelectField, TextareaField } from "../../../../../../common/components/inputs";
import { mapArray } from "../../../../../../pages/administration/account-lock/utils";

export default function NoLockSection(props) {

    const { t } = useTranslation();
    const {
        fetchedData,
        sortDurationTypes,
        methods,
        onSubmitData,
        reasonTypes
    } = props;

    const {
        handleSubmit,
    } = methods;

    return (
        <>
            {
                fetchedData != null &&

                <form className="form" onSubmit={handleSubmit(onSubmitData)}>
                    <section className="form__group">
                        <div className="message--sml message--note">
                            {t("specific:USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.NO_LOCK")}
                        </div>
                        <FormSelectField
                            className="form__field row"
                            name="lockDuration"
                            placeholder={t("USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.LOCK_DURATION_PLACEHOLDER")}
                            label={t("USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.LOCK_DURATION_LABEL")}
                            dropdownData={mapArray(sortDurationTypes)}
                            required
                        />
                        <FormSelectField
                            className="form__field row"
                            name="lockReason"
                            placeholder={t("USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.LOCK_REASON_PLACEHOLDER")}
                            label={t("USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.LOCK_REASON_LABEL")}
                            dropdownData={mapArray(reasonTypes)}
                            required
                        />

                        <TextareaField
                            name="explanation"
                            label="USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.EXPLANATION_LABEL"
                        />

                        <div className="form__field row">
                            <div className="col-sml-11 u-type--right">
                                <FormButton
                                    className="form__submit btn btn--med btn--secondary"
                                    btnText="USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.LOCK_ACCOUNT"
                                />
                            </div>
                        </div>
                    </section>
                </form>
            }
        </>
    )
}