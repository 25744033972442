import React from "react";
import { FormSelectFieldTemplate } from "themes/common/components/inputs";
import { useFormContext} from "react-hook-form";

export default function(props){
    const form = useFormContext();
    const field = form.register(props.name, {
        required: { value : props.required, message: props.errorMessage || 'COMMON.REQUIRED_FIELD'}
    });
    return <FormSelectFieldTemplate {...props} field = {field}/>
}