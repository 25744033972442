import React, { useState } from 'react';
import { observer } from 'mobx-react';

import { SportOfferContext } from '@gp/components';

import { PrematchSportTemplate } from 'themes/common/components/offer/new-offer';

export function PrematchSport(props) {
	const [hidden, setHidden] = useState(false);

	// sport is actually SportOffer model (see eventsInSports in the view store)
	return (
		<SportOfferContext.Provider value={props.sport}>
			<PrematchSportTemplate
				{...props}
				hidden={hidden}
				setHidden={setHidden}
			/>
		</SportOfferContext.Provider>
	);
}