import React from "react";
import { useTranslation } from "react-i18next";
import { formatDateTime } from "../../../../../../pages/administration/automatic-logout/utils";

export default function SettingsMessagePartial(props) {
	const { t } = useTranslation();

	const { fetchedData } = props;

	return (
		<div className="message--base message--note">
			{fetchedData != null &&
				t(
					`USER.ACCOUNT_SETTINGS.AUTOMATIC_LOGOUT.${fetchedData.message.settingsMessage}`,
					{
						interpolation: { escapeValue: false },
						0: fetchedData.message.logoutIntervalTime,
						1: fetchedData.message.bettingTimeoutTime || "-",
						2: formatDateTime(fetchedData.message.autoLogoutTime),
					}
				)}
		</div>
	);
}
