import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { EmailChangeFormSectionTemplate } from 'themes/pages/administration/email-change/components';

export default function EmailChangeFormSection(props) {

    const { checkIsEmailAvailable } = props;

    const { trigger, getFieldState } = useFormContext();

    useEffect(() => {
        if (getFieldState('newEmail').isTouched && getFieldState('repeatEmail').isTouched) {
            trigger('repeatEmail');
        }
    }, [useWatch({ name: 'newEmail' })]);

    const checkForEmailAvailiability = async (value) => {
        const response = await checkIsEmailAvailable(value);
        return response.data;
    }

    return <EmailChangeFormSectionTemplate
        checkForEmailAvailiability={checkForEmailAvailiability}
        {...props}
    />
}