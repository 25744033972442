import { observable, computed, action, runInAction, reaction } from 'mobx';
import { DateTime } from 'luxon';

import { ReportApiService } from '../../../services'

export class ReportsStore {

    constructor(rootStore, myBetsStore) {
        this.rootStore = rootStore;
        this.myBetsStore = myBetsStore;

        this.reportsService = new ReportApiService();
    }

    //#region observables

    @observable betsReport = null;
    @observable isDetailsOpen = false;

    @observable from = null;
    @observable to = null;

    //#endregion observables



    //#region actions

    @action.bound
    onInitialize() {
        this.disposeFilterReaction();
        this.filterReaction = reaction(
            () => ({
                dateRange: this.myBetsStore.myBetsFilterStore.dateRange,
            }),
            ({ dateRange }) => {
                if (dateRange != null) {
                    this.fetchReports(dateRange)
                }
            },
            {
                fireImmediately: true,
            }
        )
    }


    @action.bound
    async fetchReports(filter) {

        const betsReport = await this.reportsService.getMyBetsReport(filter.from, filter.to);

        const from = DateTime.fromISO(filter.from).toLocaleString(DateTime.DATE_SHORT);
        const to = DateTime.fromISO(filter.to).toLocaleString(DateTime.DATE_SHORT);

        runInAction(() => {
            this.from = from;
            this.to = to;
            this.betsReport = betsReport?.result?.[0];
        })
    }


    @action.bound
    openDetails() {
        this.isDetailsOpen = true;
    }

    @action.bound
    closeDetails() {
        this.isDetailsOpen = false;
    }

    //#endregion actions



    //#region disposers

    @action.bound
    onDispose() {
        this.disposeFilterReaction();
        this.betsReport = null;
        this.isDetailsOpen = false;
    }

    @action.bound
    disposeFilterReaction() {
        if (this.filterReaction != null) {
            this.filterReaction();
            this.filterReaction = null;
        }
    }

    //#endregion disposers
}