import React from "react";
import { AutomaticLogoutPopupTemplate } from "themes/common/components/popups";
import { useFixBody } from "../../hooks";
export default function AutomaticLogoutPopup(props) {

    const logout = async () => {
        App.state.rootStore.stopSession();
        App.state.rootStore.userAuthStore.resolveConnection();
    }

    useFixBody(true, props.isOpened);

    return <AutomaticLogoutPopupTemplate
        logout={logout}
        {...props}
    />
}