import { useContext } from "react";
import { 
    PasswordConfirmationGetterContext,
    PasswordConfirmationSetterContext
} from "../context/PasswordConfirmationContext";

export function usePasswordConfirmation() {
    const passwordConfirmationGetterCtx = useContext(PasswordConfirmationGetterContext);
    const passwordConfirmationSetterCtx = useContext(PasswordConfirmationSetterContext);

    return {
        setShowPasswordConfirmation : (condition) => {
            passwordConfirmationSetterCtx.setShowPasswordConfirmation(condition);
        },
        showPasswordConfirmation : passwordConfirmationGetterCtx.showPasswordConfirmation,
    }
}