import { ErrorMessage } from "../../../../../common/components/error";
import { RequiredMark } from "../../../../../common/components/inputs";
import React from "react";
import { useTranslation } from "react-i18next";

const FormInputFieldCurrencyTemplate = (props) => {
    const { t } = useTranslation();

    const {
        currencyCode,
        label,
        name,
        className,
        errorMessage = undefined,
        type = 'text',
        required = false,
        placeholder = undefined,
        marker = undefined,
        field,
        ...rest
    } = props;
    return (
        <div className={className}>
            <div className="col-sml-4">
                <label className="form__field__label">
                    {t(label)}
                    <RequiredMark required={required} />
                </label>
            </div>
            <div className="col-sml-7">

                <input
                    type={type}
                    {...field}
                    {...rest}
                    className="input input--base"
                    placeholder={t(placeholder)}
                />

            </div>
            <div className="col-sml-1">
                <ErrorMessage name={name} />
            </div>
        </div>
    )
}
export default FormInputFieldCurrencyTemplate;