import React from "react";
import { PersonalDataFormSectionTemplate } from "themes/pages/administration/personal-data/components";

export default function PersonalDataFormSection(props) {
	const checkIsCityOrStreetFieldValid = (value) => {
		//this regex does something
		return XRegExp("^[\\p{L}0-9 /'-.]{1,80}$").test(value);
	};

	return (
		<PersonalDataFormSectionTemplate
			{...props}
			checkIsCityOrStreetFieldValid={checkIsCityOrStreetFieldValid}
		/>
	);
}
