import React, { useContext } from 'react';
import classnames from 'classnames';
import { SportOfferContext } from '@gp/components';

export const LiveMatchResultLiveTemplate = ({ isScore, home, away }) => {
	const sportContext = useContext(SportOfferContext);

	return (
		<div className={classnames('offer__match__result', sportContext.abrv, { 'is-score': isScore })}>
			<span>{home}:{away}</span>
		</div>
	)
};

