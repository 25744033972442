import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { defaultTemplate } from '../../../../../common/hoc';
import { Currency } from '../../../../../common/components/currency';

class OneClickBetTemplate extends React.Component {
    render() {
        const {
            t,
            betSlipStore: {
                betSlipState: {
                    oneClickBetAmount,
                    onOneClickBetAmountChange,
                },
                isCountdownVisible,
                countdownCounter,
                onOneClickBetValueChange
            }
        } = this.props;
        return (
            <div className="oneclick">
                {isCountdownVisible && (
                    <div className="loader loader--one-click">
                        <div className="loader__card">
                            <div className="loader--countdown">{countdownCounter}</div>
                            <div className="loader__card__title">{t('HOME.LOADING')}...</div>
                            <span />
                        </div>
                    </div>
                )}
                <div className="oneclick__amount">
                    <label className="oneclick__label">{t('BET_SLIP.ONE_CLICK_BET.CHOOSE_AMOUNT')}</label>

                    <div className="oneclick__amount__buttons">
                        <input id="1" type="radio" name="amount" onClick={e => onOneClickBetAmountChange(5)} className={classnames("oneclick__radio", { "is-active": oneClickBetAmount == 5 })} />
                        <label htmlFor="1" className="u-mar--right--tny">
                            <span>{t('BET_SLIP.ONE_CLICK_BET.AMOUNT_5')}</span>
                            <Currency />
                        </label>

                        <input id="2" type="radio" name="amount" onClick={e => onOneClickBetAmountChange(10)} className={classnames("oneclick__radio", { "is-active": oneClickBetAmount == 10 })} />
                        <label htmlFor="2" className="u-mar--right--tny">
                            <span>{t('BET_SLIP.ONE_CLICK_BET.AMOUNT_10')}</span>
                            <Currency />
                        </label>

                        <input id="3" type="radio" name="amount" onClick={e => onOneClickBetAmountChange(15)} className={classnames("oneclick__radio", { "is-active": oneClickBetAmount == 15 })} />
                        <label htmlFor="3">
                            <span>{t('BET_SLIP.ONE_CLICK_BET.AMOUNT_15')}</span>
                            <Currency />
                        </label>
                    </div>
                </div>
                <div className="oneclick__custom">
                    <label htmlFor="customValue" className="oneclick__label">{t('BET_SLIP.ONE_CLICK_BET.CUSTOM_AMOUNT')}</label>
                    <div className="oneclick__custom__field">
                        <input id="customValue" className="oneclick__custom__input input input--sml" min={0} type="number" onChange={e => onOneClickBetValueChange(e.target.value)} value={oneClickBetAmount} />
                        <Currency component="span" className="oneclick__custom__currency" />
                    </div>
                </div>
            </div>
        );
    }
}

OneClickBetTemplate.propTypes = {
    betSlipStore: PropTypes.object
};

export default defaultTemplate(OneClickBetTemplate);