import React, { useContext } from "react";

import { AdditionalOfferStoreContext, GroupStoreContext } from "@gp/components";

import { AdditionalOfferGroupTemplate } from 'themes/common/components/offer/new-offer/common';

export const AdditionalOfferGroup = () => {
	const additionalOfferContext = useContext(AdditionalOfferStoreContext);

	return (
		<div>
			{additionalOfferContext.bettingGroups.map((groupStore) => (
				<GroupStoreContext.Provider key={groupStore.id} value={groupStore}>
					<AdditionalOfferGroupTemplate />
				</GroupStoreContext.Provider>
			))}
		</div>
	)
}