export class UpdateOrDeleteViewModel{
    constructor(currentLimit,bettingUserLimitType,bettingUserLimitDuration,userAccount){
        this.limitAmount = currentLimit.limitAmount;
        this.displayLimitAmount = currentLimit.limitAmount;
        this.bettingAccountTypeId = currentLimit.bettingAccountTypeId;
        this.limitDateUpdated = currentLimit.dateUpdated;
        this.bettingUserLimitTypeId = currentLimit.bettingUserLimitTypeId;
        this.bettingUserLimitDurationId = currentLimit.bettingUserLimitDurationId;
        this.limitId = currentLimit.id;
        this.lostAmount = currentLimit.usedLimitAmount;
        this.limitName = bettingUserLimitType.name;
        this.limitTypeAbrv = bettingUserLimitType.abrv;
        this.limitDurationAbrv = bettingUserLimitDuration.abrv;
        this.currencyDisplayCode = userAccount.item[0].currency.displayCode;
    }
}