import { DateTime } from "luxon";


export const formatDateTime = (date) => {
    return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
}

export const mapData = (data) => {
    if (!data) {
        return;
    }
    const mappedData = data.map(item => {
        return {
            id: item.id,
            name: item.name,
            abrv: item.abrv,
            units: item.units
        }
    });
    return mappedData;
}

export const checkForType = (type) => {

    switch (type) {
        case "loss-limit":
            return "LOSS";
        case "stake-limit":
            return "STAKE"
    }
}

export const checkForDuration = (type) => {
    switch (type) {
        case "daily-limit":
            return "DAILY";
        case "weekly-limit":
            return "WEEKLY";
        case "monthly-limit":
            return "MONTHLY";
    }
}

export const sortDurations = (durations) => {
    return durations.sort((a, b) => parseFloat(a.units) - parseFloat(b.units));
}
