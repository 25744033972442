import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { OfferOptionsContext } from '@gp/components';
import { toDisplayOdd, Sorter } from '@gp/utility';

const OutrightOfferRow = observer((props) => {
	const { t } = useTranslation();
	const offerOptionsContext = useContext(OfferOptionsContext);

	if (props.offers == null || props.offers.length === 0) {
		return null;
	}


	return props.offers.map(tip => {
		const displayTipValue = toDisplayOdd(tip.value);
		const isSelected = offerOptionsContext.isTipSelected(tip.id);

		const btnClasses = classNames(
			'quote',
			'quote--button',
			{
				'one-click': offerOptionsContext.isOneClickBetActive,
				'is-active': isSelected,
				'desc': tip.indicator === -1,
				'inc': tip.indicator === 1
			}
		);

		return (
			<div key={tip.id} className="offer__outright__item">
				<div className="offer__outright__tip" title={tip.tip}>
					{tip.tip} {tip.gender === 1 ? (<React.Fragment>({t('OFFER.GENDER.WOMEN')})</React.Fragment>) : null}
				</div>
				<button
					type="button"
					className={btnClasses}
					title={displayTipValue}
					disabled={tip.isLocked}
					onClick={e => offerOptionsContext.onTipClick(tip, e)}
				>
					{displayTipValue}
				</button>
			</div>
		);
	});
});

export const EventOutrightOfferTemplate = observer((props) => {
	if (props.eventOffer == null || props.eventOffer.size === 0) {
		return null;
	}

	const eventKeys = Array.from(props.eventOffer.values());

	if (eventKeys.length === 0) {
		return null;
	}

	return (
		<>
			{eventKeys.map(k => {
				const offers = Sorter.sortOfferByValue(Array.from(props.offerStore.keyOffersMap.get(k.id)?.values() || []));

				return (
					<OutrightOfferRow
						key={k.id}
						offers={offers}
					/>
				)
			})}
		</>
	)
});