import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { AccountActivationService } from "./services";
import { isNullOrWhitespace } from "../../../../utils/common/helpers";
import {
	useAppContext,
	useLoading,
	useNotification,
	useUser,
} from "../../../common/hooks";
import { AccountActivationTemplate } from "themes/pages/membership/account-activation";
import { ErrorPage } from "../../../common/components/error";

const accountActivationService = new AccountActivationService();

export default observer(function AccountActivation() {
	const user = useUser();
	const [accountActivationData, setAccountActivationData] = useState(null);
	const [activationError, setActivationError] = useState(null);
	const [showPopup, setShowPopup] = useState(null);

	const methods = useForm({
		mode: "onChange",
		defaultValues: {
			email: "",
		},
	});

	const { showError } = useNotification();
	const { setIsLoading } = useLoading();
	const { culture } = useAppContext();
	const { t } = useTranslation();

	useEffect(() => {
		App.state.rootStore.titleStore.setTitle(
			t("MEMBERSHIP.ACCOUNT_ACTIVATION.TITLE")
		);

		//on mount scroll to top
		document.querySelector("html, body").scrollTo(0, 0);
		const fetchData = async () => {
			try {
				setIsLoading(true);
				const accActivation =
					await accountActivationService.getAccountActivationData(
						user.accountActivation.activationToken,
						user.accountActivation.agencyKey
					);
				setAccountActivationData(accActivation);
			} catch (error) {
				setActivationError(error);
				// showError(`USER.ACCOUNT_SETTINGS.ERR_HANDLING.${error?.error?.message}`);
			} finally {
				setIsLoading(false);
			}
		};
		fetchData();

		() => {
			setAccountActivationData(null);
			setActivationError(null);
			setShowPopup(null);
		};
	}, []);

	const createAccountActivation = async (data) => {
		const activationModel = {
			activationToken: user.accountActivation.activationToken,
			agencyId: user.accountActivation.agencyId,
			email: !isNullOrWhitespace(data.email) ? data.email : null,
			emailChangeUrl: `${window.location.origin}/${culture}/email-activation?mailkey={mailkey}&username={username}`,
			password: !isNullOrWhitespace(data.password) ? data.password : null,
			username: data.userCardNumber,
			depositLimit: data.depositLimit,
			isReceivingPromoEmailsEnabled: data.isReceivingPromoEmailsEnabled,
		};

		try {
			setIsLoading(true);
			const response =
				await accountActivationService.createAccountActivation(
					activationModel
				);
			if (response.success) {
				if (
					isNullOrWhitespace(data.email) &&
					isNullOrWhitespace(data.password)
				) {
					App.state.rootStore.accountActivationInfo = true;
				} else if (
					isNullOrWhitespace(data.email) &&
					!isNullOrWhitespace(data.password)
				) {
					App.state.rootStore.accountActivationSuccess = true;
				} else {
					App.state.rootStore.accountActivationWithEmailActivationInfo = true;
				}

				user.resolveAccountActivation(
					response.data.primary.user,
					response.data.primary.token,
					response.data.primary.userAccounts
				);

				App.state.redirect(`/${culture}/home/full`);
			}
		} catch (error) {
			console.error(error);
			showError(`MEMBERSHIP.ACCOUNT_ACTIVATION.${error?.error?.message}`);
		} finally {
			setShowPopup(null);
			setIsLoading(false);
		}
	};

	const onSuccess = async (data) => {
		const userInfo = accountActivationData?.userInfo;
		const companyInfo = accountActivationData?.companyInfo;
		let isFormSubmitting = false;
		if (
			!companyInfo?.isOfflineUserEmailChangeMandatory &&
			isNullOrWhitespace(data.email) &&
			!companyInfo?.isOfflineUserPasswordChangeMandatory &&
			isNullOrWhitespace(data.password)
		) {
			isFormSubmitting = true;
			setShowPopup({ displayNoPasswordAndEmailPopup: true });
		} else if (
			!companyInfo?.isOfflineUserPasswordChangeMandatory &&
			isNullOrWhitespace(data.password)
		) {
			isFormSubmitting = true;
			setShowPopup({ displayNoPasswordPopup: true });
		} else if (
			!companyInfo?.isOfflineUserEmailChangeMandatory &&
			isNullOrWhitespace(data.email) &&
			!userInfo?.isOfflineUserMailApproved &&
			isNullOrWhitespace(data.email)
		) {
			isFormSubmitting = true;
			setShowPopup({ displayNoEmailPopup: true });
		}

		if (!isFormSubmitting) {
			await createAccountActivation(data);
		}
	};

	const logoutUser = () => {
		try {
			setIsLoading(true);
			user.removeUserFromLocalStorage();
			sessionStorage.removeItem("slip");
			App.state.rootStore.accountActivationSuccess = false;
			App.state.rootStore.accountActivationInfo = false;
			App.state.rootStore.accountActivationWithEmailActivationInfo = false;
			if (App.offer) {
				App.offer.rootStore.betSlipStore.betSlipState.reset();
			}
			App.state.history.push(`/${culture}/home/full`);
		} catch (error) {
			showError(t("USER.ACCOUNT_SETTINGS.ERR_HANDLING.GENERIC_ERROR"));
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<ErrorPage>
			<AccountActivationTemplate
				onSuccess={onSuccess}
				methods={methods}
				accountActivationData={accountActivationData}
				logoutUser={logoutUser}
				activationError={activationError}
				setActivationError={setActivationError}
				createAccountActivation={createAccountActivation}
				setShowPopup={setShowPopup}
				showPopup={showPopup}
			/>
		</ErrorPage>
	);
});
