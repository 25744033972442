import React from 'react';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

export default function OfferPrintTableTemplate(props) {
    const {
        data
    } = props;

    const { t } = useTranslation();

    return (

        <table className="table--primary" data-sid="1" data-tid="1">
            <thead className="table--primary__header">
                <tr className="table--primary__header__row">
                    <th className="table--primary__header__data u-type--left">
                        <span className="u-align--v--middle">{data.isHex ? t('OFFER_PRINT.HEX_TITLE').toUpperCase() : t('OFFER_PRINT.DEC_TITLE').toUpperCase()}</span>
                    </th>
                    <th className="table--primary__header__data u-type--center">{t('OFFER_PRINT.LAST_UPDATE_LABEL').toUpperCase()}</th>
                    <th className="table--primary__header__data u-type--center">  {t('OFFER_PRINT.DOWNLOAD_LABEL').toUpperCase()}</th>
                </tr>
            </thead>
            {/* @foreach (var offerPrintCategory in Model.OfferPrintCategories) */}
            {data.offerPrintCategories.map((category, idx) => {
                return (
                    <tbody key={`${category.name}-c-${idx}${data.isHex ? '-hex' : ''}`} className="table--primary__body table--primary__body--light">
                        <tr className="table--primary__subheader">
                            <td className="table--primary__subheader__data" colSpan="3">
                                {t(`OFFER_PRINT.CATEGORIES.${category.name}`).toUpperCase()}                        
                            </td>
                        </tr>
                        {category.offerPrintFiles.map((file, fIdx) => {
                            const hasUrl = file.url != null && file.url !== '';

                            return (
                                <tr key={`${category.name}-c-${idx}-${file.name}-f-${fIdx}${data.isHex ? '-hex' : ''}`} className="table--primary__header__row">
                                    <td className="table--primary__body__data u-type--left" colSpan={!hasUrl? "3" : "1"}>
                                        <a className="u-anchor u-anchor--negative" disabled={!hasUrl} href={file.url} target="_blank">
                                            {t(`OFFER_PRINT.FILES.${file.name}`)}
                                        </a>
                                    </td>
                                    {hasUrl ? (
                                        <>
                                            <td className="table--primary__body__data u-type--center">
                                                {DateTime.fromISO(file.dateModified).toFormat('EEE, dd.LL, T')}
                                            </td>
                                            <td className="table--primary__body__data u-type--center">
                                                <a className="btn btn--link" disabled={!hasUrl} href={file.url} target="_blank">
                                                    <i className="u-icon u-icon--med u-icon--download"></i>
                                                </a>
                                            </td>
                                        </>
                                    )
                                    : null}
                                </tr>
                            )
                        })}
                    </tbody>
                )
            })
            }
    </table>
    )
}