const httpClient = App.utils.httpClient;
const requestHeaders = {
	"Content-Type": "application/json",
};
class BettingTimeoutLookupService {
	/**
	 *
	 * @returns {promise}
	 */
	findLookup() {
		const url = `${BaseAddress}platform/betting-timeouts?fields=abrv,units,id,name,languageMetadata&rpp=20&translate=true`;
		return httpClient.get(url, requestHeaders, { responseType: "json" });
	}
}

const bettingTimeoutLookup = new BettingTimeoutLookupService();
export { bettingTimeoutLookup };
