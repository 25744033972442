import React from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { LiveSubheaderTemplate } from 'themes/common/components/subheader';

@inject(stores => ({ liveSubheaderViewStore: stores.offerStore.liveSubheaderViewStore }))
@observer
@withRouter
class LiveSubheader extends React.Component {
    async componentDidMount() {
        await this.props.liveSubheaderViewStore.onInitialize();
    }

    componentWillUnmount() {
        this.props.liveSubheaderViewStore.onDispose();
    }

    render() {
        return <LiveSubheaderTemplate {...this.props} />
    }
}

export default LiveSubheader;