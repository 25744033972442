import React from 'react';
import PropTypes from 'prop-types';
import { defaultTemplate } from '../../../../../common/hoc';

class OneClickBetNotificationTemplate extends React.Component {
    render() {
        const { t, onDeactivate } = this.props;
        return (
            <React.Fragment>
                <div className="oneclick--notice__desc">
                    <i className="u-icon u-icon--sml u-icon--one-click u-align--v--middle u-mar--right--tny"></i>
                    <span className="u-align--v--middle">{t('BET_SLIP.ONE_CLICK_BET.ACTIVE_MESSAGE')}</span>
                    <div className="u-mar--top--micro u-type--xsml">{t('BET_SLIP.ONE_CLICK_BET.ALERT')}</div>
                </div>
                <button className="oneclick--notice__action btn btn--sml btn--block btn--white" onClick={e => onDeactivate()}>
                    {t('BET_SLIP.ONE_CLICK_BET.DEACTIVATE')}
                </button>
            </React.Fragment>
        )
    }
}

OneClickBetNotificationTemplate.propTypes = {
    onDeactivate: PropTypes.func.isRequired
}

export default defaultTemplate(OneClickBetNotificationTemplate);