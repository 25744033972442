import React from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { HomeTemplate } from 'themes/pages/home';

@inject(stores => ({
    homeViewStore: stores.homeViewStore
}))
@observer
@withRouter
class Home extends React.Component {
    async componentDidMount() {
        await this.props.homeViewStore.onInitialize();
    }

    render() {
        return (
            <HomeTemplate {...this.props} />
        )
    }
}

export default Home;