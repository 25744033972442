import React from "react";
import { useTranslation } from "react-i18next";
import { useLoading } from "../../../../common/hooks/useLoading";
import { AccountStatementFilter } from "../../../../pages/account-statement/components/filter";
import { AccountStatementTransactionsTable } from "../../../../pages/account-statement/components/tables";
import { Loader } from "../../../../common/components/loader";

export default function AccountStatementTemplate(props) {

    const { t } = useTranslation();

    const {
        setStatementFilter,
        statementFilter
    } = props;

    return (
        <main className="l--full">
            <div className="container container--main">
                <div className="row">
                    <div className="l--full__content">
                        <div className="view--med">
                            <div className="title--primary">
                                {t('ACC_STATEMENT.FILTER.AS_HEAD')}
                            </div>

                            <div className="accountStatements">
                                <AccountStatementFilter
                                    setStatementFilter={setStatementFilter}
                                />

                                <AccountStatementTransactionsTable
                                    statementFilter={statementFilter}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}