import React from "react";
import { Trans, useTranslation } from "react-i18next";
import {
    formatDateTime,
    changeIdToName
} from "../../../../../../pages/administration/account-lock/utils";

export default function WithLockSection(props) {

    const { t } = useTranslation();

    const {
        fetchedData
    } = props;

    const {
        isAdminLock,
        isPermanentLocked,
        accountLockStartDate,
        accountLockEndDate,
        accountLockDurationTypeId
    } = fetchedData;

    if (fetchedData.length == 0 || accountLockDurationTypeId.length == 0) {
        return null;
    }
    
    return (
        <>
            {isAdminLock &&
                <div className="message--base message--note">
                    {t(`USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.ADMIN_ACCOUNT_LOCKED_MESSAGE`)}
                </div>
            }
            {
                isPermanentLocked &&
                <div className="message--base message--note">
                    {t(`USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.PERMANENT_ACCOUNT_LOCK_SET`)}
                </div>
            }
            {
                !isAdminLock && !isPermanentLocked &&
                <Trans>
                    <div className="message--base message--note">
                        {t(`USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.ACCOUNT_LOCKED_MESSAGE`,
                            {
                                0: changeIdToName(accountLockDurationTypeId, fetchedData),
                                1: formatDateTime(accountLockStartDate),
                                2: formatDateTime(accountLockEndDate),
                                interpolation: { escapeValue: false }
                            }
                        )}
                    </div>
                </Trans>
            }
        </>
    )
}