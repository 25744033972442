import React, { useMemo, useEffect } from 'react';
import { inject } from 'mobx-react';
import { useTranslation } from "react-i18next";
import { MyBetsPageTemplate } from 'themes/pages/my-bets';
import { MyBetsStore } from '../../../administration/pages/my-bets';

export default inject(stores => ({
    rootStore: stores.offerStore,
}))(function MyBetsPage(props) {

    const {
        rootStore,
    } = props;

    const { t } = useTranslation();
    const myBetsStore = useMemo(() => new MyBetsStore(rootStore), []);
    useEffect(
        () => {
            App.state.rootStore.titleStore.setTitle(t('MY_BETS.PAGE.TITLE'));
            myBetsStore.onInitialize();
            return () => {
                myBetsStore.onDispose();
            }
        }
        , []
    );

    return <MyBetsPageTemplate {...props} myBetsStore={myBetsStore} />
})