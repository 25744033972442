import React from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../common/components/buttons";
import {
	FormInputField,
	TextareaField,
} from "../../../../../common/components/inputs";
import {
	checkIfMessageContentFieldIsValid,
	checkIfSubjectContentFieldIsValid,
	checkIfUsernameFieldIsValid,
	emailPattern,
} from "../../../../../pages/info-pages/contact/utils";

export default function ContactTemplate(props) {
	const { methods, onSuccess } = props;

	const { handleSubmit, getValues } = methods;

	const { t } = useTranslation();

	return (
		<div id="contact-page" className="view--info view--info--contact">
			<h1 className="title--primary">
				{t("INFO_PAGES.CONTACT.CONTACT_HEADER")}
			</h1>

			<div className="message--sml message--note">
				{t("INFO_PAGES.CONTACT.FILL_CONTACT_FORM")}
			</div>

			<FormProvider {...methods}>
				<form className="form" onSubmit={handleSubmit(onSuccess)}>
					<FormInputField
						label="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.USERNAME"
						name="userName"
						className="form__field row"
						validate={{
							isUsernameValid: (value) =>
								checkIfUsernameFieldIsValid(value) ||
								"INFO_PAGES.CONTACT.INVALID_USERNAME",
						}}
						disabled={App.utils.isUserAuthenticated()}
					/>

					<FormInputField
						label="INFO_PAGES.CONTACT.CONTACT_EMAIL"
						name="email"
						className="form__field row"
						type="email"
						pattern={{
							value: emailPattern,
							message: "INFO_PAGES.CONTACT.INVALID_EMAIL",
						}}
						maxLength="100"
						removeSuccessMark
						required
					/>

					<FormInputField
						label="INFO_PAGES.CONTACT.CONTACT_SUBJECT"
						name="subject"
						className="form__field row"
						validate={{
							isSubjectValid: (value) =>
								checkIfSubjectContentFieldIsValid(value) ||
								"INFO_PAGES.CONTACT.INVALID_SUBJECT",
						}}
						maxLength="80"
						removeSuccessMark
						required
					/>

					<TextareaField
						name="message"
						label="INFO_PAGES.CONTACT.CONTACT_MESSAGE"
						className="form__field row"
						validate={{
							isMessageValid: (value) =>
								checkIfMessageContentFieldIsValid(value) ||
								"INFO_PAGES.CONTACT.INVALID_MESSAGE",
						}}
						removeSuccessMark
						required
					/>

					<div className="form__field row">
						<div className="col-sml-11">
							<div className="u-type--xsml">
								{t("INFO_PAGES.CONTACT.CONTACT_ASTERISK")}
							</div>
						</div>
					</div>
					<div className="form__field row">
						<div className="col-sml-11 u-type--right">
							<Button
								className="form__submit btn btn--med btn--secondary"
								btnText="INFO_PAGES.CONTACT.CONTACT_SUBMIT_BTN"
							/>
						</div>
					</div>
				</form>
			</FormProvider>
		</div>
	);
}
