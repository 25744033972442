import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { Link, generatePath, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { OfferStoreContext } from '@gp/components';

export default function PrematchSubheaderTemplate(props) {
    const { prematchSubheaderViewStore } = props;

    return (
        <OfferStoreContext.Provider value={prematchSubheaderViewStore}>
            <nav className="menu--period">
                <SelectedPeriodButton />
                <PeriodMenu />
            </nav>
        </OfferStoreContext.Provider>
    );
};

const SelectedPeriodButton = observer(function SelectedPeriodButton() {

    const {
        toggleExpanded,
        selected: {
            name,
        },
    } = useContext(OfferStoreContext);

    const { t } = useTranslation();

    return (
        <button
            className="menu--period__toggle btn"
            type="button"
            onClick={e => toggleExpanded()}
        >
            {t(name)}
            <i className="u-icon u-icon--tny u-icon--arrow-down--white" />
        </button>
    )
})

const PeriodMenu = observer(function PeriodMenu() {

    const {
        isExpanded,
    } = useContext(OfferStoreContext);

    if (!isExpanded) {
        return null;
    }

    return (
        <ul className="menu--period__menu">
            <PeriodItems />
        </ul>
    )
})

function PeriodItems() {

    const {
        menu,
    } = useContext(OfferStoreContext);

    return menu.map(link =>
        <PeriodItem key={link.period} link={link} />
    )

}

const PeriodItem = withRouter(function PeriodItem(props) {

    const {
        link: {
            period,
            name,
        },
        match: {
            path,
            params,
        }
    } = props;

    const {
        setSelected,
    } = useContext(OfferStoreContext);

    const { t } = useTranslation();

    const url = decodeURIComponent(generatePath(path, {
        ...params,
        period: period
    }));


    return (
        <li className="menu--period__item">
            <Link
                className="menu--period__link"
                to={url}
                onClick={e => setSelected(period)}
            >
                {t(name)}
            </Link>
        </li>
    )
})