import React from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { PasswordInput } from "../../../../../../common/components/inputs";
import { FormButton } from "../../../../../../common/components/buttons";
import { PasswordChangeNewPassword } from "../../../../../../pages/administration/password-change/components";
import { isNullOrWhitespace } from "../../../../../../../utils";

const passwordPattern = /^[a-zA-Z0-9!#$()@?{}|*+,^.\-+&=_%:;~]{5,40}$/gm;

export default function PasswordChangeFormSectionTemplate(props) {
	const { handleSubmit, getValues } = useFormContext();
	const { t } = useTranslation();
	const { onSubmitData, userInfo } = props;

	return (
		<form className="form" onSubmit={handleSubmit(onSubmitData)}>
			<div className="form__group">
				<div className="message--sml message--note">
					<h6>
						{t(
							"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_REQUIREMENTS_VALID"
						)}
					</h6>
					<ul className="list list--arrow u-type--sml">
						<li className="list list--arrow__item">
							{t(
								"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_REQUIREMENT_1",
								{ 0: "5" }
							)}
						</li>
						<li className="list list--arrow__item">
							{t(
								"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_REQUIREMENT_2"
							)}
						</li>
						<li className="list list--arrow__item">
							{t(
								"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_REQUIREMENT_3"
							)}
						</li>
						<li className="list list--arrow__item">
							{t(
								"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_REQUIREMENT_4"
							)}
						</li>
					</ul>

					<h6>
						{t(
							"specific:USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_TIPS"
						)}
					</h6>
					<ul className="list list--arrow u-type--sml">
						<li className="list list--arrow__item">
							{t(
								"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_TIPS_3"
							)}
						</li>
						<li className="list list--arrow__item">
							{t(
								"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_TIPS_4"
							)}
						</li>
						<li className="list list--arrow__item">
							{t(
								"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_TIPS_5"
							)}
						</li>
					</ul>
				</div>

				<PasswordInput
					name="currPassword"
					className="form__field row"
					label="USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.CURRENT_PASSWORD_LABEL"
					removeSuccessMark
					required
				/>

				<PasswordChangeNewPassword
					passwordPattern={passwordPattern}
					userInfo={userInfo}
				/>

				<PasswordInput
					name="repeatPassword"
					className="form__field row"
					label="USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.REPEAT_NEW_PASSWORD"
					pattern={{
						value: passwordPattern,
					}}
					validate={{
						isEqual: (value) =>
							value === getValues("newPassword") ||
							"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.DOES_NOT_MATCH_PWD",
					}}
					required
				/>
				<div className="form__field row">
					<div className="col-sml-11 u-type--right">
						<FormButton
							className="form__submit btn btn--med btn--secondary"
							btnText="COMMON.SAVE_BUTTON"
						/>
					</div>
				</div>
			</div>
		</form>
	);
}
