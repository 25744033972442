import React, { useContext} from "react";
import classNames from "classnames";
import { observer } from "mobx-react";

import { toDisplayOdd } from '@gp/utility';
import {
	OfferOptionsContext,
	EventContext,
	OfferStoreContext,
} from '@gp/components';

export default observer(function TipButtonTemplate(props) {

	const {
		tip,
		isWithoutTip,
	} = props;

	if (tip?.value == null || tip?.displayTip == null) {
		return (
			<div className="offer--additional__quotes__item">
				<div className="quote quote--placeholder" />
			</div>
		);
	}

	//#region component css 

	const componentWrapperElement = classNames(
		{
			'offer--additional__quotes__item': !isWithoutTip,
			'offer--player__item': isWithoutTip
		}
	);

	

	//#endregion component css

	return (
		<div className={componentWrapperElement}>
			<Button tip={tip} isWithoutTip={isWithoutTip} />
		</div>
	);
});

const Button = observer(function Button(props) {

	const {
		isWithoutTip,
		tip,
		tip: {
			keyId,
			displayTip,
			value,
			indicator,
			id,
		},
	} = props;

	//#region hooks

	const {
		isOneClickBetActive,
		isTipSelected,
		onTipClick,
	} = useContext(OfferOptionsContext);

	const {
		eventKeysMap,
	} = useContext(OfferStoreContext);

	const event = useContext(EventContext);

	//#endregion hooks

	
	const isSelected = isTipSelected(id);
	const displayValue = toDisplayOdd(value);

	const eventKeys = eventKeysMap.get(event.id);
	const key = eventKeys?.get(keyId);

	const isLocked = isSelected ? false : (event?.isLocked || key?.isLocked || tip.isLocked);

	//#region css classes

	const btnClasses = classNames(
		"quote",
		"quote--button",
		{
			"one-click": isOneClickBetActive,
			"is-active": isSelected,
			"desc": indicator === -1,
			"inc": indicator === 1,
		},
		"u-type--color--text--primary"
	);

	const tipValueClasses = classNames(
		{
			'quote quote--button--alt u-mar--right--tny': !isWithoutTip,
			'u-type--color--negative': isWithoutTip
		},
		'u-type--tny'
	);

	//#endregion css classes 

	return (
		<>
			<div
				className={tipValueClasses}
				title={displayTip}
			>
				{displayTip}
			</div>
			<button
				type="button"
				className={btnClasses}
				title={displayValue}
				disabled={isLocked}
				onClick={e => onTipClick(tip)}
			>
				{displayValue}
			</button>
		</>
	)
});
