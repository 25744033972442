import { inject, observer } from "mobx-react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ResultsPageTemplate } from "themes/pages/results";
import { ErrorPage } from "../../common/components/error";

export default inject((stores) => ({
	resultsMenuStore: stores.offerStore.resultsMenuStore,
	selectedSport: stores.offerStore.resultsMenuStore.selectedSport,
	selectedCategory: stores.offerStore.resultsMenuStore.selectedCategory,
	selectedTournament: stores.offerStore.resultsMenuStore.selectedTournament,
	persistentTerm: stores.offerStore.searchBarViewStore?.persistentTerm,
	resultsDataStore: stores.offerStore.resultsDataStore,
}))(
	observer(function ResultsPage(props) {
		const { t } = useTranslation();

		useEffect(() => {
			App.state.rootStore.titleStore.setTitle(t("RESULTS.TITLE"));

			//on mount scroll to top
			document.querySelector("html, body").scrollTo(0, 0);
		}, []);

		return (
			<ErrorPage>
				<ResultsPageTemplate {...props} />
			</ErrorPage>
		);
	})
);
