import React from 'react';
import { observer, inject } from 'mobx-react';

import { OddsTemplate } from 'themes/common/components/subheader';

@inject(stores => ({
    oddsStore: stores.offerStore.oddTypeStore
}))
@observer
class Odds extends React.Component {
    render() {
        return <OddsTemplate {...this.props} />
    }
}

export default Odds;