import React from 'react';
import { inject } from 'mobx-react'
import { TournamentSwitcherTemplate } from 'themes/pages/sports/components';
import { withRouter } from 'react-router-dom';
@withRouter
@inject(stores => ({
    offerMenuStore: stores.offerStore.prematchOfferMenuStore,
}))
class TournamentSwitcher extends React.Component {

    render() {
        const tournamentsList = App.state.history.location?.state?.tournamentSwitcher?.tournamentsList;
        const { tournamentId: currentTournamentId, culture,period } = this.props.match.params;
        const offerMenuStore = this.props.offerMenuStore;


        if (tournamentsList == null || tournamentsList.length < 2) {
            return null;
        }

        function moveLeft() {
            let index = tournamentsList.indexOf(currentTournamentId);
            if (index <= 0) {
                index = tournamentsList.length - 1;
            } else {
                index--;
            }

            const newTournamentId = tournamentsList[index];
            updateUrl(newTournamentId);
        }

        function moveRight() {
            let index = tournamentsList.indexOf(currentTournamentId);
            if (index >= tournamentsList.length - 1) {
                index = 0;
            } else {
                index++;
            }

            const newTournamentId = tournamentsList[index];
            updateUrl(newTournamentId);
        }

        function updateUrl(tournamentId) {

            const tournamentMenuNode = offerMenuStore.findTournamentWithId(tournamentId);
            if(tournamentMenuNode == null) {
                App.state.history.replace(tournamentId, App.state.history.location?.state);
            }

            const sportNode = tournamentMenuNode.parent.parent;

            const url = `/${culture}/sports/${period}/${sportNode.node.id}/tournament/${tournamentId}`;
            App.state.history.replace(url, App.state.history.location?.state);
        }

        return (
            <>
                <TournamentSwitcherTemplate
                    {...this.props}
                    moveLeft={moveLeft}
                    moveRight={moveRight}
                />
            </>
        )
    }
}

export default TournamentSwitcher;
