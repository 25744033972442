import { inject, observer } from 'mobx-react';
import React from 'react';
import { ResultTablesTemplate } from 'themes/pages/results/components';

export default inject(stores => ({
    resultsDataStore: stores.offerStore.resultsDataStore,
    selectedTournaments: stores.offerStore.resultsMenuStore.selectedTournaments,
    selectedTournamentsLength: stores.offerStore.resultsMenuStore.selectedTournaments ? stores.offerStore.resultsMenuStore.selectedTournaments.length : null,
    dayParam: stores.offerStore.resultsDataStore.dayParam,
    openStatisticsPopup: stores.offerStore.openStatisticsPopup,
    openStatistics: stores.offerStore.openStatistics
}))(observer(function ResultTables(props) {
    return <ResultTablesTemplate {...props} />
}))