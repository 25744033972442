import React from "react";
import { NameProvider } from "@gp/utility";
import { getTeamNames, getParsedCurrentResult, getParsedResult, getScorePerPeriod, getCurrentResult, getCurrentScorePerPeriod} from "../../utils";
import { AccountStatetementBetDetailsOfferTemplate } from "themes/pages/account-statement/components/details";

const nameProvider = new NameProvider();

export default function AccountStatetementBetDetailsOffer(props) {

    const {
        offer,
    } = props;

    if (offer == null) {
        return null;
    }

    const offerSpecifier = {
        competitor1: offer.sportData.teamOneName,
        competitor2: offer.sportData.teamTwoName,
        ...offer.specifier
    };

    const teamNames = getTeamNames(offer);

    const bettingTypeName = nameProvider.getName(offer.sportData.bettingTypeNameForBettingSlip, offerSpecifier);

    const bettingTypeTip = nameProvider.getName(teamNames, offerSpecifier);

    const parsedCurrentResult = getParsedCurrentResult(offer);

    const parsedResult = getParsedResult(offer);

    const scorePerPeriod = getScorePerPeriod(parsedResult)

    const currentResult = getCurrentResult(parsedCurrentResult);

    const currentScorePerPeriod = getCurrentScorePerPeriod(currentResult, parsedCurrentResult);

    return <AccountStatetementBetDetailsOfferTemplate
        {...props}
        parsedCurrentResult={parsedCurrentResult}
        currentResult={currentResult}
        currentScorePerPeriod={currentScorePerPeriod}
        bettingTypeName={bettingTypeName}
        bettingTypeTip={bettingTypeTip}
        parsedResult={parsedResult}
        scorePerPeriod={scorePerPeriod}
    />
}