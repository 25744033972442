import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { Header } from "../../../../../common/components/header";
import { useAppContext } from "../../../../../common/hooks";

export default function Error404PageTemplate(props) {
    const { path404, contentOnly } = props;
    const { t } = useTranslation();
    const { culture } = useAppContext();
    const { pathname } = useLocation();

    if (pathname.includes("account-settings/") || contentOnly) {
        return (
            <div className="view--sml">
                <h1 className="u-type--lrg u-type--case--uppercase u-mar--bottom--sml">
                    {t("ERROR.404.PAGE_NOT_FOUND")}
                </h1>
                {/* TODO: add this link for 404 error */}
                {/*                                 <div className="u-type--sml u-type--color--grey u-mar--left--sml u-mar--right--sml u-mar--top--sml" style={{ overflowWrap: 'break-word' }}>{path404}</div> */}
                <p className="u-type--sml u-type--wgt--bold u-type--color--error">{t("ERROR.404.PAGE_HAS_NOT_BEEN_FOUND")}</p>
                <p className="u-type--sml">{t("ERROR.404.REASONS")}</p>
                <ul className="list list--arrow u-mar--bottom--med u-type--sml">
                    <li className="list--arrow__item">{t("ERROR.404.URL_RELOCATED")}</li>
                    <li className="list--arrow__item">{t("ERROR.404.URL_INCORRECT")}</li>
                    <li className="list--arrow__item">{t("ERROR.404.URL_UNAVAILABLE")}</li>
                </ul>

                <div className="u-mar--bottom--sml">{t("ERROR.404.SUPPORT_HELP")} {" "}
                    <Link to={`/${culture}/info/contact`} className="u-anchor--primary">
                        {t("ERROR.404.HERE_LINK")}
                    </Link>
                </div>
                <div>
                    {t("specific:ERROR.404.GOTO_ACTION_LABEL")} {" "}
                    <Link to={`/${culture}/home/full`} className="u-anchor--primary">
                        {t("specific:ERROR.404.LINK_LABEL")}.
                    </Link>
                </div>
            </div>
        )
    }
    return (
        <>
            <div className="l--full">
                <div className="container container--main">
                    <div className="row">
                        <div className="l--full__content">
                            <div className="view--sml">
                                <h1 className="u-type--lrg u-type--case--uppercase u-mar--bottom--sml">
                                    {t("ERROR.404.PAGE_NOT_FOUND")}
                                </h1>
                                {/* TODO: add this link for 404 error */}
                                {/*                                 <div className="u-type--sml u-type--color--grey u-mar--left--sml u-mar--right--sml u-mar--top--sml" style={{ overflowWrap: 'break-word' }}>{path404}</div> */}
                                <p className="u-type--sml u-type--wgt--bold u-type--color--error">{t("ERROR.404.PAGE_HAS_NOT_BEEN_FOUND")}</p>
                                <p className="u-type--sml">{t("ERROR.404.REASONS")}</p>
                                <ul className="list list--arrow u-mar--bottom--med u-type--sml">
                                    <li className="list--arrow__item">{t("ERROR.404.URL_RELOCATED")}</li>
                                    <li className="list--arrow__item">{t("ERROR.404.URL_INCORRECT")}</li>
                                    <li className="list--arrow__item">{t("ERROR.404.URL_UNAVAILABLE")}</li>
                                </ul>

                                <div className="u-mar--bottom--sml">{t("ERROR.404.SUPPORT_HELP")} {" "}
                                    <Link to={`/${culture}/info/contact`} className="u-anchor--primary">
                                        {t("ERROR.404.HERE_LINK")}
                                    </Link>
                                </div>
                                <div>
                                    {t("specific:ERROR.404.GOTO_ACTION_LABEL")} {" "}
                                    <Link to={`/${culture}/home/full`} className="u-anchor--primary">
                                        {t("specific:ERROR.404.LINK_LABEL")}.
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}