import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { SUPPORTED_LANGUAGES } from "../../../../../../../utils";
import { useAppActionsContext } from "../../../../../../common/hooks/useAppActionsContext";
import { useAppContext } from "../../../../../../common/hooks/useAppContext";

export default function LanguageTemplate() {

    const { pathname, search } = useLocation();
    const { culture } = useAppContext();
    const { setCulture } = useAppActionsContext();

    const [isOverButton, setIsOverButton] = useState(false);
    const [isOverList, setIsOverList] = useState(false);
    const [isOpen, setIsOpen] = useState();

    const { i18n } = useTranslation();
    // current language
    const currentLanguage = SUPPORTED_LANGUAGES.find(x => x.key === culture);

    // filter available languages and remove current language
    const filteredLanguages = SUPPORTED_LANGUAGES.filter(x => x.key !== culture);

    const onCultureChange = (newCulture) => {

        i18n.changeLanguage(newCulture);
        // close menu
        // setLanguageMenuOpen(false);
        // navigate
        const route = pathname.substring(3);
        window.location.replace(`/${newCulture}` + route + search);
    };

    if (SUPPORTED_LANGUAGES == null) {
        return;
    }
    let button = useRef(null);

    useEffect(() => {
        if (isOpen && !isOverButton && !isOverList) {
            button.current.click();
            button.current.blur();
            setIsOpen(false);
        } else if (!isOpen && (isOverButton || isOverList)) {
            button.current.click();
            setIsOpen(true);
        }
    }, [isOverButton, isOverList]);

    return (
        <div className="dropdown"
            onTouchStart={() => {
                setIsTouchInput(true);
            }}
            onMouseEnter={() => {
                setIsOverButton(true);
            }}
            onMouseLeave={() => {
                setIsOverButton(false);
            }}
            onBlur={() => {
                setIsOpen(!isOpen);
            }} >
            <div
                ref={button}
                className="dropdown__toggle" type="button"
            >
                <i className={`u-icon u-icon--lang u-icon--lang--${currentLanguage.key}`}></i>
                <span className="dropdown__toggle__caption">{currentLanguage.label}</span>
            </div>
            <ul className="dropdown__menu" style={!isOpen ? { display: "none" } : { display: "block" }}> {/* TODO: add styling */}
                {filteredLanguages.map(lang => {
                    return (
                        <li key={lang.key} onClick={() => onCultureChange(lang.key)} onMouseEnter={() => {
                            setIsOverList(true);
                        }}
                            onMouseLeave={() => {
                                setIsOverList(false);
                            }}>
                            <button type="button" className="dropdown__item" >
                                <i className={`u-icon u-icon--lang u-icon--lang--${lang.key} u-mar--right--sml`} ></i>
                                <span>{lang.label}</span>
                            </button>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}