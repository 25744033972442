import { observable, computed, action, runInAction, reaction } from "mobx";
import { BetSlipApiService } from "../../../services";
import { MyBetsService } from "../../../offer/services/components";
import { LookupService } from "../../../lookups";
import {
	CashoutStore,
	ReuseBetSlipStore,
	ReportsStore,
	ActiveEventsStore,
	BetCancelStore,
	MyBetsFilterStore,
	betPeriods,
	helpers,
} from "./index";
import { ReportApiService } from "../../../services";
import { UserAccountType } from "../../../ui/common/constants";
import { Bet } from "./model";
import { LoaderStore } from "../../../state/stores/common";
import { BetSlipService } from "../../../offer/services/components";

export default class MyBetsStore {
	constructor(rootStore) {
		this.rootStore = rootStore;

		// Services
		this.betSlipApiService = new BetSlipApiService();
		this.myBetsService = new MyBetsService();
		this.lookupService = new LookupService();
		this.betSlipService = new BetSlipService();
		this.reportsService = new ReportApiService();

		// Helper stores
		this.cashoutStore = new CashoutStore(rootStore, this);
		this.reuseBetSlipStore = new ReuseBetSlipStore(rootStore, this);
		this.betsReportStore = new ReportsStore(rootStore, this);
		this.activeEventsStore = new ActiveEventsStore(rootStore);
		this.betCancelStore = new BetCancelStore(rootStore, this);
		this.myBetsFilterStore = new MyBetsFilterStore(rootStore, this);
		this.loader = new LoaderStore();

		// Method bindings
		this.getDateRange = this.getDateRange.bind(this);
		this.formatFilterData = this.formatFilterData.bind(this);
	}

	//#region observables

	@observable isStoreInitialized = false;
	@observable isFetchingData = true; // When store first starts it will always start fetching

	/** Array of eventIds */
	@observable rememberMyBetsFilter = null;
	@computed get activeEvents() {
		return this.activeEventsStore.activeEventIds;
	}

	@observable betsList = [];
	@computed get isEmpty() {
		return (
			this.betsList.length === 0 &&
			!this.isFetchingData &&
			this.isStoreInitialized
		);
	}

	@observable betCombinationsMap = new observable.map();

	@observable betSlipStatusLookups = [];
	@observable userAccountTypes = [];
	@observable betStatusLookups = [];

	@computed get betPeriods() {
		return betPeriods;
	}

	@observable pageNumber = 1;
	pageSize = 10;
	@observable totalItems = 0;

	//#endregion observables

	//#region computed

	@computed get isLoading() {
		return this.loader.isLoading;
	}

	/**
	 * @returns {string} account type id
	 */
	@computed get onlineAccountTypeId() {
		return this.userAccountTypes?.find(
			(type) =>
				type.abrv === UserAccountType.SportBettingAccountShopOnline
		)?.id;
	}

	/**
	 * @returns {string} account type id
	 */
	@computed get offlineAccountTypeId() {
		return this.userAccountTypes?.find(
			(type) => type.abrv === UserAccountType.SportBettingAccountShop
		)?.id;
	}

	@computed get closedBetsBetStatuses() {
		return this.betStatusLookups?.filter(
			(status) => status.abrv !== "open"
		);
	}

	//#endregion computed

	//#region actions

	@action.bound
	async onInitialize() {
		this.loader.suspend();

		this.cashoutStore.onInitialize(); // No need to await since we don't use anything from it

		let betSlipStatusLookups = null;
		let userAccountTypes = null;
		let betStatusesLookups = null;

		try {
			betSlipStatusLookups =
				await this.lookupService.findBetSlipStatuses();
			userAccountTypes =
				await this.lookupService.findBettingAccountTypes();
			betStatusesLookups = await this.lookupService.findBetStatuses();
		} catch (error) {
			console.log(error);
		}

		this.disposeFilterReaction();
		this.filterReaction = reaction(
			() => ({
				filter: this.myBetsFilterStore.filter,
			}),
			() => {
				if (
					this.isStoreInitialized && // Wait for lookups to load
					!this.myBetsFilterStore.isDefaultFilter // Don't fetch for default filter
				) {
					this.fetchBets(this.myBetsFilterStore.filter);
				}
			},
			{
				fireImmediately: true,
			}
		);
		this.myBetsFilterStore.onInitialize(); // Initialize filter after this store, we need to know if search params are empty

		runInAction(() => {
			this.betSlipStatusLookups = betSlipStatusLookups;
			this.userAccountTypes = userAccountTypes;
			this.betStatusLookups = betStatusesLookups;

			this.isStoreInitialized = true;
			this.loader.resume();
		});
	}

	@action.bound
	async fetchBets(filter) {
		this.loader.suspend();
		this.isFetchingData = true;
		this.cashoutStore.disposeSubscription();

		try {
			const response = await this.betSlipApiService.findBets(
				filter,
				filter.pageNumber,
				this.pageSize
			);

			response.item?.forEach((betSlip) => {
				betSlip?.betSlipOffers?.forEach((offer) => {
					if (
						offer?.specifier?.player == null ||
						![
							"player-total-assists",
							"player-total-rebounds",
							"player-total-3-point-field-goals",
							"player-total-points",
						].includes(offer.sportData.bettingTypeAbrv)
					) {
						return;
					}

					offer.specifier.player =
						offer.playerFirstName +
						" " +
						offer.playerLastName +
						` (${
							offer.sportData.teamOneId === offer.teamId
								? offer.sportData.teamOneName
								: offer.sportData.teamTwoName
						})`;
					offer.playerId = undefined;
					offer.teamId = undefined;
				});
			});

			const eventIdsFromBets = helpers.getEventIdsFromMyBets(
				response.item
			);
			this.activeEventsStore.fetchActiveEvents(eventIdsFromBets);

			runInAction(() => {
				this.betsList = response.item.map((bet) => new Bet(bet));

				this.pageNumber = response.page;
				this.totalItems = response.totalRecords;
				this.cashoutStore.initializeCashoutSubscription();
				this.loader.resume();
				this.isFetchingData = false;
			});
		} catch (error) {
			runInAction(() => {
				console.error(error);
				const errorMessage =
					App.state.localizationService.t("ERROR.TITLE");
				toastr.error(errorMessage);

				this.betsList = [];
				this.loader.resume();
				this.isFetchingData = false;
			});
		}
	}

	@action.bound
	refresh() {
		this.fetchBets(this.myBetsFilterStore.filter);
	}

	@action.bound
	async initBetCombinationForBetSlip(betSlipId) {
		if (this.betCombinationsMap.has(betSlipId)) {
			return;
		}

		this.betCombinationsMap.set(betSlipId, null);

		const combinations = await this.myBetsService.getSystemCombinations(
			betSlipId
		);
		this.betCombinationsMap.set(betSlipId, combinations);
	}

	@action.bound
	submitFilter(data) {
		if (!data.fetchReport) {
			this.betsReportStore.onDispose();
		} else {
			const reportsFilter = this.getDateRange(data);
			if (reportsFilter != null) {
				this.betsReportStore.fetchReports(reportsFilter);
			}
		}

		this.myBetsFilterStore.setRawFilterData(data);
	}

	@action.bound
	onPageChange(newPageNumber) {
		this.myBetsFilterStore.setPageNumber(newPageNumber);
	}

	//#endregion actions

	//#region helpers

	formatFilterData(data) {
		const filter = {
			betSlipStatusIds: this.betSlipStatusLookups.find(
				(s) => s.abrv === "submitted"
			).id,
		};

		if (data.accountTypeIds != null) {
			filter.bettingAccountTypeIds = data.accountTypeIds;
		}

		switch (data.betStatusIds) {
			case "all":
				filter.betStatusIds = this.betStatusLookups.map((s) => s.id);
				break;
			case "open":
				filter.betStatusIds = this.betStatusLookups.find(
					(s) => s.abrv === "open"
				).id;
				break;
			case "closed":
				filter.betStatusIds = this.closedBetsBetStatuses.map(
					(s) => s.id
				);
				break;
			default:
				filter.betStatusIds = data.betStatusIds;
				break;
		}

		const dateRange = this.getDateRange(data);
		if (dateRange != null) {
			filter.from = dateRange.from;
			filter.to = dateRange.to;
		}

		if (data.isLive) {
			filter.isLive = true;
		}

		return filter;
	}

	getDateRange(data) {
		const { period, from, to } = data;

		if (
			(period == null && from == null && to == null) ||
			period === "all"
		) {
			return null;
		}

		if (period !== "custom") {
			const timeInterval = this.betPeriods
				.find((p) => p.value === period)
				?.getTimeInterval();
			if (timeInterval == null) {
				// Return default
				return this.betPeriods[0].getTimeInterval();
			}
			return timeInterval;
		}

		return { from, to };
	}

	//#endregion helpers

	//#region disposers

	@action.bound
	onDispose() {
		this.cashoutStore.onDispose();
		this.reuseBetSlipStore.onDispose();
		this.betCancelStore.onDispose();
		this.myBetsFilterStore.onDispose();
		this.disposeFilterReaction();

		this.isStoreInitialized = false;
		this.isFetchingData = true;

		this.betSlipStatusLookups = [];
		this.userAccountTypes = [];
		this.betStatusLookups = [];

		this.disposeCurrentMyBets();
	}

	@action.bound
	disposeCurrentMyBets() {
		this.rememberMyBetsFilter = null;

		this.betsList = [];

		this.betCombinationsMap.clear();

		this.pageNumber = 1;
		this.pageSize = 10;
		this.totalItems = 0;
	}

	@action.bound
	disposeFilterReaction() {
		if (this.filterReaction != null) {
			this.filterReaction();
			this.filterReaction = null;
		}
	}

	//#endregion disposers
}
