import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import {
	OfferTvCoverageActionButton
} from '../../../../../../common/components/offer';

import {
	getOfferColumns,
	OfferStoreContext,
	SportOfferContext,
	EventContext,
	OfferOptionsContext,
} from '@gp/components';

import { EventDetails, EventOffer } from '../../../../../../common/components/offer/new-offer';
import { AdditionalOffer } from '../../../../../../common/components/additional-offer'

import { defaultTemplate } from '../../../../../../common/hoc';

import { StatisticsButton } from '../../../../../../common/components/offer/buttons';

export const EventTemplate = observer(function EventTemplate(props) {

	const {
		isAlternate,
		displaySport
	} = props;

	const offerStore = useContext(OfferStoreContext);
	const {
		configuration: {
			bettingTypeSelectorsStore,
		}
	} = offerStore;

	const sport = useContext(SportOfferContext);
	const event = useContext(EventContext);
	const {
		numberOfColumns,
	} = useContext(OfferOptionsContext);

	const eventOffer = offerStore.eventKeysMap.get(event.id);

	const isEventFeatured = offerStore.additionalOfferStore.additionalOfferEventData.eventId === event.id;

	let secondaryColumns = { nonEmptyColumns: 0, isEmpty: !props.hasSecondaryOffer };
	let mainColumns = null;
	let additionalOfferCount = event.totalOfferCount;

	if (eventOffer != null && eventOffer?.size !== 0) {
		const eventKeys = Array.from(eventOffer.values());
		const sportSelector = bettingTypeSelectorsStore.getSportSelector(sport)
		mainColumns = getOfferColumns(eventKeys, sportSelector, numberOfColumns);

		if (!props.noSecondaryOffer) {
			secondaryColumns = getOfferColumns(eventKeys, sportSelector, numberOfColumns);
		}

		additionalOfferCount = event.totalOfferCount - (mainColumns.nonEmptyColumns + secondaryColumns.nonEmptyColumns);
	}

	const bodyRowClass = classnames(
		"offer__body__row",
		{
			"offer__main__row": event.isLive && !secondaryColumns.isEmpty,
			"is-selected": isEventFeatured,
			"odd": isAlternate
		}
	)

	return (
		<>
			{/* EVENT DETAILS & MAIN OFFER */}
			<tr className={bodyRowClass}>
				<EventDetails
					hasSecondaryOffer={!secondaryColumns.isEmpty}
					displaySport={displaySport}
					loadTvs={props.loadTvs}
				/>

				<MainOfferWrapper mainColumns={mainColumns} />

				<ActionButtons />

				<AdditionalOfferButton
					count={additionalOfferCount}
					hasSecondaryColumns={!secondaryColumns.isEmpty}

					isEventFeatured={isEventFeatured}
				/>
			</tr>

			{/* This does not render correctly right now. */}
			{/* <SecondaryOffer secondaryColumns={secondaryColumns} isAlternate={isAlternate} /> */}

			<AdditionalOfferWrapper mainColumns={mainColumns} />
		</>
	)
});

const SecondaryOffer = defaultTemplate(function SecondaryOffer(props) {
	const {
		t,
		secondaryColumns,
		isAlternate,
	} = props;

	if (secondaryColumns.isEmpty) {
		return null;
	}

	return (
		<div className='offer offer--live offer--live--tbody'>

			<div className={classnames(
				"offer__body__row offer__ht",
				{
					'odd': !isAlternate
				}
			)}>
				<div className="offer__ht__inner">
					{t('LIVE.FIRST_HALF_TIME')}
				</div>

				<EventOffer columns={secondaryColumns} />
			</div>
		</div>
	)
})

function MainOfferWrapper(props) {

	const {
		mainColumns,
	} = props;

	if (mainColumns != null) {
		return (
			<EventOffer columns={mainColumns} />
		)
	}

	return (
		<EmptyOfferColumns />
	)
}

const AdditionalOfferWrapper = observer(function AdditionalOfferWrapper(props) {

	const {
		mainColumns,
	} = props;

	const event = useContext(EventContext);

	const {
		additionalOfferStore: {
			additionalOfferColSpan = 7,
			isAdditionalOfferVisible,
			onAdditionalOfferExpand,
			additionalOfferEventData: {
				eventId: featuredEventId,
			},
		}
	} = useContext(OfferStoreContext);

	const isEventFeatured = featuredEventId === event.id;

	if (!isAdditionalOfferVisible || !isEventFeatured) {
		return null;
	}

	const excludedBettingOfferKeys = mainColumns?.columns?.map(c => c?.componentProps?.offerKeyId)

	return (
		<AdditionalOffer
			visibleColumnKeyIds={mainColumns?.columns?.map(c => c.id)}
			excludedBettingOfferKeys={excludedBettingOfferKeys}
			colSpan={additionalOfferColSpan}
			eventId={event.id}
			onClose={e => onAdditionalOfferExpand(e)}
		/>
	)
})

const AdditionalOfferButton = observer(function AdditionalOfferButton(props) {

	const {
		count,
		hasSecondaryColumns,
		isEventFeatured,
	} = props;

	const {
		additionalOfferStore: {
			isAdditionalOfferVisible,
			onAdditionalOfferExpand,
		},
	} = useContext(OfferStoreContext);

	const event = useContext(EventContext);

	const additionalToggleBtn = classnames(
		"offer__more__btn btn btn--sml-square",
		{
			"is-active": isAdditionalOfferVisible && isEventFeatured
		}
	);

	if (count > 0) {
		return (
			<td
				className={classnames(
					"offer__body__data offer__more offer__more--sml",
					{
						"offer__more--stretch": hasSecondaryColumns
					}
				)}
			>
				<button
					className={additionalToggleBtn}
					type="button"
					onClick={e => {
						if (event.isLive) {
							App.state.redirect(`/${App.utils.getCurrentCulture()}/live/events/event/${event.id}`);
						}
						else {
							onAdditionalOfferExpand({ eventId: event.id, isLive: event.isLive })
						}
					}}
				>
					<span>+{count}</span>
					<i className={"offer__more__icon u-icon u-icon--tny " + (isAdditionalOfferVisible && isEventFeatured ? "u-icon--arrow-right--white" : "u-icon--arrow-right")} />
				</button>
			</td>
		)
	}

	return (
		<td className="offer__body__data offer__more offer__more--sml is-empty">&nbsp;</td>
	)
})

function EmptyOfferColumns(props) {

	const {
		numberOfColumns,
	} = useContext(OfferOptionsContext);

	return _.times(numberOfColumns, (i) => (
		<EmptyOfferColumn key={i} />
	))
}

function EmptyOfferColumn(props) {

	return (
		<td className="offer__body__data offer__quotes">
			<div className="offer__quotes__row">
				<span className="quote quote--placeholder" />
				<span className="quote quote--placeholder" />
				<span className="quote quote--placeholder" />
			</div>
		</td>
	)
}

const ActionButtons = observer(function ActionButtons(props) {

	const event = useContext(EventContext);

	return (
		<td className="offer__body__data offer__actions">
			{event.source != null && <StatisticsButton isOfferButton/>}

			<OfferTvCoverageActionButton />

			<LiveEventIcon />
		</td>
	)
})

const LiveEventIcon = defaultTemplate(function LiveEventIcon(props) {

	const event = useContext(EventContext);
	const willBeLive = event.hasLiveCoverage && !event.isLive;

	const iconClass = classnames(
		'offer__actions__icon u-icon u-icon--med',
		{
			'u-icon--live': willBeLive,
			'u-icon--live--off': !willBeLive,
		}
	)

	return (
		<div className="offer__actions__btn offer__actions__live btn btn--xtny-square btn--link btn--icon-center">
			<i className={iconClass} />
		</div>
	)
})