

import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { HeaderTemplate } from "themes/common/components/header";
import { LoginService } from "../../../pages/membership/login/services";
import { useAppContext, useLoading, useUser } from "../../hooks";

const loginService = new LoginService();

function Header(props) {
    const { culture } = useAppContext();
    const [loginError, setLoginError] = useState(null);
    const methods = useForm({
        mode: "onChange", defaultValues: {
            password: ""
        }
    });
    const user = useUser();
    const { setIsLoading } = useLoading();
    const { reset } = methods;

    const onSuccess = async (data) => {
        setLoginError(null);
        try {
            setIsLoading(true);
            const response = await loginService.loginUser(data);
            if (response.success) {
                user.login(response.data);
                if (response.data.accountActivation != null) {
                    user.shouldDisplayAfterLogin = true;
                    if (response.data.accountActivation.activationType === 0) {
                        user.shouldDisplayAccountActivationAfterLogin = true;
                        App.state.history.push(`/${culture}/auth/account-activation`);
                    }
                    else {
                        user.shouldDisplayForcePasswordChangeAfterLogin = true;
                        App.state.history.push(`/${culture}/auth/force-password-change`);
                    }
                } else {
                    App.state.history.push(`/${culture}/home/full`);
                }
                reset();
            }
        } catch (error) {
            console.error(error);
            reset();
            setLoginError(error);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <HeaderTemplate
            methods={methods}
            onSuccess={onSuccess}
            loginError={loginError}
            setLoginError={setLoginError}
            {...props}
        />
    )
}

export default Header;