import { observer } from "mobx-react";
import React from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "../../../../../../common/components/buttons";
import { LoginErrorPopup } from "../../../../../../common/components/header/components";
import { FormInputField, PasswordInput } from "../../../../../../common/components/inputs";
import { useAppContext } from "../../../../../../common/hooks";

export default observer(function HeaderNavigationTemplate(props) {
    const { culture } = useAppContext();
    const {
        onSuccess,
        methods,
        loginError,
        setLoginError
    } = props;
    const { handleSubmit } = methods;
    const { t } = useTranslation();

    return (
        <div className="header--primary__memebership__wrapper">
            <div className="card--message">
                <div className="card--message__thumb"></div>
                <div className="card--message__body">
                    <div className="card--message__content card--message__content--login">
                        <FormProvider {...methods}>
                            <form onSubmit={handleSubmit(onSuccess)} className="form--membership">
                                <div className="form--membership__group">
                                    <FormInputField
                                        name="username"
                                        inputClassName='form--membership__input input input--sml valid'
                                        placeholder='MEMBERSHIP.LOGIN.EMAIL_OR_USERNAME_PLACEHOLDER'
                                        noValidate
                                    />

                                    <div className="form--membership__input form__field--icon">
                                        <PasswordInput
                                            inputClassName='form__field--icon__input input input--sml valid'
                                            name="password"
                                            placeholder='MEMBERSHIP.LOGIN.PASSWORD_PLACEHOLDER'
                                            noValidate
                                        />
                                    </div>
                                    <div className="form--membership__actions">
                                        <Button
                                            className="form--membership__submit btn btn--sml btn--neutral"
                                            btnText='MEMBERSHIP.LOGIN.LOGIN_BUTTON'
                                        />
                                    </div>
                                </div>

                                {/* TODO: add forgot password and forgot username links */}
                                <div className="form--membership__group form--membership__links">
                                    <ul className="list--inline">
                                        <li className="list--inline__item">
                                            <Link to={`/${culture}/auth/forgot-username`} className="u-anchor u-anchor--underline u-anchor--primary">
                                                {t("MEMBERSHIP.LOGIN.FORGOT_USERNAME_LINK")}
                                            </Link>
                                        </li>
                                        <li className="list--inline__item">
                                            <Link to={`/${culture}/auth/forgot-password`} className="u-anchor u-anchor--underline u-anchor--primary">
                                                {t("MEMBERSHIP.LOGIN.FORGOT_PASSWORD_LINK")}
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <LoginErrorPopup loginError={loginError} setLoginError={setLoginError} />
                            </form>
                        </FormProvider>
                    </div>
                </div>
            </div>
        </div >
    )
})