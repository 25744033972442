import React from "react";
import { useTranslation } from "react-i18next";
import { RecoverPasswordFormSection } from "../../../../../pages/membership/recover-password/components";

export default function RecoverPasswordTemplate(props) {

    const { t } = useTranslation();

    return (
        <div className="view--med">

            <div className="title--primary">{t('MEMBERSHIP.RECOVER_PASSWORD.PASSWORD_RECOVERY')}</div>

            <div className="message--base message--note">
                <div className="u-type--sml u-type--wgt--bold">{t('MEMBERSHIP.RECOVER_PASSWORD.PWD_REQUIREMENTS_VALID')}</div>
                <ul className="list list--arrow">
                    <li className="list--arrow__item">{t('MEMBERSHIP.RECOVER_PASSWORD.PWD_REQUIREMENT_1', { 0: 5 })}</li>
                    <li className="list--arrow__item">{t('MEMBERSHIP.RECOVER_PASSWORD.PWD_REQUIREMENT_2')}</li>
                    <li className="list--arrow__item">{t('MEMBERSHIP.RECOVER_PASSWORD.PWD_REQUIREMENT_3')}</li>
                    <li className="list--arrow__item">{t('MEMBERSHIP.RECOVER_PASSWORD.PWD_REQUIREMENT_4')}</li>
                </ul>

                <div className="u-mar--top--sml u-type--sml u-type--wgt--bold">{t('MEMBERSHIP.RECOVER_PASSWORD.PWD_TIPS')}</div>
                <ul className="list list--unordered">
                    <li className="list--arrow__item">{t('MEMBERSHIP.RECOVER_PASSWORD.PWD_TIPS_3')}</li>
                    <li className="list--arrow__item">{t('MEMBERSHIP.RECOVER_PASSWORD.PWD_TIPS_4')}</li>
                    <li className="list--arrow__item">{t('MEMBERSHIP.RECOVER_PASSWORD.PWD_TIPS_5')}</li>
                </ul>
            </div>

            <RecoverPasswordFormSection />

        </div>
    )
}