import React from "react";
import { useTranslation } from "react-i18next";

export default function ForgotUsernamePopupSuccessTemplate(props) {

    const { t } = useTranslation();
    
    return (
        <div className="message--sml message--note">
            <div className="u-type--sml u-type--wgt--bold">
                {t('MEMBERSHIP.FORGOT_USERNAME.FORGOT_USERNAME_SUCCESS')}
            </div>
            <div>
                {t('MEMBERSHIP.FORGOT_USERNAME.CHECK_YOUR_EMAIL')}
            </div>
        </div>
    )
}