import React, { useEffect } from "react";
import { useFormContext, useWatch } from 'react-hook-form';
import { isNullOrWhitespace } from "../../../../../utils/common/helpers";
import { AccountStatementCustomDateTemplate } from "themes/pages/account-statement/components/filter";

export default function AccountStatementCustomDate(props) {
    const { showCustomDateSection } = props;

    const { trigger, getValues } = useFormContext();

    useEffect(() => {
        //cannot use getFieldState().isTouched because of ReactDateTimePicker
        //check if both fields have values and then trigger revalidation on endDate field 
        if (!isNullOrWhitespace(getValues('startDate')) && !isNullOrWhitespace(getValues('endDate'))) {
            trigger('endDate');
        }
    }, [useWatch({ name: 'startDate' })]);

    if (!showCustomDateSection) {
        return null;
    }
    return <AccountStatementCustomDateTemplate />
}