import React from 'react';
import { TournamentOfferSportsListTemplate } from 'themes/pages/sports/components';

class TournamentOfferSportsList extends React.Component {

    render() {
        return (
            <>
                <TournamentOfferSportsListTemplate {...this.props} />
            </>
        )
    }
}

export default TournamentOfferSportsList;
