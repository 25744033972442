import React, { useContext } from "react";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import {
	MyBetsBetContext,
	MyBetsStoreContext,
} from "../../../../../common/context";

import { useCurrency } from "../../../../../common/hooks";

export default function BetDetails(props) {
	const { setShowCombinations } = props;
	const { t } = useTranslation();
	const { currency } = useCurrency();
	const bet = useContext(MyBetsBetContext);
	const {
		betCancelStore: { onBetCancel },
	} = useContext(MyBetsStoreContext);

	const fiscalizationItem = bet.firstFinishedTransactionItem;

	function handleShowCombinations() {
		setShowCombinations(true);
	}

	return (
		<tr className="table--bets__details">
			<td className="u-padd--reset" colSpan={8}>
				<table
					className="table--bets--reset"
					cellSpacing={0}
					cellPadding={0}
				>
					<tbody>
						<tr className="table--bets__details__row">
							<td className="table--bets__details__data">
								{t(
									"MY_BETS.ITEM.ADDITIONAL_NUM_SELECTED_EVENTS"
								)}
							</td>
							<td className="table--bets__details__data">
								{bet.eventCount}
							</td>
						</tr>
						<tr className="table--bets__details__row">
							<td className="table--bets__details__data">
								<p>
									{t("MY_BETS.ITEM.ADDITIONAL_NUM_BETS")}
									{bet.totalNumberOfCombinations > 1 && (
										<span
											className="u-mar--left--sml no-print"
											onClick={() =>
												handleShowCombinations()
											}
										>
											(
											<span className="u-type--style--underline  no-print u-cursor--pointer">
												{t(
													"MY_BETS.ITEM.SHOW_COMBINATIONS"
												)}
											</span>
											)
										</span>
									)}
								</p>
							</td>
							<td className="table--bets__details__data">
								{bet.totalNumberOfCombinations}
							</td>
						</tr>
						<tr className="table--bets__details__row">
							<td className="table--bets__details__data">
								{t("MY_BETS.ITEM.ADDITIONAL_STAKE_PER_BET")}
							</td>
							<td className="table--bets__details__data">
								{bet.paymentPerCombination.toFixed(2)}{" "}
								{currency}
							</td>
						</tr>
						<tr className="table--bets__details__row">
							<td className="table--bets__details__data">
								{t("MY_BETS.ITEM.ADDITIONAL_TOTAL_STAKE")}
							</td>
							<td className="table--bets__details__data">
								{bet.payment.toFixed(2)} {currency}
							</td>
						</tr>
						{bet.handlingFeePercentage != null &&
							bet.handlingFeePercentage > 0 && (
								<tr className="table--bets__details__row">
									<td className="table--bets__details__data">
										{t(
											"MY_BETS.ITEM.ADDITIONAL_HANDLING_FEE",
											{
												0: bet.handlingFeePercentage.toFixed(),
											}
										)}
									</td>
									<td className="table--bets__details__data">
										- {bet.handlingFee.toFixed(2)}{" "}
										{currency}
									</td>
								</tr>
							)}
						<tr className="table--bets__details__row">
							<td className="table--bets__details__data">
								{t("MY_BETS.ITEM.ADDITIONAL_MAX_COEFFICIENT")}
							</td>
							<td className="table--bets__details__data">
								{bet.maxCoefficient.toFixed(2)}
							</td>
						</tr>
						{bet.gainBonusPercentage != null &&
							bet.gainBonusPercentage > 0 && (
								<tr className="table--bets__details__row">
									<td className="table--bets__details__data">
										{t(
											"MY_BETS.ITEM.ADDITIONAL_GAIN_BONUS",
											{
												0: bet.gainBonusPercentage.toFixed(
													2
												),
											}
										)}
									</td>
									<td className="table--bets__details__data">
										+ {bet.gainBonus.toFixed(2)} {currency}
									</td>
								</tr>
							)}
						{bet.gainTaxPercentage != null &&
							bet.gainTaxPercentage > 0 && (
								<tr className="table--bets__details__row">
									<td className="table--bets__details__data">
										{t("MY_BETS.ITEM.ADDITIONAL_GAIN_TAX", {
											0: bet.gainTaxPercentage,
										})}
									</td>
									<td className="table--bets__details__data">
										- {bet.gainTax.toFixed(2)} {currency}
									</td>
								</tr>
							)}
						<tr className="table--bets__details__row">
							<td className="table--bets__details__data">
								{t("MY_BETS.ITEM.ADDITIONAL_MAX_GAIN")}
							</td>
							<td className="table--bets__details__data">
								{bet.maxGain.toFixed(2)} {currency}
							</td>
						</tr>
						{bet.payout != null && bet.payout > 0 && (
							<tr className="table--bets__details__row">
								<td className="table--bets__details__data">
									{t("MY_BETS.ITEM.ADDITIONAL_GAIN")}
								</td>
								<td className="table--bets__details__data u-type--color--won">
									{bet.payout.toFixed(2)} {currency}
								</td>
							</tr>
						)}
						{bet.metadata != null && fiscalizationItem != null && (
							<>
								<tr className="table--bets__details__row table--bets__fiscalization table--bets__fiscalization--dashed">
									<td
										className="table--bets__details__data table--bets__fiscalization__data"
										colSpan={2}
									>
										<span className="u-mar--right--tny">
											{t(
												"MY_BETS.ITEM.ADDITIONAL_START_TIME"
											)}
											:
										</span>
										<span>
											{DateTime.fromISO(
												fiscalizationItem.transactionTimestamp
											).toLocaleString(
												DateTime.DATETIME_SHORT
											)}
										</span>
									</td>
								</tr>
								<tr className="table--bets__details__row table--bets__fiscalization">
									<td
										className="table--bets__details__data table--bets__fiscalization__data"
										colSpan={2}
									>
										<span className="u-mar--right--tny">
											{t(
												"MY_BETS.ITEM.ADDITIONAL_END_TIME"
											)}
											:
										</span>
										<span>
											{DateTime.fromISO(
												fiscalizationItem.transactionTimestamp
											).toLocaleString(
												DateTime.DATETIME_SHORT
											)}
										</span>
									</td>
								</tr>
								<tr className="table--bets__details__row table--bets__fiscalization">
									<td
										className="table--bets__details__data table--bets__fiscalization__data"
										colSpan={2}
									>
										<span className="u-mar--right--tny">
											{t(
												"MY_BETS.ITEM.ADDITIONAL_TRANSACTION_NUMBER"
											)}
											:
										</span>
										<span>
											{
												fiscalizationItem.transactionNumber
											}
										</span>
									</td>
								</tr>
								<tr className="table--bets__details__row table--bets__fiscalization">
									<td
										className="table--bets__details__data table--bets__fiscalization__data"
										colSpan={2}
									>
										<span className="u-mar--right--tny">
											{t(
												"MY_BETS.ITEM.ADDITIONAL_TSE_SERIAL_NUMBER"
											)}
											:
										</span>
										<span>
											{bet.metadata.deviceSerialNumber}
										</span>
									</td>
								</tr>
								<tr className="table--bets__details__row table--bets__fiscalization">
									<td
										className="table--bets__details__data table--bets__fiscalization__data"
										colSpan={2}
									>
										<span className="u-mar--right--tny">
											{t(
												"MY_BETS.ITEM.ADDITIONAL_SIGNATURE"
											)}
											:
										</span>
										<span>
											{fiscalizationItem.signature}
										</span>
									</td>
								</tr>
							</>
						)}
						{bet.isCancellationAvailable == true && (
							<tr className="table--bets__details__row table--bets__details__row--cancel">
								<td
									className="table--bets__details__data u-padd--x--tny"
									colSpan={2}
								>
									<button
										className="btn btn--xsml btn--link btn--icon-left u-type--wgt--bold"
										onClick={() => onBetCancel(bet)}
									>
										<i className="u-icon u-icon--xsml u-icon--remove" />
										{t("MY_BETS.ITEM.CANCEL_BET")}
									</button>
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</td>
		</tr>
	);
}
