import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { PasswordInput } from "../../../../../../common/components/inputs";

export default function PasswordChangeNewPasswordTemplate(props) {
	const { getValues } = useFormContext();
	const { t } = useTranslation();

	const { onChangeInputPassword, passwordPattern, progressCounter } = props;

	const progressClass = classNames("progress", [
		`progress--${progressCounter}`,
	]);

	return (
		<>
			<PasswordInput
				name="newPassword"
				className="form__field row"
				label="USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.NEW_PASSWORD_LABEL"
				onChange={onChangeInputPassword}
				pattern={{
					value: passwordPattern,
				}}
				validate={{
					isNotEqual: (value) => value !== getValues("currPassword"),
				}}
				required
			/>
			<div className="form__field row">
				<div className="col-sml-4">
					<label className="form__field__label">
						{t(
							"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.PWD_STRENGTH"
						)}
					</label>
				</div>
				<div className="col-sml-7">
					<progress
						className={progressClass}
						value={progressCounter}
						max="100"
					/>
				</div>
			</div>
		</>
	);
}
