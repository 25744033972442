import React from 'react';

export default function BetCombinationItem(props) {

    const {combination, ordinalNumber} = props;


    const getCombinationStatus = (statusNumber) => {
        switch(statusNumber) {
            case 1: return 'active';
            case 2: return 'win'; 
            case 3: return 'lose';
        }
        return '';
    }

    const getSystemIdentifierString = (combinationOffers) => {
        return combinationOffers.map(o => o.systemIdentifier).join('');
    }

    const getFormatedCombinationQuota = (quota) => {
        if (quota < 100) {
            return quota.toFixed(2);
        }
        return quota.toFixed();
    }

    return(
        <tr className={'table--bet__body__row u-type--color--status--' + getCombinationStatus(combination.combinationStatus)}>
            <td className='table--bet__body__data u-type--center'>{ordinalNumber}</td>
            <td className='table--bet__body__data u-type--center'>{getSystemIdentifierString(combination.combinationOffers)}</td>
            <td className='table--bet__body__data u-type--center'>{getFormatedCombinationQuota(combination.combinationQuota)}</td>
        </tr>
    )

}