import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { PasswordInput } from "../../../../../common/components/inputs";
import { useTranslation } from "react-i18next";
import { FormButton, Button } from "../../../../../common/components/buttons";

const PasswordConfirmationTemplate = (props) => {
	const methods = useForm({ mode: "onChange" });
	const { t } = useTranslation();
	const { handleSubmit, reset } = methods;
	const { data, onSuccess, onCancel, optionalMessage = undefined } = props;

	const onSubmitData = async (formData) => {
		await onSuccess(formData.password);
		reset();
	};

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmitData)} className="form__group">
				<div className="form__field row">
					<div className="col-sml-11">
						<div className="message--sml message--note">
							<div>
								{t(
									"COMMON.PASSWORD_CONFIRMATION.SELECTED_DATA"
								)}
							</div>
							<div>
								{data.map((x, index) => {
									return (
										<p key={index}>
											{t(x.name)}: {x.value}
										</p>
									);
								})}
								{t(optionalMessage)}
							</div>
						</div>
					</div>
					<div className="col-sml-1" />
				</div>
				<PasswordInput
					className="form__field row"
					label="COMMON.PASSWORD_CONFIRMATION.PASSWORD_TITLE"
					name="password"
					placeholder="COMMON.PASSWORD_CONFIRMATION.PASSWORD_PLACEHOLDER"
					errorMessage="COMMON.PASSWORD_CONFIRMATION.ENTER_PASSWORD_CONFIRMATION"
					removeSuccessMark
					required
				/>
				<div className="form__field row">
					<div className="col-sml-11 u-type--right">
						<FormButton
							className="btn btn--sml btn--accent u-mar--right--sml"
							btnText="COMMON.SAVE_BUTTON"
						/>
						<Button
							className="btn btn--sml btn--neutral"
							btnText="COMMON.CANCEL_BUTTON"
							onClick={onCancel}
						/>
					</div>
				</div>
			</form>
		</FormProvider>
	);
};
export default PasswordConfirmationTemplate;
