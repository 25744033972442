import React from 'react';
import PropTypes from 'prop-types';
import { defaultTemplate } from '../../../../../common/hoc';
import { Modal } from '../../../../../common/components/modal';

class BetSlipSubmitValidationErrorsModalTemplate extends React.Component {
    render() {
        const { data, onClose, t } = this.props;

        let modalBody = null;

        if (!data) {
            modalBody = (
                <React.Fragment>
                    {t('BET_SLIP.ERRORS.GENERIC_ERROR')}
                </React.Fragment>
            );
        }
        else if (data.message) {
            modalBody = (
                <React.Fragment>
                    {t(data.message)}
                </React.Fragment>
            );
        }
        else if (data.validationErrors) {
            modalBody = (
                <React.Fragment>
                    {data.validationErrors.map((d, i) =>
                        <div key={i}>
                            {t(d.errorMessage)}
                        </div>
                    )}
                </React.Fragment>
            );
        }
        else {
            modalBody = (
                <React.Fragment>
                    {t(data)}
                </React.Fragment>
            );
        }

        return (
            <Modal>
                <div className="popup">
                    <div className="popup__card">
                        <h6 className="popup__card__header">
                            <i className="u-icon u-icon--lrg u-icon--my-bets--anchor u-display--b u-align--h--center u-mar--bottom--sml" />
                            {modalBody}
                        </h6>
                        <div className="popup__card__footer">
                            <button className="btn btn--base btn--ghost" onClick={onClose}>{t('COMMON.CLOSE')}</button>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

BetSlipSubmitValidationErrorsModalTemplate.propTypes = {
    data: PropTypes.object,
    onClose: PropTypes.func,
};

export default defaultTemplate(BetSlipSubmitValidationErrorsModalTemplate);