import React from 'react';
import classnames from 'classnames';

import { defaultTemplate } from '../../../../../common/hoc';
import { OfferEventDetails, OfferColumn, OfferActionButtons } from '../../../../../common/components/offer';
import { AdditionalOffer } from '../../../../../common/components/additional-offer'

/**
 * @typedef {Object} OfferProps
 * @property {boolean} isLive
 * @property {boolean} showSportIcon
 * @property {boolean} renderActionsBeforeOffer
 * @property {*} onRenderActions
 * @property {Object} event
 * @property {Array} offer
 * @property {boolean} showOnlyTime
 * @property {Array} main - main betting types
 * @property {Array} secondary - secondary betting types
 * @property {boolean} isAdditionalOfferVisible
 * @property {function(string): void} onClose
 * @property {function(string): void} onAdditionalOfferExpand 
 * @property {*} onRenderAdditionalOfferButton
 */

/**
 * Renders offer for given event
 * @param {OfferProps} props 
 */
function OfferTemplate(props) {

    const {
        showSportIcon = true,
        renderActionsBeforeOffer = false,
        additionalOfferColSpan = 7,
        event,
        sport,
        showOnlyTime,
        main,
        viewStore: {
            isAdditionalOfferVisible,
            onAdditionalOfferExpand,
            loadTvs,
            tvCoverage
        },
        t
    } = props;

    /**
     * @param {Object} columnConfig 
     */
    const mapOffer = (columnConfig) => {
        return Object.keys(columnConfig).reduce((acc, col) => {
            const colData = columnConfig[col];

            if (colData == null || colData.length === 0) {
                acc.columns.push({ id: null, component: <OfferColumn key={col} /> });
                return acc;
            }

            const colBettingTypes = colData.map(cd => cd.bettingType);

            // find offer for given column
            const colOffer = event.bettingTypes.find(bt => colBettingTypes.includes(bt.abrv));

            if (colOffer == null) {
                acc.columns.push({ id: null, component: <OfferColumn key={col} /> });
                return acc;
            }

            const offer = colOffer.keyOffers[0];

            if (offer == null) {
                acc.columns.push({ id: null, component: <OfferColumn key={col} /> });
                return acc;
            }

            const tips = colData.find(cd => cd.bettingType === colOffer.abrv).tips;

            acc.columns.push({
                id: offer.keyId,
                component: <OfferColumn key={offer.keyId} offer={offer.offers} tips={tips} />
            });
            acc.nonEmptyColumns++;
            acc.isEmpty = false;

            return acc;
        }, {
            isEmpty: true,
            nonEmptyColumns: 0,
            columns: [],
        });
    }

    const mainOffer = mapOffer(main);

    const additionalOfferCount = event.totalOfferCount - mainOffer.nonEmptyColumns;

    const additionalToggleBtn = classnames(
        "offer__more__btn btn btn--sml-square",
        {
            "is-active": isAdditionalOfferVisible
        }
    );


    return (
        <React.Fragment>
            <tr className={"offer__body__row" + (isAdditionalOfferVisible ? " is-expanded" : "")}>
                <OfferEventDetails event={event} showSportIcon={showSportIcon} showOnlyTime={showOnlyTime} sport={sport} />

                {renderActionsBeforeOffer ? (
                    <td className="offer__body__data offer__actions">
                        <OfferActionButtons
                            event={event}
                            loadTvs={loadTvs}
                            tvCoverage={tvCoverage}
                        />
                    </td>
                ) : null}

                {mainOffer.columns.map(c => c.component)}

                {!renderActionsBeforeOffer ? (
                    <td className="offer__body__data offer__actions">
                        <OfferActionButtons
                            event={event}
                            loadTvs={loadTvs}
                            tvCoverage={tvCoverage}
                        />
                    </td>
                ) : null}


                <td className="offer__body__data offer__more">
                    {additionalOfferCount > 0 ?
                        (
                            <button className={additionalToggleBtn} type="button" onClick={e => onAdditionalOfferExpand(e)}>
                                <span>+{additionalOfferCount}</span>
                                <i className={"offer__more__icon u-icon u-icon--tny " + (isAdditionalOfferVisible ? "u-icon--arrow-right--white" : "u-icon--arrow-right")} />
                            </button>
                        ) :
                        (
                            <button className="offer__more__btn btn btn--link" type="button" disabled />
                        )
                    }
                </td>
            </tr>
            {isAdditionalOfferVisible ? (
                <AdditionalOffer
                    visibleColumnKeyIds={mainOffer.columns.map(c => c.id)}
                    colSpan={additionalOfferColSpan}
                    eventId={event.id}
                    onClose={e => onAdditionalOfferExpand(e)}
                />
            ) : null}
        </React.Fragment>
    )
};

export default defaultTemplate(OfferTemplate);