import React from "react";
import { defaultTemplate } from "../../../../../common/hoc";

export default defaultTemplate(function (props) {
	const { error, errorInfo, reload, t } = props;

	return (
		<main className="l--full">
			<div className="container container--main">
				<div className="row">
					<div className="view--sml u-padd--y--xlrg">
						<div className="u-type--lrg u-type--case--uppercase u-mar--bottom--sml">
							{t("ERROR.TITLE")}
						</div>
						<div className="u-mar--bottom--sml">
							{t("ERROR.MESSAGE")}
						</div>
						<details className="list--details u-display--none u-mar--bottom--med">
							<div className="list--details__title">
								{error && error?.toString()}
							</div>
							<div className="list--details__content">
								{errorInfo?.componentStack}
							</div>
						</details>
						<button
							className="btn btn--sml btn--neutral"
							type="button"
							onClick={(e) => reload()}
						>
							{t("ERROR.CTA_BUTTON_LABEL")}
						</button>
					</div>
				</div>
			</div>
		</main>
	);
});
