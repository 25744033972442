import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { BRStatistics } from './components';
import { ResultDataWrapper, ResultsMenu } from '../../../../pages/results/components';
import { Footer } from '../../../../common/components/footer';
import { ResultsSearch } from '../../../../pages/results/components';


export default observer(function ResultsPageTemplate(props) {
    
    const {
        resultsMenuStore,
        resultsMenuStore: {
            isMenuExpanded,
            toggleMenuExpanded
        }
    } = props;

    return (
        <>
            <main role="main" className={`l--main l--main--med${isMenuExpanded ? ' sidebar-left-expanded' : ''}`}>
                <div className="container container--bordered container--main">
                    <div className='row'>
                        <aside className={"l--main__menu col col-sml-2"}>
                            <ResultsSearch/>
                            <nav id="jqResult" className="menu--sport">
                                <ul id="results-menu" className="menu--sport__menu">
                                    <ResultsMenu store={resultsMenuStore} />
                                </ul>
                            </nav>
                        </aside>
                        <ResultDataWrapper />
                    </div>
                </div>
            </main>
            <footer className="l--footer">
                <Footer />
            </footer>
        </>
    )
})