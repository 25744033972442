import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function ActivationInProgressTemplate(props) {
	const { t } = useTranslation();

	return (
		<div className="popup">
			<div className="popup__card">
				<div className="popup__card__header">
					{t("MEMBERSHIP.ACCOUNT_ACTIVATION.INFO")}
				</div>
				<div className="popup__card__body">
					<div className="message--base message--note">
						{t("MEMBERSHIP.ACCOUNT_ACTIVATION.PLEASE_WAIT")}
					</div>
				</div>
				<div className="popup__card__footer">
					<Link
						to={`/${App.utils.getCurrentCulture()}/home/full`}
						className="btn btn--sml btn--primary"
					>
						<i className="u-icon u-icon--xsml u-icon--arrow-right--white"></i>{" "}
						{t("MEMBERSHIP.ACCOUNT_ACTIVATION.PROCEED_TO")}{" "}
						{t("MEMBERSHIP.ACCOUNT_ACTIVATION.HOME")}
					</Link>
				</div>
			</div>
		</div>
	);
}
