import React from 'react';
import PropTypes from 'prop-types';

class CheckboxTemplate extends React.Component {
    static propTypes = {
        indeterminate: PropTypes.bool
    };

    static defaultProps = {
        indeterminate: false
    };

    componentDidMount() {
        this.updateDeterminateProperty();
    }

    componentDidUpdate() {
        this.updateDeterminateProperty();
    }

    updateDeterminateProperty() {
        const { indeterminate } = this.props;

        this.checkbox.indeterminate = indeterminate;
    }

    render() {
        // extract indeterminate, we don't need it
        const { indeterminate, ...otherProps } = this.props;

        return <input {...otherProps} ref={c => { this.checkbox = c }} type="checkbox" />;
    }
}

export default CheckboxTemplate;