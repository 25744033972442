import React from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { LiveTemplate } from 'themes/pages/live';

@observer
@withRouter
class Live extends React.Component {
    constructor(props) {
        super(props);
    }
    
    componentDidMount() {
        this.props.liveViewStore.setDisplayFavorites(this.props.isFavorites || false);
    }

    componentDidUpdate(prevProps) {
        this.props.liveViewStore.setDisplayFavorites(!!this.props.isFavorites || false);
    }

    componentWillUnmount() {
        this.props.liveViewStore.onDispose();
    }
    render() {
        return (
            <LiveTemplate {...this.props} />
        );
    }
}

export default Live;
