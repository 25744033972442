export const OfferFile = {
    ADDITIONAL_OFFER_WITHOUT_CHARTS: 'ADDITIONAL_OFFER_WITHOUT_CHARTS',
    BASIC_OFFER_WITH_LONG_CHARTS: 'BASIC_OFFER_WITH_LONG_CHARTS',
    BASIC_OFFER_WITHOUT_CHARTS: 'BASIC_OFFER_WITHOUT_CHARTS',
    BASIC_OFFER_WITH_SHORT_CHARTS: 'BASIC_OFFER_WITH_SHORT_CHARTS',
    LAST_THREE_DAYS: 'LAST_THREE_DAYS',
    LONG_TERM_BETS: 'LONG_TERM_BETS',
    TODAY: 'TODAY',
    TOP_MATCHES: 'TOP_MATCHES',
    YESTERDAY: 'YESTERDAY'
}