import React, { useEffect, useMemo, useState } from "react";
import { LimitsHistoryTemplate } from "themes/pages/administration";
import { LimitsHistoryService } from "./services";
import { useForm } from "react-hook-form";
import { useLoading, useNotification } from "../../../common/hooks";
import { useTranslation } from "react-i18next";
import { ErrorPage } from "../../../common/components/error";

const periodsLookup = [
    {
        name: "LAST_3_MONTHS",
        id: "last3months"
    },
    {
        name: "LAST_6_MONTHS",
        id: "last6months",
    },
    {
        name: "LAST_9_MONTHS",
        id: "last9months"
    },
    {
        name: "LAST_1_YEAR",
        id: "last1year"
    }
]

const defaultModel = {
    period: "last6months",
    gamingTypeId: "default"
}

const limitsHistoryServiceInstance = new LimitsHistoryService();

function LimitsHistory() {

    const limitsHistoryService = useMemo(() => limitsHistoryServiceInstance, [])

    const { showError } = useNotification();
    const { setIsLoading } = useLoading();
    const { t } = useTranslation();

    const [lookups, setLookups] = useState({
        availableCategories: [],
        limitPeriods: [],
    });

    const methods = useForm({ mode: "onChange" });
    const { reset } = methods;

    useEffect(() => {

        App.state.rootStore.titleStore.setTitle(t('USER.ACCOUNT_SETTINGS.NAVIGATION.LIMITS_HISTORY'));

        //on mount scroll to top
        document.querySelector('html, body').scrollTo(0, 0);
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const categoriesResponse = await limitsHistoryService.getGamingLimitCategories();
                setLookups({ limitPeriods: periodsLookup, availableCategories: categoriesResponse })
                reset(defaultModel);
            } catch (error) {
                showError("USER.ACCOUNT_SETTINGS.ERR_HANDLING.GENERIC_ERROR");
            } finally {
                setIsLoading(false)
            }
        }
        fetchData();
    }, []);


    async function getLimitsHistoryData(data) {
        return await limitsHistoryService.getLimitHistoryData(data)
    }

    return (
        <ErrorPage>
            <LimitsHistoryTemplate
                lookups={lookups}
                methods={methods}
                getLimitsHistoryData={getLimitsHistoryData}
            />
        </ErrorPage>
    )
}

export default LimitsHistory;
