import React from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { LiveOfferTemplate } from 'themes/common/components/offer/v1';

/**
 * @typedef {Object} LiveOfferProps
 * @property {boolean} isLive
 * @property {boolean} showSportIcon
 * @property {Object} event
 * @property {Array} offer
 * @property {boolean} showOnlyTime
 * @property {*} main
 * @property {*} secondary
 * @property {Array} sportBettingTypes
 * @property {Array} sportBettingTypeTips
 * @property {boolean} isAdditionalOfferVisible
 * @property {function(string): void} onAdditionalOfferExpand 
 * @property {function(string): void} onClose
 * @property {*} onRenderAdditionalOfferButton
 */

// /**
//  * Renders live offer
//  * Used in legacy themes
//  * @param {LiveOfferProps} props 
//  */
// export default observer(withRouter(function (props) {
//     return <LiveOfferTemplate {...props} />
// }));

@inject(stores => ({
    offerStore: stores.offerStore
}))
@observer
@withRouter
class LiveOffer extends React.Component {
    constructor(props) {
        super(props);

        this.viewStore = new App.offer.components.OfferViewStore(props.offerStore, props.event);
    }

    render() {
        return <LiveOfferTemplate {...this.props} viewStore={this.viewStore} />
    }
}

export default LiveOffer;