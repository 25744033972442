import React from 'react';
import PropTypes from 'prop-types';
import { defaultTemplate } from '../../../../../common/hoc';
import { Modal } from '../../../../../common/components/modal';

class BetSlipSubmitConfirmationModalTemplate extends React.Component {
    render() {
        const { onAccept, onReject, t } = this.props;

        return (
          <Modal>
            <div className="popup">
              <div className="popup__card">
                <div className="popup__card__header">&nbsp;</div>
                <div className="popup__card__body">
                  <div className="message--base message--help">
                    <strong>{t('BET_SLIP.CONFIRM_SUBMIT')}</strong>
                  </div>
                </div>
                <div className="popup__card__footer">
                  <button className="btn btn--sml btn--accent" onClick={onReject}>
                    <i className="u-icon u-icon--sml u-icon--remove--white u-mar--right--tny" />
                    {t('BET_SLIP.NO')}
                  </button>
                  <button className="btn btn--sml btn--secondary u-mar--right--sml" onClick={onAccept}>
                    <i className="u-icon u-icon--sml u-icon--check--white u-mar--right--tny" />
                    {t('BET_SLIP.YES')}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        );
    }
}

BetSlipSubmitConfirmationModalTemplate.propTypes = {
    onAccept: PropTypes.func,
    onReject: PropTypes.func,
};

export default defaultTemplate(BetSlipSubmitConfirmationModalTemplate);