import React, { useContext } from "react";

import { observer } from 'mobx-react';

import { MyBetsStoreContext } from '../../../../../common/context';
import { useTranslation } from "react-i18next";

export default observer(function CashoutCounter(props) {
    const  {t} = useTranslation();
    const {
        cashoutStore: {
            isCountdownVisible,
            countdownCounter,
        }
    } = useContext(MyBetsStoreContext);

    if (!isCountdownVisible) {
        return null;
    }

    return (
        <div id="loader" className="loader" >
            <div className="loader__card" >
                <div className="loader--countdown">{countdownCounter}</div>
                <div class="loader__card__title">{t("COMMON.LOADING")}...</div>
                <span></span>
            </div>
        </div>
    )
})