import React, { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useAppContext } from "../../hooks/useAppContext";
import { DatePickerTemplate } from "themes/common/components/inputs";

export default function (props) {
	const form = useFormContext();
	const { culture } = useAppContext();
	const currentDateFormat = useLocaleDateFormatForDatePicker(culture);

	return (
		<DatePickerTemplate
			{...props}
			form={form}
			currentDateFormat={currentDateFormat}
		/>
	);
}

function useLocaleDateFormatForDatePicker(culture) {
	return useMemo(
		() =>
			new Intl.DateTimeFormat(culture)
				.formatToParts(Date.now())
				.filter((part) => part.type !== "literal")
				.map((part) => {
					if (part.type === "month") return "MM";
					if (part.type === "day") return "dd";
					if (part.type === "year") return "y";
				})
				.join("-"),
		culture
	);
}
