import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames'

const DISPLAY_SPORTS = [
	'soccer'
];

export const RedCardsTemplate = observer((props) => {
	if (!DISPLAY_SPORTS.includes(props.sport.abrv) || props.statistics == undefined) {
		return null;
	}

	let redCards = 0;

	if (props.statistics.redCards != undefined) {
		redCards += props.statistics.redCards;
	}
	if (props.statistics.redYellowCards != undefined) {
		redCards += props.statistics.redYellowCards;
	}

	if (redCards === 0) {
		return null;
	}

	return (
		<span className={classNames('offer__match__red-card', props.className)}>
			{redCards}
		</span>
	)
});