import { PasswordChangeService as PasswordChangeDataService } from "../../../../../administration/services/account-settings/password-change";
import { UserDataService } from "../../../../../administration/services/common";
import { Response, ErrorResponse } from "../../../../../administration/services/common";
import { isNullOrWhitespace } from "../../../../../utils/common/helpers";

export class PasswordChangeService {
    constructor() {
        this.passwordChangeService = new PasswordChangeDataService();
        this.userDataService = new UserDataService();
    }

    getUserEmailAndUserName() {
        const email = App.state.rootStore.userAuthStore.user.email;
        const userName = App.state.rootStore.userAuthStore.user.username;

        return new Response({ Email: email, Username: userName });
    }

    async changeUserPassword(model) {
        if (isNullOrWhitespace(model.OldPassword) || isNullOrWhitespace(model.NewPassword)) {
            throw new ErrorResponse({ message: 'GENERIC_ERROR' });
        }

        try {
            const passwordModel = {
                OldPassword: model.OldPassword,
                NewPassword: model.NewPassword,
                SendMailNotification: true,
                SiteUrl: `${location.origin}/${App.utils.getCurrentLanguage()}/home`
            };

            await this.passwordChangeService.changeUserPassword(passwordModel);
            return new Response();

        }
        catch (error) {

            if (error.message == 'Unauthorized') {
                return new ErrorResponse();
            }

            let errorMessage = "";

            if (error.data.errorCode == 400176) {

                errorMessage = "INVALID_PASSWORD";
            }
            else if (error.data.errorCode == 400170 || error.data.errorCode == 400171) {

                errorMessage = "ERROR_PWD_CHANGE_OLD_SAME";
            }
            else if (error.data.errorCode == 400165) {

                errorMessage = "ERROR_PWD_CHANGE_SIMILAR";
            }
            else if (error.data.errorCode == 400175) {
                await App.state.rootStore.userAuthStore.logoutUser();
                App.state.history.push(`/${App.utils.getCurrentCulture()}/auth/login?isChangePasswordFailed=true`);
                throw new ErrorResponse();
            }
            else {
                errorMessage = "ERROR_PWD_CHANGE";
            }
            throw new ErrorResponse({ message: errorMessage });
        }
    }
}
