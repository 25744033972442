import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import {
	getOfferColumns,
	getSecondaryOfferColumns,
	OfferStoreContext,
	SportOfferContext,
	EventContext,
	OfferOptionsContext
} from '@gp/components';

import { EventDetailsLive, EventOfferLive } from '../../../../../../common/components/offer/new-offer';

export const EventLiveTemplate = observer(function EventLiveTemplate(props) {

	const {
		isAlternate,

		hasSecondaryOffer,
		noSecondaryOffer,
	} = props;

	const offerStore = useContext(OfferStoreContext);
	const sport = useContext(SportOfferContext);
	const event = useContext(EventContext);

	const {
		numberOfColumns,
	} = useContext(OfferOptionsContext);

	const eventOffer = offerStore.eventKeysMap.get(event.id);

	if (eventOffer === undefined || eventOffer?.size === 0) {
		return (
			<EmptyRow isAlternate={isAlternate} />
		)
	}

	const eventKeys = Array.from(eventOffer.values());
	const sportSelector = offerStore.configuration.bettingTypeSelectorsStore.getSportSelector(sport);

	const mainColumns = getOfferColumns(eventKeys, sportSelector, numberOfColumns);

	let secondaryColumns = { nonEmptyColumns: 0, isEmpty: !hasSecondaryOffer };
	if (!noSecondaryOffer) {
		secondaryColumns = getSecondaryOfferColumns(eventKeys, sportSelector, numberOfColumns);
	}

	const additionalOfferCount = event.totalOfferCount - (mainColumns.nonEmptyColumns + secondaryColumns.nonEmptyColumns);


	return (
		<>
			{/* EVENT DETAILS & MAIN OFFER */}
			<div
				className={classnames(
					"offer__body__row",
					{
						'odd': !isAlternate,
						'offer__main': !secondaryColumns.isEmpty
					}
				)}
			>
				<EventDetailsLive hasSecondaryOffer={!secondaryColumns.isEmpty} />

				{/* Main offer */}
				<EventOfferLive columns={mainColumns} />

				{/* Additional offer button */}
				<AdditionalOfferButton
					count={additionalOfferCount}
					hasSecondary={!secondaryColumns.isEmpty}
				/>
			</div>

			<SecondaryOffer
				noSecondaryOffer={noSecondaryOffer}
				secondaryColumns={secondaryColumns}
				isAlternate={isAlternate}
			/>
		</>
	)
});

function SecondaryOffer(props) {

	const {
		noSecondaryOffer,

		secondaryColumns,
		isAlternate,
	} = props;

	if (secondaryColumns.isEmpty || noSecondaryOffer) {
		return null;
	}

	return (
		<div className={classnames(
			"offer__body__row offer__body__row offer__ht",
			{
				'odd': !isAlternate
			}
		)}>
			<div className="offer__ht__inner">
				{t('LIVE.FIRST_HALF_TIME')}
			</div>

			<EventOfferLive columns={secondaryColumns} />
		</div>
	)
}

const AdditionalOfferButton = observer(function AdditionalOfferButton(props) {

	const {
		count,
		hasSecondary,
	} = props;

	const event = useContext(EventContext);

	const url = `/${App.utils.getCurrentCulture()}/live/events/event/${event.id}`;

	if (count > 0) {
		return (
			<div
				className={classnames(
					"offer__body__data offer__more offer__more--sml",
					{
						"offer__more--stretch": hasSecondary
					}
				)}
			>
				<Link to={url} className="offer__more__btn btn btn--sml-square">
					<span>+{count}</span>
					<i className="offer__more__icon u-icon u-icon--tny u-icon--arrow-right" />
				</Link>
			</div>
		)
	}

	return (
		<div className="offer__body__data offer__more offer__more--sml is-empty">&nbsp;</div>
	)
})

function EmptyRow(props) {

	const {
		isAlternate,
	} = props;

	return (
		<div className={classnames("offer__body__row offer__body__row", { 'odd': !isAlternate })}>
			<EventDetailsLive />
			<EmptyColumns />
			<div className="offer__body__data offer__more offer__more--sml">&nbsp;</div>
		</div>
	)
}

function EmptyColumns(props) {

	const {
		numberOfColumns,
	} = useContext(OfferOptionsContext);

	return _.times(numberOfColumns, (i) => (
		<EmptyColum key={i} />
	))
}

function EmptyColum(props) {

	return (
		<div className='offer__body__data offer__quotes'>
			<div className='offer__quotes__row'>
				<div className='offer__quotes__row'></div>
				<div className='offer__quotes__row'></div>
				<div className='offer__quotes__row'></div>
			</div>
		</div>
	)
}