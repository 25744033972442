import React from 'react';
import { GlobalLoaderTemplate } from 'themes/common/components/loader';

export default function GlobalLoader(props) {
    if (props.isLoading) {
        return <GlobalLoaderTemplate {...props} />
    }
    else {
        return null;
    }
};