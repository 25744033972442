import React, { useState, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PasswordConfirmation } from "../../../../../common/components/administration";
import {
	useLoading,
	useNotification,
	usePasswordConfirmation,
} from "../../../../../common/hooks";

import { NoLockSection, WithLockSection } from "./components";

import { mapDataForPasswordConfirmation } from "./utils";
import { sortArray } from "../../../../../pages/administration/account-lock/utils";

export default function AccountLockTemplate(props) {
	const { t } = useTranslation();
	const { showSuccess, showWarning, showError } = useNotification();
	const { setIsLoading } = useLoading();

	const {
		methods,
		onCancel,
		onSubmitData,
		getAccountClosureViewData,
		createAccountLock,
	} = props;

	const { setShowPasswordConfirmation, showPasswordConfirmation } =
		usePasswordConfirmation();

	const [isLocked, setIsLocked] = useState(true);
	const [fetchedData, setFetchedData] = useState([]);
	const {
		getValues,
		formState: { dirtyFields },
		reset,
	} = methods;

	const [lookups, setLookups] = useState({
		durationTypes: [],
		reasonTypes: [],
	});

	const lookupsInitialData = {
		durationAbrvs: ["7-days", "14-days", "30-days", "permanent"],
		reasonAbrvs: [
			"other-reasons",
			"no-longer-interested-in-sports-betting",
			"gambling-addiction-prevention",
			"not-satisfied-with-sports-betting-services",
		],
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				setIsLoading(true);
				const response = await getAccountClosureViewData(
					lookupsInitialData
				);
				reset({ lockDuration: "", lockReason: "" });
				setFetchedData(response.data);
				setIsLocked(response.data.isLocked);
				setLookups({
					reasonTypes: response.data.reasonTypes,
					durationTypes: sortArray(response.data.durationTypes),
				});
			} catch (error) {
				showError(
					t(
						`USER.ACCOUNT_SETTINGS.ERR_HANDLING.${error?.error?.message}`
					)
				);
			} finally {
				setIsLoading(false);
			}
		};
		fetchData();
	}, [isLocked]);

	const onSuccess = async (password) => {
		const data = getValues();
		try {
			setIsLoading(true);
			const response = await createAccountLock({ ...data, password });
			if (response.success) {
				showSuccess(
					"USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.ACCOUNT_LOCK_SUCCESS"
				);
				setShowPasswordConfirmation(false);
				setIsLocked(true);
			}
		} catch (error) {
			showWarning(
				"USER.ACCOUNT_SETTINGS.ERR_HANDLING." + error?.error?.message
			);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			{fetchedData != undefined && (
				<>
					{isLocked ? (
						<WithLockSection fetchedData={fetchedData} />
					) : (
						<FormProvider {...methods}>
							{!showPasswordConfirmation ? (
								<NoLockSection
									fetchedData={fetchedData}
									sortDurationTypes={lookups.durationTypes}
									methods={methods}
									onSubmitData={onSubmitData}
									reasonTypes={lookups.reasonTypes}
								/>
							) : (
								<PasswordConfirmation
									data={mapDataForPasswordConfirmation(
										getValues(),
										dirtyFields,
										lookups.reasonTypes,
										lookups.durationTypes
									)}
									onSuccess={onSuccess}
									onCancel={onCancel}
									optionalMessage="USER.ACCOUNT_SETTINGS.ACCOUNT_LOCK.NOT_ABLE_PLACE_BET"
								/>
							)}
						</FormProvider>
					)}
				</>
			)}
		</>
	);
}
