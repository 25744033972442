import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useLoading, useNotification } from "../../../../common/hooks";
import { accountStatementServiceInstance } from "../../services/AccountStatementService";
import { AccountStatementFilterTemplate } from "themes/pages/account-statement/components/filter";

export default function AccountStatementFilter(props) {

    const [showCustomDateSection, setShowCustomDateSection] = useState(false);
    const [filterData, setFilterData] = useState({});

    const methods = useForm({
        mode: "onChange"
    });

    const { resetField, reset } = methods;

    const { setIsLoading } = useLoading();

    const { showError } = useNotification();

    const {
        setStatementFilter,
        ...rest
    } = props;

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const response = await accountStatementServiceInstance.getFilterData();
                if (response.success) {
                    setFilterData(response.data);
                    setStatementFilter({ asPeriod: 'today', asType: 'all' })
                    reset({ asPeriod: 'today', asType: 'all' });
                }
            }
            catch (error) {
                showError('ACC_STATEMENT.FILTER.' + error?.error?.message);
            }
            finally {
                setIsLoading(false);
            }
        }

        fetchData();
    }, []);

    const handleChange = event => {
        if (event.target.value === 'custom' && !showCustomDateSection) {
            setShowCustomDateSection(true);
        } else if (showCustomDateSection) {
            resetField('startDate');
            resetField('endDate');
            setShowCustomDateSection(false);
        }
    }

    const onFilterSubmit = (formData) => {
        setStatementFilter(formData);
    }

    return <FormProvider {...methods}>
        <AccountStatementFilterTemplate
            onFilterSubmit={onFilterSubmit}
            handleChange={handleChange}
            filterData={filterData}
            showCustomDateSection={showCustomDateSection}
            {...rest}
        />
        </FormProvider>
}