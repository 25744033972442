import React from 'react';
import ReactDOM from 'react-dom';
import { observer, inject } from 'mobx-react';

import { StatisticsTemplate } from 'themes/common/components/statistics';

@inject(stores => ({
    statisticsStore: stores.offerStore.statisticsStore
}))
@observer
class Statistics extends React.Component {
    async componentDidMount() {
        const { 
            statisticsStore: { 
                setCurrentEvent, 
                onInitialize, 
                setCurrentTournamentId 
            }, 
            currentEvent, 
            tournamentEvents, 
            tournamentId 
        } = this.props;
        
        if (currentEvent != null) {
            setCurrentEvent(currentEvent);
        }
        if (tournamentEvents != null && tournamentEvents.length > 0) {
            setCurrentTournamentId(tournamentEvents[0].tournamentId);
        }

        if (tournamentId != null) {
            setCurrentTournamentId(tournamentId);
        }

        await onInitialize();
    }

    componentWillUnmount() {
        this.props.statisticsStore.onDispose();
    }

    render() {
        if (this.props.elContainer) {
            return ReactDOM.createPortal(
                React.cloneElement(<StatisticsTemplate {...this.props} />),
                document.getElementById('match-stats')
            );
        }

        return <StatisticsTemplate {...this.props} />;
    }
}

export default Statistics;