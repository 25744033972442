import React from 'react';
import { defaultTemplate } from '../../../../../common/hoc';

export default defaultTemplate(function (props) {
    const { clock, t } = props;

    const time = clock.getTime();

    const currentDate = new Date();
    const timezoneOffset = currentDate.getTimezoneOffset();
    const hh = currentDate.getHours();
    const mm = currentDate.getMinutes();
    const ss = currentDate.getSeconds();
    const offset = timezoneOffset / 60;

    const calculatedOffset = () => {
        if (offset < 0) {
            return `GMT+${Math.abs(offset)}`
        } else if (offset == 0) {
            return `GMT+0`
        } else {
            return `GMT${-Math.abs(offset)}`
        }
    }

    const offsetConvert = calculatedOffset();

    const timeStr = `${(hh < 10 ? "0" : "")}${hh}:${mm < 10 ? "0" : ""}${mm}:${(ss < 10 ? "0" : "")}${ss} ${offsetConvert} `;

    return (
        <div className="header--primary__top__time">
            <i className="u-icon u-icon--sml u-icon--clock" />
            <time>
                {timeStr}
            </time>
        </div>
    )
});