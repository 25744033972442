import { ErrorResponse, UserDataService, Response } from "../../../../../administration/services/common";


export class AcceptNewTermsService {
    constructor() {
        this.userDataService = new UserDataService();
    }

    async acceptRegulation() {
        try {
            await this.userDataService.acceptRegulation();
            return new Response();
        } catch (error) {
            console.log(error)
            throw new ErrorResponse("GENERIC_ERROR");
        }
    }
}