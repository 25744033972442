import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Header(props) {

    const {
        offerCategory,
    } = props;

    const { t } = useTranslation();

    const offerCategoryToUpper = offerCategory.toUpperCase();
    const offerCategoryToLower = offerCategory.toLowerCase();

    return (
        <thead className="offer__head">
            <tr className="offer__head__row">
                <th className="offer__head__data" colSpan={2}>
                    {t(`HOME.${offerCategoryToUpper}.TITLE`)}
                </th>
                <th className="offer__head__data offer__header--quote">
                    <span className="offer__header--quote__type">{t('BETTING_TYPE.3WAY')}</span>
                    <i
                        data-tip={t('BETTING_TYPE.HEADER.3way_info')}
                        data-for={`${offerCategoryToLower}HomeHeaderTooltip`}
                        className="offer__header--quote__icon u-icon u-icon--xsml u-icon--info--white"
                    />
                </th>
                <th className="offer__head__data offer__header--quote">
                    <span className="offer__header--quote__type">{t('BETTING_TYPE.OVER_UNDER')}</span>
                    <i
                        data-tip={t('BETTING_TYPE.HEADER.over-under_info')}
                        data-for={`${offerCategoryToLower}HomeHeaderTooltip`}
                        className="offer__header--quote__icon u-icon u-icon--xsml u-icon--info--white"
                    />
                </th>

                <th className="offer__head__data u-type--right" colSpan={2}>
                    <i className="u-icon u-icon--dnf u-icon--dnf--star-alt u-type--lrg u-align--v--middle" />
                </th>
            </tr>
            <tr className="offer__subheader">
                <th className="offer__subheader__data offer__subheader__empty" colSpan={2}></th>
                <th className="offer__subheader__data offer__subheader__tip">
                    <div className="offer__subheader__tip__row">
                        <div className="offer__subheader__tip__item">1</div>
                        <div className="offer__subheader__tip__item">X</div>
                        <div className="offer__subheader__tip__item">2</div>
                    </div>
                </th>
                <th className="offer__subheader__data offer__subheader__tip">
                    <div className="offer__subheader__tip__row">
                        <div className="offer__subheader__tip__item">&nbsp;</div>
                        <div className="offer__subheader__tip__item">+</div>
                        <div className="offer__subheader__tip__item">-</div>
                    </div>
                </th>
                <th className="offer__subheader__data" colSpan={2}></th>
            </tr>
        </thead>
    )
}