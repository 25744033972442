import React, { useContext, useState } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";
import { Statistics } from "../../../../../../common/components/statistics";
import { EventContext, LookupContext } from "@gp/components";
import { useFixBody } from "../../../../../../common/hooks";

export default observer(function StatisticsButtonTemplate(props) {
	const {
		isOfferButton,
		tournamentEvents,
		tournamentId,
		currentSport,
		currentTournament,
	} = props;

	//#region context

	const event = useContext(EventContext);
	const lookupsStore = useContext(LookupContext);

	let teams = null;

	if (lookupsStore != null) {
		teams = lookupsStore.teams;
	}

	const [statisticsOpen, setStatistics] = useState(false);

	//#endregion context

	//#region  teams

	const homeTeamName =
		teams != null ? teams.get(event.teamOneId)?.name : event.homeTeam;
	const awayTeamName =
		teams != null ? teams.get(event.teamTwoId)?.name : event.awayTeam;

	//#endregion teams

	//#region css classes

	const buttonClasses = classNames(
		"jqMatchStats",
		{
			offer__actions__btn: isOfferButton,
			offer__actions__stats: isOfferButton,
		},
		"btn",
		{
			"btn--xtny-square": isOfferButton,
		},
		"btn--link",
		{
			"btn--icon-center": isOfferButton,
			"u-mar-right-sml": !isOfferButton,
		}
	);

	const iconClasses = classNames(
		{
			offer__actions__icon: isOfferButton,
		},
		"u-icon",
		{
			"u-icon--med": isOfferButton,
			"u-icon--offer": !isOfferButton,
		},
		{
			"u-icon--stats": isOfferButton,
			"u-icon--stats--neutral": !isOfferButton,
		}
	);

	//#endregion css classes

	useFixBody(true, statisticsOpen);
	return (
		<React.Fragment>
			{statisticsOpen && (
				<Statistics
					currentSport={currentSport}
					currentTournament={currentTournament}
					currentEvent={event}
					tournamentId={tournamentId}
					homeTeam={homeTeamName}
					awayTeam={awayTeamName}
					closeStatisticsModal={() => setStatistics(false)}
					tournamentEvents={tournamentEvents}
				/>
			)}
			<button
				className={buttonClasses}
				type="button"
				onClick={() => setStatistics(true)}
			>
				<i className={iconClasses} />
			</button>
		</React.Fragment>
	);
});
