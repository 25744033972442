const httpClient = App.utils.httpClient;
const requestHeaders = {
	"Content-Type": "application/json",
};
class AccountLockReasonTypesLookupService {
	/**
	 *
	 * @returns {promise}
	 */
	findLookup() {
		const url = `${BaseAddress}platform/user-account-lock-reason-types?fields=id,abrv,name,languageMetadata&translate=true&rpp=20`;
		return httpClient.get(url, requestHeaders, { responseType: "json" });
	}
}

export default new AccountLockReasonTypesLookupService();
