import React, { useContext } from "react";
import { observer } from "mobx-react";
import { maxBy } from "lodash";

import { EventContext, SportOfferContext } from "@gp/components";

import { ScoreTemplate } from "themes/common/components/offer/new-offer/common";

const pauseStatuses = [
	"not-started",
	"first-break",
	"second-break",
	"third-break",
	"fourth-break",
	"fifth-break",
	"sixth-break",
	"ended",
];

const pauseSkipSports = ["volleyball", "beach-volley"];

const periodSports = ["tennis", "table-tennis", "volleyball", "beach-volley"];

const htSports = ["soccer", "futsal"];

/**
 * Formats game score
 * @param {string} input
 * @returns {string} formatted game score
 */
const formatGameScore = (input) =>
	input.startsWith("g") ? input.substr(1) : input.toUpperCase();

export const Score = observer(function Score(props) {
	const event = useContext(EventContext);
	const sport = useContext(SportOfferContext);

	let gameScore;
	if (sport.abrv === "tennis") {
		gameScore = { home: "0", away: "0" };
		if (event.result?.currentGameScore) {
			if (event.result?.currentGameScore.home) {
				gameScore.home = formatGameScore(
					event.result?.currentGameScore.home
				);
			}
			if (event.result?.currentGameScore.away) {
				gameScore.away = formatGameScore(
					event.result?.currentGameScore.away
				);
			}
		}

		if (event.result?.tiebreak) {
			if (event.result?.tiebreakScore) {
				gameScore.home = event.result?.tiebreakScore.home || "0";
				gameScore.away = event.result?.tiebreakScore.away || "0";
			} else {
				gameScore.home = "0";
				gameScore.away = "0";
			}
		}
	}

	let lastPeriodScore = null;
	const spp =
		event.result?.scorePerPeriod != null
			? event.result?.scorePerPeriod.slice()
			: [];
	if (periodSports.includes(sport.abrv)) {
		if (
			pauseSkipSports.includes(sport.abrv) &&
			pauseStatuses.includes(event.matchStatus)
		) {
			lastPeriodScore = {
				home: "-",
				away: "-",
			};
		} else {
			if (spp.some((el) => el.periodType === "OT")) {
				lastPeriodScore = spp
					.reverse()
					.find((el) => el.periodType === "OT");
			} else {
				lastPeriodScore = maxBy(spp, "periodNumber");
			}
		}
	} else if (spp.length > 1 && htSports.includes(sport.abrv)) {
		// find first HT
		if (spp.some((el) => el.periodType === "OT")) {
			lastPeriodScore = spp.find(
				(el) => el.periodType === "OT" && el.periodNumber === 1
			);
		} else {
			lastPeriodScore = spp.find(
				(el) => el.periodType === "HALF" && el.periodNumber === 1
			);
		}
	}

	return (
		<ScoreTemplate
			serve={event.result?.serve}
			currentScore={event.currentScore}
			gameScore={gameScore}
			lastPeriodScore={lastPeriodScore}
			sport={sport}
		/>
	);
});
