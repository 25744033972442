import React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import { constants } from "@gp/gp-offer-utils";

import {
	LiveMatchResult,
	LiveMatchTime,
	LiveColumn,
	LiveOfferResult,
} from "../../../../../../common/components/offer/v1";
import { AdditionalOffer } from "../../../../../../common/components/additional-offer";

import { StatisticsButton } from "../../../../../../common/components/offer/buttons";

const SecondaryOffer = function (props) {
	if (props.isEmpty) {
		return null;
	}

	return (
		<tr
			className={classNames("offer__body__row offer__ht", {
				"is-expanded": props.isAdditionalOfferVisible,
				odd: !props.isAlt,
			})}
		>
			<td className="offer__body__data offer__ht__inner" colSpan={3}>
				{props.t("LIVE.FIRST_HALF_TIME")}
			</td>
			{props.columns.map((c) => c)}
		</tr>
	);
};

const RedCards = function (props) {
	const displaySports = ["soccer"];

	if (!displaySports.includes(props.sport.abrv) || props.statistics == null) {
		return null;
	}

	let redCards = 0;

	if (!isNaN(props.statistics.redCards)) {
		redCards += props.statistics.redCards;
	}
	if (!isNaN(props.statistics.redYellowCards)) {
		redCards += props.statistics.redYellowCards;
	}

	if (redCards === 0) {
		return null;
	}

	return <span className="offer__match__red-card">{redCards}</span>;
};

function LiveOfferTemplate(props) {
	const {
		isAlt,
		event,
		sport,
		main,
		secondary,
		showFavorites,
		addEventToFavorites,
		removeEventFromFavorites,
		viewStore: {
			isAdditionalOfferVisible,
			onAdditionalOfferExpand,
			isScore,
			toggleIsScore,
		},
		match: {
			params: { culture },
		},
		t,
	} = props;

	/**
	 * @param {Event} clickEvent
	 */
	function onAdditionalOfferClick(clickEvent) {
		if (clickEvent) {
			clickEvent.preventDefault();
		}

		onAdditionalOfferExpand(clickEvent);
	}

	const additionalOfferButtonClasses = classNames(
		"offer__more__btn btn btn--sml-square",
		{
			"btn--ghost": !isAdditionalOfferVisible,
			"btn--primary": isAdditionalOfferVisible,
		}
	);

	const additionalOfferIconClasses = classNames(
		"offer__more__icon u-icon u-icon--tny",
		{
			"u-icon--arrow-right": !isAdditionalOfferVisible,
			"u-icon--arrow-right--white": isAdditionalOfferVisible,
		}
	);

	/**
	 * @param {Object} columnConfig
	 */
	const mapOffer = (columnConfig) => {
		return Object.keys(columnConfig).reduce(
			(acc, col) => {
				const colData = columnConfig[col];

				if (colData == null || colData.length === 0) {
					acc.columns.push({
						id: null,
						component: (
							<LiveColumn key={col} matchTime={event.matchTime} />
						),
					});
					return acc;
				}

				if (colData[0].bettingType === constants.resultPlaceholder) {
					acc.columns.push({
						id: null,
						component: (
							<LiveOfferResult
								key={col}
								sport={sport}
								result={event.result}
								matchStatus={event.matchStatus}
							/>
						),
					});
					return acc;
				}

				const colBettingTypes = colData.map((cd) => cd.bettingType);

				// find offer for given column
				const colOffer = event.bettingTypes.find((bt) =>
					colBettingTypes.includes(bt.abrv)
				);

				if (colOffer == null) {
					acc.columns.push({
						id: null,
						component: (
							<LiveColumn key={col} matchTime={event.matchTime} />
						),
					});
					return acc;
				}

				const offer = colOffer.keyOffers[0];

				if (offer == null) {
					acc.columns.push({
						id: null,
						component: (
							<LiveColumn key={col} matchTime={event.matchTime} />
						),
					});
					return acc;
				}

				const tips = colData.find(
					(cd) => cd.bettingType === colOffer.abrv
				).tips;

				acc.columns.push({
					id: offer.keyId,
					component: (
						<LiveColumn
							key={offer.keyId}
							offer={offer.offers}
							tips={tips}
							matchTime={event.matchTime}
						/>
					),
				});
				acc.nonEmptyColumns++;
				acc.isEmpty = false;

				return acc;
			},
			{
				isEmpty: true,
				nonEmptyColumns: 0,
				columns: [],
			}
		);
	};

	const mainOffer = mapOffer(main);
	const secondaryOffer = mapOffer(secondary);

	const additionalOfferCount =
		event.totalOfferCount -
		(mainOffer.nonEmptyColumns + secondaryOffer.nonEmptyColumns);

	const getName = (name, gender) => {
		if (gender === 1) {
			return `${name} (${t("OFFER.GENDER.WOMEN")})`;
		}

		return name;
	};

	const homeTeamName = getName(event.homeTeam, event.homeTeamGender);
	const awayTeamName = getName(event.awayTeam, event.awayTeamGender);

	return (
		<React.Fragment>
			<tr
				className={classNames("offer__body__row offer__main", {
					odd: !isAlt,
				})}
			>
				<LiveMatchTime
					matchTime={event.matchTime}
					rowSpan={!secondaryOffer.isEmpty ? "2" : ""}
					event={event}
				/>

				<td className="offer__body__data offer__favorites">
					<button
						className="offer__favorites__btn btn btn--xtny btn--link btn--icon-center"
						type="button"
						onClick={() => {
							showFavorites
								? removeEventFromFavorites(event.id)
								: addEventToFavorites(event.id);
						}}
					>
						{showFavorites ? (
							<i className="u-icon u-icon--sml u-icon--favorites--active" />
						) : (
							<i className="u-icon u-icon--sml u-icon--favorites--inactive" />
						)}
					</button>
				</td>

				<td className="offer__body__data offer__match">
					<Link
						className="offer__match__competitors"
						to={`/${culture}/live/events/event/${event.id}`}
						data-tip={`${event.category.name} - ${event.tournament.name}: ${homeTeamName} - ${awayTeamName}`}
						data-for={event.id}
					>
						{event.homeTeam && (
							<div className="offer__match__team">
								<RedCards
									sport={sport}
									statistics={
										event.result?.homeTeamTotalStatistics
									}
								/>
								{homeTeamName}
							</div>
						)}
						{/* render result */}
						<LiveMatchResult
							matchStatus={event.matchStatus}
							currentScore={event.currentScore}
							result={event.result}
							isScore={isScore}
							toggleIsScore={toggleIsScore}
							sportAbrv={sport.abrv}
						/>
						{event.awayTeam && (
							<div className="offer__match__team">
								<RedCards
									sport={sport}
									statistics={
										event.result?.awayTeamTotalStatistics
									}
								/>
								{awayTeamName}
							</div>
						)}
						<ReactTooltip
							id={event.id}
							className="tooltip--tertiary"
							place="bottom"
							multiline={true}
						/>
					</Link>
				</td>

				<td className="offer__body__data offer__actions">
					<StatisticsButton />
				</td>

				{/* MAIN OFFER */}
				{mainOffer.columns.map((c) => c.component)}

				<td
					className="offer__body__data offer__more"
					rowSpan={!secondaryOffer.isEmpty ? "2" : ""}
				>
					{additionalOfferCount > 0 && (
						<button
							className={
								additionalOfferButtonClasses +
								(!secondaryOffer.isEmpty
									? " offer__more__btn--stretch"
									: "")
							}
							type="button"
							onClick={() => onAdditionalOfferClick()}
						>
							<span>+{additionalOfferCount}</span>
							<i className={additionalOfferIconClasses} />
						</button>
					)}
				</td>
			</tr>
			{/* SECONDARY OFFER */}
			<SecondaryOffer
				isEmpty={secondaryOffer.isEmpty}
				columns={secondaryOffer.columns.map((c) => c.component)}
				isAdditionalOfferVisible={isAdditionalOfferVisible}
				isAlt={isAlt}
				t={t}
			/>
			{/* {!secondaryOffer.isEmpty ? (
                <tr className={classNames("offer__body__row offer__body__row offer__ht", { "is-expanded": isAdditionalOfferVisible, "odd": !isAlt })}>
                    <td className="offer__ht__inner" colSpan={3}>1st halftime</td>
                    {secondaryOffer.columns.map(c => c.component)}
                </tr>
            ) : null} */}
			{isAdditionalOfferVisible ? (
				<AdditionalOffer
					enableBettingTypeGroups
					colSpan={17}
					eventId={event.id}
					onClose={(e) => onAdditionalOfferClick(e)}
					visibleColumnKeyIds={[
						...mainOffer.columns,
						...secondaryOffer.columns,
					]
						.filter((c) => c.id != null)
						.map((c) => c.id)}
					isLive
				/>
			) : null}
		</React.Fragment>
	);
}

export default observer(LiveOfferTemplate);
