import React from "react";
import { Button } from ".";
import { useFormContext } from "react-hook-form";

export default function (props) {
	const {
		formState: { dirtyFields, isSubmitting, errors, isValid },
	} = useFormContext();

	const isButtonDisabled =
		Object.keys(dirtyFields).length == 0 ||
		Object.keys(errors).length != 0 ||
		isSubmitting ||
		!isValid;

	return <Button {...props} disabled={isButtonDisabled} />;
}
