export class DecreaseViewModel {
    constructor(currentLimit, bettingUserLimitType,bettingUserLimitDuration,userAccount){
        this.limitDateUpdated = currentLimit.dateUpdated;
        this.limitAmount = currentLimit.limitAmount;
        this.displayLimitAmount = currentLimit.limitAmount;
        this.limitName = bettingUserLimitType.name;
        this.limitId = currentLimit.id;
        this.selectedLimitTypeName = bettingUserLimitType.name;
        this.limitTypeAbrv = bettingUserLimitType.abrv;
        this.limitDurationAbrv = bettingUserLimitDuration.abrv;
        this.selectedLimitDurationName = bettingUserLimitDuration.name;
        this.lostAmount = currentLimit.usedLimitAmount;
        this.displayLostAmount = currentLimit.usedLimitAmount;
        this.currencyDisplayCode = userAccount.item[0].currency.displayCode;
        this.bettingUserLimitDurationId = currentLimit.bettingUserLimitDurationId;
        this.bettingUserLimitTypeId = currentLimit.bettingUserLimitTypeId;
    }
}