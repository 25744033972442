import React from "react";
import { observer } from "mobx-react-lite";
import { useFixBody } from "../../hooks";
import { ConfirmationHandlingPopupTemplate } from "themes/common/components/popups";

export default observer(function ConfirmationHandlingPopup(props) {

    const {
        setShowSuccess
    } = props;

    const {
        countryLookup,
        isConfirmationHandlingPopupShown,
        acceptDataVerification
    } = App.state.rootStore.userAuthStore.confirmationHandlingStore;

    useFixBody(true, isConfirmationHandlingPopupShown);

    const findCountryName = (countryId) => {
        return countryLookup.find(c => c.id === countryId).name
    }

    const confirmVerification = async () => {
        await acceptDataVerification();
        if (setShowSuccess != null) {
            setShowSuccess(true);
        }
    }

    return <ConfirmationHandlingPopupTemplate
        findCountryName={findCountryName}
        confirmVerification={confirmVerification}
    />
})