import React from 'react';
import { observer, inject } from 'mobx-react';
import { PrematchRouteLayoutTemplate } from 'themes/layouts';

export default inject(stores => ({
    isMenuLoading: stores.offerStore.prematchMenuViewStore.isLoading
}))(observer(function (props) {
    return (
        <PrematchRouteLayoutTemplate {...props} />
    );
}));