const httpClient = App.utils.httpClient;

const requestHeaders = {
    'Content-Type': 'application/json',
};

const baseUrl = `${BaseAddress}platform/contact-support/`;


export class ContactService {


    async sendInquiry(contact) {
        contact.shopId = App.utils.getShopId();
        contact.agencyId = App.utils.getAgencyKey();

        return await httpClient.post(
            baseUrl,
            JSON.stringify(contact),
            requestHeaders
        )
    }

}

