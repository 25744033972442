import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { constants } from '@gp/offer';
import { OfferStoreContext, SportOfferContext } from '@gp/components';

import { OfferTip } from '../../../../../../../common/components/offer/new-offer/common';

export const OfferColumnTemplate = observer(function OfferColumnTemplate(props) {

	const {
		tips,
		columnIndex,
		offerKeyId,
	} = props;

	const sport = useContext(SportOfferContext);
	const offerStore = useContext(OfferStoreContext);

	const hasFourthTip = sport.headers.primary[columnIndex + 1] && sport.headers.primary[columnIndex + 1].length && sport.headers.primary[columnIndex + 1][0].displayTips.length >= 4;
	if (tips === undefined) {
		return <EmptyTips hasFourthTip={hasFourthTip} />;
	}

	const isMarginal = tips[0] === constants.marginPlaceholder;

	if (offerKeyId === undefined) {
		return <EmptyTips isMarginal={isMarginal} hasFourthTip={hasFourthTip} />;
	}

	const offerMap = offerStore.keyOffersMap.get(offerKeyId);

	if (offerMap == undefined || offerMap.size === 0) {
		return <EmptyTips isMarginal={isMarginal} hasFourthTip={hasFourthTip} />;
	}

	const tipsForRender = GetTipsForRender(tips, offerMap, offerStore);

	return (
		<td className="offer__body__data offer__quotes">
			<div className="offer__quotes__row">
				{tipsForRender.map(tip => {
					if (tip == null || Object.keys(tip).length === 0) {
						/** If tip is null or is empty object. */
						return null;
					}

					const tipKey = tip.tip != null ? tip.tip.id : 'margin' + tip.margin;

					return (
						<OfferTip key={tipKey} {...tip} />
					);
				})}
			</div>
		</td>
	)
});

function EmptyTips(props) {

	const {
		isMarginal,
		hasFourthTip,
	} = props;

	const elementClasses = classNames(
		"offer__body__data offer__quotes",
		{
			'offer__quotes__special': hasFourthTip
		}
	)

	return (
		<td className={elementClasses}>
			<div className="offer__quotes__row">
				{(isMarginal || hasFourthTip) ? (
					<OfferTip className="quote quote--button--alt is-empty" />
				) : (
					<OfferTip className="quote quote--placeholder" />
				)}
				<OfferTip className="quote quote--placeholder" />
				<OfferTip className="quote quote--placeholder" />
				{hasFourthTip ? <OfferTip className="quote quote--placeholder" /> : null}
			</div>
		</td>
	)
}

function GetTipsForRender(tips, offerMap, offerStore) {

	const offers = Array.from(offerMap.values());

	/** List of tips to be rendered. */
	const tipsForRender = [];

	for (let i = 0; i < Math.max(tips?.length, offers.length); i++) {
		const currentTip = tips[i];

		if (currentTip == null) {
			if (offers[i] != null) {
				tipsForRender.push({
					isMargin: false,
					tip: offers[i]
				});
				continue;
			}

			// when we reach first null offer, we know everything else behind is null. Thus we break the loop
			break;
		}

		if (currentTip === constants.emptyPlaceholder) {
			tipsForRender.push({
				key: currentTip + '-' + i,
				isMargin: false,
				tip: null
			});
			continue;
		}

		if ([constants.setnrPlaceholder, constants.gamenrPlaceholder].includes(currentTip)) {
			tipsForRender.push({
				isResult: true,
				result: offers[0].specifier[currentTip] + '. set',
			});
		}
		else if (currentTip === constants.marginPlaceholder) {
			tipsForRender.push({
				key: currentTip + '-' + i,
				isMargin: true,
				margin: offerStore.getMarginByOffer(offers[0]),
				tip: null
			});		
		}
		else if (currentTip.trim() === '') {
			tipsForRender.push({
				key: currentTip + '-' + i,
				isMargin: false,
				tip: null
			});
			continue;
		}
		else {
			const tipOffer = offers.find(o => o.tip.toLowerCase().startsWith(currentTip));

			if (tipOffer == null) {
				tipsForRender.push({
					key: currentTip + '-' + i,
					isMargin: false,
					tip: null
				});
				continue;
			}

			tipsForRender.push({
				isMargin: false,
				tip: tipOffer
			});
		}
	}

	return tipsForRender
}