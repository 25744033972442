import { AutomaticLogoutService as AutomaticLogoutDataService } from "../../../../../administration/services/account-settings/automatic-logout/AutomaticLogoutService";
import { UserDataService } from "../../../../../administration/services/common";
import {
	Response,
	ErrorResponse,
} from "../../../../../administration/services/common/Models";
import { logoutIntervalLookup } from "../../../../../administration/services/account-settings/automatic-logout/lookups/LogoutIntervalLookupService";
import { bettingTimeoutLookup } from "../../../../../administration/services/account-settings/automatic-logout/lookups/BettingTimeoutLookupService";
import { DateTime } from "luxon";

export class AutomaticLogoutService {
	constructor() {
		this.automaticLogoutDataService = new AutomaticLogoutDataService();
		this.userDataService = new UserDataService();
	}

	async getAutoLogoutPeriodsLookupAsync() {
		const response = await logoutIntervalLookup.findLookup();
		return response;
	}

	async getBettingTimeoutLookupAsync() {
		const response = await bettingTimeoutLookup.findLookup();
		return response;
	}

	async getAutomaticLogoutData() {
		const automaticLogoutModelData = {
			data: {
				bettingTimeoutId: "",
				logoutIntervalId: "",
				deactivateBettingTimeoutId: "",
				deactivateLogoutIntervalId: "",
			},
			message: {
				settingsMessage: "",
				autoLogoutTime: "",
				logoutIntervalTime: "",
				bettingTimeoutTime: "",
			},
		};
		try {
			// using await for catching exception
			const response = await this.setAutomaticLogoutSettings(
				automaticLogoutModelData
			);
			return response;
		} catch (error) {
			if (error.message == "Unauthorized") {
				return new ErrorResponse();
			}
			throw new ErrorResponse({ message: "GENERIC_ERROR" });
		}
	}

	async setAutomaticLogoutSettings(model) {
		const user = await this.userDataService.getUserInfo();
		const autoLogoutTypes = await this.getAutoLogoutPeriodsLookupAsync();
		const bettingTimeoutTypes = await this.getBettingTimeoutLookupAsync();

		if (
			autoLogoutTypes.item.length == 0 ||
			bettingTimeoutTypes.item.length == 0
		) {
			return new ErrorResponse({ message: "GENERIC_ERROR" });
		}

		const deactivateInSevenDays = DateTime.local().minus({ days: 7 });

		if (user != null) {
			const deactivateAutoLogoutAllowed =
				user.autoLogoutPeriodStartDate <
				deactivateInSevenDays.toJSDate().toISOString();

			const filteredAutoLogoutTypes = !deactivateAutoLogoutAllowed
				? autoLogoutTypes.item
						.filter((x) => x.units != 0)
						.sort((x) => x.units)
				: autoLogoutTypes.item.sort((x) => x.units);

			model.data.logoutIntervalId = user.autoLogoutPeriodId;
			model.data.bettingTimeoutId = user.bettingTimeoutId;

			model.autoLogoutTypes = filteredAutoLogoutTypes.map((x) => {
				return {
					id: x.id,
					abrv: x.abrv,
					name: x.name,
					units: x.units,
				};
			});

			const deactivateBettingTimeoutAllowed =
				user.autoLogoutPeriodStartDate <
				deactivateInSevenDays.toJSDate().toISOString();

			const filteredBettingTimeoutTypes = !deactivateBettingTimeoutAllowed
				? bettingTimeoutTypes.item
						.filter((x) => x.units != 0)
						.sort((x) => x.units)
				: bettingTimeoutTypes.item.sort((x) => x.units);

			model.bettingTimeoutTypes = filteredBettingTimeoutTypes.map((x) => {
				return {
					id: x.id,
					name: x.name,
					abrv: x.abrv,
				};
			});

			model.data.deactivateLogoutIntervalId = autoLogoutTypes.item.find(
				(x) => x.units == 0
			).id;
			model.data.deactivateBettingTimeoutId =
				bettingTimeoutTypes.item.find((x) => x.units == 0).id;

			const logoutInterval = autoLogoutTypes.item.find(
				(x) => x.id == user.autoLogoutPeriodId
			);
			const bettingTimeout = bettingTimeoutTypes.item.find(
				(x) => x.id == user.bettingTimeoutId
			);

			if (bettingTimeout != undefined) {
				model.message.bettingTimeoutTime = bettingTimeout.name;
			} else {
				model.message.bettingTimeoutTime = "";
			}

			if (logoutInterval != undefined) {
				model.message.logoutIntervalTime = logoutInterval.name;
			} else {
				model.message.logoutIntervalTime = "";
			}

			const userHasAutoLogout = user.autoLogoutPeriodId != null;
			const userHasBetTimeout = user.bettingTimeoutId != null;

			if (!userHasAutoLogout && !userHasBetTimeout) {
				model.message.settingsMessage = "NO_SETTINGS";
			} else {
				model.message.settingsMessage = "AUTO_LOGOUT_SETTINGS";
			}

			if (user.autoLogoutPeriodStartDate != null) {
				model.message.autoLogoutTime = user.autoLogoutPeriodStartDate;
			} else if (user.bettingTimeoutStartDate != null) {
				model.message.autoLogoutTime = user.bettingTimeoutStartDate;
			}
		}

		return new Response(model);
	}

	async updateAutomaticLogoutData(model) {
		const autoLogoutTypes = await this.getAutoLogoutPeriodsLookupAsync();
		const user = await this.userDataService.getUserInfo();

		const userLimitSettings = {
			AutoLogoutPeriodId: null,
			BettingTimeoutId: null,
		};

		try {
			if (
				user.bettingTimeoutId != model.bettingTimeoutId &&
				!(
					user.bettingTimeoutId == null &&
					model.bettingTimeoutId == null
				)
			) {
				userLimitSettings.BettingTimeoutId = model.bettingTimeoutId;
			}

			if (
				user.autoLogoutPeriodId != model.logoutIntervalId &&
				!(
					user.autoLogoutPeriodId == null &&
					model.logoutIntervalId == null
				)
			) {
				userLimitSettings.AutoLogoutPeriodId = model.logoutIntervalId;
			}

			if (user.autoLogoutPeriodStartDate != null) {
				model.message.autoLogoutTime = user.autoLogoutPeriodStartDate;
			} else if (user.bettingTimeoutStartDate != null) {
				model.message.autoLogoutTime = user.bettingTimeoutStartDate;
			}

			await this.automaticLogoutDataService.setUserAutomaticLogoutSettings(
				userLimitSettings
			);

			if (user.autoLogoutPeriodId != null) {
				const addHoursToCookie = await autoLogoutTypes.item.filter(
					(x) => x.id == user.autoLogoutPeriodId
				);
				const addHoursToCookieObj = addHoursToCookie[0];
				const autoLogoutCookieTimeLimit = DateTime.local().plus({
					hours: addHoursToCookieObj.units,
				});
				App.utils.setCookie(
					"auto_logout_time",
					autoLogoutCookieTimeLimit.toJSDate().toISOString()
				);
			}
			const response = await this.setAutomaticLogoutSettings(model);
			return new Response(response);
		} catch (exception) {
			let errorMessage;

			if (exception.message == "Unauthorized") {
				return new ErrorResponse();
			}
			switch (exception?.data?.errorCode) {
				case 4000022003:
					errorMessage = "AUTO_LOGOUT_WARN";
					break;

				case 400140:
					errorMessage = "AUTO_LOGOUT_TIME_ERROR";
					break;

				case 400141:
					errorMessage = "AUTO_LOGOUT_DISABLE_ERROR";
					break;

				case 400142:
					errorMessage = "BETTING_TIME_ERROR";
					break;

				case 400143:
					errorMessage = "BETTING_TIME_DISABLE_ERROR";
					break;

				default:
					errorMessage = "AUTO_LOGOUT_ERR";
					break;
			}

			const modelError = {
				statusCode: exception.data.statusCode,
				errorCode: exception.data.errorCode,
				message: errorMessage,
			};
			throw new ErrorResponse(modelError);
		}
	}
}
