import React from 'react';
import { constants } from '@gp/gp-offer-utils';

import { defaultTemplate } from '../../../../../../common/hoc';
import { LiveTip } from '../../../../../../common/components/offer/v1';

const pauseMatchTime = 'Pause';

export default defaultTemplate(function (props) {
    const { offer, tips, matchTime } = props;

    if (tips == null) {
        return (
            <React.Fragment>
                <LiveTip />
                <LiveTip />
                <LiveTip />
            </React.Fragment>
        );
    }

    let leftTip = null;
    let middleTip = null;
    let rightTip = null;

    if (offer != null && offer.length > 0) {
        if (tips[0] === 'margin') {
            const specifierKey = Object.keys(offer[0].specifier)[0];
            leftTip = {
                isMargin: true,
                value: offer[0].specifier[specifierKey]
            };
            middleTip = {
                isMargin: false,
                value: offer.find(o => o.tip.toLowerCase().startsWith(tips[1]))
            };
            rightTip = offer.find(o => o.tip.toLowerCase().startsWith(tips[2]));
        }
        else {
            leftTip = {
                isMargin: false,
                value: offer.find(o => o.tip.toLowerCase().startsWith(tips[0]))
            };
            if ([constants.setnrPlaceholder, constants.gamenrPlaceholder].includes(tips[1]) && matchTime === pauseMatchTime) {
                middleTip = {
                    isMargin: true,
                    value: offer[0].specifier[tips[1]] + '. set'
                };
            }
            else {
                middleTip = {
                    isMargin: false,
                    value: offer.find(o => o.tip.toLowerCase().startsWith(tips[1]))
                };
            }
            rightTip = offer.find(o => o.tip.toLowerCase().startsWith(tips[2]));
        }

        return (
            <React.Fragment>
                <LiveTip tip={leftTip.value} isMargin={leftTip.isMargin} />
                <LiveTip tip={middleTip.value} isMargin={middleTip.isMargin} />
                <LiveTip tip={rightTip} />
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <LiveTip />
            <LiveTip />
            <LiveTip />
        </React.Fragment>
    );

    // if (!offer || !offer.offers) {
    //     return (
    //         <React.Fragment>
    //             <LiveTip />
    //             <LiveTip />
    //             <LiveTip />
    //         </React.Fragment>
    //     )
    // }

    // const offers = offer.offers;

    // if (offers.length === 0) {
    //     return (
    //         <React.Fragment>
    //             <LiveTip />
    //             <LiveTip />
    //             <LiveTip />
    //         </React.Fragment>
    //     )
    // }

    // let leftTip = null;
    // let middleTip = null;
    // let rightTip = null;

    // // margin
    // if (tips[0] === ' ') {
    //     const specifierKey = Object.keys(offers[0].specifier)[0];
    //     leftTip = (
    //         <LiveTip isMargin margin={offers[0].specifier[specifierKey]} />
    //     );
    //     middleTip = (
    //         <LiveTip tip={offers.find(o => o.tip.toLowerCase().startsWith(tips[1]))} />
    //     );
    //     rightTip = (
    //         <LiveTip tip={offers.find(o => o.tip.toLowerCase().startsWith(tips[2]))} />
    //     );
    // }
    // else {
    //     leftTip = (
    //         <LiveTip tip={offers.find(o => o.tip.toLowerCase().startsWith(tips[0]))} />
    //     );
    //     middleTip = (
    //         <LiveTip tip={offers.find(o => o.tip.toLowerCase().startsWith(tips[1]))} />
    //     );
    //     rightTip = (
    //         <LiveTip tip={offers.find(o => o.tip.toLowerCase().startsWith(tips[2]))} />
    //     );
    // }


    // return (
    //     <React.Fragment>
    //         {leftTip}
    //         {middleTip}
    //         {rightTip}
    //     </React.Fragment>
    // )
});