import React, { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { AccountActivationEmailSectionTemplate } from "themes/pages/membership/account-activation/components";
import { isNullOrWhitespace } from "../../../../../utils/common/helpers.v1mobile";
import { EmailChangeService } from "../../../administration/email-change/services";

const emailChangeService = new EmailChangeService();
let timeoutId = null;
export default function AccountActivationEmailSection(props) {

    const { getFieldState, setError, clearErrors, trigger } = useFormContext();

    const newEmail = useWatch({ name: "email" });

    const checkForEmailAvailiability = async (value) => {
        const response = await emailChangeService.checkIsEmailAvailable(value);
        return response.data;
    }

    useEffect(() => {

        if (getFieldState('email').isTouched && getFieldState('confirmEmail').isTouched) {
            trigger('confirmEmail');
        }

        async function checkEmail() {
            if (isNullOrWhitespace(newEmail) || getFieldState('email').invalid) return;

            if (timeoutId != null) {
                clearTimeout(timeoutId);
            }

            //this error is set so user can't submit the form until request has finished
            setError('email', { type: 'wait' });

            timeoutId = setTimeout(async () => {
                const isMailAvailable = await checkForEmailAvailiability(newEmail);

                if (!isMailAvailable) {
                    setError('email', { type: 'isAvailable', message: 'USER.ACCOUNT_SETTINGS.EMAIL_CHANGE.EMAIL_EXISTS' });
                }
                else {
                    clearErrors('email');
                }
            }, 500);
        }

        checkEmail();
    }, [newEmail]);

    return (<AccountActivationEmailSectionTemplate {...props} newEmail={newEmail} />)
}