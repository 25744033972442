import React from "react";
import {
    Switch,
    Route,
} from "react-router-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { NoMatchRoute } from "../../../common/components/navigation";

import {
    AboutUs,
    Contact,
    BettingRules,
    InstallAppPage
} from "../../../pages/info-pages";

function InfoPagesLayoutTemplate() {
    return (
        <main className="l--full">
            <div className="container container--main">
                <div className="row">
                    <div className="l--full__content">
                        <div className="view--med">
                            <Switch>
                                <Route exact path="/:culture/info/about-us">
                                    <AboutUs />
                                </Route>

                                <Route exact path="/:culture/info/contact">
                                    <GoogleReCaptchaProvider
                                        language={App.utils.getCurrentLanguage()}
                                        reCaptchaKey={ReCaptchaChallengeKey}
                                    >
                                        <Contact />
                                    </GoogleReCaptchaProvider>
                                </Route>

                                <Route exact path="/:culture/info/betting-rules">
                                    <BettingRules />
                                </Route>

                                <Route exact path="/:culture/info/install-app">
                                    <InstallAppPage />
                                </Route>

                                <NoMatchRoute contentOnly />
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default InfoPagesLayoutTemplate;