import React, { useState, useEffect } from "react";
import { useLoading, useNotification } from "../../../../common/hooks";
import { accountStatementServiceInstance } from "../../services/AccountStatementService";
import { AccountStatementTransactionsTableTemplate } from "themes/pages/account-statement/components/tables";

export default function AccountStatementTransactionsTable(props) {
	const { setIsLoading } = useLoading();

	const { showError } = useNotification();

	const [showDetails, setShowDetails] = useState(0);
	const [slipDetails, setSlipDetails] = useState({});
	const [tableData, setTableData] = useState({});

	const SGUID_empty = "0000000000000000000000";

	const { statementFilter, ...rest } = props;

	useEffect(() => {
		if (Object.keys(statementFilter).length !== 0) {
			fetchTransactionsTable();
		}
	}, [statementFilter]);

	async function fetchTransactionsTable(pageNr = 1) {
		try {
			setIsLoading(true);
			const response =
				await accountStatementServiceInstance.fetchTransactionsTableData(
					statementFilter,
					pageNr
				);
			if (response.success) {
				setTableData(response.data);
			}
		} catch (error) {
			setTableData(null);
			showError("ACC_STATEMENT.TABLES." + error?.error?.message);
		} finally {
			setIsLoading(false);
		}
	}

	const fetchSlipDetails = async (transaction) => {
		if (
			transaction.betSlipId != null &&
			transaction.betSlipId != SGUID_empty
		) {
			try {
				setIsLoading(true);
				const response =
					await accountStatementServiceInstance.getSlipDetails(
						transaction.betSlipId
					);
				if (response.success) {
					for (const key in response.data.betSlipOffers) {
						const offer = response.data.betSlipOffers[key];
						if (
							offer?.specifier?.player == null ||
							![
								"player-total-assists",
								"player-total-rebounds",
								"player-total-3-point-field-goals",
								"player-total-points",
							].includes(offer.sportData.bettingTypeAbrv || "")
						) {
							continue;
						}

						offer.specifier.player =
							offer.playerFirstName +
							" " +
							offer.playerLastName +
							` (${
								offer.sportData.teamOneId === offer.teamId
									? offer.sportData.teamOneName
									: offer.sportData.teamTwoName
							})`;
						offer.playerId = null;
						offer.teamId = null;
					}

					setSlipDetails(response.data);
				}
			} catch (error) {
				setSlipDetails(null);
			} finally {
				setShowDetails(1);
				setIsLoading(false);
			}
		} else {
			try {
				setIsLoading(true);
				const response =
					await accountStatementServiceInstance.getTransactionDetails(
						transaction.numericalId
					);
				if (response.success) {
					setSlipDetails(response.data);
				}
			} catch (error) {
				setSlipDetails(null);
			} finally {
				setShowDetails(2);
				setIsLoading(false);
			}
		}
	};

	return (
		<AccountStatementTransactionsTableTemplate
			tableData={tableData}
			fetchSlipDetails={fetchSlipDetails}
			showDetails={showDetails}
			slipDetails={slipDetails}
			setShowDetails={setShowDetails}
			fetchTransactionsTable={fetchTransactionsTable}
			{...rest}
		/>
	);
}
