import React from "react";
import { useTranslation } from "react-i18next";
import { Switch, useLocation } from "react-router-dom";
import {
	NoMatchRoute,
	RouteElement,
} from "../../../common/components/navigation";
import { AdministrationSubHeader } from "../../../common/components/subheader";

import {
	PersonalData,
	PasswordChange,
	AccountLock,
	AutomaticLogout,
	EmailChange,
	MyLimits as BettingLimits,
} from "../../../pages/administration";

const routes = [
	"personal-data",
	"password-change",
	"email-change",
	"betting-limits",
	"account-lock",
	"automatic-logout",
];

function AccountSettingsLayoutTemplate() {
	const { t } = useTranslation();
	const { pathname } = useLocation();

	return (
		<main className="l--full">
			<div className="container container--main">
				<div className="row">
					<div className="l--full__content">
						<div className="view--med">
							{routes.includes(pathname.split("/")[3]) && (
								<>
									<div className="title--primary">
										{t(
											"USER.ACCOUNT_SETTINGS.NAVIGATION.USER_ACCOUNT_SETTINGS"
										)}
									</div>
									<AdministrationSubHeader />
								</>
							)}
							<Switch>
								<RouteElement
									exact
									path="/:culture/account-settings/personal-data"
									privateRoute
								>
									<PersonalData />
								</RouteElement>

								<RouteElement
									exact
									path="/:culture/account-settings/password-change"
									privateRoute
								>
									<PasswordChange />
								</RouteElement>

								<RouteElement
									exact
									path="/:culture/account-settings/email-change"
									privateRoute
								>
									<EmailChange />
								</RouteElement>

								<RouteElement
									exact
									path="/:culture/account-settings/betting-limits"
									privateRoute
								>
									<BettingLimits />
								</RouteElement>

								<RouteElement
									exact
									path="/:culture/account-settings/account-lock"
									privateRoute
								>
									<AccountLock />
								</RouteElement>

								<RouteElement
									exact
									path="/:culture/account-settings/automatic-logout"
									privateRoute
								>
									<AutomaticLogout />
								</RouteElement>

								<NoMatchRoute />
							</Switch>
						</div>
					</div>
				</div>
			</div>
		</main>
	);
}

export default AccountSettingsLayoutTemplate;
