import React from 'react';
import PropTypes from 'prop-types';
import { BetSlipSubmitConfirmationModalTemplate } from 'themes/common/components/bet-slip';

class BetSlipSubmitConfirmationModal extends React.Component {
    render() {
        return <BetSlipSubmitConfirmationModalTemplate {...this.props} />;
    }
}

BetSlipSubmitConfirmationModal.propTypes = {
    onAccept: PropTypes.func,
    onReject: PropTypes.func,
};

export default BetSlipSubmitConfirmationModal;