import React from 'react';
import { observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import { useAppContext, useUser } from '../../hooks';

export default observer(function PrivateRoute(props) {
    const { user } = useUser();
    const { culture } = useAppContext();
    const {
        children
    } = props;

    const accountActivation = App.state.rootStore.userAuthStore.accountActivation;

    if (user != null || accountActivation != null) {
        return children;
    }
    return <Redirect exact to={`/${culture}/auth/login`} />;
})