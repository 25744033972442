const httpClient = App.utils.httpClient;

const requestHeaders = {
    'Content-Type': 'application/json',
};

export class AccountStatementService {
    async fetchStatementTransactions(query = '') {
        const userId = App.utils.getUserFromLocalStorage().id;

        return await httpClient.get(`${BaseAddress}${App.utils.getAgencyKey()}/account-statements/${userId}${query}`, requestHeaders, { responseType: 'json' });
    }

    async fetchUserAccountActivity(filter) {
        requestHeaders['Accept'] = 'application/json';
        return await httpClient.post(`${BaseAddress}platform/reports/AccountActivityReview`, JSON.stringify(filter), requestHeaders, { responseType: 'json' });
    }

    async fetchUserBettingActivity(filter) {
        requestHeaders['Accept'] = 'application/json';
        return await httpClient.post(`${BaseAddress}platform/reports/BettingActivityReview`, JSON.stringify(filter), requestHeaders, { responseType: 'json' });
    }

    async getSlipDetails(betSlipId) {
        return await httpClient.get(`${BaseAddress}${App.utils.getAgencyKey()}/bet-slips/${betSlipId}?embed=betSlipOffers,player.coreUser,betSlipStatus,betStatus,betSlipType,betSlipCombinations,bettingAccountType&translate=true`, requestHeaders, { responseType: 'json' });
    }

    async getTransactionDetails(numericalId) {
        const userId = App.utils.getUserFromLocalStorage().id;

        return await httpClient.get(`${BaseAddress}${App.utils.getAgencyKey()}/user-payment-transactions/${userId}/${numericalId}?embed=paymentMethod,currency,transactionStatus&translate=true`, requestHeaders, { responseType: 'json' });
    }

    async findOffer(filter) {
        return await httpClient.post(`${BaseProxyAddress}${App.utils.getAgencyKey()}/offer`, JSON.stringify(filter), requestHeaders, { responseType: 'json' });
    }
}
