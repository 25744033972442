import React from 'react';
import { observer, inject } from 'mobx-react';

import { AdditionalOfferTemplate } from 'themes/common/components/additional-offer';

@inject(stores => ({
    offerStore: stores.offerStore,
    resizeStore: stores.offerStore.resizeStore
}))
@observer
class AdditionalOffer extends React.Component {
    constructor(props) {
        super(props);

        if (!props.eventId) {
            throw new Error("eventId prop is required on AdditionalOffer component");
        }

        this.viewStore = new App.offer.components.AdditionalOfferViewStore(this.props.offerStore, {
            enableBettingTypeGroups: !!props.enableBettingTypeGroups,
            type: this.props.type || 'prematch',
            isLive: this.props.isLive || false,
            onClose: this.props.onClose
        });
    }

    async componentDidMount() {
        this.viewStore.updateEventId(this.props.eventId);
        await this.viewStore.initializeAdditionalOfferStore();
    }

    componentWillUnmount() {
        this.viewStore.onDispose();
        // kill the instance
        this.viewStore = null;
    }

    render() {
        return (
            <AdditionalOfferTemplate {...this.props} viewStore={this.viewStore} />
        )
    }
}

export default AdditionalOffer;