import React from "react";
import {
    InsertLimitSection,
    DecreaseLimitSection,
    DeleteOrUpdateLimitSection
} from "./components"


export default function BettingLimitsTemplate(props) {
    const {
        fetchedData,
        setFetchedData,
        viewType,
        methods,
        setViewType,
        decreaseBettingLimit,
        getBettingLimitLookups,
        deactivateBettingLimit,
        updateBettingLimit,
        insertBettingLimit
    } = props;

    return (
        <>
            {viewType === 0 &&
                <InsertLimitSection
                    methods={methods}
                    setViewType={setViewType}
                    getBettingLimitLookups={getBettingLimitLookups}
                    setFetchedData={setFetchedData}
                    insertBettingLimit={insertBettingLimit}
                />
            }

            {viewType === 2 &&
                <DecreaseLimitSection
                    methods={methods}
                    fetchedData={fetchedData}
                    setViewType={setViewType}
                    decreaseBettingLimit={decreaseBettingLimit}
                    setFetchedData={setFetchedData}
                />
            }

            {viewType === 1 &&
                <DeleteOrUpdateLimitSection
                    methods={methods}
                    fetchedData={fetchedData}
                    setViewType={setViewType}
                    getBettingLimitLookups={getBettingLimitLookups}
                    deactivateBettingLimit={deactivateBettingLimit}
                    setFetchedData={setFetchedData}
                    updateBettingLimit={updateBettingLimit}
                />
            }
        </>
    )
}
