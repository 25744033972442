
import React from "react";
import { observer } from "mobx-react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
    LiveEventRouteLayout,
    LiveRouteLayout,
    PrematchRouteLayout,
} from "../../../layouts";

import { NoMatchRoute } from "../../../common/components/navigation";

 function SportsBettingLayoutTemplate() {
    return (
        <main role="main" className="l--main">
            <div className="container container--main">
                <div className="row">
                    <Switch>
                        <Route exact path={[
                            "/:culture/home/:period",
                            "/:culture/search/:period/:searchTerm",
                            "/:culture/sports/:period",
                            "/:culture/sports/:period/f/:segments*",
                            "/:culture/sports/:period/event/:eventIds/:segments*"
                        ]}>
                            <PrematchRouteLayout />
                        </Route>
                        <Route exact path="/:culture/live/:period">
                            <LiveRouteLayout />
                        </Route>
                        <Route exact path="/:culture/live/:period/event/:eventIds">
                            <LiveEventRouteLayout enableBettingTypeGroups />
                        </Route>
                        <Redirect exact from="/:culture" to="/:culture/home/full" />
                        <Redirect exact from="/:culture/home" to="/:culture/home/full" />
                        <Redirect exact from="/:culture/sports" to="/:culture/sports/full" />
                        <Redirect exact from="/:culture/live" to="/:culture/live/events" />
                        <Redirect exact from="/:culture/search" to="/:culture/home/full" />
                        <NoMatchRoute />
                    </Switch>
                </div>
            </div>
        </main>
    )
}

export default observer(SportsBettingLayoutTemplate);