import React from 'react';
import { useTranslation } from 'react-i18next';
import { AccountStatementTableTransaction } from '../../../../../../pages/account-statement/components/tables';
import { AccountStatementPopupDetails } from '../../../../../../pages/account-statement/components/details';
import { OfferPager } from '../../../../../../common/components/offer';

export default function AccountStatementTransactionsTableTemplate(props) {

    const { t } = useTranslation();

    const {
        tableData,
        fetchTransactionsTable,
        fetchSlipDetails,
        showDetails,
        slipDetails,
        setShowDetails,
    } = props;

    if (tableData === null) {
        return null;
    }

    const {
        totalRecords,
        transactions,
        page,
        recordsPerPage
    } = tableData;

    if (!transactions || transactions.length == 0) {
        return (
            <div className="message--sml message--note">
                {t('ACC_STATEMENT.TABLES.NO_TRANSACTION_TXT')}
            </div>
        )
    }

    return (
        <>
            <table className='table--primary u-mar--top--sml'>
                <thead className='table--primary__header'>
                    <tr className='table--primary__header__row'>
                        <th className='table--primary__header__data u-type--center'>
                            ID
                        </th>
                        <th className='table--primary__header__data'>
                            {t('ACC_STATEMENT.TABLES.TABLE_DATE')}
                        </th>
                        <th className='table--primary__header__data'>
                            {t('ACC_STATEMENT.TABLES.TABLE_TYPE')}
                        </th>
                        <th className='table--primary__header__data u-type--center'>
                            {t('ACC_STATEMENT.TABLES.TABLE_AMOUNT')}
                        </th>
                        <th className='table--primary__header__data u-type--center'>
                            {t('ACC_STATEMENT.TABLES.TABLE_BAL')}
                        </th>
                        <th className='table--primary__header__data u-type--right'>
                            &nbsp;
                        </th>
                    </tr>
                </thead>
                <tbody className='table--primary__body'>
                    {transactions.map(transaction =>
                        <AccountStatementTableTransaction
                            key={transaction.numericalId}
                            transaction={transaction}
                            fetchSlipDetails={fetchSlipDetails}
                        />
                    )}

                </tbody>
            </table>

            <AccountStatementPopupDetails
                showDetails={showDetails}
                slipDetails={slipDetails}
                setShowDetails={setShowDetails}
            />

            <OfferPager
                pageNumber={page}
                pageSize={recordsPerPage}
                totalItems={totalRecords}
                onPageChange={fetchTransactionsTable}
                isRemoveAllVisible={false}
                onCloseAll={false}
                rightPlacement={true}
            />

        </>
    )
}