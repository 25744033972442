import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../../../common/components/buttons';

export default function AccountStatementTransactionDetailsErrorTemplate(props) {

    const { t } = useTranslation();

    const {
        setShowDetails
    } = props;

    return (
        <div className="popup--dialog">
            <div className="popup--dialog__card">
                <div className="popup--dialog__card__header">
                    {t('ACC_STATEMENT.TRANS_DETAILS.TRANSACTION_DETAILS')}
                </div>
                <div className="popup--dialog__card__body">
                    <div className="message--base message--error"> {t('ACC_STATEMENT.TRANS_DETAILS.ERROR_OCCURED')}</div>
                </div>
                <div className="popup--dialog__card__footer">

                    <Button
                        btnClassName="btn btn--sml btn--secondary btn--icon-left"
                        btnType="button"
                        onClick={() => setShowDetails(0)}
                        btnWithFrontIcon={ <i className="u-icon u-icon--xsml u-icon--remove--white"></i>}
                        btnText="ACC_STATEMENT.TRANS_DETAILS.CLOSE_BTN"
                    />
                    
                </div>
            </div>
        </div>
    )
}
