import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { defaultTemplate } from '../../../../../common/hoc';
import { OutsideClickDetector } from '../../../../../common/components/utility';
import { Modal } from '../../../../../common/components/modal';
import { LookupContext } from '@gp/components';

export default defaultTemplate(function (props) {
    const
        { t, statisticsStore, currentEvent, homeTeam, awayTeam, closeStatisticsModal, currentSport, currentTournament } = props,
        { isLoading,
            standingStatisticsResult,
            formStatisticsResult,
            hthStatisticsResult,
            currentTab,
            openFormStatistics,
            openHeadToHeadStatistics,
            openLeaguesStatistics,
            isNewSeason,
            currentTournamentId,
            currentGroups
        } = statisticsStore;
    let body, sport, sportLookup, tournament;
    const lookupContext = React.useContext(LookupContext);
    const [activeIx, setActiveIx] = React.useState(0);
    const [activeFormIx, setActiveFormIx] = React.useState(0);

    const header = (
        <nav className="tabs--primary tabs--primary--dark u-mar--bottom--reset">
            <button
                className={`tabs--primary__item ${currentTab === 1 ? 'is-active' : ''}`}
                onClick={async () => await openLeaguesStatistics()}
            >
                <span className="tabs--primary__label">{t('STATISTICS_MODAL.LEAGUE')}</span>
            </button>
            <button
                onClick={async () => await openFormStatistics()}
                className={`tabs--primary__item ${currentTab === 2 ? 'is-active' : ''}`}
            >
                <span className="tabs--primary__label">{t('STATISTICS_MODAL.FORM')}</span>
            </button>
            {homeTeam && awayTeam && (
                <button
                    onClick={async () => await openHeadToHeadStatistics()}
                    className={`tabs--primary__item ${currentTab === 3 ? 'is-active' : ''}`}
                >
                    <span className="tabs--primary__label">{t('STATISTICS_MODAL.HEAD_TO_HEAD')}</span>
                </button>
            )}
        </nav>
    )

    if (isLoading) {
        body = (
            <div className="loader">
                <div className="loader__card">
                    <div className="loader__card__title">{t('COMMON.LOADING')}</div>
                    <span></span>
                </div>
            </div>
        )
    } else {
        let isNotEmpty = false, sportLookupId, rowClasses, hasOutcome = false;
        tournament = lookupContext != null && lookupContext.tournaments.size > 0 ? lookupContext.tournaments.get(currentTournamentId) : currentEvent != null && currentEvent.tournament != null ? currentEvent.tournament : currentTournament;
        const noGoalsSports = ["baseball"], percentageTotalSports = ["baseball", 'american-football'], bSports = ["basketball"], noDrawSports = ["baseball"], runsSports = ["baseball"], overtimeSports = ["ice-hockey"];

        sportLookupId = currentEvent.sportId != null ? currentEvent.sportId : null;
        sportLookup = sportLookupId != null ? lookupContext.sports.get(sportLookupId) : currentSport != null ? currentSport : getSportId();
        sport = sportLookup != null ? sportLookup.abrv : null;

        function renderGroups(i, renderFunc) {
            return (
                Object.keys(currentGroups).map((key, ix) => {
                    const data = currentGroups[key];
                    return (
                        <div className='match--stats__standings' key={Math.random()} >
                            <div className="match--stats__standings__name" onClick={() => currentTab != 1 ? setActiveFormIx(ix) : setActiveIx(ix)}>
                                {data.name}
                            </div>
                            {ix === i && renderFunc(data.teams)}
                        </div>
                    )
                })
            )
        }

        const noGoalsSportsCheck = !noGoalsSports.includes(sport), runsSportsCheck = runsSports.includes(sport), overtimeSportsCheck = overtimeSports.includes(sport), groupsCheck = Object.keys(currentGroups).length > 1;
        switch (currentTab) {
            case 1:
                isNotEmpty = standingStatisticsResult && standingStatisticsResult.length > 0;
                hasOutcome = isNotEmpty ? standingStatisticsResult.filter(r => r.tournamentOutcome).length > 0 : false

                function renderStandingsTable(data = []) {               
                    return (
                        <table className="match--stats__standings__table table--primary">
                            {isNotEmpty &&
                                <thead className="table--primary__header--secondary">
                                    <tr className="table--primary__header__row--secondary">
                                        <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">Pos</th>
                                        <th className="table--primary__header__data table--primary__header__data--secondary">&nbsp;</th>
                                        <th className="table--primary__header__data table--primary__header__data--secondary">M</th>
                                        <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">W</th>
                                        {!noDrawSports.includes(sport) && <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">D</th>}
                                        <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">L</th>
                                        {/*  */}
                                        {isNewSeason && renderNewSeasonTable("header", sport)}
                                        {noGoalsSportsCheck && <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">{(bSports.includes(sport) ? "B" : "G")}</th>}
                                        {runsSportsCheck && <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">RUN</th>}
                                        {overtimeSportsCheck &&
                                            <React.Fragment>
                                                <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">WOT</th>
                                                <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">LOT</th>
                                                <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">WAP</th>
                                                <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">LAP</th>
                                                <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">STREAK</th>
                                            </React.Fragment>
                                        }
                                        {(sport == "american-football" && !isNewSeason) &&
                                            <React.Fragment>
                                                <th className="table--primary__header__data table--primary__header__data--secondary">&nbsp;</th>
                                                <th className="table--primary__header__data table--primary__header__data--secondary">&nbsp;</th>
                                                <th className="table--primary__header__data table--primary__header__data--secondary">&nbsp;</th>
                                            </React.Fragment>
                                        }
                                        <th className="table--primary__header__data table--primary__header__data--secondary u-type--center"> {(percentageTotalSports.includes(sport) && !isNewSeason) ? "PTS" : "Pts"}</th>
                                        {/*  */}
                                        {hasOutcome && <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">&nbsp;</th>}
                                    </tr>
                                </thead>
                            }
                            <tbody className="table--primary__body--secondary">
                                {data && data.length > 0 ? data.map((item, idx) => {
                                    rowClasses = classNames(
                                        {
                                            "table--primary__body__row--lost": currentEvent && item.teamId === currentEvent.teamOneId,
                                            "table--primary__body__row--win": currentEvent && item.teamId === currentEvent.teamTwoId,
                                            "table--primary__body__row--secondary": currentEvent && item.teamId !== currentEvent.teamTwoId && item.teamId !== currentEvent.teamOneId
                                        }
                                    )
                                    
                                    return (
                                        <tr key={Math.random()} className={rowClasses}>
                                            <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                                                {item.sortPositionTotal}
                                            </td>
                                            <td className="table--primary__body__data table--primary__body__data--secondary">
                                                {item.team.gender == 0 ? item.team.name : item.team.name + " (" + t('OFFER.GENDER.WOMEN') + ")"}
                                            </td>
                                            <td className="table--primary__body__data table--primary__body__data--secondary">
                                                {item.matchesTotal > 0 ? item.matchesTotal : (item.leagueWinTotal + item.leagueLossTotal)}
                                            </td>
                                            <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                                                {item.winTotal > 0 ? item.winTotal : (item.leagueWinTotal > 0 ? item.leagueWinTotal : (item.divisionWinTotal))}
                                            </td>
                                            {!noDrawSports.includes(sport) && <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                                                {item.drawTotal}
                                            </td>}
                                            <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                                                {item.lossTotal > 0 ? item.lossTotal : (item.leagueLossTotal > 0 ? item.leagueLossTotal : (item.divisionLossTotal))}
                                            </td>
                                            {isNewSeason && renderNewSeasonTable(sport, item)}
                                            {(noGoalsSportsCheck && bSports.includes(sport)) ?
                                                <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                                                    {item.goalsForTotal} : {item.goalsAgainstTotal}
                                                </td> :
                                                <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                                                    {(item.goalsTotal != "0" && item.goalsTotal != "0:0" && item.goalsTotal != "") ? item.goalsTotal : (item.leagueGoalsForTotal + " : " + item.leagueGoalsAgainstTotal)}
                                                </td>}
                                            {runsSportsCheck && <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                                                {item.runsForTotal + " - " + item.runsAgainstTotal}
                                            </td>}
                                            {overtimeSportsCheck &&
                                                <React.Fragment>
                                                    <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                                                        {item.winOverTimeTotal}
                                                    </td>
                                                    <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                                                        {item.lossOverTimeTotal}
                                                    </td>
                                                    <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                                                        {item.winAfterPenaltyTotal}
                                                    </td>
                                                    <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                                                        {item.lossAfterPenaltyTotal}
                                                    </td>
                                                    <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                                                        {!isNewSeason ? item.leagueStreak : ""}
                                                    </td>
                                                </React.Fragment>}
                                            {(sport == "american-football" && !isNewSeason) &&
                                                <React.Fragment>
                                                    <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                                                        {item.leagueStreak}
                                                    </td>
                                                    <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                                                        {item.conferenceWinTotal}
                                                    </td>
                                                    <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                                                        {item.conferenceLossTotal}
                                                    </td>
                                                </React.Fragment>
                                            }
                                            {(percentageTotalSports.includes(sport) && !isNewSeason) &&
                                                <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                                                    {item.leaguePercentageTotal}
                                                </td>}
                                            {noGoalsSportsCheck && !percentageTotalSports.includes(sport) && <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                                                {(item.pointsTotal != null && item.pointsTotal != "" && item.pointsTotal != " ") ? item.pointsTotal : "-"}
                                            </td>}
                                            {hasOutcome && <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                                                {item.tournamentOutcome ? item.tournamentOutcome.shortName : ""}
                                            </td>}
                                        </tr>
                                    )
                                }) : (
                                    <tr className="table--primary__body__data table--primary__body__data--secondary--empty-state">
                                        <td className="message--base message--note u-type--color--text">{t('STATISTICS_MODAL.TOURNAMENT_STANDINGS_EMPTY')}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    )
                }
                
                body = (
                    <div className="popup--dialog__card__body">
                        {groupsCheck ? renderGroups(activeIx, renderStandingsTable) : renderStandingsTable(standingStatisticsResult)}
                    </div>
                )
                break;
            case 2:
                isNotEmpty = formStatisticsResult && formStatisticsResult.length > 0;

                function renderFormsTable(data = []) {
                    return (
                        <table className="match--stats__standings__table table--primary">
                            {isNotEmpty &&
                                <thead className="table--primary__header--secondary">
                                    <tr className="table--primary__header__row--secondary">
                                        <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">Pos</th>
                                        <th className="table--primary__header__data table--primary__header__data--secondary">&nbsp;</th>
                                        <th className="table--primary__header__data table--primary__header__data--secondary">&nbsp;</th>
                                        <th className="table--primary__header__data table--primary__header__data--secondary">M</th>
                                        <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">W</th>
                                        {!noDrawSports.includes(sport) && <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">D</th>}
                                        <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">L</th>
                                        {noGoalsSportsCheck && <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">{bSports.includes(sport) ? "B" : "G"}</th>}
                                    </tr>
                                </thead>
                            }
                            <tbody className="table--primary__body">
                                {data && data.length > 0 ? data.map((item, idx) => {
                                    rowClasses = classNames(
                                        {
                                            "table--primary__body__row--lost": currentEvent && item.teamId === currentEvent.teamOneId,
                                            "table--primary__body__row--win": currentEvent && item.teamId === currentEvent.teamTwoId,
                                            "table--primary__body__row--secondary": currentEvent && item.teamId !== currentEvent.teamTwoId && item.teamId !== currentEvent.teamOneId
                                        }
                                    )

                                    return (
                                        <tr key={idx} className={rowClasses}>
                                            <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                                                {item.sortPositionTotal}
                                            </td>
                                            <td className="table--primary__body__data table--primary__body__data--secondary">
                                                {item.team.gender == 0 ? item.team.name : item.team.name + " (" + t('OFFER.GENDER.WOMEN') + ")"}
                                            </td>
                                            <td className="table--primary__body__data table--primary__body__data--secondary">
                                                {item.tendencyTotal}
                                            </td>
                                            <td className="table--primary__body__data table--primary__body__data--secondary">
                                                {item.matchesTotal > 0 ? item.matchesTotal : (item.leagueWinTotal + item.leagueLossTotal)}
                                            </td>
                                            <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                                                {item.winTotal > 0 ? item.winTotal : (item.leagueWinTotal > 0 ? item.leagueWinTotal : (item.divisionWinTotal))}
                                            </td>
                                            {!noDrawSports.includes(sport) && <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                                                {item.drawTotal}
                                            </td>}
                                            <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                                                {item.lossTotal > 0 ? item.lossTotal : (item.leagueLossTotal > 0 ? item.leagueLossTotal : (item.divisionLossTotal))}
                                            </td>
                                            {(noGoalsSportsCheck && bSports.includes(sport)) ?
                                                <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                                                    {item.goalsForTotal} : {item.goalsAgainstTotal}
                                                </td> :
                                                <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                                                    {(item.goalsTotal != "0" && item.goalsTotal != "0:0") ? item.goalsTotal : item.leagueGoalsForTotal + " : " + item.leagueGoalsAgainstTotal}
                                                </td>}
                                        </tr>
                                    )
                                }) : (
                                    <tr className="table--primary__body__data table--primary__body__data--secondary--empty-state">
                                        <td className="message--base message--note u-type--color--text">{t('STATISTICS_MODAL.TOURNAMENT_STANDINGS_EMPTY')}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    )
                }

                body = (
                    <div className="popup--dialog__card__body">
                        {groupsCheck ? renderGroups(activeFormIx, renderFormsTable) : renderFormsTable(formStatisticsResult)}
                    </div>
                )
                break;
            case 3:
                isNotEmpty = hthStatisticsResult && hthStatisticsResult.length > 0;

                function setName(item, spec) {
                    return item[spec].gender == 0 ? item[spec].name : item[spec].name + " (" + t('OFFER.GENDER.WOMEN') + ")";
                }

                body = (
                    <div className="popup--dialog__card__body">
                        <table className="match--stats__standings__table table--primary">
                            {isNotEmpty &&
                                <thead className="table--primary__header--secondary">
                                    <tr className="table--primary__header__row--secondary">
                                        <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">Date</th>
                                        <th className="table--primary__header__data table--primary__header__data--secondary">&nbsp;</th>
                                        <th className="table--primary__header__data table--primary__header__data--secondary">&nbsp;</th>
                                        <th className="table--primary__header__data table--primary__header__data--secondary">HT</th>
                                        <th className="table--primary__header__data table--primary__header__data--secondary">FT</th>
                                    </tr>
                                </thead>
                            }
                            <tbody className="table--primary__body--secondary">
                                {(hthStatisticsResult && hthStatisticsResult.length > 0) ? hthStatisticsResult.map((item, idx) => {
                                    return (
                                        <tr key={idx} className="table--primary__body__row--secondary">
                                            <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                                                {moment(item.startDate).utc().locale(App.utils.getCurrentCulture()).format('L LTS')}
                                            </td>
                                            <td className="table--primary__body__data table--primary__body__data--secondary">
                                                {item.tournament.name}
                                            </td>
                                            <td className="table--primary__body__data table--primary__body__data--secondary u-type--wgt--bold">
                                                {setName(item, "teamOne")} - {setName(item, "teamTwo")}
                                            </td>
                                            {(item.halfTimeScore && item.halfTimeScore.homeScore !== null && item.halfTimeScore.awayScore !== null)
                                                ? <td className="table--primary__body__data table--primary__body__data--secondary">
                                                    {item.halfTimeScore.homeScore}:{item.halfTimeScore.awayScore}
                                                </td> : (
                                                    <td className="table--primary__body__data table--primary__body__data--secondary">
                                                        -
                                                    </td>
                                                )
                                            }
                                            {(item.homeScore !== null && item.awayScore !== null) ? <td className="table--primary__body__data table--primary__body__data--secondary">
                                                {item.homeScore}:{item.awayScore}
                                            </td> : (
                                                <td className="table--primary__body__data table--primary__body__data--secondary">
                                                    -
                                                </td>
                                            )}
                                        </tr>
                                    )
                                }) : (
                                    <tr className="table--primary__body__data table--primary__body__data--secondary--empty-state">
                                        <td className="message--base message--note u-type--color--text">{t('STATISTICS_MODAL.TOURNAMENT_STANDINGS_EMPTY')}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div >
                )
                break;
        }
    }
    return (
        <Modal>
            <div className="popup--dialog match--stats">
                <div className="popup--dialog__card">
                    <OutsideClickDetector onOutsideClick={e => {
                        closeStatisticsModal()
                    }}>
                        <React.Fragment>
                            {!isLoading && <React.Fragment>
                                {(tournament && !homeTeam || !awayTeam) ? <div className="popup--dialog__card__header popup--dialog__card__header--secondary">
                                    {tournament.name}
                                </div> :
                                    <div className="popup--dialog__card__header popup--dialog__card__header--secondary">
                                        {homeTeam} - {awayTeam}
                                    </div>
                                }
                            </React.Fragment>}
                            {header}
                            {body}
                            <div className="popup--dialog__card__footer">
                                <button onClick={() => closeStatisticsModal()} className="btn btn--sml btn--secondary btn--icon-left" type="button">
                                    <i className="u-icon u-icon--xsml u-icon--remove--white"></i>
                                    {t('COMMON.CLOSE')}
                                </button>
                            </div>
                        </React.Fragment>
                    </OutsideClickDetector>
                </div>
            </div>
        </Modal>
    )

    function getSportId() {
        const sportCat = lookupContext != null ? lookupContext.categories.get(tournament.sportCategoryId) : currentEvent.category;
        const sport = lookupContext != null ? lookupContext.sports.get(sportCat.sportId) : currentEvent.sport;
        return sport;
    }

    function renderNewSeasonTable(sportAbrv, item = null) {
        let content;
        if (!item) {
            if (sportAbrv == "baseball") {
                content = (
                    <React.Fragment>
                        <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">%</th>
                        <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">E#</th>
                        <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">L10</th>
                        <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">STREAK</th>
                    </React.Fragment>
                )
            }

            if (sportAbrv == "basketball") {
                content = (
                    <React.Fragment>
                        <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">W10</th>
                        <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">L10</th>
                        <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">%</th>
                        <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">SER</th>
                    </React.Fragment>
                )
            }
            if (sportAbrv == "ice-hockey") {
                content = (
                    <React.Fragment>
                        <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">G</th>
                        <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">WAP</th>
                        <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">LAP</th>
                    </React.Fragment>
                )
            }
            if (sportAbrv == "american-football") {
                content = (
                    <React.Fragment>
                        <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">%</th>
                        <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">G</th>
                        <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">GD</th>
                        <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">WH</th>
                        <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">LH</th>
                        <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">WA</th>
                        <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">LA</th>
                        <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">WAFC</th>
                        <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">LAFC</th>
                        <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">WNFC</th>
                        <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">LNFC</th>
                        <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">DW</th>
                        <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">DL</th>
                        <th className="table--primary__header__data table--primary__header__data--secondary u-type--center">SER</th>
                    </React.Fragment>
                )
            }
        } else {
            if (sportAbrv == "baseball") {
                content = (
                    <React.Fragment>
                        <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                            {item.percentageTotal}
                        </td>
                        <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                            {item.Elimination}
                        </td>
                        <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                            {item.leagueWinLast10 + " : " + item.leagueLossLast10}
                        </td>
                        <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                            {item.leagueStreak}
                        </td>
                    </React.Fragment>)
            }

            if (sportAbrv == "basketball") {
                content = (
                    <React.Fragment>
                        <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                            {item.leagueWinLast10}
                        </td>
                        <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                            {item.leagueLossLast10}
                        </td>
                        <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                            {item.percentageTotal}
                        </td>
                        <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                            {item.leagueStreak}
                        </td>
                    </React.Fragment>)
            }
            if (sportAbrv == "ice-hockey") {
                content = (
                    <React.Fragment>
                        <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                            {item.goalsForTotal + " : " + item.goalsAgainstTotal}
                        </td>
                        <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                            {item.winAfterPenaltyTotal}
                        </td>
                        <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                            {item.lossAfterPenaltyTotal}
                        </td>
                    </React.Fragment>)
            }
            if (sportAbrv == "american-football") {
                content = (
                    <React.Fragment>
                        <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                            {item.leaguePercentageTotal}
                        </td>
                        <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                            {item.goalsForTotal + " : " + item.goalsAgainstTotal}
                        </td>
                        <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                            {item.leagueGoalsForTotal + " - " + item.leagueGoalsAgainstTotal}
                        </td>
                        <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                            {item.leagueWinHome}
                        </td>
                        <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                            {item.leagueLossHome}
                        </td>
                        <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                            {item.leagueWinAway}
                        </td>
                        <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                            {item.leagueLossAway}
                        </td>
                        <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                            {item.winAfc}
                        </td>
                        <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                            {item.lossAfc}
                        </td>
                        <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                            {item.winNfc}
                        </td>
                        <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                            {item.lossNfc}
                        </td>
                        <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                            {item.divisionWinTotal}
                        </td>
                        <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                            {item.divisionLossTotal}
                        </td>
                        <td className="table--primary__body__data table--primary__body__data--secondary u-type--center">
                            {item.leagueStreak}
                        </td>
                    </React.Fragment>)
            }
        }
        return content;
    }
});