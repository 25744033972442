import React from "react";
import { EventType } from "@gp/models";
import { DateTime } from "luxon";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { isNullOrWhitespace } from "../../../../../../../utils/common/helpers";
import { formattedStatus } from "../../../../../../pages/account-statement/utils";

export default function AccountStatetementBetDetailsOfferTemplate(props) {

    const {
        offer,
        totalCombinations,
        isSystem,
        isLive,
        status,
        parsedCurrentResult,
        currentResult,
        currentScorePerPeriod,
        bettingTypeName,
        bettingTypeTip,
        parsedResult,
        scorePerPeriod
    } = props;


    const dataCharClassname = classNames(
        'table--bet__body__data table--bet__body__data--char',
        {
            "u-type--color--status--cashout": (!isLive || offer.isMulti) && status == "cashout",
            [`u-type--color--status--${formattedStatus(offer.bettingOfferStatus)}`]: (!isLive || offer.isMulti) && status != "cashout"
        }

    )

    const iconClassname = classNames(
        "u-icon u-icon--xsml",
        {
            "u-icon--status--cashout": status == "cashout",
            [`u-icon--status--${formattedStatus(offer.bettingOfferStatus)}`]: status != "cashout"
        }
    )

    const tableRowClassname = classNames(
        'table--bet__body__row',
        {
            "u-type--color--status--cashout": status == "cashout",
            [`u-type--color--status--${formattedStatus(offer.bettingOfferStatus)}`]: status != "cashout"
        }
    )

    const resultClassname = classNames(
        'table--bet__body__data--results__ft',
        {
            "u-type--color--status--cashout": status == "cashout",
            [`u-type--color--status--${formattedStatus(offer.bettingOfferStatus)}`]: status != "cashout"
        }
    )

    return (
        <div className={tableRowClassname}>
            <div className="table--bet__body__data table--bet__body__data--time">
                {DateTime.fromISO(offer.sportData.eventStartTime).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
            </div>
            <div className={dataCharClassname}>
                {offer.systemIdentifier}
            </div>
            <div className="table--bet__body__data table--bet__body__data--event">

                <EventTypes
                    offer={offer}
                    isLive={isLive}
                    currentResult={currentResult}
                    currentScorePerPeriod={currentScorePerPeriod}
                />

            </div>
            <div className="table--bet__body__data table--bet__body__data--fix">
                {offer.isBank && <span className="u-type--wgt--bold u-type--color--primary u-mar--right--sml">
                    B
                </span>}
            </div>

            <BettingTypeTipNotNormal
                offer={offer}
                bettingTypeTip={bettingTypeTip}
            />

            <BettingTypeTipNormal
                offer={offer}
                bettingTypeName={bettingTypeName}
            />

            <div className="table--bet__body__data table--bet__body__data--odds">
                {offer.value.toFixed(2)}
            </div>



            <div className="table--bet__body__data table--bet__body__data--results">

                <EventScores
                    parsedResult={parsedResult}
                    resultClassname={resultClassname}
                    scorePerPeriod={scorePerPeriod}
                />

            </div>
            <div className="table--bet__body__data table--bet__body__data--fix">
                <i className={iconClassname}></i>
            </div>
        </div>
    )
}

function EventAnchorWrapper({ children, offer }) {

    if (!offer.isEventInOffer) {
        return children;
    }

    const url = `/${App.utils.getCurrentCulture()}/${offer.isLive ? "live/events/event" : "sports/full/event"}/${offer.eventId}`;

    return (
        <a
            href={url}
            className="table--bets__body__data__link"
        >
            {children}
        </a>
    )
}

function EventTypes(props) {

    const {
        currentResult,
        currentScorePerPeriod,
        isLive,
        offer
    } = props;

    return (
        <>
            <EventTypeIsNotNormal
                offer={offer}
            />

            <EventTypeIsNormal
                offer={offer}
                currentResult={currentResult}
                currentScorePerPeriod={currentScorePerPeriod}
                isLive={isLive}
            />
        </>
    )
}

function EventTypeIsNotNormal(props) {

    const {
        offer
    } = props;

    if (offer.sportData.eventType == EventType.NORMAL) {
        return null;
    }

    return (
        <div className="table--bet__body__data--event__teams u-padd--left--base">

            <EventNameIsNotMulti
                offer={offer}
            />

            <EventNameIsMulti
                offer={offer}
            />

        </div>
    )
}

function EventNameIsMulti(props) {
    const {
        offer
    } = props;

    if (!offer.isMulti) {
        return null;
    }

    return offer.multiEventName;
}

function EventNameIsNotMulti(props) {
    const {
        offer
    } = props;

    if (offer.isMulti) {
        return null;
    }

    return <EventAnchorWrapper offer={offer}>
        {offer.sportData.eventName}
    </EventAnchorWrapper>
}

function EventTypeIsNormal(props) {

    const {
        offer,
        currentResult,
        currentScorePerPeriod,
        isLive
    } = props;

    if (offer.sportData.eventType != EventType.NORMAL) {
        return null;
    }

    return (
        <>
            <EventOfferMulti
                offer={offer}
            />

            <EventOfferNotMulti
                offer={offer}
            />

            <EventIsLive
                offer={offer}
                currentResult={currentResult}
                currentScorePerPeriod={currentScorePerPeriod}
                isLive={isLive}
            />
        </>
    )
}

function EventOfferMulti(props) {
    const {
        offer
    } = props;

    if (!offer.isMulti) {
        return null;
    }

    return (
        <div className="table--bet__body__data--event__teams u-padd--left--base">
            {offer.multiEventName}
        </div>
    )
}

function EventOfferNotMulti(props) {
    const {
        offer
    } = props;

    if (offer.isMulti) {
        return null;
    }

    return (
        <div className="table--bet__body__data--event__teams">
            <EventAnchorWrapper offer={offer}>
                {offer.sportData.teamOneName} - {offer.sportData.teamTwoName}
            </EventAnchorWrapper>
        </div>
    )
}

function EventIsLive(props) {

    const { t } = useTranslation();

    const {
        offer,
        currentResult,
        currentScorePerPeriod,
        isLive
    } = props;

    if (!isLive || currentResult == null || offer.isMulti) {
        return null;
    }
    return (
        <div className="table--bet__body__data--event__live-score">
            *{t('ACC_STATEMENT.BET_DETAILS.D_LIVE')}* {currentResult} {!isNullOrWhitespace(currentScorePerPeriod) && currentScorePerPeriod}
        </div>
    )
}

function BettingTypeTipNotNormal(props) {

    const {
        bettingTypeTip,
        offer
    } = props;

    if (offer.sportData.eventType == EventType.NORMAL) {
        return null;
    }

    return (
        <div className="table--bet__body__data table--bet__body__data--bet-type">
            {bettingTypeTip}
        </div>
    )
}

function BettingTypeTipNormal(props) {
    const {
        bettingTypeName,
        offer
    } = props;


    if (offer.sportData.eventType != EventType.NORMAL) {
        return null;
    }

    return (
        <div className="table--bet__body__data table--bet__body__data--bet-type">
            {bettingTypeName} ({offer.bettingTypeTip})
        </div>
    )
}

function EventScores(props) {
    const {
        parsedResult,
        scorePerPeriod,
        resultClassname
    } = props;

    if (parsedResult == null) {
        return null;
    }

    return (
        <>
            <CurrentScore
                parsedResult={parsedResult}
                resultClassname={resultClassname}
            />

            <FullTimeScore
                parsedResult={parsedResult}
                resultClassname={resultClassname}
            />

            <PeriodScore
                scorePerPeriod={scorePerPeriod}
            />
        </>
    )
}

function CurrentScore(props) {

    const {
        parsedResult,
        resultClassname
    } = props;

    if (parsedResult.currentScore == null || parsedResult.fullTimeScore != null) {
        return null;
    }

    return (
        <div className={resultClassname}>
            {parsedResult.currentScore.home || 0}:{parsedResult.currentScore.away || 0}
        </div>
    )
}

function FullTimeScore(props) {

    const {
        parsedResult,
        resultClassname
    } = props;

    if (parsedResult.fullTimeScore == null) {
        return null;
    }

    return (
        <div className={resultClassname}>
            {parsedResult.fullTimeScore.home || 0}:{parsedResult.fullTimeScore.away || 0}
        </div>
    )
}

function PeriodScore(props) {

    const { scorePerPeriod } = props;

    if (isNullOrWhitespace(scorePerPeriod)) {
        return null;
    }

    return (
        <div className="table--bet__body__data--results__ht">
            ({scorePerPeriod})
        </div>
    )
}