import { inject, observer } from 'mobx-react';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { TournamentOfferTemplate } from 'themes/pages/sports';

@withRouter
@inject(stores => ({
    offerStore: stores.offerStore,
    tournamentViewStore: stores.offerStore.tournamentViewStore,
}))
@observer
class TournamentOffer extends React.Component {

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        const { match: { params: { period, tournamentId } } } = this.props;
        await this.props.tournamentViewStore.initializeStore(tournamentId, period);
    }

    async componentDidUpdate(prevProps) {
        const { match: { params: { period, tournamentId } } } = this.props;
        await this.props.tournamentViewStore.initializeStore(tournamentId, period);
    }

    componentWillUnmount() {
        this.props.tournamentViewStore.onDispose();
    }

    render() {
        return <TournamentOfferTemplate{...this.props} />
    }
}

export default TournamentOffer;
