const httpClient = App.utils.httpClient;
const requestHeaders = {
    'Content-Type': 'application/json',
};
class OasisBlockReasonTypesLookupService {
    findLookup() {
        const url = `${BaseAddress}platform/block-reasons?fields=abrv,code,id,name,languageMetadata&translate=true`
        return httpClient.get(url, requestHeaders, { responseType: 'json' })
    }
}

export default new OasisBlockReasonTypesLookupService();