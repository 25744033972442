import React, { useContext } from "react";
import { observer } from "mobx-react";
import { Link, useParams } from "react-router-dom";
import classNames from "classnames";

import {
	EventContext,
	LookupContext,
	SportOfferContext,
	OfferOptionsContext,
} from "@gp/components";

import {
	LiveMatchTimeLive,
	LiveMatchResultLive,
	ScoreLive,
	StartTimeLive,
	RedCards,
} from "../../../../../../common/components/offer/new-offer/common";
import { StatisticsButton } from "../../../../../../common/components/offer/buttons";
import { TeamName } from "../../../../../../common/components/offer/details";

const ActionButtons = observer(function ActionButtons(props) {
	const event = useContext(EventContext);
	const brid = event.source ? event.source.providerId.split(":")[2] : null;

	return (
		<div className="offer__body__data offer__actions">
			{brid != null && <StatisticsButton isOfferButton />}
		</div>
	);
});

export const EventDetailsLiveTemplate = observer(
	function EventDetailsLiveTemplate(props) {
		const event = useContext(EventContext);
		const lookupContext = useContext(LookupContext);
		const sportContext = useContext(SportOfferContext);
		const options = useContext(OfferOptionsContext);

		const { culture } = useParams();

		const team1 = lookupContext.teams.get(event.teamOneId);
		const team2 = lookupContext.teams.get(event.teamTwoId);
		const category = lookupContext.categories.get(event.sportCategoryId);
		const tournament = lookupContext.tournaments.get(event.tournamentId);

		const url = `/${culture}/live/events/event/${event.id}`;

		return (
			<>
				<LiveMatchTimeLive
					event={event}
					className={classNames("offer__body__data offer__time", {
						"offer__time--stretch": props.hasSecondaryOffer,
					})}
				/>

				{!options.hideFavoriteIcon ? <FavoritesIcon /> : null}

				<div className="offer__body__data offer__match">
					{team1 != null && team2 != null ? (
						<Link
							className="offer__match__competitors"
							to={url}
							data-tip={`${category?.name} - ${tournament?.name}: ${team1?.name} - ${team2?.name}`}
							data-for="team-description"
						>
							<div className="offer__match__team">
								<TeamName team={team1} />
								<RedCards
									className="u-mar--left--tny"
									sport={sportContext}
									statistics={
										event.result?.homeTeamTotalStatistics
									}
								/>
							</div>
							<LiveMatchResultLive
								event={event}
								sport={sportContext}
							/>
							<div className="offer__match__team">
								<TeamName team={team2} />
								<RedCards
									className="u-mar--left--tny"
									sport={sportContext}
									statistics={
										event.result?.awayTeamTotalStatistics
									}
								/>
							</div>
						</Link>
					) : null}
				</div>
				<ActionButtons teamOne={team1} teamTwo={team2} />
			</>
		);
	}
);

const FavoritesIcon = observer(function FavoritesIcon() {
	const event = useContext(EventContext);
	const offerOptionsContext = useContext(OfferOptionsContext);

	const isFavorite = offerOptionsContext.isEventInFavorites(event.id);

	return (
		<div className="offer__body__data offer__favorites">
			<button
				className="offer__favorites__btn btn btn--xtny btn--link btn--icon-center"
				type="button"
				onClick={(_) => {
					if (isFavorite) {
						if (
							offerOptionsContext.removeEventFromFavorites != null
						) {
							offerOptionsContext.removeEventFromFavorites(
								event.id
							);
						}
					} else {
						if (offerOptionsContext.addEventToFavorites != null) {
							offerOptionsContext.addEventToFavorites(event.id);
						}
					}
				}}
			>
				<i
					className={classNames("u-icon u-icon--sml", {
						"u-icon--favorites--active": isFavorite,
						"u-icon--favorites--inactive": !isFavorite,
					})}
				/>
			</button>
		</div>
	);
});
