import React, { useMemo, useEffect } from "react";
import { AccountLockTemplate } from "themes/pages/administration";
import { useForm } from "react-hook-form";
import { AccountLockService } from "./services";
import { usePasswordConfirmation } from "../../../common/hooks";
import { useTranslation } from "react-i18next";
import { ErrorPage } from "../../../common/components/error";

const accountLockServiceInstance = new AccountLockService();

function AccountLock() {
	const { setShowPasswordConfirmation } = usePasswordConfirmation();
	const { t } = useTranslation();
	const methods = useForm({ mode: "onChange" });
	const {
		formState: { dirtyFields },
		reset,
	} = methods;

	const accountLockService = useMemo(() => accountLockServiceInstance, []);

	useEffect(() => {
		App.state.rootStore.titleStore.setTitle(
			t("USER.ACCOUNT_SETTINGS.NAVIGATION.TITLE_ACC_LOCK")
		);

		//on mount scroll to top
		document.querySelector("html, body").scrollTo(0, 0);
		return () => setShowPasswordConfirmation(false);
	}, []);

	const onCancel = () => {
		setShowPasswordConfirmation(false);
		reset();
	};
	const onSubmitData = () => {
		if (Object.keys(dirtyFields).length != 0) {
			setShowPasswordConfirmation(true);
		}
	};

	function getAccountClosureViewData(lookupsInitialData) {
		return accountLockService.getAccountClosureViewData(lookupsInitialData);
	}

	function createAccountLock(data) {
		return accountLockService.createAccountLock(data);
	}

	return (
		<ErrorPage>
			<AccountLockTemplate
				getAccountClosureViewData={getAccountClosureViewData}
				createAccountLock={createAccountLock}
				methods={methods}
				onCancel={onCancel}
				onSubmitData={onSubmitData}
			/>
		</ErrorPage>
	);
}

export default AccountLock;
