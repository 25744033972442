import React, { useEffect } from "react";
import { InstallAppPageTemplate } from "themes/pages/info-pages"
import { useTranslation } from "react-i18next";

export default function InstallAppPage() {
    const { t } = useTranslation();

    useEffect(() => {
        App.state.rootStore.titleStore.setTitle(t('INFO_PAGES.INSTALL_APP.INSTALL_APPLICATION'));

        document.querySelector('html, body').scrollTo(0, 0);
    }, [])

    return <InstallAppPageTemplate />;
}