const httpClient = App.utils.httpClient;
const requestHeaders = {
	"Content-Type": "application/json",
};

class TitlesLookupService {
	findLookup() {
		const url = `${BaseAddress}platform/user-titles?translate=true`;
		return httpClient.get(url, requestHeaders, { responseType: "json" });
	}
}

export default new TitlesLookupService();
