import { DateTime } from "luxon";
import { FromToDates } from "../../../../../administration/services/account-settings/limits-history/models/FromToDates";
import { LimitsHistoryService as LimitsHistoryDataService } from "../../../../../administration/services/account-settings/limits-history/LimitsHistoryService";
import { ErrorResponse, Response } from "../../../../../administration/services/common";

const limitViewModel = {
    period: "last6months",
    gamingTypeId: "default",
    page: 1
}


export class LimitsHistoryService {
    constructor() {
        this.limitsHistoryDataService = new LimitsHistoryDataService();
    }


    async getLimitHistoryData(viewModel = limitViewModel) {
        try {
            const dates = new FromToDates(viewModel.period);
            const filter = this.getGamingLimitFilter(dates, viewModel.gamingTypeId, viewModel.page);
            const response = await this.getGamingLimitHistoryWithFilter(filter);
            const result = await this.mapToViewModel(viewModel, response);
            return new Response(result);
        } catch (error) {
            if (error.message == 'Unauthorized') {
                return new ErrorResponse();
            }
            throw new ErrorResponse({ message: 'GENERIC_ERROR' });
        }

    }

    getGamingLimitFilter(dates = null, gamingLimitTypeIds = "", page = 1, sort = "dateCreated|desc") {
        const filter = {};
        filter.embed = "gamingLimitDuration,gamingLimitType";
        filter.sort = sort;
        filter.page = page;
        filter.rpp = 10;
        if (dates != null) {
            filter.from = dates.from;
            filter.to = dates.to;
        }
        if (gamingLimitTypeIds != "" && typeof gamingLimitTypeIds === "string") {
            filter.gamingLimitTypeIds = gamingLimitTypeIds;
        }
        return filter;
    }


    async getGamingLimitHistoryWithFilter(filter) {
        const limitUrl = `${BaseAddress}${App.utils.getAgencyKey()}/gaming-limit-histories/per-user`

        let filterOptions;
        if (filter.gamingLimitTypeIds == undefined || filter.gamingLimitTypeIds == "default") {
            filterOptions = `page=${filter.page}&rpp=${filter.rpp}&sort=${filter.sort}&embed=${filter.embed}`
        } else {
            filterOptions = `page=${filter.page}&rpp=${filter.rpp}&sort=${filter.sort}&embed=${filter.embed}&gamingLimitCategoryIds=${filter.gamingLimitTypeIds}`
        }
        let apiUrl
        if (filter.from != null && filter.from != "" && filter.to == null) {
            apiUrl = `${limitUrl}?from=${filter.from}&translate=True&${filterOptions}`
        } else if (filter.from == null || filter.from == "" && filter.to != null) {
            apiUrl = `${limitUrl}?to=${filter.to}&translate=True&${filterOptions}`
        } else if (filter.from != null && filter.from != "" && filter.to != null) {
            apiUrl = `${limitUrl}?from=${filter.from}&to=${filter.to}&translate=True&${filterOptions}`
        }

        const response = await this.limitsHistoryDataService.getGamingLimitHistory(apiUrl)
        return response;
    }

    async mapToViewModel(viewModel, pagedHistory) {

        viewModel.totalRecords = pagedHistory.totalRecords;
        viewModel.page = pagedHistory.page;
        viewModel.recordsPerPage = 10;
        viewModel.items = pagedHistory.item.map(x => {
            return {
                date: DateTime.fromISO(x.dateCreated).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS),
                limitType: x.gamingLimitType.name,
                limitInterval: x.gamingLimitDuration.name,
                limitAmount: x.amount,
                id: x.id
            }
        })

        return viewModel;
    }

    async getGamingLimitCategories() {

        const response = await this.limitsHistoryDataService.getAvailableLimitCategories();
        const gamingLimitCategories = [];
        const viewModel = {};
        viewModel.currencyCode = response.currencyCode;
        let categories = response.limitCategories.map(lc => {
            return {
                name: lc.gamingLimitCategory.name,
                id: lc.gamingLimitCategoryId
            }
        })
        gamingLimitCategories.push(...categories)
        gamingLimitCategories.push({
            name: "SHOW_ALL",
            id: "default",
        })
        viewModel.limitCategories = gamingLimitCategories;

        return viewModel;

    }

}

