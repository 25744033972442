import React from "react";
import {
	ActivationError,
	ActivationInProgress,
	ActivationSuccess,
} from "../../../../../pages/membership/email-activation/components";

export default function EmailActivationTemplate(props) {
	const { activationState, errorMessage } = props;

	return (
		<main className="l--full">
			<div className="container container--main">
				<div className="row">
					<div className="l--full__content">
						{/* success */}
						<ActivationSuccess activationState={activationState} />

						{/* error */}
						<ActivationError
							activationState={activationState}
							errorMessage={errorMessage}
						/>

						{/* ostalo: waiting */}
						<ActivationInProgress />
					</div>
				</div>
			</div>
		</main>
	);
}
