import React from 'react';
import PropTypes from 'prop-types';
import { defaultTemplate } from '../../../../../common/hoc';
import { Modal } from '../../../../../common/components/modal';

class BetSlipSubmitSuccessModalTemplate extends React.Component {
    render() {
        const { onClose, t } = this.props;

        return (
            <Modal>
                <div className="popup">
                    <div className="popup__card">
                        <h6 className="popup__card__header">
                            <i className="u-icon u-icon--lrg u-icon--my-bets--success u-display--b u-align--h--center u-mar--bottom--sml"></i>
                            {t('BET_SLIP.SUCCESSFULL_SUBMIT')}
                        </h6>
                        <div className="popup__card__footer">
                            <button className="btn btn--base btn--ghost w--50" onClick={onClose}>{t('COMMON.CLOSE')}</button>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

BetSlipSubmitSuccessModalTemplate.propTypes = {
    onClose: PropTypes.func,
};

export default defaultTemplate(BetSlipSubmitSuccessModalTemplate);