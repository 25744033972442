import React from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInputField } from "../../../../../../common/components/inputs";
import EmailAvailabilitySection from "../../../../../../pages/membership/account-acitvation/components/EmailAvailabilitySection";

export default function AccountActivationEmailSectionTemplate(props) {
    const { t } = useTranslation();
    const {
        methods,
        accountActivationData,
    } = props;

    const { getValues, formState: { errors } } = methods;

    const emailPattern = /^[a-zA-Z][\w\.-]*[a-zA-Z0-9]@[a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]\.[a-zA-Z][a-zA-Z\.]*[a-zA-Z]$/;
    const isEmailRequired = accountActivationData?.companyInfo.isOfflineUserEmailChangeMandatory;
    const email = useWatch({ name: "email" })

    if (accountActivationData == null) {
        return null;
    }

    return (
        <>
            {!isEmailRequired &&
                <div className="form__field row">
                    <div className="col-sml-12">
                        <div className="message--sml message--note">{t("MEMBERSHIP.ACCOUNT_ACTIVATION.EMAIL_HEADER")}</div>
                    </div>
                </div>
            }
            <FormInputField
                name="email"
                className="form__field row"
                label="MEMBERSHIP.ACCOUNT_ACTIVATION.NEW_EMAIL_LABEL"
                placeholder="MEMBERSHIP.ACCOUNT_ACTIVATION.NEW_EMAIL_PLACEHOLDER"
                pattern={{
                    value: emailPattern,
                    message: 'USER.ACCOUNT_SETTINGS.EMAIL_CHANGE.INVALID_EMAIL'
                }}
                popoverMsg={t("specific:MEMBERSHIP.ACCOUNT_ACTIVATION.EMAIL_POPUP_MESSAGE")}
                required={isEmailRequired}
            />


            <EmailAvailabilitySection />

            <FormInputField
                name="confirmEmail"
                className="form__field row"
                label="MEMBERSHIP.ACCOUNT_ACTIVATION.REPEAT_NEW_EMAIL"
                pattern={{
                    value: emailPattern,
                    message: 'USER.ACCOUNT_SETTINGS.EMAIL_CHANGE.INVALID_EMAIL'
                }}
                validate={{
                    isNotEqual: value => value === email || 'USER.ACCOUNT_SETTINGS.EMAIL_CHANGE.DOES_NOT_MATCH_EMAIL'
                }}
                popoverMsg={t("specific:MEMBERSHIP.ACCOUNT_ACTIVATION.REPEAT_EMAIL_MESSAGE")}
                required={isEmailRequired}
            />
        </>
    )
}