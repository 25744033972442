// NOTE: this components display live offer in prematch page
import React from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { OfferLiveTemplate } from 'themes/common/components/offer';

@inject(stores => ({
    offerStore: stores.offerStore
}))
@observer
@withRouter
class OfferLive extends React.Component {
    constructor(props) {
        super(props);

        this.viewStore = new App.offer.components.OfferViewStore(this.props.offerStore);
    }

    render() {
        return (
            <OfferLiveTemplate {...this.props} viewStore={this.viewStore} />
        );
    }
}

export default OfferLive;