import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { FormSelectField } from '../../../../../../common/components/inputs';
import { Button } from '../../../../../../common/components/buttons';
import { AccountStatementCustomDate } from '../../../../../../pages/account-statement/components/filter';

export default function AccountStatementFilterTemplate(props) {

    const {
        handleChange,
        filterData,
        showCustomDateSection,
        onFilterSubmit
    } = props;

    const { t } = useTranslation();

    const { handleSubmit } = useFormContext();

    const showAllTypes = {
        id: "all",
        name: t('ACC_STATEMENT.FILTER.SHOW_ALL_TRANSACTION_TYPES')
    };

    return (
        <form className="form" onSubmit={handleSubmit(onFilterSubmit)}>

            <div className="form__group">

                <FormSelectField
                    className='form__field row'
                    label='ACC_STATEMENT.FILTER.AS_PERIOD'
                    name='asPeriod'
                    dropdownData={filterData.periodLookup ? filterData.periodLookup : undefined}
                    onChange={handleChange}
                />

                <AccountStatementCustomDate
                    showCustomDateSection={showCustomDateSection}
                />

                <FormSelectField
                    className='form__field row'
                    label='ACC_STATEMENT.FILTER.AS_TYPE'
                    name='asType'
                    dropdownData={filterData.transactionLookup ? [showAllTypes, ...filterData.transactionLookup] : undefined}
                />

                <div className="form__field row">
                    <div className="col-sml-11 u-type--right">

                        <Button
                            className='form__submit btn btn--med btn--secondary'
                            btnText='ACC_STATEMENT.FILTER.AS_BUTTON'
                        />

                    </div>
                </div>
            </div>
        </form>


    )
}