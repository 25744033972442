import React from "react";
import { defaultTemplate } from "../../../../../common/hoc";

import { Modal } from "../../../../../common/components/modal";
import { useTranslation } from "react-i18next";

function MyBetsReuseConfirmationTemplate(props) {
  const { id, betSlipNumber, isLive, onClose, onAccept } = props;
  const { t } = useTranslation();

  return (
    <Modal>
      <div id="reuse-form" className="popup">
        <div className="popup__card">
          <div className="popup__card__header">{t("MY_BETS.REUSE.TITLE")}</div>
          <div className="popup__card__body">
            <div className="u-mar--bottom--tny">
              {t("MY_BETS.REUSE.MESSAGE", { bsn: betSlipNumber })}
            </div>
          </div>
          <div className="popup__card__footer">
            <button
              className="btn btn--base btn--ghost--secondary btn--icon-left"
              onClick={onClose}
              type="button"
            >
              <i className="u-icon u-icon--xsml u-icon--close" />
              {t("COMMON.CANCEL")}
            </button>
            <button
              className="btn btn--base btn--primary btn--icon-left"
              onClick={(e) => onAccept({ id: id, isLive: isLive })}
              type="submit"
            >
              <i className="u-icon u-icon--xsml u-icon--check--white" />
              {t("COMMON.YES")}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default defaultTemplate(MyBetsReuseConfirmationTemplate);
