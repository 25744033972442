import React from 'react';
import { observer, inject } from 'mobx-react';
import { SearchRouteLayoutTemplate } from 'themes/layouts';

export default inject(stores => ({
    offerStore: stores.offerStore,
}))(observer(function SearchRouteLayout(props) {
    return (
        <SearchRouteLayoutTemplate {...props} />
    );
}));