import React from "react";
import { useFixBody } from "../../../../../../common/hooks";
import {
	AccountStatementTransactionDetails,
	AccountStatementBetDetails,
} from "../../../../../../pages/account-statement/components/details";

export default function AccountStatementPopupDetailsTemplate(props) {
	const { showDetails, slipDetails, setShowDetails } = props;

	if (showDetails == 0) {
		return null;
	}

	return (
		<>
			<ShowBetDetails
				slipDetails={slipDetails}
				setShowDetails={setShowDetails}
				showDetails={showDetails}
			/>

			<ShowTransactionDetails
				slipDetails={slipDetails}
				setShowDetails={setShowDetails}
				showDetails={showDetails}
			/>
		</>
	);
}

function ShowBetDetails(props) {
	const { slipDetails, setShowDetails, showDetails } = props;

	useFixBody(true, showDetails);
	if (showDetails != 1) {
		return null;
	}

	return (
		<AccountStatementBetDetails
			slipDetails={slipDetails}
			setShowDetails={setShowDetails}
		/>
	);
}

function ShowTransactionDetails(props) {
	const { slipDetails, setShowDetails, showDetails } = props;

	if (showDetails != 2) {
		return null;
	}

	return (
		<AccountStatementTransactionDetails
			slipDetails={slipDetails}
			setShowDetails={setShowDetails}
		/>
	);
}
