import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import { constants } from '@gp/offer';
import { OfferStoreContext } from '@gp/components';

import { OfferTipLive } from '../../../../../../../common/components/offer/new-offer/common';

const EmptyTips = () => (
	<div className="offer__body__data offer__quotes">
		<div className="offer__quotes__row">
			<OfferTipLive className="quote quote--placeholder" />
			<OfferTipLive className="quote quote--placeholder" />
			<OfferTipLive className="quote quote--placeholder" />
		</div>
	</div>
)

export const OfferColumnLiveTemplate = observer((props) => {
	const offerStore = useContext(OfferStoreContext);

	if (props.offerKeyId == undefined || props.tips == undefined) {
		return <EmptyTips />;
	}

	const offerMap = offerStore.keyOffersMap.get(props.offerKeyId);

	if (offerMap == undefined || offerMap.size === 0) {
		return <EmptyTips />;
	}

	const offers = Array.from(offerMap.values());

	/** List of tips to be rendered. */
	const tipsForRender = [];

	for (let i = 0; i < Math.max(props.tips?.length, offers.length); i++) {
		const currentTip = props.tips[i];

		if (currentTip == null) {
			if (offers[i] != null) {
				tipsForRender.push({
					isMargin: false,
					tip: offers[i]
				});
				continue;
			}

			// when we reach first null offer, we know everything else behind is null. Thus we break the loop
			break;
		}

		if (currentTip === constants.emptyPlaceholder) {
			tipsForRender.push({
				key: currentTip + '-' + i,
				isMargin: false,
				tip: null
			});
			continue;
		}

		if(constants.marginPlaceholder === currentTip) {
			
			const newTip = {
				isMargin: true,
			};

			if (offers[0].specifier != undefined) {
				// if there is total specifier show it (in some cases total will not be the first one so we must check it)
				let specKey = "";
				const indexOfTotal = Object.keys(offers[0].specifier).indexOf("total"); 
				if (indexOfTotal > -1) {
					specKey = Object.keys(offers[0].specifier)[indexOfTotal];
				} else {
					specKey = Object.keys(offers[0].specifier)[0];
				}
				newTip.margin = offers[0].specifier[specKey];
			}
			else {
				newTip.tip = offers.find(o => o.tip.toLowerCase().startsWith(props.tips[0]));
			}
			tipsForRender.push(newTip)
		}
		else if ([constants.setnrPlaceholder, constants.gamenrPlaceholder].includes(currentTip)) {
			tipsForRender.push({
				isResult: true,
				result: offers[0].specifier[currentTip] + '. set',
			});
		}
		else if (currentTip.trim() === '') {
			tipsForRender.push({
				key: currentTip + '-' + i,
				isMargin: false,
				tip: null
			});
			continue;
		}
		else {
			const tipOffer = offers.find(o => o.tip.toLowerCase().startsWith(currentTip));

			if (tipOffer == null) {
				tipsForRender.push({
					key: currentTip + '-' + i,
					isMargin: false,
					tip: null
				});
				continue;
			}

			tipsForRender.push({
				isMargin: false,
				tip: tipOffer
			});
		}
	}

	return (
		<div className="offer__body__data offer__quotes">
			<div className="offer__quotes__row">
				{tipsForRender.map(tip => {
					if (tip == null || Object.keys(tip).length === 0) {
						/** If tip is null or is empty object. */
						return;
					}

					const tipKey = tip.tip != null ? tip.tip.id : 'margin' + tip.margin;

					return (
						<OfferTipLive
							key={tipKey}
							{...tip}
							className="quote quote--placeholder"
						/>
					);
				})}
			</div>
		</div>
	)
});