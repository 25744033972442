import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '../../../../../common/components/modal';

const BetSlipConfirmationModalTemplate = (props) => {
	const { t } = useTranslation();

	return (
		<Modal>
			<div className="popup">
				<div className="popup__card">
					<div className="popup__card__header">
						{/* {t('LIVE.INFO')} */}
						{props.headerText && props.headerText !== '' ? t(props.headerText) : null}
					</div>
					<div className="popup__card__body">
						<div className="message--base message--help">
							{props.bodyText && props.bodyText !== '' ? t(props.bodyText) : null}
						</div>
					</div>
					<div className="popup__card__footer">
						<button className="btn btn--sml btn--secondary u-mar--right--sml" onClick={props.onReject}>{t('BET_SLIP.NO')}</button>
						<button className="btn btn--sml btn--accent" onClick={props.onAccept}>{t('BET_SLIP.YES')}</button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default BetSlipConfirmationModalTemplate;