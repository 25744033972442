import React from 'react';
import { NavLink } from 'react-router-dom';

import { defaultTemplate } from '../../../../../common/hoc';

export default defaultTemplate(function (props) {
    const { t, liveSubheaderViewStore, match: { params } } = props;

    return (
        <nav className="tabs--primary">
            {liveSubheaderViewStore.menu.map((link) => (
                <NavLink
                    key={link.period}
                    className="tabs--primary__item"
                    activeClassName="is-active"
                    exact
                    to={`/${params.culture}/live/${link.period}`}
                >
                    {t(link.name)} ({liveSubheaderViewStore[link.countProp]})
                </NavLink>
            ))}
        </nav>
    );
});