import React from 'react';
import { defaultTemplate } from '../../../../../common/hoc';

export default defaultTemplate(function (props) {
    const { searchTerm, t } = props;

    return (
        <div className="message--sml message--note">
            {t('SEARCH.EMPTY_STATE.DESCRIPTION', { searchTerm: searchTerm })}
        </div>
    )
})