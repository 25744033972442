import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { useLoading, useNotification } from '../../../../../common/hooks';
import { ResultsMenuSport } from '../../../../../pages/results/components';
export default observer(function ResultsMenuTemplate(props) {

    const { setIsLoading } = useLoading();

    const {
        showError
    } = useNotification();

    const {
        store,
        store: {
            resultMenu
        }
    } = props

    useEffect(() => {

        async function fetchData() {
            setIsLoading(true);
            try{
                await store.onInitialize();                
            }
            catch(error) {
                showError(error);
                setIsLoading(false);
            }
        }

        fetchData();
    }, []);

    if (resultMenu == null) {
        return null;
    }

    return resultMenu.map(sport => {
        return <ResultsMenuSport key={sport.id} sport={sport} store={store} />
    })
})