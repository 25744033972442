const httpClient = App.utils.httpClient;

const requestHeaders = {
	"Content-Type": "application/json",
};

export class BettingLimitService {
	get baseUrl() {
		return `${BaseAddress}${App.utils.getAgencyKey()}`;
	}

	async getUserBettingLimitId() {
		const userId = App.state.rootStore.userAuthStore.user.id;

		const url = `${this.baseUrl}/user-accounts/users/${userId}`;
		return await httpClient.get(url, requestHeaders, {
			responseType: "json",
		});
	}

	async getUserAccounts() {
		const userId = App.state.rootStore.userAuthStore.user.id;

		const url = `${this.baseUrl}/user-accounts/users/${userId}`;
		return await httpClient.get(url, requestHeaders, {
			responseType: "json",
		});
	}

	async getUserBettingLimit(bettingAccountTypeId) {
		const userId = App.state.rootStore.userAuthStore.user.id;

		const url = `${this.baseUrl}/user-betting-limits?userIds=${userId}&bettingAccountTypeIds=${bettingAccountTypeId}`;
		return await httpClient.get(url, requestHeaders, {
			responseType: "json",
		});
	}

	async getLimitType(limitTypeId) {
		const url = `${this.baseUrl}/betting-user-limit-types/${limitTypeId}`;
		return await httpClient.get(url, requestHeaders, {
			responseType: "json",
		});
	}

	async getLimitDuration(limitDurationId) {
		const url = `${BaseAddress}${App.utils.getAgencyKey()}/betting-user-limit-durations/${limitDurationId}`;
		return await httpClient.get(url, requestHeaders, {
			responseType: "json",
		});
	}

	async insertBettingLimit(model) {
		const url = `${BaseAddress}${App.utils.getAgencyKey()}/user-betting-limits`;
		return await httpClient.post(
			url,
			JSON.stringify(model),
			requestHeaders,
			{ responseType: "json" }
		);
	}

	async decreaseBettingLimit(viewModel, limitId) {
		const url = `${this.baseUrl}/user-betting-limits/${limitId}`;
		return await httpClient.put(
			url,
			JSON.stringify(viewModel),
			requestHeaders,
			{ responseType: "json" }
		);
	}

	async deactivateBettingLimit(limitId) {
		const userId = App.state.rootStore.userAuthStore.user.id;

		const url = `${this.baseUrl}/user-betting-limits/deactivate-betting-limit/${userId}/${limitId}`;
		await httpClient.delete(url, requestHeaders);
	}

	async updateBettingLimit(limitId, model) {
		const url = `${this.baseUrl}/user-betting-limits/${limitId}`;
		return await httpClient.put(
			url,
			JSON.stringify(model),
			requestHeaders,
			{ responseType: "json" }
		);
	}
}
