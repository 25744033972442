import { useContext } from "react";
import { NotificationContext } from "../context";

export function useNotification() {
    const notificationCtx = useContext(NotificationContext);


    return {
        showError: (data) => {
            notificationCtx.showNotification({ type: "error", data });
        },
        showSuccess: (data) => {
            notificationCtx.showNotification({ type: "success", data });
        },
        showInfo: (data) => {
            notificationCtx.showNotification({ type: "info", data });
        },
        showWarning: (data) => {
            notificationCtx.showNotification({ type: "warning", data });
        },
        showSuccessWithRedirect: (data, buttons) => {
            notificationCtx.showNotification({ type: "success", data, buttons });
        },
        showErrorWithRedirect: (data, buttons) => {
            notificationCtx.showNotification({ type: "error", data, buttons });
        },
        showInfoMessage: (data) => {
            notificationCtx.showInfoNotification(data);
        },
        closeInfoMessage: () => {
            notificationCtx.closeInfoNotification();
        },
        closeNotification: () => {
            notificationCtx.closeNotification();
        },
        messageNotification: notificationCtx.messageNotification,
        notification: notificationCtx.notification,
    }
}

