import React from "react";
import { AccountStatementBetDetailsTemplate } from "themes/pages/account-statement/components/details";
import { AccountStatementBetDetailsError } from ".";
import { prepareOfferData } from "../../utils";

export default function AccountStatementBetDetails(props) {

    const { slipDetails, setShowDetails } = props;

    if (!slipDetails || Object.keys(slipDetails).length == 0) {

        return <AccountStatementBetDetailsError
            setShowDetails={setShowDetails}
        />
    }
    const finalOffers = prepareOfferData(slipDetails);

    return <AccountStatementBetDetailsTemplate
        slipDetails={slipDetails}
        setShowDetails={setShowDetails}
        finalOffers={finalOffers}
    />
}