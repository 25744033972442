import { useContext } from "react";
import {
    LoaderGetterContext,
    LoaderSetterContext
} from "../context/LoaderContext";

export function useLoading() {
    const loaderSetterCtx = useContext(LoaderSetterContext);
    const loaderGetterCtx = useContext(LoaderGetterContext);

    return {
        setIsLoading: (condition) => {
            loaderSetterCtx.setIsLoading(condition);
        },
        isLoading: loaderGetterCtx.isLoading,
    }
}