import { ErrorMessage } from "../../../../../common/components/error";
import { RequiredMark } from "../../../../../common/components/inputs";
import React from "react";
import { useTranslation } from "react-i18next";

const FormSelectFieldTemplate = (props) => {
	const { t } = useTranslation();

	const {
		label,
		name,
		className,
		dropdownData = [],
		errorMessage = undefined,
		required = false,
		placeholder = undefined,
		field,
		...rest
	} = props;

	return (
		<div className={className}>
			<div className="col-sml-4">
				<label className="form__field__label">
					{t(label)} <RequiredMark required={required} />
				</label>
			</div>

			<div className="col-sml-7">
				<select
					name={name}
					className="input input--base input--select"
					{...field}
					{...rest}
				>
					{placeholder ? (
						<option value="">{t(placeholder)}</option>
					) : null}
					{dropdownData.map((value) => {
						return (
							<option key={value.id} value={value.id}>
								{t(value.name)}
							</option>
						);
					})}
				</select>
			</div>
			<div className="col-sml-1">
				<ErrorMessage name={name} removeSuccessMark />
			</div>
		</div>
	);
};
export default FormSelectFieldTemplate;
