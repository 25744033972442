import React from 'react';
import PropTypes from 'prop-types';

import { NumberTemplate } from 'themes/common/components/display';


class Number extends React.Component {
    render() {
        return <NumberTemplate {...this.props} />
    }
}

Number.propTypes = {
    value: PropTypes.number.isRequired,
    decimalPlaces: PropTypes.number
}

Number.defaultProps = {
    decimalPlaces: 2
}

export default Number;