import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
	FormInputField,
	PasswordInput,
} from "../../../../../../common/components/inputs";
import { useUser } from "../../../../../../common/hooks";
import { PasswordStrengthBar } from "../../../../../../pages/membership/account-acitvation/components";

export default function AccountActivationPasswordSectionTemplate(props) {
	const { t } = useTranslation();
	const { accountActivationData } = props;

	if (accountActivationData == null) {
		return null;
	}

	const user = useUser();
	const { getValues } = useFormContext();
	const passwordPattern = /^[a-zA-Z0-9!#$()@?{}|*+,^.\-+&=_%:;~]{5,40}$/gm;
	const isPasswordRequired =
		accountActivationData?.companyInfo.isOfflineUserPasswordChangeMandatory;

	return (
		<>
			<div className="form__field row">
				<div className="col-sml-12">
					<div className="message--sml message--note">
						{!isPasswordRequired
							? t(
									"specific:MEMBERSHIP.ACCOUNT_ACTIVATION.ACC_ACTIVATION_PWD_LABEL"
							  )
							: t(
									"specific:MEMBERSHIP.ACCOUNT_ACTIVATION.PWD_HEADER"
							  )}
					</div>
				</div>
			</div>

			<FormInputField
				className="form__field row"
				name="userCardNumber"
				label="MEMBERSHIP.ACCOUNT_ACTIVATION.USERNAME_AND_CARD"
				readOnly
				defaultValue={accountActivationData?.userInfo?.userName}
			/>

			<PasswordStrengthBar
				userInfo={user.user}
				passwordPattern={passwordPattern}
				isPasswordRequired={isPasswordRequired}
			/>

			<PasswordInput
				name="confirmPassword"
				className="form__field row"
				label="specific:MEMBERSHIP.ACCOUNT_ACTIVATION.REPEAT_NEW_PWD"
				placeholder="specific:MEMBERSHIP.ACCOUNT_ACTIVATION.REPEAT_PWD_PLACEHOLDER"
				pattern={{
					value: passwordPattern,
				}}
				validate={{
					isEqual: (value) =>
						value === getValues("password") ||
						"USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.DOES_NOT_MATCH_PWD",
				}}
				popoverMsg={t(
					"specific:MEMBERSHIP.ACCOUNT_ACTIVATION.REPEAT_PWD_MESSAGE"
				)}
				required={isPasswordRequired}
			/>
		</>
	);
}
