import React, { useContext } from 'react';
import { EventTemplate } from 'themes/common/components/offer/new-offer';
import { EventContext, OfferStoreContext } from '@gp/components';

export function Event(props) {

	const event = useContext(EventContext);
	const offerStore = useContext(OfferStoreContext);

	return <EventErrorBoundary {...props} event={event} offerStore={offerStore} />
}

export class EventErrorBoundary extends React.Component {

	static contextType = EventContext;

	constructor(props) {

		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {

		return {
			hasError: true
		};
	}

	componentDidCatch(error, errorInfo) {
		this.setState({
			error,
			errorInfo,
			hasError: true,
		})
	}

	render() {
		if (this.state.hasError) {
			console.error(this.state?.error);
			console.error(this.state?.errorInfo);
			console.error(this.context);
			if(this.props.event?.id == null) {
				console.error("Event id is null");
				return null;
			}

			try{
				if(typeof(this.props.offerStore?.registerEventWithError) === 'function') {
					this.props.offerStore.registerEventWithError(this.props.event.id);
				}
			} catch (error) {
				console.error('Error registering event with error:', error);
			}

			return null;
		}

		return <EventTemplate {...this.props} />
	}
}