import React from 'react';
import { TournamentOfferListTemplate } from 'themes/pages/sports/components';

class TournamentOfferList extends React.Component {

    render() {
        return (<TournamentOfferListTemplate {...this.props} />)
    }
}

export default TournamentOfferList;