import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

export const TeamNameTemplate = observer((props) => {
	const { t } = useTranslation();

	if (props.gender === 1) {
		return (
			<>
				{props.name} ({t('OFFER.GENDER.WOMEN')})
			</>
		)
	}

	return (
		<span>
			{props.name}
		</span>
	)
});