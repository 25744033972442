import React from "react";
import { useTranslation } from "react-i18next";

export default function LoginErrorPopupTemplate(props) {
    const {
        loginError,
        setLoginError
    } = props;
    const { t } = useTranslation();
    if (loginError == null) {
        return null;
    }

    const closeErrorModal = () => {
        setLoginError(null)
    }

    return (
        <div className="popup--validation" >
            <div className="popup--validation__card">
                <div className="popup--validation__card__header">
                    <span>{t("MEMBERSHIP.LOGIN.TITLE")}</span>
                    <button className="btn btn--link" type="button" onClick={() => closeErrorModal()}>
                        <i className="u-icon u-icon--xsml u-icon--remove--white"></i>
                    </button>
                </div>
                <div className="popup--validation__card__body">
                    {loginError?.error?.message == null ?
                        <div>{t(`MEMBERSHIP.LOGIN.ERR_HANDLING.GENERIC_ERROR`)}</div>
                        :
                        <div>{t(`MEMBERSHIP.LOGIN.ERR_HANDLING.${loginError?.error?.message}`)}</div>
                    }
                </div>
            </div>
        </div>
    )
}