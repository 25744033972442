import React from "react";

const RequiredMarkTemplate = (props) => {
    if(!props.required){
        return null;
    }
    return (
        <span className="text-red-500"> *</span>
    )
}
export default RequiredMarkTemplate;