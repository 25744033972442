import React from 'react';
import { defaultTemplate } from '../../../../../common/hoc';

import { Checkbox } from '../../../../../common/components/inputs';

export default defaultTemplate(function (props) {
    const { menu, isLoading, t } = props;

    function renderSportLevel(sportLevel) {
        return (
            <li key={sportLevel.node.value} className="menu--sport__item">
                <button
                    className={`menu--sport__link ${sportLevel.expanded && 'is-expanded'} ${(sportLevel.checkState === 1) && 'is-checked'}`}
                    type="button"
                    onClick={e => sportLevel.toggleExpanded()}
                >
                    <div className="menu--sport__link__title">
                        <i className={`u-icon u-icon--sport u-icon--${sportLevel.node.abrv} u-mar--right--xsml`} />
                        <span>{sportLevel.node.label}</span>
                    </div>
                    <div className="menu--sport__link__action">
                        <span className="counter counter--neutral">{sportLevel.node.count}</span>
                        <div className="menu--sport__link__action-wrapper">
                            <Checkbox
                                id={`sport-${sportLevel.node.value}`}
                                className="input--check--sport"
                                name={sportLevel.node.label}
                                title={sportLevel.node.label}
                                checked={sportLevel.checkState === 1}
                                indeterminate={sportLevel.checkState === 2}
                                onClick={e => sportLevel.onCheck(sportLevel.getCheckedState({ toggle: true }))}
                                onChange={_ => { }}
                            />
                            <label htmlFor={`sport-${sportLevel.node.value}`} />
                        </div>
                    </div>
                </button>

                {/* RENDER SPORT CATEGORY */}
                {sportLevel.expanded ? (
                    <React.Fragment>
                        <ul className="menu--category">
                            {renderCategoryLevel(sportLevel)}
                        </ul>
                        {sportLevel.showMoreVisible ? (
                            <button className="menu--sport__toggle" type="button" onClick={e => sportLevel.toggleVisible(e)}>
                                {sportLevel.allChildrenVisible ? t('SPORTS_MENU.SHOW_LESS') : t('SPORTS_MENU.SHOW_MORE')}
                            </button>
                        ) : null}
                    </React.Fragment>
                ) : null}
            </li>
        )
    }

    function renderCategoryLevel(sportLevel) {
        const render = sportLevel.children.reduce((acc, categoryLevel, cIdx) => {
            if (sportLevel.visibleChildrenCount > 0 && cIdx >= sportLevel.visibleChildrenCount) {
                return acc;
            }

            acc.push(
                <li key={cIdx} className="menu--category__item">
                    <button
                        className={`menu--category__link ${categoryLevel.expanded && 'is-expanded'} ${(categoryLevel.checkState === 1) && 'is-checked'}`}
                        type="button"
                        onClick={e => categoryLevel.toggleExpanded()}
                    >
                        <div className="menu--category__link__title">{categoryLevel.node.label}</div>
                        <div className="menu--category__link__action">
                            <span className="counter counter--neutral">{categoryLevel.node.count}</span>
                            <div className="menu--category__link__action-wrapper">
                                <Checkbox
                                    id={`sport-${sportLevel.node.value}-category-${categoryLevel.node.value}`}
                                    className="input--check--category"
                                    name={categoryLevel.node.label}
                                    title={categoryLevel.node.label}
                                    checked={categoryLevel.checkState === 1}
                                    indeterminate={categoryLevel.checkState === 2}
                                    onClick={e => categoryLevel.onCheck(categoryLevel.getCheckedState({ toggle: true }))}
                                    onChange={_ => { }}
                                />
                                <label htmlFor={`sport-${sportLevel.node.value}-category-${categoryLevel.node.value}`} />
                            </div>
                        </div>
                    </button>

                    {/* RENDER TOURNAMENTS */}
                    {categoryLevel.expanded && (
                        <React.Fragment>
                            <ul className="menu--tournament">
                                {renderTournamentLevel(sportLevel, categoryLevel)}
                            </ul>
                            {categoryLevel.showMoreVisible && (
                                <button className="menu--sport__toggle" type="button" onClick={e => categoryLevel.toggleVisible(e)}>
                                    {categoryLevel.allChildrenVisible ? t('SPORTS_MENU.SHOW_LESS') : t('SPORTS_MENU.SHOW_MORE')}
                                </button>
                            )}
                        </React.Fragment>
                    )}
                </li>
            );

            return acc;
        }, []);

        return render;
    }

    function scrollToTop() {
        window.scrollTo(0, 0);
    }

    function renderTournamentLevel(sportLevel, categoryLevel) {
        const render = categoryLevel.children.reduce((acc, tournamentLevel, tIdx) => {
            if (categoryLevel.visibleChildrenCount > 0 && tIdx >= categoryLevel.visibleChildrenCount) {
                return acc;
            }

            acc.push(
                <li key={tIdx} className="menu--tournament__item">
                    <Checkbox
                        id={`sport-${sportLevel.node.value}-category-${categoryLevel.node.value}-tournament-${tournamentLevel.node.value}`}
                        className="input--check--tournament"
                        name={tournamentLevel.node.label}
                        title={tournamentLevel.node.label}
                        checked={tournamentLevel.checkState === 1}
                        indeterminate={tournamentLevel.checkState === 2}
                        onClick={e => tournamentLevel.onCheck(tournamentLevel.getCheckedState({ toggle: true }))}
                        onChange={_ => { }}
                    />
                    <label onClick={() => scrollToTop()} htmlFor={`sport-${sportLevel.node.value}-category-${categoryLevel.node.value}-tournament-${tournamentLevel.node.value}`} className="menu--tournament__link">
                        <span className="menu--tournament__name menu--tournament__name--long">{tournamentLevel.node.label}</span>
                        <span className="menu--tournament__counter counter counter--neutral">{tournamentLevel.node.count}</span>
                    </label>
                </li>
            );

            return acc;
        }, []);

        return render;
    }

    function renderMenu() {
        if (menu == null || menu.nodes == null || menu.nodes.length === 0) {
            return (
                <li className="menu--sport__empty">
                    <div className="message--base message--note">
                        {t('PREMATCH.SIDEMENU.EMPTY_STATE')}
                    </div>
                </li>
            )
        }

        return menu.nodes.map(sl => renderSportLevel(sl));
    }

    function renderMenuLoader() {
        return (
            <div className="loader--menu">
                <div className="loader__card">
                    <div className="loader__card__title">{t('COMMON.LOADING')}...</div>
                    <span />
                </div>
            </div>
        );
    }

    return (
        <nav className="menu--sport">
            <ul className="menu--sport__menu">
                {isLoading ? renderMenuLoader() : renderMenu()}
            </ul>
        </nav>
    )
});