import React from 'react';
import classnames from 'classnames';
import { maxBy } from 'lodash';

const pauseStatuses = [
    'not-started',
    'first-break',
    'second-break',
    'third-break',
    'fourth-break',
    'fifth-break',
    'sixth-break',
    'ended'
];

const pauseSkipSports = [
    'volleyball',
    'beach-volley'
];

const ServeIcon = (props) => <i className={classnames('u-icon u-icon--sport', `u-icon--${props.sport}`)} />

/**
 * Formats game score
 * @param {string} input 
 * @returns {string} formatted game score
 */
const formatGameScore = (input) => input.startsWith('g') ? input.substr(1) : input.toUpperCase();

export default function LiveOfferResultTemplate(props) {
    const { sport, result, matchStatus } = props;

    let gameScore;
    if (sport.abrv === 'tennis') {
        const gs = { home: '0', away: '0' };
        if (result.currentGameScore) {
            if (result.currentGameScore.home) {
                gs.home = formatGameScore(result.currentGameScore.home);
            }
            if (result.currentGameScore.away) {
                gs.away = formatGameScore(result.currentGameScore.away);
            }
        }

        if (result.tiebreak) {
            if (result.tiebreakScore != null) {
                gs.home = result.tiebreakScore.home || '0';
                gs.away = result.tiebreakScore.away || '0';
            }
            else {
                gs.home = '0';
                gs.away = '0';
            }
        }

        gameScore = (
            <sup className="offer__score__game">{gs.home}:{gs.away}</sup>
        );
    }

    let lastPeriodScore;
    if (pauseSkipSports.includes(sport.abrv) && pauseStatuses.includes(matchStatus)) {
        lastPeriodScore = {
            home: '-',
            away: '-'
        }
    }
    else {
        lastPeriodScore = maxBy(result.scorePerPeriod, 'periodNumber');
    }

    if (lastPeriodScore == null) {
        return (
            <React.Fragment>
                <td className="offer__body__data offer__quote offer__serve">&nbsp;</td>
                <td className="offer__body__data offer__quote offer__score">&nbsp;</td>
                <td className="offer__body__data offer__quote offer__serve">&nbsp;</td>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <td className="offer__body__data offer__quote offer__serve">
                {result.serve === 'home' ? <ServeIcon sport={sport.abrv} /> : ""}
            </td>
            <td className="offer__body__data offer__quote offer__score">
                <span className="offer__score__set">{lastPeriodScore.home || 0}:{lastPeriodScore.away || 0}</span>
                {gameScore}
            </td>
            <td className="offer__body__data offer__quote offer__serve">
                {result.serve === 'away' ? <ServeIcon sport={sport.abrv} /> : ""}
            </td>
        </React.Fragment>
    )
}