import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { isNullOrWhitespace } from "../../../../../../../utils/common/helpers";

export default function EmailAvailabilitySectionTemplate(props) {
	const { t } = useTranslation();
	const { email, error } = props;

	const availiabilityError = classNames("message--validation", {
		"field-validation-error":
			error !== undefined &&
			error?.type === "isAvailable" &&
			(!isNullOrWhitespace(email) || error?.type !== "pattern"),
		"field-validation-valid":
			error === undefined &&
			error?.type !== "isAvailable" &&
			(!isNullOrWhitespace(email) || error?.type !== "pattern"),
	});

	let addressAvailability = true;

	if (isNullOrWhitespace(email) || error?.type == "pattern") {
		addressAvailability = false;
	}

	return (
		<div className="form__field row">
			<div className="col-sml-4">
				<label htmlFor="email" className="form__field__label">
					{t(
						"specific:MEMBERSHIP.ACCOUNT_ACTIVATION.EMAIL_ADDRESS_AVAILABILITY"
					)}
				</label>
			</div>

			{addressAvailability ? (
				<>
					<div className="col-sml-7">
						<EmailAddressAvailable {...props} />
						<EmailAddressNotAvailable {...props} />
					</div>
					<div className="col-sml-1">
						<span className={availiabilityError}>
							<span></span>
						</span>
					</div>
				</>
			) : null}
		</div>
	);
}

function EmailAddressNotAvailable(props) {
	const { error, email } = props;
	const { t } = useTranslation();

	if (error === undefined || error?.type != "isAvailable") {
		return null;
	}

	return (
		<div className="u-type--style--italic u-type--color--error">
			{t("USER.ACCOUNT_SETTINGS.EMAIL_CHANGE.EMAIL_ADDRESS")}{" "}
			<strong>{email}</strong>{" "}
			{t("USER.ACCOUNT_SETTINGS.EMAIL_CHANGE.NOT_AVAILABLE")}
		</div>
	);
}

function EmailAddressAvailable(props) {
	const { error, email } = props;

	const { t } = useTranslation();

	if (error !== undefined && error.type == "isAvailable") {
		return null;
	}

	return (
		<div className="u-type--style--italic u-type--color--success">
			{t("USER.ACCOUNT_SETTINGS.EMAIL_CHANGE.EMAIL_ADDRESS")}{" "}
			<strong>{email}</strong>{" "}
			{t("USER.ACCOUNT_SETTINGS.EMAIL_CHANGE.AVAILABLE")}
		</div>
	);
}
