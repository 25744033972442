import React from 'react';
import { observer, inject } from 'mobx-react';

import { OfferTipTemplate } from 'themes/common/components/offer';

export default inject(stores => {
    const { betSlipStore, oddTypeStore } = stores.offerStore;

    const output = {
        addRemoveOffer: betSlipStore.addRemoveOffer,
        isInBetSlip: betSlipStore.betSlipState.isInBetSlip,
        isInOneClickBet: betSlipStore.betSlipState.isInOneClickBet,
        isOneClickBetActive: betSlipStore.betSlipState.isOneClickBetActive
    }

    if (oddTypeStore) {
        output.mapFn = oddTypeStore.mapFn;
    }

    return output;
})(observer(function (props) {
    return <OfferTipTemplate {...props} />;
}));