import React from "react";
import { observer } from "mobx-react-lite";
import { useFixBody, useAppContext } from "../../hooks";
import { AppPopupTemplate } from "themes/common/components/popups";

export default observer(function AppPopup() {

    const {
        isAppPopupVisible,
        hidePopupForSession
    } = App.state.rootStore.appPopupStore;

    const { culture } = useAppContext();

    useFixBody(true, isAppPopupVisible);

    const redirectToInstallPage = () => {
        hidePopupForSession();
        App.state.history.push(`/${culture}/info/install-app`);
    }

    if (!isAppPopupVisible) {
        return null;
    }

    return <AppPopupTemplate
        redirectToInstallPage={redirectToInstallPage}
    />
})