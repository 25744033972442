import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import {
    OfferStoreContext,
    OfferOptionsContext,
    EventContext,
    SportOfferContext,
    LookupContext,
} from '@gp/components';

import { GroupedEventOffer } from '../../../../../common/components/offer/grouped-event-offer';

/**
 * @param excludedBettingTypes {string[]} betting type abrv to be excluded from additional offer
 */
export default observer(function AdditionalOfferTemplate(props) {

    const {
        onClose,
        viewStore,
        viewStore: {
            rootStore: {
                betSlipStore
            },
        },
        excludedBettingOfferKeys,
    } = props;

    const options = {

        isAdditionalOffer: true,
        excludedBettingOfferKeys: excludedBettingOfferKeys || [],
        onClose,

        isOneClickBetActive: betSlipStore.betSlipState.isOneClickBetActive,
        onTipClick: betSlipStore.addRemoveOffer,

        isTipSelected: (tipId) => {
            if (betSlipStore.betSlipState.isOneClickBetActive) {
                return betSlipStore.betSlipState.isInOneClickBet(tipId);
            }
            return betSlipStore.isInBetSlip(tipId);
        },
    }

    return (
        <OfferStoreContext.Provider value={viewStore}>
            <LookupContext.Provider value={viewStore.lookupsStore}>
                <OfferOptionsContext.Provider value={options} >
                    <WrappedTemplateCore />
                </OfferOptionsContext.Provider>
            </LookupContext.Provider>
        </OfferStoreContext.Provider>
    )
});

const WrappedTemplateCore = observer(function WrappedTemplateCore(props) {

    const { } = props;

    const {
        isEmpty,
        isFetchingData,
        isStoreInitialized,
    } = useContext(OfferStoreContext);

    if (isEmpty || isFetchingData || !isStoreInitialized) {
        return null;
    }

    return (
        <tr className="offer__body__row">
            <td className="offer__body__data u-padd--reset" colSpan={8}>
                <div className="offer--additional">
                    <div className="offer--additional__body">
                        <TemplateCore />
                    </div>
                </div>
            </td>
        </tr>
    )
})

const TemplateCore = observer(function TemplateCore(props) {

    const { } = props;

    const {
        orderedEvents,
    } = useContext(OfferStoreContext);


    return orderedEvents.map(event => (
        <SportOfferContext.Provider key={event.sport.id + event.id} value={event.sport}>
            <EventContext.Provider value={event} >
                <GroupedEventOffer />
            </EventContext.Provider>
        </SportOfferContext.Provider>
    ))
})