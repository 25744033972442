import React from "react";
import { Link } from "react-router-dom";
import { Time, Timezone } from "../../../../../common/components/time";
import { defaultTemplate } from "../../../../../common/hoc";
import { PeriodConverter } from "../../../../../../state/common";

export default defaultTemplate(function (props) {
	const {
		showSportIcon = true,
		showOnlyTime = false,
		event,
		sport,
		match: {
			params: { culture, period, segments },
		},
		t,
	} = props;

	const isLive = event.isLive;

	let dateTimeColumn;

	if (isLive) {
		dateTimeColumn = <Time time={event.matchTime} />;
	} else if (showOnlyTime) {
		dateTimeColumn = (
			<Timezone dateTime={event.startTime}>
				{({ day, time }) => <React.Fragment>{time}</React.Fragment>}
			</Timezone>
		);
	} else {
		dateTimeColumn = (
			<Timezone dateTime={event.startTime}>
				{({ day, time }) => (
					<React.Fragment>
						<div>{day}</div>
						<div>{time}</div>
					</React.Fragment>
				)}
			</Timezone>
		);
	}

	let eventRoute;
	if (isLive) {
		eventRoute = `/${culture}/live/events/${event.id}`;
	} else {
		eventRoute = `/${culture}/sports/${
			PeriodConverter.IsValidPeriod(period) ? period : "full"
		}/event/${event.id}`;
		if (segments) {
			eventRoute += "/" + segments;
		}
	}

	const getName = (name, gender) => {
		if (gender === 1) {
			return `${name} ${t("OFFER.GENDER.WOMEN")}`;
		}

		return name;
	};

	const homeTeamName = getName(event.homeTeam, event.homeTeamGender);
	const awayTeamName = getName(event.awayTeam, event.awayTeamGender);

	return (
		<React.Fragment>
			<td
				className={
					"offer__body__data " +
					(isLive ? "offer__time" : "offer__day")
				}
			>
				{dateTimeColumn}
			</td>
			<td className="offer__body__data offer__match">
				<Link
					className="offer__match__competitors"
					to={eventRoute}
					data-tip={`${event.category.name} - ${event.tournament.name}: ${homeTeamName} - ${awayTeamName}`}
					data-for="team-description"
				>
					<div className="offer__match__team">{homeTeamName}</div>
					<i
						className={`offer__match__sport u-icon u-icon--sport u-icon--${sport.abrv}`}
					/>
					<div className="offer__match__team">{awayTeamName}</div>
				</Link>
			</td>
		</React.Fragment>
	);
});
