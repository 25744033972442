import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { PasswordInput } from "../../../../../../common/components/inputs";

export default function RecoverPasswordNewPasswordWithProgressTemplate(props) {
	const { t } = useTranslation();

	const { passwordPattern, onChangeInputPassword, progressCounter } = props;

	const progressClass = classNames("progress", [
		`progress--${progressCounter}`,
	]);

	return (
		<>
			<PasswordInput
				name="newPassword"
				className="form__field row"
				label="MEMBERSHIP.RECOVER_PASSWORD.NEW_PASSWORD"
				placeholder="MEMBERSHIP.RECOVER_PASSWORD.NEW_PASSWORD_PLACEHOLDER"
				onChange={onChangeInputPassword}
				pattern={{
					value: passwordPattern,
				}}
				required
			/>

			<div className="form__field row">
				<div className="col-sml-4">
					<label className="form__field__label">
						{t(
							"MEMBERSHIP.RECOVER_PASSWORD.PASSWORD_STRENGTH_LABEL"
						)}
					</label>
				</div>
				<div className="col-sml-7">
					<progress
						className={progressClass}
						value={progressCounter}
						max="100"
					></progress>
				</div>
				<div className="col-sml-1"></div>
			</div>
		</>
	);
}
