import React from 'react';
import { inject, observer } from 'mobx-react';

import { StatisticsPopupTemplate } from 'themes/common/components/popups';

export default inject(stores => ({
    closeStatisticsPopup: stores.offerStore.closeStatisticsPopup,
    statisticsBRData: stores.offerStore.statisticsBRData
}))(observer(function StatisticsPopup(props) {
    return <StatisticsPopupTemplate {...props} />
}))