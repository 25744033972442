import React from 'react';

import { ColumnType } from '@gp/components';

import { OfferColumnLive, OfferColumnResultLive } from '../../../../../../common/components/offer/new-offer/common';

export function EventOfferLiveTemplate(props) {

	const {
		columns: {
			columns,
		},
	} = props;

	return columns.map((col, idx) => 
		<Column key={col.id} column={col} />
	)
}

function Column(props) {

	const {
		column,
	} = props;

	if (column.type === ColumnType.RESULT) {
		return <OfferColumnResultLive />
	}

	return <OfferColumnLive {...column.componentProps} />
}