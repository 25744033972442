import { get } from "lodash";
import React, { useCallback, useEffect } from "react";
import { useWatch, useFormContext } from "react-hook-form";
import { EmailAvailabilitySectionTemplate } from 'themes/pages/membership/account-activation/components';
import { isNullOrWhitespace } from "../../../../../utils/common/helpers.v1mobile";
import { EmailChangeService } from "../../../administration/email-change/services";


var timeoutId = null;

const emailChangeService = new EmailChangeService();

export default function EmailAvailabilitySection(props) {

    const { setError, clearErrors, getFieldState } = useFormContext();

    const email = useWatch({ name: 'email' });
    const checkIsEmailAvailable = async (email) => {
        const response = await emailChangeService.checkIsEmailAvailable(email);
        return response.data
    }

    const { formState: { errors } } = useFormContext();

    const error = get(errors, "email");




    useEffect(() => {
        async function checkEmail() {
            if (isNullOrWhitespace(email) || getFieldState('email').invalid) {
                return;
            }

            if (timeoutId != null) {
                clearTimeout(timeoutId);
            }

            timeoutId = setTimeout(async () => {
                const isMailAvailable = await checkIsEmailAvailable(email);
                if (!isMailAvailable) {
                    setError('email', { type: 'isAvailable', message: 'USER.ACCOUNT_SETTINGS.EMAIL_CHANGE.EMAIL_EXISTS' });
                }
                else {
                    clearErrors('email.isAvailable');
                }
            }, 500);
        }

        checkEmail();
    }, [email]);

    return <EmailAvailabilitySectionTemplate
        email={email}
        error={error}
        {...props}
    />
}