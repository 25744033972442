import React from "react";
import { useTranslation } from "react-i18next";

export default function AutomaticLogoutPopupTemplate(props) {
	const { t } = useTranslation();
	const { logout } = props;

	return (
		<div className="popup">
			<div className="popup__card">
				<div className="popup__card__header">
					{t("SESSION.AUTO_LOGOUT.TITLE")}
				</div>
				<div className="popup__card__body">
					<div className="message--base message--note">
						{t("SESSION.AUTO_LOGOUT.LOGOUT_MESSAGE")}
					</div>
				</div>
				<div className="popup__card__footer">
					<button
						className="btn btn--sml btn--primary btn--icon-left"
						type="button"
						onClick={logout}
					>
						<i className="u-icon u-icon--xsml u-icon--arrow-right--white"></i>
						{t("SESSION.AUTO_LOGOUT.BUTTON_OK")}
					</button>
				</div>
			</div>
		</div>
	);
}
