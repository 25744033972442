import { chain } from "lodash";
import { isNullOrWhitespace } from "../../../utils/common/helpers";

const SGUID_empty = '0000000000000000000000';

export const formattedStatus = (bettingOfferStatus) => {
    switch (bettingOfferStatus) {
        case 0:
            return "inactive";
        case 1:
            return "active";
        case 2:
            return "win";
        case 3:
            return "win-with-reduced-odds";
        case 4:
            return "lose";
        case 5:
            return "refund";
        case 6:
            return "refund-half-bet-and-win-other-half";
        case 7:
            return "refund-half-bet-and-lose-other-half";
        case 8:
            return "cancelled";
        case 9:
            return "cashout";
        default:
            return "";
    }
}

export const prepareOfferData = (bet) => {
    const groupedOffers = chain(bet.betSlipOffers).groupBy((o) => o.eventId).value();
    var result = null;
    for (var key in groupedOffers) {
        if (groupedOffers[key].length > 1) {
            result = chain(groupedOffers[key]).orderBy('systemIdentifier').value();
            for (var i = 1; i < result.length; i++) {
                result[i].isMulti = true;
                result[i].multiEventName = (i + 1) + '. Multi';
            }
            groupedOffers[key] = result;
        }
    }
    return Object.values(groupedOffers).flatMap(x => x);
}

export const getTeamNames = (offer) => {
    if (!isNullOrWhitespace(offer.playerId) && offer.playerId != SGUID_empty) {
        return `${offer.playerFirstName}, ${offer.playerlastName}`;
    }
    else if (!isNullOrWhitespace(offer.teamId) && offer.teamId != SGUID_empty) {
        return offer.teamName;
    }
    return offer.bettingTypeTip;
}

export const getParsedCurrentResult = (offer) => {
    if (!isNullOrWhitespace(offer.currentResult)) {
        return JSON.parse(offer.currentResult);
    }
    return null;
}

export const getParsedResult = (offer) => {
    if (offer != null && offer.sportData != null && !isNullOrWhitespace(offer.sportData.result)) {
        return JSON.parse(offer.sportData.result);
    }
    return null;
}

export const getScorePerPeriod = (parsedResult) => {
    if (parsedResult != null && parsedResult.scorePerPeriod != null) {
        return parsedResult.scorePerPeriod.map(period => `${period.home || 0}:${period.away || 0}`).join("; ");
    }
    return "";
}

export const getCurrentResult = (parsedCurrentResult) => {
    if (parsedCurrentResult != null && parsedCurrentResult.currentScore != null) {
        return `${parsedCurrentResult.currentScore.home || 0}:${parsedCurrentResult.currentScore.away || 0}`
    }
    else if (parsedCurrentResult != null) {
        return `${parsedCurrentResult.home || 0}:${parsedCurrentResult.away || 0}`;
    }
    return "";
}

export const getCurrentScorePerPeriod = (currentResult, parsedCurrentResult) => {
    let currentScorePerPeriod = "";
    if (currentResult != null && parsedCurrentResult.scorePerPeriod != null) {

        for (let i = 0; i < parsedCurrentResult.scorePerPeriod.length; i++) {
            currentScorePerPeriod += `${(i != 0 ? " " : "")}(${parsedCurrentResult.scorePerPeriod[i].home || 0}:${parsedCurrentResult.scorePerPeriod[i].away || 0})`;
        }
    }
    return currentScorePerPeriod;
}

export const getSystemString = ({ system, combinationBettingOffersBankCount, eventCount, combinationBettingOffersCount }) => {
    if (combinationBettingOffersBankCount > 0) {
        return `(B x ${combinationBettingOffersBankCount}, ${system} / ${eventCount - combinationBettingOffersBankCount} = ${combinationBettingOffersCount})`;
    }
    return `(${system} / ${eventCount - combinationBettingOffersBankCount} = ${combinationBettingOffersCount})`;
}

