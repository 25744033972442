import React from 'react';
import { ModalTemplate } from "themes/common/components/modal";

class Modal extends React.Component {
    render() {
        return (
            <ModalTemplate {...this.props} />
        );
    }
}

export default Modal;

