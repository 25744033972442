import React from "react";
import { observer } from "mobx-react";

import { OfferOptionsContext } from "@gp/components";

import { LiveMatchResultLiveTemplate } from "themes/common/components/offer/new-offer/common";

const sportsWithIndicatedScore = ["soccer", "handball", "basketball"];

const notStarted = "not-started";

@observer
export class LiveMatchResultLive extends React.Component {
	static contextType = OfferOptionsContext;

	constructor(props) {
		super(props);

		this.state = {
			isScore: false,
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.event.currentScore == null) return;

		if (sportsWithIndicatedScore.includes(this.props.sport.abrv)) {
			if (
				prevProps.event.currentScore?.home !==
					this.props.event.currentScore?.home ||
				prevProps.event.currentScore?.away !==
					this.props.event.currentScore?.away
			) {
				this.setState({
					isScore: true,
				});

				setTimeout(() => {
					this.setState({
						isScore: false,
					});
				}, this.context.scoreChangeDuration);
			}
		}
	}

	render() {
		const getScore = (team) => {
			if (this.props.event.matchStatus === notStarted) {
				return "-";
			}

			if (this.props.event.currentScore != null) {
				return this.props.event.currentScore[team] || 0;
			}

			return 0;
		};

		return (
			<LiveMatchResultLiveTemplate
				isScore={this.state.isScore}
				home={getScore("home")}
				away={getScore("away")}
			/>
		);
	}
}
