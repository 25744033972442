import React from 'react';
import { inject } from 'mobx-react';
import { defaultTemplate } from '../../../common/hoc';
import { LanguagesTemplate } from 'themes/common/components/subheader';

@inject(stores => ({
    languageStore: stores.stateStore.languageStore
}))
@defaultTemplate
class Languages extends React.Component {
    async componentDidMount() {
        const { t } = this.props;

        document.querySelector('h1.title--primary').innerHTML = t('HOME.LANGUAGE');

        await this.props.languageStore.onInitialize();
    }

    render() {
        return <LanguagesTemplate {...this.props} />
    }
}

export default Languages;