import {observer, inject} from 'mobx-react';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { BetSlipDetailsTemplate } from 'themes/common/components/bet-slip';

@inject(stores => ({
    betSlipStore: stores.offerStore.betSlipStore,
}))
@observer
@withRouter
class BetSlipDetails extends React.Component {
    async componentDidMount() {
        const params = this.props.match.params;
        if(params != null) {
            const encryptedBetSlipNumber = params.encryptedBetSlipNumber;

            await this.props.betSlipStore.getSlipByEncryptedBetSlipNumber(encryptedBetSlipNumber);
        }
    }

    render() { 
        return <BetSlipDetailsTemplate {...this.props} />
    }
}
 
export default BetSlipDetails;