import React from 'react';
import classNames from 'classnames';

import { defaultTemplate } from '../../../../../../common/hoc';

export default defaultTemplate(function (props) {
    const { addRemoveOffer, isInBetSlip, isInOneClickBet, isOneClickBetActive, isMargin, tip, t } = props;

    let tipContent = (<div className="quote quote--placeholder"></div>);

    if (isMargin) {
        tipContent = (
            <div className="quote quote--button--alt">
                {tip}
            </div>
        );
    }
    else {
        if (!tip || !tip.value) {
            tipContent = (
                <div className="quote quote--placeholder"></div>
            );
        }
        else {
            const isInBs = isInBetSlip(tip.id);

            const btnClasses = classNames(
                'quote',
                'quote--button',
                {
                    'one-click': isOneClickBetActive,
                    'is-active': isOneClickBetActive ? isInOneClickBet(tip.id) : isInBs,
                    'desc': tip.indicator === -1,
                    'inc': tip.indicator === 1
                }
            );

            tipContent = (
                <button
                    type="button"
                    className={btnClasses}
                    title={tip.displayValue}
                    disabled={isInBs ? false : tip.isLocked}
                    onClick={e => addRemoveOffer(tip, e)}
                >
                    {tip.displayValue}
                </button>
            );
        }
    }

    return (
        <td className="offer__body__data offer__quote">
            {tipContent}
        </td>
    )
});