import React from 'react';
import PropTypes from 'prop-types';
import { BetSlipSubmitSuccessModalTemplate } from 'themes/common/components/bet-slip';

class BetSlipSubmitSuccessModal extends React.Component {
    render() {
        return <BetSlipSubmitSuccessModalTemplate {...this.props} />;
    }
}

BetSlipSubmitSuccessModal.propTypes = {
    onClose: PropTypes.func,
};

export default BetSlipSubmitSuccessModal;