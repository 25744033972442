import React from "react";
import { defaultTemplate } from "../../../../../common/hoc";

import { Modal } from "../../../../../common/components/modal";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

function MyBetsCashoutConfirmationTemplate(props) {
  const acceptChangeRef = useRef();

  return (
    <Modal id="myBetsCashoutConfirmation">
      <div className="popup">
        <div className="popup__card popup__card--cashout">
          <MyBetsCashoutConfirmationHeader {...props} />
          <MyBetsCashoutConfirmationBody
            acceptChangeRef={acceptChangeRef}
            {...props}
          />
          <MyBetsCashoutConfirmationFooter
            acceptChangeRef={acceptChangeRef}
            {...props}
          />
        </div>
      </div>
    </Modal>
  );
}

function MyBetsCashoutConfirmationHeader(props) {
  const { t } = useTranslation();

  return (
    <div className="popup__card__header">{t("MY_BETS.CASHOUT.TITLE")}</div>
  );
}

function MyBetsCashoutConfirmationBody(props) {
  const { acceptChangeRef, displayedAmount, isSubmitting, message } = props;
  const { t } = useTranslation();

  return (
    <div className="popup__card__body">
      {message != undefined ? (
        <div className="message--base message--warning">{message}</div>
      ) : (
        <div className="message--base message--help u-mar--bottom--reset">
          {t("MY_BETS.CASHOUT.MESSAGE", {
            amount: displayedAmount,
            currency: App.state.rootStore.currencyStore.currency,
          })}
        </div>
      )}
      <div className="u-mar--bottom--tny u-type--center">
        <input
          id="isChangeAccepted"
          className="input--check"
          ref={acceptChangeRef}
          disabled={isSubmitting}
          type="checkbox"
        />
        <label htmlFor="isChangeAccepted">
          {t("MY_BETS.CASHOUT.ACCEPT_CHANGED_AMOUNT")}
        </label>
      </div>
    </div>
  );
}

function MyBetsCashoutConfirmationFooter(props) {
  const {
    acceptChangeRef,
    id,
    calculatedCashoutAmount,
    onClose,
    onAccept,
    isSubmitting,
    isLive,
    cType,
  } = props;

  const { t } = useTranslation();

  return (
    <div className="popup__card__footer">
      <button
        className="btn btn--sml btn--ghost btn--icon-left"
        disabled={isSubmitting}
        onClick={onClose}
        type="button"
      >
        <i className="u-icon u-icon--xsml u-icon--remove"></i>
        {t("COMMON.CANCEL")}
      </button>
      <button
        className="btn btn--sml btn--primary btn--icon-left"
        disabled={isSubmitting}
        onClick={(e) => {
          onAccept({
            id: id,
            calculatedCashoutAmount: calculatedCashoutAmount,
            cType: cType,
            isChangeAccepted: acceptChangeRef.current.checked,
            isLive,
          });
        }}
        type="submit"
      >
        <i className="u-icon u-icon--xsml u-icon--check--white"></i>
        {t("MY_BETS.CASHOUT.TITLE")}
      </button>
    </div>
  );
}

export default defaultTemplate(MyBetsCashoutConfirmationTemplate);
