import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ForcePasswordChangeTemplate } from "themes/pages/membership/force-password-change";
import { isNullOrWhitespace } from "../../../../utils";
import { ErrorPage } from "../../../common/components/error";
import {
	useAppContext,
	useLoading,
	useNotification,
	useUser,
} from "../../../common/hooks";
import { ForcePasswordChangeService } from "../force-password-change/services";

const forcePasswordChangeService = new ForcePasswordChangeService();

export default function ForcePasswordChange() {
	const user = useUser();
	const [forcePasswordChangeData, setForcePasswordChangeData] =
		useState(null);
	const [forcePasswordChangeError, setForcePasswordChangeError] =
		useState(null);

	const methods = useForm({
		mode: "onChange",
	});
	const { reset } = methods;

	const { showError } = useNotification();
	const { setIsLoading } = useLoading();
	const { culture } = useAppContext();
	const { t } = useTranslation();

	const onSuccess = async (data) => {
		App.state.rootStore.userAuthStore.shouldDisplayAcceptNewTermsAfterLogin = false;
		await createForcePasswordChange(data);
	};

	const createForcePasswordChange = async (data) => {
		const accountActivationModel = {
			recoveryToken: user.accountActivation.activationToken,
			agencyId: user.accountActivation.agencyId,
			password: !isNullOrWhitespace(data.password) ? data.password : null,
			confirmPassword: !isNullOrWhitespace(data.newPasswordConfirm)
				? data.newPasswordConfirm
				: null,
			username: data.userCardNumber,
		};

		try {
			setIsLoading(true);
			const response =
				await forcePasswordChangeService.createForcePasswordChange(
					accountActivationModel
				);
			if (response.success) {
				user.resolveForcePasswordChange(
					response.data.user,
					response.data.token,
					response.data.userAccounts,
					response.data.loginHistory
				);
				App.state.history.push(`/${culture}/home/full`);
			}
		} catch (error) {
			console.error(error);
			if (error?.error?.userData) {
				if (error.error.userData?.activationToken) {
					//show popup with user data
					user.confirmationHandlingStore.confirmationHandlingUserData =
						error.error.userData;
					user.confirmationHandlingStore.showConfirmationPopup();
				} else {
					//show message about shop
					showError([
						{
							message:
								"USER.POPUPS.DATA_CONFIRMATION.USER_DATA_CONFIRMATION_REQUIRED",
							options: {
								0: error.error.userData.shopInfo,
							},
						},
					]);
				}
			} else {
				showError(
					`MEMBERSHIP.FORCE_PASSWORD_CHANGE.${error?.error?.message}`
				);
			}
		} finally {
			setIsLoading(false);
			reset();
		}
	};

	const logoutUser = async () => {
		try {
			setIsLoading(true);
			user.removeUserFromLocalStorage();
			sessionStorage.removeItem("slip");
			if (App.offer) {
				App.offer.rootStore.betSlipStore.betSlipState.reset();
			}
			App.state.history.push(`/${culture}/home/full`);
		} catch (error) {
			console.error(error);
			showError(t("USER.ACCOUNT_SETTINGS.ERR_HANDLING.GENERIC_ERROR"));
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		App.state.rootStore.titleStore.setTitle(
			t("MEMBERSHIP.FORCE_PASSWORD_CHANGE.TITLE_FPC")
		);

		//on mount scroll to top
		document.querySelector("html, body").scrollTo(0, 0);
		const fetchData = async () => {
			try {
				setIsLoading(true);
				const forcePwdChangeData =
					await forcePasswordChangeService.getForcePasswordChangeData(
						user.accountActivation.activationToken,
						user.accountActivation.agencyKey
					);
				setForcePasswordChangeData(forcePwdChangeData);

				if (
					forcePwdChangeData != null &&
					forcePwdChangeData.userInfo != null
				) {
					reset({
						userCardNumber: forcePwdChangeData.userInfo.userName,
					});
				}
			} catch (error) {
				setForcePasswordChangeError(error);
			} finally {
				setIsLoading(false);
			}
		};
		fetchData();

		() => {
			setForcePasswordChangeData(null);
			setForcePasswordChangeError(null);
		};
	}, []);

	return (
		<ErrorPage>
			<ForcePasswordChangeTemplate
				onSuccess={onSuccess}
				methods={methods}
				forcePasswordChangeData={forcePasswordChangeData}
				logoutUser={logoutUser}
				forcePasswordChangeError={forcePasswordChangeError}
				setForcePasswordChangeError={setForcePasswordChangeError}
				createForcePasswordChange={createForcePasswordChange}
			/>
		</ErrorPage>
	);
}
