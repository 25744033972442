import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { EventContext, OfferStoreContext } from '@gp/components';

import { EventDetails, EventOutrightOffer } from '../../../../../../common/components/offer/new-offer';


export const EventOutrightTemplate = observer((props) => {
	const offerStore = useContext(OfferStoreContext);
	const event = useContext(EventContext);

	const eventOffer = offerStore.eventKeysMap.get(event.id);

	return (
		<>
			<tr className="offer__body__row">
				<EventDetails />
			</tr>
			<tr className="offer__body__row">
				<td className="offer__body__data offer__outright" colSpan={2}>
					<EventOutrightOffer
						eventOffer={eventOffer}
						offerStore={offerStore}
					/>
				</td>
			</tr>
		</>
	)
});