import React from "react";
import { observer } from "mobx-react";
import { CookieConsentTemplate } from "themes/common/components/popups";

export default observer(function CookieConsent() {

    if (!App.state.rootStore.cookieConsentStore.isCookieConsentVisible) {
        return null;
    }

    const acceptCookies = () => {
        App.state.rootStore.cookieConsentStore.acceptCookieConsent();
    }

    return <CookieConsentTemplate
        acceptCookies={acceptCookies}
    />
})