export const OfferType = {
    ADDITIONAL_OFFER_DAILY: 'additional-offer-daily',
    ADDITIONAL_OFFER_WEEKLY: 'additional-offer-weekly',
    DAILY_OFFER: 'daily-offer',
    OUTRIGHT_OFFER: 'outright-offer',
    RESULTS_LAST_3_DAYS: 'results-last-3-days',
    RESULTS_TODAY: 'results-today',
    RESULTS_YESTERDAY: 'results-yesterday',
    TOP_OFFER: 'top-offer',
    WEEKLY_OFFER: 'weekly-offer'
}