import React from "react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { useFormContext } from "react-hook-form";
import {
	FormInputField,
	DatePicker,
} from "../../../../../../common/components/inputs";
import { FormButton } from "../../../../../../common/components/buttons";

export default function FormPasswordFormSectionTemplate(props) {
	const { t } = useTranslation();
	const { handleSubmit } = useFormContext();

	const { onFormSubmit } = props;

	const maxDate = DateTime.now().minus({ years: 18 }).toJSDate();
	const minDate = DateTime.now().minus({ years: 100 }).toJSDate();

	return (
		<div className="view--med">
			<h1 className="title title--primary">
				{t("MEMBERSHIP.FORGOT_PASSWORD.FORGOT_PASS_TITLE")}
			</h1>

			<div className="message--sml message--note">
				{t("MEMBERSHIP.FORGOT_PASSWORD.FORGOT_PASS")}
			</div>

			<form className="form" onSubmit={handleSubmit(onFormSubmit)}>
				<FormInputField
					className="form__field row"
					label="MEMBERSHIP.FORGOT_PASSWORD.FP_USERNAME"
					required
					name="userName"
					placeholder="MEMBERSHIP.FORGOT_PASSWORD.FP_USERNAME_PLACEHOLDER"
				/>

				<DatePicker
					className="form__field row"
					label="MEMBERSHIP.FORGOT_PASSWORD.DOB_LABEL"
					required
					name="dateOfBirth"
					maxDate={maxDate}
					minDate={minDate}
				/>

				<div className="form__field row">
					<div className="col-sml-11">
						<div className="u-type--xsml">
							{t("MEMBERSHIP.FORGOT_PASSWORD.CONTACT_ASTERISK")}
						</div>
					</div>
				</div>

				<div className="form__field row">
					<div className="col-sml-11 u-type--right">
						<FormButton
							btnText="MEMBERSHIP.FORGOT_PASSWORD.FP_SUBMIT_BTN"
							className="form__submit btn btn--med btn--secondary"
						/>
					</div>
				</div>
			</form>
		</div>
	);
}
