import React from 'react';
import { observer, inject } from 'mobx-react';

import { LiveTipTemplate } from 'themes/common/components/offer/v1';

export default inject(stores => {
    const betSlipStore = stores.offerStore.betSlipStore;
    const oddTypeStore = stores.offerStore.oddTypeStore;

    const output = {
        addRemoveOffer: betSlipStore.addRemoveOffer,
        isInBetSlip: betSlipStore.betSlipState.isInBetSlip,
        isInOneClickBet: betSlipStore.betSlipState.isInOneClickBet,
        isOneClickBetActive: betSlipStore.betSlipState.isOneClickBetActive
    }

    if (oddTypeStore) {
        output.mapFn = oddTypeStore.mapFn;
    }

    return output;
})(observer(function (props) {
    return (<LiveTipTemplate {...props} />);
}));