const httpClient = App.utils.httpClient;
const requestHeaders = {
	"Content-Type": "application/json",
};
class AccountLockDurationLookupService {
	/**
	 *
	 * @returns {promise}
	 */
	findLookup() {
		const url = `${BaseAddress}platform/platform-user-account-lock-duration-types?fields=id,abrv,name,units,languageMetadata&translate=true&rpp=20`;
		return httpClient.get(url, requestHeaders, { responseType: "json" });
	}
}

export default new AccountLockDurationLookupService();
