import React from "react";
import { useTranslation } from "react-i18next";
import { Accordion, AccordionOptionCard } from "../../../../../common/components/accordion";
import ReactMarkdown from "react-markdown";


export default function BettingRulesTemplate() {

    const { t } = useTranslation();

    return (
        <div id="betting-rules" className="view--info view--info--rules">
            <h1 className="title--primary">{t("specific:INFO_PAGES.BETTING_RULES.TITLE")}</h1>
            <Accordion header={t("specific:INFO_PAGES.BETTING_RULES.CONTRACT_PROVISIONS.TITLE")} active>

                <ReactMarkdown>
                    {t("specific:INFO_PAGES.BETTING_RULES.CONTRACT_PROVISIONS.CONTENT")}
                </ReactMarkdown>

                <AccordionOptionCard header={t("specific:INFO_PAGES.BETTING_RULES.CONTRACT_PROVISIONS.CARD_1.TITLE")}>
                    <ReactMarkdown>
                        {t("specific:INFO_PAGES.BETTING_RULES.CONTRACT_PROVISIONS.CARD_1.CONTENT")}
                    </ReactMarkdown>
                </AccordionOptionCard>

                <AccordionOptionCard header={t("specific:INFO_PAGES.BETTING_RULES.CONTRACT_PROVISIONS.CARD_2.TITLE")}>
                    <ReactMarkdown>
                        {t("specific:INFO_PAGES.BETTING_RULES.CONTRACT_PROVISIONS.CARD_2.CONTENT")}
                    </ReactMarkdown>
                </AccordionOptionCard>

                <AccordionOptionCard header={t("specific:INFO_PAGES.BETTING_RULES.CONTRACT_PROVISIONS.CARD_3.TITLE")}>
                    <ReactMarkdown>
                        {t("specific:INFO_PAGES.BETTING_RULES.CONTRACT_PROVISIONS.CARD_3.CONTENT")}
                    </ReactMarkdown>
                </AccordionOptionCard>


                <AccordionOptionCard header={t("specific:INFO_PAGES.BETTING_RULES.CONTRACT_PROVISIONS.CARD_4.TITLE")}>
                    <ReactMarkdown>
                        {t("specific:INFO_PAGES.BETTING_RULES.CONTRACT_PROVISIONS.CARD_4.CONTENT")}
                    </ReactMarkdown>
                </AccordionOptionCard>


                <AccordionOptionCard header={t("specific:INFO_PAGES.BETTING_RULES.CONTRACT_PROVISIONS.CARD_5.TITLE")}>
                    <ReactMarkdown>
                        {t("specific:INFO_PAGES.BETTING_RULES.CONTRACT_PROVISIONS.CARD_5.CONTENT")}
                    </ReactMarkdown>
                </AccordionOptionCard>


                <AccordionOptionCard header={t("specific:INFO_PAGES.BETTING_RULES.CONTRACT_PROVISIONS.CARD_6.TITLE")}>
                    <ReactMarkdown>
                        {t("specific:INFO_PAGES.BETTING_RULES.CONTRACT_PROVISIONS.CARD_6.CONTENT")}
                    </ReactMarkdown>
                </AccordionOptionCard>

                <AccordionOptionCard header={t("specific:INFO_PAGES.BETTING_RULES.CONTRACT_PROVISIONS.CARD_7.TITLE")}>
                    <ReactMarkdown>
                        {t("specific:INFO_PAGES.BETTING_RULES.CONTRACT_PROVISIONS.CARD_7.CONTENT")}
                    </ReactMarkdown>
                </AccordionOptionCard>

                <AccordionOptionCard header={t("specific:INFO_PAGES.BETTING_RULES.CONTRACT_PROVISIONS.CARD_8.TITLE")}>
                    <ReactMarkdown>
                        {t("specific:INFO_PAGES.BETTING_RULES.CONTRACT_PROVISIONS.CARD_8.CONTENT")}
                    </ReactMarkdown>
                </AccordionOptionCard>


                <AccordionOptionCard header={t("specific:INFO_PAGES.BETTING_RULES.CONTRACT_PROVISIONS.CARD_9.TITLE")}>
                    <ReactMarkdown>
                        {t("specific:INFO_PAGES.BETTING_RULES.CONTRACT_PROVISIONS.CARD_9.CONTENT")}
                    </ReactMarkdown>
                </AccordionOptionCard>


                <AccordionOptionCard header={t("specific:INFO_PAGES.BETTING_RULES.CONTRACT_PROVISIONS.CARD_10.TITLE")}>
                    <ReactMarkdown>
                        {t("specific:INFO_PAGES.BETTING_RULES.CONTRACT_PROVISIONS.CARD_10.CONTENT")}
                    </ReactMarkdown>
                </AccordionOptionCard>

                <AccordionOptionCard header={t("specific:INFO_PAGES.BETTING_RULES.CONTRACT_PROVISIONS.CARD_11.TITLE")}>
                    <ReactMarkdown>
                        {t("specific:INFO_PAGES.BETTING_RULES.CONTRACT_PROVISIONS.CARD_11.CONTENT")}
                    </ReactMarkdown>
                </AccordionOptionCard>

                <AccordionOptionCard header={t("specific:INFO_PAGES.BETTING_RULES.CONTRACT_PROVISIONS.CARD_12.TITLE")}>
                    <ReactMarkdown>
                        {t("specific:INFO_PAGES.BETTING_RULES.CONTRACT_PROVISIONS.CARD_12.CONTENT")}
                    </ReactMarkdown>
                </AccordionOptionCard>

            </Accordion >

            <Accordion header={t("specific:INFO_PAGES.BETTING_RULES.GENERAL_BETTING_RULES.TITLE")} active>

                <AccordionOptionCard header={t("specific:INFO_PAGES.BETTING_RULES.GENERAL_BETTING_RULES.CARD_1.TITLE")}>
                    <ReactMarkdown>
                        {t("specific:INFO_PAGES.BETTING_RULES.GENERAL_BETTING_RULES.CARD_1.CONTENT")}
                    </ReactMarkdown>
                </AccordionOptionCard>

                <AccordionOptionCard header={t("specific:INFO_PAGES.BETTING_RULES.GENERAL_BETTING_RULES.CARD_2.TITLE")}>
                    <ReactMarkdown>
                        {t("specific:INFO_PAGES.BETTING_RULES.GENERAL_BETTING_RULES.CARD_2.CONTENT")}
                    </ReactMarkdown>
                </AccordionOptionCard>

                <AccordionOptionCard header={t("specific:INFO_PAGES.BETTING_RULES.GENERAL_BETTING_RULES.CARD_3.TITLE")}>
                    <ReactMarkdown>
                        {t("specific:INFO_PAGES.BETTING_RULES.GENERAL_BETTING_RULES.CARD_3.CONTENT")}
                    </ReactMarkdown>
                </AccordionOptionCard>

            </Accordion>

            <Accordion header={t("specific:INFO_PAGES.BETTING_RULES.FINAL_PROVISIONS.TITLE")} active>
                <AccordionOptionCard header={t("specific:INFO_PAGES.BETTING_RULES.FINAL_PROVISIONS.CARD_1.TITLE")}>
                    <ReactMarkdown>
                        {t("specific:INFO_PAGES.BETTING_RULES.FINAL_PROVISIONS.CARD_1.CONTENT")}
                    </ReactMarkdown>
                </AccordionOptionCard>
            </Accordion>

        </div>
    )
}