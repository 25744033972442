import React from 'react';
import { defaultTemplate } from '../../../../../common/hoc';
import { OfferTip } from '../../../../../common/components/offer';

export default defaultTemplate(function (props) {
    const { offer, tips } = props;

    if (tips == null) {
        return (
            <td className="offer__body__data offer__quotes">
                <div className="offer__quotes__row">
                    <OfferTip />
                    <OfferTip />
                    <OfferTip />
                </div>
            </td>
        )
    }

    let leftTip = null;
    let middleTip = null;
    let rightTip = null;

    if (offer != null && offer.length > 0) {
        if (tips[0] === 'margin') {
            const specifierKey = Object.keys(offer[0].specifier)[0];
            leftTip = {
                isMargin: true,
                value: offer[0].specifier[specifierKey]
            };
            middleTip = offer.find(o => o.tip.toLowerCase().startsWith(tips[1]));
            rightTip = offer.find(o => o.tip.toLowerCase().startsWith(tips[2]));
        }
        else {
            leftTip = {
                isMargin: false,
                value: offer.find(o => o.tip.toLowerCase().startsWith(tips[0]))
            };
            middleTip = offer.find(o => o.tip.toLowerCase().startsWith(tips[1]));
            rightTip = offer.find(o => o.tip.toLowerCase().startsWith(tips[2]));
        }

        return (
            <React.Fragment>
                <td className="offer__body__data offer__quotes">
                    <div className="offer__quotes__row">
                        <OfferTip tip={leftTip.value} isMargin={leftTip.isMargin} />
                        <OfferTip tip={middleTip} />
                        <OfferTip tip={rightTip} />
                    </div>
                </td>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <td className="offer__body__data offer__quotes">
                <div className="offer__quotes__row">
                    <OfferTip />
                    <OfferTip />
                    <OfferTip />
                </div>
            </td>
        </React.Fragment>
    );


    // if (!offer || !offer.offers) {
    //     return (
    //         <React.Fragment>
    //             <OfferTip />
    //             <OfferTip />
    //             <OfferTip />
    //         </React.Fragment>
    //     )
    // }

    // const offers = offer.offers;

    // if (offers.length === 0) {
    //     return (
    //         <React.Fragment>
    //             <OfferTip />
    //             <OfferTip />
    //             <OfferTip />
    //         </React.Fragment>
    //     )
    // }

    // if (tips[0] === ' ') {
    //     const specifierKey = Object.keys(offers[0].specifier)[0];
    //     leftTip = (
    //         <OfferTip isMargin margin={offers[0].specifier[specifierKey]} />
    //     );
    //     middleTip = (
    //         <OfferTip tip={offers.find(o => o.tip.toLowerCase().startsWith(tips[1]))} />
    //     );
    //     rightTip = (
    //         <OfferTip tip={offers.find(o => o.tip.toLowerCase().startsWith(tips[2]))} />
    //     );
    // }
    // else {
    //     leftTip = (
    //         <OfferTip tip={offers.find(o => o.tip.toLowerCase().startsWith(tips[0]))} />
    //     );
    //     middleTip = (
    //         <OfferTip tip={offers.find(o => o.tip.toLowerCase().startsWith(tips[1]))} />
    //     );
    //     rightTip = (
    //         <OfferTip tip={offers.find(o => o.tip.toLowerCase().startsWith(tips[2]))} />
    //     );
    // }

    // return (
    //     <React.Fragment>
    //         {leftTip}
    //         {middleTip}
    //         {rightTip}
    //     </React.Fragment>
    // )
});