import React from "react";
import { useTranslation } from "react-i18next";

export default function LoaderTemplate() {
    const { t } = useTranslation();
    return (
        <div className="loader">
            <div className="loader__card">
                <div className="loader__card__title">{t('HOME.LOADING')}...</div>
                <span />
            </div>
        </div>
    )
}
