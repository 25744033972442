import { useContext } from "react";
import { CurrencyContext } from "../context";

export function useCurrency() {
    const currencyCtx = useContext(CurrencyContext);

    return {
        currency: currencyCtx.currency
    }
}

