import { observer, inject } from 'mobx-react';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { QRCodeScannerTemplate } from 'themes/common/components/subheader';
import { ErrorPage } from '../error';

@inject(stores => ({
    qRCodeScannerStore: stores.stateStore.qRCodeScannerStore
}))
@observer
@withRouter
class QRCodeScanner extends React.Component {
    async componentDidMount() {
        await this.props.qRCodeScannerStore.isMobileAppCheck();
        await this.props.qRCodeScannerStore.initializeCameraConstraints();
    }

    render() {
        return (
            <ErrorPage>
                <QRCodeScannerTemplate {...this.props} />
            </ErrorPage>
        )
    }
}
export default QRCodeScanner;
