const httpClient = App.utils.httpClient;

const requestHeaders = {
	"Content-Type": "application/json",
};
export class AutomaticLogoutService {
	setUserAutomaticLogoutSettings(userLimitSettings) {
		const user = JSON.parse(localStorage.getItem("user"));
		const userId = user.id;
		const url = `${BaseAddress}platform/agency-users/${App.utils.getAgencyId()}/${userId}/limit-settings`;

		return httpClient.put(
			url,
			JSON.stringify(userLimitSettings),
			requestHeaders,
			{ responseType: "json" }
		);
	}
}
