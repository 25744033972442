import {
	ErrorResponse,
	UserDataService,
	Response,
} from "../../../../../administration/services/common";

export class AccountActivationService {
	constructor() {
		this.userDataService = new UserDataService();
	}

	async getAccountActivationData(accountActivationToken) {
		if (accountActivationToken == null) {
			return;
		}
		if (accountActivationToken != null) {
			const userInfo = await this.userDataService.getCardUserInfoAsync();
			const companyInfo =
				await this.userDataService.getLimitedCompanySettings();
			const model = {
				userInfo: userInfo,
				companyInfo: companyInfo,
			};
			return model;
		}
	}

	async createAccountActivation(activationModel) {
		try {
			const response =
				await this.userDataService.setOfflineUserPasswordAndEmail(
					activationModel
				);
			return new Response(response);
		} catch (error) {
			console.error(error);

			let errorMessage = null;
			let userData = null;
			if (error?.data != null) {
				//todo fix this error mapping
				if (error?.data.errorCode == 400213) {
					userData = error?.data;
				} else {
					switch (error?.data?.error?.errorCode) {
						case 400170:
							errorMessage = "ERROR_PWD_CHANGE_CURRENT_SAME";
							break;

						case 400171:
							errorMessage = "ERROR_PWD_CHANGE_OLD_SAME";
							break;

						case 400141:
							errorMessage = "AUTO_LOGOUT_DISABLE_ERROR";
							break;

						case 400165:
							errorMessage = "ERROR_PWD_CHANGE_SIMILAR";
							break;

						default:
							errorMessage = "GENERIC_ERROR";
							break;
					}
				}
			}
			throw new ErrorResponse({
				message: errorMessage,
				errorCode: error,
				userData: userData,
			});
		}
	}
}
