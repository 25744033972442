import React from 'react';
import { observer, inject } from 'mobx-react';

import { AdditionalOfferRowTipTemplate } from 'themes/common/components/additional-offer';

export default inject(stores => {
    const betSlipStore = stores.offerStore.betSlipStore;

    return {
        addRemoveOffer: betSlipStore.addRemoveOffer,
        isInBetSlip: betSlipStore.betSlipState.isInBetSlip, 
        isInOneClickBet: betSlipStore.betSlipState.isInOneClickBet,
        isOneClickBetActive: betSlipStore.betSlipState.isOneClickBetActive
    }
})(observer(function (props) {
    return <AdditionalOfferRowTipTemplate {...props} />;
}));