const httpClient = App.utils.httpClient;

const requestHeaders = {
    'Content-Type': 'application/json',
};

export class MyLimitsService {

    async getAvailableCategories(categoryId = null) {
        let apiUrl;

        if (categoryId == null) {
            apiUrl = `${BaseAddress}${App.utils.getAgencyKey()}/gaming-limit-categories/available-categories?translate=True`;
        }
        else {
            apiUrl = `${BaseAddress}${App.utils.getAgencyKey()}/gaming-limit-categories/available-categories/?categoryId=${categoryId}&translate=True`;
        }

        return await httpClient.get(apiUrl, requestHeaders, { responseType: 'json' });
    }

    async resolveGamingLimit(resolveUserLimit) {
        const url = `${BaseAddress}${App.utils.getAgencyKey()}/gaming-limits/resolve`;
        return await httpClient.post(url, JSON.stringify(resolveUserLimit), requestHeaders, { responseType: 'json' });
    }

    async resolveGamingLimitRequest(gamingLimitRequest) {
        const url = `${BaseAddress}${App.utils.getAgencyKey()}/gaming-limits/resolve-request`;
        return await httpClient.post(url, JSON.stringify(gamingLimitRequest), requestHeaders, { responseType: 'json' });
    }

}