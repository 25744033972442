
import React from "react";
import { FormProvider } from "react-hook-form";
import {
    SettingsMessagePartial,
    AutomaticLogoutLookupsContainer
} from "./components";
import { FormButton } from "../../../../../common/components/buttons";

export default function AutomaticLogoutTemplate(props) {

    const {
        fetchedData,
        methods,
        onSuccess,
    } = props;

    const { handleSubmit } = methods;

    return (
        <div>
            <FormProvider {...methods}>
                <form className="form" onSubmit={handleSubmit(onSuccess)}>
                    <div className="form__group">
                        <SettingsMessagePartial fetchedData={fetchedData} />
                        <AutomaticLogoutLookupsContainer fetchedData={fetchedData} />
                        <div className="col-sml-11 u-type--right">
                            <FormButton btnText="USER.ACCOUNT_SETTINGS.AUTOMATIC_LOGOUT.SAVE_BUTTON" />
                        </div>
                    </div>
                </form>
            </FormProvider>
        </div>
    )
}


