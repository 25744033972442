import React from 'react';
import { MyBetsCashoutConfirmationTemplate } from 'themes/common/components/my-bets';
import { useFixBody } from '../../hooks';

export default function MyBetsCashoutConfirmation(props) {
    useFixBody(true, props.isOpen);

    return (
        <MyBetsCashoutConfirmationTemplate {...props} />
    )
}
