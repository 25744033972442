import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

const ServeIcon = (props) => <i className={classnames('u-icon u-icon--sml u-icon--sport', `u-icon--${props.sport}`, 'u-align--v--middle')} />

export const ScoreLiveTemplate = observer((props) => {
	return (
		<div className={classnames(
			"offer__body__data offer__score",
			{
				"server-only": props.gameScore == null && (props.serve === 'home' || props.serve === 'away'),
				"set-only": props.gameScore == null && (props.serve == null || props.serve === '')
			}
		)}>
			<div className={classnames(
				"offer__score__server",
				{
					"is-empty": props.serve == null || props.serve === ''
				}
			)}>
				<div>
					{props.serve === 'home' ? <ServeIcon sport={props.sport.abrv} /> : ""}
				</div>
				<div>
					{props.serve === 'away' ? <ServeIcon sport={props.sport.abrv} /> : ""}
				</div>
			</div>

			{props.gameScore != null ? (
				<div className="offer__score__game">
					<div>{props.gameScore.home || 0}</div>
					<div>{props.gameScore.away || 0}</div>
				</div>
			) : (
				<div className="offer__score__game">&nbsp;</div>
			)}

			{props.lastPeriodScore != null ? (
				<div className="offer__score__set">
					<div>{props.lastPeriodScore.home || 0}</div>
					<div>{props.lastPeriodScore.away || 0}</div>
				</div>
			) : (
				<div className="offer__score__set">&nbsp;</div>
			)}
		</div>
	);
})