import React, { useContext } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import { MyBetsStoreContext } from "../../../../../common/context";

export default observer(function CancelBetSlipMessagePopUp(props) {
  const {
    betCancelStore: { betCancelMessage, onExitBetCancel },
  } = useContext(MyBetsStoreContext);
  const { t } = useTranslation();

  if (betCancelMessage == null) {
    return null;
  }

  return (
    <div className="popup">
      <div className="popup__card">
        <div className="popup__card__header">
          {t("MY_BETS.BET_CANCEL.INFO_TITLE")}
        </div>
        <div className="popup__card__body">
          <div className="message--base message--success">
            {betCancelMessage}
          </div>
        </div>
        <div className="popup__card__footer">
          <button
            className="btn btn--sml btn--primary btn--icon-left"
            onClick={onExitBetCancel}
          >
            <i className="u-icon u-icon--xsml u-icon--reload--white"></i>
            {t("COMMON.CLOSE")}
          </button>
        </div>
      </div>
    </div>
  );
});
