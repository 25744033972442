import { DateTime } from "luxon";
import { BettingLimitService as BettingLimitDataService } from "../../../../../administration/services/account-settings/betting-limits/BettingLimitService";

import {
    ErrorResponse,
    Response
} from "../../../../../administration/services/common";

import {
    bettingUserLimitTypesLookup,
    bettingUserLimitDurationLookup
} from "../../../../../administration/services/account-settings/betting-limits/lookups";
import { 
    DecreaseViewModel, 
    InsertLimitViewModel, 
    UpdateOrDeleteViewModel 
} from "../../../../../administration/services/account-settings/betting-limits/models";

export class BettingLimitService {
    constructor() {
        this.bettingLimitDataService = new BettingLimitDataService();
    }

    async getBettingTypeAccountId() {
        const userAccount = await this.bettingLimitDataService.getUserBettingLimitId();
        return userAccount.item.map(item => {
            return item.accountTypeId
        });
    }

    async getBettingLimitTypesLookup() {
        try {
            return await bettingUserLimitTypesLookup.findLookup();
        } catch (error) {
            if (error.message == 'Unauthorized') {
                return new ErrorResponse();
            }
            throw new ErrorResponse({ message: 'GENERIC_ERROR' });
        }
    }

    async getBettingLimitDurationLookup() {
        try {
            return await bettingUserLimitDurationLookup.findLookup();
        } catch (error) {
            if (error.message == 'Unauthorized') {
                return new ErrorResponse();
            }
            throw new ErrorResponse({ message: 'GENERIC_ERROR' });
        }
    }


    async getBettingLimitsData() {

        try {
            const model = {};
            const userAccount = await this.bettingLimitDataService.getUserAccounts();
            const defaultBettingAccountTypeId = userAccount.item[0].accountTypeId;
            const userAccountTypeId = userAccount.item[0].accountTypeId
            const currentBettingLimit = await this.bettingLimitDataService.getUserBettingLimit(userAccountTypeId);
           
            if (currentBettingLimit.item.length != 0 && currentBettingLimit?.item[0].expirationDate <= DateTime.local().toISO()) {

                // update or delete betting limit limit view model

                const currentLimit = currentBettingLimit?.item[0];
                const bettingUserLimitType = await this.bettingLimitDataService.getLimitType(currentLimit.bettingUserLimitTypeId);
                const bettingUserLimitDuration = await this.bettingLimitDataService.getLimitDuration(currentLimit.bettingUserLimitDurationId);
                const viewModel = new UpdateOrDeleteViewModel(currentLimit,bettingUserLimitType,bettingUserLimitDuration,userAccount);
               
                const bettingLimitDataViewModel = {
                    viewType: 1,
                    defaultBettingAccountTypeId: model.defaultBettingAccountTypeId,
                    viewData: viewModel,
                }

                return new Response(bettingLimitDataViewModel);

            } else if (currentBettingLimit.item.length != 0) {

                // decrease betting limit limit view model

                const currentBettingLimit = await this.bettingLimitDataService.getUserBettingLimit(userAccountTypeId);
                const currentLimit = currentBettingLimit?.item[0];
                const bettingUserLimitType = await this.bettingLimitDataService.getLimitType(currentLimit.bettingUserLimitTypeId);
                const bettingUserLimitDuration = await this.bettingLimitDataService.getLimitDuration(currentLimit.bettingUserLimitDurationId);

                const viewModel = new DecreaseViewModel(currentLimit, bettingUserLimitType,bettingUserLimitDuration,userAccount);

                const bettingLimitViewData = {
                    viewType: 2,
                    defaultBettingAccountTypeId: defaultBettingAccountTypeId,
                    viewData: viewModel,
                }    
                return new Response(bettingLimitViewData);
            
            } else {

                //insert betting limit view model 

                const viewModel = new InsertLimitViewModel();

                const bettingLimitDataViewModel = {
                    viewType: 0,
                    defaultBettingAccountTypeId: model.defaultBettingAccountTypeId,
                    viewData: viewModel,
                }
                return new Response(bettingLimitDataViewModel);
            }
        } catch (error) {
            if (error.message == 'Unauthorized') {
                return new ErrorResponse();
            }
        }
    }


    async insertBettingLimit(model) {
        const userId = App.state.rootStore.userAuthStore.user.id;

        try {
            const bettingId = await this.getBettingTypeAccountId();
            const modelData = {
                bettingAccountTypeId: bettingId[0],
                bettingUserLimitDurationId: model.limitDuration,
                bettingUserLimitTypeId: model.limitType,
                limitAmount: model.limitAmount,
                userId: userId,
            }
            await this.bettingLimitDataService.insertBettingLimit(modelData);
            const response = await this.getBettingLimitsData();
            return new Response(response);
        } catch (error) {
            if (error.message == 'Unauthorized') {
                return new ErrorResponse();
            }
            throw new ErrorResponse({ message: "BETTING_LIMIT_ERR" });
        }
    }


    async decreaseBettingLimit(model) {
        try {
            const userId = App.state.rootStore.userAuthStore.user.id;

            const bettingId = await this.getBettingTypeAccountId();
            const id = bettingId[0];


            const limit = await this.bettingLimitDataService.getUserBettingLimit(id);
            const limitData = limit.item[0];
            let viewModel = {};
            if (limit == null) {
                throw new Error();
            }
            viewModel.limitAmount = model.limitAmount;
            viewModel.bettingAccountTypeId = id;
            viewModel.bettingUserLimitDurationId = limitData.bettingUserLimitDurationId;
            viewModel.bettingUserLimitTypeId = limitData.bettingUserLimitTypeId;
            viewModel.userId = userId;

            await this.bettingLimitDataService.decreaseBettingLimit(viewModel, limitData.id);
            const response = await this.getBettingLimitsData();
            return new Response(response);
        } catch (error) {
            if (error.message == 'Unauthorized') {
                return new ErrorResponse();
            }
        }

    }

    async deactivateBettingLimit(limitId) {
        try {
            await this.bettingLimitDataService.deactivateBettingLimit(limitId);
            return new Response();
        } catch (error) {
            if (error.message == 'Unauthorized') {
                return new ErrorResponse();
            }
            throw new ErrorResponse({ message: "GENERIC_ERROR" });
        }
    }

    async updateBettingLimit(limitId, model) {
        const userId = App.state.rootStore.userAuthStore.user.id;

        const bettingId = await this.getBettingTypeAccountId();
        model.expirationDate = DateTime.local().plus({ days: 7 });
        const modelData = {

            bettingAccountTypeId: bettingId[0],
            bettingUserLimitDurationId: model.limitDuration,
            bettingUserLimitTypeId: model.limitType,
            limitAmount: model.limitAmount,
            userId: userId,
        }
        try {
            await this.bettingLimitDataService.updateBettingLimit(limitId, modelData);

            const response = await this.getBettingLimitsData();
            return new Response(response);

        } catch (error) {
            if (error.message == 'Unauthorized') {
                return new ErrorResponse();
            }

            if (error.errorCode == "4000022000") {
                throw new ErrorResponse({ message: "GENERIC_UPDATE_FAIL" });
            } else {
                throw new ErrorResponse({ message: "BETTING_LIMIT_ERR" });
            }
        }
    }
}





