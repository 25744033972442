import React from "react";
import { useTranslation } from "react-i18next";

export default function LiveSessionExpirePopupTemplate() {
    const { t } = useTranslation();
    return (
        <div className="popup popup--session">
            <div className="popup__card">
                <div className="popup__header">
                    {t("SESSION.LIVE_SESSION_EXPIRE.LIVE_SESSION_EXPIRED_LABEL")}
                </div>
                <div className="popup__card__body">
                    <div className="message--base message--note">{t("SESSION.LIVE_SESSION_EXPIRE.LIVE_SESSION_EXPIRED_MESSAGE")}</div>
                </div>
                <div className="popup__card__footer">
                    <button className="btn btn--sml btn--primary btn--icon-left"
                        onClick={() => {
                            App.state.rootStore.liveSessionExpireStore.onRefresh();
                        }}
                        type="button"
                        >
                            <i className="u-icon u-icon--sml u-icon--reload--white"></i>
                            {t("SESSION.LIVE_SESSION_EXPIRE.BUTTON_REFRESH")}
                    </button>
                </div>
            </div>
        </div>
    )
}