import React from 'react';
import classnames from 'classnames';

import { defaultTemplate } from '../../../../../common/hoc';

const PagerButton = (props) => (
    <button
        type="button"
        disabled={props.disabled}
        onClick={e => props.onPageChange(props.page)}
        className={classnames("pagination__item", props.className, { "is-active": props.active })}
    >
        {props.children}
    </button>
);

const PageButton = (props) => (
    <PagerButton {...props}>
        {props.page}
    </PagerButton>
);

const IconButton = (props) => (
    <PagerButton {...props} className="pagination__item--transparent">
        {props.previous ? (
            <i className="u-icon u-icon--xsml u-icon--arrow-left" />
        ) : (
            <i className="u-icon u-icon--xsml u-icon--arrow-right" />
        )}
    </PagerButton>
);

const RemoveAll = defaultTemplate(function (props) {
    if (!props.onCloseAll) {
        return null;
    }
    return (
        <div className="pagination__remove">
            <button type="button" className="pagination__remove__btn btn btn--link" onClick={props.onCloseAll}>
                {props.t("COMMON.REMOVE_ALL")}
            </button>
        </div>
    )
})

export default function OfferPagerTemplate(props) {
    const {
        pageNumber,
        pageSize,
        totalItems,
        onPageChange,
        isRemoveAllVisible,
        onCloseAll,
        rightPlacement = false
    } = props;

    const remainder = totalItems % pageSize;
    const totalPages = remainder === 0 ? (totalItems / pageSize) : ((totalItems - remainder) / pageSize) + 1;

    if (totalPages <= 1) {
        return (
            <React.Fragment>
                <div className="pagination no-print">
                    {isRemoveAllVisible && <RemoveAll onCloseAll={onCloseAll} />}
                </div>
            </React.Fragment>
        )
    }

    let start = 1;
    let end = totalPages;

    if (totalPages > 3) {
        end = 3;
    }
    if (totalPages > 3 && pageNumber > 1) {
        start = pageNumber - 1;
        end = pageNumber + 1;
        if (end > totalPages) {
            end = totalPages;
            start = totalPages - 2;
        }
    }

    const renderInnerPager = () => {
        const ip = [];

        for (let i = start; i <= end; ++i) {
            if (i === pageNumber) {
                ip.push(
                    <PageButton
                        key={i}
                        page={i}
                        onPageChange={onPageChange}
                        disabled
                        active
                    />
                );
            }
            else {
                ip.push(
                    <PageButton
                        key={i}
                        page={i}
                        onPageChange={onPageChange}
                    />
                )
            }
        }

        return ip;
    }

    return (
        <React.Fragment>
            <nav className={classnames("pagination", {"pagination--secondary" : !rightPlacement, "pagination--right" : rightPlacement})}>
                <div>
                    {(totalPages > 3 && pageNumber > 1 && start > 1) ? (
                        <React.Fragment>
                            <PageButton
                                page={1}
                                onPageChange={onPageChange}
                            />
                            <IconButton
                                previous
                                page={pageNumber - 1}
                                onPageChange={onPageChange}
                            />
                        </React.Fragment>
                    ) : null}
                    {renderInnerPager()}
                    {(totalPages > 3 && pageNumber < totalPages && end < totalPages) ? (
                        <React.Fragment>
                            <IconButton
                                page={pageNumber + 1}
                                onPageChange={onPageChange}
                            />
                            <PageButton
                                page={totalPages}
                                onPageChange={onPageChange}
                            />
                        </React.Fragment>
                    ) : null}
                </div>
                {isRemoveAllVisible && <RemoveAll onCloseAll={onCloseAll} />}
            </nav>
        </React.Fragment>
    )
}