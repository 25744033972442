import React from "react";
import { useLoading } from "../../../../common/hooks";
import { ActivationInProgressTemplate } from "themes/pages/membership/email-activation/components";

export default function ActivationInProgress(props) {
	const { isLoading } = useLoading();

	if (!isLoading) {
		return null;
	}

	return <ActivationInProgressTemplate {...props} />;
}
