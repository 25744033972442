import { observable, action, runInAction } from "mobx";

import { MvcHub } from "../../../offer/hubs/cashout";

import { BetSlipService } from "../../../offer/services/components";
import { LoaderStore } from "../../../state/stores/common";

export class CashoutStore {
	constructor(rootStore, myBetsStore) {
		this.rootStore = rootStore;
		this.myBetsStore = myBetsStore;

		this.betSlipService = new BetSlipService();
		this.hub = new MvcHub();

		this.loader = new LoaderStore();
	}

	//#region  observable

	@observable isCashoutModalOpen = false;
	@observable cashoutModalData;
	@observable isCashoutFormSubmitting = false;

	@observable countDownDuration = 0;
	@observable countdownCounter = 0;
	@observable isCountdownVisible = false;

	@observable cashoutHistory = null;
	@observable cashoutHistorySlipId = null;
	@observable isCashoutHistoryOpen = false;

	//#endregion observable

	_timeoutCache = new Map();

	//#region cashout subscription

	@action.bound
	async initializeCashoutSubscription() {
		this.disposeSubscription();
		await this.hub.connect();

		const slips = this.myBetsStore.betsList
			.filter((b) => b.betSlipStatus.abrv === "submitted")
			.map((b) => ({
				betSlipId: b.id,
				betSlipNumericalId: b.betSlipNumber,
			}));

		if (slips.length === 0) {
			return;
		}

		const subscriptionFilter = {
			subscriptionId: `${
				App.utils.getUserFromLocalStorage().id
			}-my-bets-cashout`,
			environment: EnvironmentKey,
			language: App.utils.getCurrentLanguage(),
			token: App.utils.getUserToken().token,
			shopId: App.utils.getShopId(),
			agency: App.utils.getAgencyKey(),
			isAgencyUser: true,
			monitoredBetSlips: slips,
		};

		this.subscription = this.hub.cashoutHub
			.getSubscription(subscriptionFilter)
			.subscribe((response) => {
				// TODO: maybe add buffer
				runInAction(() => {
					this.myBetsStore.betsList.forEach((betSlip) => {
						const update = response.value.find(
							(u) => u.id === betSlip.id
						);

						if (update) {
							this.updateSlipCashout(betSlip, update);
						}
					});
				});
			});
	}

	/**
	 * Updates betSlip cashout related properties is cashout is available.
	 */
	@action.bound
	updateSlipCashout(betSlip, update) {
		this.removeBetSlipIdFromTimeOutCache(betSlip);
		this.updateBetSlipIndicator(betSlip, update);

		betSlip.displayedAmount = !update.isPaidOut
			? update.value.toFixed(2)
			: null;
		betSlip.cType = update.cType;
		betSlip.calculatedCashoutAmount = update.value;
	}

	@action.bound
	removeBetSlipIdFromTimeOutCache(betSlip) {
		if (this._timeoutCache.has(betSlip.id)) {
			clearTimeout(this._timeoutCache.get(betSlip.id));
			this._timeoutCache.delete(betSlip.id);
		}
	}

	@action.bound
	updateBetSlipIndicator(betSlip, update) {
		const currAmount = Number(betSlip.displayedAmount);

		// initial value for currAmount will be 0 so we must skip adding indicators
		if (currAmount <= 0 || update.value === currAmount) {
			betSlip.indicator = 0;
			return;
		}

		if (update.value > currAmount) {
			betSlip.indicator = 1;
		} else if (update.value < currAmount) {
			betSlip.indicator = -1;
		}

		const tId = setTimeout(() => {
			this._timeoutCache.delete(betSlip.id);
			runInAction(() => {
				betSlip.indicator = 0;
			});
		}, 3500);

		this._timeoutCache.set(betSlip.id, tId);
	}

	//#endregion cashout subscription

	//#region actions

	@action.bound
	async onInitialize() {
		try {
			const countDownDurationResponse = await this.betSlipService.delay();
			const countDownDuration = parseInt(countDownDurationResponse.value);
			runInAction(() => {
				this.countDownDuration = countDownDuration;
			});
		} catch (error) {
			console.error(error);
		}
	}

	@action.bound
	async cashout({
		id,
		calculatedCashoutAmount,
		cType,
		isChangeAccepted,
		isLive,
	}) {
		this.isCashoutFormSubmitting = true;

		if (isLive) {
			this.startCountdown();
		}

		try {
			this.closeCashoutModal();
			const response = await this.betSlipService.cashout(
				id,
				calculatedCashoutAmount,
				cType,
				isChangeAccepted,
				isLive
			);
			const betSlip = this.myBetsStore.betsList.find(
				(betSlip) => betSlip.id === id
			);
			this.updateSlipCashout(betSlip, { isPaidOut: true });
			if (response?.betSlipProcessingResponse?.errorCode == 0) {
				toastr.success(
					response.betSlipProcessingResponse.errorMessage,
					null,
					{ preventDuplicates: false }
				);
			} else {
				var gainValue =
					response.betSlip.payout ?? response.betSlip.gain;
				toastr.success(
					App.state.localizationService.t(
						"MY_BETS.CASHOUT.SUCCESS_MESSAGE",
						{
							amount: gainValue.toFixed(2),
							currency:
								App.state.rootStore.currencyStore.currency,
						}
					),
					null,
					{ preventDuplicates: false }
				);
				App.state.rootStore.userAuthStore.resolveBalance(
					gainValue,
					response.betSlip.bettingAccountTypeId
				);
			}
		} catch (err) {
			console.log(err);
			var errorMessage = App.state.localizationService.t(
				"MY_BETS.CASHOUT.FAILURE_MESSAGE"
			);
			if (err.data == null) {
				toastr.error(errorMessage);
			} else {
				var error = err.data;
				if (error.betSlipProcessingResponse != null) {
					errorMessage = error.betSlipProcessingResponse.errorMessage;
				} else if (error.validationResponse != null) {
					errorMessage = error.validationResponse.errorMessage;
				}
				errorMessage = errorMessage.replaceAll(
					"{currency}",
					App.state.rootStore.currencyStore.currency
				);
				const errorCode = error.betSlipProcessingResponse.errorCode;
				if (errorCode === 400082) {
					toastr.error(error.betSlipProcessingResponse.errorMessage);
				} else if (errorCode === 400083) {
					const id = error.betSlip.id;
					const calculatedCashoutAmount =
						error.betSlip.calculatedCashoutAmount;
					const displayedAmount =
						error.betSlip.calculatedCashoutAmount.toFixed(2);
					const isLive = error.betSlip.isLive;
					const message = errorMessage;
					this.openCashoutModal({
						id,
						calculatedCashoutAmount,
						displayedAmount,
						cType,
						isLive,
						message,
					});
				} else {
					toastr.error(
						App.state.localizationService.t(
							"MY_BETS.CASHOUT.FAILURE_MESSAGE"
						)
					);
				}
			}
		} finally {
			// if (isLive) {
			//     App.utils.loader.resume();
			// }
			this.stopCountDown();
			await this.refresh();

			runInAction(() => {
				this.isCashoutFormSubmitting = false;
			});
		}
	}

	@action.bound
	async refresh() {
		clearTimeout(this.refreshDebounceId);
		this.refreshDebounceId = setTimeout(async () => {
			this.myBetsStore.refresh();
		}, 500);
	}

	@action.bound
	stopCountDown() {
		this.countdownCounter = 0;
		clearInterval(this.countdownInterval);
		this.isCountdownVisible = false;
	}

	@action.bound
	startCountdown() {
		this.countdownCounter = this.countDownDuration;
		if (this.countdownCounter !== 0) {
			this.isCountdownVisible = true;
			this.countdownInterval = setInterval(async () => {
				--this.countdownCounter;
				if (this.countdownCounter <= 0) {
					clearInterval(this.countdownInterval);
					this.isCountdownVisible = false;
				}
			}, 1000);
		} else {
			this.isCountdownVisible = false;
		}
	}

	/**
	 * Closes cashout modal
	 */
	@action.bound
	closeCashoutModal() {
		this.isCashoutModalOpen = false;
		this.cashoutModalData = null;
	}

	/**
	 * Opens cashout modal
	 * @param {{id, amount}} modalData
	 */
	@action.bound
	openCashoutModal(modalData) {
		this.isCashoutModalOpen = true;
		this.cashoutModalData = modalData;
	}

	//#endregion actions

	//#regions cashout history

	@action.bound
	async fetchCashoutHistoryForBetSlip(betSlipId, betSlipNumber) {
		this.isCashoutHistoryOpen = true;
		this.cashoutHistorySlipId = betSlipNumber;

		try {
			const cashOutHistory = await this.betSlipService.getCashoutHistory(
				betSlipId
			);
			runInAction(() => {
				this.cashoutHistory = cashOutHistory;
			});
		} catch (error) {
			console.error(error);
			// close in case of error
			this.closeCashoutHistory();
		}
	}

	@action.bound
	closeCashoutHistory() {
		this.isCashoutHistoryOpen = false;
		this.cashoutHistory = null;
	}

	//#endregion cashout history

	//#region disposers

	@action.bound
	disposeSubscription() {
		if (this.subscription != null) {
			this.subscription.unsubscribe();
			this.subscription = null;
		}
	}

	@action.bound
	onDispose() {
		this.disposeSubscription();
		this.hub.destroy();

		this.isCashoutModalOpen = false;
		this.cashoutModalData = null;
		this.isCashoutFormSubmitting = false;

		this.countDownDuration = 0;
		this.countdownCounter = 0;
		this.isCountdownVisible = false;

		this.cashoutHistory = null;
		this.cashoutHistorySlipId = null;
		this.isCashoutHistoryOpen = false;
	}

	//#endregion disposers
}
