import { Settings } from "luxon";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppActionsContext, AppContext } from "../context/AppContext";

export default function AppContextProvider(props) {

    // culture context default value
    const { i18n } = useTranslation();

    let defaultCulture = i18n.language
    const [culture, _privetSetCulture] = useState(defaultCulture || "en");
    const [isNavMenuOpen, setIsNavMenuOpen] = useState(false);

    const setCulture = (newCulture) => {
        _privetSetCulture(newCulture);
        // someRootStore.setCurrentCulture(newCulture);
    }

    // default local language for date time 
    Settings.defaultLocale = culture;

    const setters = useMemo(() => ({
        setCulture,
    }), []);

    return (
        <AppContext.Provider
            value={{
                culture: culture,
                isNavMenuOpen: isNavMenuOpen,
                setIsNavMenuOpen: setIsNavMenuOpen
            }}
        >
            <AppActionsContext.Provider value={setters}>
                {props.children}
            </AppActionsContext.Provider>
        </AppContext.Provider>
    )
}