import { PersonalDataService as PersonalDataDataService } from "../../../../../administration/services/account-settings/personal-data/PersonalDataService";
import { UserDataService } from "../../../../../administration/services/common";
import {
	CountriesLookupService,
	LanguagesLookupService,
	TitlesLookupService,
} from "../../../../../administration/services/account-settings/personal-data/lookups";
import { isNullOrWhitespace } from "../../../../../utils/common/helpers";
import { filteredLanguageList } from "../utils";
import {
	Response,
	ErrorResponse,
} from "../../../../../administration/services/common";
import { SUPPORTED_LANGUAGES } from "../../../../../utils";

export class PersonalDataService {
	constructor() {
		this.personalDataService = new PersonalDataDataService();
		this.userDataService = new UserDataService();
	}

	async getPersonalData() {
		try {
			const getIsoFromSupportedLanguages = SUPPORTED_LANGUAGES.map(
				(lang) => {
					return lang.iso;
				}
			).join(",");
			const countries = await CountriesLookupService.findLookup();
			const languages = await LanguagesLookupService.findLookup(
				getIsoFromSupportedLanguages
			);
			const titles = await TitlesLookupService.findLookup();
			const userData = await this.userDataService.getUserInfo();
			const supportedLanguageList = filteredLanguageList(
				SUPPORTED_LANGUAGES,
				languages.item
			);
			return new Response({
				data: {
					userName: userData.userName,
					firstName: userData.firstName,
					lastName: userData.lastName,
					dob: userData.dateOfBirth,
					birthPlace: userData.birthPlace,
					titleId: userData.titleId,
					countryId: userData.countryId,
					city: userData.city,
					street: userData.street,
					zipCode: userData.zipCode,
					houseNumber: userData.houseNumber,
					currentEmail: userData.email,
					mobilePhone: userData.mobilePhone,
					telephoneNumber: userData.telephoneNumber,
					language: userData.cultureInfoName,
					payPin: userData.payPin,
					receiveNewsletter: userData.isReceivingPromoMailEnabled,
				},
				countries: countries.item,
				languages: supportedLanguageList,
				userTitles: titles.item,
			});
		} catch (error) {
			console.error(error);
			if (error.message == "Unauthorized") {
				return new ErrorResponse();
			}
			throw new ErrorResponse({ message: "GENERIC_ERROR" });
		}
	}

	async updateUserPin(payPin) {
		if (isNullOrWhitespace(payPin)) {
			throw new ErrorResponse({ message: "GENERIC_ERROR" });
		}
		try {
			await this.personalDataService.updateUserPin(payPin);
			return new Response();
		} catch (error) {
			if (error.message == "Unauthorized") {
				return new ErrorResponse();
			}
			throw new ErrorResponse({ message: "PERSONAL_DATA_ERR" });
		}
	}

	async updatePersonalData(model) {
		if (model == null || model.data == null) {
			throw new ErrorResponse({ message: "GENERIC_ERROR" });
		}

		let gpUser = {};

		try {
			gpUser = await this.userDataService.getUserInfo();

			gpUser.Street = model.data.street;
			gpUser.dob = model.data.dob;
			gpUser.FirstName = model.data.firstName;
			gpUser.LastName = model.data.lastName;
			gpUser.TelephoneNumber = model.data.telephoneNumber;
			gpUser.MobilePhone = model.data.mobilePhone;
			gpUser.HouseNumber = model.data.houseNumber;
			gpUser.City = model.data.city;
			gpUser.CountryId = model.data.countryId;
			gpUser.ZipCode = model.data.zipCode;
			gpUser.Language = model.data.language;
			gpUser.Password = model.password;
		} catch (error) {
			if (error.message == "Unauthorized") {
				return new ErrorResponse();
			}
			throw new ErrorResponse({ message: "GENERIC_ERROR" });
		}

		if (
			model.data?.receiveNewsletter != gpUser?.isReceivingPromoMailEnabled
		) {
			try {
				await this.userDataService.promoMaterialsApproval(
					model.data.receiveNewsletter
				);
			} catch (error) {
				console.error(error);
			}
		}

		try {
			await this.personalDataService.updatePersonalData(gpUser);
			return new Response(model);
		} catch (error) {
			if (error.message == "Unauthorized") {
				return new ErrorResponse();
			} else if (error.data.errorCode == 400175) {
				await App.state.rootStore.userAuthStore.logoutUser();
				App.state.history.push(
					`/${App.utils.getCurrentCulture()}/auth/login?isSessionTerminated=true`
				);
				throw new ErrorResponse();
			} else if (error.data.errorCode == 400176) {
				throw new ErrorResponse({ message: "INVALID_PASSWORD" });
			}
			throw new ErrorResponse({ message: "GENERIC_ERROR" });
		}
	}

	getNewPayPin() {
		return Math.floor(1000 + Math.random() * 9000);
	}
}
