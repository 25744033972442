import { EmailChangeService as EmailChangeRequestService } from "../../../../../administration/services/account-settings/email-change";
import { UserDataService } from "../../../../../administration/services/common";
import {
	Response,
	ErrorResponse,
} from "../../../../../administration/services/common";
import { isNullOrWhitespace } from "../../../../../utils/common/helpers";

export class EmailChangeService {
	constructor() {
		this.emailChangeRequestService = new EmailChangeRequestService();
		this.userDataService = new UserDataService();
	}

	async getUser(mailKey = null) {
		const viewModel = {};
		const { username, email, isOfflineUser, isOfflineUserMailApproved } =
			App.utils.getUserFromLocalStorage();
		if (isNullOrWhitespace(mailKey)) {
			if (
				(isOfflineUser && !isOfflineUserMailApproved) ||
				!App.utils.isUserAuthenticated()
			) {
				viewModel.CurrentEmail = "----";
			} else {
				viewModel.CurrentEmail = email;
			}
		} else {
			try {
				const newEmail =
					await this.emailChangeRequestService.activateEmail({
						EmailChangeToken: mailKey,
						UserName: username,
					});
				if (!isNullOrWhitespace(newEmail)) {
					App.state.rootStore.userAuthStore.resolveUserEmail(
						newEmail
					);
					viewModel.CurrentEmail = newEmail;
				} else {
					throw new ErrorResponse({
						message: "EMAIL_ACTIVATE_ERROR",
					});
				}
			} catch (error) {
				if (error.message == "Unauthorized") {
					return new ErrorResponse();
				}

				let errorMessage = "EMAIL_ACTIVATE_ERROR";

				if (error.data.errorCode == 4000022004) {
					errorMessage = "ACCOUNT_ALREADY_ACTIVATED";
				} else if (error.data.errorCode == 4040020000) {
					errorMessage = "CONFIRMATION_LINK_INVALID";
				}

				throw new ErrorResponse({
					data: { CurrentEmail: email },
					message: errorMessage,
				});
			}
		}
		return new Response(viewModel);
	}

	async checkIsEmailAvailable(emailToCheck) {
		try {
			const response =
				await this.emailChangeRequestService.checkIsEmailAvailable(
					emailToCheck
				);
			return new Response(response);
		} catch (error) {
			if (error.message == "Unauthorized") {
				return new ErrorResponse();
			}

			console.error(error);
			return new ErrorResponse(false);
		}
	}

	async changeUserEmail(model) {
		const userId = App.state.rootStore.userAuthStore.user.id;

		if (
			model == null ||
			isNullOrWhitespace(model.Password) ||
			isNullOrWhitespace(model.NewEmail)
		) {
			throw new ErrorResponse({ message: "GENERIC_ERROR" });
		}

		try {
			const emailChangeModel = {
				EmailChangeUrl: `${
					location.origin
				}/${App.utils.getCurrentCulture()}/account-settings/email-change?mailkey={mailkey}&username={username}`,
				NewEmailAddress: model.NewEmail,
				Password: model.Password,
				UserId: userId,
				agencyId: App.utils.getAgencyId(),
			};

			await this.emailChangeRequestService.changeUserEmail(
				emailChangeModel
			);
			return new Response(model.NewEmail);
		} catch (error) {
			if (error.message == "Unauthorized") {
				return new ErrorResponse();
			} else if (error.data.errorCode == 400176) {
				throw new ErrorResponse({ message: "INVALID_PASSWORD" });
			} else if (error.data.errorCode == 400175) {
				await App.state.rootStore.userAuthStore.logoutUser();
				App.state.history.push(
					`/${App.utils.getCurrentCulture()}/auth/login?isSessionTerminated=true`
				);
				throw new ErrorResponse();
			}
			throw new ErrorResponse({ message: "GENERIC_ERROR" });
		}
	}
}
