import React, { useContext } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { NavLink, Switch, Route, Redirect } from "react-router-dom";

import { MyBetsStoreContext } from "../../../../common/context";

import ClosedBetsFilterForm from "./tabs/ClosedBetsFilterForm";
import { OpenBetsFilterForm } from "./tabs";
import Body from "./Body";

import { Modals } from "./modals";

export default observer(function MyBetsPageTemplate(props) {
  const { myBetsStore } = props;

  const { isStoreInitialized } = myBetsStore;

  if (!isStoreInitialized) {
    return null;
  }

  return (
    <MyBetsStoreContext.Provider value={myBetsStore}>
      <Modals />
      <LoaderSpinner />
      <main role="main" className="l--full">
        <div className="container container--main">
          <div className="row">
            <div className="l--full__content">
              <div id="accSettingsForm" className="view--med">
                <div id="betsDiv">
                  <MyBetsPageTemplate_Title />
                  <MyBetsPageTemplate_Navigation />
                  <MyBetsTabs />
                  <Body />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </MyBetsStoreContext.Provider>
  );
});

function MyBetsPageTemplate_Title(props) {
  const { t } = useTranslation();

  return (
    <div className="title--primary no-print">
      {t("MY_BETS.PAGE.MYBETS_HEAD")}
    </div>
  );
}

function MyBetsPageTemplate_Navigation(props) {
  const { t } = useTranslation();

  return (
    <nav className="tabs--primary no-print">
      <NavLink
        to={`/${App.utils.getCurrentCulture()}/my-bets/open`}
        activeClassName="is-active"
        className="tabs--primary__item"
      >
        <span className="tabs--primary__label">
          {t("MY_BETS.PAGE.OPEN_BETS")}
        </span>
      </NavLink>
      <NavLink
        to={`/${App.utils.getCurrentCulture()}/my-bets/closed`}
        activeClassName="is-active"
        className="tabs--primary__item"
      >
        <span className="tabs--primary__label">
          {t("MY_BETS.PAGE.CLOSED_BETS")}
        </span>
      </NavLink>
    </nav>
  );
}

function MyBetsTabs(props) {
  return (
    <Switch>
      <Route exact path={["/:culture/my-bets/open"]}>
        <OpenBetsFilterForm />
      </Route>

      <Route exact path={["/:culture/my-bets/closed"]}>
        <ClosedBetsFilterForm />
      </Route>

      <Redirect to={`/${App.utils.getCurrentCulture()}/my-bets/open`} />
    </Switch>
  );
}

const LoaderSpinner = observer(function LoaderSpinner(props) {
  const { isLoading } = useContext(MyBetsStoreContext);
  const {t} = useTranslation()

  if (!isLoading) {
    return null;
  }

  return (
    <div className="loader">
      <div className="loader__card">
        <div className="loader__card__title">{t("HOME.LOADING")}...</div>
        <span />
      </div>
    </div>
  );
});
