import React, { useEffect } from 'react';
import { observer } from "mobx-react";
import moment from 'moment';
import ReactTooltip from "react-tooltip";
import { LiveMatchTimeTooltip } from '../../../../../../common/components/offer';

export const LiveMatchTimeTemplate = observer((props) => {
	useEffect(() => {
		ReactTooltip.rebuild();
	});

	const tooltipId = `match-time-${props.event.id}`;

	if (props.event.matchTime != undefined) {
		return (
			<td
				className={props.className}
			>
				<div data-for={tooltipId} data-tip className="offer__time__inner">{props.event.matchTime.displayTime}</div>
				<LiveMatchTimeTooltip event={props.event} tooltipId={tooltipId} />
			</td>
		)
	}

	return (
		<td
			className={props.className}
		>
			<div data-for={tooltipId} data-tip className="offer__time__inner">{moment(props.event.startTime).format('HH:mm')}</div>
			<LiveMatchTimeTooltip event={props.event} tooltipId={tooltipId} />
		</td>
	)
});