import {
	ErrorResponse,
	UserDataService,
	Response,
} from "../../../../../administration/services/common";

export class ForcePasswordChangeService {
	constructor() {
		this.userDataService = new UserDataService();
	}

	async getForcePasswordChangeData(forcePasswordChangeToken, agencyKey) {
		if (forcePasswordChangeToken == null) {
			return;
		}
		if (forcePasswordChangeToken != null) {
			const userInfo =
				await this.userDataService.getForcePasswordChangeCardUserInfoAsync();
			const companyInfo =
				await this.userDataService.getLimitedCompanySettings();
			const model = {
				userInfo: userInfo,
				companyInfo: companyInfo,
			};
			return model;
		}
	}

	async createForcePasswordChange(forcePasswordChangeModel) {
		try {
			const response =
				await this.userDataService.setForcePasswordChangeForOfflineUserAsync(
					forcePasswordChangeModel
				);
			return new Response(response.primary);
		} catch (error) {
			let errorMessage = null;
			let userData = null;
			if (error?.data != null) {
				//todo fix this error mapping
				if (error?.data.errorCode == 400213) {
					userData = error?.data;
				}

				switch (error?.data?.error?.errorCode) {
					case 400170:
						errorMessage = "ERROR_PWD_CHANGE_CURRENT_SAME";
						break;

					case 400171:
						errorMessage = "ERROR_PWD_CHANGE_OLD_SAME";
						break;

					case 400165:
						errorMessage = "ERROR_PWD_CHANGE_SIMILAR";
						break;

					case 400158:
						errorMessage =
							"PASSWORD_RECOVERY_TOKEN_INVALID_OR_EXPIRED";
						break;

					default:
						errorMessage = "GENERIC_ERROR";
						break;
				}
			}
			throw new ErrorResponse({
				message: errorMessage,
				errorCode: error,
				userData: userData,
			});
		}
	}
}
