const httpClient = App.utils.httpClient;

const requestHeaders = {
    'Content-Type': 'application/json',
};

const baseUrl = `${BasePrintOfferAddress}print`;

class OfferPrintService {
    constructor(){}

    async getOfferPrintData() {
        try {
            const response = await httpClient.get(`${baseUrl}/list-available-files?language=${App.utils.getCurrentCulture()}&shopId=${ShopId}&rpp=100`, requestHeaders, { responseType: 'json' });
            return response
        }
        catch(e) {
            return [];
        }
    }
}

const offerPrintService = new OfferPrintService();
export {
    offerPrintService
}