import React from 'react';
import { observer, inject } from 'mobx-react';
import { useLocation, withRouter } from 'react-router-dom';
import { SportsTemplate } from 'themes/pages/sports';

@inject(stores => ({
    sportsViewStore: stores.sportsViewStore,
    timezone: stores.stateStore.timezonesStore.selectedTimezone,
    betSlipStore: stores.offerStore.betSlipStore
}))
@withRouter
@observer
class Sports extends React.Component {

    constructor(props) {
        super(props);

        document.querySelector('html, body').scrollTo(0, 0);
    }

    async componentDidMount() {

        const currParams = this.props.match.params;
        this.props.sportsViewStore.setPeriod(currParams.period);

        if (currParams.segments) {
            const parsedSegments = App.state.RouteSportSegments.parse(currParams.segments);

            this.props.sportsViewStore.setSelectedSports(parsedSegments.sports);
            this.props.sportsViewStore.setSelectedCategories(parsedSegments.categories);
            this.props.sportsViewStore.setSelectedTournaments(parsedSegments.tournaments);
            this.props.sportsViewStore.setSelectedSpecials(parsedSegments.specials);
        }

        if (currParams.topTournamentId) {

            if (!this.props.sportsViewStore.rootStore.promoOfferViewStore.isInitialized) {
                await this.props.sportsViewStore.rootStore.promoOfferViewStore.onInitialize();
            }

            const topTournamentIds = this.props.sportsViewStore.rootStore.promoOfferViewStore.getTopTournamentIds(currParams.topTournamentId);
            this.props.sportsViewStore.setSelectedTournaments(topTournamentIds);
        }

        const urlParams = new URLSearchParams(this.props.location.search);

        if (urlParams.has('event')) {
            this.props.sportsViewStore.additionalOfferStore.setAdditionalOfferEvent(urlParams.get('event'));
        }

        // Reset selectors on the first load
        this.props.sportsViewStore.configuration.bettingTypeSelectorsStore.reset();
        await this.props.sportsViewStore.initializeStore(urlParams.get('page'));
    }

    componentWillUnmount() {
        this.props.sportsViewStore.onDispose();
    }

    async componentDidUpdate(prevProps) {
        let shouldUpdate = false;
        const currParams = this.props.match.params;
        const prevParams = prevProps.match.params;

        if (currParams.period !== prevParams.period) {
            this.props.sportsViewStore.setPeriod(currParams.period);
            shouldUpdate = true;
        }

        const urlParams = new URLSearchParams(this.props.location.search);
        const prevUrlParams = new URLSearchParams(prevProps.location.search);

        if (urlParams.has('event') && !this.props.sportsViewStore.rootStore.isAdditionalOfferVisible) {
            this.props.sportsViewStore.additionalOfferStore.setAdditionalOfferEvent(urlParams.get('event'));
            shouldUpdate = true;
        }
        else if (prevUrlParams.has('event') && !urlParams.has('event')) {
            this.props.sportsViewStore.additionalOfferStore.setAdditionalOfferEvent(null);
        }

        if (prevParams.segments !== currParams.segments || currParams.period !== prevParams.period) {
            const parsedSegments = App.state.RouteSportSegments.parse(currParams.segments);

            this.props.sportsViewStore.setSelectedSports(parsedSegments.sports);
            this.props.sportsViewStore.setSelectedCategories(parsedSegments.categories);
            this.props.sportsViewStore.setSelectedTournaments(parsedSegments.tournaments);
            this.props.sportsViewStore.setSelectedSpecials(parsedSegments.specials);
            this.props.sportsViewStore.changePage(1);
            shouldUpdate = true;
        }

        if (prevParams.topTournamentId !== currParams.topTournamentId) {
            if (currParams.topTournamentId) {
                const topTournamentIds = this.props.sportsViewStore.rootStore.promoOfferViewStore.getTopTournamentIds(currParams.topTournamentId);
                this.props.sportsViewStore.setSelectedTournaments(topTournamentIds);
            }
        }

        if (shouldUpdate) {
            await this.props.sportsViewStore.initializeStore();
        }
    }

    render() {
        return (
            <SportsTemplate {...this.props} />
        );
    }
}

export default Sports;