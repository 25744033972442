import { DateTime } from 'luxon';
import { Response, ErrorResponse } from '../../../../administration/services/common';
import { isNullOrWhitespace } from "../../../../utils/common/helpers";
import { AccountStatementTypesLookupService } from '../../../../administration/services/account-statement/lookups';
import { AccountStatementService as AccountStatementDataService } from '../../../../administration/services/account-statement';
import { UserDataService } from '../../../../administration/services/common';

const typesWithoutDetails = [
    "deposit-casino-200",
    "withdrawal-virtual-game",
    "withdrawal-live-casino2-211",
    "deposit-casino",
    "deposit-live-casino2-210",
    "deposit-casino-2-206",
    "withdrawal-live-casino",
    "withdrawal-live-casino2",
    "deposit-virtual-game",
    "withdrawal-virtual-game-205",
    "bonus",
    "deposit-live-casino-202",
    "withdrawal-casino-201",
    "withdrawal-live-casino-203",
    "deposit-virtual-game-204",
    "withdrawal-casino-2-207",
    "deposit-live-casino",
    "withdrawal-casino",
    "cash-payment",
    "cash-payout",
    "withdrawal-paylado",
    "deposit-paylado",
    "deposit-cash-to-digital-paylado"
];

let instance;

class AccountStatementService {

    constructor() {
        if (instance) {
            return instance;
        }
        instance = this;
        this.accountStatementService = new AccountStatementDataService();
        this.userDataService = new UserDataService();
    }

    async getFilterData() {
        try {
            const periods = [
                { name: 'ACC_STATEMENT.FILTER.TODAY', id: 'today' },
                { name: 'ACC_STATEMENT.FILTER.LAST_3_DAYS', id: 'last3days' },
                { name: 'ACC_STATEMENT.FILTER.LAST_7_DAYS', id: 'last7days' },
                { name: 'ACC_STATEMENT.FILTER.LAST_14_DAYS', id: 'last14days' },
                { name: 'ACC_STATEMENT.FILTER.LAST_30_DAYS', id: 'last30days' },
                { name: 'ACC_STATEMENT.FILTER.LAST_60_DAYS', id: 'last60days' },
                { name: 'ACC_STATEMENT.FILTER.CUSTOM', id: 'custom' }
            ];

            const transactionTypes = await AccountStatementTypesLookupService.findLookup();

            //Currently, we always show Online/offline radio input in Filter, so this is set to true
            let showIsOnlineFilter = true;

            // const userAccounts = await this.userDataService.fetchUserAccounts();

            // if (userAccounts != null && userAccounts.length > 1) {
            //     showIsOnlineFilter = true;
            // }

            return new Response({
                periodLookup: periods,
                transactionLookup: transactionTypes,
                showIsOnline: showIsOnlineFilter
            });
        }
        catch (error) {
            console.error(error);
            throw new ErrorResponse({ message: 'ERROR_OCCURED' });
        }
    }

    async fetchTransactionsTableData(model, page = 1) {

        const isTransactionTypeValid = (transactionTypeId) => {
            return !isNullOrWhitespace(transactionTypeId) && transactionTypeId !== 'all'
        }

        try {
            const filterParametars = {
                page: page,
                dates: model.asPeriod == 'custom' ? null : this.fromToDates(model.asPeriod),
                rpp: 10,
                sort: 'dateCreated|desc',
                embed: 'transactionType,currency',
                to: DateTime.fromISO(model.endDate).endOf('day').toString(),
                from: model.startDate,
                transactionTypesIds: isTransactionTypeValid(model.asType) ? model.asType : '',
                isOnline: model.isOnline
            }

            const filter = this.setupUserTransactionFilter(filterParametars);

            const transactionQuery = this.createQuery(filter);

            const result = await this.accountStatementService.fetchStatementTransactions(transactionQuery);

            return new Response({
                transactions: this.filterAccountStatementDetails(result.item),
                totalRecords: result.totalRecords,
                page: result.page,
                recordsPerPage: result.recordsPerPage
            });
        }
        catch (error) {
            console.error(error);
            throw new ErrorResponse({ message: 'ERROR_OCCURED' });
        }
    }

    async getBettingAndPaymentTableData(model) {
        try {
            const reportFilter = {
                Filter: {
                    AccessChannels: {
                        Agencies: null,
                        Shops: [
                            {
                                AgencyId: App.utils.getAgencyId(),
                                Id: App.utils.getShopId()
                            }
                        ]
                    },
                    Parameters: [
                        {
                            Column: 'StartDate',
                            Value: "0"
                        }
                    ]
                },
                Paging: null
            }

            if (model.asPeriod == 'custom') {
                const now = DateTime.now();
                const startDate = DateTime.fromISO(model.startDate);
                const endDate = DateTime.fromISO(model.endDate);

                reportFilter.Filter.Parameters = [
                    {
                        Column: "StartDate", Value: (Math.floor(now.diff(startDate, ["days"]).toObject().days))
                    },
                    {
                        Column: "EndDate", Value: (Math.floor(now.diff(endDate, ["days"]).toObject().days))
                    }
                ]
            }
            else {

                const period = this.fromToDates(model.asPeriod);
                const date1 = DateTime.fromISO(period.From);
                const date2 = DateTime.fromISO(period.To);
                const diffInDays = Math.floor(date2.diff(date1, ["days"]).toObject().days);

                reportFilter.Filter.Parameters = [
                    {
                        Column: 'StartDate', Value: diffInDays
                    }
                ]
            }

            const userActivityResult = await this.accountStatementService.fetchUserAccountActivity(reportFilter);

            const bettingActivityResult = await this.accountStatementService.fetchUserBettingActivity(reportFilter);


            let userActivity = null;
            let bettingActivity = null;

            if (userActivityResult.result != null && userActivityResult.result.length > 0) {
                const activity = userActivityResult.result[0];
                if (activity.NumberOfTransactions > 0) {
                    userActivity = activity;
                }
            }

            if (bettingActivityResult.result != null && bettingActivityResult.result.length > 0) {
                const activity = bettingActivityResult.result[0];
                if (activity.NumberOfBets > 0) {
                    bettingActivity = activity;
                }
            }

            return new Response({
                userActivity: userActivity,
                bettingActivity: bettingActivity,
            });
        }
        catch (error) {
            console.error(error);
            throw new ErrorResponse({ message: 'ERROR_OCCURED' });
        }
    }

    async getSlipDetails(betSlipId) {
        try {
            const slip = await this.accountStatementService.getSlipDetails(betSlipId);

            const initialEventIds = this.filterEventIds(slip.betSlipOffers);

            const eventIds = await this.filterEventIdsWithOffer(initialEventIds);

            slip.betSlipOffers.forEach(offer =>
                offer.isEventInOffer = eventIds.includes(offer.eventId)
            )

            return new Response(slip);
        }

        catch (error) {
            console.error(error);
            throw new ErrorResponse();
        }
    }

    async getTransactionDetails(numericalId) {
        try {
            const transaction = await this.accountStatementService.getTransactionDetails(numericalId);

            /*dummy data*/
            // const transaction = {
            //     amount: 1000,
            //     displayedAmount: "1000",
            //     ccExpiryDate: "2022-08-30T09:09:53.871831Z",
            //     ccMaskedPan: "1234",
            //     currency: {
            //         displayCode: "€",
            //         ISO: "1234-555"
            //     },
            //     endDate: "2022-08-30T09:09:53.871831Z",
            //     numericalId: "0001010-222",
            //     paymentMethod: {
            //         abrv: "Card",
            //         ccExpiryDate: "2022-08-30T09:09:53.871831Z",
            //         ccMaskedPan: "1234",
            //         depositMode: 1,
            //         description: "description",
            //         displayName: "displayName",
            //         id: "123414124124123",
            //         isInitial: false,
            //         maxAmount: 30000,
            //         minAmount: 100,
            //         name: "name",
            //         paymentProccessingMode: 1
            //     },
            //     startDate: "2022-08-30T09:09:53.871831Z",
            //     transactionStatus: {
            //         displayName: "In progress",
            //         abrv: 'in-progress',
            //         languageMetadata: 'metadata'
            //     }
            // }

            return new Response(transaction);
        }
        catch (error) {
            console.error(error);
            throw new ErrorResponse();
        }
    }

    /**
     * Filters event ids and returns only those that have offer
     * @param {array} eventIds list of event ids
     * @returns {Promise} array of event Ids that are in offer
     */
    async filterEventIdsWithOffer(eventIds) {

        try {
            const response = await this.accountStatementService.findOffer(eventIds);

            const eventIdsInOffer = response.offerChanges?.updates?.map(eventObj => eventObj.eventId) || [];

            return eventIdsInOffer;
        }
        catch (err) {
            console.error(err);
        }
        // default in case of exception
        return [];
    }

    filterEventIds(offers) {

        const eventIds = offers.map(eventObj => eventObj.eventId) || [];

        return {
            channels: [
                {
                    name: "event",
                    filter: [
                        {
                            startTime: {
                                gt: DateTime.local().plus({ days: -1 }).toUTC().toString()
                            },
                            id: {
                                eq: eventIds
                            }
                        }
                    ]
                }
            ]
        }

    }


    filterAccountStatementDetails(items) {
        return items.map(item => {
            item.hasDetails = !typesWithoutDetails.includes(item.transactionType.abrv);
            return item;
        });
    }

    setupUserTransactionFilter(filterParams) {
        const filter = {
            Embed: filterParams.embed,
            Sort: filterParams.sort,
            Page: filterParams.page,
            Rpp: filterParams.rpp,
        };

        if (filterParams.dates != null) {
            filter.From = filterParams.dates.From;
            filter.To = filterParams.dates.To;
        }
        else {
            if (filterParams.to != null) {
                filter.To = filterParams.to;
            }
            if (filterParams.from != null) {
                filter.From = filterParams.from;
            }
        }

        if (!isNullOrWhitespace(filterParams.transactionTypesIds)) {
            filter.TransactionTypeIds = filterParams.transactionTypesIds;
        }

        filter.IsOnline = filterParams.isOnline;

        return filter;
    }

    fromToDates(period) {
        const now = DateTime.now();
        const To = now;
        let From;

        switch (period) {
            case 'today':
                From = now.startOf('day');
                break;
            case 'last3days':
                From = now.startOf('day').minus({ days: 3 });
                break;
            case 'last7days':
                From = now.startOf('day').minus({ days: 7 });
                break;
            case 'last14days':
                From = now.startOf('day').minus({ days: 14 });
                break;
            case 'last30days':
                From = now.startOf('day').minus({ days: 30 });
                break;
            case 'last60days':
                From = now.startOf('day').minus({ days: 60 });
                break;
        }

        return {
            From: From.toJSDate().toISOString(),
            To: To.toJSDate().toISOString()
        };

    }

    createQuery(filter) {

        const queryParams = {
            embed: filter.Embed,
            page: filter.Page,
            rpp: filter.Rpp,
            translate: true
        };

        if (filter.From != null) {
            queryParams.From = filter.From;
        }

        if (filter.To != null) {
            queryParams.To = filter.To;
        }

        if (filter.TransactionTypeIds != null && !isNullOrWhitespace(filter.TransactionTypeIds)) {
            queryParams.TransactionTypeIds = filter.TransactionTypeIds;
        }

        if (filter.IsOnline) {
            queryParams.IsOnline = filter.IsOnline;
        }

        const params = new URLSearchParams(queryParams);

        const query = '?' + params.toString();

        return query;
    }
}


export const accountStatementServiceInstance = new AccountStatementService();