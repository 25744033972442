import React from 'react';
import { observer, inject } from 'mobx-react';
// import { MiniBetSlipTemplate } from 'themes/common/components/bet-slip';
import { withRouter } from 'react-router-dom';
import { BetSlipBetType } from "@gp/gp-javascript-utils";

@inject(stores => ({
    betSlipStore: stores.offerStore.betSlipStore,
    user: stores.stateStore.userAuthStore.user
}))
@withRouter
@observer
class MiniBetSlip extends React.Component {
    async componentDidMount() {
        if (!this.props.betSlipStore.betSlipState.isOneClickBetActive && localStorage.getItem('ocb') === 'true') {
            this.props.betSlipStore.betSlipState.onBetSlipBetTypeChange(BetSlipBetType.OneClickBet);
        }

        this.props.betSlipStore.hideConfirmationModal();

        await this.props.betSlipStore.slipLoad();
    }

    componentWillUnmount() {
        this.props.betSlipStore.onDispose();
    }

    render() {
        return <></>
        // return <MiniBetSlipTemplate {...this.props} />
    }
}

export default MiniBetSlip;