import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { isNullOrWhitespace } from "../../../../../../../utils/common/helpers";
import { t } from "i18next";

export default function EmailAddressAvailiability(props) {
    const { newEmail, error } = props;

    const availiabilityError = classNames(
        "message--validation",
        {
            "field-validation-error": error !== undefined && error?.type === "isAvailable" && (!isNullOrWhitespace(newEmail) || error?.type !== "pattern"),
            "field-validation-valid": error === undefined && error?.type !== "isAvailable" && (!isNullOrWhitespace(newEmail) || error?.type !== "pattern")
        }
    )

    if (isNullOrWhitespace(newEmail)) {
        return null;
    }
    return (
        <>
            <div className="col-sml-7">

                <EmailAddressAvailable {...props} />

            </div>
            <div className="col-sml-1">
                <span className={availiabilityError}>
                    <span></span>
                </span>
            </div>
        </>
    )

}

function EmailAddressAvailable(props) {
    const { error, newEmail } = props;
    const { t } = useTranslation();

    if(error?.type == "pattern"){
        return   (
            <div className="u-type--style--italic u-type--color--error">
                {t('USER.ACCOUNT_SETTINGS.EMAIL_CHANGE.INVALID_EMAIL')}
            </div>
        )  
    }
    else if(error?.type == "isAvailable"){
        return (
            <div className="u-type--style--italic u-type--color--error">
                {t('USER.ACCOUNT_SETTINGS.EMAIL_CHANGE.EMAIL_ADDRESS')} <strong>{newEmail}</strong> {t('USER.ACCOUNT_SETTINGS.EMAIL_CHANGE.NOT_AVAILABLE')}
            </div>
        )
    }
    else if(error === undefined){
        return (
            <div className="u-type--style--italic u-type--color--success">
                {t('USER.ACCOUNT_SETTINGS.EMAIL_CHANGE.EMAIL_ADDRESS')} <strong>{newEmail}</strong> {t('USER.ACCOUNT_SETTINGS.EMAIL_CHANGE.AVAILABLE')}
            </div>
        )
    }
    else if (error?.type != "isAvailable") {
        return null
    }
}

