import React from "react";
import { FormInputField } from "../../../../../../common/components/inputs";
import { Button } from "../../../../../../common/components/buttons";

export default function PersonalDataPayPinSectionTemplate(props) {
    const {
        getNewPin,
        handleSavePin,
        handleCancelPin,
        isEditing
    } = props;

    return (
        <section className="form__group">
            <FormInputField
                label="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.PAYOUT_CODE_LABEL"
                className="form__field row"
                name="payPin"
                readOnly
            />
            <div className="form__field row">
                <div className="col-sml-11 u-type--right">
                    {isEditing !== 3 ?
                        <Button
                            className="btn btn--sml btn--neutral"
                            btnType="button"
                            onClick={getNewPin}
                            btnText="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.PAYOUT_CODE"
                        />
                        :
                        <div>
                            <Button
                                className="btn btn--sml btn--accent u-mar--right--sml"
                                btnType="button"
                                onClick={handleSavePin}
                                btnText="COMMON.SAVE_BUTTON"
                            />
                            <Button
                                className="btn btn--sml btn--neutral"
                                btnType="button"
                                onClick={handleCancelPin}
                                btnText="COMMON.CANCEL_BUTTON"
                            />
                        </div>
                    }
                </div>
            </div>
        </section>
    )
}
