import React, { useState } from "react";
import { PasswordConfirmation } from "../../../../../common/components/administration";
import { FormProvider } from "react-hook-form";
import { mapDataForPasswordConfirmation } from "../../../../../pages/administration/personal-data/utils";
import { usePasswordConfirmation } from "../../../../../common/hooks";
import { PersonalDataFormSection } from "../../../../../pages/administration/personal-data/components";

export default function PersonalDataTemplate(props) {
	const { showPasswordConfirmation } = usePasswordConfirmation();

	const {
		methods,
		onCancel,
		onSubmitData,
		fetchedData,
		onSuccess,
		updateUserPin,
		getNewPayPin,
	} = props;

	const [isEditing, setIsEditing] = useState(0);

	const {
		getValues,
		reset,
		handleSubmit,
		formState: { dirtyFields },
	} = methods;

	const setActiveSection = (id) => {
		reset();
		setIsEditing(id);
	};

	const onCancelWithActiveSectionReset = () => {
		onCancel();
		setActiveSection(0);
	};

	const onSuccessWithActiveSectionReset = async (password) => {
		const isUpdateSuccess = await onSuccess(password);
		if (isUpdateSuccess) {
			setActiveSection(0);
		}
	};

	return (
		<>
			{!showPasswordConfirmation ? (
				<FormProvider {...methods}>
					<form
						className="form"
						onSubmit={handleSubmit(onSubmitData)}
					>
						<div>
							<PersonalDataFormSection
								fetchedData={fetchedData}
								setActiveSection={setActiveSection}
								setIsEditing={setIsEditing}
								isEditing={isEditing}
								payPin={getValues("payPin")}
								updateUserPin={updateUserPin}
								getNewPayPin={getNewPayPin}
							/>
						</div>
					</form>
				</FormProvider>
			) : (
				<PasswordConfirmation
					data={mapDataForPasswordConfirmation(
						getValues(),
						dirtyFields,
						fetchedData
					)}
					onSuccess={onSuccessWithActiveSectionReset}
					onCancel={onCancelWithActiveSectionReset}
					optionalMessage="COMMON.PASSWORD_CONFIRMATION.ENTER_PASSWORD_CONFIRMATION"
				/>
			)}
		</>
	);
}
