import React, { useContext } from "react";
import { observer } from 'mobx-react';

import { MyBetsStoreContext } from '../../../../../common/context';
import { MyBetsReuseConfirmation } from '../../../../../common/components/my-bets'

export default observer(function ReuseBetConfirmationPopUp(props) {

    const {
        reuseBetSlipStore: {
            isReuseModalOpen,
            reuseModalData,
            reuseBetSlip,
            closeReuseBetSlipModal,
        },
    } = useContext(MyBetsStoreContext);

    if (!isReuseModalOpen) {
        return null;
    }

    return <MyBetsReuseConfirmation isOpen={isReuseModalOpen} {...reuseModalData} onAccept={reuseBetSlip} onClose={closeReuseBetSlipModal} />
})