import React from "react";
import { ForgotUsernameFormSection, ForgotUsernamePopupSuccess } from "../../../../../pages/membership/forgot-username/components";

export default function ForgotUsernameTemplate(props) {

    const {
        isForgotUsernameSuccess,
        setIsForgotUsernameSuccess
    } = props;

    return (
        <>

            <ForgotUsernameFormSection
                isForgotUsernameSuccess={isForgotUsernameSuccess}
                setIsForgotUsernameSuccess={setIsForgotUsernameSuccess}
            />

            <ForgotUsernamePopupSuccess
                isForgotUsernameSuccess={isForgotUsernameSuccess}
            />

        </>
    )
}