import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BettingActivityTemplate } from "themes/pages/membership"
import { ErrorPage } from "../../../common/components/error";
import { useLoading, useNotification, useUser } from "../../../common/hooks";
import { useAppContext } from "../../../common/hooks/useAppContext";

export default observer(function BettingActivity() {

    const bettingActivityStore = App.state.rootStore.userAuthStore.bettingActivityStore;
    const { culture } = useAppContext();
    const { showError } = useNotification();
    const { setIsLoading } = useLoading();
    const { t } = useTranslation();
    const user = useUser();

    useEffect(() => {
        App.state.rootStore.titleStore.setTitle(t('MEMBERSHIP.BETTING_ACTIVITY.TITLE'));
        //on mount scroll to top
        document.querySelector('html, body').scrollTo(0, 0);
    }, []);

    const updateBettingActivityReview = async () => {
        try {
            setIsLoading(true)
            await bettingActivityStore.updateBettingActivityReview();
            user.resolveBettingActivity();
            if (user.user.shouldAcceptTermsAndConditions) {
                App.state.history.push(`/${culture}/auth/accept-new-terms`);
            }
            else {
                App.state.history.push(`/${culture}/home/full`)
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false)
        }
    }

    const logoutUser = async () => {
        try {
            setIsLoading(true)
            await user.logoutUser();
            App.state.history.push(`/${culture}/home/full`)
        } catch (error) {
            console.error(error);
            showError(t("USER.ACCOUNT_SETTINGS.ERR_HANDLING.GENERIC_ERROR"));
        } finally {
            setIsLoading(false)
        }
    }
    if (App.state.rootStore.userAuthStore.bettingActivityStore.isLoading) {
        return <BettingActivityLoader />
    }

    return (
        <ErrorPage>
            <BettingActivityTemplate
                updateBettingActivityReview={updateBettingActivityReview}
                logoutUser={logoutUser}
            />
        </ErrorPage>
    )
})

function BettingActivityLoader() {
    const { t } = useTranslation();
    return (
        <div className="loader loader--full">
            <div className="loader__card">
                <div className="loader__spinner u-display--b" />
                <div className="loader__title u-type--color--negative">{t('HOME.LOADING')}...</div>
            </div>
        </div>
    );
}