import React from 'react';
import { constants } from "@gp/offer";
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

/**
 * Renders empty tips container
 */
function Empty(props) {

	return (
		<div className="offer__subheader__data offer__subheader__tip">
			<div className="offer__subheader__tip__row">
				<div className="offer__subheader__tip__item">&nbsp;</div>
				<div className="offer__subheader__tip__item">&nbsp;</div>
				<div className="offer__subheader__tip__item">&nbsp;</div>
			</div>
		</div>
	)
}

export function HeaderTipColumnTemplate(props) {

	const {
		column,
	} = props;

	if (column == null || column.bettingType === constants.resultPlaceholder) {
		return <Empty />
	}

	return (
		<div className="offer__subheader__data offer__subheader__tip">
			<div className="offer__subheader__tip__row">
				<Tips tips={column.displayTips} />
			</div>
		</div>
	);
}

function Tips(props) {

	const {
		tips,
	} = props;

	return tips.map(tip =>
		<Tip key={tip} tip={tip} />
	)
}

function Tip(props) {
	const { t } = useTranslation();

	const {
		tip,
	} = props;

	if ([constants.setnrPlaceholder, constants.gamenrPlaceholder, constants.marginPlaceholder].includes(tip)) {
		return <div className="offer__subheader__tip__item">&nbsp;</div>
	}

	return (
		<div className="offer__subheader__tip__item">{t([`BETTING_TYPE.TIPS.${tip}`, tip])}</div>
	)
}