import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {EmptyStateTemplate} from "themes/common/components/empty-state";

@observer
class EmptyState extends Component {
    render() {
        return (
            <EmptyStateTemplate {...this.props} />
        );
    }
}

EmptyState.propTypes = {
    styles: PropTypes.shape({ wrapper: PropTypes.string, img: PropTypes.string }),
    imgSrc: PropTypes.string,
    imgAlt: PropTypes.string,
    message: PropTypes.string
};

export default EmptyState;