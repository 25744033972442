import React, { useEffect } from "react";
import { Error404Template } from "themes/common/components/error";

export default function Error404(props) {

    useEffect(() => {
        App.state.rootStore.isError404PageActive = true;
        App.state.rootStore.titleStore.setTitle("404");
        //on mount scroll to top
        document.querySelector('html, body').scrollTo(0, 0);

        return () => App.state.rootStore.isError404PageActive = false;
    }, []);

    return <Error404Template {...props} />
}