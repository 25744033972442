import React from 'react';

import { OfferTvCoverageActionButton } from '../../../../../common/components/offer'
import { StatisticsButton } from '../../../../../common/components/offer/buttons';

function OfferActionButtonsTemplate(props) {

	const {
		event,
		loadTvs,
		tvCoverage,
	} = props;

	return (
		<>
			<StatisticsButton isOfferButton />
			<OfferTvCoverageActionButton event={event} hasTvCoverage={event.hasTvCoverage} loadTvs={loadTvs} tvCoverage={tvCoverage} />
		</>
	);
}

export default OfferActionButtonsTemplate;