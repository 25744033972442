import React from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { LastMinuteOfferTemplate } from 'themes/pages/sports/components';

@inject(stores => ({
    offerStore: stores.offerStore
}))
@observer
@withRouter
class LastMinuteOffer extends React.Component {
    constructor(props) {
        super(props);

        this.viewStore = new App.offer.pages.home.components.LastMinuteViewStore(props.offerStore);
    }

    async componentDidMount() {
        await this.viewStore.onInitialize();
    }

    componentWillUnmount() {
        this.viewStore.onDispose();
    }

    render() {
        return (
            <LastMinuteOfferTemplate
                {...this.props}
                viewStore={this.viewStore}
            />
        );
    }
}

export default LastMinuteOffer;