import React from 'react';
import { DateTime } from 'luxon';
import { useCurrency } from '../../../../../../common/hooks';
import { Button } from '../../../../../../common/components/buttons';

export default function AccountStatementTableTransactionTemplate(props) {

    const { currency } = useCurrency()

    const {
        transaction,
        fetchSlipDetails
    } = props;

    const {
        numericalId,
        dateCreated,
        transactionType,
        amount,
        netAmount,
    } = transaction;

    return (
        <tr className='table--primary__body__row'>
            <td className='table--primary__body__data u-type--wgt--bold u-type--center'>
                {numericalId}
            </td>
            <td className='table--primary__body__data'>
                {DateTime.fromISO(dateCreated).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
            </td>
            <td className='table--primary__body__data'>
                {transactionType.name}
            </td>
            <td className='table--primary__body__data u-type--wgt--bold u-type--center'>
                {amount.toFixed(2)} {currency}
            </td>
            <td className='table--primary__body__data u-type--center'>
                {netAmount.toFixed(2)} {currency}
            </td>
            <td className='table--primary__body__data u-type--right'>

                <TransactionHasDetails
                    transaction={transaction}
                    fetchSlipDetails={fetchSlipDetails}
                />

            </td>
        </tr>
    )
}

function TransactionHasDetails(props) {
    const {
        transaction,
        fetchSlipDetails
    } = props;

    if (!transaction.hasDetails) {
        return null;
    }

    return (

        <Button
            btnClassName="btn btn--link"
            btnType="button"
            onClick={() => fetchSlipDetails(transaction)}
            btnContent={<i className='u-icon u-icon--xsml u-icon--arrow-right u-align--v--middle'></i>}
        />
    )
}