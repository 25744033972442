import React from 'react';
import { NavLink } from 'react-router-dom';

import { defaultTemplate } from '../../../../../common/hoc';
import { TeamName } from '../../../../../common/components/offer/details';

export default defaultTemplate(function LiveEventSideMenuTemplate (props) {
    const {
        liveEventMenuViewStore,
        match: {
            params: {
                culture,
                period
            }
        },
        t } = props;

    const { isLoading, eventsInSports, lookupsStore } = liveEventMenuViewStore;

    if (isLoading) {
        return (
            <div className="loader--menu">
                <div className="loader__card">
                    <div className="loader__card__title">{t("COMMON.LOADING")}...</div>
                    <span />
                </div>
            </div>
        )
    }

    return (
        <nav className="menu--sport">
            <ul className="menu--sport__menu">
                {eventsInSports.sports.map(s => {
                    // TODO: maybe add sport header with expand/collapse?
                    return s.events.map(event => {
                        const t1 = lookupsStore.teams.get(event.teamOneId);
                        const t2 = lookupsStore.teams.get(event.teamTwoId);

                        return (
                            <li key={event.id} className="menu--sport__item">
                                <NavLink
                                    className="menu--sport__link"
                                    activeClassName="is-active"
                                    to={`/${culture}/live/${period}/event/${event.id}`}
                                >
                                    <div className="menu--sport__link__title menu--sport__link__title--low">
                                        <i className={`u-icon u-icon--sport u-icon--${s.abrv} u-mar--right--xsml`} />
                                        <span>
                                            <TeamName team={t1} />
                                            {' - '}
                                            <TeamName team={t2} />
                                        </span>
                                    </div>
                                </NavLink>
                            </li>
                        )
                    })
                })}
            </ul>
        </nav>
    )
});