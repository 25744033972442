import React from 'react';
import { BetSlipBetType } from '@gp/gp-javascript-utils';
import { useTranslation } from 'react-i18next';

import { defaultTemplate } from '../../../../../common/hoc';

export default defaultTemplate(function (props) {
    const { t } = useTranslation();

    const {
        betSlipState: {
            betSlipBetType
        },
        changeBetSlipBetType
    } = props.store;

    return (
        <ul className="slip__tabs">
            <li className="slip__tabs__item">
                <button onClick={e => changeBetSlipBetType(BetSlipBetType.Normal)}
                    className={"slip__tabs__link" + (betSlipBetType === BetSlipBetType.Normal ? " is-active" : "")}>{t('specific:BET_SLIP.TITLE')}</button>
            </li>
            <li className="slip__tabs__item">
                <button onClick={e => changeBetSlipBetType(BetSlipBetType.OneClickBet)}
                    className={"slip__tabs__link" + (betSlipBetType === BetSlipBetType.OneClickBet ? " is-active" : "")}>{t('BET_SLIP.ONE_CLICK_BET.LABEL')}</button>
            </li>
        </ul>
    );
});