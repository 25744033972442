const httpClient = App.utils.httpClient;
const requestHeaders = {
    'Content-Type': 'application/json',
};
class AccountStatementTypesLookupService {

    findLookup() {
        const url = `${BaseAddress}${App.utils.getAgencyKey()}/transaction-types/account-statement-types?translate=true`
        return httpClient.get(url, requestHeaders, { responseType: 'json' })
    }
}

export default new AccountStatementTypesLookupService();