import React from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';

import { SearchTemplate } from 'themes/pages/search';

@inject(stores => ({
    searchViewStore: stores.searchViewStore,
    timezone: stores.stateStore.timezonesStore.selectedTimezone
}))
@observer
@withRouter
class Search extends React.Component {

    constructor(props) {
        super(props);

        document.querySelector('html, body').scrollTo(0, 0);
    }

    async componentDidMount() {
        const searchTerm = this.props.match.params.searchTerm;

        this.props.searchViewStore.rootStore.searchBarViewStore.setValue(searchTerm);
        await this.props.searchViewStore.onInitialize();
    }

    async componentDidUpdate(prevProps) {
        if (this.props.match.params.searchTerm !== prevProps.match.params.searchTerm) {
            await this.props.searchViewStore.onInitialize();
        }
    }

    componentWillUnmount() {
        this.props.searchViewStore.onDispose();
    }

    render() {
        return (
            <SearchTemplate {...this.props} />
        )
    }
}

export default Search;