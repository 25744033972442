import { SUPPORTED_LANGUAGES } from "./constants";

/**
 * Transforms any object to URL encoded string
 * @param {*} obj
 */
export function urlEncode(obj) {
	return Object.keys(obj)
		.map((k) => {
			return encodeURIComponent(k) + "=" + encodeURIComponent(obj[k]);
		})
		.join("&");
}

export function parseNestedJson(o) {
	var oo = {},
		t,
		parts,
		part;
	for (var k in o) {
		t = oo;
		parts = k.split(".");
		var key = parts.pop();
		while (parts.length) {
			part = parts.shift();
			t = t[part] = t[part] || {};
		}
		t[key] = o[k];
	}
	return oo;
}

export function getCurrentLanguage() {
	var culture = getCurrentCulture(); // short
	return SUPPORTED_LANGUAGES.find((s) => s.key === culture).keyLong;
}

export function getCookie(cname) {
	const name = cname + "=";
	const ca = decodeURIComponent(document.cookie).split(";");
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === " ") c = c.substring(1);
		if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
	}
	return null;
}

export function setCookie(key, value, days) {
	let expires = "";

	if (days) {
		const date = new Date();
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
		expires = "; expires=" + date.toUTCString();
	}

	document.cookie = `${key}=${value || ""}${expires}; path=/`;
}

export function getTimezone() {
	const tzCookie = getCookie("tz");

	if (tzCookie != null && tzCookie !== "") {
		const tzSplit = tzCookie.split("|");

		return {
			name: tzSplit[0],
			offset: tzSplit[1],
		};
	}

	return {
		name: "",
		offset: 0,
	};
}

export function getValueFromQuery(location, parameter) {
	let url = new URL(location);
	let value = url.searchParams.get(parameter);
	return value;
}

export function isNullOrWhitespace(value) {
	if (typeof value === "undefined" || value == null) {
		return true;
	}
	return value.replace(/\s/g, "").length < 1;
}

export function getCurrentCulture() {
	// TODO: is this the best way to get current culture ?
	const culture = window.location.pathname.split("/")[1];
	return SUPPORTED_LANGUAGES.find(
		(s) => s.key === culture || s.keyLong === culture
	).key;
}

export function fontResize() {
	var d = document.querySelector(".linkbox");

	if (d != null) {
		var a = d.getBoundingClientRect().width;
		var b = a * 0.7;
		var c = a * 0.12;

		if (d.querySelector(".u-tw-icon") != null) {
			d.querySelector(".u-tw-icon").style.fontSize = b;
		}

		if (d.querySelector(".linkbox__link__title") != null) {
			d.querySelector(".linkbox__link__title").style.fontSize = c;
		}
	}
}
