import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { validate } from "../utils";
import { RecoverPasswordNewPasswordWithProgressTemplate } from "themes/pages/membership/recover-password/components";

export default function RecoverPasswordNewPasswordWithProgress(props) {

    const { formState: { isSubmitting } } = useFormContext();
    const [progressCounter, setProgressCounter] = useState(0);

    const {
        passwordPattern
    } = props;

    if (isSubmitting && progressCounter !== 0) {
        setProgressCounter(0);
    }

    const onChangeInputPassword = (e) => {
        const passwordInput = validate(e.target.value);
        setProgressCounter(passwordInput * 25);
    }

    return <RecoverPasswordNewPasswordWithProgressTemplate
        onChangeInputPassword={onChangeInputPassword}
        progressCounter={progressCounter}
        passwordPattern={passwordPattern}
    />;
}