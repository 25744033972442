import { DateTime } from "luxon";

export const mapArray = (dataArray) => {
    if (!dataArray) return;
    const mapThroughDataArray = dataArray.map((data) => {
        return {
            id: data.id,
            name: data.name,
        }
    });
    return mapThroughDataArray;
}

export function sortArray(array) {
    if (array.length == 0) {
        return;
    }
    const sorted = array.sort(function (a, b) {
        if (a.units == -1 || b.units == -1) {
            return b.units - a.units;
        }
    })
    return sorted;
}

export const filterArray = (arr1, arr2) => {
    if (arr1.length == 0 || arr1.length == 0) {
        return;
    }
    const filtered = arr1.filter(el => {
        return arr2.indexOf(el) === -1;
    });
    return filtered;
};

export const changeIdToName = (id, fetchedData) => {
    if (id == undefined || fetchedData == undefined) {
        return;
    }
    const name = fetchedData.durationTypes.filter(item => item.id === id);

    const nameItem = name[0];
    const modifiedNameItem = nameItem.name.split(" ");
    return modifiedNameItem[0];
}


export const formatDateTime = (date) => {
    return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
}



export const processDirtyFields = (data, dirtyFields) => {
    const touchedFields = {};
    Object.keys(dirtyFields).map(fieldName => {
        touchedFields[fieldName] = data[fieldName];
    })
    return touchedFields;
}

export function sortCheckboxArray(array) {
    if (array.length == 0) {
        return;
    }
    const sortedCheckboxList = array.sort(function (a, b) {
        return a.code - b.code
    });
    return sortedCheckboxList;
}

export const removeUndefinedFields = (data) => {
    const formData = {};

    Object.keys(data).forEach(fieldName => {
        if (data[fieldName] !== undefined) {
            formData[fieldName] = data[fieldName]
        }
    })

    return formData;
}
