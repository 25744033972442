import { inject, observer } from 'mobx-react';
import React from 'react';
import { ResultDataTabsTemplate } from "themes/pages/results/components";

export default inject(stores => ({
    selectedDay: stores.offerStore.resultsDataStore.dayParam,
    changeDay: stores.offerStore.resultsDataStore.changeDayParam,
    isSearch: stores.offerStore.resultsDataStore.isSearch,
    searchString: stores.offerStore.searchBarViewStore.persistentTerm,
    resetResultsData: stores.offerStore.resultsDataStore.resetResultsData
}))(observer(function ResultDataTabs(props) {
    return <ResultDataTabsTemplate {...props} />
}))