import React, { useState, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useLoading } from "../../../common/hooks";
import { PasswordChangeTemplate } from "themes/pages/administration";
import { useNotification } from "../../../common/hooks";
import { PasswordChangeService } from "./services";
import { useTranslation } from "react-i18next";
import { ErrorPage } from "../../../common/components/error";

const PasswordChangeServiceInstance = new PasswordChangeService();

function PasswordChange() {
    const methods = useForm({ mode: "onChange" });
    const { reset } = methods;
    const { setIsLoading } = useLoading();
    const [userInfo, setUserInfo] = useState([]);
    const { showError, showSuccess } = useNotification();
    const { t } = useTranslation();

    const passwordChangeService = useMemo(() => PasswordChangeServiceInstance, []);

    useEffect(() => {

        App.state.rootStore.titleStore.setTitle(t('USER.ACCOUNT_SETTINGS.NAVIGATION.TITLE_PC'));

        //on mount scroll to top
        document.querySelector('html, body').scrollTo(0, 0);
        const fetchUserData = () => {
            try {
                setIsLoading(true);
                const response = passwordChangeService.getUserEmailAndUserName();
                const userData = Object.values(response.data);
                if (userData.length !== 0) {
                    setUserInfo(userData);
                }
            }
            catch (error) {
                showError('USER.ACCOUNT_SETTINGS.ERR_HANDLING.GENERIC_ERROR');
            }
            finally {
                setIsLoading(false);
            }
        }

        fetchUserData();
    }, []);

    const onSubmitData = async (formData) => {
        const model = { OldPassword: formData.currPassword, NewPassword: formData.newPassword };

        try {
            setIsLoading(true);
            const response = await passwordChangeService.changeUserPassword(model);
            if (response.success == true) {
                showSuccess('USER.ACCOUNT_SETTINGS.PASSWORD_CHANGE.SUCCESS_PWD_CHANGE');
            }
        }
        catch (error) {
            if (error?.error?.message) {
                showError('USER.ACCOUNT_SETTINGS.ERR_HANDLING.' + error?.error?.message);
            }
        }
        finally {
            reset();
            setIsLoading(false);
        }
    }

    return (
        <ErrorPage>
            <PasswordChangeTemplate
                methods={methods}
                userInfo={userInfo}
                onSubmitData={onSubmitData}
            />
        </ErrorPage>
    )
}

export default PasswordChange;
