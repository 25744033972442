import React from 'react';
import { defaultTemplate } from '../../../../../common/hoc';
import Slider from 'react-slick';

export default defaultTemplate(function (props) {
    const settings = {
        autoplay: true,
        autoplaySpeed: 10000,
        arrows: false,
        dots: true,
        infinite: true,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <Slider {...settings} className="carousel">
            <div className="carousel__item">
                <div className="carousel__media">
                    <img src={"/assets/images/bg/vip-homepage-carousel-" + App.utils.getCurrentCulture() + ".jpg"} className="carousel__media__img" alt="" />
                </div>
            </div>
        </Slider>
    );
});