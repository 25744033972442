import React from "react";
import { observer } from "mobx-react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { PrivateRoute } from ".";
import { useAppContext, useUser } from "../../hooks";

function RouteElement(props) {
    const {
        privateRoute = false,
        path,
        children,
        ...rest
    } = props;
    const { culture } = useAppContext();
    const user = useUser();

    const { pathname, search } = useLocation();
    if (privateRoute) {
        return (
            <PrivateRoute>
                <Route path={path} {...rest}>
                    {children}
                </Route>
            </PrivateRoute>
        )
    }

    const bettingActivityUrl = `/${culture}/auth/betting-activity`;
    const accountActivationUrl = `/${culture}/auth/account-activation`;
    const forcePasswordChangeUrl = `/${culture}/auth/force-password-change`;
    const acceptNewTermsUrl = `/${culture}/auth/accept-new-terms`;
    const loginUrl = `/${culture}/auth/login`;
    const homeUrl = `/${culture}/home/full`;
    const forgotPasswordUrl = `/${culture}/auth/forgot-password`;
    const forgotUsernameUrl = `/${culture}/auth/forgot-username`;
    const passwordRecoveryUrl = `/${culture}/auth/password-recovery`;


    // TODO: REFACTOR CODE BELOW: MOVE CONDITIONS TO USERAUTHSTORE, REARRANGE IF STATEMENTS TO BE IN CORRECT ORDER, CORRECT CONDITIONS
    if (user.accountActivation != null) {
        if (user.shouldDisplayAccountActivationAfterLogin && accountActivationUrl != pathname) {
            return <Redirect to={accountActivationUrl} />
        }

        if (user.shouldDisplayForcePasswordChangeAfterLogin && forcePasswordChangeUrl != pathname) {
            return <Redirect to={forcePasswordChangeUrl} />
        }
    }

    if (user.user != null && !user.user.bettingActivityNeeded && !user.user.shouldAcceptTermsAndConditions && user.accountActivation == null) {
        if ((pathname.includes(loginUrl) && !search.includes('returnUrl')) ||
            pathname.includes(forgotPasswordUrl) ||
            pathname.includes(forgotUsernameUrl) ||
            pathname.includes(passwordRecoveryUrl) ||
            (!user?.user?.bettingActivityNeeded && pathname.includes(bettingActivityUrl)) ||
            (!user?.user?.shouldAcceptTermsAndConditions && pathname.includes(acceptNewTermsUrl)) ||
            (user?.accountActivation == null && (pathname.includes(accountActivationUrl) || pathname.includes(forcePasswordChangeUrl)))
        ) {
            return <Redirect to={homeUrl} />
        }

    }

    if (user.user != null && user?.user?.bettingActivityNeeded && user.shouldDisplayBettingActivityAfterLogin && (
        bettingActivityUrl != pathname
    )) {
        return <Redirect to={bettingActivityUrl} />
    }

    if (user.user != null && user?.user?.shouldAcceptTermsAndConditions && user.shouldDisplayAcceptNewTermsAfterLogin && (
        acceptNewTermsUrl != pathname
    )) {
        return <Redirect to={acceptNewTermsUrl} />;
    }



    return (
        <Route path={path} {...rest}>
            {children}
        </Route>
    )
}

export default observer(RouteElement);