import React from 'react';
import { observer } from 'mobx-react';

import { OfferEmptyStateTemplate } from 'themes/common/components/offer';

/**
 * @param {{title: string, subtitle: string, description: string, callToActionUrl: string, callToActionLabel: string}} props 
 */
function OfferEmptyState(props) {
    return <OfferEmptyStateTemplate {...props} />;
}

export default observer(OfferEmptyState);