import React, { useContext } from 'react';
import ReactTooltip from 'react-tooltip';

import { LookupContext, OfferOptionsContext, OfferStoreContext } from '@gp/components';

import { defaultTemplate } from '../../../../common/hoc';
import { OfferPager } from '../../../../common/components/offer';
import { ErrorPage } from '../../../../common/components/error';
import { SportsEmptyState } from '../../../../pages/sports/components';
import { PrematchSport } from '../../../../common/components/offer/new-offer';


export default defaultTemplate(function (props) {
    const { sportsViewStore, t } = props;
    const {
        onDispose,
        additionalOfferStore: {
            onAdditionalOfferExpand
        },
        rootStore: {
            resizeStore: {
                availableColumns
            }
        },
    } = sportsViewStore;

    App.state.rootStore.titleStore.setTitle(t('SPORTS.TITLE'));

    return (
        <ErrorPage onError={onDispose}>

            <OfferStoreContext.Provider value={sportsViewStore}>
                <LookupContext.Provider value={sportsViewStore.lookupsStore}>
                    <OfferOptionsContext.Provider
                        value={{
                            numberOfColumns: availableColumns,
                            scoreChangeDuration: 3500,
                            enableInlineAdditionalOffer: false,
                            isLive: false,
                            isFavorites: false,
                            isOneClickBetActive: sportsViewStore.rootStore.betSlipStore.betSlipState.isOneClickBetActive,
                            onAdditionalOfferClick: (eventData) => {
                                onAdditionalOfferExpand(eventData);
                            },
                            onTipClick: (tip) => {
                                sportsViewStore.rootStore.betSlipStore.addRemoveOffer(tip);
                            },
                            isTipSelected: (tipId) => {
                                if (sportsViewStore.rootStore.betSlipStore.betSlipState.isOneClickBetActive) {
                                    return sportsViewStore.rootStore.betSlipStore.betSlipState.isInOneClickBet(tipId);
                                }
                                return sportsViewStore.rootStore.betSlipStore.isInBetSlip(tipId);
                            },
                            // TODO: add favorites
                            isEventInFavorites: eventId => false,
                            addEventToFavorites: eventId => { },
                            removeEventFromFavorites: eventId => { },
                        }}
                    >

                        <SportsTemplateCore />
                    </OfferOptionsContext.Provider>
                </LookupContext.Provider>
            </OfferStoreContext.Provider>

            <ReactTooltip
                id="bt-description"
                className="tooltip--tertiary"
                place="bottom"
                multiline={true}
            />
            <ReactTooltip
                id="team-description"
                className="tooltip--secondary"
                place="bottom"
                multiline={true}
            />
        </ErrorPage>
    );
});

const SportsTemplateCore = defaultTemplate(function SportsTemplateCore(props) {

    const {
        t,
    } = props;

    const {
        isLoading,
        isEmpty,
        isStoreInitialized
    } = useContext(OfferStoreContext);

    // render offer placeholder while loading
    if (isLoading) {
        return (
            <Loader />
        )
    }

    // render empty state if there are no events
    if (isEmpty) {
        return <SportsEmptyState />
    }

    if (!isStoreInitialized) {
        return null;
    }

    return (
        <>
            <h1 className="title--primary">{t('SPORTS.TITLE')}</h1>
            <SportsList />
            <OfferPagerWrapper />
        </>
    )
})

const OfferPagerWrapper = defaultTemplate(function OfferPagerWrapper(props) {

    const {
        rootStore: {
            prematchMenuViewStore: {
                closeAll
            },
        },
        pageNumber,
        pageSize,
        totalItems,
        changePage,
        isRemoveAllVisible
    } = useContext(OfferStoreContext);

    return (
        <OfferPager
            pageNumber={pageNumber}
            pageSize={pageSize}
            totalItems={totalItems}
            onPageChange={changePage}
            onCloseAll={closeAll}
            isRemoveAllVisible={isRemoveAllVisible}
        />
    )
})

const SportsList = defaultTemplate(function SportsList(props) {

    const {
        t,
    } = props;

    const {
        eventsInSports: {
            sports,
        },
        configuration: {
            bettingTypeSelectorsStore,
        }
    } = useContext(OfferStoreContext);

    return sports.map(sport => (
        <PrematchSport
            key={getSportKeyId(sport.id, sport.isLive, sport.isOutright)}
            sport={sport} bettingTypeSelectorsStore={bettingTypeSelectorsStore}
        />
    ))
})

const Loader = defaultTemplate(function Loader(props) {

    const {
        t,
    } = props;

    return (
        <div className="loader">
            <div className="loader__card">
                <div className="loader__card__title">
                    {t("COMMON.LOADING")}...
                </div>
                <span />
            </div>
        </div>
    )
})

const getSportKeyId = (id, isLive, isOutright) => {
    if (isLive) {
        return `${id}-live`;
    }

    if (isOutright) {
        return `${id}-outright`;
    }

    return id;
}