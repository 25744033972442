import React, { useContext, useState } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";

import {
	TypeConfiguration,
	getSortedEventKeysBySpecifierValue,
} from "@gp/offer";

import { SpecifierType } from "@gp/models";
import { NameProvider, Sorter, NBATeamNameComparer } from "@gp/utility";

import {
	OfferStoreContext,
	LookupContext,
	EventContext,
	OfferOptionsContext,
} from "@gp/components";

import { TipButton } from "../../../../../../common/components/offer/buttons";

const nameProvider = new NameProvider();

export default observer(function GroupedEventOfferTemplate(props) {
	const {} = props;

	const event = useContext(EventContext);

	const eventOfferClasses = classNames("event__offer", "offer--additional", {
		"event--live__offer": event.isLive,
	});

	return (
		<div className="event__body">
			<div className={eventOfferClasses}>
				<div className="offer--additional__body">
					<TemplateCore />
				</div>
			</div>
		</div>
	);
});

const TemplateCore = observer(function TemplateCore(props) {
	const { enableBettingTypeGroups } = useContext(OfferStoreContext);

	if (enableBettingTypeGroups) {
		return <BettingTypeGroups />;
	}

	return <NotGroupedBettingTypes />;
});

//#region grouped betting types

const BettingTypeGroups = observer(function BettingTypeGroups(props) {
	const { displayBettingGroup } = useContext(OfferStoreContext);

	const { bettingTypeGroups } = useContext(EventContext);

	if (displayBettingGroup !== "all") {
		const group = bettingTypeGroups.find(
			(g) => g.abrv === displayBettingGroup
		);
		return <BettingTypeGroup key={group.abrv} group={group} />;
	}

	return bettingTypeGroups.map((group) => (
		<BettingTypeGroup key={group.abrv} group={group} />
	));
});

function BettingTypeGroup(props) {
	const { group } = props;

	const [isExpanded, setExpand] = useState(true);

	return (
		<table className="offer">
			<BettingTypeGroupHeader
				isExpanded={isExpanded}
				onSetExpand={setExpand}
				group={group}
			/>
			<BettingTypeGroupBody isExpanded={isExpanded} group={group} />
		</table>
	);
}

function BettingTypeGroupHeader(props) {
	const { isExpanded, onSetExpand, group } = props;

	const iconClass = classNames("u-icon u-icon--xsml", {
		"u-icon--arrow-down--white": isExpanded,
		"u-icon--arrow-right--white": !isExpanded,
	});

	return (
		<thead className="offer__head">
			<tr className="offer__head__row">
				<th className="offer__head__data u-type--case--none">
					{group.name}
				</th>
				<th className="offer__head__data offer__toggle">
					<button
						className="offer__toggle__btn btn btn--sml-square btn--link btn--icon-center"
						type="button"
						onClick={(e) => onSetExpand(!isExpanded)}
					>
						<i className={iconClass} />
					</button>
				</th>
			</tr>
		</thead>
	);
}

const BettingTypeGroupBody = observer(function BettingTypeGroupBody(props) {
	const { isExpanded, group } = props;

	if (!isExpanded) {
		return null;
	}

	return (
		<tbody className="offer__body">
			<tr className="offer__body__row">
				<td className="offer__body__data u-padd--reset" colSpan={2}>
					<div className="offer--additional">
						<div className="offer--additional__body">
							{group.bettingTypes.map((bt) => (
								<BettingType key={bt.id} bettingType={bt} />
							))}
						</div>
					</div>
				</td>
			</tr>
		</tbody>
	);
});

//#endregion grouped betting types

//#region not grouped betting types

const NotGroupedBettingTypes = observer(function NotGroupedBettingTypes(props) {
	const event = useContext(EventContext);
	const { eventKeysMap } = useContext(OfferStoreContext);

	const eventKeys = eventKeysMap.get(event.id);
	const eventKeysForGroup = Array.from(eventKeys.values());

	let bettingTypes = eventKeysForGroup
		.reduce((acc, key) => {
			if (acc.find((bt) => bt.abrv === key.bettingType.abrv) == null) {
				acc.push(key.bettingType);
			}
			return acc;
		}, [])
		.sort(
			Sorter.sort((a, b) => {
				const aSort = a?.settingsPerSport?.[event.sportId]?.sortOrder;
				const bSort = b?.settingsPerSport?.[event.sportId]?.sortOrder;

				const aSortOrder = aSort == null ? 99999 : aSort;
				const bSortOrder = bSort == null ? 99999 : bSort;

				return Math.sign(aSortOrder - bSortOrder);
			}, "id")
		);

	return (
		<div className="offer__body offer--additional__body">
			{bettingTypes.map((bt) => (
				<BettingType key={bt.abrv} bettingType={bt} />
			))}
			<CloseButton />
		</div>
	);
});

//#endregion not grouped betting types

//#region betting type offer

const BettingType = observer(function BettingType(props) {
	const { bettingType } = props;

	const event = useContext(EventContext);
	const { 
		eventKeysMap,
		lookupsStore: { teams } } = useContext(OfferStoreContext);
	const { excludedBettingOfferKeys } = useContext(OfferOptionsContext);

	let bettingTypeKeys = Array.from(
		eventKeysMap.get(event.id).values()
	).filter((key) => key.bettingType.abrv === bettingType.abrv);
	bettingTypeKeys = getSortedEventKeysBySpecifierValue(bettingTypeKeys);

	if (Array.isArray(excludedBettingOfferKeys)) {
		bettingTypeKeys = bettingTypeKeys.filter((key) => {
			return !excludedBettingOfferKeys.includes(key.id);
		});
	}

	return bettingTypeKeys
		.sort(Sorter.sort((a, b) => NBATeamNameComparer(a, b, event, teams)))
		.map((key) => (
			<BettingTypeRow
				key={key.id}
				bettingType={bettingType}
				offerKey={key}
			/>
		));
});

const BettingTypeRow = observer(function BettingTypeRow(props) {
	const { bettingType, offerKey } = props;

	const { keyOffersMap } = useContext(OfferStoreContext);

	const keyOffers = keyOffersMap.get(offerKey.id);
	let offers = [];

	if (keyOffers != null) {
		offers = Array.from(keyOffers.values());
	}

	const btConfig = new TypeConfiguration(
		bettingType.abrv,
		offers.map((o) => o.tip)
	);

	const event = useContext(EventContext);
	const { teams } = useContext(LookupContext);

	const homeTeam = teams.get(event.teamOneId);
	const awayTeam = teams.get(event.teamTwoId);

	const specifiers = {
		...offerKey.specifier,
		competitor1: homeTeam?.name,
		competitor2: awayTeam?.name,
	};

	const name = nameProvider.getName(bettingType.nameForOfferList, specifiers);
	const description = nameProvider.getName(
		bettingType.description,
		specifiers
	);

	if (bettingType.specifierType === SpecifierType.PLAYER) {
		const sortTips = (a, b) => {
			const aVal = parseFloat(a.value);
			const bVal = parseFloat(b.value);

			if (isNaN(aVal) || isNaN(bVal)) {
				return 0;
			}

			return Math.sign(aVal - bVal);
		};
		const homeTeamOffers = offers
			.filter((o) => o.teamId === event.teamOneId && o.value > 1)
			.sort(Sorter.sort(sortTips));
		const awayTeamOffers = offers
			.filter((o) => o.teamId === event.teamTwoId && o.value > 1)
			.sort(Sorter.sort(sortTips));

		return (
			<div className="offer--additional__row offer--player">
				<div className="row offer--player__row">
					<div className="col col-sml-4 offer--player__col">
						<div className="offer--player__title u-type--wgt--regular">
							{name}
							<i
								data-tip
								data-for={
									"offerRowTooltip-" + name.replace(" ", "")
								}
								className="u-icon u-icon--xsml u-icon--info u-align--v--top u-mar--left--sml"
							/>
						</div>
					</div>
					<div className="col col-sml-4 offer--player__col">
						<div className="offer--player__team">
							{homeTeam.name}
						</div>
						<ul className="offer--player__list">
							{homeTeamOffers.map((tip) => (
								<TipButton
									key={tip.id}
									tip={tip}
									bettingTypeMapped={name}
									isWithoutTip
								/>
							))}
						</ul>
					</div>
					<div className="col col-sml-4 offer--player__col">
						<div className="offer--player__team">
							{awayTeam.name}
						</div>
						<ul className="offer--player__list">
							{awayTeamOffers.map((tip) => (
								<TipButton
									key={tip.id}
									tip={tip}
									bettingTypeMapped={name}
									isWithoutTip
								/>
							))}
						</ul>
					</div>
				</div>

				<div className="row offer--player__row">
					<div className="col col-sml-4 offer--player__col">
						&nbsp;
					</div>
					{offers
						.filter((o) => o.teamId == null)
						.map((tip) => (
							<TipButton
								key={tip.id}
								tip={tip}
								bettingTypeMapped={name}
								isWithoutTip
							/>
						))}
				</div>

				<OfferTips offers={offers} />

				<BettingTypeToolTip name={name} description={description} />
			</div>
		);
	}

	return (
		<div className="offer--additional__row">
			<div className="offer--additional__data offer--additional__data--left">
				{name}
				<i
					data-tip
					data-for={"offerRowTooltip-" + name.replace(" ", "")}
					className="u-icon u-icon--xsml u-icon--info u-align--v--top u-mar--left--sml"
				/>
			</div>
			<div className="offer--additional__data offer--additional__data--right">
				<div className="offer--additional__quotes">
					{btConfig.tips.sorted.map((tip) => {
						const tipOffer = offers.filter(
							(t) => t.tip.toLowerCase() === tip.toLowerCase()
						)[0];

						if (tipOffer == null) {
							if (btConfig.tips.tipOptions.displayPlaceholders) {
								return <TipButton key={tip} />;
							}
							return null;
						}

						return <TipButton key={tipOffer.id} tip={tipOffer} />;
					})}
				</div>
			</div>
			<BettingTypeToolTip name={name} description={description} />
		</div>
	);
});

function BettingTypeToolTip(props) {
	const { name, description } = props;

	return (
		<ReactTooltip
			id={"offerRowTooltip-" + name.replace(" ", "")}
			className="tooltip--primary tooltip--event u-padd--reset"
			place="bottom"
			effect="solid"
			multiline={true}
			border={true}
			borderColor="red"
			arrowColor="red"
		>
			<div className="tooltip--primary__header">{name}</div>
			<div className="tooltip--primary__body">{description}</div>
		</ReactTooltip>
	);
}

function OfferTips(props) {
	const { offers } = props;

	return offers
		.filter((o) => o.teamId == null)
		.map((tip) => <OfferTip key={tip.id} tip={tip} />);
}

function OfferTip(props) {
	const { tip } = props;

	return (
		<div className="row offer--player__row">
			<div className="col col-sml-4 offer--player__col">&nbsp;</div>
			<div className="col col-sml-8 offer--player__col offer--player__goal">
				<TipButton tip={tip} />
			</div>
		</div>
	);
}

//#endregion tip button

function CloseButton(props) {
	const {} = props;

	const { onClose, isAdditionalOffer } = useContext(OfferOptionsContext);

	const { t } = useTranslation();

	if (!isAdditionalOffer) {
		return null;
	}

	return (
		<div className="offer--additional__row offer--additional__close">
			<div className="offer--additional__data offer--additional__close__inner">
				<button
					className="offer--additional__close__btn btn btn--tny btn--link"
					type="button"
					onClick={onClose}
				>
					{t("COMMON.CLOSE")}
				</button>
			</div>
		</div>
	);
}
