import React from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { MostPlayedOfferTemplate } from 'themes/pages/sports/components';

@inject(stores => ({ offerStore: stores.offerStore }))
@observer
@withRouter
class MostPlayedOffer extends React.Component {
    constructor(props) {
        super(props);

        this.viewStore = new App.offer.pages.home.components.MostPlayedViewStore(props.offerStore, {
            columns: ['1', '2']
        });
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        const urlParams = new URLSearchParams(this.props.location.search);

        App.state.rootStore.titleStore.setTitle(App.state.localizationService.t('HOME.MOST_PLAYED.TITLE'));

        if (urlParams.has('event')) {
            this.viewStore.additionalOfferStore.setAdditionalOfferEvent(urlParams.get('event'));
        }

        await this.viewStore.initializeStore();
    }

    componentDidUpdate(prevProps) {
        const urlParams = new URLSearchParams(this.props.location.search);
        const prevUrlParams = new URLSearchParams(prevProps.location.search);

        if (urlParams.has('event')) {
            this.viewStore.additionalOfferStore.setAdditionalOfferEvent(urlParams.get('event'));
        }
        else if (prevUrlParams.has('event') && !urlParams.has('event')) {
            this.viewStore.additionalOfferStore.setAdditionalOfferEvent(null);
        }
    }

    componentWillUnmount() {
        // this.viewStore.onDispose();
    }

    render() {
        return (
            <MostPlayedOfferTemplate
                {...this.props}
                viewStore={this.viewStore}
            />
        );
    }
}

export default MostPlayedOffer;