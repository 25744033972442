import React, { useEffect, useState } from "react";
import { FormProvider, useFormState } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInputFieldCurrency, FormSelectField } from "../../../../../../common/components/inputs";
import { Loader } from "../../../../../../common/components/loader";
import { useLoading, useNotification } from "../../../../../../common/hooks";
import {
    checkForDuration,
    mapData,
    checkForType,
} from "../../../../../../pages/administration/my-limits/utils";
import { Button, FormButton } from "../../../../../../common/components/buttons";


export default function DeleteOrUpdateLimitSection(props) {
    const { t } = useTranslation();
    const [lookups, setLookups] = useState({
        limitDurations: [],
        limitTypes: [],
    });

    const {
        showSuccess,
        showError,
    } = useNotification();

    const {
        isLoading,
        setIsLoading
    } = useLoading();

    const {
        methods,
        fetchedData,
        setViewType,
        getBettingLimitLookups,
        deactivateBettingLimit,
        updateBettingLimit,
        setFetchedData
    } = props;

    const {
        handleSubmit,
        setValue,
        getValues,
        setError
    } = methods;

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const fetchedLookups = await getBettingLimitLookups();
                if (fetchedLookups != null) {
                    setLookups({ limitTypes: fetchedLookups.limitTypes.item, limitDurations: fetchedLookups.limitDurations.item });
                }
            } catch (error) {
                showError("USER.ACCOUNT_SETTINGS.ERR_HANDLING.GENERIC_ERROR");
            } finally {
                setIsLoading(false);
            }
        }
        fetchData();
    }, []);


    const onDeactivateSuccess = async () => {
        const limitId = fetchedData.viewData.limitId;

        try {
            setIsLoading(true);
            const response = await deactivateBettingLimit(limitId);
            if (response.success == true) {
                showSuccess("USER.ACCOUNT_SETTINGS.MY_LIMITS.DEACTIVATE_SUCCESS");
                setViewType(0);
                setValue("limitAmount", null);
            }
        } catch (error) {
            showError(t(`USER.ACCOUNT_SETTINGS.ERR_HANDLING.${error?.error?.message}`));
        } finally {
            setIsLoading(false);
        }
    }


    const onUpdateSuccess = async (data) => {
        if (Object.keys(getValues("limitAmount")).length == 0) {
            setError("limitAmount", { type: "required" }, { shouldValidate: true })
        }
        const limitId = fetchedData.viewData.limitId;
        try {
            setIsLoading(true);
            const response = await updateBettingLimit(limitId, data);
            if (response.success == true) {
                showSuccess("USER.ACCOUNT_SETTINGS.MY_LIMITS.UPDATE_SUCCESS");
                setViewType(2);
                setFetchedData(response.data.data);
            }
        } catch (error) {
            showError(t(`USER.ACCOUNT_SETTINGS.ERR_HANDLING.${error?.error?.message}`));
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <FormProvider {...methods}>
            <Loader isLoading={isLoading} />
            {fetchedData.length != 0 &&
                <form onSubmit={handleSubmit}>
                    <div className="form__group">
                        <div className="message--sml message--note u-mar--bottom--xmed">
                            <p>
                                {fetchedData != null &&
                                    t(`USER.ACCOUNT_SETTINGS.MY_LIMITS.${checkForDuration(fetchedData.viewData.limitDurationAbrv)}_${checkForType(fetchedData.viewData.limitTypeAbrv)}_LIMIT_INFO_MESSAGE`, {
                                        0: fetchedData.viewData.displayLimitAmount,
                                        1: fetchedData.viewData.currencyDisplayCode,
                                        interpolation: { escapeValue: false }
                                    })}
                            </p>
                        </div>

                        <FormSelectField
                            className="form__field row"
                            name="limitType"
                            placeholder={t("USER.ACCOUNT_SETTINGS.MY_LIMITS.LIMIT_TYPE_PLACEHOLDER")}
                            label={t("USER.ACCOUNT_SETTINGS.MY_LIMITS.LIMIT_TYPE")}
                            dropdownData={mapData(lookups.limitTypes)}
                            defaultValue={fetchedData.viewData.bettingUserLimitTypeId}
                            required
                        />

                        <FormSelectField
                            className="form__field row"
                            name="limitDuration"
                            placeholder="USER.ACCOUNT_SETTINGS.MY_LIMITS.LIMIT_DURATION_PLACEHOLDER"
                            label={t("USER.ACCOUNT_SETTINGS.MY_LIMITS.LIMIT_DURATION_LABEL")}
                            dropdownData={mapData(lookups.limitDurations)}
                            defaultValue={fetchedData.viewData.bettingUserLimitDurationId}
                            required
                        />

                        <FormInputFieldCurrency
                            label='USER.ACCOUNT_SETTINGS.MY_LIMITS.LIMIT_AMOUNT_LABEL'
                            name="limitAmount"
                            type="number"
                            className="form__field row"
                            pattern={{
                                value: /^0*[1-9][0-9]*(\.[0-9]+)?/
                            }}
                            maxLength={10}
                        />

                        <div className="form__field row">
                            <div className="col-sml-11 u-type--right">
                                <Button
                                    className="btn btn--med btn--neutral"
                                    onClick={handleSubmit(onDeactivateSuccess)}
                                    btnText="USER.ACCOUNT_SETTINGS.MY_LIMITS.LIMIT_DEACTIVATE"
                                />
                                <FormButton
                                    className="form__submit btn btn--med btn--secondary"
                                    onClick={handleSubmit(onUpdateSuccess)}
                                    btnText="USER.ACCOUNT_SETTINGS.MY_LIMITS.UPDATE_BUTTON"
                                />
                            </div>
                        </div>
                    </div>
                </form>
            }
        </FormProvider>
    )
} 
