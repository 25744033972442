import classNames from "classnames";
import React, { useState } from "react";


export default function AccordionOptionCardTemplate(props) {
    const [toggleBtn, setToggleBtn] = useState(false);

    const {
        header,
        children,
    } = props;

    const handleBtnToggle = () => {
        setToggleBtn(!toggleBtn);
    }

    const headerClass = classNames(
        "accordion--inner__item__header",
        {
            "is-expanded": toggleBtn,
        }
    )

    const bodyClass = classNames(
        "accordion--inner__item__content",
        {
            "is-expanded": toggleBtn
        }
    )

    return (
        <div className="accordion--inner">
            <div className="accordion--inner__item">
                <button type="button" className={headerClass} onClick={() => handleBtnToggle()}>{header}</button>
                <div className={bodyClass}>
                    {children}
                </div>
            </div>
        </div>
    )
}