import React, { Suspense } from "react";
import { AccountSettingsLayoutTemplate } from "themes/layouts";
import { PasswordConfirmationContainer } from "../common/containers";
class AccountSettingsLayout extends React.Component {
	render() {
		return (
			<Suspense fallback="Loading...">
				<PasswordConfirmationContainer>
					<AccountSettingsLayoutTemplate {...this.props} />
				</PasswordConfirmationContainer>
			</Suspense>
		);
	}
}

export default AccountSettingsLayout;
