import { BetOffer } from "./BetOffer";
import { observable } from "mobx";

export class Bet {
	constructor(bet) {
		this.assignValues = this.assignValues.bind(this);
		this.injectIsMultiToOffers = this.injectIsMultiToOffers.bind(this);
		this.groupOffersByEvent = this.groupOffersByEvent.bind(this);

		this.assignValues(bet);
	}

	//#region observable

	@observable cType;
	@observable isPaidOut;
	@observable maxGain;
	@observable displayedAmount;
	@observable calculatedCashoutAmount;
	@observable indicator;

	//#endregion observable

	//#region getters

	get formattedBetSystemString() {
		const interpolation = { escapeValue: false };

		if (this.system) {
			let systemString = `${this.system} / ${
				this.eventCount - this.combinationBettingOffersBankCount
			} = ${this.combinationBettingOffersCount}`;

			if (this.combinationBettingOffersBankCount > 0) {
				systemString = `B x ${this.combinationBettingOffersBankCount}, 
					${systemString}`;
			}

			return App.state.localizationService.t("MY_BETS.ITEM.INFO_SYSTEM", {
				interpolation,
				0: `(${systemString})`,
			});
		}

		if (this.totalNumberOfCombinations > 1) {
			return App.state.localizationService.t(
				"MY_BETS.ITEM.INFO_COMBINATION_MULTI",
				{ interpolation, 0: this.totalNumberOfCombinations }
			);
		}

		return App.state.localizationService.t(
			"MY_BETS.ITEM.INFO_COMBINATION",
			{ interpolation, 0: this.totalNumberOfCombinations }
		);
	}

	get firstFinishedTransactionItem() {
		if (
			this.metadata?.fiscalizationItems == null ||
			this.metadata.fiscalizationItems.length < 0
		) {
			return null;
		}

		return this.metadata.fiscalizationItems.find(
			(i) => i.isFinishTransaction == true
		);
	}

	get shouldShowBetSlipStatus() {
		return (
			this.betSlipStatus.abrv.includes("cashout") ||
			this.betSlipStatus.abrv === "paid-out"
		);
	}

	//#endregion getters

	//#region object assign

	assignValues(bet) {
		// Array of bet slip combinations
		this.betSlipCombinations = bet.betSlipCombinations;

		// Array of bet slip offers
		this.betSlipOffers = bet.betSlipOffers.map(
			(offer) => new BetOffer(offer)
		);
		this.groupedOffersByEvent = this.groupOffersByEvent(this.betSlipOffers);
		this.injectIsMultiToOffers(this.groupedOffersByEvent);

		this.betSlipStatus = bet.betSlipStatus;
		this.betSlipType = bet.betSlipType;
		this.betStatusId = bet.betStatusId;
		this.player = bet.player;

		this.cashoutTimeStamp = bet.cashoutTimeStamp;
		this.betSlipNumber = bet.betSlipNumber;
		this.betSlipSubmitEnvironmentTypeAbrv =
			bet.betSlipSubmitEnvironmentTypeAbrv;
		this.betStatus = bet.betStatus;
		this.bettingAccountType = bet.bettingAccountType;
		this.bettingAccountTypeId = bet.bettingAccountTypeId;
		this.calculatedCashoutAmount = bet.calculatedCashoutAmount;
		this.cashoutGain = bet.cashoutGain;
		this.code = bet.code;
		this.combinationBettingOffersBankCount =
			bet.combinationBettingOffersBankCount;
		this.combinationBettingOffersCount = bet.combinationBettingOffersCount;
		this.cType = bet.cType;
		this.eventCount = bet.eventCount;
		this.fiscalizationId = bet.fiscalizationId;
		this.fiscalizationSentDate = bet.fiscalizationSentDate;
		this.gain = bet.gain;
		this.gainBonus = bet.gainBonus;
		this.gainBonusPercentage = bet.gainBonusPercentage;
		this.gainTax = bet.gainTax;
		this.gainTaxPercentage = bet.gainTaxPercentage;
		this.handlingFee = bet.handlingFee;
		this.handlingFeePercentage = bet.handlingFeePercentage;
		this.ipAddress = bet.ipAddress;
		this.isBonus = bet.isBonus;
		this.isCancellationAvailable = bet.isCancellationAvailable;
		this.isLive = bet.isLive;
		this.isWege = bet.isWege;
		this.maxCoefficient = bet.maxCoefficient;
		this.maxGain = bet.maxGain;
		this.metadata = bet.metadata;
		this.minGain = bet.minGain;
		this.payment = bet.payment;
		this.paymentBonus = bet.paymentBonus;
		this.paymentPerCombination = bet.paymentPerCombination;
		this.payout = bet.payout;
		this.payoutDate = bet.payoutDate;
		this.slipStatusId = bet.slipStatusId;
		this.stake = bet.stake;
		this.stakePerCombination = bet.stakePerCombination;
		this.stakeTaxPercentage = bet.stakeTaxPercentage;
		this.system = bet.system;
		this.taxFreePayoutAmount = bet.taxFreePayoutAmount;
		this.totalNumberOfCombinations = bet.totalNumberOfCombinations;
		this.totalNumberOfOffersPerBetSlip = bet.totalNumberOfOffersPerBetSlip;
		this.userId = bet.userId;
		this.dateCreated = bet.dateCreated;
		this.dateUpdated = bet.dateUpdated;
		this.id = bet.id;

		// These are missing from response
		this.displayedAmount = this.calculatedCashoutAmount?.toFixed(2);
		this.indicator = 0;
	}

	/**
	 * @param {array} offerGroupsByEvent array of betSlipOffers grouped by event
	 */
	injectIsMultiToOffers(offerGroupsByEvent) {
		for (const eventOffers of offerGroupsByEvent) {
			if (eventOffers.length > 1) {
				for (let i = 1; i < eventOffers.length; i++) {
					eventOffers[i].isMulti = true;
					eventOffers[i].multiEventName = `${i + 1} Multi`;
				}
			}
		}
	}

	/**
	 * @param {array} offers
	 * @returns {array} array of offer offerGroup arrays
	 */
	groupOffersByEvent(offers) {
		const offerGroupsByEvent = new Map();

		for (const offer of offers) {
			const eventId = offer.eventId;

			if (!offerGroupsByEvent.has(eventId)) {
				offerGroupsByEvent.set(eventId, []);
			}

			offerGroupsByEvent.get(eventId).push(offer);
		}

		return Array.from(offerGroupsByEvent.values());
	}

	//#endregion object assign
}
