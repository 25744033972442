import { RequiredMark } from "../../../../../common/components/inputs";
import { ErrorMessage } from "../../../../../common/components/error";
import React from "react";
import { useTranslation } from "react-i18next";
import { useFormState } from "react-hook-form";

const TextareaFieldTemplate = (props) => {
    const { t } = useTranslation();
    const { errors } = useFormState();

    const {
        label,
        name,
        className,
        errorMessage = undefined,
        type = "checkbox",
        required = false,
        field,
        placeholder = undefined,
        labelClassName = null,
        removeSuccessMark,
        ...rest
    } = props;

    return (


        <div className="form__field row">
            <div className="col-sml-4">
                <label htmlFor={name} className={labelClassName ? labelClassName : "form__field__label"}>
                    {t(label)} <RequiredMark required={required} />
                </label>
            </div>
            <div className="col-sml-7">
                <textarea
                    className="input input--base input--textarea valid"
                    placeholder={placeholder}
                    name={name}
                    {...rest}
                    {...field}
                />
            </div>
            <div className="col-sml-1">
                    <ErrorMessage removeSuccessMark={removeSuccessMark} name={name} />
            </div>
        </div>
    )
}
export default TextareaFieldTemplate;