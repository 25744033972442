import React from "react";
import { ActivationSuccessTemplate } from "themes/pages/membership/email-activation/components";

export default function ActivationSuccess(props) {
	const { activationState } = props;

	if (activationState == null || activationState == false) {
		return null;
	}

	return <ActivationSuccessTemplate {...props} />;
}
