import React from 'react';
import { observer, inject } from 'mobx-react';

import { LoginHistoryTemplate } from 'themes/common/components/subheader';

@inject(stores => ({
    loginHistoryViewStore: stores.stateStore.loginHistoryViewStore
}))
@observer
class LoginHistory extends React.Component {
    async componentDidMount() {
        await this.props.loginHistoryViewStore.onInitialize();
    }

    componentWillUnmount() {
        this.props.loginHistoryViewStore.onDispose();
    }

    render() {
        return <LoginHistoryTemplate {...this.props} />;
    }
}

export default LoginHistory;