import React from 'react';
import { defaultTemplate } from '../../../../../common/hoc'

export default defaultTemplate(function (props) {
    const { description, t } = props;

    return (
        <div className="message--sml message--note">
            {t(description)}
        </div>
    )
});