import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInputField } from "../../../../../../common/components/inputs";
import { FormButton } from "../../../../../../common/components/buttons";
import { EmailChangeNewEmail } from "../../../../../../pages/administration/email-change/components";

export default function EmailChangeFormSectionTemplate(props) {

    const { t } = useTranslation();
    const { handleSubmit, getValues } = useFormContext();

    const { onSubmitData, checkForEmailAvailiability } = props;

    const emailPattern = /^[a-zA-Z][\w\.-]*[a-zA-Z0-9]@[a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]\.[a-zA-Z][a-zA-Z\.]*[a-zA-Z]$/;


    return (
        <form className="form" onSubmit={handleSubmit(onSubmitData)}>
            <div className="form__group">
                <div className="form__field row">
                    <div className="col-sml-4">
                        <label className="form__field__label">{t('USER.ACCOUNT_SETTINGS.EMAIL_CHANGE.CURRENT_EMAIL')}</label>
                    </div>
                    <div className="col-sml-7">
                        <strong>{getValues('CurrentEmail')}</strong>
                    </div>
                </div>

                <EmailChangeNewEmail
                    emailPattern={emailPattern}
                    checkForEmailAvailiability={checkForEmailAvailiability}
                />

                <FormInputField
                    className="form__field row"
                    label="USER.ACCOUNT_SETTINGS.EMAIL_CHANGE.REPEAT_NEW_EMAIL"
                    name="repeatEmail"
                    validate={{
                        isNotEqual: value => value === getValues('newEmail')
                    }}
                    pattern={{
                        value: emailPattern
                    }}
                    maxLength={150}
                    required
                />
                <div className="form__field row">
                    <div className="col-sml-11 u-type--right">
                        <FormButton
                            className="form__submit btn btn--med btn--secondary"
                            btnText='COMMON.SAVE_BUTTON'
                        />
                    </div>
                </div>
            </div>
        </form>
    )
}