import React, { useEffect, useState } from "react";
import { BettingLimitsTemplate } from "themes/pages/administration";
import { useLoading, useNotification } from "../../../common/hooks";
import { useForm } from "react-hook-form";
import { BettingLimitService } from "./services";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ErrorPage } from "../../../common/components/error";

const bettingLimitServiceInstance = new BettingLimitService();

function BettingLimits() {

    const bettingLimitService = useMemo(() => bettingLimitServiceInstance, []);

    const [fetchedData, setFetchedData] = useState([]);
    const [viewType, setViewType] = useState(null);

    const methods = useForm({ mode: "onChange" });
    const { reset } = methods;
    const { setIsLoading } = useLoading();
    const {
        showError
    } = useNotification();
    const { t } = useTranslation();

    useEffect(() => {

        App.state.rootStore.titleStore.setTitle(t('USER.ACCOUNT_SETTINGS.NAVIGATION.TITLE_BL'));

        //on mount scroll to top
        document.querySelector('html, body').scrollTo(0, 0);
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const response = await bettingLimitService.getBettingLimitsData();
                if (response.success == true) {
                    setViewType(response.data.viewType);
                    setFetchedData(response.data);
                    if (viewType != 2) {
                        reset({
                            limitType: response.data.viewData.bettingUserLimitTypeId,
                            limitDuration: response.data.viewData.bettingUserLimitDurationId,
                        });
                    } else {
                        reset({ limitAmount: response.data.viewData.limitAmount });
                    }
                }
            } catch (error) {
                if (error?.error?.message == undefined) {
                    showError(`USER.ACCOUNT_SETTINGS.ERR_HANDLING.GENERIC_ERROR`);
                }
                showError(`USER.ACCOUNT_SETTINGS.ERR_HANDLING.${error?.error?.message}`);
            } finally {
                setIsLoading(false);
            }
        }
        fetchData();
    }, [viewType]);

    const decreaseBettingLimit = async (data) => {
        return await bettingLimitService.decreaseBettingLimit(data);
    }

    const getBettingLimitLookups = async () => {
        const limitTypes = await bettingLimitService.getBettingLimitTypesLookup();
        const limitDurations = await bettingLimitService.getBettingLimitDurationLookup();
        return {
            limitTypes: limitTypes,
            limitDurations: limitDurations
        }
    }

    const deactivateBettingLimit = async (limitId) => {
        return await bettingLimitService.deactivateBettingLimit(limitId)
    }

    const updateBettingLimit = async (limitId, model) => {
        return await bettingLimitService.updateBettingLimit(limitId, model);
    }

    const insertBettingLimit = async (data) => {
        return await bettingLimitService.insertBettingLimit(data)
    }

    return (
        <ErrorPage>
            <BettingLimitsTemplate
                fetchedData={fetchedData}
                viewType={viewType}
                methods={methods}
                setViewType={setViewType}
                decreaseBettingLimit={decreaseBettingLimit}
                getBettingLimitLookups={getBettingLimitLookups}
                deactivateBettingLimit={deactivateBettingLimit}
                updateBettingLimit={updateBettingLimit}
                insertBettingLimit={insertBettingLimit}
                setFetchedData={setFetchedData}
            />
        </ErrorPage>
    )
}

export default BettingLimits;
