import React from "react";
import { useTranslation } from "react-i18next";
import { FormSelectField } from "../../../../../../common/components/inputs";
import { mapHours } from "../../../../../../pages/administration/automatic-logout/utils";


export default function AutomaticLogoutLookupsContainer(props) {

    const { fetchedData } = props;

    return (
        <React.Fragment>
            {fetchedData != undefined &&
                <React.Fragment>
                    <LogoutInterval
                        periodsLookupsResponse={fetchedData.autoLogoutTypes}
                    />
                    <BettingTimeout
                        bettingTimeoutLookupsResponse={fetchedData.bettingTimeoutTypes}
                    />
                </React.Fragment>
            }
        </React.Fragment>
    )
}

function LogoutInterval(props) {
    const { t } = useTranslation();

    const {
        periodsLookupsResponse
    } = props;

    return (
        <FormSelectField
            className="form__field row"
            name="logoutIntervalId"
            placeholder="USER.ACCOUNT_SETTINGS.AUTOMATIC_LOGOUT.AUTO_LOGOUT_INTERVAL_PLACEHOLDER"
            label={t("USER.ACCOUNT_SETTINGS.AUTOMATIC_LOGOUT.AUTO_LOGOUT_INTERVAL_LABEL")}
            dropdownData={mapHours(periodsLookupsResponse)}
            required
        />
    )
}

function BettingTimeout(props) {
    const { t } = useTranslation();

    const {
        bettingTimeoutLookupsResponse
    } = props;

    return (
        <FormSelectField
            className="form__field row"
            name="bettingTimeoutId"
            label={t("USER.ACCOUNT_SETTINGS.AUTOMATIC_LOGOUT.BETTING_TIMEOUT_LABEL")}
            dropdownData={mapHours(bettingTimeoutLookupsResponse)}
            placeholder="USER.ACCOUNT_SETTINGS.AUTOMATIC_LOGOUT.BETTING_TIMEOUT_PLACEHOLDER"
        />
    )
}