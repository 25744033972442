import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useAppContext } from "../../../../../common/hooks";

export default function AdministrationSubHeaderTemplate() {
    const { t } = useTranslation();
    const { culture } = useAppContext();
    return (
        <nav className="tabs--primary">
            <NavLink to={`/${culture}/account-settings/personal-data`}
                className="tabs--primary__item"
                activeClassName="is-active"
                isActive={(match, location) => {
                    if (location.pathname == `/${culture}/account-settings/personal-data`) {
                        return true;
                    } else if (match) {
                        return true;
                    }
                }}
            >
                <span className="tabs--primary__label">{t("USER.ACCOUNT_SETTINGS.NAVIGATION.PERSONAL_DATA")}</span>
            </NavLink>

            <NavLink to={`/${culture}/account-settings/password-change`}
                className="tabs--primary__item"
                activeClassName="is-active"
                isActive={(match, location) => {
                    if (location.pathname == `/${culture}/account-settings/password-change`) {
                        return true;
                    } else if (match) {
                        return true;
                    }
                }}
            >
                <span className="tabs--primary__label">{t("USER.ACCOUNT_SETTINGS.NAVIGATION.TITLE_PC")}</span>
            </NavLink>

            <NavLink to={`/${culture}/account-settings/email-change`}
                className="tabs--primary__item"
                activeClassName="is-active"
                isActive={(match, location) => {
                    if (location.pathname == `/${culture}/account-settings/email-change`) {
                        return true;
                    } else if (match) {
                        return true;
                    }
                }}
            >
                <span className="tabs--primary__label">{t("USER.ACCOUNT_SETTINGS.NAVIGATION.TITLE_EC")}</span>
            </NavLink>

            <NavLink to={`/${culture}/account-settings/betting-limits`}
                className="tabs--primary__item"
                activeClassName="is-active"
                isActive={(match, location) => {
                    if (location.pathname == `/${culture}/account-settings/betting-limits`) {
                        return true;
                    } else if (match) {
                        return true;
                    }
                }}
            >
                <span className="tabs--primary__label">{t("USER.ACCOUNT_SETTINGS.NAVIGATION.TITLE_BL")}</span>
            </NavLink>

            <NavLink to={`/${culture}/account-settings/account-lock`}
                className="tabs--primary__item"
                activeClassName="is-active"
                isActive={(match, location) => {
                    if (location.pathname == `/${culture}/account-settings/account-lock`) {
                        return true;
                    } else if (match) {
                        return true;
                    }
                }}
            >
                <span className="tabs--primary__label">{t("USER.ACCOUNT_SETTINGS.NAVIGATION.ACCOUNT_LOCK")}</span>
            </NavLink>

            <NavLink to={`/${culture}/account-settings/automatic-logout`}
                className="tabs--primary__item"
                activeClassName="is-active"
                isActive={(match, location) => {
                    if (location.pathname == `/${culture}/account-settings/automatic-logout`) {
                        return true;
                    } else if (match) {
                        return true;
                    }
                }}
            >
                <span className="tabs--primary__label">{t("USER.ACCOUNT_SETTINGS.NAVIGATION.AUTOMATIC_LOGOUT")}</span>
            </NavLink>
        </nav>
    )
}