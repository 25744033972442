import React from 'react';
import { observer } from 'mobx-react';

import { LiveMatchResultTemplate } from 'themes/common/components/offer/v1';

const sportsWithIndicatedScore = [
    'handball',
    'basketball'
];

@observer
class LiveMatchResult extends React.Component {

    componentDidUpdate(prevProps) {
        if (!this.props.currentScore || !prevProps.currentScore)
            return;

        // if (this.props.sportAbrv === 'soccer') {
        if (!sportsWithIndicatedScore.includes(this.props.sportAbrv)) {
            const prevScore = prevProps.currentScore;
            const currScore = this.props.currentScore;

            if (prevScore.home !== currScore.home || prevScore.away !== currScore.away) {
                this.props.toggleIsScore();

                setTimeout(this.props.toggleIsScore, 3900);
            }
        }

    }

    render() {
        return <LiveMatchResultTemplate {...this.props} />;
    }
}

export default LiveMatchResult;