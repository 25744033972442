import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "../../../../../common/components/buttons";
import { useAppContext } from "../../../../../common/hooks";

export default function FooterTemplate() {
	const { t } = useTranslation();

	const { culture } = useAppContext();

	function scrollToTop() {
		window.scrollTo(0, 0);
	}

	return (
		<div className="container">
			<div className="footer__body">
				<div className="row">
					<div className="footer__body__col">
						<div className="title--footer">
							{t("FOOTER.COMPANY")}
						</div>
						<ul>
							<li>
								<Link
									to={`/${culture}/info/about-us`}
									className="footer__body__link"
								>
									{t("FOOTER.ABOUTUS")}
								</Link>
							</li>
							<li>
								<Link
									to={`/${culture}/info/contact`}
									className="footer__body__link"
								>
									{t("FOOTER.CONTACT")}
								</Link>
							</li>
						</ul>
					</div>
					<div className="footer__body__col">
						<div className="title--footer">
							{t("FOOTER.INFORMATION")}
						</div>
						<ul>
							<Link
								to={`/${culture}/info/betting-rules`}
								className="footer__body__link"
							>
								{t("FOOTER.BET_RULES")}
							</Link>
						</ul>
					</div>
					<div className="footer__body__col">
						<img src="/assets/images/bg/18.png" alt="" />
					</div>
				</div>
			</div>

			<div className="footer__disclaimer">
				<Button
					className="btn btn--scroll-to-top"
					onClick={scrollToTop}
					type="button"
				/>
			</div>

			<div className="footer__bottom">
				<div className="u-type--center">
					© Development - {new Date().getFullYear()} by VIP BETTING,
					All Rights Reserved.
				</div>
			</div>
		</div>
	);
}
