import React from "react";
import { useTranslation } from "react-i18next";

const ButtonTemplate = ({
	btnClassName = "form__submit btn btn--med btn--secondary",
	btnContent = undefined,
	btnWithFrontIcon = undefined,
	btnText,
	btnType = "submit",
	...rest
}) => {
	const { t } = useTranslation();

	if (btnWithFrontIcon != undefined) {
		return (
			<button className={btnClassName} type={btnType} {...rest}>
				{btnWithFrontIcon}
				{t(btnText)}
			</button>
		);
	}

	return (
		<button className={btnClassName} type={btnType} {...rest}>
			{t(btnText)}
			{btnContent != undefined && btnContent}
		</button>
	);
};
export default ButtonTemplate;
