import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { OneClickBetNotificationTemplate } from 'themes/common/components/bet-slip';

@observer
class OneClickBetNotification extends React.Component {
    render() {
        return <OneClickBetNotificationTemplate {...this.props} />;
    }
}

OneClickBetNotification.propTypes = {
    onDeactivate: PropTypes.func
};

export default OneClickBetNotification;