import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import {
  MyBetsStoreContext,
  MyBetsBetContext,
} from "../../../../../common/context";
import { BetCombinationItem } from ".";
import { useFixBody } from "../../../../../common/hooks";

export default observer(function BetCombinations(props) {
  const { setShowCombinations, showCombinations } = props;

  const bet = useContext(MyBetsBetContext);
  const { initBetCombinationForBetSlip, betCombinationsMap } =
    useContext(MyBetsStoreContext);

  useEffect(() => {
    initBetCombinationForBetSlip(bet.id);
  }, []);

  useFixBody(true, showCombinations);

  if (
    !betCombinationsMap.has(bet.id) ||
    betCombinationsMap.get(bet.id) == null
  ) {
    return null;
  }

  return (
    <div className="popup--dialog">
      <div className="popup--dialog__card popup--dialog__card--secondary">
        <BetCombinationsHeader setShowCombinations={setShowCombinations} />
        <BetCombinationsBody />
        <BetCombinationsFooter setShowCombinations={setShowCombinations} />
      </div>
    </div>
  );
});

const BetCombinationsHeader = observer(function BetCombinationsHeader(props) {
  const { t } = useTranslation();
  const bet = useContext(MyBetsBetContext);

  return (
    <div className="popup--dialog__card__header">
      {t("MY_BETS.ITEM.BET_SLIP_COMBINATIONS.TITLE", { 0: bet.betSlipNumber })}
    </div>
  );
});

const BetCombinationsBody = observer(function BetCombinationsBody(props) {
  const { t } = useTranslation();
  const bet = useContext(MyBetsBetContext);
  const { betCombinationsMap } = useContext(MyBetsStoreContext);

  const combinations = betCombinationsMap.get(bet.id);

  return (
    <div className="popup--dialog__card__body">
      <table className="table--bet">
        <thead className="table--bet__subheader table--bet__subheader--secondary u-type--case--uppercase">
          <tr className="table--bet__subheader__row">
            <th className="table--bet__subheader__data u-type--center">
              {t("MY_BETS.ITEM.BET_SLIP_COMBINATIONS.ORDINAL_NUMBER")}
            </th>
            <th className="table--bet__subheader__data u-type--center">
              {t("MY_BETS.ITEM.BET_SLIP_COMBINATIONS.COMBINATION")}
            </th>
            <th className="table--bet__subheader__data u-type--center">
              {t("MY_BETS.ITEM.BET_SLIP_COMBINATIONS.TOTAL_QUOTA")}
            </th>
          </tr>
        </thead>
        <tbody className="table--bet__body">
          <BetCombinationsList combinations={combinations} />
        </tbody>
      </table>
      <div className="u-display--f u-display--f--justify-spaced u-padd--x--sml">
        <div className="u-type--color--status--open u-padd--y--sml">
          <i className="u-icon u-icon--xsml u-icon--status u-icon--status--open--circle"></i>
          {t("MY_BETS.ITEM.BET_SLIP_COMBINATIONS.OPEN")}
        </div>
        <div className="u-type--color--status--won u-padd--y--sml">
          <i className="u-icon u-icon--xsml u-icon--status u-icon--status--won--circle"></i>
          {t("MY_BETS.ITEM.BET_SLIP_COMBINATIONS.WON")}
        </div>
        <div className="u-type--color--status--lost u-padd--y--sml">
          <i className="u-icon u-icon--xsml u-icon--status u-icon--status--lost--circle"></i>
          {t("MY_BETS.ITEM.BET_SLIP_COMBINATIONS.LOST")}
        </div>
        <div className="u-type--color--status--cancelled u-padd--y--sml">
          <i className="u-icon u-icon--xsml u-icon--status u-icon--status--cancelled"></i>
          {t("MY_BETS.ITEM.BET_SLIP_COMBINATIONS.CANCELLED")}
        </div>
        <div className="u-type--color--status--returned u-padd--y--sml">
          <i className="u-icon u-icon--xsml u-icon--status u-icon--status--returned"></i>
          {t("MY_BETS.ITEM.BET_SLIP_COMBINATIONS.RETURNED")}
        </div>
      </div>
    </div>
  );
});

function BetCombinationsList(props) {
  const { combinations } = props;

  if (combinations != null && combinations.length > 0)
    return combinations.map((combination, index) => (
      <BetCombinationItem
        combination={combination}
        key={index}
        ordinalNumber={index + 1}
      />
    ));

  return null;
}

function BetCombinationsFooter(props) {
  const { setShowCombinations } = props;

  const { t } = useTranslation();

  return (
    <div className="popup--dialog__card__footer">
      <button
        className="btn btn--sml btn--secondary btn--icon-left"
        onClick={() => setShowCombinations(false)}
      >
        <i className="u-icon u-icon--xsml u-icon--remove--white"></i>
        {t("COMMON.CLOSE")}
      </button>
    </div>
  );
};
