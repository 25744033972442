/**
 * adds displayedAmount and indicator to bet
 * Adds is multi and multiEventName to bet.betSlipOffers
 * @params {object} bet 
 */
export function mapMissingPropertyToBet(bet) {
    const offerGroupsByEvent = new Map();

    for (const offer of bet.betSlipOffers) {

        const eventId = offer.eventId;

        if (!offerGroupsByEvent.has(eventId)) {
            offerGroupsByEvent.set(eventId, []);
        }

        offerGroupsByEvent.get(eventId).push(offer);

    }

    const mappedOffers = [];
    for (const eventOffers of Array.from(offerGroupsByEvent.values())) {

        if (eventOffers.length > 1) {
            for (let i = 1; i < eventOffers.length; i++) {
                eventOffers[i].isMulti = true;
                eventOffers[i].multiEventName = `${i + 1} Multi`;
            }
        }

        mappedOffers.push(...eventOffers);

    }

    bet.betSlipOffers = mappedOffers;
    bet.displayedAmount = bet.calculatedCashoutAmount?.toFixed(2);
    bet.indicator = 0;
}

/**
 * @param {array} bets list of bets
 * @returns {array} of unique event ids
 */
export function getEventIdsFromMyBets(bets) {
    const eventIds = new Set();
    bets.forEach(bet => {
        bet.betSlipOffers.forEach(offer => {
            eventIds.add(offer.eventId);
        })
    });

    return Array.from(eventIds.values())
}