import React from 'react';
import { TournamentOfferHeaderTemplate } from 'themes/pages/sports/components';

class TournamentOfferHeader extends React.Component {

    render() {
        return (<TournamentOfferHeaderTemplate {...this.props} />)
    }
}

export default TournamentOfferHeader;