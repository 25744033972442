const httpClient = App.utils.httpClient;

const requestHeaders = {
	"Content-Type": "application/json",
};
export class PersonalDataService {
	get baseUrl() {
		return `${BaseAddress}platform/agency-users`;
	}

	async updatePersonalData(model) {
		const body = JSON.stringify(model);
		return await httpClient.put(
			`${this.baseUrl}/${App.utils.getAgencyId()}/${
				App.state.rootStore.userAuthStore.user.id
			}/with-password`,
			body,
			requestHeaders,
			{ responseType: "json" }
		);
	}

	async updateUserPin(pin) {
		return await httpClient.put(
			`${this.baseUrl}/update-pin/${App.state.rootStore.userAuthStore.user.username}/${pin}`,
			null,
			requestHeaders,
			{ responseType: "json" }
		);
	}
}
