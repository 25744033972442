import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { LoginPopupTemplate } from "themes/common/components/popups";
import { LoginService } from "../../../pages/membership/login/services";
import { useUser } from "../../hooks";
import { useAppContext } from "../../hooks/useAppContext";
import { useLoading } from "../../hooks/useLoading";

const loginService = new LoginService();

export default function LoginPopup(props) {
    const user = useUser();
    const { setIsLoading } = useLoading();
    const { culture } = useAppContext();

    const methods = useForm({
        mode: "onChange", defaultValues: {
            password: ""
        }
    });

    const [loginError, setLoginError] = useState(null);

    const { reset } = methods;

    const onSuccess = async (data) => {
        setLoginError(null);
        try {
            setIsLoading(true);
            const response = await loginService.loginUser(data);
            if (response.success) {
                user.login(response.data);
                if (response.data.accountActivation != null) {
                    user.shouldDisplayAfterLogin = true;
                    user.shouldDisplayAccountActivationAfterLogin = true;
                    App.state.history.push(`/${culture}/auth/account-activation`);
                } else {
                    App.state.history.push(`/${culture}/home/full`);
                }
                reset();
                App.offer.rootStore.betSlipStore.toggleLoginPopup();
            }
        } catch (error) {
            console.error(error);
            reset();
            setLoginError(error);
        } finally {
            setIsLoading(false);
        }
    }

    return <LoginPopupTemplate
        methods={methods}
        onSuccess={onSuccess}
        loginError={loginError}
        setLoginError={setLoginError}
        {...props}
    />
}