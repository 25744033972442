import { action, computed, observable, reaction } from 'mobx';
import { Queue } from '../../../common';

export class LiveMatchTrackerState {

	//#region default options

	/**
	 * @typedef LMTConfiguration
	 * @property {boolean} disableWidgetHeader
	 * @property {boolean} disablePitch
	 * @property {string} layout
	 * @property {boolean} disableComponents
	 * @property {string} momentum
	 * @property {string} scoreboard
	 * @property {string} collapseTo
	 * @property {boolean} expanded
	 * @property {string} tabsPosition
	 * @property {string} detailedScoreboard
	 * @property {string} activeSwitcher
	 * @property {boolean} disableAds
	 * @property {string} pitchLogo
	 * @property {string} goalBannerImage
	 * @property {number} maxSportTrackers
	 * @property {number} maxTrackers
	 * @property {number} trackerPosition
	 */
	defaultOptions = {
		disableWidgetHeader: LiveMatchTrackingSettings.DisableWidgetHeader,
		disablePitch: LiveMatchTrackingSettings.DisablePitch,
		layout: LiveMatchTrackingSettings.Layout,
		disableComponents: LiveMatchTrackingSettings.DisableComponents,
		momentum: LiveMatchTrackingSettings.Momentum,
		scoreboard: LiveMatchTrackingSettings.Scoreboard,
		collapseTo: LiveMatchTrackingSettings.CollapseTo,
		expanded: LiveMatchTrackingSettings.Expanded,
		tabsPosition: LiveMatchTrackingSettings.TabsPosition,
		detailedScoreboard: LiveMatchTrackingSettings.DetailedScoreboard,
		activeSwitcher: LiveMatchTrackingSettings.ActiveSwitcher,
		disableAds: LiveMatchTrackingSettings.DisableAds,
		pitchLogo: LiveMatchTrackingSettings.PitchLogo,
		goalBannerImage: LiveMatchTrackingSettings.GoalBannerImage,
		maxSportTrackers: LiveMatchTrackingSettings.MaxSportTrackers,
		maxTrackers: LiveMatchTrackingSettings.MaxTrackers,
		trackerPosition: LiveMatchTrackingSettings.TrackerPosition,
	};

	//#endregion default options



	//#region observable 

	@observable lmtsDisabled = false;
	@observable sideWidgetMatchId = null;
	@observable unMountedWidgets = [];

	//#endregion observable 



	//#region computed

	/**
	 * @returns true when there is widget on element with 'lmt-side-container' id
	 */
	@computed
	get sideWidgetVisible() {
		const widgetContainerId = 'lmt-side-container';
		return this.eventLmts.isInQueue({ widgetContainerId }, (a, b) => a.widgetContainerId === b.widgetContainerId);;
	}

	/**
	 * @returns callback that can check if widget is loaded for selected container element and matchId
	 */
	@computed
	get activeWidgets() {
		return this.eventLmts.elements;
	}

	//#endregion computed


	//#region constructor 

	constructor() {
		const maxTrackers = LiveMatchTrackingSettings.MaxTrackers;
		this.eventLmts = new Queue(maxTrackers, (queueElement, element) => {
			return queueElement.widgetContainerId === element.widgetContainerId;
		});

		this.widgetOnContainerId.bind(this)

		this.disconnectReaction = reaction(
			() => App.state.rootStore.shouldDisconnect, 
			disconnectionFlag => {
				if (disconnectionFlag) {
					this.onDispose();
				}
			},
			{
                fireImmediately: true,
            }
		);
		// $(document).bind('status.sessionExpired', (event) => {
		// 	this.onDispose();
		// });

		if (this.isWidgetFunctionLoaded()) {
			SIR('changeLanguage', this.getCurrentLanguageForSIR())
		}
	}

	//#endregion constructor



	//#region mount and unmount of widgets

	@action.bound
	toggleWidget(widgetContainerId, matchId) {
		if (this.activeWidgets.find(widget => widget.widgetContainerId === widgetContainerId && widget.matchId === matchId)) {
			this.removeWidgetFromContainer(widgetContainerId);
		} else {
			this.addWidgetToSelectedContainer(widgetContainerId, matchId);
		}
	}

	/**
	 * Remount last side widget
	 */
	@action.bound
	remountSideWidget() {
		if (this.sideWidgetMatchId === null) return;

		this.addWidgetToSelectedContainer('lmt-side-container', this.sideWidgetMatchId);
		this.sideWidgetMatchId = null;
	}

	/**
	 * Unmounts side widget from container
	 */
	@action.bound
	unmountSideWidget() {

		this.eventLmts.clearLastRemoved();
		this.removeWidgetFromContainer('lmt-side-container');
		const widget = this.eventLmts.lastRemovedElement;
		this.eventLmts.clearLastRemoved();
		if (widget !== undefined && widget.widgetContainerId === 'lmt-side-container') this.sideWidgetMatchId = widget.matchId;
		else this.sideWidgetMatchId = null;

	}

	@action.bound
	reMountAllWidgets() {
		while (this.unMountedWidgets.length !== 0) {
			const widget = this.unMountedWidgets.pop();
			if (widget === undefined || document.getElementById(widget.widgetContainerId) === null) continue;

			this.addWidgetToSelectedContainer(widget.widgetContainerId, widget.matchId, widget.options);
		}
	}

	@action.bound
	unMountAllWidgets() {
		this.unMountedWidgets = [];
		const widgetsToUnmount = this.eventLmts.elements.filter(item => item.widgetContainerId !== 'lmt-side-container');
		while (widgetsToUnmount.length !== 0) {
			const widget = widgetsToUnmount.pop();
			if (widget === undefined) continue;
			this.unMountedWidgets.push(widget);

			this.removeWidgetFromContainer(widget.widgetContainerId);
		}

	}

	//#endregion mount and unmount of widgets



	//#region add remove widget

	/**
	 * 
	 * @param {string} widgetContainerId id of widget container
	 * @param {string} matchId sport radar match id
	 * @param {object} options optional default widget settings overrides 
	 */
	@action.bound
	addWidgetToSelectedContainer(widgetContainerId, matchId, options = {}) {

		if (this.lmtsDisabled || !this.isWidgetFunctionLoaded() || !matchId) {
			if (this.isWidgetFunctionLoaded() && widgetContainerId === 'lmt-side-container') {
				this.removeWidgetFromContainer('lmt-side-container');
			}
			return;
		}

		options = Object.assign({}, this.defaultOptions, options);

		// see: https://widgets.sir.sportradar.com/docs/Widgets.html#.onTrack
		options.onTrack = (event, data) => {
			// Error check
			if (event === 'license_error') {
				// in case of an error
				console.error(data.error);
				this.disableLmts();

			}
		}

		const SIRCallback = function () {
			App.state.logger.logInfo('LMT loaded');
		};

		if (!this.eventLmts.isInQueue({ widgetContainerId, matchId, options })) {

			this.eventLmts.clearLastRemoved();
			this.eventLmts.enqueue({ widgetContainerId, matchId });
			const removedElement = this.eventLmts.lastRemovedElement;
			if (removedElement !== undefined) this.removeWidgetFromContainer(removedElement.widgetContainerId);

			SIR('addWidget',
				'#' + widgetContainerId,
				'match.lmtPlus',
				{
					...options,
					matchId: matchId
				},
				SIRCallback
			);
		} else {

			const i = this.eventLmts.elements.findIndex(el => el.widgetContainerId === widgetContainerId)
			this.eventLmts.elements[i] = { widgetContainerId, matchId: matchId };

			SIR('updateWidget',
				'#' + widgetContainerId,
				{
					...options,
					matchId: matchId
				},
				SIRCallback
			);
		}
	}

	/**
	 * 
	 * @param {string} widgetContainerId removes widget from container
	 * @returns void
	 */
	@action.bound
	removeWidgetFromContainer(widgetContainerId) {
		if (document.getElementById(widgetContainerId) === null) {
			//console.error('You are trying to remove widget from non existing element. If there was widget on that element it may still make server requests.')
			// Container doesn't exist, can't remove widget
			return;
		}

		if (!this.isWidgetFunctionLoaded()) {
			// NOTE: we have no way of stopping request towards widget provider!
			console.error('Widget provider script is not available. Not able to close the widget');
			return;
		}

		SIR('removeWidget', '#' + widgetContainerId);
		this.eventLmts.remove({ widgetContainerId });
	}

	//#endregion  add remove widget



	//#region helpers

	/**
	 * @param {string} selector 
	 * @param {string} matchId
	 * @returns {boolean} True if widget with matchId is displayed on element with selector id
	 */
	widgetOnContainerId(widgetContainerId) {
		return this.eventLmts.isInQueue({ widgetContainerId });
	}

	/**
	 * @returns true if sport radar widget is available
	 */
	isWidgetFunctionLoaded() {
		return window.SIR !== undefined;
	}

	/** 
	 * @returns {string} language that is 
	 */
	getCurrentLanguageForSIR() {
		let lang = App.utils.getCurrentCulture()

		if(lang === "sq") {
			lang = "sqi";
		} else if( lang === "sv") {
			lang = "se";
		}
		
		return lang;
	}

	//#endregion helpers



	//#region disposers

	/**
	 * Disable lmts and disposes all active widgets
	 */
	@action.bound
	disableLmts() {
		console.warn('lmts disabled')
		this.lmtsDisabled = true;
		this.onDispose();
	}


	/**
	 * Remove all active widgets
	 */
	@action.bound
	onDispose() {
		while (!this.eventLmts.isEmpty) {
			this.eventLmts.clearLastRemoved();
			this.eventLmts.dequeue();
			const widget = this.eventLmts.lastRemovedElement;
			if (widget === undefined) continue;

			this.removeWidgetFromContainer(widget.widgetContainerId);
		}
	}

	//#endregion disposers
}
