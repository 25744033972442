import React from 'react';
import { range } from 'lodash';

import { ScoreType } from '../../../../../../administration/services/results';

export default function ScoreRendererTemplate(props) {
    const {
        scoreType,
        columnsCount,
        event: {
            id,
            fullTime,
            halfTime,
            scorePerPeriod,
            overTimeScore,
            penalties
        }
    } = props;

    return (
        <>
            <FullTimeScore fullTime={fullTime} scoreType={scoreType} />
            {scoreType !== ScoreType.FTONLY ? 
                <>
                    <ScorePerPeriod id={id} scorePerPeriod={scorePerPeriod} columnsCount={columnsCount} scoreType={scoreType} />
                    <HalfTimeScore halfTime={halfTime} scoreType={scoreType} />
                    <OverTimeScore overTimeScore={overTimeScore} penalties={penalties} />
                    <OverTimeEmpty overTimeScore={overTimeScore} scoreType={scoreType} />
                </> 
            : null}
        </>
    )
}

const FullTimeScore = function FullTimeScore(props) {
    const {
        fullTime
     } = props;

    if (fullTime != null) {
        return <td className="u-type--color--danger" style={{textAlign: 'center', fontWeight: 'bold'}}>{fullTime.home}:{fullTime.away}</td>
    }
    else {
        return <td>-</td>
    }
};

const ScorePerPeriod = function ScorePerPeriod(props) {
    const {
        scoreType,
        scorePerPeriod,
        columnsCount,
        id
    } = props;

    if (scoreType === ScoreType.PERPERIOD || scoreType === ScoreType.PERPERIODWITHOT) {
        if (scorePerPeriod != null) {
            const difference = columnsCount - scorePerPeriod.length;
            return (
                <>
                    {scorePerPeriod.map((period, idx) => {
                        return <td key={`${id}-spp-${idx}`} className="table--results__body__data table--results__body__data--period">{period.home}:{period.away}</td>
                    })}

                    {scorePerPeriod.length < columnsCount ? (
                        range(difference).map((item, idx) => {
                            return <td key={`${id}-spp-empty-${idx}`} className="table--results__body__data table--results__body__data--period">-</td>
                        })
                    ) : null}
                </>
            )
        }
        else {
            return null;
        }
    }

    return null;
}

const HalfTimeScore = function HalfTimeScore(props) {
    const {
        scoreType,
        halfTime
    } = props;

    if (scoreType === ScoreType.HEAD2HEAD || scoreType === ScoreType.HEAD2HEADWITHOT) {
        if (halfTime != null) {
            return <td className="table--results__body__data table--results__body__data--ht">{halfTime.home}:{halfTime.away}</td>
        }
        else {
            return <td className="table--results__body__data">-</td>
        }
    }

    return null;
}

const OverTimeScore = function OverTimeScore(props) {
    const {
        overTimeScore,
        penalties
    } = props;

    if (overTimeScore == null) {
        return null;
    }
    
    return <td className="table--results__body__data table--results__body__data--otap">{overTimeScore.home}:{overTimeScore.away} {penalties != null ? `(${penalties.home}:${penalties.away})` : ''}</td>
}

const OverTimeEmpty = function OverTimeEmpty(props) {
    const {
        scoreType,
        overTimeScore
    } = props;

    if (overTimeScore == null && (scoreType === ScoreType.PERPERIODWITHOT || scoreType === ScoreType.HEAD2HEADWITHOT || scoreType === ScoreType.FTONLYWITHOT)) {
        return <td className="table--results__body__data">-</td>
    }

    return null;
}