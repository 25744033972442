import React from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";

export default function AboutUsTemplate() {
    const { t } = useTranslation();

    return (
        <div id="about-us" className="view--info view--info--about">
            <h1 className="title--primary">{t("specific:INFO_PAGES.ABOUT_US.TITLE")}</h1>
            <div className="u-type--color--negative">
                <ReactMarkdown>
                    {t("specific:INFO_PAGES.ABOUT_US.CONTENT")}
                </ReactMarkdown>
                <div className="map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1450.579720086095!2d17.800369727096992!3d43.35277540724197!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0000000000000000%3A0x5f51b8389e7c1c87!2sSportska+Kladionica+Millennium+d.o.o.+Mostar!5e0!3m" width="100%" height="300" frameBorder="0" style={{ border: "0" }}></iframe>
                </div>
            </div>
        </div>
    )

}

