import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import { OfferStoreContext, LookupContext, OfferOptionsContext } from '@gp/components';

import { defaultTemplate } from '../../../../common/hoc';
import { ErrorPage } from '../../../../common/components/error';
import { SearchEmptyState } from '../../../../pages/search/components';

import { PrematchSport } from '../../../../common/components/offer/new-offer';


const SearchTemplate = defaultTemplate(function SearchTemplate(props) {

    const {
        t,
        searchViewStore,
    } = props;

    const {
        rootStore: {
            searchBarViewStore: {
                persistentTerm,
            },
            resizeStore: {
                availableColumns,
            },
        },
        onDispose,
    } = searchViewStore;

    App.state.rootStore.titleStore.setTitle(t('SEARCH.TITLE', { searchTerm: persistentTerm }));

    return (
        <ErrorPage onError={onDispose}>
            <OfferStoreContext.Provider value={searchViewStore} >
                <LookupContext.Provider value={searchViewStore.lookupsStore}>
                    <OfferOptionsContext.Provider
                        value={{
                            numberOfColumns: availableColumns,
                            scoreChangeDuration: 3500,
                            enableInlineAdditionalOffer: false,
                            isLive: false,
                            isFavorites: false,
                            isOneClickBetActive: searchViewStore.rootStore.betSlipStore.betSlipState.isOneClickBetActive,
                            onAdditionalOfferClick: (eventData) => {
                                onAdditionalOfferExpand(eventData);
                            },
                            onTipClick: (tip) => {
                                searchViewStore.rootStore.betSlipStore.addRemoveOffer(tip);
                            },
                            isTipSelected: (tipId) => {
                                if (searchViewStore.rootStore.betSlipStore.betSlipState.isOneClickBetActive) {
                                    return searchViewStore.rootStore.betSlipStore.betSlipState.isInOneClickBet(tipId);
                                }
                                return searchViewStore.rootStore.betSlipStore.isInBetSlip(tipId);
                            },
                            // TODO: add favorites
                            isEventInFavorites: eventId => false,
                            addEventToFavorites: eventId => { },
                            removeEventFromFavorites: eventId => { },
                        }}
                    >
                        <SearchPageCore />
                    </OfferOptionsContext.Provider>
                </LookupContext.Provider>
            </OfferStoreContext.Provider>
        </ErrorPage>
    )
})
export default SearchTemplate;

const SearchPageCore = observer(function SearchPageCore(props) {

    const {
        isLoading,
        isEmpty,
        rootStore: {
            searchBarViewStore: {
                persistentTerm,
            },
        },
    } = useContext(OfferStoreContext);

    if (isLoading) {
        return (
            <Loading />
        )
    }

    if (isEmpty) {
        return (
            <SearchEmptyState searchTerm={persistentTerm} />
        )
    }

    return (
        <>
            <SearchPageHeader />

            <SportsList />
        </>
    )
})

const SearchPageHeader = defaultTemplate(function SearchPageHeader(props) {

    const {
        t,
    } = props;

    const {
        rootStore: {
            searchBarViewStore: {
                persistentTerm,
                onReset: onSearchTermReset
            },
        },
    } = useContext(OfferStoreContext);

    return (
        <div className="header--search header--search--primary">
            <div>
                <i className="header--search__icon u-icon u-icon--sml u-icon--search--white" />
                {t('specific:SEARCH.SEARCH_RESULT_FOR')} <span>{persistentTerm}</span>
            </div>

            <button className="btn btn--link" type="button" onClick={onSearchTermReset}>
                <i className="u-icon u-icon--sml u-icon--remove--white" />
            </button>
        </div>
    )
})

const SportsList = observer(function SportsList(props) {

    const { } = props;
    const {
        isFetchingData,

        eventsInSports,
        
        configuration: {
            bettingTypeSelectorsStore,
        }
    } = useContext(OfferStoreContext);

    const getSportKeyId = (id, isLive, isOutright) => {
        if (isLive) {
            return `${id}-live`;
        }

        if (isOutright) {
            return `${id}-outright`;
        }

        return id;
    }

    if(isFetchingData) {
        return null;
    }

    return eventsInSports.sports.map(sport => (
        <PrematchSport
            key={getSportKeyId(sport.id, sport.isLive, sport.isOutright)}
            sport={sport}
            bettingTypeSelectorsStore={bettingTypeSelectorsStore}
        />
    ))
})

const Loading = defaultTemplate(function Loading(props) {
    const {
        t,
    } = props;

    return (
        <div className="loader">
            <div className="loader__card">
                <div className="loader__card__title">{t('COMMON.LOADING')}...</div>
                <span />
            </div>
        </div>
    )
})