import classNames from "classnames";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

import { useFixBody, useNotification } from "../../../../../common/hooks";

function PopupMessageTemplate() {
	const { closeNotification, notification } = useNotification();
	const location = useLocation();

	const { t } = useTranslation();

	useFixBody(true, notification);

	useEffect(() => {
		if (notification != null) {
			closeNotification();
		}
	}, [location]);

	if (notification == null) {
		return null;
	}

	const changeTextToUperCase = (text) => {
		return text.toUpperCase();
	};

	const typeClasses = classNames({
		"message--base message--success": notification.type == "success",
		"message--base message--warning": notification.type == "warning",
		"message--base message--error": notification.type == "error",
		"message--base message--note": notification.type == "info",
	});

	const type = typeof notification.data;

	return (
		<div className="popup">
			<div className="popup__card">
				<div className="popup__card__header">
					{changeTextToUperCase(t(`USER.POPUPS.INFO`))}
				</div>
				<div className="popup__card__body">
					<div className={typeClasses}>
						{type !== "string"
							? notification.data.map((entry) => {
									if (entry.options?.title) {
										return <h6>{t(entry.message)}</h6>;
									}
									return (
										t(entry.message, entry.options) + " "
									);
							  })
							: t(notification.data)}
					</div>
				</div>
				<div className="popup__card__footer">
					<NotificationButtons
						closeNotification={closeNotification}
						notification={notification}
					/>
				</div>
			</div>
		</div>
	);
}

export default PopupMessageTemplate;

function NotificationButtons({ notification, closeNotification }) {
	const { t } = useTranslation();

	const handleSubmit = () => {
		closeNotification();
	};

	const changeTextToUperCase = (text) => {
		return text.toUpperCase();
	};

	if (!notification.buttons) {
		return (
			<button
				className="btn btn--sml btn--primary btn--icon-left"
				onClick={handleSubmit}
			>
				<i className="u-icon u-icon--xsml u-icon--arrow-right--white"></i>
				{changeTextToUperCase(t(`USER.POPUPS.CLOSE_BTN`))}
			</button>
		);
	}

	return notification.buttons.map((notificationButton, i) => (
		<NotificationButtonWithRedirect
			key={i}
			notificationButton={notificationButton}
		/>
	));
}

function NotificationButtonWithRedirect({ notificationButton }) {
	const { t } = useTranslation();

	if (!notificationButton) {
		return null;
	}
	const { redirectFunction, btnText } = notificationButton;

	return (
		<button
			className="btn btn--sml btn--primary btn--icon-left"
			onClick={redirectFunction}
		>
			{t(btnText)}
		</button>
	);
}
