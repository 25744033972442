import React from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { LiveEventSideMenuTemplate } from 'themes/common/components/sidemenu';

@inject(stores => {
    return {
        liveEventMenuViewStore: stores.offerStore.liveEventMenuViewStore,
    }
})
@withRouter
@observer
class LiveEventSideMenu extends React.Component {
    async componentDidMount() {
        this.props.liveEventMenuViewStore.onLoad();
    }

    componentWillUnmount() {
        this.props.liveEventMenuViewStore.onDispose();
    }

    render() {
        return (
            <LiveEventSideMenuTemplate {...this.props} />
        );
    }
}

export default LiveEventSideMenu;