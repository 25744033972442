import React from "react";
import { observer, inject } from "mobx-react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";

import { Home } from "../../../pages/home";
import { Sports, SportsLanding } from "../../../pages/sports";
import { Search } from "../../../pages/search";
import { EventWrapper as Event } from "../../../pages/events";

import { defaultTemplate } from "../../../common/hoc";
import { PrematchSubheader } from "../../../common/components/subheader";
import {
	PrematchSideMenu,
	SearchBar,
} from "../../../common/components/sidemenu";
import { Results } from "../../../common/components/results";
import {
	BetSlip,
	OneClickBetNotification,
} from "../../../common/components/bet-slip";
import { NoMatchRoute } from "../../../common/components/navigation";
import { ErrorPage } from "../../../common/components/error";

@inject((stores) => ({
	rootStore: stores.offerStore,
}))
@withRouter
class PrematchRouteLayoutTemplate extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { t, rootStore, isMenuLoading } = this.props;

		return (
			<React.Fragment>
				<aside className="l--main__menu">
					<SearchBar />
					<PrematchSubheader />
					<PrematchSideMenu />
				</aside>

				<div className="l--main__content">
					{isMenuLoading ? (
						<div className="loader">
							<div className="loader__card">
								<div className="loader__card__title">
									{t("COMMON.LOADING")}...
								</div>
								<span />
							</div>
						</div>
					) : (
						<Switch>
							<Route exact path="/:culture/home/:period">
								<Home />
							</Route>
							<Route
								exact
								path="/:culture/search/:period/:searchTerm"
							>
								<Search />
							</Route>
							<Route exact path={["/:culture/sports/:period"]}>
								<SportsLanding />
							</Route>
							<Route
								exact
								path={["/:culture/sports/:period/f/:segments*"]}
							>
								<Sports />
							</Route>
							<Route
								exact
								path="/:culture/sports/:period/event/:eventIds/:segments*"
							>
								<Event />
							</Route>

							<NoMatchRoute />
						</Switch>
					)}
				</div>
				<AsideWrapper rootStore={rootStore} />
			</React.Fragment>
		);
	}
}

export default defaultTemplate(PrematchRouteLayoutTemplate);

const AsideWrapper = observer(function AsideWrapper(props) {
	const {
		rootStore,
		rootStore: {
			betSlipStore: {
				betSlipScrollStore: { currentScroll },
			},
		},
	} = props;

	return (
		<aside className="l--aside">
			<div
				id="bsWrapper"
				className="l--aside__wrapper"
				style={{ marginTop: currentScroll }}
			>
				<OneClickBetNotificationWrapper rootStore={rootStore} />
				<div
					id="lmt-side-container"
					className="widget widget--stream"
				></div>
				<BetSlip />
				<Results />
			</div>
		</aside>
	);
});

const OneClickBetNotificationWrapper = observer(
	function OneClickBetNotificationWrapper(props) {
		const {
			rootStore: {
				betSlipStore: {
					betSlipState: {
						isOneClickBetActive,
						onDeactivateOneClickBet,
					},
				},
			},
		} = props;

		if (!isOneClickBetActive) {
			return null;
		}

		return (
			<div className="oneclick--notice">
				<OneClickBetNotification
					onDeactivate={onDeactivateOneClickBet}
				/>
			</div>
		);
	}
);
