import React from "react";
import { observer } from "mobx-react";
import { Switch, Route } from "react-router-dom";

import { defaultTemplate } from "../../../common/hoc";
import { Live, LiveUpcoming } from "../../../pages/live";
import { LiveSubheader } from "../../../common/components/subheader";
import { Results } from "../../../common/components/results";
import {
	BetSlip,
	OneClickBetNotification,
} from "../../../common/components/bet-slip";

class LiveRouteLayoutTemplate extends React.Component {
	constructor(props) {
		super(props);

		this.liveViewStore = new App.offer.pages.live.LiveViewStore(
			props.rootStore
		);
		this.liveUpcomingViewStore =
			new App.offer.pages.live.LiveUpcomingViewStore(props.rootStore);
	}

	componentDidMount() {
		App.state.rootStore.titleStore.setTitle(
			this.props.t("COMMON.LIVE_BETTING")
		);
	}

	render() {
		const {
			rootStore: { betSlipStore },
		} = this.props;

		return (
			<React.Fragment>
				<div className="l--main__content l--main__content--live">
					<LiveSubheader />
					<Switch>
						<Route exact path="/:culture/live/events">
							<Live liveViewStore={this.liveViewStore} />
						</Route>
						<Route exact path="/:culture/live/upcoming">
							<LiveUpcoming
								liveUpcomingViewStore={
									this.liveUpcomingViewStore
								}
								liveViewStore={this.liveViewStore}
							/>
						</Route>
						<Route exact path="/:culture/live/my-favorites">
							<Live
								liveViewStore={this.liveViewStore}
								isFavorites
							/>
						</Route>
					</Switch>
				</div>
				<aside className="l--aside">
					<Aside betSlipStore={betSlipStore} />
				</aside>
			</React.Fragment>
		);
	}
}

export default defaultTemplate(LiveRouteLayoutTemplate);

const Aside = observer(function Aside(props) {
	const {
		betSlipStore: {
			betSlipScrollStore: { currentScroll },
			betSlipState,
		},
	} = props;

	return (
		<div
			id="bsWrapper"
			className="l--aside__wrapper"
			style={{ marginTop: currentScroll }}
		>
			<OneClickBetNotificationWrapper betSlipState={betSlipState} />
			<BetSlip />
			<Results />
		</div>
	);
});

const OneClickBetNotificationWrapper = observer(
	function OneClickBetNotificationWrapper(props) {
		const {
			betSlipState: { isOneClickBetActive, onDeactivateOneClickBet },
		} = props;

		if (!isOneClickBetActive) {
			return null;
		}

		return (
			<div className="oneclick--notice">
				<OneClickBetNotification
					onDeactivate={onDeactivateOneClickBet}
				/>
			</div>
		);
	}
);
