import React from 'react';
import { Provider, inject } from 'mobx-react';

import Home from './Home';


@inject(s => ({ offerStore: s.offerStore }))
class HomeWrapper extends React.Component {
    constructor(props) {
        super(props);

        this.homeViewStore = new App.offer.pages.home.HomeViewStore(props.offerStore);
    }

    render() {
        return (

            <Provider homeViewStore={this.homeViewStore}>
                <Home />
            </Provider>
        )
    }
}

export default HomeWrapper;