import React from "react";
import {
    CashoutHistoryModal,
    CancelBetSlipPopUp,
    CashoutCounter,
    ReuseBetConfirmationPopUp,
    CashoutConfirmationPopUp,
    CancelBetSlipMessagePopUp,
} from "./";

export default function Modals(props) {

    return (
        <>
            <CashoutHistoryModal />
            <CancelBetSlipPopUp />
            <CashoutCounter />
            <ReuseBetConfirmationPopUp />
            <CashoutConfirmationPopUp />
            <CancelBetSlipMessagePopUp />
        </>
    )
}