import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { DateTime } from "luxon";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useNotification, useLoading } from "../../../../common/hooks";
import { forgotPasswordServiceInstance } from "../services";
import { ForgotPasswordFormSectionTemplate } from "themes/pages/membership/forgot-password/components";

export default function ForgotPasswordFormSection(props) {
	const { executeRecaptcha } = useGoogleReCaptcha();

	const { showError, showSuccessWithRedirect } = useNotification();

	const { setIsLoading } = useLoading();

	const methods = useForm({
		mode: "onChange",
		defaultValues: {
			userName: "",
			dateOfBirth: DateTime.now().minus({ years: 18 }).toString(),
		},
	});

	const { reset } = methods;

	const redirectToHome = () => {
		App.state.redirect(`/${App.utils.getCurrentCulture()}/home/full`);
	};

	//TODO: add loader

	const onFormSubmit = async (formData) => {
		try {
			setIsLoading(true);
			const recaptchaToken = await executeRecaptcha(
				"requestPasswordRecovery"
			);
			const response =
				await forgotPasswordServiceInstance.requestPasswordRecovery(
					formData,
					recaptchaToken
				);
			if (response.success) {
				showSuccessWithRedirect(
					[
						{
							message:
								"MEMBERSHIP.FORGOT_PASSWORD.SUCCESSFUL_REQUEST",
						},
						{
							message:
								"MEMBERSHIP.FORGOT_PASSWORD.FP_CHECK_EMAIL",
						},
					],
					[
						{
							btnText:
								"MEMBERSHIP.FORGOT_PASSWORD.BACK_TO_HOME_PAGE",
							redirectFunction: redirectToHome,
						},
					]
				);
			}
		} catch (error) {
			reset();
			if (error?.error?.options) {
				showError([
					{
						message:
							"MEMBERSHIP.FORGOT_PASSWORD." +
							error?.error?.message,
						options: { 0: error?.error?.options },
					},
				]);
			} else {
				showError(
					"MEMBERSHIP.FORGOT_PASSWORD." + error?.error?.message
				);
			}
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<FormProvider {...methods}>
			<ForgotPasswordFormSectionTemplate onFormSubmit={onFormSubmit} />
		</FormProvider>
	);
}
