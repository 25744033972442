import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PasswordInput } from "../../../../../common/components/inputs";
import FormInputField from "../../../../../common/components/inputs/FormInputField";
import { Modal } from "../../../../../common/components/modal";
import { useFixBody } from "../../../../../common/hooks";
import { useAppContext } from "../../../../../common/hooks/useAppContext";
import { useLocation } from "react-router-dom";

export default observer(function LoginPopupTemplate(props) {
	const { t } = useTranslation();
	const { culture } = useAppContext();
	const location = useLocation();
	const { methods, onSuccess, loginError, setLoginError } = props;

	useFixBody(true, App.offer.rootStore.betSlipStore.showLoginPopup);

	//hide login popup if user changes his route(e.g. goes browser back)

	useEffect(() => {
		if (App.offer.rootStore.betSlipStore.showLoginPopup) {
			App.offer.rootStore.betSlipStore.toggleLoginPopup();
		}
	}, [location]);

	if (!App.offer.rootStore.betSlipStore.showLoginPopup) {
		return null;
	}

	const toggleLoginPopup = () => {
		App.offer.rootStore.betSlipStore.toggleLoginPopup();
		setLoginError(null);
	};

	const { handleSubmit } = methods;

	return (
		<Modal id="login-popup-container">
			<div className="popup--login">
				<div className="popup--login__card">
					<div className="popup--login__card__header">
						<div>
							<i className="u-icon u-icon--big u-icon--user u-align--v--text-top u-mar--right--xsml"></i>
							{t("MEMBERSHIP.LOGIN.TITLE")}
						</div>
						<button
							className="popup--login__card__close btn btn--link"
							type="button"
							onClick={() => toggleLoginPopup()}
						>
							<i className="u-icon u-icon--sml u-icon--remove--primary"></i>
						</button>
					</div>
					<div className="popup--login__card__body">
						<FormProvider {...methods}>
							<form
								className="form--login"
								onSubmit={handleSubmit(onSuccess)}
							>
								{/* 
                                <div id="loginErrorDiv">
                                    <div id="loginErrorMsg" className="form--login__notice"></div>
                                </div> */}
								<LoginErrorMessage loginError={loginError} />
								<div className="form--login__field">
									<FormInputField
										inputClassName="input input--base"
										placeholder="MEMBERSHIP.LOGIN.EMAIL_OR_USERNAME_LABEL"
										name="username"
										required
										noValidate
									/>
								</div>

								{/* 
                                <div className="form--login__field">

                                    <input id="loginUsername" className="input input--base" type="text" placeholder="Username" data-val="true" data-val-length="The field Username must be a string with a maximum length of 20." data-val-length-max="20" data-val-required="This field is required" maxlength="20" name="Username" value="" />
                                    <span className="message--validation u-display--none field-validation-valid" data-valmsg-for="Username" data-valmsg-replace="true"></span>
                                </div> */}
								<div className="form--login__field form__field--icon">
									<PasswordInput
										inputClassName="form__field--icon__input input input--base"
										placeholder="MEMBERSHIP.LOGIN.PASSWORD_PLACEHOLDER"
										name="password"
										noValidate
									/>
								</div>
								{/*           <div className="form--login__field form__field--icon">
                                    <input id="loginModalPwdInput" className="jqModalPasswordInput form__field--icon__input input input--base" type="password" placeholder="Password" data-val="true" data-val-length="The field Password must be a string with a maximum length of 40." data-val-length-max="40" data-val-required="This field is required" maxlength="40" name="Password" />
                                    <div className="jqTogglePwdType form__field--icon__symbol" >
                                        <i className="jqPasswordIcon u-icon u-icon--med u-icon--view--off"></i>
                                    </div>
                                </div> */}

								<div className="form--login__field">
									<button
										className="form--login__submit btn btn--sml btn--secondary"
										type="submit"
									>
										{t("MEMBERSHIP.LOGIN.LOGIN_BUTTON")}
									</button>
								</div>
							</form>
						</FormProvider>

						<div className="popup--login__card__footer">
							<div className="u-mar--bottom--micro">
								<Link
									to={`/${culture}/auth/forgot-username`}
									className="u-anchor"
									onClick={() => toggleLoginPopup()}
								>
									{t("MEMBERSHIP.LOGIN.FORGOT_USERNAME_LINK")}
								</Link>
							</div>
							<div>
								<Link
									to={`/${culture}/auth/forgot-password`}
									className="u-anchor"
									onClick={() => toggleLoginPopup()}
								>
									{t("MEMBERSHIP.LOGIN.FORGOT_PASSWORD_LINK")}
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
});

function LoginErrorMessage(props) {
	const { t } = useTranslation();
	const { loginError } = props;

	if (loginError == null) {
		return null;
	}

	if (loginError?.error?.errorCode == 400168) {
		return (
			<div className="form--login__notice">
				{t(
					`MEMBERSHIP.LOGIN.ERR_HANDLING.${loginError?.error?.message}`,
					{
						0: loginError?.error?.errorDuration,
						1: loginError?.error?.errorFailedAttempts,
					}
				)}
			</div>
		);
	} else if (loginError.error.errorCode == 400166) {
		return (
			<div className="form--login__notice">
				{t(
					`MEMBERSHIP.LOGIN.ERR_HANDLING.${loginError?.error?.message}`,
					{
						0: loginError?.error?.errorFailedAttempts,
						1: loginError?.error?.errorDuration,
					}
				)}
			</div>
		);
	}

	return (
		<div className="form--login__notice">
			{t(`MEMBERSHIP.LOGIN.ERR_HANDLING.${loginError?.error?.message}`)}
		</div>
	);
}
