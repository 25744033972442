import React, { useEffect, useMemo, useState } from "react";
import { AutomaticLogoutTemplate } from "themes/pages/administration";
import { useForm } from "react-hook-form";
import { AutomaticLogoutService } from "./services";
import { useLoading, useNotification } from "../../../common/hooks";
import { useTranslation } from "react-i18next";
import { ErrorPage } from "../../../common/components/error";

const automaticLogoutServiceInstance = new AutomaticLogoutService();

function AutomaticLogout() {
	const automaticLogoutService = useMemo(
		() => automaticLogoutServiceInstance,
		[]
	);

	const methods = useForm({ mode: "onChange" });
	const {
		reset,
		formState: { dirtyFields },
	} = methods;
	const [fetchedData, setFetchedData] = useState(null);
	const { setIsLoading } = useLoading();
	const { showSuccess, showError } = useNotification();

	const { t } = useTranslation();

	useEffect(() => {
		App.state.rootStore.titleStore.setTitle(
			t("USER.ACCOUNT_SETTINGS.NAVIGATION.AUTOMATIC_LOGOUT")
		);

		//on mount scroll to top
		document.querySelector("html, body").scrollTo(0, 0);
		const fetchData = async () => {
			try {
				setIsLoading(true);
				const response =
					await automaticLogoutService.getAutomaticLogoutData();
				if (response.success == true) {
					setFetchedData(response.data);
					reset({
						bettingTimeoutId: response.data.data.bettingTimeoutId,
						logoutIntervalId: response.data.data.logoutIntervalId,
					});
				}
			} catch (error) {
				showError(
					`USER.ACCOUNT_SETTINGS.ERR_HANDLING.${error?.error?.message}`
				);
			} finally {
				setIsLoading(false);
			}
		};
		fetchData();
	}, []);

	const onSuccess = async (data) => {
		const modelData = { ...fetchedData, ...data };
		try {
			if (Object.keys(dirtyFields).length == 0) {
				reset();
			}
			setIsLoading(true);
			const response =
				await automaticLogoutService.updateAutomaticLogoutData(
					modelData
				);
			if (response.success == true) {
				showSuccess(
					"USER.ACCOUNT_SETTINGS.AUTOMATIC_LOGOUT.AUTO_LOGOUT_SUCCESS"
				);
				setFetchedData(response.data.data);
				reset({
					bettingTimeoutId: fetchedData.data.bettingTimeoutId,
					logoutIntervalId: fetchedData.data.logoutIntervalId,
				});

				const user = JSON.parse(localStorage.getItem("user"));

				const autoLogoutId = fetchedData.autoLogoutTypes.find(
					(id) => id.id == data.logoutIntervalId
				);

				if (autoLogoutId.units === 0) {
					localStorage.removeItem("auto-logout-period");
					App.state.rootStore.automaticLogoutStore.clearTimer();
					return;
				}

				if (autoLogoutId.id != fetchedData.logoutIntervalId) {
					const autoLogoutPeriodDuration =
						new Date().getTime() +
						autoLogoutId.units * 60 * 60 * 1000;

					localStorage.setItem(
						"auto-logout-period",
						JSON.stringify(autoLogoutPeriodDuration)
					);
					user.autoLogoutPeriod = autoLogoutId.units;

					const autoLogoutDurationLeft = JSON.parse(
						localStorage.getItem("auto-logout-period")
					);
					const automaticLogoutExpiresIn =
						autoLogoutDurationLeft - new Date().getTime();
					App.state.rootStore.automaticLogoutStore.startTimer(
						automaticLogoutExpiresIn
					);
				}
			}
		} catch (error) {
			reset({
				bettingTimeoutId: fetchedData.data.bettingTimeoutId,
				logoutIntervalId: fetchedData.data.logoutIntervalId,
			});
			showError(
				`USER.ACCOUNT_SETTINGS.ERR_HANDLING.${error.error.message}`
			);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<ErrorPage>
			<AutomaticLogoutTemplate
				onSuccess={onSuccess}
				fetchedData={fetchedData}
				methods={methods}
			/>
		</ErrorPage>
	);
}

export default AutomaticLogout;
