import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

export default observer(function ResultDataTabsTemplate(props) {

    const {
        selectedDay,
        changeDay,
        isSearch,
        searchString,
        resetResultsData
    } = props;

    const { t } = useTranslation();
    
    if (isSearch) {
        return (

            <div class="header--search">
            <div>
                <i class="u-icon u-icon--sml u-icon--search"></i>
                {t('RESULTS.RESULT_CONTENT.SEARCH.SEARCH_RESULT_FOR')}<span> {searchString}</span>
            </div>

            <button class="jqClose btn btn--link" type="button"  onClick={resetResultsData}>
                <i class="u-icon u-icon--sml u-icon--remove"></i>
            </button>
        </div>
        )
    }

    return (
        <nav className='tabs--primary'>
                <a className={`tabs--primary__item ${selectedDay === 0 ? 'is-active' : ''}`} onClick={() => changeDay(0)} >
                    {t('RESULTS.RESULT_CONTENT.TODAY')}
                </a>
                <a className={`tabs--primary__item ${selectedDay === -1 ? 'is-active' : ''}`} onClick={() => changeDay(-1)} >
                    {t('RESULTS.RESULT_CONTENT.YESTERDAY')}
                </a>
        </nav>
    )
})