import { inject, observer } from 'mobx-react';
import React from 'react';
import { ResultTablesSelectedTemplate } from 'themes/pages/results/components';

export default inject(stores => ({
    resultsDataStore: stores.offerStore.resultsDataStore,
    resultsMenuStore: stores.offerStore.resultsMenuStore,
    selectedTournaments: stores.offerStore.resultsMenuStore.selectedTournaments,
    selectedTournamentsLength: stores.offerStore.resultsMenuStore.selectedTournaments.length,
    setSelectedTournaments: stores.offerStore.resultsMenuStore.setSelectedTournaments,
    openStatistics: stores.offerStore.openStatistics
}))(observer(function ResultTablesSelected(props) {
    return <ResultTablesSelectedTemplate {...props} />
}))