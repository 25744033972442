import React from 'react';
import { defaultTemplate } from '../../../../../common/hoc';

export default defaultTemplate(function (props) {
    const {
        themeStore: {
            isLightTheme,
            toggleTheme
        },
        t
    } = props;
    return (
        <div className="subheader__item">
            <label htmlFor="toggleActive" className="subheader__label">{t('SUBHEADER.THEME_SELECTOR.LABEL')}</label>
            <div className="u-display--ib">
                <input
                    id="toggleActive"
                    className="toggle"
                    onChange={e => toggleTheme()}
                    type="checkbox"
                    checked={!isLightTheme} />
                <label htmlFor="toggleActive" className="toggle-button toggle-button--regular"></label>
            </div>
        </div>
    );
});