import React from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { PrematchSideMenuTemplate } from 'themes/common/components/sidemenu';

const periods = ['full', 'today', '3d', '24h', '3h'];
const ignorePeriod = ['highlights', 'last-minute', 'most-played'];

@inject(stores => ({
    viewStore: stores.offerStore.prematchMenuViewStore,
    menu: stores.offerStore.prematchMenuViewStore.menu,
    isLoading: stores.offerStore.prematchMenuViewStore.isLoading,
    onInitialize: stores.offerStore.prematchMenuViewStore.onInitialize,
    uncheckAll: stores.offerStore.prematchMenuViewStore.uncheckAll,
    collapseTree: stores.offerStore.prematchMenuViewStore.menu.collapseTree,
    onDispose: stores.offerStore.prematchMenuViewStore.onDispose,
    expandedElementId: stores.offerStore.prematchMenuViewStore.expandedElementId,
    expandedElement: stores.offerStore.prematchMenuViewStore.expandedElement,
    isMenuExpanded: stores.offerStore.prematchMenuViewStore.isMenuExpanded,
    expandCollapseElement: stores.offerStore.prematchMenuViewStore.expandCollapseElement,
    expandCollapseMenu: stores.offerStore.prematchMenuViewStore.expandCollapseMenu,
    elementSubMenu: stores.offerStore.prematchMenuViewStore.elementSubMenu,
}))
@withRouter
@observer
class PrematchSideMenu extends React.Component {
    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        const { match: { path, params: { culture, period, segments } } } = this.props;
        let initObject = {
            culture,
            period,
            ...App.state.RouteSportSegments.parse(segments)
        };

        await this.props.onInitialize(initObject);
    }

    async componentDidUpdate(prevProps) {
        const { match: { path, params: { culture, period, segments } } } = this.props;
        const { match: { path: prevPath, params: { period: prevPeriod } } } = prevProps;

        let shouldUpdate = prevPeriod !== period;

        const updateObj = {
            culture,
            period: ignorePeriod.includes(period) ? prevPeriod : period,
            // period,
            ...App.state.RouteSportSegments.parse(segments)
        }

        if (prevPath !== path) {
            if (path.indexOf('home') > -1) {
                this.props.collapseTree();
            }
        }

        if (this.props.match.path === "/:culture/sports/:period" && segments == null && this.props.menu.hasSelected) {
            /** Reset menu if current page is sports and there are no segments in menu. */
            //this.props.resetTo(updateObj);
            this.props.uncheckAll();
        }

        if (shouldUpdate) {
            await this.props.onInitialize(updateObj);
        }
    }

    componentWillUnmount() {
        this.props.onDispose();
    }

    render() {
        return (
            <PrematchSideMenuTemplate {...this.props} />
        );
    }
}

export default PrematchSideMenu;