import React from 'react';
import { MyBetsReuseConfirmationTemplate } from 'themes/common/components/my-bets';
import { useFixBody } from '../../hooks';

export default function (props) {
    useFixBody(true, props.isOpen);
    
    return (
        <MyBetsReuseConfirmationTemplate {...props} />
    )
}
