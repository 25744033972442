import React from 'react';
import { Provider, inject } from 'mobx-react';

import Search from './Search';


@inject(s => ({ offerStore: s.offerStore }))
class SearchWrapper extends React.Component {
    constructor(props) {
        super(props);

        this.searchViewStore = new App.offer.pages.search.SearchViewStore(props.offerStore);
    }

    render() {
        return (
            <Provider searchViewStore={this.searchViewStore}>
                <Search />
            </Provider>
        )
    }
}

export default SearchWrapper;