import React from 'react';
import { observer, inject } from 'mobx-react';

import { MyBetsTemplate } from 'themes/common/components/my-bets';

@inject(stores => ({
    myBetsViewStore: stores.offerStore.myBetsViewStore
}))
@observer
class MyBets extends React.Component {
    async componentDidMount() {
        await this.props.myBetsViewStore.onInitialize();
    }

    componentWillUnmount() {
        this.props.myBetsViewStore.onDispose();
    }

    render() {
        return <MyBetsTemplate {...this.props} />
    }
}

export default MyBets;