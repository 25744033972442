import React from 'react';
import { defaultTemplate } from '../../../../../common/hoc';

import { DropDownList } from '@progress/kendo-react-dropdowns';

export default defaultTemplate(function (props) {
    const {
        timezonesStore: {
            timezones,
            isLoading,
            setSelectedTimezoneBtn,
            internalSelectedTimezone,
            selectTimezone,
            isOpen,
            onClose,
            toggleTimeZonePopup,
            utcNumber
        },
        t
    } = props;

    return (
        <React.Fragment>
            <label className="header--primary__top__timezone">
                GMT {utcNumber}
            </label>
            {isOpen &&
                <div className="popup--timezone">
                    <div className="popup--timezone__card">
                        <div className="popup--timezone__card__header">
                            <label htmlFor="timezoneId" className="popup--timezone__card__header__title">
                                <i className="u-icon u-icon--med u-icon--locale" />
                                {t('SUBHEADER.TIMEZONES.LABEL')}
                            </label>
                            <button className="btn btn--link" onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();

                                onClose();
                            }}>
                                <i className="u-icon u-icon--sml u-icon--remove--neutral" />
                            </button>
                        </div>
                        <div className="popup--timezone__card__body">
                            <div>{t('SUBHEADER.TIMEZONES.CONTENT')}</div>
                            <DropDownList
                                id="timezoneId"
                                name="timezoneId"
                                className="k-dropdown"
                                loading={isLoading}
                                data={timezones.peek()}
                                value={internalSelectedTimezone}
                                dataItemKey="id"
                                textField="name"
                                onChange={event => selectTimezone(event.target.value)}
                            />
                        </div>
                        <div className="popup--timezone__card__footer">
                            <button className="btn btn--sml btn--primary" type="button" onClick={setSelectedTimezoneBtn}>Ok</button>
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    );

});