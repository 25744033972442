import React, { useContext } from 'react';
import { BetSlipTypes } from '@gp/gp-javascript-utils';

import { defaultTemplate } from '../../../../../common/hoc';
import { BetSlipStoreContext } from '../../../../../common/context';
import { useTranslation } from 'react-i18next';

export default defaultTemplate(function (props) {
    const { t } = useTranslation();

    const {
        onBetSlipTypeChange,
        betSlipState: {
            betSlipType
        },
        isSystemEnabled = true
    } = props.store;

    return (
        <nav className="tabs--secondary">
            <button
                onClick={e => onBetSlipTypeChange(BetSlipTypes.Combination)}
                className={"tabs--secondary__item" + (betSlipType === BetSlipTypes.Combination ? " is-active" : "")}
            >
                {t('BET_SLIP.TYPE.COMBINATION')}
            </button>
            <button
                disabled={!isSystemEnabled}
                onClick={e => onBetSlipTypeChange(BetSlipTypes.System)}
                className={"tabs--secondary__item" + (betSlipType === BetSlipTypes.System ? " is-active" : "")}
            >
                {t('BET_SLIP.TYPE.SYSTEM')}
            </button>
        </nav>
    );
});