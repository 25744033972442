import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { I18nextProvider } from 'react-i18next';

import { LiveMatchTrackerContext } from './common/context';
import { GlobalLoader } from './common/components/loader';

import ReactApp from './App';

ReactDOM.render(
    <Suspense fallback={<GlobalLoader />}>
        <I18nextProvider i18n={App.state.localizationService}>
            <Provider
                stateStore={App.state.rootStore}
                offerStore={App.offer.rootStore}
            >
                <LiveMatchTrackerContext.Provider value={App.offer.rootStore.liveMatchTrackerState}>
                    <ReactApp />
                </LiveMatchTrackerContext.Provider>
            </Provider>
        </I18nextProvider>
    </Suspense>,
    document.getElementById('app')
);