import { DateTime } from "luxon";

export const mapHours = (defaultHours) => {
    if (!defaultHours) {
        return;
    }
    const newLogoutInfo = defaultHours.map(hour => {
        return {
            id: hour.id,
            name: hour.name
        }
    })
    return newLogoutInfo;
}

export const formatDateTime = (date) => {
    return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
}