import React from 'react';
import PropTypes from 'prop-types';

class NumericInputTemplate extends React.Component {
    render() {
        return (
            <input
                {...this.props}
                type="number"
            />
        )
    }
}

NumericInputTemplate.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    step: PropTypes.string,
    min: PropTypes.number
}

export default NumericInputTemplate;