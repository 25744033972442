const httpClient = App.utils.httpClient;
const requestHeaders = {
	"Content-Type": "application/json",
};
class BettingUserLimitTypesLookupService {
	/**
	 *
	 * @returns {promise}
	 */
	findLookup() {
		const url = `${BaseAddress}${App.utils.getAgencyKey()}/betting-user-limit-types?translate=true`;
		return httpClient.get(url, requestHeaders, { responseType: "json" });
	}
}

export default new BettingUserLimitTypesLookupService();
