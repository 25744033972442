const httpClient = App.utils.httpClient;

const requestHeaders = {
	"Content-Type": "application/json",
};

export class EmailChangeService {
	get baseUrl() {
		return `${BaseAddress}platform/agency-users/email-change`;
	}

	async activateEmail(emailChangeTo) {
		const body = JSON.stringify(emailChangeTo);
		return await httpClient.put(this.baseUrl, body, requestHeaders, {
			responseType: "json",
		});
	}

	async checkIsEmailAvailable(checkEmail) {
		const body = JSON.stringify(checkEmail);
		const checkEmailUrl = `${BaseAddress}platform/agency-users/email-availability`;
		return await httpClient.post(checkEmailUrl, body, requestHeaders, {
			responseType: "json",
		});
	}

	async changeUserEmail(emailChange) {
		const body = JSON.stringify(emailChange);
		return await httpClient.post(this.baseUrl, body, requestHeaders, {
			responseType: "json",
		});
	}
}
