import { DateTime } from "luxon";
import {
	Response,
	ErrorResponse,
	UserDataService,
} from "../../../../../administration/services/common";

class ForgotUsernameService {
	constructor() {
		this.userDataService = new UserDataService();
	}

	async forgotUsername(model, recaptchaToken = null) {
		try {
			const newModel = {
				dateOfBirth: DateTime.fromISO(model.dateOfBirth).toFormat(
					"MM-dd-yyyy"
				),
				email: model.email,
				challengeResponse: recaptchaToken,
			};
			await this.userDataService.forgotUsername(newModel);
			return new Response();
		} catch (error) {
			console.error(error);
			if (error.statusCode == 500) {
				const errorObject = await error.rawResponse.json();
				if (errorObject.errorCode === 400158) {
					throw new ErrorResponse({
						message: "FORGOT_USERNAME_INVALID_USERNAME_DOB",
					});
				} else if (errorObject.errorCode === 4030000000) {
					throw new ErrorResponse({
						message: "FORGOT_USERNAME_NOT_VERIFIED",
					});
				} else if (errorObject.errorCode === 400161) {
					throw new ErrorResponse({
						message: "FORGOT_USERNAME_SHOP_ADDRESS",
						options: errorObject.details,
					});
				}
			}
			throw new ErrorResponse({ message: "GENERIC_ERROR" });
		}
	}
}

const forgotUsernameServiceInstance = new ForgotUsernameService();
export { forgotUsernameServiceInstance };
