import React from 'react';
import { AdditionalOfferGroupTemplate } from 'themes/common/components/additional-offer';

class AdditionalOfferGroup extends React.Component {
    constructor(props) {
        super(props);

        this.viewStore = new App.offer.components.AdditionalOfferGroupViewStore();
    }

    render() {
        return <AdditionalOfferGroupTemplate {...this.props} viewStore={this.viewStore} />;
    }
}

export default AdditionalOfferGroup;