import React from "react";
import { SessionExpirePopupTemplate } from "themes/common/components/popups";
import { useFixBody } from "../../hooks";

export default function SessionExpirePopup(props) {
    useFixBody(true, props.isOpened);

    const stopSession = () => {
        App.state.rootStore.stopSession();
        App.state.rootStore.userAuthStore.resolveConnection();
    }

    return <SessionExpirePopupTemplate {...props} stopSession={stopSession} />
} 