import React from 'react';
import { defaultTemplate } from '../../../../../common/hoc';

import { DropDownList } from '@progress/kendo-react-dropdowns';

export default defaultTemplate(function (props) {
    const {
        oddsStore: {
            types,
            selectedOddType,
            onChange,
            isLoading
        },
        t
    } = props;

    return (
        <div className="subheader__item">
            <label htmlFor="odds" className="subheader__label">{t('SUBHEADER.ODDS.LABEL')}</label>
            <DropDownList
                name="odds"
                className="k-dropdown--primary k-dropdown--primary--odds"
                data={types}
                loading={isLoading}
                value={selectedOddType}
                dataItemKey="id"
                textField="name"
                onChange={event => onChange(event.target.value)}
            />
        </div>
    );
});