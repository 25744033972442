import React, { useEffect } from "react";
import { useWatch, useFormContext } from "react-hook-form";
import { isNullOrWhitespace } from "../../../../../utils/common/helpers";
import { EmailChangeNewEmailTemplate } from 'themes/pages/administration/email-change/components';

var timeoutId = null;

export default function EmailChangeNewEmail(props) {

    const { setError, clearErrors, getFieldState } = useFormContext();

    const newEmail = useWatch({ name: 'newEmail' });

    const { emailPattern, checkForEmailAvailiability } = props;

    useEffect(() => {
        async function checkEmail() {
            if (isNullOrWhitespace(newEmail) || getFieldState('newEmail').invalid) return;

            if (timeoutId != null) {
                clearTimeout(timeoutId);
            }

            //this error is set so user can't submit the form until request has finished
            setError('newEmail', { type: 'wait' });

            timeoutId = setTimeout(async () => {
                const isMailAvailable = await checkForEmailAvailiability(newEmail);
                if (!isMailAvailable) {
                    setError('newEmail', { type: 'isAvailable', message: 'USER.ACCOUNT_SETTINGS.EMAIL_CHANGE.EMAIL_EXISTS' });
                }
                else {
                    clearErrors('newEmail');
                }
            }, 500);
        }

        checkEmail();
    }, [newEmail]);

    return <EmailChangeNewEmailTemplate
        emailPattern={emailPattern}
        newEmail={newEmail}
    />
}