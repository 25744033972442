import {observer, inject} from 'mobx-react';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { BetSlipCombinationModalTemplate } from 'themes/common/components/bet-slip';

@inject(stores => ({
    betSlipStore: stores.offerStore.betSlipStore,
}))
@observer
@withRouter
class BetSlipCombinationModal extends React.Component {    
    render() {        
        return <BetSlipCombinationModalTemplate {...this.props} />
    }
}


export default BetSlipCombinationModal;