import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function LoginStatusMessageTemplate() {

    const { search } = useLocation();
    const { t } = useTranslation();

    const messageDisplayType = search.substring(
        search.indexOf("?") + 1,
        search.indexOf("=")
    )

    switch (messageDisplayType) {
        case "isUserLockedOut":
            return (
                <div className="message--base message--session">{t("MEMBERSHIP.LOGIN.ENTERIES_INCORRECT_PASSWORD", { 0: 5 })}</div>
            );
        case "isChangePasswordFailed":
            return (
                <div className="message--base message--session">{t("MEMBERSHIP.LOGIN.ENTERIES_INCORRECT_PASSWORD", { 0: 5 })}</div>
            );
        case "isSessionExpired":
            return (
                <div className="message--base message--session">{t("MEMBERSHIP.LOGIN.SESSION_EXPIRED")}</div>
            );

        case "isSessionTerminated":
            return (
                <div className="message--base message--session">{t("MEMBERSHIP.LOGIN.SESSION_TERMINATED")}</div>
            );
        case "isActivatingAccount":
            return (
                <div className="row u-mar--bottom--med">
                    <div className="col col-sml-12">
                        <div className="message--base message--warning">{t("MEMBERSHIP.LOGIN.ACTIVATING_ACCOUNT_MESSAGE")}</div>
                    </div>
                </div>
            );

        case "isAutomaticLogout":
            return (
                <div className="message--base message--session">{t("MEMBERSHIP.LOGIN.AUTOMATIC_LOGOUT_MESSAGE")}</div>
            );

        default:
            return <h1 className="message--base message--session">{t("MEMBERSHIP.LOGIN.PLEASE_LOGIN")}</h1>
    }

}