import React, { useMemo, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';

import { MyBetsBetDetailsPageTemplate } from 'themes/pages/my-bets';
import { BetDetailsStore } from '../../../administration/pages/my-bets';

export default inject(stores => ({
    rootStore: stores.offerStore,
}))(observer(function MyBetsBetDetailsPage(props) {

    const {
        rootStore,
    } = props;

    const params = useParams();
    const { t } = useTranslation();
    const betDetailsStore = useMemo(() => new BetDetailsStore(rootStore), []);

    useEffect(
        () => {
            App.state.rootStore.titleStore.setTitle(t('MY_BETS.PAGE.TITLE'));
            betDetailsStore.onInitialize();
            return () => {
                betDetailsStore.onDispose();
            }
        }
        , []
    );

    useEffect(
        () => {
            if (betDetailsStore.isStoreInitialized) {
                betDetailsStore.getBetDetails(params.betId);
            }
        }, [params.betId, betDetailsStore.isStoreInitialized]);

    return <MyBetsBetDetailsPageTemplate {...props} betDetailsStore={betDetailsStore} />
}))