import React from 'react';

export default function GlobalLoaderTemplate(props) {

    return (
        <div className="loader">
            <div className="loader__spinner">
                <div className="loader__spinner__wrapper">
                    <div />
                    <div />
                    <div />
                </div>
            </div>
        </div>
    );
};