import React, { useEffect, useState } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { ForgotPasswordTemplate } from "themes/pages/membership/forgot-password";
import { useTranslation } from "react-i18next";
import { ErrorPage } from "../../../common/components/error";
import { useNotification } from "../../../common/hooks";

export default function ForgotPassword() {
	const { t } = useTranslation();
	const { closeNotification } = useNotification();

	useEffect(() => {
		App.state.rootStore.titleStore.setTitle(
			t("MEMBERSHIP.FORGOT_PASSWORD.TITLE")
		);

		//on mount scroll to top
		document.querySelector("html, body").scrollTo(0, 0);
		return () => closeNotification();
	}, []);

	const [isForgotPasswordSuccess, setIsForgotPasswordSuccess] =
		useState(false);

	return (
		<ErrorPage>
			<GoogleReCaptchaProvider
				language={App.utils.getCurrentLanguage()}
				reCaptchaKey={ReCaptchaChallengeKey}
			>
				<ForgotPasswordTemplate
					isForgotPasswordSuccess={isForgotPasswordSuccess}
					setIsForgotPasswordSuccess={setIsForgotPasswordSuccess}
				/>
			</GoogleReCaptchaProvider>
		</ErrorPage>
	);
}
