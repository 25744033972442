import React, { useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInputFieldCurrency, FormSelectField } from "../../../../../../common/components/inputs";
import { Loader } from "../../../../../../common/components/loader";
import { useLoading, useNotification } from "../../../../../../common/hooks";
import {
    mapData
} from "../../../../../../pages/administration/my-limits/utils";
import { FormButton } from "../../../../../../common/components/buttons";



export default function InsertLimitSection(props) {
    const { t } = useTranslation();
    const [lookups, setLookups] = useState({
        limitDurations: [],
        limitTypes: [],
    });

    const {
        showError,
        showSuccess,
        showWarning
    } = useNotification();

    const {
        isLoading,
        setIsLoading
    } = useLoading();

    const {
        setViewType,
        methods,
        getBettingLimitLookups,
        insertBettingLimit,
        setFetchedData
    } = props;

    const { handleSubmit } = methods;


    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true)
                const fetchedLookups = await getBettingLimitLookups();
                if (fetchedLookups != null) {
                    setLookups({ limitTypes: fetchedLookups.limitTypes.item, limitDurations: fetchedLookups.limitDurations.item });
                }
            } catch (error) {
                showError(`USER.ACCOUNT_SETTINGS.ERR_HANDLING.${error?.error?.message}`);
            } finally {
                setIsLoading(false);
            }
        }
        fetchData();
    }, []);

    const onSuccess = async (data) => {
        try {
            setIsLoading(true);
            const response = await insertBettingLimit(data);

            if (response.success == true) {
                setViewType(2);
                setFetchedData(response.data.data);
                showSuccess("USER.ACCOUNT_SETTINGS.MY_LIMITS.LIMIT_INSERT_SUCCESS");
            }
        } catch (error) {
            setViewType(4)
            showWarning("USER.ACCOUNT_SETTINGS.MY_LIMITS.LIMIT_INSERT_FAIL");
        } finally {
            setIsLoading(false);
        }
    }

    return (

        <FormProvider {...methods}>
            <Loader isLoading={isLoading} />
            <form onSubmit={handleSubmit(onSuccess)}>
                <div className="form__group">

                    <div className="message--base message--note u-mar--bottom--xmed">
                        {t("USER.ACCOUNT_SETTINGS.MY_LIMITS.NO_LIMIT")}
                    </div>

                    <FormSelectField
                        className="form__field row"
                        name="limitType"
                        placeholder={t("USER.ACCOUNT_SETTINGS.MY_LIMITS.LIMIT_TYPE_PLACEHOLDER")}
                        label={t("USER.ACCOUNT_SETTINGS.MY_LIMITS.LIMIT_TYPE")}
                        dropdownData={mapData(lookups.limitTypes)}
                        required
                    />

                    <FormSelectField
                        className="form__field row"
                        name="limitDuration"
                        placeholder="USER.ACCOUNT_SETTINGS.MY_LIMITS.LIMIT_DURATION_PLACEHOLDER"
                        label={t("USER.ACCOUNT_SETTINGS.MY_LIMITS.LIMIT_DURATION_LABEL")}
                        dropdownData={mapData(lookups.limitDurations)}
                        required
                    />

                    <FormInputFieldCurrency
                        label='USER.ACCOUNT_SETTINGS.MY_LIMITS.LIMIT_AMOUNT_LABEL'
                        name="limitAmount"
                        type="number"
                        className="form__field row"
                        pattern={{
                            value: /^0*[1-9][0-9]*(\.[0-9]+)?/
                        }}
                        maxLength={10}
                        required
                    />

                    <div className="form__field row">
                        <div className="col-sml-11 u-type--right">
                            <FormButton
                                className="form__submit btn btn--med btn--secondary"
                                btnText="USER.ACCOUNT_SETTINGS.MY_LIMITS.SAVE_BUTTON"
                            />
                        </div>
                    </div>
                </div>
            </form>
        </FormProvider>
    )
}