const httpClient = App.utils.httpClient;

const requestHeaders = {
	"Content-Type": "application/json",
};

export class PasswordChangeService {
	async changeUserPassword(model) {
		const user = JSON.parse(localStorage.getItem("user"));
		const userName = user.username;
		const body = JSON.stringify(model);
		return await httpClient.put(
			`${BaseAddress}platform/agency-users/${App.utils.getAgencyId()}/${userName}/verify-and-change-password`,
			body,
			requestHeaders,
			{ responseType: "json" }
		);
	}
}
