import React, { useContext } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";

import { Modal } from "../../../../../common/components/modal";
import { useCurrency, useFixBody } from "../../../../../common/hooks";
import { MyBetsStoreContext } from "../../../../../common/context";

export default observer(function CashoutHistoryModal(props) {
  const {
    cashoutStore: {
      isCashoutHistoryOpen,
      cashoutHistory,
    },
  } = useContext(MyBetsStoreContext);

  const { t } = useTranslation();
  useFixBody(true, isCashoutHistoryOpen);
  if (!isCashoutHistoryOpen || cashoutHistory == null) {
    return null;
  }

  return (
      <Modal>
        <div className="popup">
          <div className="popup--dialog__card">
            <div className="popup--dialog__card__header">
              <div className="popup--dialog__card__header u-type--base">
                {t("MY_BETS.ITEM.CASHOUT_HISTORY")}
              </div>
            </div>
            <CashoutHistoryBody />
            <CloseCashoutHistory />
          </div>
        </div>
        </Modal>
  );
});

function CashoutHistoryBody(props) {
  return (
    <div className="popup--dialog__card__body">
      <table className="table--bet">
        <thead className="table--bet__subheader table--bet__subheader--secondary u-type--case--uppercase">
          <CashoutHistoryTableHeaderRow />
        </thead>
        <tbody className="table--bet__body">
          <CashoutHistoryTableRows />
        </tbody>
      </table>
    </div>
  );
}

function CashoutHistoryTableHeaderRow(props) {
  const { t } = useTranslation();

  // TODO translation for header

  return (
    <tr className="table--bet__subheader__row">
      <th className="table--bet__subheader__data u-type--case--uppercase">
        {t("MY_BETS.ITEM.ORDINAL_NUMBER")}
      </th>
      <th className="table--bet__subheader__data u-type--case--uppercase">
        {t("MY_BETS.ITEM.INFO_DATE")}
      </th>
      <th className="table--bet__subheader__data u-type--right u-type--case--uppercase">
        {t("MY_BETS.ITEM.CASHOUT_VALUE")}
      </th>
    </tr>
  );
}

const CashoutHistoryTableRows = observer(function CashoutHistoryTableRows(
  props
) {
  const {
    cashoutStore: {
      cashoutHistory: { betSlipCashoutHistoryValues },
    },
  } = useContext(MyBetsStoreContext);

  return betSlipCashoutHistoryValues.map((value, index) => (
    <CashoutHistoryTableRow
      key={value.timestamp}
      count={index + 1}
      rowData={value}
    />
  ));
});

const CashoutHistoryTableRow = observer(function CashoutHistoryTableRow(props) {
  const {
    count,
    rowData: { timestamp, cashoutValue },
  } = props;

  const { currency } = useCurrency();

  const time = DateTime.fromISO(timestamp)
    .toLocal()
    .toLocaleString(DateTime.DATETIME_SHORT)
    .split(",")
    .join(",");

  return (
    <tr className="table--bet__body__row table--bet__body__row--secondary">
      <td className="table--bet__body__data">{count}</td>
      <td className="table--bet__body__data">{time}</td>
      <td className="table--bet__body__data u-type--right">
        {cashoutValue} {currency}
      </td>
    </tr>
  );
});

function CloseCashoutHistory(props) {
  const {
    cashoutStore: { closeCashoutHistory },
  } = useContext(MyBetsStoreContext);
  const { t } = useTranslation();

  return (
    <div className="popup--dialog__card__footer">
      <button
        className="btn btn--sml btn--secondary btn--icon-left"
        type="button"
        onClick={closeCashoutHistory}
      >
        <i className="u-icon u-icon--xsml u-icon--remove--white"></i>
        {t("COMMON.CLOSE")}
      </button>
    </div>
  );
}
