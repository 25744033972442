import React from 'react';
import { Provider, inject } from 'mobx-react';

import Sports from './Sports';

@inject(s => ({ offerStore: s.offerStore }))
class SportsWrapper extends React.Component {
    constructor(props) {
        super(props);

        this.sportsViewStore = new App.offer.pages.sports.SportsViewStore(props.offerStore);
    }

    render() {
        return (
            <Provider sportsViewStore={this.sportsViewStore}>
                <Sports />
            </Provider>
        )
    }
}

export default SportsWrapper;