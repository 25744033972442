import React from 'react';
import { useFormContext } from 'react-hook-form';
import { DatePicker } from '../../../../../../common/components/inputs';

export default function AccountStatementCustomDateTemplate() {

    const { getValues } = useFormContext();

    return (
        <div>

            <DatePicker
                label='ACC_STATEMENT.FILTER.START_DATE'
                name='startDate'
                className='form__field row'
                required
            />

            <DatePicker
                label='ACC_STATEMENT.FILTER.END_DATE'
                name='endDate'
                className='form__field row'
                validate={{
                    isBiggerThanStartDate: (value) => value > getValues('startDate') || 'COMMON.ERROR_MESSAGES.END_DATE_LOWER'
                }}
                required
            />

        </div>
    )
}