import {
	Response,
	ErrorResponse,
} from "../../../../../administration/services/common";
import { UserDataService } from "../../../../../administration/services/common";

class RecoverPasswordService {
	constructor() {
		this.userDataService = new UserDataService();
	}

	async checkPasswordRecoveryToken(passwordRecoveryToken, username) {
		if (passwordRecoveryToken == null || username == null) {
			throw new ErrorResponse({
				message:
					"MEMBERSHIP.FORGOT_PASSWORD.PASSWORD_RECOVERY_TOKEN_INVALID_OR_EXPIRED",
			});
		}

		try {
			await this.userDataService.checkPasswordRecoveryToken(
				passwordRecoveryToken,
				username
			);
		} catch (error) {
			if (
				error?.data?.errorCode == 400163 ||
				error?.data?.errorCode == 4040020000
			) {
				throw new ErrorResponse({
					message:
						"MEMBERSHIP.FORGOT_PASSWORD.PASSWORD_RECOVERY_TOKEN_INVALID_OR_EXPIRED",
				});
			}
			throw new ErrorResponse({
				message:
					"MEMBERSHIP.FORGOT_PASSWORD.PASSWORD_RECOVERY_REQUEST_ERROR",
			});
		}
	}

	async recoverPassword(token, username, formData) {
		try {
			await this.userDataService.recoverPassword({
				passwordRecoveryToken: token,
				username: username,
				...formData,
			});
			return new Response();
		} catch (error) {
			if (error.statusCode == 500) {
				const errorObject = await error.rawResponse.json();
				if (errorObject?.errorCode === 400163) {
					throw new ErrorResponse({
						message: "PASSWORD_RECOVERY_TOKEN_INVALID_OR_EXPIRED",
					});
				} else if (errorObject?.errorCode === 400165) {
					throw new ErrorResponse({
						message: "ERROR_PWD_CHANGE_SIMILAR",
					});
				} else if (
					errorObject?.errorCode === 400170 ||
					errorObject?.errorCode === 400171
				) {
					throw new ErrorResponse({
						message: "ERROR_PWD_CHANGE_OLD_SAME",
					});
				}
			}
			throw new ErrorResponse({ message: "PASSWORD_RECOVERY_ERROR" });
		}
	}
}

const recoverPasswordServiceInstance = new RecoverPasswordService();
export { recoverPasswordServiceInstance };
