import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNotification, useLoading } from "../../../common/hooks";
import { recoverPasswordServiceInstance } from "./services";
import { RecoverPasswordTemplate } from "themes/pages/membership/recover-password";
import { ErrorPage } from "../../../common/components/error";

export default function RecoverPassword() {
	const [isRecoverPasswordSuccess, setIsRecoverPasswordSuccess] =
		useState(false);
	const { showErrorWithRedirect, closeNotification } = useNotification();
	const { setIsLoading } = useLoading();
	const { t } = useTranslation();

	const urlParams = new URLSearchParams(App.state.history.location.search);

	useEffect(() => {
		App.state.rootStore.titleStore.setTitle(
			t("MEMBERSHIP.FORGOT_PASSWORD.TITLE")
		);

		//on mount scroll to top
		document.querySelector("html, body").scrollTo(0, 0);

		checkAreParamsValid();

		return () => closeNotification();
	}, []);

	const redirectToHome = () => {
		App.state.redirect(`/${App.utils.getCurrentCulture()}/home/full`);
	};

	const redirectToLogin = () => {
		App.state.redirect(`/${App.utils.getCurrentCulture()}/auth/login`);
	};

	async function checkAreParamsValid() {
		try {
			setIsLoading(true);
			const tokenFromURL = urlParams.get("passwordRecoveryToken");
			const userNameFromURL = urlParams.get("username");
			await recoverPasswordServiceInstance.checkPasswordRecoveryToken(
				tokenFromURL,
				userNameFromURL
			);
		} catch (error) {
			showErrorWithRedirect(error?.error?.message, [
				{
					btnText: "MEMBERSHIP.RECOVER_PASSWORD.BACK_TO_HOME_PAGE",
					redirectFunction: redirectToHome,
				},
				{
					btnText: "MEMBERSHIP.RECOVER_PASSWORD.LOGIN",
					redirectFunction: redirectToLogin,
				},
			]);
		} finally {
			setIsLoading(false);
		}
	}

	return (
		<ErrorPage>
			<RecoverPasswordTemplate
				isRecoverPasswordSuccess={isRecoverPasswordSuccess}
				setIsRecoverPasswordSuccess={setIsRecoverPasswordSuccess}
			/>
		</ErrorPage>
	);
}
