import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { AboutUsTemplate } from "themes/pages/info-pages";

export default function AboutUs() {
    const { t } = useTranslation();

    useEffect(() => {
        App.state.rootStore.titleStore.setTitle(t('INFO_PAGES.NAVIGATION.ABOUT_US'));

        document.querySelector('html, body').scrollTo(0, 0);
    }, []);

    return (
        <AboutUsTemplate />
    )
}
