import React from "react";
import { Loader } from "../../../../../common/components/loader";
import { FormProvider } from "react-hook-form";
import { PasswordChangeFormSection } from "../../../../../pages/administration/password-change/components";

export default function PasswordChangeTemplate(props){
    const { methods, onSubmitData, userInfo} = props;
    const { formState : { isSubmitting }} = methods;

    return (
        <>
            <Loader isLoading = {isSubmitting} />
            <FormProvider {...methods} >

                <PasswordChangeFormSection 
                    onSubmitData = {onSubmitData} 
                    userInfo = {userInfo}
                />
                
            </FormProvider>
        </>
    )
}