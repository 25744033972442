import React, { useEffect, useContext } from "react";
import { observer } from "mobx-react";
import { useParams, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";

import {
	OfferStoreContext,
	OfferOptionsContext,
	LookupContext,
} from "@gp/components";

import { LiveSports } from "../../../../common/components/offer/new-offer";
import { defaultTemplate } from "../../../../common/hoc";

export default observer((props) => {
	const { liveViewStore } = props;

	const {
		rootStore: {
			resizeStore: { availableColumns },
		},
	} = liveViewStore;

	const { culture } = useParams();

	useEffect(() => {
		if (!liveViewStore.rootStore.hub.isStarted) {
			return;
		}

		liveViewStore.onInitialize();

		return () => {
			liveViewStore.onDispose();
		};
	}, [liveViewStore.rootStore.hub.isStarted]);

	return (
		<>
			<OfferStoreContext.Provider value={liveViewStore}>
				<LookupContext.Provider value={liveViewStore.lookupsStore}>
					<OfferOptionsContext.Provider
						value={{
							numberOfColumns: availableColumns,
							scoreChangeDuration: 3500,
							enableInlineAdditionalOffer: false,
							isLive: liveViewStore.isLive,
							isFavorites: liveViewStore.displayFavorites,
							isFavoritesEnabled:
								liveViewStore.isFavoritesEnabled,
							isOneClickBetActive:
								liveViewStore.rootStore.betSlipStore
									.betSlipState.isOneClickBetActive,
							onAdditionalOfferClick: (eventId) => {
								App.state.history.push(
									`/${culture}/live/events/event/${eventId}`
								);
							},
							onTipClick: (tip) => {
								liveViewStore.rootStore.betSlipStore.addRemoveOffer(
									tip
								);
							},
							isTipSelected: (tipId) => {
								if (
									liveViewStore.rootStore.betSlipStore
										.betSlipState.isOneClickBetActive
								) {
									return liveViewStore.rootStore.betSlipStore.betSlipState.isInOneClickBet(
										tipId
									);
								}
								return liveViewStore.rootStore.betSlipStore.isInBetSlip(
									tipId
								);
							},
							isEventInFavorites:
								liveViewStore.configuration.favoritesStore
									.isInFavorites,
							addEventToFavorites: (eventId) => {
								liveViewStore.configuration.favoritesStore.add(
									eventId
								);
								// check if all non favorite events are added, if yes, redirect to my favorites tab
								if (!liveViewStore.hasVisibleEvents) {
									App.state.history.push(
										`/${culture}/live/my-favorites`
									);
								}
							},
							removeEventFromFavorites: (eventId) => {
								liveViewStore.configuration.favoritesStore.remove(
									eventId
								);
								// check if all favorite events are removed, if yes, redirect to live list
								if (!liveViewStore.hasVisibleEvents) {
									App.state.history.push(
										`/${culture}/live/events`
									);
								}
							},
							bettingTypeSelectorsStore:
								liveViewStore.configuration
									.bettingTypeSelectorsStore,
						}}
					>
						<LiveTemplateCore />
					</OfferOptionsContext.Provider>
				</LookupContext.Provider>
			</OfferStoreContext.Provider>
			<ReactTooltip
				id="bt-description"
				className="tooltip--primary"
				place="bottom"
				multiline={true}
			/>
			<ReactTooltip
				id="team-description"
				className="tooltip--tertiary"
				place="bottom"
				multiline={true}
			/>
			<ReactTooltip
				id="match-period-score"
				className="tooltip--primary tooltip--primary--sml u-padd--reset"
				place="left"
				multiline={true}
				getContent={(data) => {
					if (data == null || data === "") {
						return null;
					}

					// data is actually eventId
					const event = liveViewStore.eventsMap.get(data);

					if (
						event == null ||
						event.result == null ||
						event.result?.scorePerPeriod == null
					) {
						return null;
					}

					return (
						<>
							{event.result?.scorePerPeriod.map((score, idx) => (
								<div key={idx}>
									<div className="tooltip__header">
										{`P${idx + 1}`}
									</div>
									<div className="tooltip__body">
										{`${score.home ? score.home : 0}:${
											score.away ? score.away : 0
										}`}
									</div>
								</div>
							))}
						</>
					);
				}}
			/>
		</>
	);
});

const LiveTemplateCore = observer(function LiveTemplateCore(props) {
	const { isLoading, isEmpty, sportsToDisplay } =
		useContext(OfferStoreContext);

	if (isLoading) {
		return <Loader />;
	}

	// render empty state if there are no events
	if (isEmpty) {
		return <Empty />;
	}

	return (
		<>
			<FavoritesHeader />
			<LiveSports sports={sportsToDisplay} />
		</>
	);
});

const FavoritesHeader = defaultTemplate(function FavoritesHeader(props) {
	const { t } = props;

	const { displayFavorites, activeFavoritesCount } =
		useContext(OfferStoreContext);

	if (!displayFavorites) {
		return null;
	}

	return (
		<div className="header--favorites">
			<i className="u-icon u-icon--big u-icon--favorites--white" />
			<div className="header--favorites__title">
				{t("LIVE.SIDEMENU.FAVORITES")}
			</div>
			<span className="counter">{activeFavoritesCount}</span>
		</div>
	);
});

const Loader = defaultTemplate(function Loader(props) {
	const { t } = props;

	return (
		<div className="loader">
			<div className="loader__card">
				<div className="loader__card__title">
					{t("COMMON.LOADING")}...
				</div>
				<span />
			</div>
		</div>
	);
});

const Empty = defaultTemplate(function Empty(props) {
	const { t } = props;

	const { displayFavorites } = useContext(OfferStoreContext);

	return (
		<div className="message--sml message--note">
			{displayFavorites ? <FavoritesEmpty /> : <LiveEmpty />}
		</div>
	);
});

const FavoritesEmpty = defaultTemplate(function FavoritesEmpty(props) {
	const { t } = props;

	return (
		<div>
			{t("LIVE.FAVORITES.NO_EVENTS")}{" "}
			{t("LIVE.FAVORITES.MY_LIVE_FAVORITES")}.
			{t("LIVE.FAVORITES.CLICK_ON")}{" "}
			<i className="u-icon u-icon--big u-icon--favorites--inactive" />{" "}
			{t("LIVE.FAVORITES.TO_ADD_EVENT")}
			<i className="u-icon u-icon--big u-icon--favorites" />{" "}
			{t("LIVE.FAVORITES.REMOVE_EVENT")}
		</div>
	);
});

const LiveEmpty = defaultTemplate(function LiveEmpty(props) {
	const { t } = props;

	return <div>{t("LIVE.NO_EVENTS")}</div>;
});
