import { DateTime } from "luxon";

export const removeUndefinedFields = (data, fetchedData) => {
    const formData = {};
    const newObject = {};

    Object.keys(data).map(fieldName => {
        if (data[fieldName] !== undefined) {
            formData[fieldName] = data[fieldName]
        }
    })

    return Object.assign(newObject, fetchedData.data, formData);
}

export const mapDataForPasswordConfirmation = (data, dirtyFields, fetchedData) => {

    const processedData = processDirtyFields(data, dirtyFields);

    const dataEntries = Object.entries(processedData);

    const mappedData = dataEntries.map(entry => {
        //add icon for + and -
        if (entry[1] === true) {
            entry[1] = "+";
        }
        else if (entry[1] === false) {
            entry[1] = '-';
        }
        else if (entry[0] === 'dob') {
            entry[1] = DateTime.fromISO(entry[1]).toLocaleString(DateTime.DATE_SHORT);
        }
        else if (entry[0] === 'receiveNewsletter') {
            return {
                "name": 'specific:USER.ACCOUNT_SETTINGS.PERSONAL_DATA.NEWSLETTER_LABEL',
                "value": entry[1]
            }
        }
        return {
            "name": 'USER.ACCOUNT_SETTINGS.PERSONAL_DATA.' + entry[0].toUpperCase(),
            "value": entry[1]
        }
    })

    const getIDValues = changeIdToName(mappedData, fetchedData);
    return getIDValues;
}

export const mapLanguages = (defaultLangs) => {
    if (!defaultLangs) {
        return;
    }
    return defaultLangs.map(lang => {
        return {
            id: lang.culture,
            name: lang.name
        }
    });
}

export const filteredLanguageList = (supportedLanguages, allLanguages) => {
    return allLanguages.filter(lookupLang => supportedLanguages.some(supportedLang => lookupLang.iso === supportedLang.iso.toUpperCase()));
}

const processDirtyFields = (data, dirtyFields) => {
    const touchedFields = {};
    Object.keys(dirtyFields).map(fieldName => {
        touchedFields[fieldName] = data[fieldName];
    })
    return touchedFields;
}

const changeIdToName = (data, fetchedData) => {
    const changedData = data.map(x => {
        const getName = fetchedData.languages.find(lang => lang.culture === x.value) || fetchedData.userTitles.find(title => title.id === x.value) || fetchedData.countries.find(country => country.id === x.value);
        if (getName && getName.name) {
            x.value = getName.name;
        }
        return x;
    })
    return changedData;
}