import React from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import classNames from 'classnames';
import { Button } from '../../../../../../common/components/buttons';

export default function AccountStatementTransactionDetailsTemplate(props) {

    const { t } = useTranslation();

    const { setShowDetails, slipDetails } = props;

    const {
        paymentMethod,
        amount,
        currency,
        transactionStatus,
        ccMaskedPan,
        ccExpiryDate,
        startDate,
        endDate
    } = slipDetails;

    const paymentMethodIcon = classNames(
        "u-icon u-icon--payment u-mar--right--sml",
        [`u-icon--payment--${paymentMethod.abrv.toLowerCase()}`]
    )

    return (
        <div className="popup--dialog">
            <div className="popup--dialog__card">
                <div className="popup--dialog__card__header">
                    {t('ACC_STATEMENT.TRANS_DETAILS.TRANSACTION_DETAILS')}
                </div>
                <div className="popup--dialog__card__body">
                    <table className="table--primary">
                        <tbody className="table--primary__body">
                            <tr className="table--primary__body__row">
                                <td colSpan="7" className="table--bet__details">
                                    <div className="table--bet__details__row">
                                        <div className="table--bet__details__col u-type--wgt--bold">{t('ACC_STATEMENT.TRANS_DETAILS.TRANSACTION_ID')}</div>
                                        <div className="table--bet__details__col">{paymentMethod.id}</div>
                                    </div>
                                    <div className="table--bet__details__row">
                                        <div className="table--bet__details__col u-type--wgt--boldl">{t('ACC_STATEMENT.TRANS_DETAILS.AMOUNT')}</div>
                                        <div className="table--bet__details__col">{amount}</div>
                                    </div>
                                    <div className="table--bet__details__row">
                                        <div className="table--bet__details__col u-type--wgt--bold">{t('ACC_STATEMENT.TRANS_DETAILS.CURRENCY')}</div>
                                        <div className="table--bet__details__col">{currency.displayCode}</div>
                                    </div>
                                    <div className="table--bet__details__row">
                                        <div className="table--bet__details__col u-type--wgt--bold">{t('ACC_STATEMENT.TRANS_DETAILS.STATUS')}</div>
                                        <div className="table--bet__details__col">{transactionStatus.displayName}</div>
                                    </div>
                                    <div className="table--bet__details__row">
                                        <div className="table--bet__details__col u-type--wgt--bold">{t('ACC_STATEMENT.TRANS_DETAILS.PAYMENT_METHOD')}</div>
                                        <div className="table--bet__details__col">
                                            <div className="u-display--f u-display--f--center">
                                                <i className={paymentMethodIcon}></i>
                                                {ccMaskedPan}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table--bet__details__row">
                                        <div className="table--bet__details__col u-type--wgt--bold">{t('ACC_STATEMENT.TRANS_DETAILS.CARD_EXPIRY')}</div>
                                        <div className="table--bet__details__col">{DateTime.fromISO(ccExpiryDate).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}</div>
                                    </div>
                                    <div className="table--bet__details__row">
                                        <div className="table--bet__details__col u-type--wgt--bold">{t('ACC_STATEMENT.TRANS_DETAILS.START_DATE')}</div>
                                        <div className="table--bet__details__col">{DateTime.fromISO(startDate).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}</div>
                                    </div>
                                    <div className="table--bet__details__row">
                                        <div className="table--bet__details__col u-type--wgt--bold">{t('ACC_STATEMENT.TRANS_DETAILS.END_DATE')}</div>
                                        <div className="table--bet__details__col">{DateTime.fromISO(endDate).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="popup--dialog__card__footer">

                    <Button
                        btnClassName="btn btn--sml btn--secondary btn--icon-left"
                        btnType="button"
                        onClick={() => setShowDetails(0)}
                        btnWithFrontIcon={<i className="u-icon u-icon--xsml u-icon--remove--white"></i>}
                        btnText="ACC_STATEMENT.TRANS_DETAILS.CLOSE_BTN"
                    />
                    
                </div>
            </div>
        </div>
    )
}