import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import ReactTooltip from 'react-tooltip';

import {
    OfferStoreContext,
    OfferOptionsContext,
    LookupContext,
} from '@gp/components';

import { Header, Body } from '../../../../../common/components/selected-offer-categories';

export default observer(function HighLightOfferHomeTemplate(props) {

    const {
        viewStore,
    } = props;

    const options = {
        numberOfColumns: 2,
        scoreChangeDuration: 3500,

        enableInlineAdditionalOffer: false,
        onAdditionalOfferClick: ({ eventId, isLive }) => {
            let period = 'highlights';

            let url = `/${App.utils.getCurrentCulture()}/sports/${period}`;

            App.state.redirect(`${url}?event=${eventId}`);
        },

        isOneClickBetActive: viewStore.rootStore.betSlipStore.betSlipState.isOneClickBetActive,
        onTipClick: (tip) => {
            viewStore.rootStore.betSlipStore.addRemoveOffer(tip);
        },
        isTipSelected: (tipId) => {
            if (viewStore.rootStore.betSlipStore.betSlipState.isOneClickBetActive) {
                return viewStore.rootStore.betSlipStore.betSlipState.isInOneClickBet(tipId);
            }
            return viewStore.rootStore.betSlipStore.isInBetSlip(tipId);
        },
    }

    return (
        <OfferStoreContext.Provider value={viewStore} >
            <LookupContext.Provider value={viewStore.lookupsStore} >
                <OfferOptionsContext.Provider value={options}>
                    <TemplateCore />
                </OfferOptionsContext.Provider>
            </LookupContext.Provider>
        </OfferStoreContext.Provider>
    )
})

const TemplateCore = observer(function TemplateCore(props) {

    const {
        isEmpty,
    } = useContext(OfferStoreContext);

    if (isEmpty) {
        return null;
    }

    return (
        <React.Fragment>
            <table className="offer offer--highlights">
                <Header offerCategory={'highlights'} />
                <tbody className="offer__body">
                    <Body />
                </tbody>
            </table>
            
            <ReactTooltip
                id="highlightsHomeHeaderTooltip"
                className="tooltip--primary"
                place="bottom"
                multiline={true}
            />
        </React.Fragment>
    );
});