import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Router } from 'react-router-dom';
import { SportsBettingLayoutTemplate } from 'themes/layouts';

class SportsBettingLayout extends React.Component {
    render() {
        return (
            <SportsBettingLayoutTemplate {...this.props} />
        )
    }
}

export default SportsBettingLayout;