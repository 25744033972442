import React from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { LiveHighlightOfferTemplate } from 'themes/pages/home/components';

@inject(stores => ({
    offerStore: stores.offerStore
}))
@observer
@withRouter
class LiveHighlightOffer extends React.Component {
    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        await this.props.liveHighlightsViewStore.onInitialize();
    }

    componentWillUnmount() {
        this.props.liveHighlightsViewStore.onDispose();
    }

    render() {
        return (
            <LiveHighlightOfferTemplate
                {...this.props}
            />
        );
    }
}

export default LiveHighlightOffer;