import React, { useEffect } from 'react';
import { OfferPrintTableTemplate } from 'themes/pages/offer-print/components';

export default function OfferPrintTable(props) {

    useEffect(()=>{

        App.state.rootStore.titleStore.setTitle(App.state.localizationService.t('OFFER_PRINT.TITLE'));

    },[])
    return <OfferPrintTableTemplate {...props} />
}