import React, { useState } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

export default function RealityCheckPopupTemplate(props) {
    const { t } = useTranslation();
    const {
        fetchedData,
        timePlayedData,
        resume,
        logout
    } = props;

    const acceptGameResumeRef = useRef(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    if (fetchedData == null || timePlayedData == null) {
        return null;
    }
    const setAcceptGameResume = () => {
        setIsSubmitting(acceptGameResumeRef.current.checked);
    }

    return (
        <div className="popup--reality">
            <div className="popup--reality__card">
                <div className="popup--reality__card__header">
                    <strong>{t("SESSION.REALITY_CHECK.REALITY_CHECK")}</strong>
                </div>
                <div className="popup--reality__card__body">
                    <div className="popup--reality__card__section">
                        <div className="row">
                            <div className="col col-sml-12 col-xxlrg-6">{t("SESSION.REALITY_CHECK.TIME_PLAYED")}:</div>
                            <div className="col col-sml-12 col-xxlrg-6">{t("SESSION.REALITY_CHECK.TIME_PLAYED_VALUE", { 0: timePlayedData.hours, 1: timePlayedData.minutes })}</div>
                        </div>
                    </div>

                    <div className="popup--reality__card__section">
                        <div className="popup--reality__card__section__title">{t("SESSION.REALITY_CHECK.SPORTS_BETTING_TITLE")}</div>
                        <div className="popup--reality__card__section__content">
                            <div className="row">
                                <div className="col col-sml-12 col-xxlrg-6">{t("SESSION.REALITY_CHECK.NUM_BETS")}:</div>
                                <div className="col col-sml-12 col-xxlrg-6">{fetchedData.sportBettingInfo.numberOfBets}</div>
                            </div>
                            <div className="row">
                                <div className="col col-sml-12 col-xxlrg-6">{t("SESSION.REALITY_CHECK.GAIN")}:</div>
                                <div className="col col-sml-12 col-xxlrg-6">{fetchedData.sportBettingInfo.gain}</div>
                            </div>
                            <div className="row">
                                <div className="col col-sml-12 col-xxlrg-6">{t("SESSION.REALITY_CHECK.LOSS")}:</div>
                                <div className="col col-sml-12 col-xxlrg-6">{fetchedData.sportBettingInfo.loss}</div>
                            </div>
                            <div className="row">
                                <div className="col col-sml-12 col-xxlrg-6">{t("SESSION.REALITY_CHECK.STAKE")}::</div>
                                <div className="col col-sml-12 col-xxlrg-6">{fetchedData.sportBettingInfo.stake}</div>
                            </div>
                        </div>
                    </div>
                    <div className="popup--reality__card__section">
                        <div className="row">
                            <div className="col col-sml-12">
                                <input ref={acceptGameResumeRef}
                                    onChange={() => setAcceptGameResume()}
                                    id="acceptGameResume"
                                    className="input--check"
                                    name="acceptGameResume"
                                    type="checkbox"
                                />
                                <label htmlFor="acceptGameResume">{t("SESSION.REALITY_CHECK.ACCEPT_GAME_RESUME")}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="popup--reality__card__footer">
                    <button className="btn btn--sml btn--secondary btn--ghost--neutral" onClick={logout}>
                        {t("SESSION.REALITY_CHECK.LOGOUT_BUTTON")}
                    </button>
                    <button className="btn btn--sml btn--primary" onClick={resume} disabled={!isSubmitting} type="submit">
                        {t("SESSION.REALITY_CHECK.RESUME_BUTTON")}
                    </button>
                </div>
            </div>
        </div>
    )
}