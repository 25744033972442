import { observable, action } from 'mobx';

// TODO duplicated from MyBetsViewStore.js
export class ReuseBetSlipStore {

    constructor(rootStore, myBetsStore) {
        this.rootStore = rootStore;
        this.myBetsStore = myBetsStore;
    }

    //#region observables

    /** Array of eventIds */

    @observable isReuseModalOpen = false;
    @observable reuseModalData;


    //#endregion observables




    //#region modals

    @action.bound
    async reuseBetSlip({ id, isLive }) {
        this.rootStore.prematchOfferMenuStore.closeOfferMenu();
        await this.rootStore.betSlipStore.getSlipAndMap(id);

        if (isLive) {
            App.state.redirect(`/${App.utils.getCurrentCulture()}/live/events`);
        }
        else {
            App.state.redirect(`/${App.utils.getCurrentCulture()}/sports/full`);
        }

        this.closeReuseBetSlipModal();
    }

    /**
     * Opens reuse bet slip modal
     * @param {{id, betSlipNumber}} modalData 
     */
    @action.bound
    openReuseBetSlipModal(modalData) {
        this.isReuseModalOpen = true;
        this.reuseModalData = modalData;
    }

    /**
    * Closes reuse bet slip modal
    */
    @action.bound
    closeReuseBetSlipModal() {
        this.isReuseModalOpen = false;
        this.reuseModalData = null;
    }

    //#endregion modals



    //#region disposers

    @action.bound
    onDispose() {

        this.isReuseModalOpen = false;
        this.reuseModalData = null;
    }


    //#endregion disposers
}