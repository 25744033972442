import React from 'react';
import { defaultTemplate } from '../../../common/hoc';

import { Event } from '../../../pages/events';
import { LiveSubheader } from '../../../common/components/subheader';
import { LiveEventSideMenu } from '../../../common/components/sidemenu';
import { Results } from '../../../common/components/results';
import { BetSlip, OneClickBetNotification } from '../../../common/components/bet-slip'
import { ErrorPage } from '../../../common/components/error';

export default defaultTemplate(function (props) {
    const { t } = props;
    return (
        <React.Fragment>
            <aside className="l--main__menu l--main__menu--event">
                {/* SUBHEADER */}
                <LiveSubheader />

                {/* SIDEMENU */}
                <LiveEventSideMenu />
            </aside>

            <div className={"l--main__content" + (!props.isOneClickBetActive ? " is-full" : "")}>
                <ErrorPage>
                    <Event isLive />
                </ErrorPage>
            </div>

            <aside className="l--aside">
                <div id="bsWrapper" className="l--aside__wrapper" style={{ marginTop: props.currentScroll }}>
                    {props.isOneClickBetActive &&
                        <div className="oneclick--notice">
                            <OneClickBetNotification onDeactivate={props.onDeactivateOneClickBet} />
                        </div>
                    }
                    <BetSlip />
                    <Results />
                </div>
            </aside>
        </React.Fragment>
    );
});