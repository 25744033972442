import React from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { PrematchSubheaderTemplate } from 'themes/common/components/subheader';

@inject(stores => ({ prematchSubheaderViewStore: stores.offerStore.prematchSubheaderViewStore }))
@withRouter
@observer
class PrematchSubheader extends React.Component {
    componentDidMount() {
        this.props.prematchSubheaderViewStore.setSelected(this.props.match.params.period);
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.period !== prevProps.match.params.period) {
            this.props.prematchSubheaderViewStore.setSelected(this.props.match.params.period);
        }
    }

    render() {
        return <PrematchSubheaderTemplate {...this.props} />
    }
}

export default PrematchSubheader;