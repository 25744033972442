import React from "react";
import { CurrencyContext } from "../context/";

function CurrencyDisplayContainer(props) {
	const currencyDisplay = {
		currency: App.state.rootStore.currencyStore.currency,
	};
	return (
		<CurrencyContext.Provider value={currencyDisplay}>
			{props.children}
		</CurrencyContext.Provider>
	);
}

export default CurrencyDisplayContainer;
