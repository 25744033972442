import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";

import { OneClickBetTemplate } from "themes/common/components/bet-slip";

@inject((stores) => ({
	betSlipStore: stores.offerStore.betSlipStore,
}))
@observer
class OneClickBet extends React.Component {
	render() {
		return <OneClickBetTemplate {...this.props} />;
	}
}

OneClickBet.propTypes = {
	betSlipStore: PropTypes.object,
};

export default OneClickBet;
