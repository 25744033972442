import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

const ServeIcon = (props) => <i className={classnames('u-icon u-icon--sport', `u-icon--${props.sport}`)} />

export const OfferColumnResultLiveTemplate = observer((props) => {
	if (props.lastPeriodScore == null) {
		return (
			<div className="offer__body__data offer__quotes">
				<div className="offer__quotes__row">
					<div className="offer__body__data offer__quote offer__serve">&nbsp;</div>
					<div className="offer__body__data offer__quote offer__score">&nbsp;</div>
					<div className="offer__body__data offer__quote offer__serve">&nbsp;</div>
				</div>
			</div>
		)
	}

	return (
		<div className="offer__body__data offer__quotes">
			<div className="offer__quotes__row">
				<div className="offer__body__data offer__quote offer__serve">
					{props.serve === 'home' ? <ServeIcon sport={props.sport.abrv} /> : ""}
				</div>
				<div className="offer__body__data offer__quote offer__score">
					<span className="offer__score__set">{props.lastPeriodScore.home || 0}:{props.lastPeriodScore.away || 0}</span>
					{props.gameScore != null ? (
						<sup className="offer__score__game">{props.gameScore.home}:{props.gameScore.away}</sup>
					) : null}
				</div>
				<div className="offer__body__data offer__quote offer__serve">
					{props.serve === 'away' ? <ServeIcon sport={props.sport.abrv} /> : ""}
				</div>
			</div>
		</div>
	)
})