import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

export default observer(function ResultsMenuSportTemplate(props) {
    const {
        sport,
        store: {
            isMenuExpanded,
            toggleMenuExpanded,
            selectedSport,
            setSelectedSport,
            setSelectedTournaments,
            selectedTournaments,
            setExpandedCategories,
            expandedCategories
        }
    } = props;

    return (
        <li className="menu--sport__item">
            <a className={`menu--sport__link${selectedSport === sport.id ? ' is-expanded' : ''}${selectedTournaments.some(t => t.sportId === sport.id) ? ' is-selected' : ''}`} onClick={() => {
                if (selectedSport !== sport.id) {
                    setSelectedSport(sport.id);
                    if (!isMenuExpanded) {
                        toggleMenuExpanded();
                    }
                }
                else {
                    setSelectedSport();
                    toggleMenuExpanded();
                }
            }}> 
                <div className="menu--sport__link__title">
                    <i className={`u-icon u-icon--sport u-mar--right--xsml u-icon--${sport.abrv}`}></i>
                    <span>{sport.name}</span>    
                </div>
                <span className="counter counter--neutral">{sport.count}</span>
            </a>

            {selectedSport === sport.id ? (
                    <ul className="menu--category">
                        {sport.children.map(category => {
                            return (
                                <li className="menu--category__item" key={category.id} onClick={(e) => {
                                    e.stopPropagation();
                                    setExpandedCategories(category.id);
                                }}>
                                    <a className='menu--category__link'>
                                        <div className='munu--category__link__title'>{category.name}</div>
                                        <span className="counter counter--neutral">{category.count}</span>

                                    </a>
                                    <ul className="menu--tournament">
                                        {expandedCategories.includes(category.id) ? category.children.map(tournament => {
                                            return <li key={tournament.id} data-selected={selectedTournaments.some(t => t.id === tournament.id)} className="menu--tournament__item" onClick={(e) => {
                                                e.stopPropagation();
                                                setSelectedTournaments(tournament);
                                            }}>
                                                <input className="input--check--tournament" type="checkbox"></input>
                                                <label className={`menu--tournament__link`}>
                                                    <span className="menu--tournament__name">{tournament.name}</span>
                                                    <span className="menu--tournament__counter counter counter--neutral">{tournament.count}</span>
                                                </label>
                                            </li>
                                        }) : null}
                                    </ul>
                                </li>
                            )
                        })}
                    </ul>
            ) : null}
        </li>

    )

})