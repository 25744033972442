import { DateTime } from "luxon";
import { UserDataService } from "../../../../../administration/services/common/UserDataService";
import { Response, ErrorResponse } from "../../../../../administration/services/common/Models";

class ForgotPasswordService {

    constructor() {
        this.userDataService = new UserDataService();
    }

    async requestPasswordRecovery(formData, recaptchaToken = null) {

        try {

            const model = {
                dateOfBirth: DateTime.fromISO(formData.dateOfBirth).toFormat('MM-dd-yyyy'),
                recoverUrl: `${location.origin}/${App.utils.getCurrentCulture()}/auth/password-recovery{?passwordRecoveryToken}&username=${formData.userName}`,
                userName: formData.userName,
                challengeResponse: recaptchaToken
            }

            await this.userDataService.requestPasswordRecovery(model);
            return new Response();
        }
        catch (error) {
            console.log(error);
            if (error?.data?.errorCode === 400158) {
                throw new ErrorResponse({ message: 'FORGOT_PASSWORD_USERNAME_OR_DATEOFBIRTH_INVALID' });
            }
            else if (error?.data?.errorCode === 400159) {
                throw new ErrorResponse({ message: 'ERROR_PWD_CHANGE_SHOP', options: error?.data?.details });
            }
            else if (error?.data?.errorCode === 400160) {
                throw new ErrorResponse({ message: 'FORGOT_USERNAME_NOT_VERIFIED' });
            }
            else if (error?.data?.errorCode === 400163) {
                throw new ErrorResponse({ message: 'PASSWORD_RECOVERY_TOKEN_INVALID_OR_EXPIRED' });
            }

            throw new ErrorResponse({ message: 'PASSWORD_RECOVERY_REQUEST_ERROR' });
        }
    }
}

const forgotPasswordServiceInstance = new ForgotPasswordService();
export { forgotPasswordServiceInstance }