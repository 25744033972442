import React, { useContext } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { MyBetsStoreContext } from "../../../../common/context";
import { BetItem, BetReports } from "./components";
import { OfferPager } from "../../../../common/components/offer";

export default observer(function Body(props) {
  const { pageNumber, pageSize, totalItems, onPageChange } =
    useContext(MyBetsStoreContext);
  return (
    <div className="card--primary__body card--primary__body--bordered">
      <BetReports />
      <BetList />
     
       <OfferPager
                pageNumber={pageNumber}
                pageSize={pageSize}
                totalItems={totalItems}
                onPageChange={onPageChange}
                withNumberInput
            />
    </div>
  );
});

const BetList = observer(function BetList(props) {
  const { betsList, isEmpty } = useContext(MyBetsStoreContext);

  if (isEmpty) {
    return <EmptyMyBetsMessage />;
  }

  return betsList.map((bet,index) => <BetItem key={bet.id} bet={bet} printId={index} />);
});

function EmptyMyBetsMessage(props) {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const messageStr = pathname.includes("open")
    ? t("MY_BETS.PAGE.NO_OPEN_BETS")
    : t("MY_BETS.PAGE.NO_CLOSED_BETS");

  return <div className="message--base message--note">{messageStr}</div>;
}
