import { observable, action, runInAction } from 'mobx';
import { BetSlipApiService } from '../../../services';
import { LoaderStore } from '../../../state/stores/common';

export class BetCancelStore {

    constructor(rootStore, myBetsStore) {
        this.rootStore = rootStore;
        this.myBetsStore = myBetsStore;

        // Services
        this.betSlipApiService = new BetSlipApiService();

        // Helper stores
        this.loader = new LoaderStore();

        this.getResponseErrorMessage = this.getResponseErrorMessage.bind(this);
    }



    //#region observables

    @observable isCancelPopUpOpen = false;
    @observable betToCancel = null;
    @observable betCancelMessage = null;

    //#endregion observables



    //#region bet cancel

    @action.bound
    async onBetCancelConfirm() {
        try {
            const response = await this.betSlipApiService.putCancelBet(this.betToCancel.id);
            const gainValue = response.betSlip.payout ?? response.betSlip.gain;
            App.state.rootStore.userAuthStore
                .resolveBalance(gainValue, response.betSlip.bettingAccountTypeId);

            await this.myBetsStore.refresh();
            runInAction(() => {
                this.betCancelMessage = this.getResponseErrorMessage(response);
            })
        } catch (error) {
            console.error(error);
            runInAction(() => {
                this.betCancelMessage = App.state
                    .localizationService.t('MY_BETS.BET_CANCEL.CANCEL_FAILED');
            })
        }
    }

    getResponseErrorMessage(response) {
        if (
            response.validationErrors != null &&
            response.validationErrors.length >= 0
        ) {
            return result.validationErrors[0].errorMessage;
        }

        if (
            response.betSlipProcessingResponses != null &&
            response.betSlipProcessingResponses.length >= 0
        ) {
            return result.betSlipProcessingResponses[0].errorMessage;
        }

        if (response.betSlip != null) {
            return App.state.localizationService.t('MY_BETS.BET_CANCEL.CANCEL_SUCCESS');
        }

        return App.state.localizationService.t('MY_BETS.BET_CANCEL.CANCEL_FAILED');
    }

    @action.bound
    onBetCancel(bet) {
        this.isCancelPopUpOpen = true;
        this.betToCancel = bet;
    }

    @action.bound
    onExitBetCancel() {
        this.isCancelPopUpOpen = false;
        this.betToCancel = null;
        this.betCancelMessage = null;
    }


    //#endregion bet cancel



    //#region disposers

    @action.bound
    onDispose() {
        this.onExitBetCancel();
    }

    //#endregion disposers
}