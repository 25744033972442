import React from "react";
import {
	FormInputField,
	FormSelectField,
	DatePicker,
} from "../../../../../../common/components/inputs";
import { mapLanguages } from "../../../../../../pages/administration/personal-data/utils";
import { PersonalDataPayPinSection } from "../../../../../../pages/administration/personal-data/components";
import {
	FormButton,
	Button,
} from "../../../../../../common/components/buttons";

export default function PersonalDataFormSectionTemplate(props) {
	const {
		fetchedData,
		isEditing,
		setIsEditing,
		setActiveSection,
		payPin,
		updateUserPin,
		getNewPayPin,
		checkIsCityOrStreetFieldValid,
	} = props;

	return (
		<div className="at-PersonalDataForm">
			<section className="form__group">
				<FormInputField
					label="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.USERNAME"
					name="userName"
					className="form__field row"
					disabled
				/>
				<FormSelectField
					label="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.TITLEID"
					className="form__field row"
					name="titleId"
					dropdownData={
						fetchedData ? fetchedData.userTitles : undefined
					}
					disabled
				/>
				<FormInputField
					label="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.FIRSTNAME"
					name="firstName"
					className="form__field row"
					disabled
				/>
				<FormInputField
					label="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.LASTNAME"
					name="lastName"
					className="form__field row"
					disabled
				/>
				<DatePicker
					label="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.DOB"
					name="dob"
					className="form__field row"
					disabled
					readOnly
				/>
			</section>

			<section className="form__group">
				<FormInputField
					label="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.STREET"
					name="street"
					className="form__field row"
					required
					validate={{
						isStreetValid: (value) =>
							checkIsCityOrStreetFieldValid(value),
					}}
					maxLength={80}
					disabled={isEditing !== 1}
				/>
				<FormInputField
					label="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.HOUSENUMBER"
					name="houseNumber"
					className="form__field row"
					pattern={{
						value: /^[A-Za-z0-9\./-]{1,20}$/,
					}}
					maxLength={20}
					disabled={isEditing !== 1}
				/>
				<FormInputField
					label="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.ZIPCODE"
					name="zipCode"
					className="form__field row"
					required
					pattern={{
						value: /^[A-Za-z0-9][A-Za-z0-9\-\s]{0,38}[A-Za-z0-9]$/,
					}}
					maxLength={40}
					disabled={isEditing !== 1}
				/>
				<FormInputField
					label="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.CITY"
					name="city"
					className="form__field row"
					validate={{
						isCityValid: (value) =>
							checkIsCityOrStreetFieldValid(value),
					}}
					required
					maxLength={80}
					disabled={isEditing !== 1}
				/>
				<FormSelectField
					label="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.COUNTRYID"
					className="form__field row"
					name="countryId"
					dropdownData={
						fetchedData ? fetchedData.countries : undefined
					}
					disabled={isEditing !== 1}
				/>
				<div className="form__field row--right">
					<div className="col-sml-11 u-type--right">
						{isEditing !== 1 ? (
							<Button
								className="btn btn--sml btn--neutral"
								btnType="button"
								onClick={() => setActiveSection(1)}
								btnText="COMMON.EDIT_BUTTON"
							/>
						) : (
							<div>
								<FormButton
									className="btn btn--sml btn--accent u-mar--right--tny"
									btnText="COMMON.SAVE_BUTTON"
								/>
								<Button
									className="btn btn--sml btn--neutral"
									btnType="button"
									onClick={() => setActiveSection(0)}
									btnText="COMMON.CANCEL_BUTTON"
								/>
							</div>
						)}
					</div>
				</div>
			</section>

			<section className="form__group">
				<FormSelectField
					label="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.LANGUAGE"
					className="form__field row"
					name="language"
					dropdownData={mapLanguages(
						fetchedData ? fetchedData.languages : undefined
					)}
					placeholder="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.LANGUAGE_PLACEHOLDER"
					disabled={isEditing !== 2}
				/>

				<FormInputField
					label="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.PHONE_LABEL"
					name="telephoneNumber"
					className="form__field row"
					pattern={{
						value: /^\+([0-9][\/. ()-]*){8,}$/,
					}}
					maxLength={40}
					disabled={isEditing !== 2}
				/>

				<FormInputField
					label="USER.ACCOUNT_SETTINGS.PERSONAL_DATA.MOBILEPHONE"
					name="mobilePhone"
					className="form__field row"
					pattern={{
						value: /^\+([0-9][\/. ()-]*){8,}$/,
					}}
					maxLength={40}
					disabled={isEditing !== 2}
				/>

				<div className="form__field row--right">
					<div className="col-sml-11 u-type--right">
						{isEditing !== 2 ? (
							<Button
								className="btn btn--sml btn--neutral"
								btnType="button"
								onClick={() => setActiveSection(2)}
								btnText="COMMON.EDIT_BUTTON"
							/>
						) : (
							<div>
								<FormButton
									className="btn btn--sml btn--accent u-mar--right--tny"
									btnText="COMMON.SAVE_BUTTON"
								/>
								<Button
									className="btn btn--sml btn--neutral"
									btnType="button"
									onClick={() => setActiveSection(0)}
									btnText="COMMON.CANCEL_BUTTON"
								/>
							</div>
						)}
					</div>
				</div>
			</section>

			<PersonalDataPayPinSection
				payPin={payPin}
				updateUserPin={updateUserPin}
				getNewPayPin={getNewPayPin}
				isEditing={isEditing}
				setIsEditing={setIsEditing}
			/>
		</div>
	);
}
