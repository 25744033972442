import React, { useState } from 'react';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { defaultTemplate } from '../../../../common/hoc'
import { Offer, OfferPager } from '../../../../common/components/offer';
import { EventContext, LookupContext, OfferStoreContext } from '@gp/components';

const UpcomingEventGroup = observer(function UpcomingEventGroup(props) {

    const { t } = useTranslation();
    const [isExpanded, setIsExpanded] = useState(true);

    const events = props.offer[props.groupDate];

    const groupDateMoment = moment(props.groupDate, 'DD.MM.YYYY');

    let day;
    if (groupDateMoment.isSame(props.now, 'd')) {
        day = t('OFFER.TIME.TODAY');
    }
    else if (groupDateMoment.isSame(props.tomorrow, 'd')) {
        day = t('OFFER.TIME.TOMORROW');
    }
    else {
        day = groupDateMoment.locale(App.utils.getCurrentCulture()).format('dddd');
    }

    function expandCollapseTable(event) {
        event.preventDefault();

        setIsExpanded(!isExpanded);
    }

    return (
        <tbody className={classNames("offer__body", { 'is-collapsed': !isExpanded })}>
            <tr className={classNames("offer__subheader", { 'is-collapsed': !isExpanded })}>
                <td className="offer__subheader__data offer__toggle--sml">
                    <button className="offer__toggle--sml__btn btn btn--link btn--icon-center" type="button" onClick={expandCollapseTable}>
                        <i className="u-icon u-icon--xsml u-icon--arrow-right" />
                    </button>
                </td>

                <td className="offer__subheader__data offer__subheader__date" colSpan={2}>
                    {day}, {groupDateMoment.format('DD.MM')}
                </td>

                <td className="offer__subheader__data offer__subheader__tip">
                    <div className="offer__subheader__tip__row">
                        <div className="offer__subheader__tip__item">1</div>
                        <div className="offer__subheader__tip__item">X</div>
                        <div className="offer__subheader__tip__item">2</div>
                    </div>
                </td>

                <td className="offer__subheader__data offer__subheader__tip">
                    <div className="offer__subheader__tip__row">
                        <div className="offer__subheader__tip__item">1</div>
                        <div className="offer__subheader__tip__item">X</div>
                        <div className="offer__subheader__tip__item">2</div>
                    </div>
                </td>

                <td className="offer__subheader__data offer__subheader__tip">
                    <div className="offer__subheader__tip__row">
                        <div className="offer__subheader__tip__item">1</div>
                        <div className="offer__subheader__tip__item">X</div>
                        <div className="offer__subheader__tip__item">2</div>
                    </div>
                </td>

                <td className="offer__subheader__data offer__subheader__tip">
                    <div className="offer__subheader__tip__row">
                        <div className="offer__subheader__tip__item">&nbsp;</div>
                        <div className="offer__subheader__tip__item">+</div>
                        <div className="offer__subheader__tip__item">-</div>
                    </div>
                </td>

                <td className=" offer__subheader__data">&nbsp;</td>
            </tr>
            {isExpanded && events.map(event => {
                return (
                    <EventContext.Provider key={event.id} value={event} >
                        <Offer
                            showOnlyTime
                            event={event}
                            sport={event.sportObj}
                            renderActionsBeforeOffer={true}
                            main={props.columns}
                            additionalOfferColSpan={19}
                        />
                    </EventContext.Provider>
                )
            })}
        </tbody>
    )
});

export default defaultTemplate(function (props) {
    const { liveUpcomingViewStore, t } = props;
    const {
        grouppedEventsByDate,
        columns,
        isLoading,
        isEmpty,
        pageNumber,
        pageSize,
        totalItems,
        onPageChange,
    } = liveUpcomingViewStore;

    // render offer placeholder while loading
    if (isLoading) {
        return (
            <Loader />
        )
    }

    if (isEmpty) {
        return (
            <div className="message--sml message--note">
                {t('specific:LIVE.UPCOMING.NO_EVENTS')}
            </div>
        )
    }

    const now = moment().startOf('day');
    const tomorrow = moment().add(1, 'day').startOf('day');

    const { total, ...offer } = grouppedEventsByDate;

    if(!isEmpty && total <= 0) {

        // Prevent flashing of upcoming header and offer pager if offer is not fully loaded
        return null;
    }

    return (
        <OfferStoreContext.Provider value={liveUpcomingViewStore}>
			<LookupContext.Provider value={liveUpcomingViewStore.lookupsStore}>
                <ReactTooltip
                    id="upcomingHeaderTooltip"
                    className="tooltip--tertiary"
                    place="bottom"
                    multiline={true}
                />
                <ReactTooltip
                    id="team-description"
                    className="tooltip--tertiary"
                    place="bottom"
                    multiline={true}
                />
                
                <table className="offer offer--upcoming">
                    <thead className="offer__head">
                        <tr className="offer__head__row">
                            <th className="offer__head__data">
                                <i className="u-icon u-icon--dnf u-icon--dnf--stopwatch u-type--lrg u-align--v--middle" />
                            </th>
                            <th className="offer__head__data" colSpan={2}>
                                {t("LIVE.UPCOMING.TITLE")}
                                <span className="counter u-mar--left--sml">{total}</span>
                            </th>
                            <th className="offer__head__data offer__header--quote">
                                <span className="offer__header--quote__type">{t('LIVE.UPCOMING.BETTING_TYPES.TIP')}</span>
                                <i data-for="upcomingHeaderTooltip" data-tip={t('LIVE.UPCOMING.BETTING_TYPES.TIP_INFO')} className="offer__header--quote__icon u-icon u-icon--xsml u-icon--info--white" />
                            </th>
                            <th className="offer__head__data offer__header--quote">
                                <span className="offer__header--quote__type">{t('LIVE.UPCOMING.BETTING_TYPES.HT')}</span>
                                <i data-for="upcomingHeaderTooltip" data-tip={t('LIVE.UPCOMING.BETTING_TYPES.HT_INFO')} className="offer__header--quote__icon u-icon u-icon--xsml u-icon--info--white" />
                            </th>
                            <th className="offer__head__data offer__header--quote">
                                <span className="offer__header--quote__type">{t('LIVE.UPCOMING.BETTING_TYPES.NEXT_GOAL')}</span>
                                <i data-for="upcomingHeaderTooltip" data-tip={t('LIVE.UPCOMING.BETTING_TYPES.NEXT_GOAL_INFO')} className="offer__header--quote__icon u-icon u-icon--xsml u-icon--info--white" />
                            </th>
                            <th className="offer__head__data offer__header--quote">
                                <span className="offer__header--quote__type">{t('LIVE.UPCOMING.BETTING_TYPES.OVER_UNDER')}</span>
                                <i data-for="upcomingHeaderTooltip" data-tip={t('LIVE.UPCOMING.BETTING_TYPES.OVER_UNDER_INFO')} className="offer__header--quote__icon u-icon u-icon--xsml u-icon--info--white" />
                            </th>
                            <th className="offer__head__data offer__toggle">&nbsp;</th>
                        </tr>
                    </thead>

                    {Object.keys(offer).sort((a, b) => moment(a, 'DD.MM.YYYY') - moment(b, 'DD.MM.YYYY')).map((groupDate, gdIdx) => (
                        <UpcomingEventGroup
                            key={groupDate}
                            offer={offer}
                            groupDate={groupDate}
                            now={now}
                            tomorrow={tomorrow}
                            //renderActions={renderActions}
                            columns={columns}
                        />
                    ))}
                </table>

                <OfferPager
                    pageNumber={pageNumber}
                    pageSize={pageSize}
                    totalItems={totalItems}
                    onPageChange={onPageChange}
                    isRemoveAllVisible={false}
                />
            </LookupContext.Provider>
        </OfferStoreContext.Provider>
    );
});

const Loader = defaultTemplate(function Loader(props) {
    const {
        t,
    } = props;

    return (
        <div className="loader">
            <div className="loader__card">
                <div className="loader__card__title">
                    {t("COMMON.LOADING")}...
                </div>
                <span />
            </div>
        </div>
    )
})