import React from "react";
import { Redirect, Route } from "react-router-dom";
import { Error404 } from "../error";

export default function NoMatchRoute(props) {

    // const path404 = window.location.protocol + "//" + window.location.host + "/" + window.location.pathname + window.location.search;
    const path404 = window.location.pathname + window.location.search;

    return (
        <Route path="*">
            <Route path="/:culture/Membership/Authentication/Login" render={({ match }) => (
                <Redirect to={`/${match.params.culture}/auth/login`} />
            )} />
            <Route path="/:culture/Membership/Authentication/ForgotUsername" render={({ match }) => (
                <Redirect to={`/${match.params.culture}/auth/forgot-username`} />
            )} />
            <Route path="/:culture/Membership/Password/ForgotPassword" render={({ match }) => (
                <Redirect to={`/${match.params.culture}/auth/forgot-password`} />
            )} />
            <Route path="/:culture/Administration/AccountSettings" render={({ match }) => (
                <Redirect to={`/${match.params.culture}/account-settings/personal-data`} />
            )} />
            <Route path="/:culture/bets" render={({ match }) => (
                <Redirect to={`/${match.params.culture}/my-bets`} />
            )} />
            <Error404 {...props} path404={path404} />
        </Route>
    )
}