import React from 'react';
import PropTypes from 'prop-types';

import { NumericInputTemplate } from 'themes/common/components/inputs';

class NumericInput extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value.toFixed(2) || '',
            internalChange: false
        }
    }

    componentDidUpdate(prevProps) {
        if (!this.state.internalChange && this.state.value != this.props.value) {
            this.setState({
                value: this.props.value.toFixed(2),
                internalChange: false
            });
        }
    }

    handleChange = (event) => {
        this.setState({
            value: event.target.value,
            internalChange: true
        });
    }

    handleBlur = (event) => {
        if (this.props.isReadonly) {
            return;
        }

        const parsedValue = parseFloat(this.state.value);

        this.props.onChange(parsedValue);

        if (this.props.onBlur) {
            this.props.onBlur(event);
        }

        this.setState({
            value: parsedValue.toFixed(2),
            internalChange: false
        })
    }

    render() {
        const { isReadonly, ...other } = this.props;

        return (
            <NumericInputTemplate
                {...other}
                value={this.state.value}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
            />
        );
    }
}

NumericInput.propTypes = {
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    step: PropTypes.string,
    min: PropTypes.number,
    isReadonly: PropTypes.bool
}

NumericInput.defaultProps = {
    step: '.01',
    min: 0,
    isReadonly: false
}

export default NumericInput;