import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function ActivationSuccessTemplate() {
	const { t } = useTranslation();

	return (
		<div className="view--med">
			<div className="view--sml">
				<h1 className="u-type--lrg u-type--case--uppercase u-mar--bottom--sml">
					{t(
						"MEMBERSHIP.ACCOUNT_ACTIVATION.EMAIL_VERIFICATION_TITLE"
					)}
				</h1>
				<div className="message--base message--success">
					{t(
						"MEMBERSHIP.ACCOUNT_ACTIVATION.EMAIL_VERIFICATION_SUCCESS"
					)}
				</div>
				<Link
					className="u-anchor--primary"
					to={`/${App.utils.getCurrentCulture()}/home/full`}
				>
					{t("MEMBERSHIP.ACCOUNT_ACTIVATION.BACK_TO_HOME_PAGE")}
				</Link>
			</div>
		</div>
	);
}
