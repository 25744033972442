import { observable, computed, action, runInAction, reaction } from 'mobx';
import { DateTime } from 'luxon';

function createDefaultRawData() {
    return {
        betSlipStatusIds: null,
        bettingAccountTypeIds: null,
        betStatusIds: null,

        period: null,
        from: null,
        to: null,

        isLive: null,

        pageNumber: 1,
    }
}

export class MyBetsFilterStore {


    constructor(rootStore, myBetsStore) {
        this.rootStore = rootStore;
        this.myBetsStore = myBetsStore;

        this.setDefaultFilterData(createDefaultRawData());

        this.getUrlData = this.getUrlData.bind(this);
    }

    @observable rawData = null;
    @observable isDefaultFilter = false;

    @computed get filter() {

        const filter = {};

        if (this.embed != null) {
            filter.embed = this.embed;
        }

        if (this.betSlipStatusIds != null) {
            filter.betSlipStatusIds = this.betSlipStatusIds;
        }

        if (this.betStatusIds != null) {
            filter.betStatusIds = this.betStatusIds;
        }

        if (this.bettingAccountTypeIds != null) {
            filter.bettingAccountTypeIds = this.bettingAccountTypeIds;
        }

        if (this.dateRange != null) {
            filter.from = this.dateRange.from;
            filter.to = this.dateRange.to;
        }

        if (this.isLive != null) {
            filter.isLive = this.isLive;
        }

        if (this.pageNumber != null) {
            filter.pageNumber = this.pageNumber;
        }

        return filter;
    }

    //#region filter components

    @computed get betSlipStatusIds() {
        return this.myBetsStore.betSlipStatusLookups?.find(s => s.abrv === 'submitted')?.id;
    }

    @computed get betStatusIds() {

        if (this.rawData.betStatusIds == null) {
            return null;
        }

        switch (this.rawData.betStatusIds) {
            case 'all':
                return null;
            case 'open':
                return this.myBetsStore.betStatusLookups?.find(s => s.abrv === 'open')?.id;
            case 'closed':
                return this.myBetsStore.closedBetsBetStatuses.map(s => s.id);
            default:
                return this.rawData.betStatusIds;
        }
    }

    @computed get bettingAccountTypeIds() {
        if (this.rawData.bettingAccountTypeIds == null) {
            return null;
        }

        return this.rawData.bettingAccountTypeIds;
    }

    @computed get dateRange() {
        const {
            period,
            from,
            to,
        } = this.rawData;

        if (period == null && from == null && to == null || period === 'all') {
            return null;
        }

        if (period !== 'custom') {
            const timeInterval = this.myBetsStore.betPeriods.find(p => p.value === period)?.getTimeInterval();
            if (timeInterval == null) {
                // Return default
                return this.myBetsStore.betPeriods[0].getTimeInterval();
            }
            return timeInterval;
        }

        return { from, to: DateTime.fromISO(to).endOf('day').toString() };
    }

    @computed get isLive() {
        // Only add isLive to filter if it is true;
        // If isLive=false is sent it will fetch bets that are not live and this check 
        // Servers as option to show only live
        if (this.rawData.isLive !== true) {
            return null;
        }

        return this.rawData.isLive;
    }

    @computed get pageNumber() {
        return this.rawData.pageNumber;
    }

    embed = "betSlipOffers,betSlipStatus,betStatus,betSlipType";

    //#endregion filter components



    //#region setters

    @action.bound
    setBetSlipStatusIds(betSlipStatusIds) {
        this.rawData.betSlipStatusIds = betSlipStatusIds;
        this.isDefaultFilter = false;
    }

    @action.bound
    setAccountTypeIds(bettingAccountTypeIds) {
        this.rawData.bettingAccountTypeIds = bettingAccountTypeIds;
        this.isDefaultFilter = false;
    }

    @action.bound
    setBetStatusIds(betStatusIds) {
        this.rawData.betStatusIds = betStatusIds;
        this.isDefaultFilter = false;
    }

    @action.bound
    setPeriod(period) {
        this.rawData.period = period;
        this.isDefaultFilter = false;
    }

    @action.bound
    setFrom(from) {
        this.rawData.from = from;
        this.isDefaultFilter = false;
    }

    @action.bound
    setTo(to) {
        this.rawData.to = to;
        this.isDefaultFilter = false;
    }

    @action.bound
    setIsLive(isLive) {
        this.rawData.isLive = isLive;
        this.isDefaultFilter = false;
    }

    @action.bound
    setRawFilterData(filterData) {
        this.rawData = Object.assign(createDefaultRawData(), filterData);
        this.isDefaultFilter = false;
    }

    @action.bound
    setDefaultFilterData() {
        this.rawData = createDefaultRawData();
        this.isDefaultFilter = true;
    }

    @action.bound
    setPageNumber(number) {
        this.rawData.pageNumber = number;
        this.isDefaultFilter = false;
    }

    //#endregion setters




    @action.bound
    onInitialize() {

        const urlData = this.getUrlData();

        if (App.state.history.location.search !== '') {

            this.setRawFilterData(
                Object.assign(
                    createDefaultRawData(),
                    urlData,
                )
            );
        }

        this.onDisposeUrlParamsUpdated();
        this.urlParamsUpdatedReaction = reaction(
            () => ({
                filter: this.filter
            }),
            () => this.updateUrlParamsFromRawData(),
            {
                fireImmediately: false,
            }
        )
    }

    @action.bound
    handleHistoryBack() {
        const currentFilterData = this.getUrlData();

        if (App.state.history.location.search !== '') {
            this.setRawFilterData(
                Object.assign(
                    createDefaultRawData(),
                    currentFilterData,
                )
            );
        }
    }

    getUrlData() {
        const urlData = App.state.getUrlParamsAsObj();

        if ('betSlipStatusIds' in urlData) {
            urlData.betSlipStatusIds = urlData.betSlipStatusIds.split(',');
        }

        if ('bettingAccountTypeIds' in urlData) {
            urlData.bettingAccountTypeIds = urlData.bettingAccountTypeIds.split(',');
        }

        if (
            'betStatusIds' in urlData &&
            !['all', 'open', 'closed'].includes(urlData.betStatusIds)
        ) {
            urlData.betStatusIds = urlData.betStatusIds.split(',');
        }

        // period is string
        // from is string
        // to is string

        if ('isLive' in urlData) {
            if (urlData.isLive === 'true') {
                urlData.isLive = true;
            } else {
                urlData.isLive = false;
            }
        }

        if ('pageNumber' in urlData) {
            urlData.pageNumber = parseInt(urlData.pageNumber);
            if (isNaN(urlData.pageNumber) || urlData.pageNumber < 1) {
                urlData.pageNumber = 1;
            }
        }

        return urlData;
    }

    @action.bound
    updateUrlParamsFromRawData() {
        // Filter out null params to not clutter url
        const newUrlParams = {};
        for (const [key, value] of Array.from(Object.entries(this.rawData))) {
            if (value == null) {
                continue;
            }
            newUrlParams[key] = value;
        }

        if (!this.isDefaultFilter) {
            App.state.updateUrlParams(newUrlParams, true);
        }
    }



    //#region disposers

    @action.bound
    onDispose() {
        this.onDisposeUrlParamsUpdated();
        this.setDefaultFilterData(createDefaultRawData());
    }

    @action.bound
    onDisposeUrlParamsUpdated() {
        if (this.urlParamsUpdatedReaction != null) {
            this.urlParamsUpdatedReaction();
            this.urlParamsUpdatedReaction = null;
        }
    }

    //#endregion disposers
}