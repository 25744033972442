import {
	ErrorResponse,
	Response,
	UserDataService,
} from "../../../../../administration/services/common";
export class LoginService {
	constructor() {
		this.userDataService = new UserDataService();
	}

	async loginUser(userData) {
		try {
			let response;
			if (typeof userData === "string") {
				response = await this.userDataService.ssoLogin(userData);
			} else {
				response = await this.userDataService.loginUser(userData);
			}
			return new Response(response.primary);
		} catch (error) {
			console.error(error);
			let errorMessage;
			let errorFailedAttempts = null;
			let errorDuration = null;
			let userData = null;

			if (error == null || error.message == "Unauthorized") {
				throw new ErrorResponse({ message: "USER_UNABLE" });
			}

			switch (Number(error?.data?.errorCode)) {
				case 400101:
					errorMessage = "EMAIL_LOGIN";
					break;

				case 400110:
					errorMessage = "PASSWORD_LAST_TRY";
					break;

				case 400111:
					errorMessage = "USER_LOCKED";
					break;

				case 400166:
					errorMessage = "USER_TEMP_LOCK_TIME";
					errorFailedAttempts = 5;
					errorDuration = 30;
					// }
					break;

				case 400168:
					errorMessage = "USER_TEMP_LOCK";
					errorFailedAttempts = 5;
					errorDuration = 30;
					break;

				case 400112:
					errorMessage = "USER_NOT_VERIFIED";
					break;

				case 400102:
				case 400113:
					errorMessage = "INVALID_PASS_OR_USERNAME_POPUP";
					break;
				case 400202:
					errorMessage = "USER_DOCUMENTS_NOT_VERIFIED";
					break;
				case 400197:
					errorMessage = "OASIS_GAMING_EXCLUSION";
					break;
				case 400213:
					userData = error?.data;
					break;
				default:
					errorMessage = "USER_UNABLE";
					break;
			}
			const modelError = {
				errorCode: error?.data?.errorCode,
				message: errorMessage,
				errorFailedAttempts: errorFailedAttempts,
				errorDuration: errorDuration,
				userData: userData,
			};
			throw new ErrorResponse(modelError);
		}
	}
}
