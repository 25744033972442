import React from 'react';
import { range } from 'lodash';

import { ScoreType } from '../../../../../../administration/services/results';

export default function ScoreHeaderRendererTemplate(props) {

    const {
        scoreType,
        columnsCount,
        tournamentId
    } = props;

    if (scoreType === ScoreType.HEAD2HEAD || scoreType === ScoreType.HEAD2HEADWITHOT) {
        return (
            <>
                <th className="table--results__subheader__data table--results__subheader__data--ft">FT</th>
                <th className="table--results__subheader__data table--results__subheader__data--ht">HT</th>
                {scoreType === ScoreType.HEAD2HEADWITHOT ?
                    <th className="table--results__subheader__data table--results__subheader__data--otap">OT/AP</th>
                : null}
            </>
        )
    }
    else if (scoreType === ScoreType.FTONLY || scoreType === ScoreType.FTONLYWITHOT) {
        return (
            <>
                <th className="table--results__subheader__data table--results__subheader__data--ft">FT</th>
                {scoreType === ScoreType.FTONLYWITHOT ?
                    <th className="table--results__subheader__data table--results__subheader__data--otap">OT/AP</th>
                : null}
            </>
        )
    }
    else {
        return (
            <>
                <th className="table--results__subheader__data table--results__subheader__data--ft">FT</th>
                {range(columnsCount).map((item, idx) => {
                    return <th key={`${tournamentId}-tsh-${idx}`} className="table--results__subheader__data table--results__subheader__data--period">{`P${item + 1}`}</th>
                })}
                {scoreType === ScoreType.PERPERIODWITHOT ?
                    <th className="table--results__subheader__data table--results__subheader__data--otap">OT/AP</th>
                : null}
            </>
        )
    }
}