import classNames from "classnames";
import React, { useState } from "react";


export default function AccordionTemplate(props) {
    const {
        header,
        children,
        active
    } = props;

    // default state of active is false 

    const [toggleBtn, setToggleBtn] = useState(active);


    const handleBtnToggle = () => {
        setToggleBtn(!toggleBtn);
    }

    const headerClass = classNames(
        "accordion__header",
        {
            "is-expanded": toggleBtn,
        }
    )

    const bodyClass = classNames(
        "accordion__content u-padd--top--xsml u-padd--bottom--xsml",
        {
            "is-expanded": toggleBtn
        }
    )



    return (
        <div className="accordion" >
            <div className="accordion__item" >
                <h3 className={headerClass} onClick={() => handleBtnToggle()}>
                    {header}
                </h3>
                <div className={bodyClass}>
                    {children}
                </div>
            </div>
        </div>
    )
}
