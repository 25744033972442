import React from 'react';
import { observer, inject } from 'mobx-react';

import { ClockTemplate } from 'themes/common/components/subheader';

export default inject(stores => ({
    clock: stores.stateStore.clock
}))(observer(function (props) {
    return (
        <ClockTemplate {...props} />
    )
}));