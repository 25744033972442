import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { validate } from "../../../../pages/administration/password-change/utils";
import { PasswordChangeNewPasswordTemplate } from "themes/pages/administration/password-change/components";

export default function PasswordChangeNewPasswordSection(props) {
	const { userInfo, passwordPattern } = props;
	const {
		formState: { isSubmitting },
	} = useFormContext();
	const [progressCounter, setProgressCounter] = useState(0);

	if (isSubmitting && progressCounter !== 0) {
		setProgressCounter(0);
	}

	const onChangeInputPassword = (e) => {
		const passwordInput = validate(e.target.value, userInfo);
		setProgressCounter(passwordInput * 25);
	};

	return (
		<PasswordChangeNewPasswordTemplate
			progressCounter={progressCounter}
			onChangeInputPassword={onChangeInputPassword}
			passwordPattern={passwordPattern}
		/>
	);
}
