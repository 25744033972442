import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { runInAction } from 'mobx';
import classnames from 'classnames';

import {
	SportOfferContext,
	OfferOptionsContext,
	LookupContext,
	OfferStoreContext,
} from '@gp/components';

export const ColumnTypeSelectorsTemplate = observer(function ColumnTypeSelectorsTemplate(props) {
	const sport = useContext(SportOfferContext);
	const viewStore = useContext(OfferStoreContext);
	const lookups = useContext(LookupContext);
	const options = useContext(OfferOptionsContext);

	const sportSelectors = viewStore.configuration.bettingTypeSelectorsStore.getSportSelector(sport);

	const availableSelectors = sport.headers.selectors.filter(s => s.primary.some(p => lookups.bettingTypesByAbrv.has(p)));

	function updateSelectors(column, newSelector) {
		runInAction(() => {
			// NOTE: every change on sportSelectors will update state that is why it is wrapped in the runInAction

			// pick selector configuration for available sport selectors
			const chosenSelector = availableSelectors.find(s => s.name === newSelector);

			const existingSelectorColumn = Object.values(sportSelectors)
				.findIndex(ss => ss.name === chosenSelector.name) + 1; // +1 because index is 0-based and columns are 1-based

			if (existingSelectorColumn > 0) {
				// we need to swap
				const temp = sportSelectors[column];

				sportSelectors[column] = {
					name: chosenSelector.name,
					tips: chosenSelector.tips,
					displayTips: chosenSelector.displayTips,
					bettingTypes: chosenSelector.primary,
					secondaryBettingTypes: chosenSelector.secondary
				};
				sportSelectors[`${existingSelectorColumn}`] = temp;
			}
			else {
				// do normal update
				sportSelectors[column] = {
					name: chosenSelector.name,
					tips: chosenSelector.tips,
					displayTips: chosenSelector.displayTips,
					bettingTypes: chosenSelector.primary,
					secondaryBettingTypes: chosenSelector.secondary
				}
			}

			viewStore.configuration.bettingTypeSelectorsStore.setSportSelector(sport, sportSelectors);
		});
	}

	const disabledOptions = Object.keys(sportSelectors)
		.filter(colIdx => sportSelectors[colIdx] != null && colIdx <= options.numberOfColumns)
		.map(colIdx => sportSelectors[colIdx].name);

	return [...Array(options.numberOfColumns)].map((_, i) => (
		<ColumnBettingTypeSelectorDropDown
			key={i}

			sportSelectors={sportSelectors}
			disabledOptions={disabledOptions}
			availableSelectors={availableSelectors}
			updateSelectors={updateSelectors}
			colIndex={i + 1}
		/>
	));
})

function ColumnBettingTypeSelectorDropDown(props) {

	const {
		sportSelectors,
		disabledOptions,
		availableSelectors,
		updateSelectors,
		colIndex,
	} = props;

	if (sportSelectors?.[`${colIndex}`] == null) {
		return <div className="header--sport__item header--sport__item--type" />;
	}

	const columnSelector = sportSelectors[`${colIndex}`]

	return (
		<Dropdown
			columnIndex={colIndex}
			disabledOptions={disabledOptions}
			value={columnSelector}
			allOptions={availableSelectors.map(s => s.name)}
			onChange={(newValue) => updateSelectors(`${colIndex}`, newValue)}
		/>
	)
}

function Dropdown(props) {
	const {
		value,
		onChange,
		disabledOptions,
		allOptions,
	} = props;

	return (
		<div className="header--sport__item header--sport__item--type">
			<select
				className="input input--sml input--select input--select--type"
				value={value.name}
				onChange={e => onChange(e.target.value)}
			>
				<Options
					allOptions={allOptions}
					disabledOptions={disabledOptions}
				/>
			</select>
		</div >
	)
}

function Options(props) {

	const {
		allOptions,
		disabledOptions,
	} = props;

	return allOptions.map(bt => (
		<DropdownOption
			key={bt}
			bettingType={bt}
			disabledOptions={disabledOptions}
		/>
	))
}

function DropdownOption(props) {

	const { t } = useTranslation();

	const {
		bettingType,
		disabledOptions,
	} = props;

	return (
		<option
			value={bettingType}
			className={classnames(
				"input--select--type__option",
				{
					"is-disabled": disabledOptions.includes(bettingType)
				}
			)}
			disabled={disabledOptions.includes(bettingType)}
		>
			{t('BETTING_TYPE.HEADER.' + bettingType)}
		</option>
	)
}