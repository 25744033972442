import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import {
    OfferStoreContext,

    EventContext,
    SportOfferContext,
} from '@gp/components';

import { Event } from '../../../../../common/components/offer/new-offer'

export default observer(function Body(props) {

    const {
        isExpanded,
        eventsList,
    } = useContext(OfferStoreContext);

    if (!isExpanded) {
        return null;
    }

    return eventsList.map((event, index) => {

        return (
            <SportOfferContext.Provider key={event.id} value={event.sport}>
                <EventContext.Provider value={event}>
                    <Event
                        isAlternate={index % 2 === 1}
                        displaySport
                        noSecondaryOffer={true}
                        secondaryOfferCount={0}
                    />
                </EventContext.Provider>
            </SportOfferContext.Provider>
        )
    })
})