import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { OfferOptionsContext, SportOfferContext, OfferStoreContext } from '@gp/components';

import { ColumnTypeSelectors, HeaderTipColumn } from '../../../../../../common/components/offer/new-offer/common';

function EventCount(props){

	const {
		count,
	} = props;

	return (
		<span className="counter counter--dark u-mar--left--tny">{count}</span>
	)
}

export const SportHeaderTemplate = observer(function SportHeaderTemplate(props) {

	const {
		visible,
		onShowHide,
	} = props;

	const {
		abrv,
		name,
		id,
		eventCount,
	} = useContext(SportOfferContext);

	const { t } = useTranslation();

	return (
		<div className="offer__head">
			<div className="offer__head__row">
				<div className="offer__head__data offer__header">
					<div className="offer__header__wrapper">
						<i className={"u-icon u-icon--big u-icon--sport u-icon--" + (abrv)} />
						<span className="offer__header__sport">{t(name)}</span>
						<EventCount count={eventCount} />
					</div>
				</div>

				<ColumnTypeSelectors isLive />

				<ToggleButton
					visible={visible}
					sportId={id}
					onToggle={onShowHide}
				/>
			</div>
			<div
				className={classNames(
					'offer__subheader',
					{
						'u-display--f': visible,
						'u-display--none': visible
					}
				)}
			>
				<div className="offer__subheader__data offer__subheader__empty">&nbsp;</div>

				<HeaderTipColumns />

				<div className="offer__subheader__data offer__subheader__toggle">&nbsp;</div>
			</div>
		</div>
	)
});

const HeaderTipColumns = observer(function HeaderTipColumns(props) {

	const sport = useContext(SportOfferContext);
	const {
		numberOfColumns,
	} = useContext(OfferOptionsContext);
	const {
		configuration: {
			bettingTypeSelectorsStore
		},
	} = useContext(OfferStoreContext);

	const selectors = bettingTypeSelectorsStore.getSportSelector(sport);
	const selectorsArr = Object.values(selectors).slice(0, numberOfColumns);

	return selectorsArr.map((col, index) => (
		<HeaderTipColumn
			key={col == null ? index : col.name}
			column={col}
		/>
	));
})

function ToggleButton(props) {

	const {
		visible,
		sportId,
		onToggle,
	} = props;

	return (
		<div className="offer__head__data offer__toggle">
			<button
				className="offer__toggle__btn btn btn--sml-square btn--link btn--icon-center"
				type="button"
				onClick={() => onToggle(sportId)}
			>
				<i
					className={classNames(
						'u-icon u-icon--xsml',
						{
							'u-icon--arrow-down--white': visible,
							'u-icon--arrow-right--white': !visible
						})}
				/>
			</button>
		</div>
	)
}