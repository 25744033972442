import React, { useEffect, useState } from "react";
import {
    LoaderGetterContext,
    LoaderSetterContext
} from "../context/LoaderContext";

function LoadingContainer(props) {
    const [isLoading, setIsLoading] = useState(false);

    const loaderSetterCtxValue = {
        setIsLoading: (condition) => { setIsLoading(condition) }
    }

    useEffect(() => {
        return () => setIsLoading(false)
    }, [])

    const loaderGetterCtxValue = {
        isLoading: isLoading
    }


    return (
        <LoaderGetterContext.Provider value={loaderGetterCtxValue}>
            <LoaderSetterContext.Provider value={loaderSetterCtxValue}>
                {props.children}
            </LoaderSetterContext.Provider>
        </LoaderGetterContext.Provider>
    )
}

export default LoadingContainer;