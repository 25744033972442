import React, { useState, useContext, useRef } from "react";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import ReactTooltip from "react-tooltip";
import { EventContext } from "@gp/components";

export default inject((s) => ({ tvInfoService: s.offerStore.tvInfoService }))(
	observer((props) => {
		const [tvCoverage, setTvCoverage] = useState(null);
		const [tooltipRef, setTooltipRef] = useState(null);
		const timeOutIdRef = useRef(null);

		const event = useContext(EventContext);
		const { t } = useTranslation();

		const dataTip =
			event.hasTvCoverage && tvCoverage !== ""
				? tvCoverage
				: t("COMMON.NO_DATA");

		return (
			<button
				ref={(ref) => setTooltipRef(ref)}
				data-for="tv-description"
				data-tip={dataTip}
				onMouseEnter={() => {
					clearTimeout(timeOutIdRef.current);

					if (!event.hasTvCoverage) {
						return;
					}

					if (tvCoverage != null && tvCoverage != "") {
						if (tooltipRef != null) {
							ReactTooltip.show(tooltipRef);
						}
						return;
					}

					timeOutIdRef.current = setTimeout(async () => {
						try {
							const result = await props.tvInfoService.getTvInfo(
								event.id
							);

							setTvCoverage(result);
							if (tooltipRef != null) {
								ReactTooltip.show(tooltipRef);
							}
						} catch (err) {
							console.error("Failed to fetch TV info", err);
							clearTimeout(timeoutId);

							setTvCoverage(null);
							if (tooltipRef != null) {
								ReactTooltip.hide(tooltipRef);
							}
						}
					}, 250);
				}}
				onMouseLeave={() => {
					clearTimeout(timeOutIdRef.current);
					if (tooltipRef != null) {
						ReactTooltip.hide(tooltipRef);
					}
				}}
				disabled={!event.hasTvCoverage}
				className={classNames(
					"offer__actions__btn offer__actions__tv btn btn--xtny-square btn--link btn--icon-center btn--icon-center",
					{
						"is-disabled": !event.hasTvCoverage,
					}
				)}
				type="button"
			>
				<i className="offer__actions__icon u-icon u-icon--med u-icon--tv" />
			</button>
		);
	})
);
