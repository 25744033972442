import React, { useContext, useRef, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { WindowScroller, AutoSizer, List } from 'react-virtualized';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _ from 'lodash';

import { OfferStoreContext, SportOfferContext, EventContext, getSecondaryOfferColumns, OfferOptionsContext } from '@gp/components';

import { SportHeader, EventLive, EventOfferLive } from '../../../../../../common/components/offer/new-offer';

class EventRenderErrorBoundary extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			hasError: false
		};
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		console.error(`--- ERROR WHILE RENDERING EVENT ${this.props.event.id} ---`);
		console.error(`Event: ${this.props.event}`, error, errorInfo);
	}

	render() {
		if (this.state.hasError) {
			// Don't render event if there was an error while rendering
			return null;
		}

		return this.props.children;
	}
}

export const LiveSportsTemplate = observer((props) => {
	const { t } = useTranslation();
	const viewStore = useContext(OfferStoreContext);
	const options = useContext(OfferOptionsContext);

	const [hiddenSports, setHiddenSports] = useState([]);
	const wsRef = useRef();

	/**
	 * @param {{eventCount: number, headers: any, events?: []}[]} sports 
	 * @param {(eventId: string) => boolean} shouldAddEvent returns true if event should be in the list
	 * @param {[]} hiddenSportEvents
	 */
	const flatSports = () => {
		const listToReduce = viewStore.displayFavorites ? props.sports.filter(s => s.events?.some(e => viewStore.eventFavoritesFilter(e.id))) : props.sports;
		const flat = listToReduce.reduce((acc, sport) => {

			acc.push({
				isHeader: true,
				isMain: false,
				isSecondary: false,
				sport: {
					abrv: sport.abrv,
					id: sport.id,
					name: sport.name,
					isLive: sport.isLive,
					sortOrder: sport.sortOrder,
					eventCount: viewStore.displayFavorites ? sport.events.filter(e => viewStore.eventFavoritesFilter(e.id)).length : sport.events.length,
					headers: sport.headers,
				},
			});

			if (hiddenSports.includes(sport.id)) {
				// skip mapping sport events if sport is hidden
				return acc;
			}

			const eventsList = viewStore.displayFavorites ? sport.events.filter(item => viewStore.eventFavoritesFilter(item.id)) : sport.events;

			eventsList.forEach((e, eIdx) => {
				let eventAdditionalRow = null;

				const eventRow = {
					isHeader: false,
					isMain: true,
					isSecondary: false,
					index: eIdx,
					event: e,
					hasSecondaryOffer: false,
					sport: {
						abrv: sport.abrv,
						id: sport.id,
						name: sport.name,
						isLive: sport.isLive,
						sortOrder: sport.sortOrder,
						eventCount: sport.eventCount,
						headers: sport.headers,
					},
				};

				// check if secondary offer exists
				const eventOffer = viewStore.eventKeysMap.get(e.id);
				if (eventOffer != null && eventOffer.size !== 0) {
					const eventKeys = Array.from(eventOffer.values());
					// TODO: reconfigure secondary offer based on primary offer selected headers
					const secondaryColumns = getSecondaryOfferColumns(eventKeys, viewStore.configuration.bettingTypeSelectorsStore.getSportSelector(sport), options.numberOfColumns);

					if (!secondaryColumns.isEmpty) {
						eventAdditionalRow = {
							isHeader: false,
							isMain: false,
							isSecondary: true,
							index: eIdx,
							event: e,
							secondaryOffer: secondaryColumns,
							sport: {
								abrv: sport.abrv,
								id: sport.id,
								name: sport.name,
								isLive: sport.isLive,
								sortOrder: sport.sortOrder,
								eventCount: sport.eventCount,
								headers: sport.headers,
							},
						};

						eventRow.hasSecondaryOffer = true;
					}
				}

				// must be in this order!!!
				acc.push(eventRow);
				if (eventAdditionalRow != null) {
					acc.push(eventAdditionalRow);
				}
			});

			return acc;
		}, []);

		return flat;
	}

	const flatSportEventsList = flatSports();

	useEffect(() => {
		wsRef.current.updatePosition();
	}, [flatSportEventsList]);

	const toggleSportVisibility = (sportId) => {
		const hiddenSportsCopy = [...hiddenSports];
		if (hiddenSportsCopy.includes(sportId)) {
			const sIdx = hiddenSportsCopy.indexOf(sportId);
			if (sIdx !== -1) {
				hiddenSportsCopy.splice(sIdx, 1);
				setHiddenSports(hiddenSportsCopy);
			}
		}
		else {
			hiddenSportsCopy.push(sportId);
			setHiddenSports(hiddenSportsCopy);
		}
	}

	const _getRowHeight = ({ index }) => {
		const item = flatSportEventsList[index];

		if (item.isHeader) {
			if (hiddenSports.includes(item.sport.id)) {
				// this means sport is collapsed
				return 36;
			}

			return 66;
		}

		// event main and secondary offer have the same height
		return 30;
	}

	const _renderRow = ({ key, index, style }) => {
		const item = flatSportEventsList[index];

		if (item.isHeader) {
			return (
				<div key={item.sport.id} style={style} className="offer offer--live offer--live--thead">
					<SportOfferContext.Provider value={item.sport}>
						<SportHeader
							sport={item.sport}
							visible={!hiddenSports.includes(item.sport.id)}
							onShowHide={toggleSportVisibility}
						/>
					</SportOfferContext.Provider>
				</div>
			)
		}

		// render half time offer
		if (item.isSecondary) {
			return (
				<div
					key={item.event.id + '-secondary'}
					style={style}
					className="offer offer--live offer--live--tbody"
				>
					<SportOfferContext.Provider value={item.sport}>
						<EventContext.Provider key={item.event.id} value={item.event}>
							<div className={classNames("offer__body__row offer__body__row offer__ht", { 'odd': (item.index % 2 !== 0) })}>
								<div className="offer__ht__inner">
									{t('LIVE.FIRST_HALF_TIME')}
								</div>
								<EventOfferLive columns={item.secondaryOffer} />
							</div>
						</EventContext.Provider>
					</SportOfferContext.Provider>
				</div>
			)
		}

		// render event
		return (
			<div
				key={item.event.id}
				style={style}
				className="offer offer--live offer--live--tbody"
			>
				<EventRenderErrorBoundary event={item.event}>
					<SportOfferContext.Provider value={item.sport}>
						<EventContext.Provider key={item.event.id} value={item.event}>
							<EventLive
								headers={item.sport.headers}
								isAlternate={item.index % 2 === 0}
								hasSecondaryOffer={item.hasSecondaryOffer}
								noSecondaryOffer
							/>
						</EventContext.Provider>
					</SportOfferContext.Provider>
				</EventRenderErrorBoundary>
			</div>
		)
	}

	let elRef;
	return (
		<div className="offer--virtualized">
			<WindowScroller ref={wsRef} scrollElement={elRef}>
				{(wsProps) => (
					<AutoSizer disableHeight>
						{(asProps) => (
							<List
								autoHeight
								height={wsProps.height}
								width={asProps.width}
								isScrolling={wsProps.isScrolling}
								onScroll={wsProps.onChildScroll}
								overscanRowCount={10}
								rowHeight={_getRowHeight}
								rowCount={flatSportEventsList.length}
								rowRenderer={_renderRow}
								scrollTop={wsProps.scrollTop}
							/>
						)}
					</AutoSizer>
				)}
			</WindowScroller>
		</div>
	)
});