import React from 'react';
import moment from 'moment';
import { defaultTemplate } from '../../../../../common/hoc';

const dateFormat = 'D.M.YYYY HH:mm:ss';

export default defaultTemplate(function (props) {
    const { loginHistoryViewStore, t } = props;
    const { isLoading, loginHistory } = loginHistoryViewStore;

    if (isLoading) {
        return <div />;
    }

    return (
        <div className="col col-sml-6">
            <div className="header--primary__top__activity">
                <div>{t("LOGIN_HISTORY.LAST_LOGIN", { date: moment(loginHistory.lastLogin).format(dateFormat) })}</div>
                <div>{t("LOGIN_HISTORY.LOGGED_IN_FROM", { date: moment(loginHistory.currentLogin).format(dateFormat) })}</div>
            </div>
        </div>
    );
});