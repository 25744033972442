import React, { useContext, useMemo } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import {
	OfferStoreContext,
	OfferOptionsContext,
	usePrevious,
} from '@gp/components';
import { toDisplayOdd } from "@gp/utility";


const calculateTipClass = function (visible, prevValue, newValue) {
	if (visible) {
		if (newValue > prevValue) {
			// value increased -> green indicator
			return 'inc';
		}
		else if (newValue < prevValue) {
			// value decreased -> red indicator
			return 'desc';
		}
		else {
			return '';
		}
	}
	else {
		return '';
	}
}

class TipButton extends React.PureComponent {
	render() {
		const displayTipValue = toDisplayOdd(this.props.value);

		return (
			<>
				{this.props.displayTipName ? (
					<div className="quote quote--button--alt u-mar--right--tny u-type--tny" title={this.props.label}>{this.props.label}</div>
				) : null}
				<button
					type="button"
					title={displayTipValue}
					onClick={this.props.onClick}
					disabled={this.props.isLocked}
					className={classNames(
						'quote quote--button',
						{
							'one-click': this.props.isOneClickBetActive,
							'is-active': this.props.isSelected,
							'u-type--color--text--primary': this.props.displayTipName,
							'desc': this.props.indicator === -1,
							'inc': this.props.indicator === 1,
						}
					)}
				>
					{displayTipValue}
				</button>
			</>
		)
	}
}


export const OfferTipTemplate = observer((props) => {
	const offerStore = useContext(OfferStoreContext);
	const offerOptionsContext = useContext(OfferOptionsContext);

	if (props.isMargin) {
		return (
			<div className={classNames(
				"quote quote--button--alt",
				{
					"is-long": props.margin.length > 7
				}
			)}>
				{props.margin}
			</div>
		);
	}

	if (props.isResult) {
		return (
			<div className="quote quote--button--alt">
				{props.result}
			</div>
		);
	}

	if (props.tip == undefined || !props.tip.value) {
		return (
			<span className="quote quote--placeholder" />
		);
	}

	const event = offerStore.eventsMap.get(props.tip.eventId);
	const eventKeys = offerStore.eventKeysMap.get(props.tip.eventId);
	const key = eventKeys?.get(props.tip.keyId);

	const isSelected = offerOptionsContext.isTipSelected(props.tip.id);

	return (
		<TipButton
			value={props.tip.value}
			label={props.tip.tip}
			indicator={props.tip.indicator}
			displayTipName={props.displayTipName}
			isLocked={isSelected ? false : (event?.isLocked || key?.isLocked || props.tip.isLocked)}
			onClick={e => offerOptionsContext.onTipClick(props.tip)}
			isSelected={isSelected}
			isOneClickBetActive={offerOptionsContext.isOneClickBetActive}
		/>
	);
});