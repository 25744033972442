import React, { useContext } from "react";
import { observer } from "mobx-react";

import { MyBetsStoreContext } from "../../../../../common/context";
import { MyBetsCashoutConfirmation } from "../../../../../common/components/my-bets";

export default observer(function CashoutConfirmationPopUp(props) {
  const {
    cashoutStore: {
      isCashoutModalOpen,
      cashout,
      closeCashoutModal,
      isCashoutFormSubmitting,
      cashoutModalData,
    },
  } = useContext(MyBetsStoreContext);

  if (!isCashoutModalOpen) {
    return null;
  }

  return (
    <MyBetsCashoutConfirmation
      isOpen={isCashoutModalOpen}
      {...cashoutModalData}
      onAccept={cashout}
      onClose={closeCashoutModal}
      isSubmitting={isCashoutFormSubmitting}
    />
  );
});
