import React, { useState } from "react";
import { PasswordInputTemplate } from "themes/common/components/inputs";
import { useFormContext } from "react-hook-form";

export default function PasswordInput({
	validate,
	pattern,
	onChange,
	...props
}) {
	const form = useFormContext();
	const [showIcon, setShowIcon] = useState(false);
	const [pwdIcon, setPwdIcon] = useState(false);
	const [pwdType, setPwdType] = useState("password");

	const field = form.register(props.name, {
		required: {
			value: props.required,
			message: props.errorMessage || "COMMON.REQUIRED_FIELD",
		},
		disabled: props.disabled,
		onChange,
		validate,
		pattern,
	});

	const togglePwdType = () => {
		setPwdIcon(!pwdIcon);
		if (pwdType == "password") {
			setPwdType("text");
		} else {
			setPwdType("password");
		}
	};

	const handleInput = (e) => {
		e.persist();
		if (e.target.value.length == 0) {
			setShowIcon(false);
		} else if (!showIcon) {
			setShowIcon(true);
		}
	};

	return (
		<PasswordInputTemplate
			field={field}
			togglePwdType={togglePwdType}
			handleInput={handleInput}
			pwdIcon={pwdIcon}
			pwdType={pwdType}
			showIcon={showIcon}
			{...props}
		/>
	);
}
