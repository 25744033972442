import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import { BetSlipTypes, BetSlipBetType, parseSpecifier, formatTip, EventType } from '@gp/gp-javascript-utils';

import { defaultTemplate } from '../../../../../common/hoc';
import { EmptyState } from '../../../../../common/components/empty-state';
import { BetSlipBetTypeSelector, OneClickBet } from '../../../../../common/components/bet-slip';
import { Currency } from '../../../../../common/components/currency';
import { Number } from '../../../../../common/components/display';
import { NumericInput } from '../../../../../common/components/inputs';
import {
    BetSlipTypeSelector,
    BetSlipSubmitConfirmationModal,
    BetSlipSubmitSuccessModal,
    BetSlipConfirmationModal
} from '../../../../../common/components/bet-slip';

function TeamName(props) {
    return (
        <React.Fragment>{props.name} {props.gender === 1 ? <React.Fragment>({props.t('OFFER.GENDER.WOMEN')})</React.Fragment> : null}</React.Fragment>
    )
}

// let lastScrollY = 0;
// let ticking = false;

class BetSlipTemplate extends React.Component {
    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        await this.props.betSlipStore.betSlipScrollStore.enableDisable();
        this.props.betSlipStore.betSlipScrollStore.currentScroll = 0;
        this.props.betSlipStore.betSlipScrollStore.onInitialize();
        await this.props.betSlipStore.betSlipScrollStore.enableDisable();
    }

    componentWillUnmount() {
        this.props.betSlipStore.betSlipScrollStore.onDispose();
    }

    render() {
        const {
            t,
            betSlipStore,
            betSlipStore: {
                betSlipScrollStore: {
                    isEnabled,
                    enableDisable
                }
            }
        } = this.props;

        return (
            <>
                <div className="slip">
                    <BetSlipBetTypeSelector store={betSlipStore} />
                    <Loader store={betSlipStore} />
                    <SlipHeader store={betSlipStore} />
                    <SlipBody store={betSlipStore} />
                </div>
                {/* SCROLLER ENABLED/DISABLED BUTTON */}
                <div className="widget--scroller">
                    <button className="btn btn--sml btn--link" type="button" onClick={enableDisable}>
                        <strong>{t('BET_SLIP.SCROLLER.LABEL', { label: !isEnabled ? t('BET_SLIP.SCROLLER.ENABLED') : t('BET_SLIP.SCROLLER.DISABLED') })}</strong>
                    </button>
                </div>
                <BetSlipConfirm store={betSlipStore} />
                <BetSlipSubmitSuccess store={betSlipStore} />
                <BetSlipOneClickBetClear store={betSlipStore} />
                <BetSlipReset store={betSlipStore} />
            </>
        );
    }
}

const BetSlipConfirm = observer(function BetSlipConfirm(props) {
    const {
        showConfirmationModal,
        onSubmitSlip,
        toggleConfirmationModal
    } = props.store;

    if (!showConfirmationModal) {
        return null;
    }

    return <BetSlipSubmitConfirmationModal onAccept={onSubmitSlip} onReject={toggleConfirmationModal} />
});

const BetSlipSubmitSuccess = observer(function BetSlipSubmitSuccess(props) {
    const {
        showSubmitSuccessModal,
        toggleSubmitSuccessModal
    } = props.store;

    if (!showSubmitSuccessModal) {
        return null;
    }

    return <BetSlipSubmitSuccessModal onClose={toggleSubmitSuccessModal} />
});

const BetSlipOneClickBetClear = observer(function BetSlipOneClickBetClear(props) {
    const {
        showOneClickBetClearSlipModal,
        onBetSlipBetTypeChangeAccept,
        onCloseOneClickBetChangeModal
    } = props.store;

    if (!showOneClickBetClearSlipModal) {
        return null;
    }

    return <BetSlipConfirmationModal
        headerText="LIVE.INFO"
        bodyText="BET_SLIP.ONE_CLICK_BET_CLEAR_CONFIRMATION"
        onAccept={onBetSlipBetTypeChangeAccept}
        onReject={onCloseOneClickBetChangeModal}
    />
});

const BetSlipReset = observer(function BetSlipReset(props) {
    const {
        showSlipResetModal,
        onAcceptSlipReset,
        onRejectSlipReset
    } = props.store;

    if (!showSlipResetModal) {
        return null;
    }

    return <BetSlipConfirmationModal
        headerText="LIVE.INFO"
        bodyText="BET_SLIP.CLEAR_BET_SLIP_CONFIRMATION"
        onAccept={onAcceptSlipReset}
        onReject={onRejectSlipReset}
    />
});

const SlipBody = observer(function SlipBody(props) {
    const { t } = useTranslation();

    const {
        betSlipState: {
            isSlipEmpty,
            betSlipBetType   
        }
    } = props.store;

    const emptyStateStyle = {
        wrapper: "widget__body",
    };

    if (betSlipBetType === BetSlipBetType.OneClickBet) {
        return <OneClickBet />
    }

    if (isSlipEmpty) {
        return (
            <EmptyState styles={emptyStateStyle}>
                {t('BET_SLIP.CHOOSE_QUOTES')}
            </EmptyState>
        )
    }

    return (
        <div className="slip__body">
            <CountdownLoader store={props.store} />
            <SlipEvents store={props.store} />            
            <RemoveAllButton store={props.store} />

            <SystemSection store={props.store} />
            <div className="slip__info">
                <div className="slip__item">
                    <EventCount store={props.store} />
                </div>
                <Combinations store={props.store} />
                {/* STAKES */}
                <Stakes store={props.store} />
                {/* EO STAKES */}
                <HandlingFee store={props.store} />
                <SlipMaxCoefficient store={props.store} />
                <div className="slip__item">
                    <div className="slip__item__row">
                        <GainLabel store={props.store} />
                        <Gain store={props.store} />
                    </div>
                </div>
                <GainBonusPercentage store={props.store} />
                <GainTaxPercentage store={props.store} />
                <MaxGain store={props.store} />
                <Payment store={props.store} />
            </div>
            <CheckOdds store={props.store} />
            <SlipFooter store={props.store} />
        </div>
    );
});

const Loader = observer(function Loader(props) {
    const { t } = useTranslation();

    const {
        isLoading,
        isCountdownVisible
    } = props.store;

    if (!isLoading || isCountdownVisible) {
        return null;
    }
    
    return (
        <div className="loader loader--bet-slip">
            <div className="loader__card">
                <div className="loader__card__title">{t('HOME.LOADING')}...</div>
                <span />
            </div>
        </div>
    )
});

const CountdownLoader = observer(function CountdownLoader(props) {
    const { t } = useTranslation();

    const {
        isCountdownVisible,
        countdownCounter
    } = props.store;

    if (!isCountdownVisible) {
        return null;
    }

    return (
        <div className="loader loader--bet-slip">
            <div className="loader__card">
                <div className="loader--countdown">{countdownCounter}</div>
                <div className="loader__card__title">{t('HOME.LOADING')}...</div>
                <span />
            </div>
        </div>
    )
});

const SlipHeader = observer(function SlipHeader(props) {
    const {
        isSlipEmpty,
        betSlipBetType
    } = props.store;

    if (isSlipEmpty || betSlipBetType === BetSlipBetType.OneClickBet) {
        return null;
    }

    return <BetSlipTypeSelector store={props.store} />;
});

const SlipEvents = function SlipEvents(props) {
    return <SlipEventList store={props.store} />
};

const SlipEventList = observer(function SlipEventList(props) {
    const {
        betSlipState: {
            slipEventEntries
        }
    } = props.store;

    return Object.keys(slipEventEntries).map((eventId, idx) => {
        const eventOffer = slipEventEntries[eventId]; // array of offers for the same event
        const eventDetails = eventOffer[0].sportData;

        return <SlipEvent key={idx} store={props.store} eventId={eventId} eventDetails={eventDetails} eventOffer={eventOffer} />
    });
});

const SlipEvent = observer(function SlipEvent(props) {
    const {
        eventId,
        eventDetails,
        eventOffer
    } = props;

    return <div className="slip__match">
        <div className="slip__match__pair">
            <EventDetails eventDetails={eventDetails} />
            <div className="slip__match__pair__actions">
                <BankButton eventId={eventId} store={props.store} />
                <RemoveEventOfferButton eventId={eventId} store={props.store} />
            </div>
        </div>
        <EventOfferList eventOffer={eventOffer} store={props.store} />
    </div>
});

const EventOfferList = observer(function EventOfferList(props) {
    const {
        eventOffer
    } = props;

    return eventOffer.map((offer, oIdx) => {
        return <EventOfferItem key={oIdx} offer={offer} store={props.store} eventOffer={eventOffer} />
    })
});

const EventOfferItem = observer(function EventOfferItem(props) {
    const { t } = useTranslation();

    const {
        offer,
        eventOffer
    } = props;

    const slipMatchPickClasses = classnames(
        'slip__match__pick',
        {
            'is-excluded': offer.isExcluded,
            'bet-lock': offer.isEventLocked || offer.isKeyLocked || offer.isOfferLocked || offer.errors != null,
            'bet-stop': offer.isDeleted
        }
    );

    const quoteClasses = classnames(
        'quote--button--sml',
        {
            'desc': offer.indicator === -1,
            'inc': offer.indicator === 1
        }
    );

    return (
        <div className={slipMatchPickClasses}>
            <div className="slip__match__pick__row">
                <div className="slip__match__pick__bet">
                    {offer.sportData.eventType === EventType.LongTerm ? null : (
                        <React.Fragment>
                            {parseSpecifier(offer.sportData.bettingTypeNameForBettingSlip, offer.specifier, {
                                competitor1: t('OFFER.COMPETITORS.HOME'),
                                competitor2: t('OFFER.COMPETITORS.AWAY')
                            }).parsedString}
                        </React.Fragment>
                    )}
                    <span className="slip__match__pick__bet__type">
                        {offer.displayTip}
                    </span>
                </div>
                <div className="slip__match__pick__odd">
                    <div className={`${quoteClasses} ${offer.isExcluded ? "u-type--color--text--tertiary" : ""}`}>
                        {offer.displayValue}
                    </div>
                    <div className="slip__match__pick__odd__actions">
                        <OfferIncludeExclude store={props.store} offer={offer} />
                        <RemoveFromBetSlip store={props.store} offer={offer} eventOffer={eventOffer} />
                    </div>
                </div>
            </div>
        </div>
    );
});

const OfferIncludeExclude = observer(function OfferIncludeExclude(props) {
    const {
        offer,
        store: {
            toggleTipExclude,
            betSlipState: {
                currentSlipOffers
            }
        }
    } = props;

    if (currentSlipOffers.length <= 1 && !offer.isExcluded) {
        return null;
    }

    return <button className="slip__match__btn" type="button" onClick={() => toggleTipExclude({ id: offer.bettingOfferId })}>
        <OfferIncludeExcludeIcon offer={offer} />
    </button>
});

const OfferIncludeExcludeIcon = observer(function OfferIncludeExcludeIcon(props) {
    const {
        offer: {
            isExcluded
        }
    } = props;

    if (isExcluded) {
        return <i className="u-icon u-icon--xsml u-icon--check--include" />;
    }

    return <i className="u-icon u-icon--xsml u-icon--check--exclude" />;
});

const RemoveFromBetSlip = observer(function RemoveFromBetSlip(props) {
    const {
        eventOffer,
        offer
    } = props;

    const {
        removeFromBetSlip
    } = props.store;

    if (eventOffer.length <= 1) {
        return null;
    }

    return <button className="slip__match__btn" type="button" onClick={() => removeFromBetSlip({ id: offer.bettingOfferId })}>
        <i className="u-icon u-icon--xsml u-icon--remove" />
    </button>
});

const EventDetails = observer(function EventDetails(props) {
    const { t } = useTranslation();

    const {
        eventDetails
    } = props;

    if (eventDetails.eventType !== EventType.Normal) {
        return <div>{eventDetails.eventName}</div>
    }

    return (
        <div>
            <TeamName name={eventDetails.teamOneName} gender={eventDetails.teamOneGender} t={t} /> - <TeamName name={eventDetails.teamTwoName} gender={eventDetails.teamTwoGender} t={t} />
        </div>
    )
});

const BankButton = observer(function BankButton(props) {
    const {
        addRemoveBank,
        isEventBank,
        betSlipState: {
            betSlipType
        }
    } = props.store;

    const {
        eventId
    } = props;


    if (betSlipType !== BetSlipTypes.System) {
        return null;
    }

    return (
        <button
            className={"slip__match__btn slip__match__btn--bank" + (isEventBank(eventId) ? ' is-active' : '')}
            type="button"
            onClick={() => addRemoveBank(eventId)}
        >
            B
        </button>
    )
});

const RemoveEventOfferButton = observer(function RemoveEventOfferButton(props) {
    const {
        onRemoveEventOfferFromBetSlip
    } = props.store;

    const {
        eventId
    } = props;

    return (
        <button
            className="slip__match__btn"
            type="button"
            onClick={() => onRemoveEventOfferFromBetSlip(eventId)}
        >
            <i className="u-icon u-icon--xsml u-icon--remove--light" />
        </button>
    )
});

const RemoveAllButton = observer(function RemoveAllButton(props) {
    const { t } = useTranslation();

    const {
        onResetSlip,
        betSlipState: {
            slipSize,
        }
    } = props.store;

    if (slipSize <= 1) {
        return null;
    }

    return (
        <div className="slip__item slip__item--remove-all">
            <div className="slip__item__row">
                <button className="btn btn--tny btn--link" onClick={onResetSlip}>{t('BET_SLIP.REMOVE_ALL_BETS')}</button>
            </div>
        </div>
    )
});

const Stakes = observer(function Stakes(props) {
    const { t } = useTranslation();

    const {
        betSlipState: {
            combinations
        }
    } = props.store;

    if (combinations > 1) {
        return (
            <React.Fragment>
                <div className="slip__item">
                    <div className="slip__item__row">
                        <div className="slip__item__row--left">{t('BET_SLIP.STAKE_PER_COMBINATION')}</div>
                        <div className="slip__item__row--right">
                            <StakePerCombination store={props.store} />
                        </div>
                    </div>
                </div>
                <div className="slip__item">
                    <div className="slip__item__row">
                        <div className="slip__item__row--left">{t('BET_SLIP.TOTAL_STAKE')}</div>
                        <div className="slip__item__row--right">
                            <TotalStake store={props.store} />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    return (
        <div className="slip__item">
            <div className="slip__item__row">
                <div className="slip__item__row--left">{t('BET_SLIP.TOTAL_STAKE')}</div>
                <div className="slip__item__row--right">
                    <DefaultStakeButton store={props.store} />
                    <TotalStake store={props.store} />
                </div>
            </div>
        </div>
    )
});

const StakePerCombination = observer(function StakePerCombination(props) {
    const {
        onPaymentPerCombinationApply,
        checkLengthValue,
        onPaymentPerCombinationChange,
        betSlipState: {
            isProcessingSlipEmpty,
            paymentPerCombination
        }
    } = props.store;

    return (
        <>
            <NumericInput
                disabled={isProcessingSlipEmpty}
                className="input input--tny"
                name="paymentPerCombination"
                value={paymentPerCombination}
                onChange={onPaymentPerCombinationChange}
                onBlur={onPaymentPerCombinationApply}
                onInput={checkLengthValue}
            />
            <Currency component="span" />
        </>
    )
});

const TotalStake = observer(function TotalStake(props) {
    const {
        onTotalStakeApply,
        checkLengthValue,
        onTotalStakeChange,
        betSlipState: {
            isProcessingSlipEmpty,
            payment
        }
    } = props.store;

    return (
        <>
            <NumericInput
                disabled={isProcessingSlipEmpty}
                className="input input--tny"
                name="totalStake"
                value={payment}
                onChange={onTotalStakeChange}
                onBlur={onTotalStakeApply}
                onInput={checkLengthValue}
            />
            <Currency component="span" />
        </>
    )
});

const DefaultStakeButton = observer(function DefaultStakeButton(props) {
    const {
        setDefaultBetSlipStake
    } = props.store;

    return (
        <button
            className="btn btn--tny btn--accent btn--icon-center u-mar--right--tny"
            type="button"
            onClick={setDefaultBetSlipStake}
        >
            <i className="u-icon u-icon--sml u-icon--set-stake" />
        </button>
    )
});

const SystemSection = observer(function SystemSection(props) {
    const {
        betSlipState: {
            betSlipType
        }
    } = props.store;

    if (betSlipType !== BetSlipTypes.System) {
        return null;
    }

    return (
        <div className="slip__system">
            <AddRemoveCombinations store={props.store} />
        </div>
    )
});

const AddRemoveCombinations = observer(function AddRemoveCombinations(props) {
    const {
        betSlipState: {
            availableCombinations
        }
    } = props.store;

    if (availableCombinations == null) {
        return null;
    }

    return (
        <>
            {availableCombinations.map((combo, i) => (
                <AvailableCombinationItem combo={combo} key={i} store={props.store} />
            ))}
        </>
    )
});

const AvailableCombinationItem = observer(function AvailableCombinationItem(props) {
    const {
        addRemoveCombination,
        isCombinationSelected,
        betSlipState: {
            bankOfferCount
        }
    } = props.store;

    const {
        combo
    } = props;

    return (
        <button
            className={`slip__system__btn btn btn--xsml ${(isCombinationSelected(combo.key)) ? 'is-selected' : ''}`}
            onClick={e => addRemoveCombination(combo.key, e)}
        >
            {bankOfferCount > 0 ? <React.Fragment>B{bankOfferCount} + </React.Fragment> : null}{combo.key}/{combo.total} ({combo.numberOfCombinations})
        </button>
    )
});

const EventCount = observer(function EventCount(props) {
    const { t } = useTranslation();

    const {
        betSlipState: {
            eventCount
        }
    } = props.store;

    return (
        <>
            <div className="slip__item__row">
                <div className="slip__item__row--left">{t('BET_SLIP.NO_PLAYED_EVENTS')}</div>
                <div className="slip__item__row--right">{eventCount}</div>
            </div>
        </>
    )
});

const Combinations = observer(function Combinations(props) {
    const { t } = useTranslation();

    const {
        betSlipState: {
            combinations
        }
    } = props.store;

    if (combinations <= 1) {
        return null;
    }

    return (
        <div className="slip__item">
            <div className="slip__item__row">
                <div className="slip__item__row--left">{t('BET_SLIP.NO_BETS')}</div>
                <div className="slip__item__row--right">{combinations}</div>
            </div>
        </div>
    )
});

const HandlingFee = observer(function HandlingFee(props) {
    const { t } = useTranslation();

    const {
        betSlipState: {
            isHandlingFeeActive,
            handlingFeePercentage,
            handlingFeeAmount
        }
    } = props.store;

    if (!isHandlingFeeActive) {
        return null;
    }

    return (
        <div className="slip__item">
            <div className="slip__item__row">
                <div className="slip__item__row--left">{t('BET_SLIP.HANDLING_FEE', { feePercentage: handlingFeePercentage })}</div>
                <div className="slip__item__row--right">
                    -<Number value={handlingFeeAmount} /> <Currency />
                </div>
            </div>
        </div>
    )
});

const SlipMaxCoefficient = observer(function SlipMaxCoefficient(props) {
    const { t } = useTranslation();

    const {
        betSlipState: {
            slipMaxCoefficient
        }
    } = props.store;

    return (
        <div className="slip__item">
            <div className="slip__item__row">
                <div className="slip__item__row--left">{t('BET_SLIP.MAX_COEF')}</div>
                <div className="slip__item__row--right">
                    <Number value={slipMaxCoefficient} />
                </div>
            </div>
        </div>
    )
});

const GainLabel = observer(function GainLabel(props) {
    const { t } = useTranslation();

    const {
        betSlipState: {
            gainBonusPercentage,
            gainTaxPercentage
        }
    } = props.store;

    if (gainBonusPercentage > 0 || gainTaxPercentage > 0) {
        return <div className="slip__item__row--left">{t('BET_SLIP.GAIN')}</div>
    }

    return <div className="slip__item__row--left">{t('BET_SLIP.MAX_GAIN')}</div>
});

const Gain = observer(function Gain(props) {
    const {
        checkLengthValue,
        betSlipState: {
            isProcessingSlipEmpty,
            gain,
            onGainChange,
            onGainApply
        }
    } = props.store;

    return (
        <div className="slip__item__row--right">
            <NumericInput
                disabled={isProcessingSlipEmpty}
                className="input input--tny"
                name="maxGain"
                value={gain}
                onChange={onGainChange}
                onBlur={onGainApply}
                onInput={checkLengthValue}
            />
            <Currency />
        </div>
    )
});

const GainBonusPercentage = observer(function GainBonusPercentage(props) {
    const { t } = useTranslation();

    const {
        betSlipState: {
            gainBonusPercentage,
            gainBonusAmount
        }
    } = props.store;

    if (gainBonusPercentage <= 0) {
        return null;
    }
    
    return (
        <div className="slip__item">
            <div className="slip__item__row">
                <div className="slip__item__row--left"> {t('BET_SLIP.GAIN_BONUS')} ({gainBonusPercentage} %)</div>
                <div className="slip__item__row--right">
                    +<Number value={gainBonusAmount} />
                    <Currency />
                </div>
            </div>
        </div>
    )
});

const GainTaxPercentage = observer(function GainTaxPercentage(props) {
    const { t } = useTranslation();

    const {
        betSlipState: {
            gainTaxPercentage,
            gainTaxAmount
        }
    } = props.store;

    if (gainTaxPercentage <= 0) {
        return null;
    }
    
    return (
        <div className="slip__item">
            <div className="slip__item__row">
                <div className="slip__item__row--left"> {t('BET_SLIP.GAIN_TAX')} ({gainTaxPercentage} %)</div>
                <div className="slip__item__row--right">
                    -<Number value={gainTaxAmount} />
                    <Currency />
                </div>
            </div>
        </div>
    )
});

const MaxGain = observer(function MaxGain(props) {
    const { t } = useTranslation();

    const {
        betSlipState: {
            gainBonusPercentage,
            gainTaxPercentage,
            maxGain
        }
    } = props.store;

    if (gainBonusPercentage <= 0 && gainTaxPercentage <= 0) {
        return null;
    }

    return (
        <div className="slip__item">
            <div className="slip__item__row">
                <div className="slip__item__row--left"> {t('BET_SLIP.MAX_GAIN')}</div>
                <div className="slip__item__row--right">
                    <Number value={maxGain} />
                    <Currency />
                </div>
            </div>
        </div>
    )
});

const Payment = observer(function Payment(props) {
    const { t } = useTranslation();

    const {
        betSlipState: {
            payment
        }
    } = props.store;

    return (
        <div className="slip__item">
            <div className="slip__item__row">
                <div className="slip__item__row--left">{t('BET_SLIP.PAYMENT')}</div>
                <div className="slip__item__row--right">
                    <Number value={payment} />
                    <Currency />
                </div>
            </div>
        </div>
    )
});

const CheckOdds = observer(function CheckOdds(props) {
    const { t } = useTranslation();

    const {
        betSlipState: {
            acceptOddsChanges,
            onToggleAcceptOddsChangesChange
        }
    } = props.store;

    return (
        <div className="slip__odds">
            <input 
                id="acceptOddsChanges" 
                className="input--check" 
                type="checkbox" 
                checked={acceptOddsChanges} 
                onChange={onToggleAcceptOddsChangesChange} 
            />
            <label htmlFor="acceptOddsChanges">
                {t('BET_SLIP.TYPE.CHECK_ODDS')}
            </label>
        </div>
    )
});

const SlipFooter = observer(function SlipFooter(props) {
    const { t } = useTranslation();

    const {
        hasInvalidBets,
        removeInactiveBets,
        toggleConfirmationModal
    } = props.store;

    if (hasInvalidBets) {
        return (
            <div className="slip__footer">
                <button className="slip__button slip__button--remove-inactive" onClick={removeInactiveBets}>
                    <i className="u-icon u-icon--xsml u-icon--remove--white" />
                    {t('BET_SLIP.REMOVE_INACTIVE')}
                </button>
            </div>
        )
    }

    return (
        <button className="slip__button slip__button--submit" onClick={toggleConfirmationModal}>
            <i className="u-icon u-icon--xsml u-icon--arrow-right--white" />
            {t('BET_SLIP.SUBMIT_BET')}
        </button>
    ) 
});

export default defaultTemplate(BetSlipTemplate);