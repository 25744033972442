import {
	Response,
	ErrorResponse,
} from "../../../../../administration/services/common";
import { isNullOrWhitespace } from "../../../../../utils/common";
import { EmailChangeService as EmailChangeServiceBase } from "./EmailChangeService.js";

export class EmailChangeService extends EmailChangeServiceBase {
	constructor() {
		super();
	}

	async getUser(mailKey = null) {
		const viewModel = {};
		const { username, email, isOfflineUser, isOfflineUserMailApproved } =
			App.utils.getUserFromLocalStorage();
		if (isNullOrWhitespace(mailKey)) {
			if (
				(isOfflineUser && !isOfflineUserMailApproved) ||
				!App.utils.isUserAuthenticated()
			) {
				viewModel.CurrentEmail = "----";
			} else {
				viewModel.CurrentEmail = email;
			}
		} else {
			try {
				const newEmail =
					await this.emailChangeRequestService.activateEmail({
						EmailChangeToken: mailKey,
						UserName: username,
					});
				if (!isNullOrWhitespace(newEmail)) {
					App.state.rootStore.userAuthStore.resolveUserEmail(
						newEmail
					);
					viewModel.CurrentEmail = newEmail;
				} else {
					throw new ErrorResponse({
						message: "EMAIL_ACTIVATE_ERROR",
					});
				}
			} catch (error) {
				if (error.message == "Unauthorized") {
					return new ErrorResponse();
				}

				let errorMessage = "EMAIL_ACTIVATE_ERROR";

				if (error.data.errorCode == 4000022004) {
					errorMessage = "ACCOUNT_ALREADY_ACTIVATED";
				} else if (error.data.errorCode == 4040020000) {
					errorMessage = "CONFIRMATION_LINK_INVALID";
				}

				throw new ErrorResponse({
					data: {
						CurrentEmail: isOfflineUserMailApproved
							? email
							: "----",
					},
					message: errorMessage,
				});
			}
		}
		return new Response(viewModel);
	}
}
