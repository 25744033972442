const httpClient = App.utils.httpClient;
const requestHeaders = {
    'Content-Type': 'application/json',
};

class LanguagesLookupService {
    async findLookup(isos) {

        const url = `${BaseAddress}platform/languages?sort=name|asc&rpp=1000&translate=true&isos=${isos}`

        return await httpClient.get(url, requestHeaders, { responseType: 'json' })
    }

    /**
* 
* @returns {promise}
*/
    findLookup() {
        const url = `${BaseAddress}platform/languages?sort=name|asc&rpp=1000&translate=true`
        return httpClient.get(url, requestHeaders, { responseType: 'json' })
    }
}
export default new LanguagesLookupService();