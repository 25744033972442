import React from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { OfferTemplate } from 'themes/common/components/offer';

/**
 * @typedef {Object} OfferProps
 * @property {boolean} isLive
 * @property {boolean} showSportIcon
 * @property {boolean} renderActionsBeforeOffer
 * @property {*} onRenderActions
 * @property {Object} event
 * @property {Array} offer
 * @property {boolean} showOnlyTime
 * @property {Array} main - main betting types
 * @property {Array} secondary - secondary betting types
 */

@inject(stores => ({
    offerStore: stores.offerStore
}))
@observer
@withRouter
class Offer extends React.Component {
    constructor(props) {
        super(props);

        this.viewStore = new App.offer.components.OfferViewStore(props.offerStore, props.event);
    }

    render() {
        return (
            <OfferTemplate {...this.props} viewStore={this.viewStore} />
        );
    }
}

export default Offer;