import React, { Suspense } from "react";
import { NotificationContainerTemplate } from "themes/common/components/containers";

function ContainerTemplate(props) {
    return (
        <Suspense fallback="Loading...">

            <NotificationContainerTemplate>
                {props.children}
            </NotificationContainerTemplate>
        </Suspense>
    )
}

export default ContainerTemplate;
