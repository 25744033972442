import { observer } from "mobx-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "../../../../../../common/components/buttons";
import { useAppContext, useUser } from "../../../../../../common/hooks";
import { formatDateTime } from "../../../../../../pages/administration/account-lock/utils";
function HeaderAuthNavigationTemplate() {
	const user = useUser();
	const { t } = useTranslation();
	const { culture } = useAppContext();
	const logout = async () => {
		await user.logoutUser();
		App.state.history.push(`/${culture}/home/full`);
		App.offer.rootStore.searchBarViewStore.onTermChange("");
	};
	if (user.user == null) {
		return null;
	}

	return (
		<div className="header--primary__panel">
			<div className="header--primary__panel__inner">
				{/* TODO: add info popup after login */}
				<HeaderPopup />
				<HeaderAuthNavigation />
				<div className="header--primary__account">
					<div id="headerBalances">
						<ul className="header--primary__account__list">
							<li>
								<span className="header--primary__account__label">
									{t("specific:HEADER.USER")}:{" "}
								</span>
								<span className="header--primary__account__user">
									{" "}
									{user.user.username}
								</span>
							</li>
							<UserBalance />
						</ul>
					</div>
					<div className="header--primary__actions">
						<Button
							className="header--primary__actions__logout btn btn--sml btn--neutral"
							onClick={logout}
							btnText={t("HEADER.LOGOUT")}
						/>
						<Button
							className="header--primary__actions__reload btn btn--rounded"
							onClick={() => user.refreshUserBalance()}
							btnContent={
								<i className="u-icon u-icon--lrg u-icon--refresh"></i>
							}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
export default observer(HeaderAuthNavigationTemplate);

const UserBalance = observer(function () {
	const { t } = useTranslation();
	const user = useUser();

	if (user.userAccounts == null) {
		return null;
	}

	return user.userAccounts.map((x) => {
		return (
			<li key={x.abrv}>
				<span className="header--primary__account__balance">
					{t(`specific:HEADER.${x.abrv.toUpperCase()}`)}:{" "}
				</span>
				<span>{x.balance.toFixed(2)}</span>
				<span>{x.currency.displayCode}</span>
			</li>
		);
	});
});

function HeaderAuthNavigation() {
	const { culture } = useAppContext();
	const { t } = useTranslation();

	return (
		<nav>
			<ul className="header--primary__navigation">
				<li className="header--primary__navigation__item">
					<Link
						to={`/${culture}/my-bets/open`}
						className="header--primary__navigation__link"
					>
						<span className="header--primary__navigation__thumb header--primary__navigation__thumb--bets"></span>
						<span className="header--primary__navigation__caption">
							{t("HEADER.MY_BETS")}
						</span>
					</Link>
				</li>
				<li className="header--primary__navigation__item">
					<Link
						to={`/${culture}/account-statement`}
						className="header--primary__navigation__link"
					>
						<span className="header--primary__navigation__thumb header--primary__navigation__thumb--statement"></span>
						<span className="header--primary__navigation__caption">
							{t("HEADER.ACCOUNT_STATEMENT")}
						</span>
					</Link>
				</li>
				<li className="header--primary__navigation__item">
					<Link
						to={`/${culture}/account-settings/personal-data`}
						className="header--primary__navigation__link"
					>
						<span className="header--primary__navigation__thumb header--primary__navigation__thumb--settings"></span>
						<span className="header--primary__navigation__caption">
							{t("HEADER.ACCOUNT_SETTINGS")}
						</span>
					</Link>
				</li>
			</ul>
		</nav>
	);
}

const HeaderPopup = observer((props) => {
	const {
		user,
		resolveAccountLockAfterLogin,
		resolveAlreadyLoggedInPopup,
		resolveBettingTimeoutAfterLogin,
	} = useUser();

	const { t } = useTranslation();
	const { accountLockInfo, bettingTimeoutInfo } = user;
	const isAlreadyLoggedIn =
		typeof user.isAlreadyLoggedIn === "object" &&
		!user.isAlreadyLoggedIn?.viewed;

	if (
		accountLockInfo.duration &&
		accountLockInfo.duration != 0 &&
		!accountLockInfo?.viewed
	) {
		return (
			<div className="popup--validation popup--validation--left">
				<div className="popup--validation__card">
					<div className="popup--validation__card__header">
						<span>{t("specific:COMMON.INFO_LABEL")}</span>
						<button
							className="jqCloseInfo btn btn--link"
							type="button"
							onClick={() => resolveAccountLockAfterLogin()}
						>
							<i className="u-icon u-icon--xsml u-icon--remove--white"></i>
						</button>
					</div>
					<div className="popup--validation__card__body">
						{t("USER.POPUPS.LOCK_POPUP", {
							0: accountLockInfo?.duration,
							1: formatDateTime(accountLockInfo?.startDate),
							2: formatDateTime(accountLockInfo?.endDate),
							interpolation: { escapeValue: false },
						})}
					</div>
				</div>
			</div>
		);
	}

	if (
		accountLockInfo.duration == 0 &&
		bettingTimeoutInfo.duration != null &&
		!bettingTimeoutInfo?.viewed
	) {
		return (
			<div className="popup--validation popup--validation--left">
				<div className="popup--validation__card">
					<div className="popup--validation__card__header">
						<span>{t("specific:COMMON.INFO_LABEL")}</span>
						<button
							className="jqCloseInfo btn btn--link"
							type="button"
							onClick={() => resolveBettingTimeoutAfterLogin()}
						>
							<i className="u-icon u-icon--xsml u-icon--remove--white"></i>
						</button>
					</div>
					<div className="popup--validation__card__body">
						{t("USER.POPUPS.USER_BETTING_TIMEOUT", {
							0: bettingTimeoutInfo?.duration,
							1: formatDateTime(accountLockInfo?.endDate),
							interpolation: { escapeValue: false },
						})}
					</div>
				</div>
			</div>
		);
	}

	if (accountLockInfo.duration == 0 && isAlreadyLoggedIn) {
		return (
			<div className="popup--validation popup--validation--left">
				<div className="popup--validation__card">
					<div className="popup--validation__card__header">
						<span>{t("specific:COMMON.INFO_LABEL")}</span>
						<button
							className="jqCloseInfo btn btn--link"
							type="button"
							onClick={() => resolveAlreadyLoggedInPopup()}
						>
							<i className="u-icon u-icon--xsml u-icon--remove--white"></i>
						</button>
					</div>
					<div className="popup--validation__card__body">
						{t("USER.POPUPS.IS_ALREADY_LOGGED_IN")}
					</div>
				</div>
			</div>
		);
	}

	return null;
});
