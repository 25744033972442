import React from 'react';
import ReactTooltip from 'react-tooltip';

import { defaultTemplate } from '../../../../../common/hoc';

function LiveMatchTimeTooltipTemplate(props) {
    const {
        event: {
            result: {
                scorePerPeriod
            }
        },
        tooltipId
    } = props

    return (
        <React.Fragment>
            {scorePerPeriod && (
                <ReactTooltip
                    id={tooltipId}
                    className="tooltip--primary tooltip--primary--sml u-padd--reset"
                    place="left"
                    multiline={true}
                >
                    {scorePerPeriod.map((score, idx) => {
                        return (
                            <div key={idx}>
                                <div className="tooltip__header">
                                    {`P${idx + 1}`}
                                </div>
                                <div className="tooltip__body">
                                    {`${score.home ? score.home : 0}:${score.away ? score.away : 0}`}
                                </div>
                            </div>
                        )
                    })}
                </ReactTooltip>
            )}
        </React.Fragment>
    )
}

export default defaultTemplate(LiveMatchTimeTooltipTemplate);