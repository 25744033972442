import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useLoading, useNotification, useUser } from "../../../common/hooks";
import { ContactTemplate } from "themes/pages/info-pages";
import { ContactService } from "./services";
import { ErrorPage } from "../../../common/components/error";

const contactServiceInstance = new ContactService();

export default observer(function Contact() {
	const { t } = useTranslation();
	const { setIsLoading } = useLoading();
	const methods = useForm({ mode: "onChange" });
	const { reset } = methods;
	const { user } = useUser();
	const { showSuccess, showError } = useNotification();

	const { executeRecaptcha } = useGoogleReCaptcha();

	const contactService = useMemo(() => contactServiceInstance, []);

	useEffect(() => {
		App.state.rootStore.titleStore.setTitle(
			t("INFO_PAGES.NAVIGATION.CONTACT")
		);
		if (user != undefined) {
			reset({ userName: user.username, email: user.email });
		}

		document.querySelector("html, body").scrollTo(0, 0);
	}, []);

	const onSuccess = async (data) => {
		try {
			setIsLoading(true);
			const recaptchaToken = await executeRecaptcha("sendInquiry");
			await contactService.sendInquiry({
				...data,
				challengeResponse: recaptchaToken,
			});
			reset();
			showSuccess("INFO_PAGES.CONTACT.CONTACT_US_SUCCESS");
		} catch (error) {
			showError("INFO_PAGES.CONTACT.CONTACT_US_ERROR");
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<ErrorPage>
			<ContactTemplate onSuccess={onSuccess} methods={methods} />
		</ErrorPage>
	);
});
