import { Response, ErrorResponse } from "../../../../../administration/services/common";
import { isNullOrWhitespace } from "../../../../../utils/common/helpers";
import { EmailChangeService } from "../../../../../administration/services/account-settings/email-change";

class EmailActivationService {
    constructor() {
        this.emailChangeService = new EmailChangeService();
    }

    async emailActivation(mailkey, username) {

        if (isNullOrWhitespace(mailkey)) {
            throw new ErrorResponse({ message: 'EMAIL_TOKEN_REQUIRED' });
        }

        try {
            const newEmail = await this.emailChangeService.activateEmail({ EmailChangeToken: mailkey, UserName: username });
            if (!isNullOrWhitespace(newEmail)) {
                App.state.rootStore.userAuthStore.resolveUserEmail(newEmail);
                return new Response();
            }
            throw new ErrorResponse({ message: 'EMAIL_ACTIVATE_ERROR' });
        }
        catch (error) {
            console.error(error);
            if (error?.data?.errorCode == 4000022004) {
                throw new ErrorResponse({ message: 'ACCOUNT_ALREADY_ACTIVATED' });
            }
            else if (error?.data?.errorCode == 4040020000) {
                throw new ErrorResponse({ message: 'CONFIRMATION_LINK_INVALID' });
            }
            throw new ErrorResponse({ message: 'EMAIL_ACTIVATE_ERROR' });
        }
    }
}

const emailActivationService = new EmailActivationService();
export { emailActivationService };