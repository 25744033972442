import React, { useEffect } from 'react';
import { defaultTemplate } from '../../../../common/hoc';

import { HighlightOffer, LastMinuteOffer } from '../../../../pages/home/components';

export default defaultTemplate(function SportsLandingTemplate(props) {

    const {t,
        viewStore: {
            isEmpty,
            isLoading,
            isStoreInitialized,

            highlightsViewStore,
            lastMinuteViewStore,
        }
    } = props;

    useEffect(()=>{
        App.state.rootStore.titleStore.setTitle(t('INFO_PAGES.HELP.SPORTS_BETTING.TITLE'));
    },[])

    if (isLoading) {
        return <Loader />
    }

    if (isEmpty) {
        return <EmptyMessage />
    }

    if (!isStoreInitialized) {
        return null;
    }

    return (
        <React.Fragment>
            <HighlightOffer viewStore={highlightsViewStore} />
            <LastMinuteOffer viewStore={lastMinuteViewStore} />
        </React.Fragment>
    )
})

const Loader = defaultTemplate(function Loader(props) {
    const {
        t,
    } = props;

    return (
        <div className="loader">
            <div className="loader__card">
                <div className="loader__card__title">
                    {t("COMMON.LOADING")}...
                </div>
                <span />
            </div>
        </div>
    )
})

const EmptyMessage = defaultTemplate(function EmptyMessage(props) {

    const {
        t,
    } = props;

    return (
        <div className="message--sml message--note">
            {t('SPORTS.EMPTY_STATE.DESCRIPTION')}
        </div>
    )
})