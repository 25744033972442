import React from "react";
import { AccountStatementTransactionDetailsTemplate } from "themes/pages/account-statement/components/details";
import { AccountStatementTransactionDetailsError } from ".";

export default function AccountStatementTransactionDetails(props) {

    const { slipDetails, setShowDetails } = props;

    if (!slipDetails || Object.keys(slipDetails).length == 0) {

        return <AccountStatementTransactionDetailsError
            setShowDetails={setShowDetails}
        />
    }

    return <AccountStatementTransactionDetailsTemplate
        {...props}
    />
}