import React, { useState } from 'react';
import { inject } from 'mobx-react';
import { TournamentEventsTemplate } from 'themes/common/components/offer/new-offer';

export const TournamentEvents = inject(s => ({
	visibleColumns: s.offerStore.resizeStore.visibleColumns,
}))((props) => {
	const [hidden, setHidden] = useState(false);

	return (
		<TournamentEventsTemplate
			{...props}
			hidden={hidden}
			setHidden={setHidden}
		/>
	);
});