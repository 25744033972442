import React from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { LiveSideMenuTemplate } from 'themes/common/components/sidemenu';

@inject(stores => ({
    liveMenuViewStore: stores.offerStore.liveMenuViewStore,
}))
@withRouter
@observer
class LiveSideMenu extends React.Component {
    constructor(props) {
        super(props);

        this._updateSelectedItems = this._updateSelectedItems.bind(this);
    }

    async componentDidMount() {
        const { culture, period } = this.props.match.params;

        // culture won't change
        this.props.liveMenuViewStore.setCulture(culture);
        this.props.liveMenuViewStore.onPeriodChange(period);


        if (App.state.history.action !== 'POP') {
            this.props.liveMenuViewStore.clearSelectedSportIds();
            this._updateSelectedItems();
        }

        await this.props.liveMenuViewStore.onInitialize();
    }

    async componentDidUpdate(prevProps) {
        let shouldUpdate = false;

        const { period: prevPeriod, ids: prevIds } = prevProps.match.params;
        const { period, ids } = this.props.match.params;

        if (period !== prevPeriod) {
            this.props.liveMenuViewStore.onPeriodChange(this.props.match.params.period);
            shouldUpdate = true;
        }

        if (ids !== prevIds) {
            this._updateSelectedItems();
            shouldUpdate = true;
        }

        if (shouldUpdate) {
            await this.props.liveMenuViewStore.onInitialize();
        }
    }

    componentWillUnmount() {
        this.props.liveMenuViewStore.onDispose();
    }

    _updateSelectedItems() {
        const { ids } = this.props.match.params;

        if (!ids) {
            this.props.liveMenuViewStore.setSelected([]);
            return;
        }

        if (ids.indexOf('favorites') > -1) {
            this.props.liveMenuViewStore.setSelected('favorites');
        }
        else if (ids.indexOf('highlights') > -1) {
            this.props.liveMenuViewStore.setSelected('highlights');
        }
        else {
            this.props.liveMenuViewStore.setSelected(ids.split('/'));
        }
    }

    render() {
        return (
            <LiveSideMenuTemplate {...this.props} />
        );
    }
}

export default LiveSideMenu;