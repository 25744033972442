import React, { Suspense } from 'react';
import { observer, inject } from 'mobx-react';
import { MainLayoutTemplate } from 'themes/layouts';
import { Router } from 'react-router-dom';
import { AppContextContainer, CurrencyDisplayContainer, LoadingContainer, NotificationContainer } from '../common/containers';
import { GlobalLoader } from '../common/components/loader';

@inject(stores => ({
    onInitialize: stores.offerStore.onInitialize,
    onDispose: stores.offerStore.onDispose,
    updateWidthAndHeight: stores.offerStore.resizeStore.updateWidthAndHeight,
}))
@observer
class MainLayout extends React.Component {
    resizeTimeoutId;

    handleResize = () => {
        clearTimeout(this.resizeTimeoutId);
        this.resizeTimeoutId = setTimeout(() => {
            this.props.updateWidthAndHeight({
                width: window.innerWidth,
                height: window.innerHeight
            });
        }, 50);
    }

    async componentDidMount() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);

        await this.props.onInitialize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);

        this.props.onDispose();
    }

    render() {
        return (
            <Suspense fallback={<GlobalLoader />}>
                <AppContextContainer>
                    <LoadingContainer>
                        <Router history={App.state.history}>
                            <CurrencyDisplayContainer>
                                <NotificationContainer>
                                    <MainLayoutTemplate {...this.props} />
                                </NotificationContainer>
                            </CurrencyDisplayContainer>
                        </Router>
                    </LoadingContainer>
                </AppContextContainer>
            </Suspense>
        )
    }
}

export default MainLayout;