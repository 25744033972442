import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AccountStatementTemplate } from 'themes/pages/account-statement';
import { ErrorPage } from "../../common/components/error";

export default function AccountStatement() {
    const { t } = useTranslation();
    const [statementFilter, setStatementFilter] = useState({});

    useEffect(() => {

        App.state.rootStore.titleStore.setTitle(t('HEADER.ACCOUNT_STATEMENT'));
        if (App.state.rootStore?.isSideMenuExpanded != null) {
            App.state.rootStore.closeSideMenu();
        }
        //on mount scroll to top
        document.querySelector('html, body').scrollTo(0, 0);

        return () => {
            if (App.state.rootStore?.isSideMenuExpanded != null) {
                App.state.rootStore.openSideMenu();
            }
        }
    }, []);

    return (
        <ErrorPage>
            <AccountStatementTemplate
                setStatementFilter={setStatementFilter}
                statementFilter={statementFilter}
            />
        </ErrorPage>
    )
}
