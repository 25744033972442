import React from 'react';
import { LoaderTemplate } from 'themes/common/components/loader';
// import { useLoading } from '../../../../v2/hooks/shared/useLoading';
import { useLoading } from "../../hooks"

export default function Loader(props) {
    const { isLoading } = useLoading();

    if (isLoading) {
        return <LoaderTemplate {...props} />
    }
    else {
        return null;
    }
};
