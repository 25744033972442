const httpClient = App.utils.httpClient;

const requestHeaders = {
    'Content-Type': 'application/json'
};

export class LimitsHistoryService {

    async getGamingLimitHistory(apiUrl) {
        return await httpClient.get(apiUrl, requestHeaders, { responseType: "json" });
    }

    async getAvailableLimitCategories(categoryId = null) {
        let apiUrl;
        if (categoryId == null) {
            apiUrl = `${BaseAddress}${App.utils.getAgencyKey()}/gaming-limit-categories/available-categories?translate=True`;
        }
        else {
            apiUrl = `${BaseAddress}${App.utils.getAgencyKey()}/gaming-limit-categories/available-categories/?categoryId=${categoryId}&translate=True`;
        }

        return await httpClient.get(apiUrl, requestHeaders, { responseType: 'json' });
    }

}