import React from 'react';

import { defaultTemplate } from '../../../../../common/hoc';
import { OutsideClickDetector } from '../../../../../common/components/utility';

export default defaultTemplate(function (props) {
    const { searchBarViewStore, period, t } = props;
    const { searchTerm, onTermChange, onSubmit, displayValidationPopup, closeValidationPopup} = searchBarViewStore;

    const onSearch= async(e)=>{
        onSubmit({ event: e, period: period, redirect: false })

        if(searchTerm===""){
            App.offer.rootStore.resultsDataStore.changeDayParam(0);
        }
        else{
            App.offer.rootStore.resultsDataStore.changeDayParam(null);
        }
        App.offer.rootStore.resultsMenuStore.resetSelectedTournaments();
        await App.offer.rootStore.resultsDataStore.onInitialize();
    }

    return (
        <form className="form--search" onSubmit={onSearch}>
            <input
                className="form--search__input input input--base"
                value={searchTerm}
                type="search"
                placeholder={t("PREMATCH.SEARCH")}
                onChange={e => onTermChange(e.target.value)}
            />
            <button className="form--search__submit btn" type="submit">
                <i className="u-icon u-icon--sml u-icon--search--neutral" />
            </button>

            {displayValidationPopup ? (
                <OutsideClickDetector wrapperClasses="form--search__validation" onOutsideClick={e => closeValidationPopup()}>
                    <React.Fragment>
                        <div className="form--search__validation__header">
                            <div>{t('PREMATCH.SEARCH')}</div>
                            <button className="btn btn--link" type="button" onClick={e => closeValidationPopup()}>
                                <i className="u-icon u-icon--xsml u-icon--remove"></i>
                            </button>
                        </div>
                        <div className="form--search__validation__body">
                            {t('PREMATCH.SEARCH_ERROR')}
                        </div>
                    </React.Fragment>
                </OutsideClickDetector>
            ) : null}
        </form>
    )
});