import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { observer } from "mobx-react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import {
	OfferStoreContext,
	SportOfferContext,
	OfferOptionsContext,
	EventContext,
	LookupContext,
} from "@gp/components";

import { defaultTemplate } from "../../../../common/hoc";
import {
	TeamName,
	EventTime,
} from "../../../../common/components/offer/details";
import { StatisticsButton } from "../../../../common/components/offer/buttons";
import { GroupedEventOffer } from "../../../../common/components/offer/grouped-event-offer";

export default defaultTemplate(function EventTemplate(props) {
	const {
		eventViewStore,
		eventViewStore: {
			rootStore: { betSlipStore },
		},
	} = props;

	const options = {
		isOneClickBetActive: betSlipStore.betSlipState.isOneClickBetActive,
		onTipClick: betSlipStore.addRemoveOffer,

		isTipSelected: (tipId) => {
			if (betSlipStore.betSlipState.isOneClickBetActive) {
				return betSlipStore.betSlipState.isInOneClickBet(tipId);
			}
			return betSlipStore.isInBetSlip(tipId);
		},
	};

	return (
		<OfferStoreContext.Provider value={eventViewStore}>
			<LookupContext.Provider value={eventViewStore.lookupsStore}>
				<OfferOptionsContext.Provider value={options}>
					<TemplateCore />
				</OfferOptionsContext.Provider>
			</LookupContext.Provider>
		</OfferStoreContext.Provider>
	);
});

const TemplateCore = observer(function TemplateCore(props) {
	const { isLoading, isEmpty, orderedEvents } = useContext(OfferStoreContext);

	const { pathname } = useLocation();
	const { t } = useTranslation();

	if (isLoading) {
		return (
			<div id="loader" className="loader">
				<div className="loader__card">
					<div className="loader__card__title">
						{t("EVENT.LOADING")}...
					</div>
					<span />
				</div>
			</div>
		);
	}

	if (isEmpty) {
		// If event is on live page and offer is empty redirect back to live page
		if (pathname.includes("live")) {
			return (
				<Redirect
					to={`/${App.utils.getCurrentCulture()}/live/events`}
				/>
			);
		}

		// If event is not open on live page show empty offer message
		return (
			<div className="message--sml message--note">
				{t("EVENT.EMPTY_STATE.DESCRIPTION")}
			</div>
		);
	}

	return orderedEvents.map((event) => (
		<SportOfferContext.Provider
			key={event.sport.id + event.id}
			value={event.sport}
		>
			<EventContext.Provider value={event}>
				<Event />
			</EventContext.Provider>
		</SportOfferContext.Provider>
	));
});

const Event = observer(function Event() {
	const event = useContext(EventContext);
	const { t } = useTranslation();

	if (event.bettingTypeGroups.length === 0) {
		return (
			<div className="message--sml message--note">
				{t("BETTING_TYPE.NO_OFFER")}
			</div>
		);
	}

	const eventClasses = classNames("event", {
		"event--live": event.isLive,
	});

	return (
		<div className={eventClasses}>
			<EventHeader />
			<GroupedEventOffer />
		</div>
	);
});

const EventHeader = observer(function EventHeader(props) {
	const {} = props;

	//#region context

	const event = useContext(EventContext);
	const sport = useContext(SportOfferContext);
	const { categories, tournaments } = useContext(LookupContext);

	//#endregion context

	const category = categories.get(event.sportCategoryId);
	const tournament = tournaments.get(event.tournamentId);

	return (
		<header
			className={event.isLive ? "event__header bg--" + sport.abrv : ""}
		>
			<div className="event__header__info">
				<div className="row w--100">
					<div className="col col-sml-9">
						<h1 className="event__header__title">
							<i
								className={
									"event__header__title__icon u-icon u-icon--sport u-icon--sport--light u-icon--" +
									sport.abrv
								}
							/>
							<span>
								{category.name} - {tournament.name}:{" "}
								<EventName />
							</span>
						</h1>
					</div>
					<div className="col col-sml-3 u-type--xsml u-type--right">
						<div className="u-display--f u-display--f--center u-display--f--justify-end h--100">
							{event.isLive && (
								<i className="u-icon u-icon--offer u-icon--live u-mar--right--tny" />
							)}
							<StatisticsButton />

							<EventTime />
						</div>
					</div>
				</div>
			</div>

			<EventStats />
		</header>
	);
});

const EventName = observer(function EventName(props) {
	const {} = props;

	//#region context

	const event = useContext(EventContext);
	const { teams } = useContext(LookupContext);

	//#endregion context

	const homeTeam = teams.get(event.teamOneId);
	const awayTeam = teams.get(event.teamTwoId);

	if (event.name != null) {
		return event.name;
	}

	return (
		<>
			<TeamName team={homeTeam} /> - <TeamName team={awayTeam} />
		</>
	);
});

const EventStats = observer(function EventStats(props) {
	const {} = props;

	const { t } = useTranslation();

	//#region context

	const event = useContext(EventContext);
	const sport = useContext(SportOfferContext);
	const { teams } = useContext(LookupContext);

	//#endregion context

	//#region teams

	const homeStats = event.result?.homeTeamTotalStatistics;
	const awayStats = event.result?.awayTeamTotalStatistics;

	const homeRedCards =
		homeStats && homeStats.redCards ? homeStats.redCards : 0;
	const awayRedCards =
		awayStats && awayStats.redCards ? awayStats.redCards : 0;

	const homeRedYellowCards =
		homeStats && homeStats.redYellowCards ? homeStats.redYellowCards : 0;
	const awayRedYellowCards =
		awayStats && awayStats.redYellowCards ? awayStats.redYellowCards : 0;

	const homeTeam = teams.get(event.teamOneId);
	const awayTeam = teams.get(event.teamTwoId);

	//#endregion teamsF
	const isSoccer = sport.abrv === "soccer";
	const isTennis = sport.abrv === "tennis";

	const isHalfTimeSport = ["soccer", "handball"].includes(sport.abrv);
	const isPeriodSport = [
		"ice-hockey",
		"tennis",
		"table-tennis",
		"volleyball",
		"beach-volley",
	].includes(sport.abrv);
	const isServeSport = [
		"tennis",
		"table-tennis",
		"volleyball",
		"beach-volley",
	].includes(sport.abrv);
	const isQuarterSport = sport.abrv === "basketball";

	const formatGameScore = (input) =>
		input.startsWith("g") ? input.substr(1) : input.toUpperCase();

	if (!event.isLive) {
		return null;
	}

	return (
		<div className="event__info__stats">
			{/* SERVER */}
			{isServeSport && (
				<div className="event__info__stats__col">
					<div className="event__info__stats__item event__info__stats__item--server">
						&nbsp;
					</div>
					<div className="event__info__stats__item event__info__stats__item--server">
						<i
							className={
								"u-icon u-icon--server" +
								(event.result?.serve === "home"
									? " u-icon--server--active"
									: "")
							}
						/>
					</div>
					<div className="event__info__stats__item event__info__stats__item--server">
						<i
							className={
								"u-icon u-icon--server" +
								(event.result?.serve === "away"
									? " u-icon--server--active"
									: "")
							}
						/>
					</div>
				</div>
			)}

			{/* TEAM NAMES */}
			<div className="event__info__stats__col event__info__stats__col--teams">
				<div className="event__info__stats__item">&nbsp;</div>
				<div className="event__info__stats__item u-type--left u-type--color--negative">
					<TeamName team={homeTeam} />
				</div>
				<div className="event__info__stats__item u-type--left u-type--color--negative">
					<TeamName team={awayTeam} />
				</div>
			</div>

			{/* CORNERS */}
			{isSoccer && (
				<div className="event__info__stats__col">
					<div className="event__info__stats__item">
						<i className="u-icon u-icon--sml u-icon--corner" />
					</div>
					{homeStats ? (
						<div className="event__info__stats__item">
							{homeStats.cornerKicks || 0}
						</div>
					) : (
						<div className="event__info__stats__item">&nbsp;</div>
					)}
					{awayStats ? (
						<div className="event__info__stats__item">
							{awayStats.cornerKicks || 0}
						</div>
					) : (
						<div className="event__info__stats__item">&nbsp;</div>
					)}
				</div>
			)}

			{/* RED CARDS */}
			{isSoccer && (
				<div className="event__info__stats__col">
					<div className="event__info__stats__item">
						<i className="u-icon u-icon--sml u-icon--card--red" />
					</div>
					{homeStats ? (
						<div className="event__info__stats__item">
							{homeRedCards + homeRedYellowCards}
						</div>
					) : (
						<div className="event__info__stats__item">&nbsp;</div>
					)}
					{awayStats ? (
						<div className="event__info__stats__item">
							{awayRedCards + awayRedYellowCards}
						</div>
					) : (
						<div className="event__info__stats__item">&nbsp;</div>
					)}
				</div>
			)}

			{/* YELLOW CARDS */}
			{isSoccer && (
				<div className="event__info__stats__col">
					<div className="event__info__stats__item">
						<i className="u-icon u-icon--sml u-icon--card--yellow" />
					</div>
					{homeStats ? (
						<div className="event__info__stats__item">
							{homeStats.yellowCards || 0}
						</div>
					) : (
						<div className="event__info__stats__item">&nbsp;</div>
					)}
					{awayStats ? (
						<div className="event__info__stats__item">
							{awayStats.yellowCards || 0}
						</div>
					) : (
						<div className="event__info__stats__item">&nbsp;</div>
					)}
				</div>
			)}

			{/* HT - HALF TIME SCORE */}
			{isHalfTimeSport && event.result?.halfTimeScore && (
				<div className="event__info__stats__col">
					<div
						className="event__info__stats__item u-type--xsml u-type--wgt--bold u-type--color--negative"
						title={t("EVENT.DETAILS.HT_TITLE")}
					>
						{t("EVENT.DETAILS.HT")}
					</div>
					<div className="event__info__stats__item">
						{event.result?.halfTimeScore.home || 0}
					</div>
					<div className="event__info__stats__item">
						{event.result?.halfTimeScore.away || 0}
					</div>
				</div>
			)}

			{/* SCORE PER PERIOD */}
			{(isPeriodSport || isQuarterSport) &&
				event.result?.scorePerPeriod && (
					<div className="event__info__stats__col">
						<div className="event__info__stats__row">
							{event.result?.scorePerPeriod.map((item) => {
								return (
									<div
										key={`${item.periodNumber}_header`}
										className="event__info__stats__item u-type--tny u-type--wgt--bold u-type--color--negative"
									>
										P{item.periodNumber}
									</div>
								);
							})}
						</div>

						<div className="event__info__stats__row">
							{event.result?.scorePerPeriod.map(
								(periodItem, idx) => {
									return (
										<div
											key={`${idx}_home`}
											className="event__info__stats__item"
										>
											{periodItem.home || 0}
										</div>
									);
								}
							)}
						</div>

						<div className="event__info__stats__row">
							{event.result?.scorePerPeriod.map(
								(periodItem, idx) => {
									return (
										<div
											key={`${idx}_away`}
											className="event__info__stats__item"
										>
											{periodItem.away || 0}
										</div>
									);
								}
							)}
						</div>
					</div>
				)}

			{/* OT - OVERTIME SCORE */}
			{event.result?.overtimeScore && (
				<div className="event__info__stats__col">
					<div
						className="event__info__stats__item u-type--tny u-type--wgt--bold u-type--color--negative"
						title={t("EVENT.DETAILS.OT_TITLE")}
					>
						{t("EVENT.DETAILS.OT")}
					</div>
					<div className="event__info__stats__item">
						{event.result?.overtimeScore.home || 0}
					</div>
					<div className="event__info__stats__item">
						{event.result?.overtimeScore.away || 0}
					</div>
				</div>
			)}

			{/* TBS - TIE BREAKER SCORE */}
			{event.result?.tiebreakScore && (
				<div className="event__info__stats__col">
					<div
						className="event__info__stats__item u-type--tny u-type--wgt--bold u-type--color--negative"
						title={t("EVENT.DETAILS.TBS_TITLE")}
					>
						{t("EVENT.DETAILS.TBS")}
					</div>
					<div className="event__info__stats__item">
						{event.result?.tiebreakScore.home || 0}
					</div>
					<div className="event__info__stats__item">
						{event.result?.tiebreakScore.away || 0}
					</div>
				</div>
			)}

			{/* GAME SCORE - TENNIS */}
			{isTennis &&
				event.result?.currentGameScore &&
				!event.result?.tiebreakScore && (
					<div className="event__info__stats__col">
						<div
							className="event__info__stats__item u-type--tny u-type--wgt--bold u-type--color--negative"
							title={t("EVENT.DETAILS.GAME_TITLE")}
						>
							{t("EVENT.DETAILS.GAME")}
						</div>
						<div className="event__info__stats__item">
							{formatGameScore(
								event.result?.currentGameScore.home || "0"
							)}
						</div>
						<div className="event__info__stats__item">
							{formatGameScore(
								event.result?.currentGameScore.away || "0"
							)}
						</div>
					</div>
				)}

			{/* CURRENT SCORE */}
			<div className="event__info__stats__col">
				<div className="event__info__stats__item">
					<i
						className={
							"u-icon u-icon--sml u-icon--score u-icon--score--" +
							sport.abrv
						}
					/>
				</div>
				<div className="event__info__stats__item event__info__stats__item--score">
					{event.currentScore?.home ? event.currentScore.home : 0}
				</div>
				<div className="event__info__stats__item event__info__stats__item--score">
					{event.currentScore?.away ? event.currentScore.away : 0}
				</div>
			</div>
		</div>
	);
});
