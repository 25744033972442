import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { SportOfferContext, OfferStoreContext } from '@gp/components';

import { TournamentEvents } from '../../../../../../common/components/offer/new-offer';
import { ColumnTypeSelectors } from '../../../../../../common/components/offer/new-offer/common';

export const PrematchSportTemplate = observer(function PrematchSportTemplate(props) {

	const {
		hidden,
		setHidden,
	} = props;

	const sport = useContext(SportOfferContext);

	return (
		<React.Fragment>

			<SportHeader hidden={hidden} setHidden={setHidden} />

			<SportList hidden={hidden} categories={sport.categories} />

		</React.Fragment>
	)
});

const SportHeader = observer(function SportHeader(props) {

	const {
		hidden,
		setHidden,
	} = props;

	const {
		isLive,
		isOutright,
		name,
		eventCount,
		abrv,
	} = useContext(SportOfferContext);
	const {
		additionalOfferStore: {
			isAdditionalOfferVisible,
		}
	} = useContext(OfferStoreContext);
	const { t } = useTranslation();

	return(
		<div className={classNames(
			"header--sport",
			{
				"header--sport--sml": isAdditionalOfferVisible,
				"is-collapsed": hidden
			}
		)}>
			<div className={classNames(
				"header--sport__item header--sport__item--title",
				{
					"has-border": !isOutright
				})}
				title={name}
			>
				<i className={"u-icon u-icon--sport u-icon--sport--light u-icon--" + (abrv) + " u-mar--right--tny"} />
				<div>
					{name}
					{isLive ? (<> - {t('OFFER.LIVE')}</>) : null}
					{isOutright ? (<> - {t('OFFER.SPECIAL')}</>) : null}
				</div>
				<span className="counter u-mar--left--tny">{eventCount}</span>
			</div>

			<BettingTypeColumnSelector />

			<div className={classNames(
				"header--sport__item header--sport__item--empty",
				{
					"has-border": !isOutright
				}
			)} />

			<ToggleButton hidden={hidden} setHidden={setHidden} />
		</div>
	)
})

const SportList = observer(function SportList(props) {

	const {
		hidden,
		categories,
	} = props;

	if (hidden) {
		return null;
	}

	return categories.map(category => (
		<Category
			key={category.id}
			category={category}
		/>
	))
})

const Category = observer(function Category(props) {

	const {
		category,
		category: {
			tournaments,
		},
	} = props;

	return tournaments.map(tournament => (
		<TournamentEvents
			key={tournament.id}
			category={category}
			tournament={tournament}
		/>
	))
});

const BettingTypeColumnSelector = observer(function BettingTypeColumnSelector(props) {

	const sport = useContext(SportOfferContext);

	if (sport.isOutright) {
		return null;
	}

	return (
		<ColumnTypeSelectors />
	)
})

function ToggleButton(props) {

	const {
		hidden,
		setHidden,
	} = props;

	return (
		<div className="header--sport__item header--sport__item--toggle">
			<button
				className="btn btn--sml-square btn--link btn--icon-center"
				onClick={() => setHidden(!hidden)}
				data-type="sport"
			>
				<i className={classNames(
					'u-icon u-icon--xsml',
					{
						'u-icon--arrow-down--white': !hidden,
						'u-icon--arrow-right--white': hidden
					}
				)} />
			</button>
		</div>
	)
}