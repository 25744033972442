import { observer } from "mobx-react";
import React from "react";
import { AccountActivationSuccessPopupTemplate } from "themes/pages/membership/account-activation/components";

export default observer(function AccountActivationSuccessPopup(props) {

    const handleAccountActivationRedirectOnTerms = () => {
        App.state.rootStore.userAuthStore.initialize();
    }

    return <AccountActivationSuccessPopupTemplate
        handleAccountActivationRedirectOnTerms={handleAccountActivationRedirectOnTerms}
        {...props}
    />
})