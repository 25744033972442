import { RequiredMark } from "../../../../../common/components/inputs";
import { ErrorMessage } from "../../../../../common/components/error";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

const PasswordInputTemplate = (props) => {
	const { t } = useTranslation();
	const {
		label,
		name,
		className,
		required = false,
		errorMessage = undefined,
		field,
		placeholder = undefined,
		togglePwdType,
		handleInput,
		pwdIcon,
		pwdType,
		showIcon,
		removeSuccessMark,
		noValidate,
		inputClassName,
		popoverMsg,
		...rest
	} = props;

	const iconClass = classNames("u-icon u-icon--med", {
		"u-icon--view--off": !pwdIcon,
		"u-icon--view--on": pwdIcon,
	});

	const [mouseOverState, setMouseOverState] = useState(false);
	// console.log(label, noValidate);

	if (noValidate) {
		return (
			<>
				<input
					className={inputClassName}
					type={pwdType}
					placeholder={t(placeholder)}
					autoComplete="off"
					onInput={handleInput}
					id={name}
					maxLength="20"
					name={name}
					{...field}
					{...rest}
				/>
				<div
					className="form__field--icon__symbol"
					onClick={togglePwdType}
				>
					{showIcon ? <i className={iconClass} /> : null}
				</div>
			</>
		);
	}

	const inputWrappClassName = classNames("col-sml-7", {
		"popover popover--bottom": popoverMsg,
	});
	return (
		<div className={className}>
			<div className="col-sml-4">
				<label className="form__field__label">
					{t(label)}
					<RequiredMark required={required} />
				</label>
			</div>
			<div className={inputWrappClassName}>
				<div className="form__field--icon">
					{popoverMsg ? (
						<input
							className={
								inputClassName
									? inputClassName
									: "input input--base"
							}
							type={pwdType}
							{...field}
							{...rest}
							onInput={handleInput}
							placeholder={t(placeholder)}
							onMouseEnter={() => setMouseOverState(true)}
							onMouseLeave={() => setMouseOverState(false)}
						/>
					) : (
						<input
							className={
								inputClassName
									? inputClassName
									: "input input--base"
							}
							type={pwdType}
							{...field}
							{...rest}
							onInput={handleInput}
							placeholder={t(placeholder)}
						/>
					)}
					{popoverMsg != null && mouseOverState && (
						<div className="popover__wrapper popover--bottom__wrapper popover__wrapper--big">
							<div className="popover__content">{popoverMsg}</div>
							<span className="popover__arrow popover--bottom__arrow" />
						</div>
					)}
					<div
						className="form__field--icon__symbol"
						onClick={togglePwdType}
					>
						{showIcon ? <i className={iconClass} /> : null}
					</div>
				</div>
			</div>
			<div className="col-sml-1">
				<ErrorMessage
					name={name}
					removeSuccessMark={removeSuccessMark}
				/>
			</div>
		</div>
	);
};
export default PasswordInputTemplate;
