import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { Link, useParams } from 'react-router-dom';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

import {
	EventContext,
	LookupContext,
	SportOfferContext,
	OfferOptionsContext,
} from '@gp/components';

import {
	LiveMatchTime,
	LiveMatchResultLive,
	ScoreLive,
	RedCards,
	StartTime,

} from '../../../../../../common/components/offer/new-offer/common';

import { TeamName } from '../../../../../../common/components/offer/details';

export const EventDetailsTemplate = observer(function EventDetailsTemplate(props) {

	const {
		hasSecondaryOffer,
		displaySport,
	} = props;

	const event = useContext(EventContext);
	const options = useContext(OfferOptionsContext);

	return (
		<>
			<MatchTime hasSecondaryOffer={hasSecondaryOffer} />
			{(options.isLive && !options.hideFavoriteIcon) ? <FavoritesIcon /> : null}

			{event.isOutright ? (
				<td className="offer__body__data offer__outright-match">
					{event.name}
				</td>
			) : (
				<td className="offer__body__data offer__match">
					<TeamNamesWithLink shouldDisplayIcon={displaySport} />
				</td>
			)}
		</>
	)
})

function SportIcon(props) {

	const {
		shouldDisplayIcon,
	} = props;

	const sport = useContext(SportOfferContext);

	if (!shouldDisplayIcon) {
		return null;
	}


	return <i className={`offer__match__sport u-icon u-icon--sport u-icon--${sport.abrv}`} />
}

const TeamNamesWithLink = observer(function TeamNamesWithLink(props) {

	const {
		shouldDisplayIcon,
	} = props;

	const event = useContext(EventContext);
	const lookupContext = useContext(LookupContext);

	const { culture } = useParams();

	let t1, t2;
	if (!event.isOutright) {
		t1 = lookupContext.teams.get(event.teamOneId);
		t2 = lookupContext.teams.get(event.teamTwoId);
	}

	let category = lookupContext.categories.get(event.sportCategoryId);
	let tournament = lookupContext.tournaments.get(event.tournamentId);


	let link = `/${culture}/sports/full/event/${event.id}`;
	if (event.isLive) {
		link = `/${culture}/live/events/event/${event.id}`;
	}

	if (event.isOutright || t1 == null || t2 == null) {
		return null;
	}

	return (
		<Link
			className="offer__match__competitors"
			to={{ pathname: link, state: { prevUrl: App.state.history.location.pathname + App.state.history.location.search } }}
			data-tip={`${category?.name} - ${tournament?.name}: ${t1?.name} - ${t2?.name}`}
			data-for="team-description"
		>
			<TeamNames shouldDisplayIcon={shouldDisplayIcon} />
			<ReactTooltip
				id="team-description"
				className="at-tooltipTeams tooltip--tertiary"
				place="bottom"
				multiline={true}
			/>
		</Link>
	)
})

const TeamNames = observer(function TeamNames(props) {

	const {
		shouldDisplayIcon,
	} = props;

	const event = useContext(EventContext);
	const lookupContext = useContext(LookupContext);
	const sportContext = useContext(SportOfferContext);

	let t1, t2;
	if (!event.isOutright) {
		t1 = lookupContext.teams.get(event.teamOneId);
		t2 = lookupContext.teams.get(event.teamTwoId);
	}

	return (
		<>
			<div className="offer__match__team">
				<TeamName team={t1} />
				<RedCards sport={sportContext} statistics={event.result?.homeTeamTotalStatistics} />
			</div>

			<MatchResult shouldDisplayIcon={shouldDisplayIcon} />

			<div className="offer__match__team">
				<TeamName team={t2} />
				<RedCards sport={sportContext} statistics={event.result?.awayTeamTotalStatistics} />
			</div>
		</>
	)
})

const MatchTime = observer(function MatchTime(props) {

	const {
		hasSecondaryOffer,
	} = props;

	const event = useContext(EventContext);

	if (event.isLive) {

		return (
			<LiveMatchTime event={event} className={classNames(
				'offer__body__data offer__time',
				{
					'offer__time--stretch': hasSecondaryOffer
				}
			)}
			/>
		)
	}
	return (

		<StartTime event={event} className={"offer__body__data " + (!event.isOutright ? "offer__day" : "offer__outright-time")} />
	)

})

const MatchResult = observer(function MatchResult(props) {

	const {
		shouldDisplayIcon,
	} = props;

	const event = useContext(EventContext);
	const sport = useContext(SportOfferContext);

	if (event.isLive) {
		return (
			<>
				<ScoreLive />
				<LiveMatchResultLive event={event} sport={sport} />
				<SportIcon shouldDisplayIcon={shouldDisplayIcon} />
			</>
		)
	}

	if (shouldDisplayIcon) {
		return <SportIcon shouldDisplayIcon={shouldDisplayIcon} />
	}


	return (
		<span className="offer__match__separator">-</span>
	)
})

const FavoritesIcon = observer(function FavoritesIcon() {
	const event = useContext(EventContext);
	const {
		isLive,
		hideFavoriteIcon,

		removeEventFromFavorites,
		addEventToFavorites,
		isEventInFavorites,
	} = useContext(OfferOptionsContext);

	if (!isLive || hideFavoriteIcon) {
		return null;
	}

	const isFavorite = isEventInFavorites(event.id);

	return (
		<td className="offer__body__data offer__favorites">
			<button
				className="offer__favorites__btn btn btn--xtny btn--link btn--icon-center"
				type="button"
				onClick={_ => {
					if (isFavorite) {
						if (removeEventFromFavorites != null) {
							removeEventFromFavorites(event.id);
						}
					}
					else {
						if (addEventToFavorites != null) {
							addEventToFavorites(event.id);
						}
					}
				}}
			>
				<i className={classNames("u-icon u-icon--sml",
					{
						"u-icon--favorites--active": isFavorite,
						"u-icon--favorites--inactive": !isFavorite
					})}
				/>
			</button>
		</td>
	)
});
