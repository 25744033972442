export const checkIfUsernameFieldIsValid = (value) => {
    return XRegExp("^[a-zA-Z0-9_-]{4,20}$").test(value);
}

export const checkIfMessageContentFieldIsValid = (value) => {
    return XRegExp("^[\\p{L}\\s\\n0-9!@#$€()?{}|*+,^.-/\\\\+&=%_:;~¿£¥₡₱¢₪₩₭₮₦zł₽₴'\"-]{10,4000}$").test(value);
}

export const checkIfSubjectContentFieldIsValid = (value) => {
    return XRegExp("^[\\p{L}0-9 /'\-\.]{1,80}$").test(value);
}

export const emailPattern = /^[a-zA-Z][\w\.-]*[a-zA-Z0-9]@[a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]\.[a-zA-Z][a-zA-Z\.]*[a-zA-Z]$/;
    