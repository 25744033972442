import React from "react";
import { FormInputFieldTemplate } from "themes/common/components/inputs";
import { useFormContext } from "react-hook-form";

export default function ({ pattern, validate, ...props }) {
    const form = useFormContext();
    const field = form.register(props.name, {
        required: { value: props.required, message: props.errorMessage || 'COMMON.REQUIRED_FIELD' },
        disabled : props.disabled,
        pattern,
        validate
    });
    return <FormInputFieldTemplate {...props} field={field} />
}