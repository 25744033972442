import React from 'react';
import { range } from 'lodash';
import { defaultTemplate } from '../../../../../common/hoc';

export default defaultTemplate(function (props) {
    const {
        liveMenuViewStore: {
            menu,
            onSelect,
            selected,
            isLoading
        },
        match: { params: { culture, period } },
        t
    } = props;

    const isActive = (id) => selected.indexOf(id) > -1;

    return (
        <aside></aside>
    );
});