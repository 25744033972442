const httpClient = App.utils.httpClient;
const requestHeaders = {
    'Content-Type': 'application/json',
};
class CountriesLookupService {
    findLookup() {
        const url = `${BaseAddress}platform/countries?sort=name|asc&fields=id,name&rpp=1000&translate=true`
        return httpClient.get(url, requestHeaders, { responseType: 'json' })
    }
}

export default new CountriesLookupService();