import React, { useContext } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import { MyBetsStoreContext } from "../../../../../common/context";
import { useFixBody } from "../../../../../common/hooks";

export default observer(function CancelBetSlipPopUp(props) {
  const {
    betCancelStore: {
      isCancelPopUpOpen,
      onBetCancelConfirm,
      onExitBetCancel,
      betCancelMessage,
    },
  } = useContext(MyBetsStoreContext);

  const { t } = useTranslation();

  useFixBody(true, isCancelPopUpOpen);

  if (!isCancelPopUpOpen || betCancelMessage != null) {
    return null;
  }

  return (
    <div id="bet-cancel" className="popup">
      <div className="popup__card">
      <div class="popup__card__header"></div>
        <div className="popup__card__body">
          <div class="message--base message--help">
            <strong>{t("MY_BETS.CANCEL_SLIP_TEXT")}</strong>
          </div>
             </div>
        <div className="popup__card__footer">
          <button
            className="btn btn--sml btn--primary btn--icon-left"
            type="button"
            onClick={onExitBetCancel}
          >
            <i class="u-icon u-icon--xsml u-icon--remove--white"></i>
            {t("BET_SLIP.NO")}
          </button>
          <button
            className="btn btn--sml btn--primary btn--icon-left"
            type="button"
            onClick={onBetCancelConfirm}
          >
            <i class="u-icon u-icon--xsml u-icon--check--white"></i>
            {t("COMMON.YES")}
          </button>
        </div>
      </div>
    </div>
  );
});
