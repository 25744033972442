import React, { useEffect } from 'react';

import { defaultTemplate } from '../../../../common/hoc';
import { HighlightOffer, LastMinuteOffer, PromoOffer } from '../../../../pages/home/components';

export default defaultTemplate(function (props) {
    const {
        homeViewStore,
        homeViewStore: {
            isLoading,
        },
        t,
    } = props;

    App.state.rootStore.titleStore.setTitle(t('HOME.TITLE'));

    if (isLoading) {
        return <Loader />
    }

    useEffect(() => {
        // add toastr on home page after success account activation 
        if (window.toastr == null) {
            return;
        }
        if (App.state.rootStore.accountActivationWithEmailActivationInfo) {
            const { email } = JSON.parse(localStorage.getItem("user"));
            window.toastr.info(
                t("specific:MEMBERSHIP.ACCOUNT_ACTIVATION.NOTIFICATION.ACCOUNT_VERIFICATION_SENT_TOAST",
                    {
                        0: email
                    }),
                t("specific:MEMBERSHIP.ACCOUNT_ACTIVATION.NOTIFICATION.ACCOUNT_ACTIVATION_INFO_LABEL")
            )
            App.state.rootStore.accountActivationWithEmailActivationInfo = false;
        } else if (App.state.rootStore.accountActivationInfo) {
            window.toastr.info(
                t("specific:MEMBERSHIP.ACCOUNT_ACTIVATION.NOTIFICATION.ACCOUNT_ACTIVATION_SUCCESS_TOAST"),
                t("specific:MEMBERSHIP.ACCOUNT_ACTIVATION.NOTIFICATION.ACCOUNT_ACTIVATION_INFO_LABEL")
            )
            App.state.rootStore.accountActivationInfo = false;
        } else if (App.state.rootStore.accountActivationSuccess) {
            window.toastr.success(
                t("specific:MEMBERSHIP.ACCOUNT_ACTIVATION.NOTIFICATION.ACCOUNT_ACTIVATION_SUCCESS_MESSAGE"),
                t("specific:MEMBERSHIP.ACCOUNT_ACTIVATION.NOTIFICATION.ACCOUNT_ACTIVATION_SUCCESS_LABEL")
            )
            App.state.rootStore.accountActivationSuccess = false;
        }
    }, []);

    return (
        <React.Fragment>
            <PromoOffer />
            <HighlightOfferAndLastMinute viewStore={homeViewStore} />
        </React.Fragment>
    )

});

const HighlightOfferAndLastMinute = defaultTemplate(function HighlightOfferAndLastMinute(props) {
    const {
        viewStore: {
            isEmpty,
            lastMinuteViewStore,
            highlightsViewStore
        }
    } = props;

    if (isEmpty) {
        return <EmptyMessage />
    }

    return (
        <React.Fragment>
            <HighlightOffer viewStore={highlightsViewStore} />
            <LastMinuteOffer viewStore={lastMinuteViewStore} />
        </React.Fragment>
    )
})

const Loader = defaultTemplate(function Loader(props) {

    const {
        t,
    } = props;

    return (
        <div className="loader">
            <div className="loader__card">
                <div className="loader__card__title">
                    {t("COMMON.LOADING")}...
                </div>
                <span />
            </div>
        </div>
    )
})

const EmptyMessage = defaultTemplate(function EmptyMessage(props) {

    const {
        t,
    } = props;

    return (
        <div className="message--sml message--note">
            {t('HOME.EMPTY_STATE.DESCRIPTION')}
        </div>
    )
})