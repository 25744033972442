// NOTE: this template is used for live events on prematch page
import React from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { constants } from "@gp/gp-offer-utils";

import { defaultTemplate } from "../../../../../common/hoc";
import {
	LiveMatchResult,
	LiveColumn,
	LiveOfferResult,
} from "../../../../../common/components/offer/v1";
import { LiveMatchTimeTooltip } from "../../../../../common/components/offer";
import { Time } from "../../../../../common/components/time";
import { AdditionalOffer } from "../../../../../common/components/additional-offer";

function OfferLiveTemplate(props) {
	const {
		event,
		sport,
		main,
		secondary,
		viewStore: {
			isAdditionalOfferVisible,
			onAdditionalOfferExpand,
			isScore,
			toggleIsScore,
		},
		type,
		match: {
			params: { culture },
		},
		t,
	} = props;

	const homeRedCards = event.result?.homeTeamTotalStatistics
		? event.result?.homeTeamTotalStatistics.redCards +
		  event.result?.homeTeamTotalStatistics.redYellowCards
		: 0;
	const awayRedCards = event.result?.awayTeamTotalStatistics
		? event.result?.awayTeamTotalStatistics.redCards +
		  event.result?.awayTeamTotalStatistics.redYellowCards
		: 0;

	const isSoccer = sport.abrv === "soccer";

	const mapOffer = (columnConfig) => {
		return Object.keys(columnConfig).reduce(
			(acc, col) => {
				const colData = columnConfig[col];

				if (colData == null || colData.length === 0) {
					acc.columns.push({
						id: null,
						component: <LiveColumn key={col} />,
					});
					return acc;
				}

				if (colData[0].bettingType === constants.resultPlaceholder) {
					acc.columns.push({
						id: null,
						component: (
							<LiveOfferResult
								key={col}
								sport={sport}
								result={event.result}
							/>
						),
					});
					return acc;
				}

				const colBettingTypes = colData.map((cd) => cd.bettingType);

				// find offer for given column
				const colOffer = event.bettingTypes.find((bt) =>
					colBettingTypes.includes(bt.abrv)
				);

				if (colOffer == null) {
					acc.columns.push({
						id: null,
						component: <LiveColumn key={col} />,
					});
					return acc;
				}

				const tips = colData.find(
					(cd) => cd.bettingType === colOffer.abrv
				).tips;

				const offer = colOffer.keyOffers[0];

				if (offer == null) {
					acc.columns.push({
						id: null,
						component: <LiveColumn key={col} />,
					});
					return acc;
				}

				acc.columns.push({
					id: offer.keyId,
					component: (
						<LiveColumn
							key={offer.keyId}
							offer={offer.offers}
							tips={tips}
						/>
					),
				});

				acc.nonEmptyColumns++;
				acc.isEmpty = false;

				return acc;
			},
			{
				isEmpty: true,
				nonEmptyColumns: 0,
				columns: [],
			}
		);
	};

	const mainOffer = mapOffer(main);

	const additionalOfferCount =
		event.totalOfferCount - mainOffer.nonEmptyColumns;

	const tooltipId = `offer-live-${event.id}-matchtime`;

	return (
		<React.Fragment>
			<tr
				className={
					"offer__body__row" +
					(isAdditionalOfferVisible ? " is-expanded" : "")
				}
			>
				<td
					data-tip
					data-for={tooltipId}
					className="offer__body__data offer__day"
				>
					<Time time={event.matchTime} />
					<LiveMatchTimeTooltip event={event} tooltipId={tooltipId} />
				</td>
				<td className="offer__body__data offer__match">
					<Link
						className="offer__match__competitors"
						to={`/${culture}/live/events/event/${event.id}`}
						data-tip={`${event.category.name} - ${event.tournament.name}: ${event.homeTeam} - ${event.awayTeam}`}
						data-for="team-description"
					>
						{/* HOME TEAM */}
						<div className="offer__match__team">
							{isSoccer && homeRedCards > 0 && (
								<span className="offer__match__red-card">
									{homeRedCards}
								</span>
							)}
							{event.homeTeam}
						</div>
						{/* RESULT */}
						<LiveMatchResult
							matchStatus={event.matchStatus}
							currentScore={event.currentScore}
							result={event.result}
							isScore={isScore}
							toggleIsScore={toggleIsScore}
							sportAbrv={sport.abrv}
						/>
						{/* AWAY TEAM */}
						<div className="offer__match__team">
							{isSoccer && awayRedCards > 0 && (
								<span className="offer__match__red-card">
									{awayRedCards}
								</span>
							)}
							{event.awayTeam}
						</div>
					</Link>
				</td>

				{mainOffer.columns.map((c) => c.component)}

				<td className="offer__body__data offer__more" colSpan={2}>
					{additionalOfferCount > 0 ? (
						<button
							className={
								"offer__more__btn btn btn--sml-square " +
								(isAdditionalOfferVisible
									? "btn--primary"
									: "btn--tertiary")
							}
							type="button"
							onClick={(e) => onAdditionalOfferExpand()}
						>
							<span>+{additionalOfferCount}</span>
							<i
								className={
									"offer__more__icon u-icon u-icon--tny " +
									(isAdditionalOfferVisible
										? "u-icon--arrow-right--white"
										: "u-icon--arrow-right")
								}
							/>
						</button>
					) : (
						<React.Fragment>&nbsp;</React.Fragment>
					)}
				</td>
			</tr>
			{isAdditionalOfferVisible ? (
				<AdditionalOffer
					colSpan={16}
					eventId={event.id}
					onClose={(e) => onAdditionalOfferExpand(e)}
					type={type}
					visibleColumnKeyIds={mainOffer.columns.map((c) => c.id)}
				/>
			) : null}
		</React.Fragment>
	);
}

export default defaultTemplate(OfferLiveTemplate);
