import React, {
	useState,
	useContext,
	useEffect,
	useRef,
	useCallback,
} from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import classNames from "classnames";

import {
	MyBetsStoreContext,
	MyBetsBetContext,
} from "../../../../../common/context";
import { useCurrency } from "../../../../../common/hooks";
import { BetCombinations, BetDetails, BetOfferItem, CashoutActions } from "./";

export default function BetItem(props) {
	const { bet, printId } = props;

	const [showCombinations, setShowCombinations] = useState(false);

	const [isDetailsDisplayed, setDetailsDisplayed] = useState(false);
	const toggleDetails = () => setDetailsDisplayed(!isDetailsDisplayed);

	return (
		<MyBetsBetContext.Provider value={bet}>
			<div className="bet-card" id={`print-${printId}-card`}>
				<div className="bet-logo"></div>
				<BetItemCardHeader />

				{/* BET ITEM TABLE */}
				<BetItemTable
					bet={bet}
					toggleDetails={toggleDetails}
					isDetailsDisplayed={isDetailsDisplayed}
					setShowCombinations={setShowCombinations}
					printId={printId}
				/>
				{showCombinations == true && (
					<BetCombinations
						showCombinations={showCombinations}
						setShowCombinations={setShowCombinations}
					/>
				)}
			</div>
		</MyBetsBetContext.Provider>
	);
}

const BetItemCardHeader = observer(function BetItemCardHeader(props) {
	return (
		<div className="bet-title">
			<BetStatus />
			<BetItemActions />
		</div>
	);
});

function BetStatus(props) {
	const bet = useContext(MyBetsBetContext);

	const { t } = useTranslation();

	let text = "";
	if (bet.isLive) {
		text = t("MY_BETS.ITEM.BET_SLIP_NUMBER_LIVE", {
			0: bet.betSlipNumber,
			1: bet.betStatus.name.toLocaleUpperCase(),
		});
	} else {
		text = t("MY_BETS.ITEM.BET_SLIP_NUMBER", {
			0: bet.betSlipNumber,
			1: bet.betStatus.name.toLocaleUpperCase(),
		});
	}

	return (
		<div className={"bet-status bet-status--" + bet.betStatus.abrv}>
			{text}
		</div>
	);
}

const BetItemActions = observer(function BetItemActions(props) {
	return (
		<div className="bet-card__actions">
			<ReuseButton />
			<CashoutActions />
		</div>
	);
});

const ReuseButton = observer(function ReuseButton(props) {
	const { t } = useTranslation();
	const { betSlipOffers, id, betSlipNumber, isLive } =
		useContext(MyBetsBetContext);

	const {
		reuseBetSlipStore: { openReuseBetSlipModal },
	} = useContext(MyBetsStoreContext);

	const hasActiveOffer = betSlipOffers.some(
		(o) => o.bettingOfferStatus === 1
	);

	if (!hasActiveOffer) {
		return null;
	}
	return (
		<button
			className="bet-card__actions__btn bet-card__actions__btn--reuse btn btn--sml btn--accent btn--icon-left no-print"
			type="button"
			onClick={(e) =>
				openReuseBetSlipModal({ id, betSlipNumber, isLive })
			}
		>
			<i className="u-icon u-icon--sml u-icon--reuse-bet-slip"></i>
			<span>{t("MY_BETS.ITEM.REUSE")}</span>
		</button>
	);
});

function BetItemTable(props) {
	const { toggleDetails, isDetailsDisplayed, setShowCombinations, printId } =
		props;

	const [isPrinting, setIsPrinting] = useState(false);

	return (
		<table className="table--bets" cellSpacing={0} cellPadding={0}>
			<BetItemTableHead
				toggleDetails={toggleDetails}
				isDetailsDisplayed={isDetailsDisplayed}
				isPrinting={isPrinting}
				setIsPrinting={setIsPrinting}
				printId={printId}
			/>
			<tbody className="table--bets__body">
				<BetOffers />
				{(isDetailsDisplayed == true || isPrinting) && (
					<BetDetails setShowCombinations={setShowCombinations} />
				)}
			</tbody>
		</table>
	);
}

const BetOffers = observer(function BetOffers(props) {
	const bet = useContext(MyBetsBetContext);
	const { betSlipOffers } = bet;

	if (betSlipOffers == null) {
		return null;
	}

	return betSlipOffers.map((offer, index) => (
		<BetOfferItem key={offer.id} offer={offer} id={index} />
	));
});

//#region header

const BetItemTableHead = observer(function BetItemTableHead(props) {
	const {
		toggleDetails,
		isDetailsDisplayed,
		isPrinting,
		setIsPrinting,
		printId,
	} = props;

	const { t } = useTranslation();

	const subheaderBaseClass =
		"table--bets__subheader__data table--bets__subheader__data--";

	return (
		<thead className="table--bets__header">
			<tr className="table--bets__header__row">
				<th className="u-padd--reset" colSpan={8}>
					<BetItemHeaderTable
						toggleDetails={toggleDetails}
						isDetailsDisplayed={isDetailsDisplayed}
						isPrinting={isPrinting}
						setIsPrinting={setIsPrinting}
						printId={printId}
					/>
				</th>
			</tr>
			<tr className="table--bets__subheader">
				<th className={`${subheaderBaseClass}time`}>
					{t("MY_BETS.ITEM.TIME_COLUMN")}
				</th>
				<th className={`${subheaderBaseClass}char`}>&nbsp;</th>
				<th className={`${subheaderBaseClass}event`}>
					{t("MY_BETS.ITEM.EVENT_NAME_COLUMN")}
				</th>
				<th className={`${subheaderBaseClass}fix`}>&nbsp;</th>
				<th className={`${subheaderBaseClass}bet-type`}>
					{t("MY_BETS.ITEM.TIP_NAME_COLUMN")}
				</th>
				<th className={`${subheaderBaseClass}odds`}>
					{t("MY_BETS.ITEM.QUOTA_COLUMN")}
				</th>
				<th className={`${subheaderBaseClass}results`}>
					{t("MY_BETS.ITEM.RESULT_COLUMN")}
				</th>
				<th className={`${subheaderBaseClass}fix`}>&nbsp;</th>
			</tr>
		</thead>
	);
});

const BetItemHeaderTable = observer(function BetItemHeaderTable(props) {
	const {
		toggleDetails,
		isDetailsDisplayed,
		printId,
		isPrinting,
		setIsPrinting,
	} = props;

	const bet = useContext(MyBetsBetContext);
	const { t } = useTranslation();
	const { currency } = useCurrency();
	const printButtonRef = useRef();

	const startPrint = useCallback((target) => {
		const element = document.getElementById(target.id + "-card");
		const isSafari = /^((?!chrome|android).)*safari/i.test(
			navigator.userAgent
		); //check if safari
		if (isSafari) {
			let printWindow = window.open(
				"",
				"Print",
				"width=5,height=5,top=200,left=200,toolbars=no,scrollbars=no,status=no,resizable=no"
			);
			printWindow.document.write("<!DOCTYPE html>");
			printWindow.document.write("<html><head>");
			printWindow.document.write(
				'<link rel="stylesheet" href="/css/app.css" />'
			);
			printWindow.document.write(
				'</head><body><div class="bet-card is-printing">'
			);
			printWindow.document.write(element.innerHTML);
			printWindow.document.write("</div></body></html>");

			printWindow.document.close();
			printWindow.focus();
			printWindow.print();
			printWindow.close();
		} else {
			element.classList.add("is-printing");
			window.print();
			element.classList.remove("is-printing");
		}
	}, []);

	useEffect(() => {
		if (isPrinting) {
			startPrint(printButtonRef.current);
			setIsPrinting(false);
		}
	}, [isPrinting]);

	const displayDetailsIndicatorClass = classNames(
		"u-icon u-icon--xsml",
		"u-align--v--middle",
		{
			"u-icon--arrow-down": isDetailsDisplayed,
			"u-icon--arrow-right": !isDetailsDisplayed,
		}
	);

	return (
		<table className="table--bets--reset" cellSpacing={0} cellPadding={0}>
			<tbody>
				<tr>
					<th className="table--bets__header__data table--bets__header__data--time">
						{t("MY_BETS.ITEM.INFO_DATE")}{" "}
						{DateTime.fromISO(bet.dateCreated)
							.toLocaleString(
								DateTime.DATETIME_SHORT_WITH_SECONDS
							)
							.split(",")
							.join("")}
					</th>
					<th className="table--bets__header__data table--bets__header__data--type">
						<BetItemHeaderTableType />
					</th>
					<th className="table--bets__header__data table--bets__header__data--price">
						{t("MY_BETS.ITEM.INFO_PAYMENT", {
							0: bet.payment.toFixed(2),
						})}{" "}
						{currency.toUpperCase()}
					</th>
					<th className="table--bets__header__data table--bets__header__data--price">
						{t("MY_BETS.ITEM.INFO_MAX_GAIN", {
							0: bet.maxGain.toFixed(2),
						})}{" "}
						{currency.toUpperCase()}
					</th>
					<th className="table--bets__header__data table--bets__header__data--print">
						<button
							className="btn btn--link no-print"
							type="button"
							onClick={() => setIsPrinting(true)}
						>
							<i className="u-icon u-icon--big u-icon--print u-align--v--middle"></i>
						</button>
					</th>
					<th
						className="table--bets__header__data table--bets__header__data--more"
						colSpan={3}
					>
						<button
							ref={printButtonRef}
							className="table--bets__header__data--more__btn btn btn--link no-print u-align--v--middle"
							type="button"
							onClick={() => toggleDetails()}
							id={`print-${printId}`}
						>
							<i className={displayDetailsIndicatorClass}></i>
						</button>
					</th>
				</tr>
			</tbody>
		</table>
	);
});

function BetItemHeaderTableType() {
	const bet = useContext(MyBetsBetContext);

	const isSystem = bet.system != null && bet.system != "";
	if (isSystem) {
		return `${bet.formattedBetSystemString}`;
	}

	return bet.formattedBetSystemString;
}

//#endregion header
