import React from 'react';
import PropTypes from 'prop-types';

class OutsideClickDetector extends React.Component {
    constructor(props) {
        super(props);

        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target) && (event.target !== 'navItemMenu' || event.target.parentElement.id !== 'navItemMenu')) {
            if (this.props.onOutsideClick) {
                this.props.onOutsideClick(event)
            }
        }
    }

    render() {
        return (
            <div ref={this.wrapperRef} className={this.props.wrapperClasses}>
                {this.props.children}
            </div>
        )
    }
}

OutsideClickDetector.propTypes = {
    onOutsideClick: PropTypes.func,
    wrapperClasses: PropTypes.string,
    children: PropTypes.element.isRequired
}

export default OutsideClickDetector;