import React, { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { AccountActivationPasswordSectionTemplate } from "themes/pages/membership/account-activation/components";

export default function AccountActivationPasswordSection(props) {

    const { trigger, getFieldState } = useFormContext();

    useEffect(() => {
        if (getFieldState("password").isTouched && getFieldState("confirmPassword").isTouched) {
            trigger('confirmPassword');
        }
    }, [useWatch({ name: 'password' })]);

    return <AccountActivationPasswordSectionTemplate {...props} />
} 