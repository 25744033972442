import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import {
	useForm,
	FormProvider,
	useFormContext,
	useWatch,
} from "react-hook-form";

import { UserAccountType } from "../../../../../common/constants";
import { MyBetsStoreContext } from "../../../../../common/context";
import { DatePicker } from "../../../../../common/components/inputs";

export default observer(function ClosedBetsFilterForm(props) {
	const { t } = useTranslation();
	const methods = useForm();
	const { register, handleSubmit, reset } = methods;

	const {
		userAccountTypes,
		onlineAccountTypeId,

		betPeriods,

		betsReportStore,
		myBetsFilterStore: { setRawFilterData, bettingAccountTypeIds, rawData, handleHistoryBack },
	} = useContext(MyBetsStoreContext);

	const onSubmit = (data) => {
		setRawFilterData({
			...data,
			fetchReport: true,
			bettingAccountTypeIds: rawData.bettingAccountTypeIds,
			betStatusIds: data.betStatusIds,
		});
	};

	useEffect(() => {
		betsReportStore.onInitialize();
		return betsReportStore.onDispose;
	}, []);

	/**
	 * On mount submit default request
	 */
	useEffect(() => {
		if (App.state.history.action === 'POP') {
            handleHistoryBack();
        }

		if (
			App.state.history.location.search != null &&
			App.state.history.location.search !== ""
		) {
			return;
		}

		setRawFilterData({
			betStatusIds: "closed",
			fetchReport: true,
			bettingAccountTypeIds: [
				onlineAccountTypeId,
				userAccountTypes.find(
					(at) => at.abrv === UserAccountType.CashToDigitalAccount
				).id,
			],
			period: "today",
		});
	}, []);

	useEffect(() => {
		reset({
			...rawData,
			betStatusIds:
				typeof rawData.betStatusIds !== "string"
					? rawData.betStatusIds[0]
					: rawData.betStatusIds,
		});
	}, [bettingAccountTypeIds]);

	return (
		<FormProvider {...methods}>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className="form u-mar--bottom--med no-print"
			>
				<section className="form__group">
					<div className="form__field row">
						<div className="col-sml-4">
							<label
								htmlFor="myBetsPeriodSelect"
								className="form__field__label"
							>
								{t("MY_BETS.PAGE.FILTER_FORM.SELECT_PERIOD")}
							</label>
						</div>
						<div className="col-sml-7">
							<select
								id="myBetsPeriodSelect"
								className="input input--base input--select"
								name="period"
								{...register("period")}
							>
								{betPeriods.map((betPeriod) => (
									<option
										key={betPeriod.value}
										value={betPeriod.value}
									>
										{t(
											`MY_BETS.PAGE.FILTER_FORM.PERIODS.${betPeriod.name}`
										)}
									</option>
								))}
							</select>
						</div>
						<div className="col-sml-1"></div>
					</div>

					<CustomPeriodInput />

					<BetStatusDropDown />

					<div className="form__field row">
						<div className="col-sml-4"></div>
						<div className="col-sml-7">
							<input
								id="checkLive"
								className="input--check"
								name="isLive"
								type="checkbox"
								{...register("isLive")}
							/>
							<label htmlFor="checkLive">
								{t("MY_BETS.PAGE.FILTER_FORM.SHOW_LIVE_BETS")}
							</label>
						</div>
					</div>

					<div className="form__field row">
						<div className="col-sml-11 u-type--right">
							<button
								className="form__submit btn btn--med btn--secondary"
								type="submit"
							>
								{t("MY_BETS.PAGE.FILTER_FORM.SUBMIT_BUTTON")}
							</button>
						</div>
					</div>
				</section>
			</form>
		</FormProvider>
	);
});

function CustomPeriodInput(props) {
	const { setValue, getValues } = useFormContext();
	const currentPeriod = useWatch({ name: "period" });
	const {
		myBetsFilterStore: { rawData },
	} = useContext(MyBetsStoreContext);
	if (currentPeriod !== "custom") {
		return null;
	}

	return (
		<>
			<DatePicker
				label="MY_BETS.PAGE.FILTER_FORM.START_DATE"
				name="from"
				className="form__field row"
				required
			/>
			<DatePicker
				label="MY_BETS.PAGE.FILTER_FORM.END_DATE"
				name="to"
				className="form__field row"
				validate={{
					isBiggerThanStartDate: (value) =>
						value > getValues("from") ||
						"COMMON.ERROR_MESSAGES.END_DATE_LOWER",
				}}
				required
			/>
		</>
	);
}

const BetStatusDropDown = observer(function BetStatusDropDown(props) {
	const { t } = useTranslation();
	const { register } = useFormContext();
	const { closedBetsBetStatuses } = useContext(MyBetsStoreContext);

	return (
		<div className="form__field row">
			<div className="col-sml-4">
				<label
					htmlFor="myBetsStatusSelect"
					className="form__field__label"
				>
					{t("MY_BETS.PAGE.FILTER_FORM.BETS_STATUS")}
				</label>
			</div>
			<div className="col-sml-7">
				<select
					id="myBetsStatusSelect"
					className="input input--base input--select"
					{...register("betStatusIds")}
				>
					<option value="closed">
						{t("MY_BETS.PAGE.FILTER_FORM.ALL_BETS")}
					</option>
					{closedBetsBetStatuses.map((status) => (
						<option key={status.id} value={status.id}>
							{status.name}
						</option>
					))}
				</select>
			</div>
		</div>
	);
});
