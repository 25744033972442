import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { DateTime } from "luxon";
import { useCurrency } from "../../../../../../common/hooks";
import { isNullOrWhitespace } from "../../../../../../../utils/common/helpers";
import { getSystemString } from "../../../../../../pages/account-statement/utils";
import { AccountStatetementBetDetailsOffer } from "../../../../../../pages/account-statement/components/details";
import { Button } from "../../../../../../common/components/buttons";

export default function AccountStatementBetDetailsTemplate(props) {
	const { t } = useTranslation();
	const { currency } = useCurrency();

	const { slipDetails, setShowDetails, finalOffers } = props;

	const isSystem = !isNullOrWhitespace(slipDetails.system);

	const {
		isLive,
		system,
		totalNumberOfCombinations,
		dateCreated,
		payment,
		maxGain,
		status,
		eventCount,
		paymentPerCombination,
		handlingFeePercentage,
		handlingFee,
		maxCoefficient,
		betSlipStatus,
		gain,
		displayedCashoutGain,
		metadata,
		betStatus,
		betSlipNumber,
		payout,
		gainBonusPercentage,
		gainBonus,
		combinationBettingOffersBankCount,
		combinationBettingOffersCount,
		gainTaxPercentage,
		gainTax,
	} = slipDetails;

	const betStatusClassname = classNames(
		[`bet-status bet-status--${betSlipStatus.abrv}`],
		{
			"bet-status--cashout": betSlipStatus.abrv == "cashout"
		},
		"u-mar--left--tny"
	);

	return (
		<div className="popup--dialog">
			<div className="popup--dialog__card">
				<div className="popup--dialog__card__header u-display--f">
					<span>
						{t("ACC_STATEMENT.BET_DETAILS.BET_SLIP_NUMBER_SINGLE", {
							0: betSlipNumber,
						})}
					</span>

					<span className={betStatusClassname}>
						{t("ACC_STATEMENT.BET_DETAILS." + betStatus.abrv.toUpperCase()).toUpperCase()}
						{isLive ?
							`*${t("ACC_STATEMENT.BET_DETAILS.D_LIVE").toUpperCase()}*`
						: ""}
					</span>
				</div>
				<div className="popup--dialog__card__body">
					<div className="table--bet">
						<div className="table--bet__header u-display--f">
							<div className="table--bet__header__data table--bet__header__data--time u-type--left">
								{/* react also does escape */}
								{t("ACC_STATEMENT.BET_DETAILS.INFO_DATE", {
									interpolation: { escapeValue: false },
									0: DateTime.fromISO(
										dateCreated
									).toLocaleString(
										DateTime.DATETIME_SHORT_WITH_SECONDS
									),
								})}
							</div>

							<div className="table--bet__header__data table--bet__header__data--type">
								<AdditionalDescriptionSystem
									combinationBettingOffersBankCount={
										combinationBettingOffersBankCount
									}
									eventCount={eventCount}
									combinationBettingOffersCount={
										combinationBettingOffersCount
									}
									system={system}
								/>

								<AdditionalDescriptionNotSystem
									system={system}
									totalNumberOfCombinations={
										totalNumberOfCombinations
									}
								/>
							</div>
							<div className="table--bet__header__data table--bet__header__data--price">
								{t("ACC_STATEMENT.BET_DETAILS.INFO_PAYMENT", {
									0: payment.toFixed(2),
								})}{" "}
								{currency}
							</div>
							<div
								className="table--bet__header__data table--bet__header__data--price"
								colSpan={2}
							>
								{t("ACC_STATEMENT.BET_DETAILS.INFO_MAX_GAIN", {
									0: maxGain.toFixed(2),
								})}{" "}
								{currency}
							</div>
							<div className="table--bet__header__data">
								&nbsp;
							</div>
							<div className="table--bet__header__data">
								&nbsp;
							</div>
						</div>
						<div className="table--bet__subheader u-display--f">
							<div className="table--bet__subheader__data table--bet__subheader__data--time">
								{t("ACC_STATEMENT.BET_DETAILS.T_TIME")}
							</div>
							<div className="table--bet__subheader__data table--bet__subheader__data--char">
								&nbsp;
							</div>
							<div className="table--bet__subheader__data table--bet__subheader__data--event">
								{t("ACC_STATEMENT.BET_DETAILS.T_EVENT")}
							</div>
							<div className="table--bet__subheader__data table--bet__subheader__data--fix">
								&nbsp;
							</div>
							<div className="table--bet__subheader__data table--bet__subheader__data--bet-type">
								{t("ACC_STATEMENT.BET_DETAILS.T_TIP")}
							</div>
							<div className="table--bet__subheader__data table--bet__subheader__data--odds">
								{t("ACC_STATEMENT.BET_DETAILS.T_QUOTA")}
							</div>
							<div className="table--bet__subheader__data table--bet__subheader__data--results">
								{t("ACC_STATEMENT.BET_DETAILS.T_RESULT")}
							</div>
							<div className="table--bet__subheader__data table--bet__subheader__data--fix">
								&nbsp;
							</div>
						</div>
						<div className="table--bet__body">
							{finalOffers &&
								finalOffers.map((offer) => (
									<AccountStatetementBetDetailsOffer
										offer={offer}
										isSystem={isSystem}
										totalCombinations={
											totalNumberOfCombinations
										}
										isLive={isLive}
										key={offer.bettingOfferId}
										status={betSlipStatus.abrv}
									/>
								))}
						</div>
						<div className="table--bet__details">
							<div className="table--bet__details__row">
								<div className="table--bet__details__col">
									{t(
										"ACC_STATEMENT.BET_DETAILS.ADDITIONAL_NUM_SELECTED_EVENTS"
									)}
								</div>
								<div className="table--bet__details__col">
									{eventCount}
								</div>
							</div>
							<div className="table--bet__details__row">
								<div className="table--bet__details__col">
									<p>
										{t(
											"ACC_STATEMENT.BET_DETAILS.ADDITIONAL_NUM_BETS"
										)}
									</p>
								</div>
								<div className="table--bet__details__col">
									{totalNumberOfCombinations}
								</div>
							</div>
							<div className="table--bet__details__row">
								<div className="table--bet__details__col">
									{t(
										"ACC_STATEMENT.BET_DETAILS.ADDITIONAL_STAKE_PER_BET"
									)}
								</div>
								<div className="table--bet__details__col">
									{paymentPerCombination.toFixed(2)} {currency}
								</div>
							</div>

							<div className="table--bet__details__row">
								<div className="table--bet__details__row">
									<div className="table--bet__details__col">
										{t(
											"ACC_STATEMENT.BET_DETAILS.ADDITIONAL_TOTAL_STAKE"
										)}{" "}
										({combinationBettingOffersCount} x{" "}
										{paymentPerCombination.toFixed(2)})
									</div>
									<div className="table--bet__details__col">
										{payment.toFixed(2)} {currency}
									</div>
								</div>
							</div>

							<div className="table--bet__details__row">
								<div className="table--bet__details__col">
									{t(
										"ACC_STATEMENT.BET_DETAILS.ADDITIONAL_MAX_COEFFICIENT"
									)}
								</div>
								<div className="table--bet__details__col">
									{maxCoefficient.toFixed(2)}
								</div>
							</div>

							<HandlingFeePercentage
								handlingFee={handlingFee}
								handlingFeePercentage={handlingFeePercentage}
							/>

							<GainTax
								gainTaxPercentage={gainTaxPercentage}
								gainTax={gainTax}
							/>

							<GainBonusPercentage
								gainBonusPercentage={gainBonusPercentage}
								gainBonus={gainBonus}
							/>

							<div className="table--bet__details__row">
								<div className="table--bet__details__col">
									{t(
										"ACC_STATEMENT.BET_DETAILS.ADDITIONAL_MAX_GAIN"
									)}
								</div>
								<div className="table--bet__details__col">
									{maxGain.toFixed(2)} {currency}
								</div>
							</div>

							<AdditionalGain payout={payout} />
						</div>
					</div>
				</div>

				<div className="popup--dialog__card__footer">
					<Button
						btnClassName="btn btn--sml btn--secondary btn--icon-left"
						btnType="button"
						onClick={() => setShowDetails(0)}
						btnWithFrontIcon={
							<i className="u-icon u-icon--xsml u-icon--remove--white"></i>
						}
						btnText="ACC_STATEMENT.BET_DETAILS.CLOSE_BTN"
					/>
				</div>
			</div>
		</div>
	);
}

function GainTax(props) {
	const { t } = useTranslation();
	const { currency } = useCurrency();

	const { gainTax, gainTaxPercentage } = props;

	if (!gainTax && !gainTaxPercentage) {
		return null;
	}

	return (
		<div className="table--bet__details__row">
			<div className="table--bet__details__col">
				{t("ACC_STATEMENT.BET_DETAILS.ADDITIONAL_GAIN_TAX", {
					0: gainTaxPercentage,
				})}
			</div>
			<div className="table--bet__details__col">
				- {gainTax.toFixed(2)} {currency}
			</div>
		</div>
	);
}

function AdditionalDescriptionSystem(props) {
	const { t } = useTranslation();

	const { system } = props;

	if (isNullOrWhitespace(system)) {
		return null;
	}

	const systemString = getSystemString({ ...props });

	return t("ACC_STATEMENT.BET_DETAILS.INFO_SYSTEM", {
		interpolation: { escapeValue: false },
		0: systemString,
	});
}

function AdditionalDescriptionNotSystem(props) {
	const { system, totalNumberOfCombinations } = props;

	if (system) {
		return null;
	}

	return (
		<>
			<AdditionalDescriptionCombination
				totalNumberOfCombinations={totalNumberOfCombinations}
			/>
			<AdditionalDescriptionCombinations
				totalNumberOfCombinations={totalNumberOfCombinations}
			/>
		</>
	);
}

function AdditionalDescriptionCombinations(props) {
	const { t } = useTranslation();

	const { totalNumberOfCombinations } = props;

	if (totalNumberOfCombinations <= 1) {
		return null;
	}

	return t("ACC_STATEMENT.BET_DETAILS.INFO_COMBINATION_MULTI", {
		0: totalNumberOfCombinations,
	});
}

function AdditionalDescriptionCombination(props) {
	const { t } = useTranslation();

	const { totalNumberOfCombinations } = props;

	if (totalNumberOfCombinations > 1) {
		return null;
	}

	return t("ACC_STATEMENT.BET_DETAILS.INFO_COMBINATION", {
		0: totalNumberOfCombinations,
	});
}

function HandlingFeePercentage(props) {
	const { t } = useTranslation();
	const { currency } = useCurrency();

	const { handlingFeePercentage, handlingFee } = props;

	if (!handlingFeePercentage || handlingFeePercentage == 0) {
		return null;
	}

	return (
		<div className="table--bet__details__row">
			<div className="table--bet__details__col">
				{t("ACC_STATEMENT.BET_DETAILS.ADDITIONAL_HANDLING_FEE", {
					0: handlingFeePercentage,
				})}
			</div>
			<div className="table--bet__details__col">
				- {handlingFee.toFixed(2)} {currency}
			</div>
		</div>
	);
}

function AdditionalGain(props) {
	const { t } = useTranslation();
	const { currency } = useCurrency();

	const { payout } = props;

	if (!payout || isNullOrWhitespace(payout.toString())) {
		return null;
	}

	return (
		<div className="table--bet__details__row">
			<div className="table--bet__details__col u-type--color--won">
				{t("ACC_STATEMENT.BET_DETAILS.ADDITIONAL_GAIN")}
			</div>
			<div className="table--bet__details__col u-type--color--won">
				{payout.toFixed(2)} {currency}
			</div>
		</div>
	);
}

function GainBonusPercentage(props) {
	const { t } = useTranslation();

	const { currency } = useCurrency();

	const { gainBonusPercentage, gainBonus } = props;

	if (!gainBonusPercentage || gainBonusPercentage == 0) {
		return null;
	}

	return (
		<div className="table--bet__details__row">
			<div className="table--bet__details__col">
				{t("ACC_STATEMENT.BET_DETAILS.ADDITIONAL_GAIN_BONUS", {
					0: gainBonusPercentage,
				})}
			</div>
			<div className="table--bet__details__col">
				+ {gainBonus.toFixed(2)} {currency}
			</div>
		</div>
	);
}
