import React from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const StartTimeLiveTemplate = observer((props) => {
	const { t, i18n } = useTranslation();

	let day;
	if (props.isToday) {
		day = t('OFFER.TIME.TODAY');
	}
	else if (props.isTomorrow) {
		day = t('OFFER.TIME.TOMORROW_SHORT');
	}
	else {
		day = moment(props.event.startTime).locale(i18n.language).format('ddd DD.MM.');
	}

	const time = moment(props.event.startTime).format('HH:mm');

	return (
		<div className={props.className}>
			<div className="offer__day__date">{day}</div>
			<strong className="offer__day__time">{time}</strong>
			{(props.event.hasLiveCoverage && !props.event.isLive) ? (<div className="tag tag--sml">Live</div>) : null}
		</div>
	)
});