import React from "react";
import { FormProvider, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../common/components/buttons";
import { useAppContext, useUser } from "../../../../../common/hooks";
import {
	AccountActivationEmailSection,
	AccountActivationPasswordSection,
} from "../../../../../pages/membership/account-acitvation/components";

export default function AccountActivationTemplate(props) {
	const {
		methods,
		onSuccess,
		accountActivationData,
		createAccountActivation,
		showPopup,
		setShowPopup,
	} = props;

	const { t } = useTranslation();
	const { culture } = useAppContext();
	const { handleSubmit } = methods;
	const user = useUser();

	if (accountActivationData == null) {
		return null;
	}

	const logout = () => {
		user.removeUserFromLocalStorage();
		App.state.history.push(`/${culture}/home/full`);
	};

	return (
		<>
			<div className="view--med">
				<div className="title--primary">
					{t("MEMBERSHIP.ACCOUNT_ACTIVATION.TITLE")}
				</div>
				<FormProvider {...methods}>
					<form
						className="form"
						onSubmit={handleSubmit(onSuccess)}
						autoComplete="off"
					>
						<section>
							{accountActivationData?.userInfo
								?.isNewPasswordRequired && (
								<AccountActivationPasswordSection
									accountActivationData={
										accountActivationData
									}
								/>
							)}
							{!accountActivationData?.userInfo
								?.isOfflineUserMailApproved && (
								<AccountActivationEmailSection
									methods={methods}
									accountActivationData={
										accountActivationData
									}
								/>
							)}
						</section>

						<div className="form__field row">
							<div className="col-sml-11 u-type--right">
								<Button
									className="btn btn--med btn--neutral"
									onClick={() => logout()}
									btnText="COMMON.CANCEL_BUTTON"
									btnType="button"
								/>
								<Button
									className="form__submit btn btn--med btn--secondary"
									btnText={t(
										"specific:MEMBERSHIP.ACCOUNT_ACTIVATION.SUBMIT"
									)}
								/>
							</div>
						</div>
						<InfoPopup
							showPopup={showPopup}
							setShowPopup={setShowPopup}
							createAccountActivation={createAccountActivation}
							accountActivationData={accountActivationData}
						/>
					</form>
				</FormProvider>
			</div>
		</>
	);
}

function InfoPopup(props) {
	const { showPopup, setShowPopup, createAccountActivation } = props;

	const { getValues } = useFormContext();
	const { t } = useTranslation();

	if (showPopup?.displayNoEmailPopup) {
		return (
			<div className="popup">
				<div className="popup__card">
					<div className="popup__card__header">
						{t(
							"specific:MEMBERSHIP.ACCOUNT_ACTIVATION.POPUPS.CONFIRMATION_LABEL"
						)}
					</div>
					<div className="popup__card__body">
						<div className="message--base message--help">
							<strong>
								{t(
									"specific:MEMBERSHIP.ACCOUNT_ACTIVATION.POPUPS.MAIL_NOT_ENTERED"
								)}
							</strong>
						</div>
					</div>
					<div className="popup__card__footer">
						<button
							className="btn btn--sml btn--primary btn--icon-left"
							type="button"
							onClick={() => setShowPopup(false)}
						>
							<i className="u-icon u-icon--sml u-icon--remove--white" />
							{t(
								"specific:MEMBERSHIP.ACCOUNT_ACTIVATION.POPUPS.CANCEL"
							)}
						</button>
						<button
							className="btn btn--sml btn--primary btn--icon-left"
							type="button"
							onClick={() => createAccountActivation(getValues())}
						>
							<i className="u-icon u-icon--sml u-icon--check--white" />
							{t(
								"specific:MEMBERSHIP.ACCOUNT_ACTIVATION.POPUPS.CONTINUE"
							)}
						</button>
					</div>
				</div>
			</div>
		);
	}

	if (showPopup?.displayNoPasswordPopup) {
		return (
			<div className="popup">
				<div className="popup__card">
					<div className="popup__card__header">
						{t(
							"specific:MEMBERSHIP.ACCOUNT_ACTIVATION.POPUPS.CONFIRMATION_LABEL"
						)}
					</div>
					<div className="popup__card__body">
						<div className="message--base message--help">
							<strong>
								{t(
									"specific:MEMBERSHIP.ACCOUNT_ACTIVATION.POPUPS.PWD_NOT_ENTERED"
								)}
							</strong>
						</div>
					</div>
					<div className="popup__card__footer">
						<button
							className="btn btn--sml btn--primary btn--icon-left"
							type="button"
							onClick={() => setShowPopup(false)}
						>
							<i className="u-icon u-icon--sml u-icon--remove--white" />
							{t(
								"specific:MEMBERSHIP.ACCOUNT_ACTIVATION.POPUPS.CANCEL"
							)}
						</button>
						<button
							className="btn btn--sml btn--primary btn--icon-left"
							type="button"
							onClick={() => createAccountActivation(getValues())}
						>
							<i className="u-icon u-icon--sml u-icon--check--white" />
							{t(
								"specific:MEMBERSHIP.ACCOUNT_ACTIVATION.POPUPS.CONTINUE"
							)}
						</button>
					</div>
				</div>
			</div>
		);
	}

	if (showPopup?.displayNoPasswordAndEmailPopup) {
		return (
			<div className="popup">
				<div className="popup__card">
					<div className="popup__card__header">
						{t(
							"specific:MEMBERSHIP.ACCOUNT_ACTIVATION.POPUPS.CONFIRMATION_LABEL"
						)}
					</div>
					<div className="popup__card__body">
						<div className="message--base message--help">
							<strong>
								{t(
									"specific:MEMBERSHIP.ACCOUNT_ACTIVATION.POPUPS.PASSWORD_EMAIL_NOT_ENTERED"
								)}
							</strong>
						</div>
					</div>
					<div className="popup__card__footer">
						<button
							className="btn btn--sml btn--primary btn--icon-left"
							type="button"
							onClick={() => setShowPopup(false)}
						>
							<i className="u-icon u-icon--sml u-icon--remove--white" />
							{t(
								"specific:MEMBERSHIP.ACCOUNT_ACTIVATION.POPUPS.CANCEL"
							)}
						</button>
						<button
							className="btn btn--sml btn--primary btn--icon-left"
							type="button"
							onClick={() => createAccountActivation(getValues())}
						>
							<i className="u-icon u-icon--sml u-icon--check--white" />
							{t(
								"specific:MEMBERSHIP.ACCOUNT_ACTIVATION.POPUPS.CONTINUE"
							)}
						</button>
					</div>
				</div>
			</div>
		);
	}

	return null;
}

//TODO: no email popup
/*
 <div id="offlineConfirmPopup" className="popup" style="display: none;">
        <div class="popup__card">
            <div class="popup__card__header">
                Confirmation
            </div>
            <div class="popup__card__body">
                <div class="message--base message--help">
                    <strong id="confirmPopupText">Mail address is not entered. Are you sure you want to proceed without activating your email address?</strong>
                </div>
            </div>
            <div class="popup__card__footer">
                <button id="closePopup" class="btn btn--sml btn--primary btn--icon-left" type="button">
                    <i class="u-icon u-icon--sml u-icon--remove--white"/>
                    Cancel
                </button>
                <button id="acceptNoEmail" class="btn btn--sml btn--primary btn--icon-left" type="button">
                    <i class="u-icon u-icon--sml u-icon--check--white"/>
                    Continue
                </button>
            </div>
        </div>
    </div>

*/

//TODO: no password popup

/*
<div id="offlineConfirmPopup" class="popup" style="">
        <div class="popup__card">
            <div class="popup__card__header">
                Confirmation
            </div>
            <div class="popup__card__body">
                <div class="message--base message--help">
                    <strong id="confirmPopupText">You did not enter a new password. Are you sure you want to continue without changing the password?</strong>
                </div>
            </div>
            <div class="popup__card__footer">
                <button id="closePopup" class="btn btn--sml btn--primary btn--icon-left" type="button">
                    <i class="u-icon u-icon--sml u-icon--remove--white"/>
                    Cancel
                </button>
                <button id="acceptNoEmail" class="btn btn--sml btn--primary btn--icon-left" type="button">
                    <i class="u-icon u-icon--sml u-icon--check--white"/>
                    Continue
                </button>
            </div>
        </div>
    </div>

*/

// TODO: no email and password popup

/* 
<div id="offlineConfirmPopup" class="popup" style="">
        <div class="popup__card">
            <div class="popup__card__header">
                Confirmation
            </div>
            <div class="popup__card__body">
                <div class="message--base message--help">
                    <strong id="confirmPopupText">You did not enter a new password or your email address. Are you sure you do not want to change the password or to activate an email address?</strong>
                </div>
            </div>
            <div class="popup__card__footer">
                <button id="closePopup" class="btn btn--sml btn--primary btn--icon-left" type="button">
                    <i class="u-icon u-icon--sml u-icon--remove--white"/>
                    Cancel
                </button>
                <button id="acceptNoEmail" class="btn btn--sml btn--primary btn--icon-left" type="button">
                    <i class="u-icon u-icon--sml u-icon--check--white"/>
                    Continue
                </button>
            </div>
        </div>
    </div>

*/
