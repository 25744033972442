import React from "react";
import { Controller } from "react-hook-form";
import RDTP from "react-datetime-picker/dist/entry.nostyle";
import { ErrorMessage } from "../../../../../common/components/error";
import { RequiredMark } from "../../../../../common/components/inputs";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

const DatePickerTemplate = (props) => {
	const { t } = useTranslation();

	const {
		label,
		name,
		className,
		errorMessage = undefined,
		required = false,
		disabled = false,
		readOnly,
		form,
		validate,
		format,
		currentDateFormat,
		...dateTimePickerProps
	} = props;

	return (
		<div className={className}>
			<div className="col-sml-4">
				<label className="form__field__label">
					{t(label)}
					<RequiredMark required={required} />
				</label>
			</div>
			<div className="col-sml-7">
				<Controller
					name={name}
					control={form.control}
					rules={{
						required: {
							value: required,
							message: errorMessage || "COMMON.REQUIRED_FIELD",
						},
						validate: validate,
					}}
					render={({ field }) => (
						<RDTP
							className={
								readOnly
									? "react-datetime-picker--readonly"
									: ""
							}
							value={
								field.value
									? DateTime.fromISO(field.value).toJSDate()
									: undefined
							}
							onChange={(date) =>
								field.onChange(
									date ? date.toISOString() : undefined
								)
							}
							locale={App.utils.getCurrentCulture()}
							dayPlaceholder={t("COMMON.DAY_PLACEHOLDER")}
							monthPlaceholder={t("COMMON.MONTH_PLACEHOLDER")}
							yearPlaceholder={t("COMMON.YEAR_PLACEHOLDER")}
							format={format || currentDateFormat}
							disableCalendar={disabled}
							clearIcon={disabled ? null : undefined}
							disabled={disabled}
							{...dateTimePickerProps}
						/>
					)}
				/>
			</div>
			<div className="col-sml-1">
				<ErrorMessage name={name} />
			</div>
		</div>
	);
};

export default DatePickerTemplate;
