import React from 'react';
import { defaultTemplate } from '../../../../../common/hoc';

export default defaultTemplate(function (props) {
    const { t } = props;
    return (
        <div className="widget u-mar--top--sml">
            <div className="widget__header">{t('RESULTS.TITLE')}</div>
            <div className="widget__banner">
                <a href={`/${App.utils.getCurrentCulture()}/results`} target="_blank">
                    <img src={"/assets/images/bg/vip-results-banner--" + App.utils.getCurrentCulture() + ".png"} className="widget__banner__img" alt="" />
                </a>
            </div>
        </div>
    )
});