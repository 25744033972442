import React from "react";
import { useFormContext } from "react-hook-form";
import { PasswordInput } from "../../../../../../common/components/inputs";
import { RecoverPasswordNewPasswordWithProgress } from "../../../../../../pages/membership/recover-password/components";
import { FormButton } from "../../../../../../common/components/buttons";

const passwordPattern = /^[a-zA-Z0-9!#$()@?{}|*+,^.\-+&=_%:;~]+$/;

export default function RecoverPasswordFormSectionTemplate(props) {

    const { handleSubmit, getValues } = useFormContext();

    const {
        onSubmitData
    } = props;

    return (
        <form className="form" onSubmit={handleSubmit(onSubmitData)}>

            <RecoverPasswordNewPasswordWithProgress
                passwordPattern={passwordPattern}
            />

            <PasswordInput
                className="form__field row"
                name="repeatPassword"
                required
                label="MEMBERSHIP.RECOVER_PASSWORD.REPEAT_NEW_PASSWORD"
                placeholder="MEMBERSHIP.RECOVER_PASSWORD.REPEAT_NEW_PASSWORD_PLACEHOLDER"
                pattern={{
                    value: passwordPattern
                }}
                validate={{
                    isEqual: value => value === getValues('newPassword')
                }}


            />

            <div className="form__field row">
                <div className="col-sml-11 u-type--right">

                    <FormButton
                        btnText="MEMBERSHIP.RECOVER_PASSWORD.SAVE_BUTTON"
                        className="form__submit btn btn--med btn--secondary"
                    />

                </div>
            </div>
        </form>
    )
}