import React from 'react';
import { OfferPrintPageTemplate } from 'themes/pages/offer-print';
import { ErrorPage } from '../../common/components/error';

export default function OfferPrintPage(props) {
    return (
        <ErrorPage>
            <div className='l--page'>
                <OfferPrintPageTemplate {...props} />
            </div>
        </ErrorPage>
    )
}