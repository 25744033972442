import React from "react";
import { Switch } from "react-router-dom";
import { observer } from "mobx-react";
import ReactTooltip from "react-tooltip";

import {
	AccountSettingsLayout,
	InfoPagesLayout,
	MembershipLayout,
	SportsBettingLayout,
} from "../../../layouts";

import {
	NoMatchRoute,
	RouteElement,
} from "../../../common/components/navigation";
import { Footer } from "../../../common/components/footer";
import { Header } from "../../../common/components/header";
import { useAppContext } from "../../../common/hooks";
import { Loader } from "../../../common/components/loader";
import { AccountStatement } from "../../../pages/account-statement";
import { LoginPopup } from "../../../common/components/popups";
import { MyBetsPage } from "../../../pages/my-bets";
import { OfferPrintPage } from "../../../pages/offer-print";
import { PopupDisplay } from "../../../common/components/popups";
import { ErrorPage } from "../../../common/components/error";

import { ResultsPage } from "../../../pages/results";
import { EmailActivation } from "../../../pages/membership/email-activation";

const MainLayoutTemplate = function (props) {
	return (
		<>
			<PopupDisplay />
			<Loader />
			<Header />
			<ErrorPage>
				<Switch>
					<RouteElement
						path={[
							"/:culture/home/",
							"/:culture/sports/",
							"/:culture/live/",
							"/:culture/search/",
						]}
					>
						<SportsBettingLayout />
					</RouteElement>

					<RouteElement path={["/:culture/info"]}>
						<InfoPagesLayout />
					</RouteElement>

					<RouteElement path={["/:culture/auth"]}>
						<MembershipLayout />
					</RouteElement>

					<RouteElement
						path={["/:culture/account-settings"]}
						privateRoute
					>
						<AccountSettingsLayout />
					</RouteElement>

					<RouteElement
						path="/:culture/account-statement"
						privateRoute
					>
						<AccountStatement />
					</RouteElement>

					<RouteElement
						path={[
							"/:culture/my-bets/:betsType",
							"/:culture/my-bets",
						]}
						privateRoute
					>
						<MyBetsPage />
					</RouteElement>
					<RouteElement path="/:culture/email-activation">
						<EmailActivation />
					</RouteElement>

					<RouteElement path="/:culture/results">
						{/* TODO: add results route component here */}
						<ResultsPage />
					</RouteElement>

					<RouteElement path="/:culture/offer">
						<OfferPrintPage />
					</RouteElement>

					<NoMatchRoute />
				</Switch>

				<ReactTooltip
					id="tv-description"
					className="tooltip--secondary"
					place="bottom"
					multiline={true}
				/>
				{/* <LoginPopup /> */}
				<div id="login-popup-container"></div>
				<LoginPopup />
			</ErrorPage>
			<footer className="footer">
				<Footer />
			</footer>
		</>
	);
};

export default observer(MainLayoutTemplate);
