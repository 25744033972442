import React, { useEffect } from 'react';
import { observer } from "mobx-react";
import moment from 'moment';
import ReactTooltip from "react-tooltip";

export const LiveMatchTimeTemplate = observer((props) => {
	useEffect(() => {
		ReactTooltip.rebuild();
	});

	if (props.event.matchTime != undefined) {
		return (
			<td
				className={props.className}
				data-for="match-period-score"
				data-tip={props.event.id}
			>
				<div className="offer__time__inner">{props.event.matchTime.displayTime}</div>
			</td>
		)
	}

	return (
		<td
			className={props.className}
			data-for="match-period-score"
			data-tip={props.event.id}
		>
			<div className="offer__time__inner">{moment(props.event.startTime).format('HH:mm')}</div>
		</td>
	)
});