import React, { useState } from "react";
import { useFormContext } from 'react-hook-form';
import { validate } from '../utils';
import { PasswordStrengthBarTemplate } from 'themes/pages/membership/account-activation/components';

export default function PasswordStrengthBar(props) {

    const { userInfo, passwordPattern, isPasswordRequired, popoverMsg } = props;
    const { formState: { isSubmitting } } = useFormContext();
    const [progressCounter, setProgressCounter] = useState(0);

    if (isSubmitting && progressCounter !== 0) {
        setProgressCounter(0);
    }

    const onChangeInputPassword = (e) => {
        const passwordInput = validate(e.target.value, userInfo);
        setProgressCounter(passwordInput * 25);
    }


    return <PasswordStrengthBarTemplate
        progressCounter={progressCounter}
        onChangeInputPassword={onChangeInputPassword}
        passwordPattern={passwordPattern}
        isPasswordRequired={isPasswordRequired}
        popoverMsg={popoverMsg}
    />

}