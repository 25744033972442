import React, { useState, useContext, useEffect } from 'react';
import { inject } from 'mobx-react';

import { ConsoleLogger } from "@gp/utility";
import { AdditionalOfferStore } from "@gp/offer";
import { OfferOptionsContext, AdditionalOfferStoreContext, AdditionalOfferOptionsContext } from "@gp/components";

import { InlineAdditionalOfferTemplate } from 'themes/common/components/offer/new-offer';

export const InlineAdditionalOffer = inject(s => ({
	rootStore: s.offerStore
}))((props) => {
	const offerOptionsContext = useContext(OfferOptionsContext);

	const [isLoading, setIsLoading] = useState(false);
	const [viewStore] = useState(new AdditionalOfferStore({
		eventId: props.eventId,
		enableGroups: offerOptionsContext.isLive,
		enableThrottling: true,
		throttle: 4,
		removeDelay: 10,
		logger: new ConsoleLogger(false)
	}));

	const additionalOfferSubscriptionRequest = {
		subscriptionId: `additional-${props.eventId}`,
		// compress: true,
		compress: false,
		channels: [
			{
				name: 'event',
				filter: {
					id: props.eventId
				}
			},
			{
				name: 'betOffer'
			}
		],
	}

	useEffect(() => {
		if (!props.rootStore.hub.isStarted) {
			return;
		}

		setIsLoading(true);
		const $offerSubscription = props.rootStore.hub.getOfferSubscription(additionalOfferSubscriptionRequest).subscribe(response => {
			viewStore.assignOfferData(response);
			setIsLoading(false);
		});

		return () => {
			$offerSubscription.unsubscribe();
		}

	}, [props.rootStore.hub.isStarted]);

	if (!props.rootStore.hub.isStarted) {
		return null;
	}

	return (
		<AdditionalOfferStoreContext.Provider value={viewStore}>
			<AdditionalOfferOptionsContext.Provider value={{
				excludeKeyIds: props.excludeKeyIds,
				onClose: props.onClose,
			}}>
				<InlineAdditionalOfferTemplate isLoading={isLoading} />
			</AdditionalOfferOptionsContext.Provider>
		</AdditionalOfferStoreContext.Provider>
	);
});