import React from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';

import { StartTimeLiveTemplate } from 'themes/common/components/offer/new-offer/common';

export const StartTimeLive = (observer(function StartTimeLive(props) {
	const now = moment().local();
	const tomorrow = moment().local().add(1, 'day');
	let dt = moment(props.event.startTime);

	const isToday = dt.isSame(now, 'day');
	const isTomorrow = dt.isSame(tomorrow, 'day');

	return (
		<StartTimeLiveTemplate
			className={props.className}
			event={props.event}
			isToday={isToday}
			isTomorrow={isTomorrow}
		/>
	);
}));