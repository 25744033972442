import { DateTime } from "luxon";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useLocation } from "react-router-dom";
import {
	HeaderAuthNavigation,
	HeaderNavigation,
	Language,
} from "../../../../../common/components/header/components";
import { Clock } from "../../../../../common/components/subheader";
import { useAppContext, useUser } from "../../../../../common/hooks";

export default observer(function HeaderTemplate(props) {
	const { methods, onSuccess, loginError, setLoginError } = props;
	const { culture } = useAppContext();
	// test
	const user = useUser();
	const { t } = useTranslation();
	const location = useLocation();

	if (location.pathname.includes("email-activation")) {
		return <EmailActivationHeader />;
	}

	return (
		<header className="header--primary">
			<div className="header--primary__top">
				<div className="container">
					<HeaderTopMenu />
				</div>
			</div>

			<div className="header--primary__middle">
				<div className="container">
					<div className="row row--no-gutters">
						<div className="col col-sml-3">
							<div className="header--primary__logo">
								<figure className="header--primary__logo__wrap">
									<Link
										to={`/${culture}/home/full`}
										className="header--primary__logo__img is-active"
									></Link>
								</figure>
							</div>
						</div>
						{location.pathname != `/${culture}/offer` && (
							<div className="col col-sml-9">
								{!App.state.rootStore.isError404PageActive &&
								user.user != null ? (
									<HeaderAuthNavigation />
								) : (
									<div className="header--primary__memebership">
										<HeaderNavigation
											methods={methods}
											onSuccess={onSuccess}
											loginError={loginError}
											setLoginError={setLoginError}
										/>
									</div>
								)}
							</div>
						)}
					</div>
				</div>
			</div>

			<nav className="header--primary__nav">
				<div className="container">
					<nav className="nav--primary">
						<ul className="nav--primary__menu">
							<li className="nav--primary__item">
								<NavLink
									to={`/${culture}/home/full`}
									className="nav--primary__link"
									activeClassName="is-active"
									isActive={(match, location) =>
										location.pathname.includes("home") ||
										match
									}
								>
									{t("HOME.HOME")}
								</NavLink>
							</li>
							<li className="nav--primary__item">
								<NavLink
									to={`/${culture}/sports/full`}
									className="nav--primary__link"
									activeClassName="is-active"
									isActive={(match, location) =>
										location.pathname.includes("sports") ||
										match
									}
								>
									{t("SPORTS.SPORTS_BETTING")}
								</NavLink>
							</li>
							<li className="nav--primary__item">
								<NavLink
									to={`/${culture}/live/events`}
									className="nav--primary__link"
									activeClassName="is-active"
									isActive={(match, location) =>
										location.pathname.includes("live") ||
										match
									}
								>
									{t("LIVE.LIVE_BETTING")}
								</NavLink>
							</li>
							<li className="nav--primary__item">
								<NavLink
									to={`/${culture}/info/contact`}
									className="nav--primary__link"
									activeClassName="is-active"
									isActive={(match, location) =>
										location.pathname.includes(
											"info/contact"
										) || match
									}
								>
									{t("FOOTER.CONTACT")}
								</NavLink>
							</li>
						</ul>
					</nav>
				</div>
			</nav>
		</header>
	);
});

const HeaderTopMenu = observer(function () {
	const user = useUser();

	const { t } = useTranslation();
	const { pathname } = useLocation();
	if (pathname.includes("email-activation")) {
		return (
			<div className="row row--no-gutters">
				<div className="col col-sml-6">
					<Clock />
				</div>
				<div className="col col-sml-6 u-type--right"></div>
			</div>
		);
	}

	if (user.user == null) {
		return (
			<div className="row row--no-gutters">
				<div className="col col-sml-6">
					<Clock />
				</div>
				<div className="col col-sml-6 u-type--right">
					<Language />
				</div>
			</div>
		);
	}

	return (
		<div className="row row--no-gutters">
			<div className="col col-sml-3">
				<Clock />
			</div>
			<div className="col col-sml-6">
				<div className="header--primary__top__activity">
					<div>
						{t("HEADER.LAST_LOGIN")}{" "}
						{DateTime.fromISO(
							user.loginHistory?.lastLogin
						).toLocaleString(
							DateTime.DATETIME_SHORT_WITH_SECONDS
						)}{" "}
					</div>
					<div>
						{t("HEADER.LOGGED_IN_FROM")}{" "}
						{DateTime.fromISO(
							user.loginHistory?.currentLogin
						).toLocaleString(
							DateTime.DATETIME_SHORT_WITH_SECONDS
						)}{" "}
					</div>
				</div>
			</div>
			<div className="col col-sml-3 u-type--right">
				<Language />
			</div>
		</div>
	);
});

const EmailActivationHeader = () => {
	const { t } = useTranslation();
	const { culture } = useAppContext();

	return (
		<header className="header--primary">
			<div className="header--primary__top">
				<div className="container">
					<HeaderTopMenu />
				</div>
			</div>
			<div className="header--primary__middle">
				<div className="container">
					<div className="row row--no-gutters">
						<div className="col col-sml-3">
							<div className="header--primary__logo">
								<figure className="header--primary__logo__wrap">
									<Link
										to={`/${culture}/home/full`}
										className="header--primary__logo__img is-active"
									></Link>
								</figure>
							</div>
						</div>
					</div>
				</div>
			</div>
			<nav className="header--primary__nav">
				<div className="container">
					<nav className="nav--primary">
						<ul className="nav--primary__menu">
							<li className="nav--primary__item">
								<NavLink
									to={`/${culture}/home/full`}
									className="nav--primary__link"
									activeClassName="is-active"
									isActive={(match, location) =>
										location.pathname.includes("home") ||
										match
									}
								>
									{t("HOME.HOME")}
								</NavLink>
							</li>
							<li className="nav--primary__item">
								<NavLink
									to={`/${culture}/sports/full`}
									className="nav--primary__link"
									activeClassName="is-active"
									isActive={(match, location) =>
										location.pathname.includes("sports") ||
										match
									}
								>
									{t("SPORTS.SPORTS_BETTING")}
								</NavLink>
							</li>
							<li className="nav--primary__item">
								<NavLink
									to={`/${culture}/live/events`}
									className="nav--primary__link"
									activeClassName="is-active"
									isActive={(match, location) =>
										location.pathname.includes("live") ||
										match
									}
								>
									{t("LIVE.LIVE_BETTING")}
								</NavLink>
							</li>
							<li className="nav--primary__item">
								<NavLink
									to={`/${culture}/info/contact`}
									className="nav--primary__link"
									activeClassName="is-active"
									isActive={(match, location) =>
										location.pathname.includes(
											"info/contact"
										) || match
									}
								>
									{t("FOOTER.CONTACT")}
								</NavLink>
							</li>
						</ul>
					</nav>
				</div>
			</nav>
		</header>
	);
};
