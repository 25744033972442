import { observable, computed, action, runInAction } from "mobx";
import {
	MyBetsService,
	BetSlipService,
} from "../../../offer/services/components";
import { LookupService } from "../../../lookups";
import {
	CashoutStore,
	ReuseBetSlipStore,
	ActiveEventsStore,
	BetCancelStore,
	helpers,
} from "./index";
import { Bet } from "./model";
import { LoaderStore } from "../../../state/stores/common";

export default class BetDetailsStore {
	constructor(rootStore) {
		this.rootStore = rootStore;

		// Services
		this.betSlipService = new BetSlipService();
		this.myBetsService = new MyBetsService();
		this.lookupService = new LookupService();

		// Helper stores
		this.activeEventsStore = new ActiveEventsStore(rootStore);
		this.cashoutStore = new CashoutStore(rootStore, this);
		this.reuseBetSlipStore = new ReuseBetSlipStore(rootStore, this);
		this.betCancelStore = new BetCancelStore(rootStore, this);
		this.activeEventsStore = new ActiveEventsStore(rootStore, this);
		this.loader = new LoaderStore();
	}

	//#region observables

	@observable isStoreInitialized = false;
	@observable isFetchingData = true; // It has to initially fetch data
	@observable bet = null;
	@computed get betsList() {
		if (this.bet != null) {
			return [this.bet];
		}

		return [];
	}
	@computed get isEmpty() {
		return (
			this.bet == null && this.isStoreInitialized && !this.isFetchingData
		);
	}
	@computed get activeEvents() {
		return this.activeEventsStore.activeEventIds;
	}

	@observable betCombinationsMap = new observable.map();

	@observable betSlipStatusLookups = [];
	@observable userAccountTypes = [];
	@observable betStatusLookups = [];

	//#endregion observables

	//#region store initialization

	@action.bound
	async onInitialize() {
		this.cashoutStore.onInitialize();
		let betSlipStatusLookups = null;
		let userAccountTypes = null;
		let betStatusesLookups = null;

		try {
			betSlipStatusLookups =
				await this.lookupService.findBetSlipStatuses();
			userAccountTypes =
				await this.lookupService.findBettingAccountTypes();
			betStatusesLookups = await this.lookupService.findBetStatuses();
		} catch (error) {
			console.log(error);
		}

		runInAction(() => {
			this.betSlipStatusLookups = betSlipStatusLookups;
			this.userAccountTypes = userAccountTypes;
			this.betStatusLookups = betStatusesLookups;

			this.isStoreInitialized = true;
		});
	}

	//#endregion store initialization

	//#region data fetching

	@action.bound
	async getBetDetails(slipId) {
		this.isFetchingData = true;
		try {
			const bet = await this.betSlipService.getSlipWithoutCombinations(
				slipId
			);

			bet?.betSlipOffers?.forEach((offer) => {
				if (
					offer?.specifier?.player == null ||
					![
						"player-total-assists",
						"player-total-rebounds",
						"player-total-3-point-field-goals",
						"player-total-points",
					].includes(offer.sportData.bettingTypeAbrv)
				) {
					return;
				}

				offer.specifier.player =
					offer.playerFirstName +
					" " +
					offer.playerLastName +
					` (${
						offer.sportData.teamOneId === offer.teamId
							? offer.sportData.teamOneName
							: offer.sportData.teamTwoName
					})`;
				offer.playerId = undefined;
				offer.teamId = undefined;
			});
			const eventIdsFromBet = helpers.getEventIdsFromMyBets([bet]);
			this.activeEventsStore.fetchActiveEvents(eventIdsFromBet);

			runInAction(() => {
				this.bet = new Bet(bet);
				this.isFetchingData = false;
				this.cashoutStore.initializeCashoutSubscription();
			});
		} catch (error) {
			runInAction(() => {
				console.error(error);
				this.isFetchingData = false;
			});
		}
	}

	@action.bound
	refresh() {
		if (this.bet?.id == null) {
			return;
		}
		this.getBetDetails(this.bet.id);
	}

	@action.bound
	async initBetCombinationForBetSlip(betSlipId) {
		if (this.betCombinationsMap.has(betSlipId)) {
			return;
		}

		this.betCombinationsMap.set(betSlipId, null);

		const combinations = await this.myBetsService.getSystemCombinations(
			betSlipId
		);
		this.betCombinationsMap.set(betSlipId, combinations);
	}

	//#endregion data fetching

	//#region disposers

	@action.bound
	onDispose() {
		this.betSlipStatusLookups = [];
		this.userAccountTypes = [];
		this.betStatusLookups = [];

		this.betCancelStore.onDispose();
		this.cashoutStore.onDispose();
		this.betCombinationsMap.clear();
		this.isStoreInitialized = false;
		this.bet = null;
		this.isFetchingData = false;
	}

	//#endregion disposers
}
