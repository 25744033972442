import React from "react";
import { observer } from "mobx-react";
import { useLocation } from "react-router-dom";

import {
	AutomaticLogoutPopup,
	LiveSessionExpirePopup,
	RealityCheckPopup,
	SessionExpirePopup,
} from "../../../../../common/components/popups";

export default observer(function PopupDisplayTemplate() {
	const {
		sessionExpireStore: { isActive: isSessionExpireActive },
		liveSessionExpireStore: { isActive: isLiveSessionExpireActive },
		automaticLogoutStore: { isActive: isAutomaticLogoutActive },
		realityCheckStore: { isActive: isRealityCheckActive },
	} = App.state.rootStore;

	if (isSessionExpireActive) {
		return <SessionExpirePopup isOpened={isSessionExpireActive} />;
	}

	if (isLiveSessionExpireActive) {
		return <LiveSessionExpirePopup isOpened={isLiveSessionExpireActive} />;
	}

	if (isRealityCheckActive) {
		return <RealityCheckPopup isOpened={isRealityCheckActive} />;
	}

	if (isAutomaticLogoutActive) {
		return <AutomaticLogoutPopup isOpened={isAutomaticLogoutActive} />;
	}
	return null;
});
