import React from "react";
import {
    FormProvider,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { isNullOrWhitespace } from "../../../../../../utils/common/helpers";
import { Button } from "../../../../../common/components/buttons";
import {
    FormInputField,
    PasswordInput
} from "../../../../../common/components/inputs";
import { useAppContext } from "../../../../../common/hooks";
import {
    LoginErrorMessage,
    LoginStatusMessage
} from "../../../../../pages/membership/login/components";


export default function LoginTemplate(props) {
    const { t } = useTranslation();
    const { culture } = useAppContext();
    const {
        methods,
        onSuccess,
        loginError
    } = props;
    const { handleSubmit } = methods;

    return (
        <>
            <div className="view--sml is-active">

                <h1 className="title--primary">{t("MEMBERSHIP.LOGIN.TITLE")}</h1>

                <FormProvider {...methods}>
                    <form className="form" onSubmit={handleSubmit(onSuccess)}>
                        <section>
                            <LoginStatusMessage />
                            <LoginErrorMessage loginError={loginError} />

                            <FormInputField
                                name="username"
                                className='form__field row'
                                label='MEMBERSHIP.LOGIN.EMAIL_OR_USERNAME_LABEL'
                                placeholder='MEMBERSHIP.LOGIN.EMAIL_OR_USERNAME_PLACEHOLDER'
                                validate={{
                                    isNullOrWhitespace: value => isNullOrWhitespace(value) !== true || 'COMMON.REQUIRED_FIELD'
                                }}
                                removeSuccessMark
                                required
                            />

                            <PasswordInput
                                className='form__field row'
                                inputClassName="input input--base u-padd--left--reset"
                                label='MEMBERSHIP.LOGIN.PASSWORD_LABEL'
                                name="password"
                                placeholder='MEMBERSHIP.LOGIN.PASSWORD_PLACEHOLDER'
                                validate={{
                                    isNullOrWhitespace: value => isNullOrWhitespace(value) !== true || 'COMMON.REQUIRED_FIELD'
                                }}
                                removeSuccessMark
                                required
                            />

                            <div className="form__field row">
                                <div className="col-sml-11 u-type--right">
                                    <Button
                                        className="form__submit btn btn--med btn--secondary"
                                        btnText="MEMBERSHIP.LOGIN.LOGIN_BUTTON"
                                    />
                                </div>
                            </div>
                        </section>

                        <div className="u-type--center u-type--xsml u-type--wgt--bold">
                            <hr className="u-mar--reset" />
                            <div className="u-padd--xmed">
                                <div>
                                    <Link to={`/${culture}/auth/forgot-username`} className="u-anchor u-anchor--primary">
                                        {t("MEMBERSHIP.LOGIN.FORGOT_USERNAME_LINK")}
                                    </Link>
                                </div>
                                <div>
                                    <Link to={`/${culture}/auth/forgot-password`} className="u-anchor u-anchor--primary">
                                        {t("MEMBERSHIP.LOGIN.FORGOT_PASSWORD_LINK")}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </form>
                </FormProvider >
            </div>
        </>
    )
}