import React from "react";
import { FormProvider } from "react-hook-form";
import { PasswordConfirmation } from "../../../../../common/components/administration";
import { mapDataForPC } from "../../../../../pages/administration/email-change/utils";
import { EmailChangeFormSection } from "../../../../../pages/administration/email-change/components";
import { usePasswordConfirmation } from "../../../../../common/hooks";

export default function EmailChangeTemplate(props) {


    const { showPasswordConfirmation } = usePasswordConfirmation();

    const {
        methods,
        onSubmitData,
        onCancel,
        onSuccess,
        checkIsEmailAvailable
    } = props;

    const { getValues } = methods;



    return (
        <>
            {!showPasswordConfirmation ?
                <FormProvider {...methods}>
                    <EmailChangeFormSection
                        onSubmitData={onSubmitData}
                        checkIsEmailAvailable={checkIsEmailAvailable}
                    />
                </FormProvider>
                : <PasswordConfirmation
                    data={mapDataForPC(getValues('newEmail'))}
                    onCancel={onCancel}
                    onSuccess={onSuccess}
                    optionalMessage="COMMON.PASSWORD_CONFIRMATION.ENTER_PASSWORD_CONFIRMATION"
                />
            }

        </>
    )
}