import React from 'react';
import { inject } from 'mobx-react';
import { useParams } from 'react-router-dom';

import { SearchBarTemplate } from 'themes/common/components/sidemenu';

export default inject(stores => ({
    searchBarViewStore: stores.offerStore.searchBarViewStore
}))(function (props) {
    const { period } = useParams();

    return <SearchBarTemplate {...props} period={period} />;
});