import React from 'react';
import { observer } from 'mobx-react';
import { MainLayout } from './layouts';

@observer
class App extends React.Component {
    render() {
        return (
            <MainLayout />
        )

    }
}

export default App;