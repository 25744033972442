import React from 'react';
import { withRouter } from 'react-router-dom';

import { defaultTemplate } from '../../../common/hoc';
import { FooterTemplate } from 'themes/common/components/footer';

export default defaultTemplate(withRouter(function Footer(props) {
    return (<FooterTemplate {...props} />);
}));

