import React, { useContext } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";

import { MyBetsStoreContext } from "../../../../../common/context";
import { useCurrency, useFixBody } from "../../../../../common/hooks";

export default observer(function BetReports(props) {
	const {
		betsReportStore: { betsReport },
	} = useContext(MyBetsStoreContext);

	if (betsReport == null) {
		// Such emptiness to be displayed
		return null;
	}

	return (
		<>
			<BetReports_Table />
			<BetReports_OpenDetailsButton />
			<BetReports_Details />
		</>
	);
});

//#region BetReports_Table

function BetReports_Table(props) {
	return (
		<table className="table--primary u-mar--top--sml u-mar--bottom--tny no-print">
			<BetReports_Table_Caption />
			<BetReports_Table_Head />
			<BetReports_Table_Body />
		</table>
	);
}

const BetReports_Table_Caption = observer(function BetReports_Table_Caption(
	props
) {
	const { t } = useTranslation();

	const {
		betsReportStore: { from, to },
	} = useContext(MyBetsStoreContext);

	const captionStr = t("MY_BETS.REPORTS.PROFIT_PAYMENT_RATIO", {
		0: from,
		1: to,
		interpolation: { escapeValue: false },
	});
	return <caption className="u-type--wgt--bold">{captionStr}</caption>;
});

function BetReports_Table_Head(props) {
	const { t } = useTranslation();
	const { currency } = useCurrency();

	const headerColumns = [
		`${t("MY_BETS.REPORTS.NUMBER_OF_BET_SLIPS")}`,
		`${t("MY_BETS.REPORTS.PAYMENT")} (${currency})`,
		`${t("MY_BETS.REPORTS.NET_GAIN")} (${currency})`,
		`${t("MY_BETS.REPORTS.LOSS")} (${currency})`,
		`${t("MY_BETS.REPORTS.PROFIT")} (${currency})`,
		`${t("MY_BETS.REPORTS.PROFIT")} / ${t(
			"ACC_STATEMENT.BET_DETAILS.ADDITIONAL_PAYMENT"
		)} (%)`,
	];

	return (
		<thead className="table--primary__header">
			<tr className="table--primary__header__row">
				{headerColumns.map((colum, idx) => (
					<th
						key={idx}
						className="table--primary__header__data u-type--center"
					>
						{colum}
					</th>
				))}
			</tr>
		</thead>
	);
}

const BetReports_Table_Body = observer(function BetReports_Table_Body(props) {
	const {
		betsReportStore: {
			betsReport: {
				Count,
				Payment,
				NetGain,
				Loss,
				Profit,
				ProfitPercentage,
			},
		},
	} = useContext(MyBetsStoreContext);

	return (
		<tbody className="table--primary__body">
			<tr className="table--primary__body__row">
				<td className="table--primary__body__data u-type--center">
					{Count}
				</td>
				<td className="table--primary__body__data u-type--center">
					{Payment.toFixed(2)}
				</td>
				<td
					className={`table--primary__body__data u-type--center ${repIndicCss(
						NetGain
					)}`}
				>
					{NetGain.toFixed(2)}
				</td>
				<td
					className={`table--primary__body__data u-type--center ${repIndicCss(
						Loss
					)}`}
				>
					{Loss.toFixed(2)}
				</td>
				<td
					className={`table--primary__body__data u-type--center ${repIndicCss(
						Profit
					)} `}
				>
					{Profit.toFixed(2)}
				</td>
				<td
					className={`table--primary__body__data u-type--center ${repIndicCss(
						ProfitPercentage
					)} `}
				>
					{ProfitPercentage.toFixed(2)}
				</td>
			</tr>
		</tbody>
	);
});

//#endregion BetReports_Table

function BetReports_OpenDetailsButton(props) {
	const {
		betsReportStore: { openDetails },
	} = useContext(MyBetsStoreContext);

	const { t } = useTranslation();

	return (
		<div className="u-type--right no-print">
			<button
				id="showReportDetails"
				className="btn btn--tny btn--link"
				type="button"
				onClick={openDetails}
			>
				{t("MY_BETS.REPORTS.SHOW_DETAILS")}
			</button>
		</div>
	);
}

const BetReports_Details = observer(function BetReports_Details(props) {
	const { t } = useTranslation();

	const {
		betsReportStore: { from, to, isDetailsOpen },
	} = useContext(MyBetsStoreContext);
	useFixBody(true, isDetailsOpen);
	if (!isDetailsOpen) {
		return null;
	}

	const captionStr = t("MY_BETS.REPORTS.PROFIT_PAYMENT_RATIO", {
		0: from,
		1: to,
		interpolation: { escapeValue: false },
	});

	return (
		<div id="reportDetails" className="popup--dialog">
			<div className="popup--dialog__card">
				<div className="popup--dialog__card__header">
					<p className="u-type--wgt--bold">{captionStr}</p>
				</div>
				<div className="popup--dialog__card__body">
					<table className="table--bet">
						<DetailsReportsTableHeader />
						<DetailsReportsTableBody />
					</table>
				</div>
				<div className="popup--dialog__card__footer">
					<DetailsCloseButton />
				</div>
			</div>
		</div>
	);
});

function DetailsReportsTableHeader(props) {
	const { t } = useTranslation();
	const { currency } = useCurrency();

	return (
		<thead className="table--bet__subheader table--bet__subheader--secondary u-type--case--uppercase">
			<tr className="table--bet__subheader__row">
				<th className="table--bet__subheader__data">
					{t("ACC_STATEMENT.BET_DETAILS.BET_SLIP")}
				</th>
				<th className="table--bet__subheader__data">
					{t("MY_BETS.ITEM.INFO_DATE")}
				</th>
				<th className="table--bet__subheader__data u-type--right">
					{t("BET_SLIP.PAYMENT")} ({currency})
				</th>
				<th className="table--bet__subheader__data u-type--right">
					{t("ACC_STATEMENT.TABLES.TABLE_GAIN")}/
					{t("ACC_STATEMENT.TABLES.TABLE_LOSS")} ({currency})
				</th>
				<th className="table--bet__subheader__data u-type--right">
					{t("MY_BETS.REPORTS.PROFIT")}/
					{t("ACC_STATEMENT.BET_DETAILS.ADDITIONAL_PAYMENT")} (%)
				</th>
			</tr>
		</thead>
	);
}

const DetailsReportsTableBody = observer(function DetailsReportsTableBody(
	props
) {
	const {
		betsReportStore: {
			betsReport: { MyBetsDetails },
		},
	} = useContext(MyBetsStoreContext);

	const total = [...MyBetsDetails].reduce(
		(acc, curr) => {
			acc.Payment += curr.Payment;
			acc.GainLoss += curr.GainLoss;
			return acc;
		},
		{ Payment: 0, GainLoss: 0 }
	);

	return (
		<tbody className="table--bets__body">
			{MyBetsDetails.map((item) => (
				<DetailsReportsTableBodyRow key={item.Id} item={item} />
			))}
			<DetailsReportTotalRow total={total} />
		</tbody>
	);
});

const DetailsReportsTableBodyRow = observer(function DetailsReportsTableBodyRow(
	props
) {
	const {
		item: {
			BetSlipNumber,
			DateCreated,
			Payment,
			GainLoss,
			ProfitPercentage,
			BetStatusAbrv,
		},
	} = props;

	const date = DateTime.fromISO(DateCreated)
		.toLocaleString(DateTime.DATETIME_SHORT)
		.split(",")
		.join("");

	return (
		<tr className="table--bet__body__row table--bet__body__row--secondary">
			<td className="table--bet__body__data">{BetSlipNumber}</td>
			<td className="table--bet__body__data">{date}</td>
			<td className="table--bet__body__data u-type--right">
				{Payment.toFixed(2)}
			</td>
			<td
				className={`table--bet__body__data u-type--right u-type--color--${BetStatusAbrv}`}
			>
				{GainLoss.toFixed(2)}
			</td>
			<td
				className={`table--bet__body__data u-type--right u-type--color--${BetStatusAbrv}`}
			>
				{ProfitPercentage.toFixed(2)}
			</td>
		</tr>
	);
});

function DetailsCloseButton(props) {
	const { t } = useTranslation();

	const {
		betsReportStore: { closeDetails },
	} = useContext(MyBetsStoreContext);

	return (
		<div className="popup__footer">
			<button
				id="closeReportDetails"
				className="btn btn--sml btn--secondary btn--icon-left"
				type="button"
				onClick={closeDetails}
			>
				<i className="u-icon u-icon--xsml u-icon--remove--white" />
				{t("COMMON.CLOSE")}
			</button>
		</div>
	);
}

function DetailsReportTotalRow(props) {
	const {
		total: { Payment, GainLoss },
	} = props;
	return (
		<tr className="table--bet__body__row table--bet__body__row--secondary">
			<td className="table--bet__body__data" colSpan={2}>
				<strong>Total</strong>
			</td>
			<td className="table--bet__body__data u-type--right">
				<strong>{Payment.toFixed(2)}</strong>
			</td>
			<td className="table--bet__body__data u-type--right u-type--color--lost">
				<strong>{GainLoss.toFixed(2)}</strong>
			</td>
			<td className="table--bet__body__data u-type--right u-type--color--lost">
				<strong>{((GainLoss / Payment) * 100).toFixed(2)}</strong>
			</td>
		</tr>
	);
}

//#region utils

/**
 * @parm {number} number value for which to get indicator className name
 * @returns {string} indicator className name
 */
function repIndicCss(number) {
	if (number < 0) {
		return "u-type--color--lost";
	}

	if (number > 0) {
		return "u-type--color--won";
	}

	return "";
}

//#endregion utils
