import React from 'react';
import { observer, inject } from 'mobx-react';

import { MostPlayedOfferTemplate } from 'themes/pages/home/components';

@inject(stores => ({
	offerStore: stores.offerStore
}))
@observer
class MostPlayedOffer extends React.Component {
	constructor(props) {
		super(props);

		this.viewStore = new App.offer.pages.home.components.MostPlayedViewStore(props.offerStore, {columns: ["1", "2"]});
	}

	async componentDidMount() {
		await this.viewStore.initializeStore();
	}

	componentWillUnmount() {
		this.viewStore.onDispose();
	}

	render() {
		return <MostPlayedOfferTemplate {...this.props} viewStore={this.viewStore} />
	}
}

export default MostPlayedOffer;