import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BettingRulesTemplate } from "themes/pages/info-pages";

export default function BettingRules() {
    const { t } = useTranslation();

    useEffect(() => {
        App.state.rootStore.titleStore.setTitle(t('INFO_PAGES.NAVIGATION.BETTING_RULES'));

        document.querySelector('html, body').scrollTo(0, 0);
    }, []);

    return <BettingRulesTemplate />
}
