import React from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useFormContext } from "react-hook-form";
import { useNotification, useLoading } from "../../../../common/hooks";
import { forgotUsernameServiceInstance } from "../services";
import { ForgotUsernameFormSectionTemplate } from "themes/pages/membership/forgot-username/components";

export default function ForgotUsernameFormSection(props) {
	const { showError } = useNotification();

	const { reset } = useFormContext();

	const { executeRecaptcha } = useGoogleReCaptcha();

	const { setIsLoading } = useLoading();

	const { isForgotUsernameSuccess, setIsForgotUsernameSuccess } = props;

	if (isForgotUsernameSuccess) {
		return null;
	}

	const onFormSubmit = async (formData) => {
		try {
			setIsLoading(true);
			const recaptchaToken = await executeRecaptcha("forgotUsername");
			const response = await forgotUsernameServiceInstance.forgotUsername(
				formData,
				recaptchaToken
			);
			if (response.success) {
				setIsForgotUsernameSuccess(true);
			}
		} catch (error) {
			console.error(error);
			reset();
			if (error?.error?.options) {
				showError([
					{
						message:
							"MEMBERSHIP.FORGOT_USERNAME." +
							error?.error?.message,
						options: { 0: error?.error?.options },
					},
				]);
			} else {
				showError(
					"MEMBERSHIP.FORGOT_USERNAME." + error?.error?.message
				);
			}
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<ForgotUsernameFormSectionTemplate
			onFormSubmit={onFormSubmit}
			{...props}
		/>
	);
}
