import { useFormContext, useFormState } from "react-hook-form";
import { get } from "lodash";
import React from "react";

const ErrorMessageTemplate = (props) => {
	const { name, removeSuccessMark } = props;

	const { errors } = useFormState();
	const error = get(errors, name);
	const { getFieldState, getValues } = useFormContext();
	const { isDirty } = getFieldState(name);

	if (error) {
		return <Invalid />;
	} else if (
		getValues(name) == "" ||
		!isDirty ||
		(removeSuccessMark && getValues(name)?.length != 0)
	) {
		return null;
	} else {
		return <Valid />;
	}
};
export default ErrorMessageTemplate;

function Valid() {
	return (
		<span className="message--validation field-validation-valid">
			<span />
		</span>
	);
}

function Invalid() {
	return (
		<span className="message--validation field-validation-error">
			<span />
		</span>
	);
}
