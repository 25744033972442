import React from 'react';
import PropTypes from 'prop-types';
import { BetSlipResetConfirmationModalTemplate } from 'themes/common/components/bet-slip';

class BetSlipResetConfirmationModal extends React.Component {
    render() {
        return <BetSlipResetConfirmationModalTemplate {...this.props} />;
    }
}

BetSlipResetConfirmationModal.propTypes = {
    onAccept: PropTypes.func,
    onReject: PropTypes.func,
};

export default BetSlipResetConfirmationModal;