import React, { useEffect, useState } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { ForgotUsernameTemplate } from "themes/pages/membership/forgot-username";
import { FormProvider, useForm } from "react-hook-form";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { ErrorPage } from "../../../common/components/error";
import { useNotification } from "../../../common/hooks";

export default function ForgotUsername() {
	const { t } = useTranslation();
	const { closeNotification } = useNotification();
	useEffect(() => {
		App.state.rootStore.titleStore.setTitle(
			t("MEMBERSHIP.FORGOT_USERNAME.TITLE")
		);

		//on mount scroll to top
		document.querySelector("html, body").scrollTo(0, 0);
		return () => closeNotification();
	}, []);

	const [isForgotUsernameSuccess, setIsForgotUsernameSuccess] =
		useState(false);

	const methods = useForm({
		mode: "onChange",
		defaultValues: {
			dateOfBirth: DateTime.now().minus({ years: 18 }).toString(),
			email: "",
		},
	});

	return (
		<ErrorPage>
			<FormProvider {...methods}>
				<GoogleReCaptchaProvider
					language={App.utils.getCurrentLanguage()}
					reCaptchaKey={ReCaptchaChallengeKey}
				>
					<ForgotUsernameTemplate
						isForgotUsernameSuccess={isForgotUsernameSuccess}
						setIsForgotUsernameSuccess={setIsForgotUsernameSuccess}
					/>
				</GoogleReCaptchaProvider>
			</FormProvider>
		</ErrorPage>
	);
}
