import React from "react";
import { useTranslation } from "react-i18next";

export default function TeamNameTemplate(props) {
	const { team } = props;

	const { t } = useTranslation();

	if (team == null) {
		return null;
	}

	const { name, gender } = team;

	let genderStr = null;
	if (gender === 1) {
		genderStr = t("OFFER.GENDER.WOMEN");
	}

	return (
		<>
			{name} {genderStr}
		</>
	);
}
