import React from 'react';
import { inject } from 'mobx-react';

import { TournamentStatisticsButtonTemplate } from 'themes/common/components/offer/buttons';
@inject(stores => ({
    openStatisticsPopup: stores.offerStore.openStatisticsPopup,
    openStatistics: stores.offerStore.openStatistics
}))
class TournamentStatisticsButton extends React.Component {

    render() {
        return (
            <TournamentStatisticsButtonTemplate {...this.props} />
        );
    }
}
export default TournamentStatisticsButton;