import React, { useEffect, useState, useMemo } from "react";
import { PersonalDataTemplate } from "themes/pages/administration";
import { useForm } from "react-hook-form";
import { PersonalDataService } from "./services";
import {
	useNotification,
	useLoading,
	usePasswordConfirmation,
} from "../../../common/hooks";
import { removeUndefinedFields } from "./utils";
import { useTranslation } from "react-i18next";
import { ErrorPage } from "../../../common/components/error";

const personalDataServiceInstance = new PersonalDataService();

function PersonalData() {
	const [fetchedData, setFetchedData] = useState(null);
	const { setIsLoading } = useLoading();
	const { setShowPasswordConfirmation } = usePasswordConfirmation();
	const methods = useForm({ mode: "onChange" });
	const { t } = useTranslation();

	const {
		reset,
		getValues,
		setValue,
		formState: { dirtyFields },
	} = methods;

	const { showError, showSuccess } = useNotification();

	const personalDataService = useMemo(() => personalDataServiceInstance, []);

	const fetchData = async () => {
		try {
			setIsLoading(true);
			const response = await personalDataService.getPersonalData();
			if (response.success == true) {
				setFetchedData(response.data);
				reset(response.data.data);
			}
		} catch (error) {
			console.error(error);
			showError(
				"USER.ACCOUNT_SETTINGS.ERR_HANDLING." + error?.error?.message
			);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		App.state.rootStore.titleStore.setTitle(
			t("USER.ACCOUNT_SETTINGS.NAVIGATION.PERSONAL_DATA")
		);
		//on mount scroll to top
		document.querySelector("html, body").scrollTo(0, 0);

		fetchData();
		return () => setShowPasswordConfirmation(false);
	}, []);

	const onSubmitData = () => {
		setShowPasswordConfirmation(true);
	};

	const onCancel = () => {
		reset();
		setShowPasswordConfirmation(false);
	};

	const onSuccess = async (password) => {
		try {
			setIsLoading(true);
			const formData = removeUndefinedFields(getValues(), fetchedData);
			const response = await personalDataService.updatePersonalData({
				data: { ...formData },
				password,
			});
			if (response.success === true) {
				setFetchedData({ ...fetchedData, data: response.data.data });
				reset(response.data.data);
				setShowPasswordConfirmation(false);
				showSuccess(
					"USER.ACCOUNT_SETTINGS.PERSONAL_DATA.PERSONAL_DATA_UPDATE_SUCCESS"
				);
			}
			setIsLoading(false);
			return true;
		} catch (error) {
			if (error?.error?.message) {
				showError(
					"USER.ACCOUNT_SETTINGS.ERR_HANDLING." +
						error?.error?.message
				);
			}
			setIsLoading(false);
			return false;
		}
	};

	const handleDiscard = () => {
		if (Object.keys(dirtyFields).length != 0) {
			const payPin = getValues("payPin");
			reset();
			setValue("payPin", payPin);
		}
	};

	const updateUserPin = (currPayPin) => {
		return personalDataService.updateUserPin(currPayPin);
	};

	const getNewPayPin = () => {
		return personalDataService.getNewPayPin();
	};

	return (
		<ErrorPage>
			<PersonalDataTemplate
				fetchedData={fetchedData}
				onSubmitData={onSubmitData}
				methods={methods}
				onCancel={onCancel}
				onSuccess={onSuccess}
				handleDiscard={handleDiscard}
				updateUserPin={updateUserPin}
				getNewPayPin={getNewPayPin}
			/>
		</ErrorPage>
	);
}
export default PersonalData;
