import { Provider } from "mobx-react";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import {
    NotificationContainer,
    LoadingContainer,
    PasswordConfirmationContainer
} from ".";

function SinglePageCoreContainer(props) {
    const {
        children,
        passwordConfirmationNeeded
    } = props;

    if (passwordConfirmationNeeded) {
        return (
            <Suspense fallback="Loading...">

                <NotificationContainer>
                    <LoadingContainer>
                        <PasswordConfirmationContainer>
                            {children}
                        </PasswordConfirmationContainer>
                    </LoadingContainer>
                </NotificationContainer>
            </Suspense>
        )
    }

    return (
        <Suspense fallback="Loading...">
            <NotificationContainer>
                <LoadingContainer>
                    {children}
                </LoadingContainer>
            </NotificationContainer>
        </Suspense>

    )
}


export default SinglePageCoreContainer;
