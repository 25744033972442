import React, { useEffect, useState, useCallback } from "react";
import { PopupMessage } from "../../../../../common/components/popups";
import { NotificationContext } from "../../../../../common/context";

function NotificationContainerTemplate(props) {
    const [notification, setNotification] = useState(null);

    const contextValue = {
        closeNotification: () => { setNotification(null) },
        showNotification: (data) => { setNotification(data) },
        notification: notification
    }

    return (
        <NotificationContext.Provider value={contextValue}>
            <PopupMessage />
            {props.children}
        </NotificationContext.Provider>
    )
}

export default NotificationContainerTemplate;
