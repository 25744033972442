import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { recoverPasswordServiceInstance } from "../services";
import { useNotification, useLoading } from "../../../../common/hooks";
import { RecoverPasswordFormSectionTemplate } from "themes/pages/membership/recover-password/components";

export default function RecoverPasswordFormSection(props) {
	const methods = useForm({ mode: "onChange" });

	const { showError, showSuccessWithRedirect } = useNotification();

	const { setIsLoading } = useLoading();

	const { reset } = methods;

	const urlParams = new URLSearchParams(App.state.history.location.search);

	const redirectToHome = () => {
		App.state.redirect(`/${App.utils.getCurrentCulture()}/home/full`);
	};

	const onSubmitData = async (formData) => {
		try {
			setIsLoading(true);
			const tokenFromURL = urlParams.get("passwordRecoveryToken");
			const userNameFromURL = urlParams.get("username");
			const response =
				await recoverPasswordServiceInstance.recoverPassword(
					tokenFromURL,
					userNameFromURL,
					formData
				);
			if (response.success == true) {
				showSuccessWithRedirect(
					"MEMBERSHIP.RECOVER_PASSWORD.PASSWORD_CHANGE_SUCCESS",
					[
						{
							btnText: "MEMBERSHIP.RECOVER_PASSWORD.CLOSE_BTN",
							redirectFunction: redirectToHome,
						},
					]
				);
			}
		} catch (error) {
			showError("MEMBERSHIP.RECOVER_PASSWORD." + error?.error?.message);
			reset();
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<FormProvider {...methods}>
			<RecoverPasswordFormSectionTemplate onSubmitData={onSubmitData} />
		</FormProvider>
	);
}
