import React from 'react';
import PropTypes from 'prop-types';
import { BetSlipEventDeletionModalTemplate } from 'themes/common/components/bet-slip';

class BetSlipEventDeletionModal extends React.Component {
    render() {
        return <BetSlipEventDeletionModalTemplate {...this.props} />;
    }
}

BetSlipEventDeletionModal.propTypes = {
    data: PropTypes.object,
    eventId: PropTypes.string,
    onTipDelete: PropTypes.func,
    onEventDelete: PropTypes.func,
    onClose: PropTypes.func
};

export default BetSlipEventDeletionModal;