import React from 'react';
import { LmtSideContainerTemplate } from 'themes/common/components/sidemenu';

class LmtSideContainer extends React.Component {
    render() {
        return (
            <LmtSideContainerTemplate />
        );
    }
}

export default LmtSideContainer