import React from 'react';
import { ForgotUsernamePopupSuccessTemplate } from "themes/pages/membership/forgot-username/components";

export default function ForgotUsernamePopupSuccess(props){

    const {
        isForgotUsernameSuccess
    } = props;

    if(!isForgotUsernameSuccess){
        return null;
    }

    return <ForgotUsernamePopupSuccessTemplate {...props} />
}