import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useState } from "react";
import { RealityCheckPopupTemplate } from "themes/common/components/popups";
import { RealityCheckService } from "../../../../state/services/common";
import { useFixBody, useUser } from "../../hooks";

const realityCheckService = new RealityCheckService();

export default observer(function RealityCheckPopup(props) {
    const [fetchedData, setFetchedData] = useState();
    const [timePlayedData, setTimePlayedData] = useState(null);
    const user = useUser();

    function timeConvert(n) {
        const num = n;
        const hours = (num / 60);
        const rhours = Math.floor(hours);
        const minutes = (hours - rhours) * 60;
        const rminutes = Math.round(minutes);
        return {
            hours: rhours,
            minutes: rminutes
        }
    }

    const logout = async () => {
        if (App.utils.isUserAuthenticated()) {
            await realityCheckService.resolveRealityCheckData(user.user.id, true);
            await App.state.rootStore.userAuthStore.logoutUser({ isRealityCheckLogout: true });
        }
        App.state.rootStore.stopSession();
        const homeUrlLink = `/${App.utils.getCurrentCulture()}/home/full`;
        if (window.location.pathname !== homeUrlLink) {
            App.state.redirect(homeUrlLink);
        }
    }

    const resume = async () => {
        if (!App.utils.isUserAuthenticated()) {
            await App.state.rootStore.userAuthStore.logoutUser();
            await realityCheckService.resolveRealityCheckData(user.user.id);
            App.state.redirect(`/${App.utils.getCurrentCulture()}/auth/login?returnUrl=${window.location.pathname}&isSessionExpire=true`);
        }
        else {
            //extend play time
            let newRealityCheckValue = DateTime.now().plus({ hour: 1 }).toMillis();
            localStorage.setItem('reality-check-expiration', newRealityCheckValue);
            await realityCheckService.resolveRealityCheckData(user.user.id);
            const realityCheckDurationLeft = JSON.parse(localStorage.getItem("reality-check-expiration"))
            const currentDate = new Date();
            const expiresIn = realityCheckDurationLeft - currentDate.getTime();

            if (expiresIn != null && !isNaN(expiresIn)) {
                App.state.rootStore.realityCheckStore.startTimer(expiresIn);
                App.state.rootStore.realityCheckStore.deactivatePopup();
                App.state.rootStore.userAuthStore.resolveConnection();
            }
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await realityCheckService.getRealityCheckData();
                if (response != null) {
                    setFetchedData(response);
                    setTimePlayedData(timeConvert(response.timePlayed))
                }
            } catch (error) {
                console.error(error);
            }
        }

        fetchData();
    }, []);

    useFixBody(true, props.isOpened);

    return <RealityCheckPopupTemplate
        fetchedData={fetchedData}
        timePlayedData={timePlayedData}
        logout={logout}
        resume={resume}
        {...props}
    />
})