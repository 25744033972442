import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { DateTime } from 'luxon';
import { range } from 'lodash';
import { useTranslation } from 'react-i18next';

import { MatchStatuses } from '../../../../../../administration/services/results';
import { useLoading, useNotification } from '../../../../../common/hooks';
import { ScoreHeaderRenderer, ScoreRenderer } from '../../../../../pages/results/components';
import { StatisticsButton } from '../../../../../common/components/offer/buttons';

export default observer(function ResultTablesTemplate(props) {
    const {
        showError
    } = useNotification();

    const { setIsLoading } = useLoading();

    const { t } = useTranslation();

    const {
        selectedTournaments,
        setSelectedTournaments,
        resultsDataStore,
        resultsMenuStore,
        resultsDataStore: {
            resultData
        }
    } = props;

    useEffect(() => {

        async function fetchData() {
            setIsLoading(true);
            try{
                await resultsDataStore.onInitialize();
            }
            catch(error) {
                showError(error);
            }
            finally {
                setIsLoading(false);
            }
        }

        fetchData();
    }, [selectedTournaments.length]);

    if (resultData == null) {
        return null;
    }

    if (resultData.length === 0) {
        return (
            <div class="card--primary__body">
                <div class="message--base message--note">
                    <div>{t('RESULTS.RESULT_CONTENT.EMPTY_STATE_TITLE')}</div>
                    <div>{t('RESULTS.RESULT_CONTENT.EMPTY_STATE_DESCRIPTION')}</div>
                </div>
            </div>
        )
    }

    return (
        <>
            {resultData.map(result => {
                var tbodyColspan = 0;
                switch (result.sport.abrv)
                {
                    case "baseball":
                    case "golf":
                    case "motorsport":
                    case "pesapallo":
                    case "beach-soccer":
                    case "floorball":
                    case "american-football":
                    case "snooker":
                    case "esoccer":
                    case "bandy":
                        tbodyColspan = 7;
                        break;
                    case "futsal":
                    case "handball":
                    case "rugby":
                    case "soccer":
                        tbodyColspan = 8;
                        break;
                    case "aussie-rules":
                    case "ice-hockey":
                    case "field-hockey":
                        tbodyColspan = 10;
                        break;
                    case "beach-volley":
                    case "badminton":
                    case "basketball":
                    case "tennis":
                    case "waterpolo":
                    case "volleyball":
                        tbodyColspan = 11;
                        break;
                    default:
                        tbodyColspan = 6;
                        break;
                }

                var tbodyColspanMax = 11;
                var theadColspan = 10;
                // var theadColspan = (Model.IsFiltered ? 11 : 12);


                return result.tournaments.map(tournament => {
                    return (
                        <table key={`${result.sport.id}-${result.sportCategory.id}-${tournament.id}`} className={`table--results table--results--${result.sport.abrv}`}>
                            <thead className="table--results__header">
                                <tr className="table--results__header__row">

                                    <th className="table--results__header__data u-type--left" colSpan={theadColspan}>
                                        <i className={`u-icon u-icon--sport u-icon--sport--light u-icon--${result.sport.abrv} u-mar--right--sml`}></i>
                                        <span>{result.sport.name} - {result.sportCategory.name}</span>
                                    </th>

                                    {selectedTournaments.length > 0 ?
                                        <th class="table--results__header__data u-type--right" >
                                            <button class="jqClose btn btn--link" data-type="current" type="button"  onClick={()=>setSelectedTournaments(tournament)}>
                                                <i class="u-icon u-icon--sml u-icon--remove--white"></i>
                                            </button>
                                        </th>
                                    : null}
                                </tr>
                            </thead>
                            <thead className="table--results__subheader">
                                <tr className="table--results__subheader__row">
                                    <th className="table--results__subheader__data table--results__subheader__data--tournament" colSpan="2" title={tournament.name}>
                                        {tournament.name}
                                    </th>                                
                                    <th className="table--results__subheader__data table--results__subheader__data--stats">
                                        <StatisticsButton 
                                            tournamentId={tournament.id} 
                                            currentSport={result.sport} 
                                            currentTournament={{ 
                                                name: tournament.name, 
                                                abrv: tournament.abrv, 
                                                id: tournament.id, 
                                                source: tournament.source 
                                            }}
                                            isOfferButton
                                        />
                                    </th>
                                    {/* @* These headers will be sport specific *@
                                    @await Component.InvokeAsync("ScoreHeaderRenderer", new { scoreType = result.ScoreType, columnsCount = result.ColumnsCount }) */}

                                    <ScoreHeaderRenderer scoreType={result.scoreType} columnsCount={result.columnsCount} tournamentId={tournament.id} />

                                    <th className="table--results__subheader__data table--results__subheader__data--tip">{t('RESULTS.RESULT_CONTENT.TIP')}</th>
                                    <th className="table--results__subheader__data table--results__subheader__data--odd">{t('RESULTS.RESULT_CONTENT.ODD')}</th>

                                    {tbodyColspan < tbodyColspanMax ? (
                                         Array.apply(null, Array(tbodyColspanMax - tbodyColspan)).map((item, idx) => {
                                            return <th key={`${tournament.id}-${idx}`} className="table--results__subheader__data is-empty">&nbsp;</th>
                                        }))
                                    : null}                 
                                </tr>
                            </thead>
                            <tbody className="table--results__body">
                                {Array.from(tournament.events).map(eventGroup => {
                                    return <React.Fragment key={`${tournament.id}-${eventGroup[0]}`}>
                                        <tr className="table--results__body__row table--results__body__row--bordered">
                                            <td className="table--results__body__data table--results__body__data--date" colSpan={tbodyColspanMax}>
                                                {eventGroup[0]}
                                            </td>
                                        </tr>

                                        {eventGroup[1].map(event => {
                                            return <tr key={event.id} className="table--results__body__row">
                                                <td className="table--results__body__data table--results__body__data--time">
                                                    {DateTime.fromISO(event.startTime).toFormat('T')}
                                                </td>
                                                <td className="table--results__body__data table--results__body__data--teams">
                                                    <div>{event.teamOne}</div>
                                                    <div>{event.teamTwo}</div>
                                                </td>

                                                {MatchStatuses.includes(event.matchStatus.abrv) ? (
                                                    <>
                                                        <td className="table--results__body__data table--results__body__data--stats">&nbsp;</td>
                                                        <td className="table--results__body__data table--results__body__data--postponed" colSpan={tbodyColspanMax - 3}>{t('RESULTS.RESULT_CONTENT.POSTPONED')}</td>
                                                    </>
                                                ): (
                                                    <>
                                                        <td class="table--results__body__data is-empty">&nbsp;</td>
                                                        <ScoreRenderer 
                                                            scoreType={result.scoreType}
                                                            columnsCount={result.columnsCount} 
                                                            event={event}
                                                        />

                                                        <td className="table--results__body__data table--results__body__data--tip">
                                                            <strong>{event.tip}</strong>
                                                        </td>
                                                        <td className="table--results__body__data table--results__body__data--odd">
                                                            <strong>{event.odd !== '-' && parseFloat(event.odd) % 1 === 0 ? parseFloat(event.odd).toFixed(1) : event.odd}</strong>
                                                        </td>

                                                        {tbodyColspan < tbodyColspanMax ? (
                                                            Array.apply(null, Array(tbodyColspanMax - tbodyColspan)).map((item, idx) => {
                                                                return <th key={`${event.id}-${idx}`} className="table--results__body__data is-empty">&nbsp;</th>
                                                            }))
                                                        : null}
                                                    </>
                                                )}
                                            </tr>
                                        })}
                                    </React.Fragment>
                                })}
                            </tbody>
                        </table>
                    )
                })
            })}
        </>
    )
})