import React, { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { PasswordChangeFormSectionTemplate } from "themes/pages/administration/password-change/components";
import { isNullOrWhitespace } from "../../../../../utils";

export default function PasswordChangeFormSection(props) {
	const { trigger, getFieldState, getValues } = useFormContext();

	useEffect(() => {
		if (
			!isNullOrWhitespace(getValues("currPassword")) &&
			getFieldState("currPassword").isTouched &&
			getFieldState("newPassword").isTouched
		) {
			trigger("newPassword");
		}
	}, [useWatch({ name: "currPassword" })]);

	useEffect(() => {
		if (
			!isNullOrWhitespace(getValues("newPassword")) &&
			getFieldState("newPassword").isTouched &&
			getFieldState("repeatPassword").isTouched
		) {
			trigger("repeatPassword");
		}
	}, [useWatch({ name: "newPassword" })]);

	return <PasswordChangeFormSectionTemplate {...props} />;
}
